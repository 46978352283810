import React, { Component } from 'react';
import { push } from 'react-router-redux';

import { 
  currencyWithCents,
  formatDateMmDdYyyy,
  formatDateMmYyyy,
  integer,
  zeroToEmpty
} from 'common/util/common-formatters';
import {
  createGroupClassNameGenerator,
  getOrganizationFromLocation
} from 'common/util/common-helpers';

import { 
  cusipLink,
  rate
} from 'www/util/www-formatters';

import { mapColumns } from 'www/util/labels';

import Table, { TYPES } from 'common/table/Table';
import Customizable from 'common/table/features/Customizable';
import Paginator, { loadPageWithCall } from 'common/table/features/Paginator';

import {
  DEFAULT_COLLATERAL_PAGE_SIZE,
  DEFAULT_INIT_PAGE
} from 'common/util/common-helpers';

import ButtonDropdown from 'common/button/ButtonDropdown';

 import ENUMS from 'www/util/enumFormatters';

import {
getCollateralOptionSelector, paginate
} from 'www/util/www-helpers';

 const {issuer} = ENUMS;

const ISSAUANCE_PLACEHOLDER='ISSUANCE';
const ONGOING_PLACEHOLDER='CURRENT';

export default class DealCollateralTable extends Component {

  hasOngoingDealClasses(details){
    return details && details.deal && details.deal.ongoingClasses && details.deal.ongoingClasses &&
        details.deal.ongoingClasses[0] && details.deal.ongoingClasses[0].discType!='ISSUANCE';
  }

  componentDidMount() {

    const { details } = this.props;
    const { collateral } = details;

    if (!this.props.hideCollateralAndDocumentTab) {
      let selectedOption = details.recordType;

      if(selectedOption=== undefined){
        if (this.hasOngoingDealClasses(details) )
          selectedOption=ONGOING_PLACEHOLDER;
        else
          selectedOption=ISSAUANCE_PLACEHOLDER;
      }
        if (!collateral || collateral.length === 0 ) {
          this.props.getDealCollateralCount(this.props.dealId, selectedOption);
          this.props.getDealCollateral({'dealId': this.props.dealId, 'recordType': selectedOption}, DEFAULT_INIT_PAGE, DEFAULT_COLLATERAL_PAGE_SIZE);
        }
    }
      if(sessionStorage.getItem('tabIndex')){
           setTimeout(()=>{ sessionStorage.removeItem('tabIndex');}), 0
      }
  }



  onOptionsSelect=(changedOption)=>{
    console.log("inside onchange method");
    const { details } = this.props;

    if (changedOption !== details.recordType) {
      this.props.getDealCollateralCount(this.props.dealId, changedOption);

      this.props.getDealCollateral({'dealId': this.props.dealId, 'recordType': changedOption}, DEFAULT_INIT_PAGE, DEFAULT_COLLATERAL_PAGE_SIZE);
    }
   }

  UNSAFE_componentWillReceiveProps(nextProps) {
    document.body.scrollTop = 0;
  }

    redirectToDeal = (e) => {
        sessionStorage.setItem('tabIndex',this.props.tabIndex);
            const location = this.props.location;
            window.location.assign(window.location.origin + location.pathname);
            e.preventDefault();
    }

  render() {
    const { dealId, trustNumber, details, getDealCollateral, changePage } = this.props;
    const { collateral, isFetchingCollateral, hasError, message, pageIdx, pageSize, dealCollateralCount } = details;

    let selectedOption = details.recordType;

    const selectOptions = (this.hasOngoingDealClasses(details)) ? [ISSAUANCE_PLACEHOLDER, ONGOING_PLACEHOLDER] : [ISSAUANCE_PLACEHOLDER];
    const {onOptionsSelect}=this;

    if (hasError && !this.props.hideCollateralAndDocumentTab) {
      return <div className="empty-text">{message || 'Unable to load collateral. Please try again later.'}</div>;
    } else if (isFetchingCollateral) {
      return <div className="loading more"><i className="fa fa-spinner fa-pulse"/></div>;
    }

    if (collateral === undefined && !this.props.hideCollateralAndDocumentTab) {
      console.log('Not fetching, no error, but no collateral either? should at least have empty array.',
        {props: this.props});
      return <div className="loading more"><i className="fa fa-spinner fa-pulse"/></div>;      
    }

    if(selectedOption === ONGOING_PLACEHOLDER && details.deal && details.deal.dealStatus !=='Active' && collateral){
      collateral.length = 0;
      // deal is paid off, they want to see current but have it be empty
    }

    if(collateral.length> 0 && details && details.deal && details.deal.dealType == 'ESF'){
      collateral.forEach(esf => {esf.classId=null;esf.trustNumber=null;});
    }

    if(selectedOption=== undefined){
      if (this.hasOngoingDealClasses(details))
        selectedOption=ONGOING_PLACEHOLDER;
      else
        selectedOption=ISSAUANCE_PLACEHOLDER;
    }

    const exportType = selectedOption === ONGOING_PLACEHOLDER ? 'CURR' : 'ISS';

    const exportLink = `/api/deal/${getOrganizationFromLocation()}/${dealId}/collateral?recordType=${selectedOption}&export=true`;
    let exportName = '${trustNumber}_COLLAT_${exportType}.txt';
    let exportAll = true;
    let anonymousExport = false;

    const paginatedCollateral = paginate(collateral, pageIdx, pageSize);

    const config = {
      className: 'deal-collateral-table',
      table: {
        className: 'results deal-table',
        isDealOrL2CollateralTable: true,
        rowDynamicClassName: createGroupClassNameGenerator('groupId', paginatedCollateral, 'group-start', ''),
        columnDefaults: {
          sortable: false
        },
        key: '',
        columns: [
          { 
            key: 'groupId',
            formatter: zeroToEmpty,
            className: 'center header-center',
            dynamicClassName: createGroupClassNameGenerator('groupId', paginatedCollateral, 'group-cell-show', 'group-cell-hide')
          },
          { 
            key: 'cusip', 
            formatter: cusipLink,
            className: 'center header-center'
          },
          { 
            key: 'secId', 
            className: 'center header-center'
          },
          { 
            key: 'issuer', 
            formatter: issuer,
            className: 'center header-center'
          },
          { 
            key: 'prefix', 
            className: 'center header-center'
          },
          { 
            key: 'netRate',
            formatter: rate,
            className: 'center header-center'
          },
          { 
            key: 'issueDt', 
            formatter: formatDateMmDdYyyy,
            className: 'center header-center'
          },
          { 
            key: 'maturity', 
            formatter: formatDateMmYyyy,
            className: 'center header-center'
          },
          { 
            key: 'upb', 
            formatter: currencyWithCents,
            className: 'center header-center'
          },
          { 
            key: 'upbContribIssuance', 
            formatter: currencyWithCents,
            className: 'center header-center'
          },
          { 
            key: 'upbContribCurrent', 
            formatter: currencyWithCents,
            className: 'center header-center'
          },
          { 
            key: 'rate', 
            formatter: rate,
            className: 'center header-center'
          },
          { 
            key: 'rmm', 
            formatter: integer,
            className: 'center header-center'
          },
          { 
            key: 'lnAge', 
            formatter: integer,
            className: 'center header-center'
          },
          {
            key: 'trustNumber',
            className: 'center header-center'
          },
          {
            key: 'classId',
            className: 'center header-center'
          }
        ],
        columnEmptyText: '-',
        emptyText: {
          key: 'collateralText',
          text: 'No collateral available'
        }
      }
    };

    config.buttons = [optionSelector()];


    mapColumns('dealCollateral', config.table.columns);

    return (
        <div>
        {
          !this.props.hideCollateralAndDocumentTab ?
              <div>
                <Table
                    type={TYPES.simple}
                    features={[Customizable, Paginator]}
                       {...this.props}
                       config={config}
                       data={paginatedCollateral}
                       threshold={50}
                       count={dealCollateralCount}
                       isFetchingMore={isFetchingCollateral}
                       pageIdx={pageIdx}
                       pageSize={pageSize}
                       loadPage={(collateral.length > 0 && details && details.deal) ? loadPageWithCall(changePage, {'dealId': dealId, 'recordType': selectedOption}, pageSize)
                           : loadPageWithCall(getDealCollateral, {'dealId': dealId, 'recordType': selectedOption} , pageSize)}
                       exportAll={exportAll}
                       anonymousExport={anonymousExport}
                       exportLink={exportLink}
                       exportName={exportName} />
                <div className="caption">* as of the issuance of the Deal</div>
              </div> :
              <div>
                This particular view does not pertain to selective classes within a deal. Click the following link to reset the page to show the collateral tab data belonging to the entire deal
                &nbsp;
                <a href={'#'} onClick={(e) => this.redirectToDeal(e)}>Click Here</a>
              </div>
        }
        </div>
    );



    function optionSelector(){
      const options=getCollateralOptionSelector(selectedOption,selectOptions, onOptionsSelect);
      return (
          <div className="deal-tranch-month-selector right">
            <ButtonDropdown {...options} />
          </div>
      );
    }



  }


}
