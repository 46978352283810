import React from 'react';
import { Link } from 'react-router-dom';

import { eventHandler } from 'common/util/events';

export default ({visible, options, onItemSelect}) => {
  const onItemSelectHandler = (option, idx) => {
    if (onItemSelect) {
      onItemSelect(option, idx);
    }

    if (option.onClick) {
      option.onClick(option, idx);
    }
  };

  return (
    <ul className={visible ? 'toggled' : ''}>
      {options.map((option, idx) => {
        if (option === '-') {
          return <li key={idx} className="separator"><hr/></li>;
        }

        const { disabled, text, url, download, external = false, action, onClick = () => {}, formatter } = option;
        const label = formatter ? formatter(text) : text;
        if (disabled) {
          return <li key={idx} className="disabled">{label}</li>;
        } else if (url) {
          if (download) {
            return <li key={idx}><a href={url} download={download}>{label}</a></li>;
          } else if (external) {
            return <li key={idx}><a href={url}>{label}</a></li>;
          } else {
            return <li key={idx}><Link to={url}>{label}</Link></li>;
          }
        } else if (action) {
          return <li key={idx} onClick={action} tabIndex="1">{label}</li>;
        } else if (onClick) {
          return <li key={idx} onClick={eventHandler(onItemSelectHandler, option, idx)} tabIndex="1">{label}</li>;
        }
      })}
    </ul>
  );
};
