import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { eventHandler } from 'common/util/events';
import { multilineText, getOrganizationFromLocation } from 'common/util/common-helpers';


export default class AcceptTermsForm extends Component {

  state = {
    showTerms: true
  };

  toggleTerms = () => {
    this.setState({showTerms: !this.state.showTerms});
  };

  render() {
    const { settings, acceptTerms, cancel } = this.props;
    const { showTerms } = this.state;

    let content;
    if (showTerms) {
      content = <TermsForm termsAndConditions={settings.termsAndConditions} tcDate={settings.tcDate} onCancel={this.toggleTerms} onAccept={acceptTerms} termsModif={true}/>;
    } else {
      content = <AreYouSure onOk={cancel} onCancel={this.toggleTerms} />;
    }

    return <div className="modal register-form">{content}</div>;
  }
}

function AreYouSure({onOk, onCancel}) {
  return (
    <div className="terms-conditions confirmation">
      <h2>Are you sure?</h2>
      <div className="contents">If you click yes below, you will be logged out and prompted to accept the new terms
        and conditions on each subsequent login attempt. Clicking no will return you to the terms and conditions.</div>
      <button className="btn-danger" onClick={onCancel}>No</button>
      <button className="btn-primary" onClick={onOk}>Yes</button>
    </div>
  );
}

export function TermsForm({termsAndConditions, tcDate, onCancel, onAccept, termsModif=false, initialTC= false, showLogin=false, onLogin, isRegister=false, onClose}) {
  const currentOrg = getOrganizationFromLocation();
  return (
    <div className="terms-conditions conditions">
      <h2>Terms &amp; Conditions</h2>
      { termsModif && <div className='termsModif'>Please review and accept the new terms & conditions in order to continue.</div> }
      { initialTC && currentOrg==='fannie' && <div className='termsModif'>By accessing Fannie Mae's security disclosure information via PoolTalk® you agree to these Terms & Conditions.<br />Click "Agree" below or log into your PoolTalk® account to proceed.</div> }
      { initialTC && currentOrg==='freddie' && <div className='termsModif'>Before accessing security disclosure information, you must login to your account, or read and agree to the Terms and Conditions described at the following link:</div> }
      <div className="contents"><a href={termsAndConditions} target="_blank">{termsAndConditions}</a>
      <p>Last Updated: {tcDate}</p></div>
      {showLogin && <button className="btn-login" onClick={onLogin}>Login</button>}
      {!isRegister && <button className="btn-danger" onClick={onCancel}>Disagree</button>}
      {!isRegister && <button className="btn-primary" onClick={onAccept}>Agree</button>}
      {isRegister && <button className="btn-close" onClick={onClose}>Close</button>}
    </div>
  );
}

function onPrint(termsAndConditions) {
  let childWindow = window.open('', 'Terms And Conditions', 'location=no, menubar=no, toolbar=no');
  childWindow.document.open();
  const terms = multilineText(termsAndConditions);
  childWindow.document.write(`<html><div id='print-view'></div></html>`)
  ReactDOM.render(
    terms,
    childWindow.document.getElementById('print-view')
  );

  childWindow.print();
  childWindow.document.close();
  childWindow.close();
}