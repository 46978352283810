import {getFirstIndexOf, getSubStringFromBegining, getNextIndexOf, getSubString} from 'common/util/common-helpers'
import { truncate } from 'lodash';

const ALL_PLACEHOLDER = 'All';
function getmonthMapper(){
    let monthMap = new Map();
    monthMap.set('January', '01');
    monthMap.set('February', '02');
    monthMap.set('March', '03');
    monthMap.set('April', '04');
    monthMap.set('May', '05');
    monthMap.set('June', '06');
    monthMap.set('July', '07');
    monthMap.set('August', '08');
    monthMap.set('September', '09');
    monthMap.set('October', '10');
    monthMap.set('November', '11');
    monthMap.set('December', '12');

    return monthMap;

}
function getMonthNumber(formattedMonth){
    return formattedMonth === ALL_PLACEHOLDER ? formattedMonth : getmonthMapper().get(formattedMonth); 
}

export function filterDealDocuments(documents, filters){
    const{documentType, year, month} = filters;
    
    const filteredDocuments =documents.filter(doc=>{
        const documentTypeCode = getSubStringFromBegining(doc.headingKey, getFirstIndexOf(doc.headingKey, '_'));
        const effectiveYear = getSubStringFromBegining(doc.document.effectiveDate,getFirstIndexOf(doc.document.effectiveDate, '-'));
        const firstOccurenceIndex = getFirstIndexOf(doc.document.effectiveDate, '-');
        const secondOccurenceIndex = getNextIndexOf(doc.document.effectiveDate, '-', firstOccurenceIndex+1);
        const effectiveMonth = getSubString(doc.document.effectiveDate, firstOccurenceIndex+1, secondOccurenceIndex);
        let typeCheck = true;
        let monthCheck = true;

        if(documentType!==ALL_PLACEHOLDER){
            typeCheck = documentTypeCode === documentType
        }
        if(month!==ALL_PLACEHOLDER){
            monthCheck = effectiveMonth === month;
        }

        return typeCheck && effectiveYear === year && monthCheck;
    });
    return filteredDocuments;
}

export function createFilter(documentType, year, month){
    const monthNumber = getMonthNumber(month);
    return {documentType: documentType, year: year, month:monthNumber};
}