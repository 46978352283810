import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSettings } from 'www/actions/settingsActions';

class Footer extends Component {

render() {
  const { settings, params } = this.props;
  const { organization } = params;
  if (organization === "freddie") {
      return (
          <footer>
              <Link to='/freddie/multilenders'>Freddie Multilenders&reg;</Link>
              <a href="http://www.freddiemac.com/mbs/legal/">Legal Documents</a>
              <a href="http://www.freddiemac.com/mbs/contact">Help</a>
              <a href="http://www.freddiemac.com/terms/privacy.html">Privacy Policy</a>
              <a href={settings.termsAndConditions}>Terms & Conditions</a>
          </footer>
      );
  } else {
      return (
          <footer>
              {/*<a href="http://www.fanniemae.com/portal/jsp/mbs/documents/mbs/prospectus/index.html">Prospectus</a>*/}
              <a href={settings.dataDynamics}>Data Dynamics</a>
              <a href="http://www.fanniemae.com/portal/privacy-statement.html">Privacy Policy</a>
              <a href={settings.termsAndConditions}>Terms & Conditions</a>
              <a href={settings.contactUs}>Contact Us</a>
          </footer>
      );
  }
  }
  
}

export default connect(
  ({settings}) => ({ settings }),
  { getSettings }
)(Footer)
