import React from 'react';
import classNames from 'classnames';

import ButtonDropdown from 'common/button/ButtonDropdown';
import { eventHandlerWithPrevent } from 'common/util/events';
import { formatDateTimeMmDdYyyyHourMinute } from 'common/util/common-formatters';
import { getOrganizationFromLocation } from 'common/util/common-helpers';
import { isFreddie } from 'www/util/www-helpers';


export default ({profile, loggedIn, showLogin, logout, params, lastLoginTimeStamp, idleMessage, newMsg, portfolio, validateCsrf, redirectPage, redirect}) => {
 
  const className = classNames('header-login', {'logged-in': loggedIn});
  const defaultPortfolioLink = portfolio && portfolio.length > 0 ? `/${portfolio[0].slug}` : '';
  if (loggedIn) {
    const org = getOrganizationFromLocation()
    const accountOptionsFreddie = [
      { text: 'Alerts', url: `/${org}/account/alerts`},
      { text: 'Data Files and Reports', url: `/${org}/account/datafiles/singleclass/issuance`},
      { text: 'Portfolio', url: `/${org}/account/portfolio${defaultPortfolioLink}`},
      { text: 'Profile', url: `/${org}/account/profile`},
      { text: 'Reset Password', url: `/${org}/account/resetPassword`},
      { text: 'Logout', action: logout}
    ];
    const accountOptionsFannie = [
      { text: 'Alerts', url: `/${org}/account/alerts`},
      { text: 'Portfolio', url: `/${org}/account/portfolio${defaultPortfolioLink}`},
      { text: 'Profile', url: `/${org}/account/profile`},
      { text: 'Reset Password', url: `/${org}/account/resetPassword`},
      { text: 'Logout', action: logout}
    ];
    let  accountOptions = accountOptionsFannie
      if (org  === "freddie") {
          accountOptions = accountOptionsFreddie
      }

    const { firstName, lastName} = profile;

    return (
      <div className={className}>
        <div className="user-name">{firstName} {lastName}</div>
        <div className="last-login-date">Last Login: {formatDateTimeMmDdYyyyHourMinute(lastLoginTimeStamp) || <span className="monospace">{'\u00af\\(\u30C3)/\u00af'}</span>} ET</div>
        <ButtonDropdown options={accountOptions} />
      </div>
    );
  } else {
	  
	if(redirect === true){	 
	    redirect = false;
	    return redirectPage();
	} else {
		   return (
	      <div className={className}>
              { isFreddie() ?  <span className="fre-idle-message">{idleMessage}</span> : <span className="fnm-idle-message">{idleMessage}</span> }
	       <a href="#" className="login-button" onClick={validateCsrf}>Login</a>
	      </div>
	    );
    }
  }
};
