import React, {Component} from 'react';
import classNames from 'classnames';

import { getColumnStyles } from 'common/table/Cell';
import SimpleTable from 'common/table/SimpleTable';
import { headerTooltip } from 'common/table/HeaderCell';


export default (props) => {
  const config = {
    ...props.config,
    className: classNames('grouped-table', props.config.className)
};

  return <SimpleTable {...props} config={config} headerType={GroupedHeader} />;
};


const GroupedHeader = ({columns}) => {
  const groupedColumns = transformColumns({columns});

  return (
    <li className="list-group-item">
      {groupedColumns.map(GroupedHeaderCell)}
    </li>
  );
};

/*
 Note: width properties will be ignored for grouped header cells and thus will cause misalignment between
 header and data cells if used. However, width is recommended over flex on non-grouped cells.
 */
const GroupedHeaderCell = (group, idx) => {
  let label, title, style;
  if (group.column) {
    label = group.column.label;
    title = headerTooltip(group.column);
    style = getColumnStyles(group.column);
  } else {
    label = title = group.groupLabel;
    // The flex for the column group will be the sum of its columns' flex (defaulted to 1 as SimpleTable does)
    style = {flex: group.columns.reduce((flex, column) => (flex + (column.flex || 1)), 0)};
  }

  return (
    <div key={idx} className="heading-root" style={style}>
      <div className="group-heading" title={title}>{label}</div>
      <div className="column-heading-container">
        {group.columns.map((column, idx) => {
          const style = {flex: column.flex || 1};
          const className = classNames("column-heading", column.className);
          const title = column.title || column.label;

          return <div key={idx} className={className} style={style} title={title}>{column.label}</div>;
        })}
      </div>
    </div>
  );
};

/*
 Transforms columns from a simple list (that is needed for SimpleTable to process data rows correctly)
 into an array of groups of columns where simple columns can be interleaved with column groups, e.g.
 [column, {groupLabel, columns: [column, ...]}, ...]

 Note: this function doesn't care if each group's columns are bunched together, but data cells will
 not line up with header cells correctly if this is not done.
 */
const transformColumns = ({columns}) => {
  const groupHash = {};
  const groupedColumns = [];

  columns.forEach((column) => {
    const groupLabel = column.group;

    // If there's a group field, we'll bundle the columns together. Otherwise it's a simple column.
    if (groupLabel) {
      if (groupHash[groupLabel]) {
        groupHash[groupLabel].push(column);
      } else {
        groupedColumns.push({
          groupLabel,
          columns: groupHash[groupLabel] = [column]
        });
      }
    } else {
      groupedColumns.push({column, columns: []});
    }
  });

  return groupedColumns;
};