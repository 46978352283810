import React, { Component } from 'react';
import { translateKey, formatDateMmYyyy } from 'common/util/common-formatters';
import QuartileTable from 'www/components/details/QuartileTable';
import NextInterestRateAdjustmentCeilingAndFloor from 'www/components/details/NextInterestRateAdjustmentCeilingAndFloor';
import NextInterestRateAdjustmentRateAndMargin from 'www/components/details/NextInterestRateAdjustmentRateAndMargin';
import NextInterestRateAdjustmentUpbAndCount from 'www/components/details/NextInterestRateAdjustmentUpbAndCount';
import DistributionTable from 'www/components/details/DistributionTable';
import ExtendedDistributionTable from 'www/components/details/ExtendedDistributionTable';
import { yesNoIndicator } from 'www/util/www-formatters';
import { isArmSecurity, isEsfPseudopool, isReperformingSecurity, isRPLModStepOrFixed ,isRPLModStep, isSingleSecurity, isInterestOnlyOrMixed} from 'www/util/www-helpers';
import { getLabels } from 'www/util/labels';
import ENUMS from  'www/util/enumFormatters';
import {compareDateLessThan, getPlatformDate} from "../../../common/util/common-helpers";


const {
  borrowerCount,
  channelType,
  daysDelinquentMonthCount,
  governmentInsured,
  loanModificationProgramType,
  loanModificationType,
  loanPurposeType,
  modificationInformation,
  mortgageInsurancePercentage,
  numberOfUnits,
  occupancyStatusType,
  propertyType,
  unavailableData,
  yesNoNotAvailableNotApplicableType,
  yesNoNotAvailableNotApplicableType2,
  propertyValuationMethodType,
  borrowerAssistancePlan,
  alternativeDelinquencyResolution,
  numberOfAlternativeDelinquencyResolutions,
  interestRateBuydownType,
  greenProgramType,
  greenBuildingCertificationType,
  energyRatingIndexType,
  renewableEnergyType
}  = ENUMS;

export default class MonthlyStatistics extends Component {

  UNSAFE_componentWillReceiveProps(nextProps, oldProps) {
    const { monthly, month, fetchMonthlyData, cusip } = nextProps;

    if (month && month !== oldProps.month && !monthly.data[month] && !monthly.isFetching && !monthly.hasError) {
      fetchMonthlyData(cusip, month);
    }
  }

  render() {
    const { details, currentOrIssuance, month } = this.props;
    const asOfDate = formatDateMmYyyy(month || details.data[currentOrIssuance].factorDt
                                            || details.data[currentOrIssuance].issueDt);

    return <MonthlyStats {...this.props} asOfDate={asOfDate} />;
  }

}

const MonthlyStats = (props) => {

  const { asOfDate, details, currentOrIssuance, month, monthly, settings } = props;
  const { issuance, current } = details.data;
  const secu = monthly.data[month] || details.data[currentOrIssuance];
  const isRpl = secu ? isReperformingSecurity(secu) : false;
  const isRplModStepFixed = secu ? isRPLModStepOrFixed(secu) : false;
  const isRplModStep = secu ? isRPLModStep(secu) : false;
  const isMbs = secu ? secu.secType === 'MBS' : false;
  const isEsfP = secu ? isEsfPseudopool(secu) : false;
  const stratLabels = getLabels('stratNames');
  const isissuance = currentOrIssuance === 'issuance';
  const hasNonStandardLoans = details.data && details.data.issuance && details.data.issuance.distributionsMap
    && details.data.issuance.distributionsMap.NonStandardLoanType && details.data.issuance.distributionsMap.NonStandardLoanType.length > 0;
  const {currentPlatformMonth, currentPlatformYear, currentPlatformDay} = settings;
  const platformDt = getPlatformDate(currentPlatformMonth, currentPlatformYear, currentPlatformDay);
  const hideGreenProgramStrat = compareDateLessThan(platformDt, greenprogramEffectiveDate) && compareDateLessThan(issuance.issueDt, greenprogramEffectiveDate);

  const hasQuartiles = (secu && secu.quartiles.length > 0) ? true : false;

  if (!isEsfP || hasQuartiles) {
    return (
        <div>
          <div className="subheading">{isEsfP ? 'Decile' : 'Quartile'} Distribution as of {asOfDate}</div>
          <QuartileTable {...props} />
          <ArmStrats {...props} asOfDate={asOfDate} />
          {isEsfP ? getEsfStrats({props, stratLabels,isissuance}) : getDefaultStrats({props, stratLabels, isRpl, isMbs, isRplModStep, isRplModStepFixed, isissuance, hasNonStandardLoans, hideGreenProgramStrat})}
        </div>
    );
  } else { return (<h1>No Data Available</h1>); }
};

function Strat(props) {
  if (props.visible === false) {
    return null;
  }

  const { title } = props;
  const detailProps = props.label === undefined ? { label: title, ...props} : props;
  return <DistributionTable {...detailProps} />;
}

const ArmStrats = (props) => {
  if (!isArmSecurity(props.details.data.issuance)) {
    return null
  }

  return (
    <div className="arm-strats">
      <div className="subheading">Next Interest Rate Adjustment Distribution</div>
      <NextInterestRateAdjustmentUpbAndCount {...props} />

      <div className="subheading">Next Interest Rate Adjustment Rates and Margins</div>
      <NextInterestRateAdjustmentRateAndMargin {...props} />

      <div className="subheading">Next Interest Rate Adjustment Ceilings and Floors</div>
      <NextInterestRateAdjustmentCeilingAndFloor {...props} />
    </div>
  );
};

function getDefaultStrats({props, stratLabels, isRpl, isMbs, isRplModStep, isRplModStepFixed, isissuance, hasNonStandardLoans, hideGreenProgramStrat}) {
  return (
    <div className="security-type-specific-strats">
      <div className="two-panel-flex">
        <div>
          <Strat {...props} title={stratLabels["UnavailableData"]} dataKey="UnavailableData" formatter={unavailableData} />
          <Strat {...props} title={stratLabels["OriginationLoanPurpose"]}  dataKey="OriginationLoanPurpose" formatter={loanPurposeType} visible={isRplModStepFixed} />
          <Strat {...props} title={stratLabels["LoanPurpose"]} dataKey="LoanPurpose" formatter={loanPurposeType} />
          <Strat {...props} title={stratLabels["OriginationOccupancyStatus"]} dataKey="OriginationOccupancyStatus" formatter={occupancyStatusType} label="Occ. Status" visible={isRplModStepFixed}/>
          <Strat {...props} title={stratLabels["OccupancyStatus"]} dataKey="OccupancyStatus" formatter={occupancyStatusType} label="Occ. Status" visible={!isRplModStepFixed}/>
          <Strat {...props} title={stratLabels["PropertyType"]} dataKey="PropertyType" formatter={propertyType}/>
          <Strat {...props} title={stratLabels["GreenProgramSummary"]} dataKey="GreenProgramSummary" formatter={greenProgramType} visible={!hideGreenProgramStrat} hideIfEmpty={true}/>
          <Strat {...props} title={stratLabels["GreenBuildingCertification"]} dataKey="GreenBuildingCertification" formatter={greenBuildingCertificationType} visible={!hideGreenProgramStrat} hideIfEmpty={true}/>
          <Strat {...props} title={stratLabels["EnergyRatingIndex"]} dataKey="EnergyRatingIndex" formatter={energyRatingIndexType} visible={!hideGreenProgramStrat} hideIfEmpty={true}/>
          <Strat {...props} title={stratLabels["RenewableEnergyType"]} dataKey="RenewableEnergyType" formatter={renewableEnergyType} visible={!hideGreenProgramStrat} hideIfEmpty={true}/>
          <Strat {...props} title={stratLabels["OriginationYear"]} dataKey="OriginationYear" label="Orig. Year"/>
          <Strat {...props} title={stratLabels["OriginationChannel"]} dataKey="OriginationChannel" formatter={channelType} visible={isRplModStepFixed}/>
          <Strat {...props} title={stratLabels["Channel"]} dataKey="Channel" formatter={channelType} visible={!isRplModStepFixed}/>
          <Strat {...props} title={stratLabels["MICancellationIndicator"]} dataKey="MICancellationIndicator" formatter={yesNoNotAvailableNotApplicableType}/>
          <Strat {...props} title={stratLabels["MICoverage"]} dataKey="MICoverage" formatter={mortgageInsurancePercentage}/>
       </div>
        <div>
          <Strat {...props} title={stratLabels["FirstTimeHomeBuyerIndicator"]} dataKey="FirstTimeHomeBuyerIndicator" formatter={yesNoNotAvailableNotApplicableType}/>
          <Strat {...props} title={stratLabels["NumberOfBorrowers"]} dataKey="NumberOfBorrowers" formatter={borrowerCount} label="Borrowers"/>
          <Strat {...props} title={stratLabels["NumberOfUnits"]} dataKey="NumberOfUnits" formatter={numberOfUnits}/>
          <Strat {...props} title={stratLabels["NonStandardLoanType"]} dataKey="NonStandardLoanType" formatter={yesNoNotAvailableNotApplicableType} visible={isMbs && isissuance && hasNonStandardLoans}/>
          <Strat {...props} title={stratLabels["GovernmentInsuredOrGuaranteed"]} dataKey="GovernmentInsuredOrGuaranteed" formatter={governmentInsured} label="Guarantor" hideIfEmpty={true}/>
          <Strat {...props} title={stratLabels["NotPayingPrincipalInFirstDistribution"]} dataKey="NotPayingPrincipalInFirstDistribution" formatter={yesNoNotAvailableNotApplicableType2} visible={isissuance}/>
          <Strat {...props} title={stratLabels["PropertyValuationMethodType"]} dataKey="PropertyValuationMethodType" formatter={propertyValuationMethodType} visible={pvmToggle}/>
          <Strat {...props} title={stratLabels["LoanSpecialProgramTypeCode"]} dataKey="LoanSpecialProgramTypeCode" formatter={getLabelInsteadValue}/>
          <Strat {...props} title={stratLabels["InterestRateBuydownType"]} dataKey="InterestRateBuydownType" formatter={interestRateBuydownType} />
          <Strat {...props} title={stratLabels["GeographicalIndicator"]} dataKey="GeographicalIndicator" label="State" formatter={getLabelInsteadValue}/>
          <Strat {...props} title={stratLabels["DaysDelinquentRange"]} dataKey="DaysDelinquentRange" formatter={getLabelInsteadValue} visible={!isissuance}/>
          <Strat {...props} title={stratLabels["BorrowerAssistancePlan"]} dataKey="BorrowerAssistancePlan" formatter={borrowerAssistancePlan} visible={!isissuance}/>
         </div>
      </div>
      
      <div>
        <Strat {...props} title={stratLabels["AlternativeDelinquencyResolution"]} dataKey="AlternativeDelinquencyResolution" formatter={alternativeDelinquencyResolution} visible={!isissuance} stratType='adr'/>
        <Strat {...props} title={stratLabels["NumberOfAlternativeDelinquencyResolutions"]} dataKey="NumberOfAlternativeDelinquencyResolutions" formatter={numberOfAlternativeDelinquencyResolutions} visible={!isissuance} stratType='adr'/>
      </div>

      <ExtendedDistributionTable
        {...props}
        title={stratLabels["SellerName"]}
        dataKey="SellerName"
        label="Seller Name"
        monthsToAmortizationVisible={false}
        cityStateVisible={slrSvrCityState}
        labelCity="SELLER CITY"
        labelState="SELLER STATE"
        keyFlex={2}
        formatter= {getLabelInsteadValue} />

      <ExtendedDistributionTable
        {...props}
        title={stratLabels["ServicerName"]}
        dataKey="ServicerName"
        label="Servicer Name"
        monthsToAmortizationVisible={false}
        cityStateVisible={slrSvrCityState}
        labelCity="SERVICER CITY"
        labelState="SERVICER STATE"
        keyFlex={2}
        formatter= {getLabelInsteadValue} />

      <ExtendedDistributionTable
        {...props}
        title={stratLabels["InterestOnlyFirstPrincipalAndInterestPaymentDate"]}
        dataKey="InterestOnlyFirstPrincipalAndInterestPaymentDate"
        label="Date"
        visible={(isArmSecurity || !isSingleSecurity) && isInterestOnlyOrMixed}
        doNotShowIfEmpty={true}
        monthsToAmortizationVisible={true} 
        cityStateVisible={false}
        labelCity=""
        labelState=""
        />

      <div className="two-panel-flex">
        <div>
          <Strat {...props} title={stratLabels["ModificationCount"]} dataKey="ModificationCount" visible={isRplModStepFixed} />
          <Strat {...props} title={stratLabels["ModificationType"]} dataKey="ModificationType" formatter={loanModificationType} visible={isRplModStepFixed} />
        </div>
        <div>
          <Strat {...props} title={stratLabels["ModificationProgram"]} dataKey="ModificationProgram" formatter={loanModificationProgramType} visible={isRplModStepFixed} />
        </div>
      </div>


    </div>
  );
}

function getEsfStrats({props, stratLabels, isissuance}) {
  return (
    <div className="security-type-specific-strats">
      <div className="two-panel-flex">
        <div>
          <Strat {...props} title={stratLabels["UnavailableData"]} dataKey="UnavailableData" formatter={unavailableData} />
          <Strat {...props} title={stratLabels["LoanPurpose"]} dataKey="LoanPurpose" formatter={loanPurposeType} />
          <Strat {...props} title={stratLabels["OccupancyStatus"]} dataKey="OccupancyStatus" formatter={occupancyStatusType} label="Occ. Status" />
          <Strat {...props} title={stratLabels["PropertyType"]} dataKey="PropertyType" formatter={propertyType} />
          <Strat {...props} title={stratLabels["GeographicalIndicator"]} dataKey="GeographicalIndicator" label="State" formatter={getLabelInsteadValue} />
        </div>
        <div>
          <Strat {...props} title={stratLabels["NumberOfBorrowers"]} dataKey="NumberOfBorrowers" formatter={borrowerCount} label="Borrowers" />
          <Strat {...props} title={stratLabels["FirstTimeHomeBuyerIndicator"]} dataKey="FirstTimeHomeBuyerIndicator" formatter={yesNoNotAvailableNotApplicableType} />
          <Strat {...props} title={stratLabels["NumberOfUnits"]} dataKey="NumberOfUnits" formatter={numberOfUnits} />
          <Strat {...props} title={stratLabels["Channel"]} dataKey="Channel" formatter={channelType} />
          <Strat {...props} title={stratLabels["PropertyValuationMethodType"]} dataKey="PropertyValuationMethodType" formatter={propertyValuationMethodType} visible={pvmToggle} />
          <Strat {...props} title={stratLabels["LoanSpecialProgramTypeCode"]} dataKey="LoanSpecialProgramTypeCode" formatter={getLabelInsteadValue} />
          <Strat {...props} title={stratLabels["InterestRateBuydownType"]} dataKey="InterestRateBuydownType" formatter={interestRateBuydownType} />
          <Strat {...props} title={stratLabels["DaysDelinquentRange"]} dataKey="DaysDelinquentRange" formatter={getLabelInsteadValue} visible={!isissuance} />
          <Strat {...props} title={stratLabels["BorrowerAssistancePlan"]} dataKey="BorrowerAssistancePlan" formatter={borrowerAssistancePlan} visible={!isissuance} />
        </div>
      </div>

      <div>
        <Strat {...props} title={stratLabels["AlternativeDelinquencyResolution"]} dataKey="AlternativeDelinquencyResolution" formatter={alternativeDelinquencyResolution} visible={!isissuance} stratType='adr' />
        <Strat {...props} title={stratLabels["NumberOfAlternativeDelinquencyResolutions"]} dataKey="NumberOfAlternativeDelinquencyResolutions" formatter={numberOfAlternativeDelinquencyResolutions} visible={!isissuance} stratType='adr' />
      </div>

      <ExtendedDistributionTable
        {...props}
        title={stratLabels["SellerName"]}
        dataKey="SellerName"
        label="Seller Name"
        monthsToAmortizationVisible={false}
        keyFlex={2}
        cityStateVisible={false}
        labelCity=""
        labelState=""
        formatter= {getLabelInsteadValue} />

      <ExtendedDistributionTable
        {...props}
        title={stratLabels["ServicerName"]}
        dataKey="ServicerName"
        label="Servicer Name"
        monthsToAmortizationVisible={false}
        keyFlex={2}
        cityStateVisible={false}
        labelCity=""
        labelState=""
        formatter= {getLabelInsteadValue} />

      <div className="two-panel-flex">
        <div>
          <Strat {...props} title={stratLabels["MICancellationIndicator"]} dataKey="MICancellationIndicator" formatter={yesNoNotAvailableNotApplicableType} />
        </div>
        <div>
          <Strat {...props} title={stratLabels["MICoverage"]} dataKey="MICoverage" formatter={mortgageInsurancePercentage} />
          <Strat {...props} title={stratLabels["GovernmentInsuredOrGuaranteed"]} dataKey="GovernmentInsuredOrGuaranteed" formatter={governmentInsured} label="Guarantor" hideIfEmpty={true}/>
          <Strat {...props} title={stratLabels["OriginationYear"]} dataKey="OriginationYear" label="Orig. Year" />
        </div>
      </div>
      
    </div>
  );
}

const getLabelInsteadValue = (value, column, row) => row.label;
