import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { throttle } from 'common/util/common-helpers';
import GroupedTable from 'common/table/GroupedTable';
import SimpleTable from 'common/table/SimpleTable';

export default class InfiniteTable extends Component  {
  constructor(props) {
    super(props);
    this.scrollListener = throttle(this.scrollListener.bind(this), 100);
    this.state = {page: 0, isLoadingMore: false};
  }

  componentDidMount() {
    this.attachScrollListener();
  }

  componentDidUpdate() {
    this.attachScrollListener();
  }

  UNSAFE_componentWillUnmount() {
    this.removeScrollListener();
  }

  _findElement() {
    return this.props.elementIsScrollable ? ReactDOM.findDOMNode(this) : window;
  }

  attachScrollListener =  () => {
    this.state.isLoadingMore = this.props.isLoadingMore;
    this.state.page = this.props.page;

    if (!this.props.loadMore) {
      return;
    }

    let el = this._findElement();
    el.addEventListener('scroll', this.scrollListener, true);
    el.addEventListener('resize', this.scrollListener, true);
    this.scrollListener(); //TODO: I think this is questionable behavior
  };

  removeScrollListener() {
    let el = this._findElement();
    el.removeEventListener('scroll', this.scrollListener, true);
    el.removeEventListener('resize', this.scrollListener, true);
  }

  _elScrollListener() {
    let el = ReactDOM.findDOMNode(this);
    let topScrollPos = el.scrollTop;
    let totalContainerHeight = el.scrollHeight;
    let containerFixedHeight = el.offsetHeight;
    let bottomScrollPos = topScrollPos + containerFixedHeight;

    return (totalContainerHeight - bottomScrollPos);
  }

  _windowScrollListener() {
    let el = ReactDOM.findDOMNode(this);
    let windowScrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
    let elTotalHeight = topPosition(el) + el.offsetHeight;
    return elTotalHeight - windowScrollTop - window.innerHeight;
  }

  scrollListener = () => {
    const { elementIsScrollable, threshold, loadMore } = this.props;

    if (this.state.isLoadingMore || !loadMore) {
      return;
    }

    const bottomPosition = elementIsScrollable ? this._elScrollListener() : this._windowScrollListener();
    if (bottomPosition < Number(threshold)) {
      this.state.isLoadingMore = true;
      this.removeScrollListener(); //Once it gets more data, we'll (maybe) reattach
      if (loadMore) {
        loadMore(++this.state.page);
      }
    }
  };

  render () {
    const { config, data, sort, isLoadingMore, showLoader, loader } = this.props;

    return (
      <div>
        {React.createElement(config.grouped ? GroupedTable : SimpleTable, this.props)}
        {(isLoadingMore && showLoader) ? loader : undefined}
      </div>
    );
  };
}

InfiniteTable.defaultProps = {
  elementIsScrollable: false,
  containerHeight: '100%',
  threshold: 100,
  isLoadingMore: false,
  loader: <div className="loading more"><i className="fa fa-spinner fa-pulse"/></div>,
  showLoader: true
};

const topPosition = (domEl) => domEl ? domEl.offsetTop + topPosition(domEl.offsetParent) : 0;