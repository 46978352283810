export const DETAILS_REQUEST = 'DETAILS_REQUEST';
export const DETAILS_RESPONSE = 'DETAILS_RESPONSE';
export const DETAILS_ERROR = 'DETAILS_ERROR';
export const DETAILS_SORT = 'DETAILS_SORT';
export const COLLATERAL_COUNT_REQUEST = 'COLLATERAL_COUNT_REQUEST';
export const COLLATERAL_COUNT_RESPONSE = 'COLLATERAL_COUNT_RESPONSE';
export const COLLATERAL_COUNT_ERROR = 'COLLATERAL_COUNT_ERROR';
export const ALLOCATION_REQUEST = 'ALLOCATION_REQUEST';
export const ALLOCATION_RESPONSE = 'ALLOCATION_RESPONSE';
export const ALLOCATION_ERROR = 'ALLOCATION_ERROR';
export const COLLATERAL_REQUEST = 'COLLATERAL_REQUEST';
export const COLLATERAL_RESPONSE = 'COLLATERAL_RESPONSE';
export const COLLATERAL_ERROR = 'COLLATERAL_ERROR';
export const LOAD_MORE_COLLATERAL_REQUEST = 'LOAD_MORE_COLLATERAL_REQUEST';
export const LOAD_MORE_COLLATERAL_RESPONSE = 'LOAD_MORE_COLLATERAL_RESPONSE';
export const DATA_FILES_REQUEST = 'DATA_FILES_REQUEST';
export const DATA_FILES_RESPONSE = 'DATA_FILES_RESPONSE';
export const DATA_FILES_ERROR = 'DATA_FILES_ERROR';
export const MONTHLY_DETAILS_REQUEST = 'MONTHLY_DETAILS_REQUEST';
export const MONTHLY_DETAILS_RESPONSE = 'MONTHLY_DETAILS_RESPONSE';
export const MONTHLY_DETAILS_ERROR = 'MONTHLY_DETAILS_ERROR';
export const TAX_REQUEST = 'TAX_REQUEST';
export const TAX_RESPONSE = 'TAX_RESPONSE';
export const TAX_ERROR = 'TAX_ERROR';
export const TAX_COUNT_REQUEST = 'TAX_COUNT_REQUEST';
export const TAX_COUNT_RESPONSE = 'TAX_COUNT_RESPONSE';
export const TAX_COUNT_ERROR = 'TAX_COUNT_ERROR';
export const TAX_SORT = 'TAX_SORT';
export const TAX_YEAR_SELECT = 'TAX_YEAR_SELECT';
export const SECURITY_DOCUMENTS_REQUEST = 'SECURITY_DOCUMENTS_REQUEST';
export const SECURITY_DOCUMENTS_RESPONSE = 'SECURITY_DOCUMENTS_RESPONSE';
export const SECURITY_DOCUMENTS_ERROR = 'SECURITY_DOCUMENTS_ERROR';
export const DETAILS_MAJORS_REQUEST = 'DETAILS_MAJORS_REQUEST';
export const DETAILS_MAJORS_RESPONSE = 'DETAILS_MAJORS_RESPONSE';
export const DETAILS_MAJORS_ERROR = 'DETAILS_MAJORS_ERROR';
export const DETAILS_PAGE_CHANGE_REQUEST = 'DETAILS_PAGE_CHANGE_REQUEST';

export function changePage(params, pageIdx, pageSize, sort) {
  return {
    type: DETAILS_PAGE_CHANGE_REQUEST,
    params: params,
    pageIdx,
    pageSize,
    sort
  };
}

export function getDetails(cusip) {
  return {
    type: DETAILS_REQUEST,
    cusip,
    id: cusip
  };
}

export function getAllocation(cusip, isRemic) {
  return {
    type: ALLOCATION_REQUEST,
    cusip,
    isRemic
  };
}

export function getCollateralCount(recordObject) {
  return {
    type: COLLATERAL_COUNT_REQUEST,
    recordObject
  };
}

export function getTaxCount(cusip) {  
  return {
    type: TAX_COUNT_REQUEST,
    cusip
  };
}

export function getCollateral(recordObject, pageIdx, pageSize, sort, factorDt) {
  return {
    type: COLLATERAL_REQUEST,
    recordObject,
    pageIdx,
    pageSize,
    sort,
    factorDt
  };
}

export function sortDetails(column, key) {
  return {
    type: DETAILS_SORT,
    key,
    field: column.key
  };
}

export function loadMoreCollateral(pageNumber) {
  return {
    type: LOAD_MORE_COLLATERAL_REQUEST,
    pageNumber
  }
}

export function getDataFiles({cusip, secId}) {
  return {
    type: DATA_FILES_REQUEST,
    cusip,
    secId
  };
}

export function fetchMonthlyData(cusip, month) {
  return {
    type: MONTHLY_DETAILS_REQUEST,
    cusip,
    month
  };
}

export function fetchTaxData(cusip,pageIdx, pageSize, sort) {
  return {
    type: TAX_REQUEST,
    cusip,
    pageIdx, 
    pageSize,    
    sort
  };
}

export function taxSort(column, securityIndex) {
  return {
    type: TAX_SORT,
    field: column.key,
    securityIndex
  }
}

export function taxYearSelect({year, securityIndex}) {
  return {
    type: TAX_YEAR_SELECT,
    year,
    securityIndex
  };
}

export function getSecurityDocuments(cusip) {
  return {
    type: SECURITY_DOCUMENTS_REQUEST,
    cusip
  };
}

export function getDetailsMajors(cusip) {
  return {
    type: DETAILS_MAJORS_REQUEST,
    cusip,
    id: cusip
  }
}

export const getDetailTaxFactCount = () => {0};
