import React, { Component } from 'react';

import { sortBy, SORT_DESCENDING } from 'common/util/common-helpers';
import { factor, rate, issuanceIndicator } from 'www/util/www-formatters';
import { isRemicSecurity  } from 'www/util/www-helpers';

import SimpleTable from 'common/table/SimpleTable';
import ButtonDropdown from 'common/button/ButtonDropdown';


export default class PoolFactorTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedYear: 0
        };
    }

    onSelectYear = (obj, index) => {
        this.setState({selectedYear: Number(obj.year)});
    };

    // TO get list of year pool factor data
  getYearList(poolFactorData) {
    let arrYearList = [];
    try {
      poolFactorData.map((pf) => {
        arrYearList.push(this.getYearFromYYYYMM(pf.factorDt));
      });
      // To get unique items only in year list
      arrYearList = arrYearList && arrYearList.length ? [...(new Set(arrYearList))] : arrYearList;
    } catch (e) {
      console.log(e);
      arrYearList = [];
    }
    return arrYearList;
  }

  // get year from partial date e.g. 2018-11
  getYearFromYYYYMM(partialDate) {
      let year = 0;
      try {
        if (partialDate && partialDate.length) {
          const tempArrDate = partialDate.split('-');
          year = tempArrDate && tempArrDate.length ? Number(tempArrDate[0]) : 0;
        }
      } catch (e) {
          console.log(e);
          year = 0;
      }
      return year;
  }

  // get current or latest year from year list
  getCurrentYearFromYearList(yearList) {
      var d = new Date();
      let currentYear = Number(d.getFullYear());
      try {
          if (yearList && yearList.length) {
              currentYear = Math.max(...yearList);
          }
      } catch (e) {
          console.log(e);
          currentYear = Number(d.getFullYear());
      }
      return currentYear;
  }

  render() {
    const { details, onHeaderClick } = this.props;
    const { data, sort } = details;
    const sortKey = 'current.runningFactorHist';
    const factorDt = details.data.issuance.factorDt;

    const gridConfig = {
      sortKey,
      className: 'pool-factor-table',
      columns: [
        { key: 'factorDt', label: 'Month', sortable: true, formatter: issuanceIndicator(factorDt), width: '150px' },
        { key: 'factor', label: 'Factor', formatter: factor, className: 'right', sortable: true }
      ],
      emptyText: 'No Data',
      columnEmptyText: '-'
    };

    if (!isRemicSecurity(details.data.issuance)) {
      gridConfig.columns.push(
        { key: 'poolPtr', label: 'WA Net Interest Rate', className: 'right', formatter: rate, sortable: true }
      );
    }
      let yearSelector = '';
          let gridData = data.current && data.current.cusip
                      ? data.current.runningFactorHist || []
                      : data.issuance.runningFactorHist || [];    
        let years = gridData && gridData.length ? this.getYearList(gridData) : [];
        years = sortBy([].concat(years), h => h, SORT_DESCENDING);
        const selectedYear = this.state && this.state.selectedYear > 0 ? this.state.selectedYear : years && years.length ? years[0] : 0;
        const yearOptions = years.map(year => ({
            text: year,
            year,
            onClick: this.onSelectYear,
            disabled: year === selectedYear
        }));
        yearSelector = (!isRemicSecurity(details.data.issuance)) ? <div className="deal-tranch-month-selector right">
                            Viewing pool factors for year:&nbsp;
                            <ButtonDropdown label={selectedYear} options={yearOptions}/>
                        </div> : <div className="deal-tranch-month-selector right">
                            Viewing security factors for year:&nbsp;
                            <ButtonDropdown label={selectedYear} options={yearOptions}/>
                        </div>;
        if (selectedYear > 0) {
            // To filter pool factor data based on selected year
            gridData = gridData.filter((data) => {
                const year = this.getYearFromYYYYMM(data.factorDt);
                return year === selectedYear;
            });
        }
        gridConfig.buttons = [ yearSelector ];

    gridData = sortBy([].concat(gridData), h => h.factorDt, SORT_DESCENDING);

    gridConfig.onHeaderClick = onHeaderClick;

    const gridProps = {
      config: gridConfig,
      data: gridData,
      sort: sort[sortKey]
    };
    return (<SimpleTable {...gridProps} />);
  }

}
