import React, { Component } from 'react';
import { push } from 'react-router-redux';

import SimpleTable from 'common/table/SimpleTable';
import { formatDateMmDdYyyy } from 'common/util/common-formatters';
import { documentDownload,  issuanceIndicator } from 'www/util/www-formatters';
import { toBoolean } from 'common/util/common-helpers';
import { isRemicSecurity, isFreddieSecurity } from 'www/util/www-helpers';
import { getLabels } from 'www/util/labels';

export default class DocumentsList extends Component {

  componentDidMount() {
    this.props.getSecurityDocuments(this.props.cusip);
  }

    redirectToDeal = (e) => {
        sessionStorage.setItem('tabIndex',this.props.tabIndex);
        const location = this.props.location;
        window.location.assign(window.location.origin + location.pathname);
        e.preventDefault();
    }

  render() {
  	const dealType = this.props.hasOwnProperty('deal') ? this.props.deal.dealType : [];
  	const issuer = this.props.hasOwnProperty('deal') ? this.props.deal.issuer : [];
    const { documents, message, hasError, isFetching } = this.props.documents;
    let hideDocumentTab = false;
    
    //Looks through each document and removes the S if it's in the filename
    if (documents.length > 0 && dealType.length > 0){
    	for(var i = 0; i < documents.length; i++){
    		var doc = documents[i];
    		var name = doc.document.name;
    		var tempName;
    		var zipIndex = name.indexOf('FC');
    		
    		if(zipIndex != -1 && issuer == 'FRE' ){
    			tempName = name.slice(0,zipIndex);
    			
    			if((dealType == 'SMBS' || dealType == 'ESF') && tempName.charAt(tempName.length - 1) == "S"){
    				documents[i].document.dName = name; 
    				documents[i].document.name = name.slice(0,zipIndex-1) + name.slice(zipIndex);
    			}
    		}
    	}
    }
    
    if (this.props.location && this.props.location.query && this.props.location.query.q && this.props.location.query.q.length) {
        hideDocumentTab = true;
    }
    
    if (isFetching && !hideDocumentTab) {
      return <div className="loading more"><i className="fa fa-spinner fa-pulse"/></div>;
    }

    if (hasError && !hideDocumentTab) {
      return <div className="empty-text">Unable to retrieve documents. {message}</div>;
    }


    const documentsConfig = {
      className: 'document-table',
      columns: [
        {key: 'document', label: 'Document', formatter: documentDownload },
        {key: 'correctionDocument', label: '', formatter: documentDownload }
      ]
    };

    return (
        <div>
            {!hideDocumentTab ?
                <div className="two-panel-flex">
                  <div>
                    <div className="subheading">Legal Documents</div>
                    <SimpleTable config={documentsConfig} data={documents}/>
                  </div>
                </div> :
                <div>
                  This particular view does not pertain to selective classes within a deal. Click the following link to reset the page to show the documents belonging to the entire deal
                  &nbsp;
                  <a href={'#'} onClick={(e) => this.redirectToDeal(e)}>Click Here</a>
                </div>
            }
        </div>
    );
  }
};
