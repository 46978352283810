import {
  DEAL_REQUEST, 
  DEAL_DOCUMENTS_REQUEST,
  DEAL_DOCUMENTS_RESPONSE,
  DEAL_DOCUMENTS_ERROR,
} from 'www/actions/dealActions';

import {
  sortBy,
  SORT_DESCENDING
} from 'common/util/common-helpers';

import { reducerGenerator } from 'common/util/redux';

const initialState = {
  issuanceDocuments: [],
  monthlyDocuments: [],
  isFetching: false,
  hasError: false,
  message: null
};

function onDealDocumentsResponse(state, action) {

  let {issuance:issuanceDocuments ,  monthly:monthlyDocuments} = action.documents;

  //will remove it once backend integration is done
  const tmpIssuanceDocuments = [{headingKey:"95_2_2021-001",
                                document:{id:"2089437",
                                          name:"FNM_2021-001_PAC",
                                          effectiveDate:"2021-01-26"
                                         }},
                                 {headingKey:"98_2_2021-001",
                                 document:{id:"2089439",
                                          name:"FNM_2021-001_SUPPLEMENT",
                                          effectiveDate:"2021-01-26"
                                          }}
                                                
                              ];

  const tmpmonthlyDocuments = [{headingKey:"111_2_2021-001",
                                document:{id:"2089440",
                                          name:"FNM_2021-001_Delinquency Report",
                                          effectiveDate:"2020-01-26"
                                          }},
                               {headingKey:"135_2_2021-001",
                                document:{id:"2089537",
                                          name:"FNM_2021-001_REMIC_LOAN_LEVEL",
                                          effectiveDate:"2021-01-26"   
                                          }},
                                {headingKey:"135_2_2021-001",
                                document:{id:"2089537",
                                        name:"FNM_2021-001_REMIC_LOAN_LEVEL",
                                        effectiveDate:"2020-02-26"   
                                          }},
                                {headingKey:"130_2_2021-001",
                                document:{id:"2089537",
                                      name:"FNM_2021-001_REMIC_DEAL_LEVEL",
                                      effectiveDate:"2019-03-26"   
                                          }},
                                {headingKey:"132_2_2021-001",
                                document:{id:"2089537",
                                    name:"FNM_2021-001_REMIC_SUMMARY",
                                    effectiveDate:"2021-04-26"   
                                }},
                                {headingKey:"131_2_2021-001",
                                document:{id:"2089537",
                                  name:"FNM_2021-001_REMIC_LOAN_LVEL_ZIP",
                                  effectiveDate:"2020-05-26"   
                              }}];

  //issuanceDocuments = tmpIssuanceDocuments;
  //issuanceDocuments = [];
  //monthlyDocuments = tmpmonthlyDocuments;

  sortBy(issuanceDocuments, doc => doc.document.effectiveDate, SORT_DESCENDING);
  sortBy(monthlyDocuments, doc => doc.document.effectiveDate, SORT_DESCENDING);                           
  return {
    ...initialState, 
    issuanceDocuments,
    monthlyDocuments
  };
}

export default reducerGenerator(initialState, {
  DEAL_REQUEST: (state, action) => ({...initialState}),
  DEAL_ISSUANCE_REQUEST: (state, action) => ({...initialState}),
  DEAL_DOCUMENTS_REQUEST: (state, action) => ({...initialState, isFetching: true}),
  DEAL_DOCUMENTS_RESPONSE: onDealDocumentsResponse,
  DEAL_DOCUMENTS_ERROR: (state, action) => ({
    ...state,
    hasError: true,
    isFetching: false,
    message: action.message || 'Unable to load documents. Please try again later.'
  })
});
