import React, {Component, useState} from 'react';

import { eventHandlerWithPrevent } from 'common/util/events';
import { multilineText } from 'common/util/common-helpers';

import { updateDocumentTitle } from 'www/util/www-helpers';


export default class Help extends Component {

  constructor(props){
    super(props)
    const initialState = {
      helpGuide: null
    };
    const settings = this.props || {};      
    const helpGuide = this.state || {};
    this.state = {
      currentHelpGuide:undefined
    }

  }

  state = {
    ...this.initialState
  }
  setDefaultState = () => {
    this.setState({ ...this.initialState });
  }


  UNSAFE_componentWillMount() {
    updateDocumentTitle('Help');
  }

  helpGuideSelect = (helpGuide) => {
    this.setState({helpGuide});
  };

  render() {

    const helpGuide = this.helpGuide;
    const helpGuides = this.helpGuides;
    const hasFetched = this.hasFetched;
    const urrentHelpGuide = null;

    const isActiveGuide = (helpGuide, idx) => {
      return currentHelpGuide === helpGuide || (currentHelpGuide === null && idx === 0);
    };
    const activeGuide = currentHelpGuide || helpGuides[0];
    const defaultBody = hasFetched ? <p>No help guides have been created.</p> : <p>Loading...</p>;

    return (
      <div className="content help">
        <header>
          <h1>Help</h1>
        </header>
        <aside>
          <ul>
            {helpGuides.map((helpGuide, idx) => (
              <li className={isActiveGuide(helpGuide, idx) && 'active'}>
                <a onClick={eventHandlerWithPrevent(this.helpGuideSelect, helpGuide)}>{helpGuide.title}</a>
              </li>
            ))}
          </ul>
        </aside>
        <main>
          {activeGuide ? multilineText(activeGuide.description) : defaultBody}
        </main>
      </div>
    );
  };
}
