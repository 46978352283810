import React, { Component } from 'react';

import Table, { TYPES } from 'common/table/Table';
import Customizable from 'common/table/features/Customizable';
import Paginator, { loadPageWithCall } from 'common/table/features/Paginator';
import ButtonDropdown from 'common/button/ButtonDropdown';

import {
  commaThousands,
  currencyWithCents,
  decimal,
  formatDateMmDdYyyy,
  formatDateMmYyyy,
  integer,
  checkIfZero
} from 'common/util/common-formatters';


import {
  cusipLink,
  notAvailableGenerator,
  rate,
  ltv,
  yesNoIndicator
} from 'www/util/www-formatters';

import {
  getOrgCode,
  isArmSecurity,
  isReperformingSecurity,
  isRPLModStepOrFixed,
  isRPLModStep,
  isSingleSecurity,
  isL1,
  isInterestOnly,
  getCollateralOptionSelector,
  paginate
} from 'www/util/www-helpers';

import { mapColumns } from 'www/util/labels';

import {
  DEFAULT_COLLATERAL_PAGE_SIZE,
  DEFAULT_INIT_PAGE
} from 'common/util/common-helpers';

import { getNewSort, sortArray } from 'common/util/sorter';

import ENUMS from 'www/util/enumFormatters';

const {
    amortizationType,
    channelType,
    governmentInsured,
    initialFixedRatePeriodType,
    daysDelinquentMonthCount,
    loanCorrectionType,
    loanModificationProgramType,
    loanModificationType,
    loanPurposeType,
    occupancyStatusType,
    propertyType,
    mortgageInsurancePercentage,
    mortgageInsurancePercentageForCollateral,
    propertyValuationMethodType,
    alternativeDelinquencyResolution,
    numberOfAlternativeDelinquencyResolutions,
    borrowerAssistancePlan
  } = ENUMS;
  

const ISSAUANCE_PLACEHOLDER='ISSUANCE';
const ONGOING_PLACEHOLDER='CURRENT';

export default class CollateralPanel extends Component {

  constructor(props, context) {
      super(props, context);

    this.gridConfig = {
      key: 'collateral',
      columnDefaults: {
        sortable: true
      },
      columns,
      columnEmptyText: '-',
      emptyText: {
        key: 'collateralText',
        text: 'No collateral available'
      }
    };
    this.factorDt = '1999-01-01';
    this.issueDt = '1999-01-01';
    this.compareDt = this.factorDt;
  }
  
  onOptionsSelect=(changedOption)=>{
    const { cusip, collateral,details } = this.props;

    if (changedOption === ONGOING_PLACEHOLDER)
      this.compareDt = this.factorDt;
    else
      this.compareDt = this.issueDt;

    if (changedOption !== collateral.selctedOption) {
      this.props.getCollateralCount({'cusip':cusip,'recordType':changedOption});
      this.props.getCollateral({'cusip':cusip,'recordType':changedOption}, DEFAULT_INIT_PAGE, DEFAULT_COLLATERAL_PAGE_SIZE, collateral.sort, this.compareDt );
    }
  }

  componentDidMount() {
    const { cusip, collateral, details } = this.props;
    let {selctedOption} = collateral;
    if (details && details.data && details.data.current && details.data.current.factorDt)
        this.factorDt = details.data.current.factorDt;
    if (details && details.data && details.data.issuance && details.data.issuance.issueDt)
        this.issueDt = details.data.issuance.issueDt;
    if(collateral.selctedOption=== undefined){
      if (details && details.data && details.data.current && details.data.current.discType && details.data.current.discType!='ISSUANCE'  )
        selctedOption=ONGOING_PLACEHOLDER;
       else
        selctedOption=ISSAUANCE_PLACEHOLDER;
    }

    if (selctedOption === ONGOING_PLACEHOLDER)
      this.compareDt = this.factorDt;
    else
      this.compareDt = this.issueDt;

    if (!collateral.data) {
      this.props.getCollateralCount({'cusip':cusip,'recordType':selctedOption});
      this.props.getCollateral({'cusip':cusip,'recordType':selctedOption}, DEFAULT_INIT_PAGE, DEFAULT_COLLATERAL_PAGE_SIZE, collateral.sort, this.compareDt);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let gridColumns = isSingleSecurity(nextProps.details.data.issuance) ? columns.filter(f => !f.hideForSingleSecurity) : columns;
    gridColumns =  !isInterestOnly(nextProps.details.data.issuance) ? gridColumns.filter(f => !f.hideForInterestOnly) : gridColumns;

    if (isArmSecurity(nextProps.details.data.issuance)) {
      gridColumns = gridColumns.concat(armColumns);
      if (!isInterestOnly(nextProps.details.data.issuance)) {
    		gridColumns = gridColumns.filter(f => !f.hideForInterestOnly);
      }
    }

    if (isReperformingSecurity(nextProps.details.data.issuance)) {
      gridColumns = gridColumns.concat(rplCommonColumns);
      if (isRPLModStepOrFixed(nextProps.details.data.issuance)) {
      gridColumns = gridColumns.concat(rplStepFixedColumns);
      if (isRPLModStep(nextProps.details.data.issuance)) {
      	gridColumns = gridColumns.concat(rplStepColumns);
      }
    }
    }else{
    	let nonRplgridColumns = isSingleSecurity(nextProps.details.data.issuance) ? nonRplCommonColumns.filter(f => !f.hideForSingleSecurity) : nonRplCommonColumns;
    	nonRplgridColumns =  !isInterestOnly(nextProps.details.data.issuance) ? nonRplgridColumns.filter(f => !f.hideForInterestOnly) : nonRplgridColumns;
    	gridColumns = gridColumns.concat(nonRplgridColumns);
    }
    if (!isRPLModStepOrFixed(nextProps.details.data.issuance)) {
    	gridColumns = gridColumns.concat(nonRplStepFixedColumns);   
    }
    this.gridConfig.columns = gridColumns;
  }

  render() {
    const { cusip, collateral, details, getCollateral, changePage} = this.props;
    let {selctedOption} = collateral;
    const {onOptionsSelect}=this;
    const { data, sort, isFetching, message, count, isFetchingMore } = collateral;
    const selectOptions = (details && details.data && details.data.current && details.data.current.discType && details.data.current.discType!='ISSUANCE') ? [ISSAUANCE_PLACEHOLDER, ONGOING_PLACEHOLDER] : [ISSAUANCE_PLACEHOLDER];

    if(collateral.selctedOption=== undefined){
      if (details && details.data && details.data.current && details.data.current.discType && details.data.current.discType!='ISSUANCE')
        selctedOption = ONGOING_PLACEHOLDER;
      else
        selctedOption = ISSAUANCE_PLACEHOLDER;
    }

    if (selctedOption === ONGOING_PLACEHOLDER)
      this.compareDt = this.factorDt;
    else
      this.compareDt = this.issueDt;

    if (message) {
      return <div className="collateral empty-text">{message}</div>;
    } else if (isFetching || !data) {
      return <div className="loading more"><i className="fa fa-spinner fa-pulse"/></div>;
    }


    const pageSize = details.pageSize ? details.pageSize : data.pageSize;
    const pageIdx = details.pageIdx ? details.pageIdx : data.pageIdx;
    const config = {
      className: 'collateral-table',
      title: count !== null ? <span>This security contains <strong>{commaThousands(count)} LOANS</strong> as collateral.</span> : null,
      customizingTitle: 'Customizing All Loan Level Collateral',
      table: {
        ...this.gridConfig,
        onHeaderClick: (column, sortKey) => {
          const newSort = getNewSort(sort, column.key, ((sort && sort.direction) || 'asc'));
          getCollateral({'cusip':cusip,'recordType':selctedOption}, DEFAULT_INIT_PAGE, pageSize, newSort, this.compareDt );
        }
      }
    };
    config.buttons = [ optionSelector() ];

    const recordType = ISSAUANCE_PLACEHOLDER === selctedOption ? ISSAUANCE_PLACEHOLDER : ONGOING_PLACEHOLDER
    let exportLink = `/api/security/${getOrgCode()}/${cusip}/collateral?export=true&recordType=${recordType}`;
    let exportAll = false
    let anonymousExport = false
    let overridingColumns = false
    if (isL1(details.data.issuance)) {
      exportAll = true
      // Older IE versions (as of IE11) have a bug where if a URL is too long, it will display a "Can't reach page"
      // error page while still downloads the file successfully.
      // So to keep the download URL short, columns is set to empty here and let the API default to all the columns
      // from Vendor file.
      overridingColumns = true
      exportLink += '&columns='
      //null out BAP for issuance
	    if(this.props.collateral != null && this.props.collateral.selctedOption == 'ISSUANCE') {
	    	if(this.props.collateral.data != null && this.props.collateral.data.collateral != null && this.props.collateral.data.collateral.length > 0) {
	    		 this.props.collateral.data.collateral =   this.props.collateral.data.collateral.map(function(item) { item.borrowerAssistancePlan = null; return item;});
	    	}
	    }
    }
    let exportName = '${details.data.issuane.secId}_${cusip}_COLLAT_${recordType}.txt'
    return (
      <Table type={TYPES.simple}
             features={[Customizable, Paginator]}
             {...this.props}
             config={config}
             data={data.collateral.map(col => ({...col, ...col.armFields, ...col.rplFields}))}
             sort={sort}
             threshold={50}
             count={count}
             isFetchingMore={isFetchingMore}
             pageIdx={pageIdx}
             pageSize={pageSize}
             pageViewName={'loans'}
             loadPage={(collateral.data && collateral.count) ? loadPageWithCall(changePage, {'cusip':cusip,'recordType':selctedOption}, pageSize)
                 : loadPageWithCall(getCollateral, {'cusip':cusip,'recordType':selctedOption}, pageSize, sort, this.compareDt)}
             exportAll={exportAll}
             anonymousExport={anonymousExport}
             overridingColumns={overridingColumns}
             exportLink={exportLink}
             exportName={exportName} />
    );
    function optionSelector(){
      const options=getCollateralOptionSelector(selctedOption,selectOptions, onOptionsSelect);
      return (
              <div className="deal-tranch-month-selector right">
                <ButtonDropdown {...options} />
              </div>
              );
    }
  };
  
}

let columns = [
  { key: 'loanSequenceNumber' },
  { key: 'loanCorrectionIndicator', formatter: loanCorrectionType, visible: false },
  { key: 'prefix', visible: false },
  { key: 'secId', visible: false },
  { key: 'cusip'},
  { key: 'mortgageLoanAmount', formatter: currencyWithCents, className: 'right', visible: false },
  { key: 'investorLoanUPBIssuance', formatter: currencyWithCents, className: 'right' },
  { key: 'investorLoanUPBCurrent', formatter: currencyWithCents, className: 'right' },
  { key: 'amortizationType', formatter: amortizationType },
  { key: 'interestRateOrigination', formatter: rate, className: 'right', visible: false },
  { key: 'interestRateIssuance', formatter: rate, className: 'right', visible: false },
  { key: 'interestRateCurrent', formatter: rate, className: 'right', visible: false },
  { key: 'netInterestRateIssuance', formatter: rate, className: 'right' },
  { key: 'netInterestRateCurrent', formatter: rate, className: 'right' },
  { key: 'firstPaymentDate', formatter: formatDateMmYyyy, visible: false },
  { key: 'maturityDate', formatter: formatDateMmYyyy },
  { key: 'loanTerm', formatter: integer, className: 'right' },
  { key: 'rmm', formatter: integer, className: 'right' },
  { key: 'lnAge', formatter: integer, className: 'right' },
  { key: 'dti', formatter: integer, className: 'right', visible: false },
  { key: 'numberofBorrowers', formatter: integer, className: 'right', visible: false },
  { key: 'firstTimeHomeBuyerIndicator', formatter: yesNoIndicator, visible: false },
  { key: 'loanPurpose', formatter: loanPurposeType, visible: false },
  { key: 'numberOfUnits', emptyText: 'N/A', formatter: integer, flex: 0.5, className: 'right', visible: false },
  { key: 'propertyType', formatter: propertyType, visible: false },
  { key: 'geographicalIndicator', visible: false },
  { key: 'seller' },
  { key: 'servicer' },
  { key: 'mortgageInsurancePercentage', emptyText: 'N/A', formatter: mortgageInsurancePercentageForCollateral, className: 'right', visible: false },
  { key: 'mortgageInsuranceCancellationIndicator', formatter: yesNoIndicator, visible: false },
  { key: 'governmentInsuredOrGuaranteed', formatter: governmentInsured, visible: false },
  { key: 'assumabilityIndicator', formatter: yesNoIndicator, visible: false },
  { key: 'interestOnlyLoanIndicator', formatter: yesNoIndicator, visible: false },
  { key: 'prepaymentPenaltyIndicator', formatter: yesNoIndicator, visible: false },
  { key: 'loanParticipationPct', formatter: ltv, visible: false   },
  { key: 'alternativeDelinquencyResolution',  formatter: alternativeDelinquencyResolution,  visible: false   },
  { key: 'numberOfAlternativeDelinquencyResolutions', visible: false   },
  { key: 'totalDeferralAmount', formatter: currencyWithCents, visible:false   },
  { key: 'borrowerAssistancePlan', formatter: borrowerAssistancePlan, visible: false   },
  { key: 'daysDelinquentMonthCount', formatter: daysDelinquentMonthCount, visible: false, labelKey: 'daysDelinquentMonthCountLabel' }
  ];

if (pvmToggle) {
  columns.push({ key: 'propertyValuationMethodType', formatter: propertyValuationMethodType, visible: false })
}
columns.push({ key: 'specialEligibilityProgramTypeText', visible: false })
if (slrSvrCityState) {
  columns.push({ key: 'sellerCity', visible: false }),
  columns.push({ key: 'sellerState', visible: false }),
  columns.push({ key: 'servicerCity', visible: false }),
  columns.push({ key: 'servicerState', visible: false }) 
}

mapColumns('l1-collateral', columns);
const nonRplStepFixedColumns = [
  { key: 'ltv', formatter: integer, className: 'right', visible: false },
  { key: 'cltv', formatter: integer, className: 'right', visible: false },
  { key: 'credScore', formatter: integer, className: 'right', visible: false },
  { key: 'occupancyStatus', formatter: occupancyStatusType },
  { key: 'channel', formatter: channelType, visible: false }
];

mapColumns('l1-non-rpl-step-fixed-collateral', nonRplStepFixedColumns);

const nonRplCommonColumns = [
  { key: 'interestOnlyFirstPrincipalAndInterestPaymentDate', formatter: formatDateMmYyyy, visible: false, hideForSingleSecurity: true, hideForInterestOnly:true },
  { key: 'monthsToAmortization', visible: false, hideForSingleSecurity: true, hideForInterestOnly:true },
  { key: 'prepaymentPenaltyTotalTerm', visible: false, hideForSingleSecurity: true }
];

mapColumns('l1-nonrpl-common-collateral', nonRplCommonColumns);
const armColumns = [
  { key: 'indexCode', visible: false },
  { key: 'mortgageMargin', formatter: rate, visible: false },
  { key: 'mbsMargin', formatter: rate, visible: false },
  { key: 'interestRateAdjustmentFrequency', visible: false },
  { key: 'interestRateLookBack', visible: false, formatter: integer },
  { key: 'interestRateRoundingMethod', visible: false },
  { key: 'interestRateRoundingPercentage', visible: false },
  { key: 'convertibilityIndicator', visible: false },
  { key: 'initialFixedPeriod', formatter: initialFixedRatePeriodType, visible: false },
  { key: 'nextInterestRateAdjustmentDate', formatter: formatDateMmYyyy, visible: false },
  { key: 'monthsToNextInterestRateAdjustmentDate', formatter: integer, visible: false },
  { key: 'lifeCeilingInterestRate', formatter: rate, visible: false },
  { key: 'lifeCeilingNetInterestRate', formatter: rate, visible: false },
  { key: 'lifeInterestRateFloor', formatter: rate, visible: false },
  { key: 'netLifeInterestRateFloor', formatter: rate, sortable: false, visible: false },
  { key: 'initialInterestRateCapUpPercentage', formatter: rate, visible: false },
  { key: 'initialInterestRateCapDownPercentage', formatter: rate, visible: false },
  { key: 'periodicInterestRateCapUpPercentage', formatter: rate, visible: false },
  { key: 'periodicInterestRateCapDownPercentage', formatter: rate, visible: false }
];

mapColumns('l1-arm-collateral', armColumns);

const rplStepFixedColumns = [
  
  { key: 'modificationProgramType', formatter: loanModificationProgramType, visible: false },
  { key: 'modificationType', formatter: loanModificationType, visible: false },
  { key: 'modificationCount', visible: false },
  { key: 'totalCapitalizedAmount', formatter: currencyWithCents, visible: false },
  { key: 'interestBearingMortgageLoanAmount', formatter: currencyWithCents, visible: false },
  { key: 'noIntBearingUPBForbearanceAmount', formatter: currencyWithCents, visible: false },
  { key: 'currentDeferredUPB', formatter: currencyWithCents, visible: false },
  { key: 'loanAgeAsOfModification', visible: false },
  { key: 'interestRateStepIndicator', formatter: yesNoIndicator, visible: false },
  { key: 'originationMortgageLoanAmount', formatter: currencyWithCents, visible: false },
  { key: 'originationInterestRate', formatter: rate, visible: false },
  { key: 'originationAmortizationType', formatter: amortizationType, visible: false },
  { key: 'originationInterestOnlyLoanIndicator', formatter: yesNoIndicator, visible: false },
  { key: 'originationFirstPaymentDate', formatter: formatDateMmYyyy, visible: false },
  { key: 'originationMaturityDate', formatter: formatDateMmYyyy, visible: false },
  { key: 'originationLoanTerm', visible: false },
  { key: 'originationLTV', formatter: notAvailableGenerator(999, rate), visible: false },
  { key: 'originationCLTV', formatter: notAvailableGenerator(999, rate), visible: false },
  { key: 'originationDTI', formatter: notAvailableGenerator(999, rate), visible: false },
  { key: 'originationCreditScore', formatter: notAvailableGenerator(9999, rate), visible: false },
  { key: 'originationLoanPurpose', formatter: loanPurposeType, visible: false },
  { key: 'originationOccupancyStatus', formatter: occupancyStatusType, visible: false },
  { key: 'originationChannel', formatter: channelType, visible: false } 
];

const rplStepColumns = [
  { key: 'initialStepFixedRatePeriod', formatter: initialFixedRatePeriodType, visible: false },
  { key: 'totalStepCount', visible: false },
  { key: 'remainingStepCount', visible: false },
  { key: 'nextStepRate', formatter: rate, visible: false },
  { key: 'terminalStepRate', formatter: rate, visible: false },
  { key: 'terminalStepDate', formatter: formatDateMmYyyy, visible: false },
  { key: 'stepRateAdjustmentFrequency', formatter: checkIfZero, visible: false },
  { key: 'nextStepRateAdjDate', formatter: formatDateMmYyyy, visible: false },
  { key: 'monthToNextStepRateAdjustmentDate', formatter: formatDateMmDdYyyy, visible: false }, 
  { key: 'periodicStepCapUpPercent', formatter: rate, visible: false }
 ];

const rplCommonColumns = [
  { key: 'estimatedLTV', formatter: notAvailableGenerator(999, rate), visible: false },
  { key: 'updatedCreditScore', formatter: notAvailableGenerator(9999, rate), visible: false },
  { key: 'loanPerformanceHistory', visible: false }
];

mapColumns('l1-rpl-step-fixed-collateral', rplStepFixedColumns);
mapColumns('l1-rpl-step-collateral', rplStepColumns);
mapColumns('l1-rpl-common-collateral', rplCommonColumns);
