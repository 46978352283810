import { DOCUMENTS_REQUEST, DOCUMENTS_RESPONSE,UNSUBSCRIBE_REQUEST, UNSUBSCRIBE_RESPONSE, UNSUBSCRIBE_ERROR } from 'www/actions/documentsActions';
import { CHECK_EMAIL_LANDING_REQUEST, CHECK_EMAIL_LANDING_RESPONSE, CHECK_EMAIL_LANDING_ERROR } from 'www/actions/documentsActions';

import { reducerGenerator } from 'common/util/redux';



const initialState = {
  hasError: false,
  message: '',
  hasFetched: false,
  isFetching: false,
  checkDocument: false,
  dates: {}
};

export default reducerGenerator(initialState, {
    DOCUMENTS_REQUEST: (state, action) => {
      return {...initialState, isFetching: true};
    },
    DOCUMENTS_RESPONSE: (state, action) => {
      return {availableDocuments: action.documents || [], hasError: false, hasFetched: true, isFetching: false};
    },
    DOCUMENTS_ERROR: (state, action) => {
      return {...initialState, message: action.message, hasError: true, hasFetched: true};
    },
    UNSUBSCRIBE_REQUEST: (state, action) => {
      return {...initialState, isFetching: true};
    },
    UNSUBSCRIBE_RESPONSE: (state, action) => {
      return {message: 'Unsubscribed', hasError: false, hasFetched: true, isFetching: false};
    },
    UNSUBSCRIBE_ERROR: (state, action) => {
      return {...initialState, message: 'Unsubscription is not Successful ', hasError: true, hasFetched: true};
    },
    CHECK_EMAIL_LANDING_REQUEST: (state, action) => {
      return {...initialState, isFetching: true};
    },
    CHECK_EMAIL_LANDING_RESPONSE: (state, action) => {
      return { isValidDoc: action.combinedRes.get("isValidDoc"), isSubscribed: action.combinedRes.get("isSubscribed"), checkDocument: true, hasError: false, hasFetched: true, isFetching: false};
    },
    CHECK_EMAIL_LANDING_ERROR: (state, action) => {
      return {...initialState, message: 'Error checking document and subscription ', checkDocument: true, hasError: true, hasFetched: true};
    },

});

