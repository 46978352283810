import React, { Component } from 'react';
import classNames from 'classnames';

import { eventHandlerWithPrevent } from 'common/util/events';

export default class TabPanel extends Component {

  constructor(props) {
    super(props);
    this.state = { tabActive: props.tabActive || 1 };
  }

  UNSAFE_componentWillReceiveProps(newProps){
    if(newProps.tabActive && newProps.tabActive !== this.props.tabActive){
      this.setState({tabActive: newProps.tabActive});
    }
  }


  componentDidMount(){
    this.setState({tabActive: this.props.tabActive || 1});
  }
  render() {
    const className = classNames('tabs', this.props.className);

    return (
      <div className={className}>
        {this._getMenuItems()}
        {this._getSelectedPanel()}
      </div>
    );
  }

  setActive = (index) => {
    let onAfterChange = this.props.onAfterChange,
      $selectedPanel = this.refs['tab-panel'],
      $selectedTabMenu = this.refs[`tab-menu-${index}`];

    const defaultPageSize = this.props.children[index - 1].key === 'collateral' ? 100 : 50;
    this.setState({ tabActive: index }, () => {
      this.props.changePage('', 1, defaultPageSize);
      if (onAfterChange) {
        onAfterChange(index, $selectedPanel, $selectedTabMenu);
      }
    });
  };

  _getMenuItems() {
    let { children } = this.props;

    if (!children) {
      throw new Error('Tabs must contain at least one Panel');
    }

    if (!Array.isArray(children)) {
      children = [children];
    }

    let $menuItems = children
      .map(($panel) => typeof $panel === 'function' ? $panel() : $panel)
      .filter(($panel) => $panel)
      .map(($panel, index) => {
        const ref = `tab-menu-${index + 1}`;
        const title = $panel.props ? $panel.props.tabTitle : $panel.key;
        const classes = classNames(
          'tabs-menu-item',
          {'active': this.state.tabActive === (index + 1)}
        );

        return (
          <li ref={ref} key={index} className={classes}>
            <a href="#" onClick={eventHandlerWithPrevent(this.setActive, index + 1)}>{title}</a>
          </li>
        );
      });
     
    return (
      <nav className="tabs-navigation">
        <ul className="tabs-menu">{$menuItems}</ul>
      </nav>
    );
  }

  _getSelectedPanel() {
    let index = this.state.tabActive - 1;
    let $panel = this.props.children[index];

    return (
      <article ref="tab-panel" className="tab-panel">{$panel}</article>
    );
  }

}
