import React, { Component } from 'react';

import Recaptcha  from 'www/util/Recaptcha';
import { emailRegexString} from 'common/util/regexes';


const initialState = {
  email: '',
  validationMessage: '',
  password: '',
  forgotPwdClicked: false,
  forgotPwdLbl: 'Forgot Password?',
  captchaVerified: false,
  previousAttempt: false
};

export default class LoginForm extends Component {
  state = initialState;

	onEmailKeyUp = (e) => {
	    this.state.email = e.target.value;

	    const validationMessage = this.emailInput.checkValidity() ? '' : 'Please enter a valid email address';
	    this.setState({validationMessage});

	};

	registerEmailInput = (ref) => {
	    this.emailInput = ref;
	};

  onClickForgotPwdFn =()=> {
    const { onForgotPassword} = this.props;
    if(this.state.captchaVerified){
    	const validCaptchaMessage ='';
    	this.setState({validCaptchaMessage});
    	if (this.state.email && !this.state.validationMessage) {
      		this.setState({
        		forgotPwdClicked: !this.state.forgotPwdClicked,
        		forgotPwdLbl: ''
      		});
      		onForgotPassword({...this.state});
    	} else {
	    	const validationMessage = 'Please enter a valid email address';
	    	this.setState({validationMessage});
    	}
    }else{
    	const validCaptchaMessage='Ensure CAPTCHA check is completed before clicking on reset password link';
    	this.setState({validCaptchaMessage});
    }
  }

  onLogin = () => {
    const { onLogin, email } = this.props;

    if (this.state.email && !this.state.validationMessage && this.state.password) {
      onLogin({...this.state});
      this.setState({previousAttempt:true});
    }
  };

  onPasswordKeyUp = (e) => {
    this.state.password = e.target.value;

    if (e.keyCode == 13) { // Enter key
      this.onLogin();
    }
  };
  
  expiredDiscCaptchaCallback= (response) => {
      this.setState({captchaVerified:false,captchaResponse:''});
   };
  
  verifyDiscCaptchaCallback =  (response) => {
       this.setState({captchaVerified:true,captchaResponse:response});
   };

  onCaptchaReset = () => {
      this.setState({previousAttempt:false});
  }

  render() {
    const { isFetching, onClose, onRegister } = this.props;
    const { forgotPwdClicked, forgotPwdLbl, captchaVerified, validationMessage, validCaptchaMessage } = this.state ;
    const message = this.props.message || this.state.message;
     const eMailMessage = (this.state.forgotPwdClicked) ? 'An email has been sent to reset the password for your MBS Disclosure account. Click the link within the email to reset your password.' : '' ;
     
    return (
      <div className="modal register-form">
        <div className="close-button" onClick={onClose}><i className="fa fa-close"/></div>
        <div className="title">Sign In</div>
        <div className="description">
          Type in your registered email address and password below. If you have forgotten your password, enter email address and click "Forgot Password".
        </div>
        <div className="form">
          <input type="email" autoFocus placeholder="Email" onKeyUp={this.onEmailKeyUp} ref={this.registerEmailInput}
          pattern={emailRegexString}/>

          <input type="password" className="margin-above" placeholder="Password" onKeyUp={this.onPasswordKeyUp} />
          <div className="error-message">{validationMessage || message} </div>

          <button onClick={this.onLogin} disabled={!captchaVerified}>
            {isFetching ? (<i className="fa fa-spinner fa-spin"/>) : 'Login'}
          </button>
          
          <Recaptcha verifyCallback={this.verifyDiscCaptchaCallback} expiredCallback = {this.expiredDiscCaptchaCallback} sitekey={recaptchaTrackId} elementID={"disclosuresCaptcha"} render={"explicit"} hl={"en"}
                     previousAttempt={this.state.previousAttempt} onResetCaptcha={this.onCaptchaReset} />
        </div>
          <div>
          <a href="#" onClick={onRegister} className="forgot-password-link">Create New Account.</a><br></br>
          <a href="#" onClick={this.onClickForgotPwdFn}  className="forgot-password-link">{this.state.forgotPwdLbl}</a>
          <div className="error-message">{validCaptchaMessage} </div>
          </div>
      </div>
    );
  }
}