import React, { Component } from 'react';

export default class MessageForm extends Component {

  render() {
    const { onLogin, onClose, message } = this.props;

    return (
        <div className="modal message-form">
          <div className="close-button" onClick={onClose}><i className="fa fa-close"/></div>
          <div className="description">{message}</div>
          <div className="form">
            <button onClick={onLogin}>Login/Register</button>
          </div>
        </div>
    );
  }
}