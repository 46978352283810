import React, { Component } from 'react';
import {ChangePassword} from 'www/components/account/ChangePassword';
import { connect } from 'react-redux';
import { close } from 'common/login/loginActions';
import { onResetPassword } from 'www/actions/accountActions';
import ConfirmModal from 'common/ConfirmModal';
import OkModal from 'common/OkModal';
import { getOrganizationFromLocation } from 'common/util/common-helpers';

import {loadBanners} from 'www/actions/bannerAction';
import { getSettings  } from 'www/actions/settingsActions';
import { isLoggedIn } from 'common/login/loginActions';
import {fetchInitialData} from "www/util/www-helpers";

const initialState = {    
    resetPwdClicked: false,
    onConfirmResetPwd: false,
    resetPwdSuccess: false
};

class ResetPassword extends Component {
  state = initialState;
  constructor(props, context) {
    super(props, context);
    this.navhistory=props.history;
    this.state = initialState;
  }
  componentDidMount() {
    fetchInitialData(this.props);
  }
  
 
  takeToHome = () => {
    var logoutLocation = window.location.protocol + "//" + window.location.host + "/" + getOrganizationFromLocation();
    window.location = logoutLocation;
  };


  onConfirmSuccessResetPwdFn =() => {
    this.takeToHome();
  };

    onCancelResetPwd = () => {
        var delayInMilliseconds = 10;
    
        this.setState({resetPwdClicked: !this.state.resetPwdClicked, onConfirmResetPwd:false});
        var localnavhistory = this.navhistory;
        this.navhistory.goBack();
        setTimeout(function() {
  			
          localnavhistory.go(0);
          //var lastLocation = window.location.href;
          //window.location = lastLocation;
        
		}, delayInMilliseconds);
        
        
    };

    onClickForgotPwdFn =()=> {
        const { onForgotPassword, email } = this.props;
        this.setState({
            resetPwdClicked: !this.state.resetPwdClicked
            
        });

    this.state.onConfirmResetPwd ? onForgotPassword({...this.state, email}): '';
  }

  onConfirmForgotPwdFn =() => {
    this.onResetPassword();
  }

  onResetPassword = () => {
    this.props.onResetPassword(this.props.account.profile.email);
    this.setState({...this.state, resetPwdClicked: true, resetPwdSuccess: true});
    
  };

  render() {
  
	  if(this.state.resetPwdSuccess) {
	    return (
	        <div>
	          <div>
	            <OkModal title="Reset Password"
	              text={`An email will be sent to reset the password for your MBS Disclosure account. Click the link within the email to reset your password..` }
	              onOk={this.onConfirmSuccessResetPwdFn}
	              onCancel={this.onConfirmSuccessResetPwdFn}
	            /> </div> : <div> </div>}            
	        </div>
	    );
	
	  } else if(!this.state.resetPwdClicked) {
	    return (
	        <div>
	          <div>
	            <ConfirmModal title="Reset Password"
	              text={`Please confirm that you would like to reset your password. An email will be sent to reset the password for your MBS Disclosure account. Click the link within the email to reset your password.` }
	              onOk={this.onConfirmForgotPwdFn}
	              onCancel={this.onCancelResetPwd}
	            /> </div> : <div> </div>}            
	        </div>
	    );
	  }
  }
}


export default connect(
  ({login, account, routing, settings, globalBanners}) => ({ loginState: login, account, routing, settings, globalBanners }),
  { close, onResetPassword, getSettings, isLoggedIn, loadBanners}
)(ResetPassword);