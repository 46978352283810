import React, {Component} from 'react';
import classNames from 'classnames';
import {withRouter} from 'react-router-dom';
import AdvancedSearch from 'www/components/AdvancedSearch';
import {pad2} from 'common/util/common-formatters';
import {getOrganizationFromLocation, nativeInputValid, toDate} from 'common/util/common-helpers';
import {securityType} from 'www/util/www-formatters';

import {factorOrBlank, moneyOrBlank, rateOrBlank} from 'common/util/regexes';

import {switchByOrganization, getSecurityTypeFromLabel} from 'www/util/www-helpers';

const JANUARY = 1;
const DECEMBER = 12;
const MIN_YEAR = 1975;
const MAX_YEAR = 2060;

const initialState = {
  q: '',
  advanced: {
    pool: '',
    trust: '',
    issrSpclSecuType: '',
    prefix: '',
    issuanceFrom: '',
    issuanceTo: '',
    maturityFrom: '',
    maturityTo: '',
    issuanceFromMonth: JANUARY,
    issuanceFromYear: 'SELECT',
    issuanceToMonth: DECEMBER,
    issuanceToYear: 'SELECT',
    maturityFromMonth: JANUARY,
    maturityFromYear: 'SELECT',
    maturityToMonth: DECEMBER,
    maturityToYear: 'SELECT',
    status: '',
    netRateTo: '',
    netRateFrom: '',
    factorFrom: '',
    factorTo: '',
    upbFrom: '',
    upbTo: '',
    q: '',
    currentPlatformMonth: '',
    currentPlatformYear: ''
  },
  invalid: {},
  hintsVisible: false,
  advancedVisible: false,
  organization:''
};

const toExcludeFromQuery = [
  'issuanceFromMonth',
  'issuanceFromYear',
  'issuanceToMonth',
  'issuanceToYear',
  'maturityFromMonth',
  'maturityFromYear',
  'maturityToMonth',
  'maturityToYear'
].reduce((toExclude, val) => {
    toExclude[val] = true;
    return toExclude;
  }, {});

class SearchBox extends Component {

  state = JSON.parse(JSON.stringify(initialState));



  
  componentDidMount(){
    const organization = getOrganizationFromLocation()
     this.setState({organization});
     const{settings} =this.props;
     const{currentPlatformYear} = settings;
     console.log("hopefully the current Year is: "+ currentPlatformYear);
  }

  UNSAFE_componentWillMount() {
    const { routing } = this.props;
    // this.state.advancedVisible = !!(routing.locationBeforeTransitions.query && routing.locationBeforeTransitions.query.advanced);
    this.state.advancedVisible = false;
  }

  hasSearchCriteria() {
    const { q, advanced } = this.state;
    let hasCriteria = false;

    if (q) {
      return true;
    }

    Object.keys(advanced).map(function(key) {
      if (advanced[q] && advanced['q'] !== '' ||  advanced[key]) {
        hasCriteria = true;
      }
    });

    return hasCriteria;
  }

  handleInputChange = (e) => {
    let { name, value } = e.target;
    if (name === 'q') {
      this.setState({q: value});
    } else {
      if (name === 'advancedQuery') {
        name = 'q';
      }

      this.setState({advanced: {...this.state.advanced, [name]: value}});
    }
    if (e && e.keyCode === 13) { // Enter key
      this.onSearch();
    }
  }
    handleInputChangeSelect = (from, to) => {
      this.setState({advanced: {...this.state.advanced, [from.name]: from.value, [to.name]: to.value}});
    }
  
  handleFocusChange = (e) => {
    this.validate();
  };

  prepSearchParams = ({advanced, advancedVisible, q}) => {
    if (!advancedVisible) {
      return {q};
    }

    const params = {};

    // fix for lack of IE support for Object.entries and apparently Object.keys
    for (const key in advanced) {
      if (advanced.hasOwnProperty(key)) {
        if (toExcludeFromQuery[key]) {
          continue;
        }

        params[key] = advanced[key];
      }

    }

    [{field: 'issuanceFrom', formatter: formatMonthDayYear}, {field: 'issuanceTo', formatter: formatMonthDayYear},
      {field: 'maturityFrom', formatter: formatMonthYear}, {field: 'maturityTo', formatter: formatMonthYear}]
      .forEach(({field, formatter}) => {
      const selectedDate = {
        month: advanced[field + 'Month'],
        year: advanced[field + 'Year']
      };
      if (selectedDate.year!='Select' && (initialState.advanced[field + 'Month'] !== selectedDate.month
           || initialState.advanced[field + 'Year'] !== selectedDate.year)) {
        params[field] = formatter(selectedDate.month, selectedDate.year);
      }
      if (selectedDate.year == 'Select'){
        params[field] = initialState.advanced[field];
      }
    });

    if(params['issrSpclSecuType']){
      params['issrSpclSecuType'] = securityType(params['issrSpclSecuType']);
    }

    return params;
  };

  onSearch = (e) => {
    const { state, props } = this;
    if (!this.validate()) {
      return;
    }
    const searchParams = this.prepSearchParams(state);
    props.search(searchParams, props.history);
    this.setState({...initialState, advancedVisible: false});
  };

  validate = () => {
    const { state } = this;

    const newInvalid = {};

    let isValid = true; 

    if (state.advancedVisible) {
      const {
        factorFrom, factorTo,
        netRateFrom, netRateTo,
        upbFrom, upbTo,
        q
        } = state.advanced;

      if (!factorOrBlank.test(factorFrom) ) {
        newInvalid['factorFrom'] = true;
        newInvalid['factorError'] = true;
        isValid = false;
      }

      if (!factorOrBlank.test(factorTo) ) {
        newInvalid['factorTo'] = true;
        newInvalid['factorError'] = true;
        isValid = false;
      }

      if (!rateOrBlank.test(netRateFrom) ) {
        newInvalid['netRateFrom'] = true;
        newInvalid['netRateError'] = true;
        isValid = false;
      }

      if (!rateOrBlank.test(netRateTo) ) {
        newInvalid['netRateTo'] = true;
        newInvalid['netRateError'] = true;
        isValid = false;
      }

      if (!moneyOrBlank.test(upbFrom) || !nativeInputValid('upbFrom')) {
        newInvalid['upbFrom'] = true;
        newInvalid['upbError'] = true;
        isValid = false;
      }

      if (!moneyOrBlank.test(upbTo) || !nativeInputValid('upbTo')) {
      newInvalid['upbTo'] = true;
        newInvalid['upbError'] = true;
        isValid = false;
      }

      if (q === undefined) {
        console.log('Not advanced and no basic criteria specified');      
        newInvalid['q'] = true;
        isValid = false;
      }
    
    } else if (state.q === undefined) {
      console.log('Not advanced and no basic criteria specified');      
      newInvalid['q'] = true;
      isValid = false;
    }

    if (!this.hasSearchCriteria()) {
      console.log('No search criteria entered.');
      isValid = false;
    }

    this.setState({invalid: newInvalid});
    return isValid;
  };

  onClear = () => {
    this.setState({...initialState, advancedVisible: true});
  };

  onCancel = () => {
    this.setState({...initialState, advancedVisible: false});
  };

  toggleAdvancedPanel = (e) => {
    let urlParams = new URLSearchParams(this.props.location.search);
    // Take all relevant request parameters and apply them to the state
    this.state.advanced = {};

    Object.keys(initialState.advanced).forEach(param => {
      if (param === 'issrSpclSecuType') {
        const securityTypeLocal = getSecurityTypeFromLabel(urlParams.get(param));
        this.state.advanced[param] = securityTypeLocal || initialState.advanced[param];
      } else {
        this.state.advanced[param] = urlParams.get(param) || initialState.advanced[param];
      }
    });

    ['issuanceFrom', 'issuanceTo', 'maturityFrom', 'maturityTo'].forEach(field => {
      const date = toDate(urlParams.get(field));
      if (date) {
        this.state.advanced[field + 'Month'] = date.getMonth() + 1;
        this.state.advanced[field + 'Year'] =  date.getFullYear();
      }
    });

    // Clear out the basic search and make advanced form visible
    this.setState({q: '', hintsVisible: false, advancedVisible: !this.state.advancedVisible});
  };

  onSearchFocus = () => {
    if (!this.state.advancedVisible) {
      this.setState({hintsVisible: true});
    }
  };

  onSearchBlur = () => {
    this.setState({hintsVisible: false});
  };

  render() {
    const { settings, loggedIn } = this.props;
    // const { params } = this.props.match;
    const { advancedVisible, advanced, hintsVisible, invalid } = this.state;
    const { onSearchFocus, onSearchBlur } = this;

    let { q } = this.state;

    if (advancedVisible) {
      q = advanced.q;
    }
  
    let classes = classNames(
      'search-box',
      this.organization,
      { 'advanced-visible': advancedVisible }
    );

    let searchClasses = classNames("search", {'logged-in': loggedIn})

    const searchPlaceHolder = switchByOrganization('CUSIP, Security Identifier or Trust Identifier',
                                                     'CUSIP, Security Identifier, Trust Identifier or Series Number');
    return (
      <div className={classes}>
        <AdvancedSearch {...advanced}
                        yearRange={{min: MIN_YEAR, max: MAX_YEAR}}
                        statuses={settings.statuses}
                        currentPlatformYear={settings.currentPlatformYear}
                        searchParams={advanced}
                        handleInputChangeSelect={this.handleInputChangeSelect}
                        onInputChange={this.handleInputChange}
                        onFocusChange={this.handleFocusChange}
                        onSearch={this.onSearch}
                        onClear={this.onClear}
                        onCancel={this.onCancel}
                        hasSearchCriteria={this.hasSearchCriteria()}
                        invalidFields={invalid} 
                        searchPlaceHolder={searchPlaceHolder}
                        q={q}/>
        
        <SearchHints visible={hintsVisible} />

        <input className={searchClasses}
               value={q} 
               name="q"
               disabled={advancedVisible}
               onKeyUp={this.handleInputChange} 
               onChange={this.handleInputChange}
               onFocus={onSearchFocus}
               onBlur={onSearchBlur}
               placeholder={searchPlaceHolder} />

        <i className="fa fa-cogs advanced" onClick={this.toggleAdvancedPanel} title="Toggle Advanced Search" tabIndex="0" />
        <button className="btn btn-primary" onClick={this.onSearch}><i className="fa fa-search" /></button>
      </div>
    );
  }

}


function SearchHints({visible}) {
  if (!visible) {
    return null;
  }

  const trustNumberExamples = switchByOrganization('2006-012 or TR000425', '000366, 004907');
  const trustNumberClassExamples = switchByOrganization('2006-012/BZ or TR000425/IO9', '000366/PO, 004907/FA');
  
  
  const trustOrSeriesIdentifierExamples = switchByOrganization('3140GN2A0', '3132DMBQ7');
  const securitiyIdentifierExamples = switchByOrganization('BH0768', 'SD0047');
  
  const trustOrSeriesName = switchByOrganization('Trust Identifier', 'Series Identifier');
  

  return (
    <div className="search-hints">
      <div className="search-hint-title">Examples</div>
      <div className="search-hint"><span className="search-hint-example">CUSIP:</span><span className="search-hint-text">{trustOrSeriesIdentifierExamples}</span></div>
      <div className="search-hint"><span className="search-hint-example">Security Identifier:</span><span className="search-hint-text">{securitiyIdentifierExamples}</span></div>
      <div className="search-hint"><span className="search-hint-example">{trustOrSeriesName}:</span><span className="search-hint-text">{trustNumberExamples}</span></div>
      <div className="search-hint"><span className="search-hint-example">{trustOrSeriesName}/Class:</span><span className="search-hint-text">{trustNumberClassExamples}</span></div>
      <div className="search-hint-title"></div>
      <div className="search-hint"><span className="search-hint-example">An asterisk (*) can be utilized to enable partial matches.</span></div>
    </div>
  );
}

function formatMonthYear(month, year) {
  return  pad2(month) + '/' + year;
}

function formatMonthDayYear(month, year) {
  return  pad2(month) + '/01/' + year;
}

export default withRouter(SearchBox);