import React, { Component } from 'react';
import { emailRegexString} from 'common/util/regexes';


const initialState = {
  email: '',
  validationMessage: ''
};

export default class StepOneForm extends Component {
  state = initialState;

  onCheckEmail = () => {
    if (this.state.email && !this.state.validationMessage) {
      this.props.onCheckEmail({...this.state});
    }
  };

  onEmailKeyUp = (e) => {
    this.state.email = e.target.value;

    const validationMessage = this.emailInput.checkValidity() ? '' : 'Please enter a valid email address';
    this.setState({validationMessage});

    if (e.keyCode == 13) { // Enter key
      this.onCheckEmail();
    }
  };

  registerEmailInput = (ref) => {
    this.emailInput = ref;
  };

  render() {
    const { isFetching, onClose, message } = this.props;
    const { validationMessage } = this.state;

    return (
      <div className="modal step-one-form">
        <div className="close-button" onClick={onClose}><i className="fa fa-close"/></div>
        <div className="description">
          Please Enter your email address below. If you have an account we will prompt you to enter your password. If you
          do not have an account we will prompt you to create one.
        </div>
        <div className="form">
          <input type="email" autoFocus
                 onKeyUp={this.onEmailKeyUp}
                 ref={this.registerEmailInput}
                 pattern={emailRegexString}/>
          <button onClick={this.onCheckEmail}>
            {isFetching ? (<i className="fa fa-spinner fa-spin"/>) : 'continue'}
          </button>
          <div className="error-message">{validationMessage || message}</div>
        </div>
      </div>
    );
  }
}