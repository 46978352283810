import React from 'react';

import { currency } from 'common/util/common-formatters';

import { factor, rate, corrected } from 'www/util/www-formatters';
import { isRemicSecurity } from 'www/util/www-helpers';

export default ({cusip, offPlatformRedirects = {}}) => {
  return (
    <div className="details-header">
      <div className="cusip">{cusip || '---'}<label>CUSIP</label></div>
      <div className="off-platform-nav">
        <a href={offPlatformRedirects.disclosureRedirectURLDescription}>View security disclosure information<i className="fa fa-external-link-square" /></a>
      </div>
    </div>
  );
};
