import React from 'react'
import FannieAnnouncement from './fannieAnnouncement'
import { Link } from 'react-router-dom'

const FannieAnnouncements = function (props) {
  const { fannieResources, fannieArchiveResources } = props
  const { params } = props.match
  const recent = fannieResources.managedResourceDataList || []
  const archived = fannieArchiveResources.managedResourceDataList || []

  return (
    <div className="fnm-announce-boxes">
      <div className="fnm-single-panel-flex">
        <section className="fnm-news">
          <h1 className="section-heading section-text-alignment">Recent Announcements: </h1>
          {recent.length > 0 ? <ul>{recent.map((obj) => (<FannieAnnouncement {...obj} key={obj.id}/>))}</ul>
            : <h3>No announcements are available for this period</h3>}
          <div className="centered-link"><Link to={{
            pathname: `/${params.organization}/announcements`,
            state: { fannieArchiveResources: archived }
          }}><u>Announcements Archive</u></Link></div>
        </section>
      </div>
    </div>

  )
}
export default FannieAnnouncements