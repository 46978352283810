import {
    DETAILS_REQUEST, DETAILS_RESPONSE, DETAILS_ERROR, DETAILS_SORT, DETAILS_PAGE_CHANGE_REQUEST
} from 'www/actions/detailsActions';

import {isEmpty, max, not, sortBy, trim} from 'common/util/common-helpers';
import {reducerGenerator} from 'common/util/redux';
import {transformStrats} from 'www/util/www-helpers';
import {onSortArray, onSortWrappedArray, applyInitialSortWrapped} from 'common/util/sorter';
import {updatePvm} from 'common/util/common-helpers';

const initialState = {
    message: null,
    data: null,
    hasError: false,
    isFetching: false,
    sort: {}
};

const stratRegExp = /distributionsMap/;

function onSortDetails(state, action) {
    const {key} = action;

    let results, sort;

    if (stratRegExp.test(key)) {
        const stratKey = key.substring(key.lastIndexOf('.') + 1);
        const issuanceKey = `issuance.distributionsMap.${stratKey}`;
        const currentKey = `current.distributionsMap.${stratKey}`;

        results = onSortWrappedArray(state.data, state.sort[issuanceKey], {...action, key: issuanceKey});
        sort = {...state.sort, [issuanceKey]: results.newSort};

        results = onSortWrappedArray(results.sortedWrapper, state.sort[currentKey], {...action, key: currentKey});
        sort = {...sort, [currentKey]: results.newSort};
    } else {
        results = onSortWrappedArray(state.data, state.sort[key], action);
        sort = {...state.sort, [key]: results.newSort};
    }

    return {...state, sort, data: results.sortedWrapper};
}

function applyDetailsSortDefaults(currSort, details) {
    const newSort = {};

    const {issuance, current} = details;

    if (current && current.distributionsMap) {
        Object.keys(current.distributionsMap).map((key) => {
            newSort[`current.distributionsMap.${key}`] = {
                field: 'percentageInvestorLoanUPB',
                direction: 'desc'
            }
        });
    }

    if (issuance && issuance.distributionsMap) {
        Object.keys(issuance.distributionsMap).map((key) => {
            newSort[`issuance.distributionsMap.${key}`] = {
                field: 'percentageInvestorLoanUPB',
                direction: 'desc'
            }
        });
    }

    return {...newSort, ...currSort};
}


function onDetailsResponse(state, action) {
    let data = action.details || {};
    let sort = applyDetailsSortDefaults(state.sort, data);

    var enableDealLink = false;
    if (data.issuance) {
        data.issuance.prefix = trim(data.issuance.prefix);
        enableDealLink = data.issuance.issuer !== 'FNM' || data.issuance.platformInd !== '3';
        data.issuance.enableDealLink = enableDealLink;
        if (data.issuance.status === 'Dissolved' && data.issuance.dissolvedInMonthOfIssuance === false) {
            data.issuance.status = 'Active';
        }
        let issueDt = data.issuance.issueDt;
        let newDate = pvmPnewDate;
        let pvmKey = 'issuance.distributionsMap.PropertyValuationMethodType';
        updatePvm(issueDt, newDate, pvmKey,data)
    } else {
        console.warn('Missing Issuance value for Security Details, is this expected?')
    }

    if (data.current) {
        data.current.prefix = trim(data.current.prefix);
        data.current.enableDealLink = enableDealLink;
        if (data.current.status === 'PaidOff') {
            data.issuance.status = 'Active';
        }
        let factorDt = data.current.factorDt;
        let newDate = pvmPnewDate;
        let pvmKey = 'current.distributionsMap.PropertyValuationMethodType';
        updatePvm(factorDt, newDate, pvmKey,data)
    }

    Object.keys(sort).forEach((key) => {
        let {sortedWrapper, newSort} = applyInitialSortWrapped(data, key, sort[key]);
        data = sortedWrapper;
        sort[key] = newSort;
    });

    // Easier to extract the field note from here, rather than adjust elsewhere to expect object rather than string.
    // US10219: display 1.1.1 issuance note from issuance coreSec instead when there is currently no available 1.16.1 narrative for current cusip yet
    // Notice that DS web server returns data value in the format {"issuance":{"key":"value","issuanceNotes":"simple string note of 1.1.1 or 1.16.1 narrative text associated to security up to final state"},"current":{},"issuanceNotes":{"note":"simple note string of latest 1.16.1 narrative text associated to security after final state."},"ongoingNotes":{"note":"simple ongoing note string of 1.16.2 banner text for ongoing security"}}
    if (data.issuanceNotes) { // there is 1.16.1 narrative
        data.issuanceNotes = data.issuanceNotes.note;
    } else if (data.issuance && data.issuance.issuanceNotes) { // no 1.16.1 narrative but there is 1.1.1 onboarding note
        data.issuanceNotes = data.issuance.issuanceNotes; // notice that the note inside data.issuance is a string, not an object like data.issuanceNote
    }
    ;

    if (data.ongoingNotes) {
        data.ongoingNotes = data.ongoingNotes.note;
    }

    return {
        ...initialState,
        data,
        sort
    };
}

export default reducerGenerator(initialState, {
    DETAILS_REQUEST: (state, action) => ({...initialState, isFetching: true}),
    DETAILS_RESPONSE: onDetailsResponse,
    DETAILS_ERROR: (state, action) => ({...initialState, message: action.message, hasError: true}),
    DETAILS_SORT: onSortDetails,
    DETAILS_PAGE_CHANGE_REQUEST: (state, action) => ({...state, isFetching: false, pageIdx: action.pageIdx, pageSize: action.pageSize}),
});
