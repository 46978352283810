import React from 'react'

const FannieAnnouncement = function (props) {
  const { link, title, description, creationDate } = props
  const announcementLink = link && title ? <a href={link}><u>{title}</u></a> : ''
  const announcementDescription = description ? <span>{`: ${description}`}</span> : ''
  const date = new Date(creationDate)
  const day = date.toLocaleString('en-US', {  day: 'numeric',timeZone: 'UTC' })
  const month = date.toLocaleString('en-US', { month: 'long', timeZone: 'UTC' })

  return (<li>
    <div className="announce-container">
      <div style={{width:'1%', float:'left', textAlign:'center', height:'100%;', minWidth:'6em'}}>
        <span>{month} {day}</span>
      </div>
      <div style={{width:'1%', float:'center', textAlign:'center', height:'100%;', minWidth:'3em'}}>
        <span> - </span>
      </div>
      <div style={{width:'98%', float:'right', textAlign:'left', height:'100%'}}>
        <span>{announcementLink}{announcementDescription}</span>
      </div>
    </div>
  </li>)
}
export default FannieAnnouncement