import {
  DETAILS_REQUEST,
  DETAILS_RESPONSE,
  DETAILS_ERROR,
  COLLATERAL_COUNT_REQUEST,
  COLLATERAL_COUNT_RESPONSE,
  COLLATERAL_COUNT_ERROR,
  COLLATERAL_REQUEST,
  COLLATERAL_RESPONSE,
  COLLATERAL_ERROR,
  LOAD_MORE_COLLATERAL_REQUEST,
  LOAD_MORE_COLLATERAL_RESPONSE,
  ALLOCATION_REQUEST,
  ALLOCATION_RESPONSE,
  ALLOCATION_ERROR,
  DATA_FILES_REQUEST,
  DATA_FILES_RESPONSE,
  DATA_FILES_ERROR,
  MONTHLY_DETAILS_REQUEST,
  MONTHLY_DETAILS_RESPONSE,
  MONTHLY_DETAILS_ERROR,
  TAX_REQUEST,
  TAX_RESPONSE,
  TAX_ERROR,
  TAX_COUNT_REQUEST,
  TAX_COUNT_RESPONSE,
  TAX_COUNT_ERROR,
  SECURITY_DOCUMENTS_REQUEST,
  SECURITY_DOCUMENTS_RESPONSE,
  SECURITY_DOCUMENTS_ERROR,
  DETAILS_MAJORS_REQUEST,
  DETAILS_MAJORS_RESPONSE,
  DETAILS_MAJORS_ERROR,
  DETAILS_PAGE_CHANGE_REQUEST
} from 'www/actions/detailsActions';
import { reduceMiddleware } from 'common/util/redux';
import { getOrganizationCodeFromLocation, sortIntByKey, paginate } from 'www/util/www-helpers';

import API from 'common/util/api';
import * as _ from 'lodash';

function detailsRequest(getState, next, action) {
  API.get(`security/${getOrganizationCodeFromLocation()}/${action.cusip}`).then(
    response => next({...action, type: DETAILS_RESPONSE, details: response}),
    message => next({...action, type: DETAILS_ERROR, message})
  );
}

function collateralCountRequest(getState, next, action) {
  const{recordObject}= action;
  const endpoint =`security/${getOrganizationCodeFromLocation()}/${recordObject.cusip}/collateral/count`;
  let query =`recordType=${recordObject.recordType}`;
  API.get(`${endpoint}?${query}`).then(
    response => next({...action, type: COLLATERAL_COUNT_RESPONSE, count: response.count}),
    message => next({...action, type: COLLATERAL_COUNT_ERROR, message})
  );
}

function TaxCountRequest(getState, next, action) {  
  API.get(`tax/${getOrganizationCodeFromLocation()}/cusip/${action.cusip}/count`).then(
    response => next({...action, type: TAX_COUNT_RESPONSE, count: response.count}),
    message => next({...action, type: TAX_COUNT_ERROR, message})
  );
}

function collateralRequest(getState, next, action) {
  const { pageIdx, pageSize, sort, recordObject, factorDt } = action;

  const endpoint = `security/${getOrganizationCodeFromLocation()}/${recordObject.cusip}/collateral`;
  let query = `recordType=${recordObject.recordType}`;
  if (sort) { // sort.field && sort.direction)
    const {field, direction} = sort;
    query += `&sortField=${field}&sortAsc=${direction === 'asc'}`;
  }

  return API.get(`${endpoint}?${query}`).then(
    response => {
        next({...action, type: COLLATERAL_RESPONSE, payload: response, factorDt})
    },
    message => next({...action, type: COLLATERAL_ERROR, message})
  );
}

function allocationRequest(getState, next, action) {
  if (window.location.search === '?up') {
    setTimeout(()=> next({...action, type: ALLOCATION_RESPONSE, payload: SAMPLE_UPWARD_ALLOCATION_SECURITY}));
    return;
  }

  API.get(`security/${getOrganizationCodeFromLocation()}/${action.cusip}/parent_summaries`, {securityIsL3: action.isRemic}).then(
    response => next({...action, type: ALLOCATION_RESPONSE, payload: response}),
    message => next({...action, type: ALLOCATION_ERROR, message})
  );
}

function loadMoreCollateralRequest(getState, next, {page}){
  //Artificial delay to provide feedback
  setTimeout(() => next({type: LOAD_MORE_COLLATERAL_RESPONSE, page}), 250);
}

function dataFilesRequest(getState, next, {cusip, secId}) {
  API.get(`security/${getOrganizationCodeFromLocation()}/dates/${cusip}`).then(
    response => next({type: DATA_FILES_RESPONSE, results: response, cusip, secId}),
    message => next({type: DATA_FILES_ERROR, message})
  );
}

function monthlyDetailsRequest(getState, next, {cusip, month}) {
  API.get(`security/${getOrganizationCodeFromLocation()}/${cusip}/${month}`).then(
    response => next({type: MONTHLY_DETAILS_RESPONSE, results: response, cusip, month}),
    message => next({type: MONTHLY_DETAILS_ERROR, message})
  );
}

function taxRequest(getState, next, action) {
  
  const { cusip, pageIdx, pageSize,sort } = action;  
  const endpoint = `tax/${getOrganizationCodeFromLocation()}/${cusip}/tax`;
  
  let query = `page=${pageIdx}&max_results=${pageSize}`;
  if (sort) { // sort.field && sort.direction)
    const {field, direction} = sort;
    query += `&sortField=${field}&sortAsc=${direction === 'asc'}`;
  }
  
  if (window.location.search === '?mockCombo') {
    setTimeout(() => next(
      {
        type: TAX_RESPONSE, 
        results: taxThirteenMonths(SAMPLE_TAX_RECOMBINABLE_DATA),
        cusip,
        pageSize
        
      })); 

    return;
  }
  
  //regualr Security
  if (window.location.search === '?mockReg') {
    setTimeout(() => next(
      {
        type: TAX_RESPONSE, 
        results: taxThirteenMonths(SAMPLE_TAX_REGULAR_DATA),
        cusip,
        pageSize
      }));

    return;
  }
  
  API.get(`${endpoint}?${query}`).then(  
    response => {
      next({...action, type: TAX_RESPONSE, results: taxThirteenMonths(response), cusip});
    },
    message => {
      next({...action, type: TAX_ERROR, message: null, data: []});
    }
  )
}



/**
 Tax data comes in with 12 months of data per year. For some unknown reason
 we want to always display January of the following year with the previous year
 as if each year is 13 months.
 */
function taxThirteenMonths(taxContainer) {
    if (taxContainer == undefined || taxContainer.data == undefined) {
        return taxContainer;
    }
    const formateedTaxContainer = sortByMonths(taxContainer);
    formateedTaxContainer.data.forEach(taxThirteenMonthsForSecTax);


    return formateedTaxContainer;

    function taxThirteenMonthsForSecTax(secTax) {
        Object.keys(secTax).forEach(year => taxThirteenMonthsImpl(year, secTax));
    }

    function taxThirteenMonthsImpl(year, secTax) {

        // assume that if we have a next year,
        // then we have december this year already
        // and next year starts with january

        const nextYear = secTax[parseInt(year) + 1];
        if (nextYear == undefined || nextYear.length === 0) {
            return;
        }

        const thisYear = secTax[year];

        thisYear.push(nextYear[0]);
    }

    function sortByMonths(taxContainer) {
        const formattedTaxContainer = taxContainer;
      if(taxContainer && taxContainer.data && taxContainer.data.length) {
          const taxData  = taxContainer.data[0];
          const keys = Object.keys(taxData);
          if (keys && keys.length) {
              for (let i=0; i<keys.length; i++) {
                  taxData[keys[i]].sort(sortIntByKey('taxMonth'))
              }
          }
          formattedTaxContainer.data[0] = taxData;
      }

      return formattedTaxContainer;
    }
}

function securityDocumentsRequest(getState, next, action) {
  
  if (window.location.search == "?mock") {
    setTimeout(() => next({
      ...action, 
      type: SECURITY_DOCUMENTS_RESPONSE, 
      documents: _.cloneDeep(SAMPLE_SECURITY_DOCUMENTS)
    }));
    return;
  }
  
  API.get(`security/${getOrganizationCodeFromLocation()}/${action.cusip}/documents`).then(
    response => next({...action, type: SECURITY_DOCUMENTS_RESPONSE, documents: response}),
    message => next({...action, type: SECURITY_DOCUMENTS_ERROR, message})
  );
}


function detailsMajorsRequest(getState, next, {cusip}) {
  API.get(`security/${getOrganizationCodeFromLocation()}/${cusip}/majors`).then(
    results => next({type: DETAILS_MAJORS_RESPONSE, results, cusip}),
    message => next({type: DETAILS_MAJORS_ERROR, message})
  )
}

function pageChangeRequest(getState, next, action) {
    next({...action});
}

export default reduceMiddleware({
  [ALLOCATION_REQUEST]: allocationRequest,
  [DETAILS_REQUEST]: detailsRequest,
  [COLLATERAL_COUNT_REQUEST]: collateralCountRequest,
  [COLLATERAL_REQUEST]: collateralRequest,
  [LOAD_MORE_COLLATERAL_REQUEST]: loadMoreCollateralRequest,
  [DATA_FILES_REQUEST]: dataFilesRequest,
  [MONTHLY_DETAILS_REQUEST]: monthlyDetailsRequest,
  [TAX_REQUEST]: taxRequest,  
  [SECURITY_DOCUMENTS_REQUEST]: securityDocumentsRequest,
  [DETAILS_MAJORS_REQUEST]: detailsMajorsRequest,
  [TAX_COUNT_REQUEST]: TaxCountRequest,
    [DETAILS_PAGE_CHANGE_REQUEST]: pageChangeRequest,
});


const SAMPLE_SECURITY_DOCUMENTS = [
 {
 "headingKey" : "GNMA_REMIC_DELAY_FLOATERS",
 "document" : {
     "id": "9875",
     "name": "file-22.pdf",
     "effectiveDate" : "2017-04-22"
     },
 "correctionDocument" : {
     "id": "1234",
     "name": "file-22--corrected-25.pdf",
     "effectiveDate" : "2017-04-25"
     }
 },
 {
   "headingKey" : "GNMA_REMIC_DELAY_FLOATERS",
   "document" : {
       "id": "9875",
       "name": "file-24.xls",
       "effectiveDate" : "2017-04-24"
     }
 },
 {
   "headingKey" : "GNMA_REMIC_DELAY_FLOATERS",
   "document" : {
       "id": "9875",
       "name": "file-21.doc",
       "effectiveDate" : "2017-04-21"
     }
 },
 {
   "headingKey" : "GNMA_REMIC_DELAY_FLOATERS",
   "document" : {
       "id": "9875",
       "name": "file-22.docx",
       "effectiveDate" : "2017-04-22"
    }
 },

];

const SAMPLE_UPWARD_ALLOCATION_SECURITY =
{ "parent_summaries": [
      {
        "prefix": "P1",
        "cusip": "AKTSR15R3",
        "issuer": "FNM",
        "contribUpbIss": 300,
        "secId": "SECUID1",
        "secType": "SCR",
        "netRate": 4.675,
        "issueDt": "2010-04-01",
        "maturity": "2050-05-01",
        "pctAllocated": 30,
        "pctContributed": 30,
        "upbIss": 7500
      },
      {
        "prefix": "P2",
        "cusip": "FQVDDZ956",
        "issuer": "FNM",
        "contribUpbIss": 100,
        "secId": "SECUID2",
        "secType": "SCR",
        "netRate": 3.892,
        "issueDt": "2014-12-01",
        "maturity": "2054-01-01",
        "pctAllocated": 10,
        "pctContributed": 10,
        "upbIss": 8900
      },
      {
        "prefix": "P3",
        "cusip": "96QKJTDX3",
        "issuer": "FNM",
        "contribUpbIss": 200,
        "secId": "SECUID3",
        "secType": "REMIC",
        "netRate": 5.125,
        "issueDt": "2016-09-01",
        "maturity": "2056-10-01",
        "pctAllocated": 20,
        "pctContributed": 20,
        "upbIss": 85930000
      }
    ]
};

const SAMPLE_TAX_RECOMBINABLE_DATA = {
};


function CREATE_MULTI_YEAR_MOCK_TAX() {

  /*
  {
    "data": {
      "2012": [
        { ...  },
        { ...  },
        { ...  },
      ]
    },
    "footerText": [
      "The above amounts are reported on a cash basis.",
      "* These amounts are expressed in $1000 units. They are determined as gross amount\/(original principal or notional amount\/1000).",
      "The Treasury regulations carry special reporting concerns for both the nominees and holders of interest in Widely Held Fixed Investment Trust (WHFIT) securities.",
      "Please consult a tax professional and refer to applicable IRS guidance for additional information.",
      "This letter is for information only and is not tax advice."
    ]
  }
  */

  var data = CREATE_MULTI_YEAR_MOCK_DATA();


  var tax = {factorsResponse: true,
    url: 'http://taxfactors.efanniemae.com/taxfactors/search.htm',
    data: data, "footerText": [
      "The above amounts are reported on a cash basis.",
      "* These amounts are expressed in $1000 units. They are determined as gross amount\/(original principal or notional amount\/1000).",
      "The Treasury regulations carry special reporting concerns for both the nominees and holders of interest in Widely Held Fixed Investment Trust (WHFIT) securities.",
      "Please consult a tax professional and refer to applicable IRS guidance for additional information.",
      "This letter is for information only and is not tax advice."
    ]};

  return tax;


  function twoDigits(x) {
      return x < 10 ? '0' + x : x.toString();
  }
}

function CREATE_MULTI_YEAR_MOCK_DATA(startYear = 2012) {

  /*
    {
    "2012": [
      { ...  },
      { ...  },
      { ...  },
    }
  */

  var data = {};

  for(var y=startYear; y<2017; y++) {
      
      var months = [];

      for (var m=0; m<12; m++) {
          months.push({
              "issuer": "FRE",
              "secId": "SecId_" + m,
              "classId": "ClassId_" + m,
              "trustId": "TrustId_" + m,
              "cusip": "3138M12Y3",
              "reportingPeriod": parseInt(y.toString() + twoDigits(m)),
              "issueAmount": 0,
              "interestAccrualMethod": "Actual\/Actual",
              "taxYear": y,
              "taxMonth": m + 1,
              "taxQuarter": Math.floor( (m+1) / 3),
              "accrualPeriodDays": new Date(y, m + 1, -1).getDate(),
              "accrualPeriodStartDate": new Date(y, m, 1),
              "accrualPeriodEndDate": new Date(y, m+1, -1),
              "taxPaymentDate": new Date(y, m+1, 14),              
              "endUpbAmount": Math.round(y * 6.5 + m * 0.4 * 100) / 100,
              "qsiFactor": 1 - m / 1000,
              "oidFactor": 1 - m / 1005,
              "otherIncomeExpenseFactor": 1 - m / 1013,
              "sec212Factor": 1 - m / 1017,
              "beginningAipFactor": 1 - m / 1023,
              "mdar": 1 - m / 1029,
              "realEstatePct": 98 + m / 20,
              "category": "SINGLE_CLASS",
              "updateTimeStamp": new Date()
          });
      }

      data[y] = months;
  }

  data[startYear].splice(0, 3);

  return data;


  function twoDigits(x) {
      return x < 10 ? '0' + x : x.toString();
  }
}
