import React from 'react';
const FannieArchivedAnnouncement = function (props){
    const {title, link, description, creationDate} = props.record;
    const date = new Date(creationDate)
    const day = date.toLocaleString('en-US', {  day: 'numeric',timeZone: 'UTC' })
    const month = date.toLocaleString('en-US', { month: 'long', timeZone: 'UTC' })
    const year = date.toLocaleString('en-US', { year: 'numeric', timeZone: 'UTC' })
    const announcementLink = link && title? <a style = {{fontSize:'medium', textAlign: 'left' }} href={link}>{title}</a> : '';
    const announcementDescription = description? <p style={{margin:0,padding:0,textAlign:'left',fontSize: 'medium'}}>{description}</p> : ''

    return (<li style={{display:'flex', marginTop:'0.5em', padding:0}}>
        <div style={{display: 'flex', width:'100%'}}>
            <div style={{width:'1%', float:'left', textAlign:'center', height:'100%;', minWidth:'11em'}}>
            <span >{`${month} ${day}, ${year}`}</span>
        </div>
        <div style={{width:'98%', float:'right', textAlign:'left', height:'100%;'}}>
            {announcementLink}
            {announcementDescription}
        </div>
        </div>
    </li>);
}
export default FannieArchivedAnnouncement;