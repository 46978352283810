import React, { Component } from 'react';

import ConfirmModal from 'common/ConfirmModal';

export default class extends Component {
  render() {
    const { account } = this.props;

    let confirm = null;
    
    if (account.deleteVisible) {
      confirm = <ConfirmModal title="Delete Custom View" text={account.message} onOk={() => account.okAction()} onCancel={() => account.cancelAction() }/>;
    }

    if (account.copyVisible) {
      confirm = <ConfirmModal title="Copy View" text={account.message} onOk={() => account.okAction()} onCancel={() => account.cancelAction() }/>;
    }

    if (account.saveAsVisible) {
      confirm = <ConfirmModal title="Save As" text={account.message} onOk={() => account.okAction()} onCancel={() => account.cancelAction() }/>;
    }
    
    return confirm;
  }
}