import React from 'react';

import {
  getOrgCode,
  isEsfClass,
  isEsfPseudopool,
  isFannieSecurity,
  isGnmaBackedSecurity,
  isOpenMajorSecurity,
  isRemicSecurity,
  isShelfSecurity,
  isRecombinableTranche
} from 'www/util/www-helpers';

import TabPanel from 'common/TabPanel';
import ButtonDropdown from 'common/button/ButtonDropdown';
import AttributeComparisonTable from 'www/components/details/AttributeComparisonTable';
import PoolFactorTable from 'www/components/details/PoolFactorTable';
import StructurePanel from 'www/components/details/StructurePanel';
import L2StructurePanel from 'www/components/details/L2StructurePanel';
import CollateralPanel from 'www/components/details/CollateralPanel';
import StatisticsPanel from 'www/components/details/StatisticsPanel';
import DocumentsList from 'www/components/details/DocumentsList';
import Allocation from 'www/components/details/Allocation';
import TaxPanel from 'www/components/details/TaxPanel';
import DetailTaxFactorTable from 'www/components/details/DetailTaxFactorTable';
import MajorsPanel from 'www/components/details/MajorsPanel';

export default (props) => {
  const { 
    cusip, 
    details, 
    loggedIn, 
    onLoginClick
  } = props;

  const { issuance } = details.data;  
  const isFnm = isFannieSecurity(issuance);
  const isGnma = isGnmaBackedSecurity(issuance);
  const isEsfC = isEsfClass(issuance);
  const isEsfP = isEsfPseudopool(issuance);
  const isRemic = isRemicSecurity(issuance);
  const isShelf = isShelfSecurity(issuance);
  const isMajor = isOpenMajorSecurity(issuance);
  const isCombo = isRecombinableTranche

  let tabs = [];
  let i=0
  //if (isShelf) {
    //tabs.push(
   //     <div key="collateral" tabTitle="Collateral">
    //      <div className="subheading">This security is being set-up and no collateral has been assigned.</div>
  //      </div>
  //      );

  //} else {
    if (isRemic || isEsfC) {
      tabs.push(
          <div className="security-details-container" key="secDetails" tabTitle="Details" >
            <div className="two-panel-flex">
              {isRemic && <AttributeComparisonTable {...props} />}
              <PoolFactorTable {...props} key={i++} />
            </div>
        </div>
      );
      {isRemic && tabs.push(<Allocation {...props} isRemic={isRemic} key="allocation" tabTitle="Allocation" />)}

    } else {
      if (isShelf) {
    	  tabs.push(
  		        <div className="security-details-container" key="secDetails" tabTitle="Details" >
  		          {/*<ButtonDropdown className="security-details-export" key="settings" buttonClassName="icon-button" iconCls="fa fa-cog" options={exportOptions} />*/}
  		          <div className="two-panel-flex">
  		            <AttributeComparisonTable {...props} />
  		            <PoolFactorTable {...props} key={i++} />
  		          </div>
  		        </div>
  		      );
    	  tabs.push(
        <div key="collateral" tabTitle="Collateral">
    	          <div className="subheading">This security is being set-up and no collateral has been assigned.</div>
    	        </div>
    	        );
    	  
    	        
      }
      else{
      const exportName = 'details.csv';
      const exportLink = `/api/security/${getOrgCode()}/${cusip}/details?export=true`;

      let exportOptions;
      if (loggedIn){
    	exportOptions = [];  
        //exportOptions = [
        //  { text: 'Export', url: exportLink, disabled: !exportLink, download: exportName || false }
        //];
      } else {
    	exportOptions = [];
        //exportOptions = [
        // { text: 'Export', action: () => onLoginClick('You must be logged in to export', () => {}), disabled: !exportLink}
        //];
      }

      tabs.push(
        <div className="security-details-container" key="secDetails" tabTitle="Details" >
          {/*<ButtonDropdown className="security-details-export" key="settings" buttonClassName="icon-button" iconCls="fa fa-cog" options={exportOptions} />*/}
          <div className="two-panel-flex">
            <AttributeComparisonTable {...props} />
            {!isEsfP && <PoolFactorTable {...props} key={i++}/>}
          </div>
        </div>
      );
      
      if (!isEsfP) {
        tabs.push(
          <Allocation {...props} isRemic={isRemic} key="allocation" tabTitle="Allocation" />
        );
      }
    }
    }
    if (!isShelf) {
    if (issuance.secType === 'SCR') {
      tabs.push(<L2StructurePanel {...props} key="collateral" tabTitle="Collateral" />);
    } else if (isEsfC && isFnm) {       
      tabs.push(<StructurePanel {...props} key="collateral" tabTitle="Collateral" />);
    } else if (issuance.secType === 'MBS') {       
      tabs.push(<CollateralPanel {...props} key="collateral" tabTitle="Collateral" />);
    }

    if (!isRemic && !isGnma && !isEsfC) {
      tabs.push(
        <div key="stats" tabTitle="Statistics">
          <StatisticsPanel {...props} />
        </div>
      );
    }

    if (!isEsfP) {
      
     //tabs.push(<TaxPanel {...props} key="tax" tabTitle="Tax Factors" />); 
     tabs.push(<DetailTaxFactorTable {...props} key="tax" tabTitle="Tax Factors" />);
      		
    }

    if (isMajor) {
      tabs.push(<MajorsPanel {...props} key="majors" tabTitle="Major Balances" />);
    }
  }

  tabs.push(<DocumentsList {...props} key="docs" tabTitle="Documents" />);

  return (
    <div className="detail-tabs">
      <TabPanel tabActive={props.activeTab} changePage={props.changePage}>{tabs}</TabPanel>
    </div>
  );
};
