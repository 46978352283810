let [replaceCache, historyCache]  = [[], []];

export const BACK_ACTION = {type: 'BACK'};
export const FORWARD_ACTION = {type: 'FORWARD'};

export default ({getState}) => (next) => (action) => {

  if (action.type === "@@router/UPDATE_LOCATION") {
    replaceCache.push(action.payload.pathname);
  }

  if (action.type === "@@router/UPDATE_LOCATION" && action.payload.action == 'POP') {
    let prevItem = replaceCache[replaceCache.length - 3];

    if (prevItem == action.payload.pathname) {
      //Needs to go back twice, to account for bumpHistory() and updateHistory()
      next(BACK_ACTION);
      next(BACK_ACTION);
      next(BACK_ACTION);
      next(BACK_ACTION);

      replaceCache.pop();
      historyCache.push(replaceCache.pop());
    } else if (historyCache[historyCache.length - 1] == action.payload.pathname) {
      next(FORWARD_ACTION);
      next(FORWARD_ACTION);

      historyCache.pop();
    }
  }

  next(action);
};