import { LOGIN_REQUEST } from 'common/login/loginActions';
import API from 'common/util/api';
import { reduceMiddleware } from 'common/util/redux';
import { getOrganizationFromLocation } from 'common/util/common-helpers';

import { GET_BANNER_REQUEST, GET_BANNER_RESPONSE, GET_BANNER_ERROR } from 'www/actions/bannerAction';


function getBannerRequest(getState, next, action) {
  API.get('settings/'+getOrganizationFromLocation()+'/banner').then(
    (response) => {
      next({...action, type: GET_BANNER_RESPONSE, response});
    },
    (message) => {
      next({...action, type: GET_BANNER_ERROR, message});
    }
  );
}

export default reduceMiddleware({
  [GET_BANNER_REQUEST]: getBannerRequest,
  [LOGIN_REQUEST]: getBannerRequest
});  