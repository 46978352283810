import React, { Component } from 'react';

import Table, { TYPES } from 'common/table/Table';
import { COLUMN_TYPES } from 'common/table/SimpleTable';
import { columnValue } from 'common/table/Cell';
import Customizable from 'common/table/features/Customizable';
import { formatDateMmDdYyyy,currencyWithCents, percent, zeroToEmpty } from 'common/util/common-formatters';
import { getOrganizationFromLocation, createGroupClassNameGenerator, sortDesc, toLookup, maxKey } from 'common/util/common-helpers';
import { factor, taxFactor,cusipLink } from 'www/util/www-formatters';
import { mapColumns } from 'www/util/labels';
import { isRemicSecurity, getOrganizationCodeFromLocation, isFreddie, paginate, isSmbs } from 'www/util/www-helpers';
import { HeaderField } from 'www/components/details/DetailHeader';
import DealTaxPanel from 'www/components/deal/DealTaxPanel';
import Paginator, { loadPageWithCall } from 'common/table/features/Paginator';
import {  
  DEFAULT_INIT_PAGE,
  DEFAULT_TAX_FACTOR_PAGE_SIZE,
  IS_75_DAY_SECURITY
} from 'common/util/common-helpers';



export default class DealTaxFactorTable extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        taxRequestCalled: false
    };

    componentDidMount() {
        const { getDealTax } = this.props;
        const { params } = this.props.match;
        const { currentPlatformYear } = this.props.settings;
        const { dealTax } = this.props.details;


        const timeStamp = new Date();
        const currentYear = currentPlatformYear ? currentPlatformYear : timeStamp.getFullYear();

        this.setState({taxRequestCalled: false});
        if (this.props.location && this.props.location.query && this.props.location.query.q && this.props.location.query.q.length) {
            if (this.props.deal && this.props.deal.classes && this.props.deal.classes.length && !dealTax.dataByCusip) {
                const cusipParam = this.props.deal.classes.map((cls) => {
                    return cls.cusip;
                });
                    getDealTax({
                        'trustNumber': params.trustNumber,
                        'cusipParam': cusipParam,
                        "currentYear": currentYear
                    }, DEFAULT_INIT_PAGE, DEFAULT_TAX_FACTOR_PAGE_SIZE);
            }
        }
        else {
            if (!dealTax.dataByCusip) {
                getDealTax({
                    'trustNumber': params.trustNumber,
                    'cusipParam': [],
                    "currentYear": currentYear
                }, DEFAULT_INIT_PAGE, DEFAULT_TAX_FACTOR_PAGE_SIZE);
            }
        }

            if(sessionStorage.getItem('tabIndex')){
            setTimeout(()=>{ sessionStorage.removeItem('tabIndex');}), 0
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        document.body.scrollTop = 0;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    redirectToDeal = (e) => {
        sessionStorage.setItem('tabIndex',this.props.tabIndex);
        const location = this.props.location;
        window.location.assign(window.location.origin + location.pathname);
        e.preventDefault();
    }

    render() {
        const { deal,  trustNumber, getDealTax, changePage, details, settings } = this.props;
        const { pageIdx, pageSize, count, dealTax, totalCount, isFetchingTax } = details;
        const { currentPlatformYear, currentPlatformMonth } = settings;
        const propsData = this.props;
        let additionalYears = 0;

        if (isFetchingTax) {
            return <div className="loading"><i className="fa fa-spinner fa-pulse"/></div>;
        }

        let tranches = [];
        let cusipsFromTax = [];
        if (dealTax && dealTax.dataByCusip) {
            // To get all cusip list from dealTax response
            cusipsFromTax = dealTax.sortedCuisp;
            if(cusipsFromTax && cusipsFromTax.length) {
                const filteredTranches = deal.classes.filter((dc) => {
                    return cusipsFromTax.indexOf(dc.cusip) >= 0;
                });
                filteredTranches && filteredTranches.length ? tranches = filteredTranches : tranches;
            } else {
                tranches = [];
            }
        }
        let cusipParam = [];
        if (this.props.classParam && this.props.classParam.length) {
            if (this.props.deal && this.props.deal.classes && this.props.deal.classes.length) {
                cusipParam  = this.props.deal.classes.map((cls) => {
                    return cls.cusip;
                });
            }
        }

        const paginatedTranches = paginate(tranches, pageIdx, pageSize);

        const gridConfig = {
            table: {
                className: 'results deal-table;',
                rowDynamicClassName: createGroupClassNameGenerator('groupId', paginatedTranches, 'group-start', ''),
                columnDefaults: {
                    sortable: false
                },
                key: 'tax',
                uniqueKey: 'cusip',
                columns: [
                    {
                        key: 'groupId',
                        formatter: zeroToEmpty,
                        className: 'center header-center',
                        emptyText: isSmbs(details) ? '-' : '',
                        dynamicClassName: createGroupClassNameGenerator('groupId', paginatedTranches, 'group-cell-show', 'group-cell-hide')
                    },
                    {
                        key: 'expandCollapse',
                        type: COLUMN_TYPES.EXPANDER
                    },
                    {
                        key: 'classId',
                        className: 'center header-center'
                    },
                    {
                        key: 'cusip',formatter: cusipLink,className: 'center header-center'
                    }

                ],
                expandedComponent,
                columnEmptyText: '-',
                emptyText: 'No tax data available'
            }
        };
        mapColumns('tax', gridConfig.table.columns);

        const  timeStamp = new Date();
        const currentYear = currentPlatformYear ? currentPlatformYear : timeStamp.getFullYear();
        const currentMonth = currentPlatformMonth ? currentPlatformMonth : timeStamp.getMonth() + 1;
        if(currentMonth === 1) {
            additionalYears = 1;
        }
        const exportLink = `/api/tax/${getOrganizationCodeFromLocation()}/deal/${trustNumber}/taxexport/${currentYear}/${additionalYears}`;
        const trancheCount = tranches.length || 0;

        return (
            <Table type={TYPES.simple}
                   features={[Customizable, Paginator]}
                   {...this.props}
                   config={gridConfig}
                   pageIdx={pageIdx}
                   pageSize={pageSize}
                   count ={trancheCount}
                   totalCount ={totalCount}
                   onTotalCountClick={this.redirectToDeal}
                   loadPage={(dealTax && dealTax.dataByCusip) ? loadPageWithCall(changePage, {'trustNumber': trustNumber, 'cusipParam': cusipParam, 'currentYear': currentYear}, pageSize) :
                       loadPageWithCall(getDealTax, {'trustNumber': trustNumber, 'cusipParam': cusipParam, 'currentYear': currentYear, DEFAULT_INIT_PAGE, DEFAULT_TAX_FACTOR_PAGE_SIZE}, pageSize)}
                   data={paginatedTranches}
                   exportLink={exportLink}
                   exportName="Tax.csv" />
        );

        function expandedComponent(row, rowIdx, data, config) {
            const cusip = data[rowIdx].cusip;
            const dealTax = propsData.details.dealTax.data;
            const dealTaxByCusip = propsData.details.dealTax.dataByCusip;
            const footerText = propsData.details.dealTax.footerText;
            let cusipListTaxData = dealTaxByCusip[cusip];
            //dealTax.forEach(item => Object.keys(item).map(itm => item[itm]).forEach(innerItem => cusipListTaxData = cusipListTaxData.concat(innerItem)));
            let isComboClass = false;
            cusipListTaxData.filter((data) => {
                if(data.cusip === cusip && data.classificationType === 'Combo Class') {
                    isComboClass = true;
                    return isComboClass;
                }
            });
            let currentYearCusipTaxData = cusipListTaxData;
            let cusipFooterText = footerText;

            const cusipMappingList = propsData.details &&
            propsData.details.dealTax && propsData.details.dealTax.cusipMappingList && propsData.details.dealTax.cusipMappingList[cusip] ?
                propsData.details.dealTax.cusipMappingList[cusip] : [];

            return (
                <DealTaxPanel {...propsData} cusip={cusip} cusipTaxData={currentYearCusipTaxData} footerText={cusipFooterText} isComboClass={isComboClass} cusipMappingList={cusipMappingList}/>
            );

            return (
                <li className="row-expanded" key={'rowExpanded' + rowIdx}>

                    {gridConfig
                        .table
                        .columns
                        .filter(column => column.visible === false)
                        .map(column =>

                                <div className="row-expanded-item" key={column.key}>
                  <span className="row-expanded-value">
                    {columnValue(row, column, rowIdx, config)}
                  </span>
                                    <span className="row-expanded-label">
                    {column.label}
                  </span>
                                </div>
                        )}
                </li>
            );
        }
    }
}

