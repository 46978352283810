import React, { Component } from 'react';
import SimpleTable from 'common/table/SimpleTable';
import { documentDownload,  issuanceIndicator } from 'www/util/www-formatters';
import DealMonthlyDocuments from './DealMonthlyDocuments';
import DealIssuanceDocuments from './DealIssuanceDocuments';

export default class DealDocumentList extends Component{

  componentDidMount() {
    if (!this.props.hideCollateralAndDocumentTab) {
        this.props.getDealDocuments(this.props.trustNumber);
    }

      if(sessionStorage.getItem('tabIndex')){
          setTimeout(()=>{ sessionStorage.removeItem('tabIndex');}),0
      }
  }

  redirectToDeal = (e) => {
    sessionStorage.setItem('tabIndex',this.props.tabIndex);
    const location = this.props.location;
    window.location.assign(window.location.origin + location.pathname);
    e.preventDefault();
}

render(){
  const dealType = this.props.hasOwnProperty('deal') ? this.props.deal.dealType : [];
  const issuer = this.props.hasOwnProperty('deal') ? this.props.deal.issuer : [];
  const { issuanceDocuments, monthlyDocuments, message, hasError, isFetching } = this.props.documents;
  const { recordDownload } = this.props;
  let hideDocumentTab = false;

  //Looks through each document and removes the S if it's in the filename
  if (issuanceDocuments.length > 0 && dealType.length > 0){
    for(var i = 0; i < issuanceDocuments.length; i++){
      var doc = issuanceDocuments[i];
      var name = doc.document.name;
      var tempName;
      var zipIndex = name.indexOf('FC');
      
      if(zipIndex != -1 && issuer == 'FRE' ){
        tempName = name.slice(0,zipIndex);
        
        if((dealType == 'SMBS' || dealType == 'ESF') && tempName.charAt(tempName.length - 1) == "S"){
          issuanceDocuments[i].document.dName = name; 
          issuanceDocuments[i].document.name = name.slice(0,zipIndex-1) + name.slice(zipIndex);
        }
      }
    }
  }

  if (this.props.location && this.props.location.query && this.props.location.query.q && this.props.location.query.q.length) {
    hideDocumentTab = true;
}

if (isFetching && !hideDocumentTab) {
  return <div className="loading more"><i className="fa fa-spinner fa-pulse"/></div>;
}

if (hasError && !hideDocumentTab) {
  return <div className="empty-text">Unable to retrieve documents. {message}</div>;
}

const documentsConfig = {
  className: 'document-table',
  columns: [
    {key: 'document', label: 'Document', formatter: documentDownload},
    {key: 'correctionDocument', label: '', formatter: documentDownload}
  ]
};

return (
  <div>
    <div>
        {!hideDocumentTab && issuanceDocuments.length>0?
            <div className="two-panel-flex">
              <div>
                <div className="subheading">Issuance and Legal Documents</div>
                <DealIssuanceDocuments config={documentsConfig} data={issuanceDocuments}/>
              </div>
            </div> :<div></div>
        }
    </div>
    <div>
      {!hideDocumentTab && monthlyDocuments.length>0 ?
      <div className="two-panel-flex">
          <div>
              <div className="subheading">Monthly</div>
              <DealMonthlyDocuments config = {documentsConfig}  documents = {monthlyDocuments}/>
          </div>
      </div>:<div></div>}
    </div>
  </div>
);
  return(<p>Just a test</p>)
}

}