import { 
  DEAL_REQUEST,
  DEAL_RESPONSE,
  DEAL_ERROR,
  DEAL_ISSUANCE_REQUEST,
  DEAL_ISSUANCE_RESPONSE,
  DEAL_ISSUANCE_ERROR,
  DEAL_COMPONENT_REQUEST,
  DEAL_COMPONENT_RESPONSE,
  DEAL_COMPONENT_ERROR,
  DEAL_TAX_REQUEST,
  DEAL_TAX_RESPONSE,
  DEAL_TAX_ERROR,
  DEAL_DATES_REQUEST,
  DEAL_DATES_RESPONSE, 
  DEAL_DATES_ERROR,
  DEAL_AS_OF_REQUEST, 
  DEAL_AS_OF_RESPONSE, 
  DEAL_AS_OF_ERROR,
  DEAL_COLLATERAL_COUNT_REQUEST,
  DEAL_COLLATERAL_COUNT_RESPONSE,
  DEAL_COLLATERAL_COUNT_ERROR,
  DEAL_COLLATERAL_REQUEST,
  DEAL_COLLATERAL_RESPONSE,
  DEAL_COLLATERAL_ERROR,
  DEAL_TAX_FACTOR_COUNT_REQUEST,
  DEAL_TAX_FACTOR_COUNT_RESPONSE,
  DEAL_TAX_FACTOR_COUNT_ERROR,
  DEAL_BY_CLASS_REQUEST,
  DEAL_BY_CLASS_RESPONSE,
  DEAL_BY_CLASS_ERROR,
  DEAL_CLASS_COUNT_REQUEST,
  DEAL_CLASS_COUNT_RESPONSE,
  DEAL_CLASS_COUNT_ERROR,
  DEAL_CLASS_CUSIPS_REQUEST,
  DEAL_CLASS_CUSIPS_RESPONSE,
  DEAL_CLASS_CUSIPS_ERROR,
  DEAL_PAGE_CHANGE_REQUEST
} from 'www/actions/dealActions';

import { reducerGenerator } from 'common/util/redux';
import { replaceValueIfEquals, sortBy, toLookup, sortSecondary } from 'common/util/common-helpers';

const initialState = {
  message: null,
  deal: null,
  dealTax: {
      data: [],
      footerText: {}
      },
  dates: [],
  isIssuance: false,
  asOfDate: null,
  hasError: false,
  isFetching: false,
  isFetchingCollateral: false,
  isFetchingTaxCount: false,
  isFetchingTaxClassCount: false,
  count: null,
  isFetchingTax: false,
  totalCount: null,
  classCusips: []
};

function processReceivedDealWithLogic(action) {
  let isAsOfDate = action.deal.ongoingClasses != undefined && action.deal.ongoingClasses.length > 0;
  return processReceivedDeal(action, isAsOfDate);
}

function processReceivedDeal({deal}, isAsOfDate) {
  const dealOut = deal.deal;
  
  if (deal.notes) {
    dealOut.notes = deal.notes.note;
  }
  
  dealOut.issuanceClasses = deal.issuanceClasses;
  dealOut.ongoingClasses = deal.ongoingClasses;

  if(isAsOfDate){
    dealOut.classes = combineAndCollapseDealClassesAsOfDate(deal);
  }else{
    dealOut.classes = combineAndCollapseDealClassesIssuance(deal);
  }

  dealOut.dealSize = deal.ongoingUpb || deal.issuanceUpb;

  sortSecondary(dealOut.classes,(v1, v2) => sortByGrpAndCusip(v1, v2));

  return dealOut;
}

function processComponentDeal({componentDeal}, isAsOfDate) {
  const dealOut = componentDeal.deal;

  dealOut.issuanceClasses = componentDeal.issuanceClasses;
  dealOut.ongoingClasses = componentDeal.ongoingClasses;

  if(isAsOfDate){
    dealOut.classes = combineAndCollapseDealClassesAsOfDate(componentDeal);
  }else{
    dealOut.classes = combineAndCollapseDealClassesIssuance(componentDeal);
  }

  dealOut.dealSize = componentDeal.ongoingUpb || componentDeal.issuanceUpb;
  
  sortBy(dealOut.classes, c => replaceValueIfEquals(c.groupId, 0, 9999));

  return dealOut;
}

function processDealCollateral({collateral}) {
  return sortSecondary(collateral,(v1, v2) => sortByGrpAndCusip(v1, v2));
}

function sortByGrpAndCusip(v1, v2) {
    let xGrp = String(replaceValueIfEquals(v1.groupId, 0, 9999)); //force to be string
    let yGrp = String(replaceValueIfEquals(v2.groupId, 0, 9999)); //force to be string

	if (xGrp.localeCompare(yGrp, undefined, { numeric: true, sensitivity: 'base' }) != 0) {
		return xGrp.localeCompare(yGrp, undefined, { numeric: true, sensitivity: 'base' });
	}else{		
		if (v1.cusip > v2.cusip) return 1;
		if (v1.cusip < v2.cusip) return -1;
	}
}

/**
 * Issuance classes and security details should be shown in this view except two current fields
 */
function combineAndCollapseDealClassesIssuance({issuanceClasses, ongoingClasses}) {
 
   if (issuanceClasses == undefined || issuanceClasses.length === 0) {
     return [];
   }
  
  return issuanceClasses.map(source => {
    const combined = {...source, ...(source.remicFields || {})};
    combined.upbIssuance = combined.upb;
    combined.upbOngoing = combined.upb;
    return combined;
  });
}

function mapInactiveClasses(inactiveClasses) {
  return inactiveClasses.map(cls => {
    const wasPaidOffAtIssuance = cls.factor === undefined
    return {
      groupId : 'INACTIVE',
      classId: cls.remicFields.classId,
      cusip: cls.cusip,
      notionalDealInd: cls.remicFields.notionalDealInd,
      upbIssuance: cls.upb,
      upbOngoing: wasPaidOffAtIssuance ? null : 0.0,
      classFactor: wasPaidOffAtIssuance ? cls.classFactor : 0.0,
      maturity: cls.maturity
    }});
}

/**
 * Ongoing(For selected reporting period) classes and security details should be shown in this view except issuanceUPB
 */
function combineAndCollapseDealClassesAsOfDate({issuanceClasses, ongoingClasses}) {

  if (issuanceClasses == undefined || issuanceClasses.length === 0) {
    return [];
  }

  if (ongoingClasses == undefined || ongoingClasses.length === 0) {
    return mapInactiveClasses(issuanceClasses);
  }

  const issuanceByCusip = toLookup(issuanceClasses, cls => cls.cusip);

  ongoingClasses = ongoingClasses.map(source => {
    const issuance = issuanceByCusip[source.cusip] || {}; // really should never be null, but protect NPE
    const combined = {...source, ...(source.remicFields || {})};
    combined.upbOngoing = combined.upb;
    combined.upbIssuance = issuance.upb;
    if (!combined.groupId) {
      combined.groupId = issuance.groupId;
    }

    if (!(source.status === 'Active')) {
      combined.groupId = 'INACTIVE';
    }
    return combined;
  });

  //Including issuance securities which is already matured (not in ongoing) as "INACTIVE
  
  const issuanceLength = issuanceClasses.length ;
  const ongoingLength = ongoingClasses.length ;
  
  if(ongoingLength < issuanceLength){
    let leftOverClasses = issuanceClasses.splice( (ongoingLength) , (issuanceLength -1)) ;
    return ongoingClasses.concat(mapInactiveClasses(leftOverClasses));
  }else{
    return ongoingClasses ;
  }
  
}

function onDealCollateralError({state, action}) {
  return {...state, message: (action && action.message) ? action.message : 'There was an error retrieving collateral.', hasError: true, isFetchingCollateral: false, collateral: []};
}

function onDealTaxFactorCountError({state, action}) {
  return {...state, message: (action && action.message) ? action.message : 'There was an error retrieving TaxFactor count.', hasError: true, isFetchingTaxCount: false, collateral: []};
}

function onDealClassCountError({state, action}) {
	  return {...state, message: (action && action.message) ? action.message : 'There was an error retrieving Deal Classes count.', hasError: true, isFetchingTaxClassCount: false, collateral: []};
	}

export default reducerGenerator(initialState, {
  DEAL_REQUEST: (state, action) => ({...state, isFetching: true, pageIdx: action.pageIdx, pageSize: action.pageSize}),
  DEAL_PAGE_CHANGE_REQUEST: (state, action) => ({...state, isFetching: false, pageIdx: action.pageIdx, pageSize: action.pageSize}),
  DEAL_RESPONSE: (state, action) =>({...state, deal: processReceivedDealWithLogic(action), recordType: undefined,
          asOfDate: action.asOfDate, isIssuance: false, isFetching: false, hasError: false, pageIdx: action.pageIdx, pageSize: action.pageSize,
          collateral: [], dealTax: []}),
  DEAL__ERROR: (state, action) => ({...initialState, message: action.message, hasError: true}),
  DEAL_ISSUANCE_REQUEST: (state, action) => ({...state, isFetching: true, pageIdx: action.pageIdx, pageSize: action.pageSize}),
  DEAL_ISSUANCE_RESPONSE: (state, action) =>({...state, deal: processReceivedDeal(action, false),
          asOfDate: action.asOfDate, isIssuance: true, isFetching: false, hasError: false, pageIdx: action.pageIdx, pageSize: action.pageSize}),
  DEAL_ISSUANCE_ERROR: (state, action) => ({...initialState, message: action.message, hasError: true}),
  DEAL_COMPONENT_REQUEST: (state, action) => ({...state, isComponentFetching: true}),
  DEAL_COMPONENT_RESPONSE: (state, action) =>({...state, componentDeal: processComponentDeal(action, false),
     isComponentFetching: false, hasComponentError: false}),
     DEAL_COMPONENT_ERROR: (state, action) => ({...initialState, message: action.message, hasComponentError: true}),
  DEAL_BY_CLASS_REQUEST: (state, action) => ({...state, isFetching: true, pageIdx: action.pageIdx, pageSize: action.pageSize}),
  DEAL_BY_CLASS_RESPONSE: (state, action) =>({...state, deal: processReceivedDeal(action, false),
      						 asOfDate: action.asOfDate, isIssuance: true, isFetching: false, hasError: false, pageIdx: action.pageIdx, pageSize: action.pageSize}),
  DEAL_BY_CLASS_ERROR: (state, action) => ({...initialState, message: action.message, hasError: true}),

  DEAL_TAX_REQUEST: (state, action) => ({...state, isFetchingTax: true, pageIdx: action.pageIdx, pageSize: action.pageSize}),
  DEAL_TAX_RESPONSE: (state, action) =>({...state, dealTax: action.dealTax, isFetchingTax: false, hasError: false, pageIdx: action.pageIdx, pageSize: action.pageSize}),
  DEAL_TAX_ERROR: (state, action) => ({...initialState, message: action.message, hasError: true, isFetchingTax: false, dealTax: []}),
  
  DEAL_TAX_FACTOR_COUNT_RESPONSE: (state, action) => ({...state, count: action && action.count >= 0 ? action.count : state.count, totalCount: action && action.totalCount >= 0  ? action.totalCount : state.totalCount}),
  DEAL_TAX_FACTOR_COUNT_ERROR: onDealTaxFactorCountError,
  
  DEAL_CLASS_COUNT_RESPONSE: (state, action) => ({...state, dealTrancheCount: action && action.count >= 0 ? action.count : state.count, totalCount: action && action.totalCount >= 0 ? action.totalCount : state.totalCount}),
  DEAL_CLASS_COUNT_ERROR: onDealClassCountError,

  DEAL_COLLATERAL_COUNT_RESPONSE: (state, action) => ({...state, dealCollateralCount: action.count}),
  DEAL_COLLATERAL_COUNT_ERROR: onDealCollateralError,

  DEAL_COLLATERAL_REQUEST: (state, action) => ({...state, isFetchingCollateral: true, hasError: false, pageIdx: action.pageIdx, pageSize: action.pageSize}),
  DEAL_COLLATERAL_RESPONSE: (state, action) => ({...state, collateral: processDealCollateral(action),recordType:action.recordType, isFetchingCollateral: false, hasError: false, pageIdx: action.pageIdx, pageSize: action.pageSize}),
  DEAL_COLLATERAL_ERROR: onDealCollateralError,

  DEAL_DATES_REQUEST: (state, action) => ({...state, dates: [], hasError: false}),
  DEAL_DATES_RESPONSE: (state, action) => ({...state, dates: action.dates || [], hasError: false}),
  DEAL_DATES_ERROR: (state, action) => ({...state, message: (action && action.message) ? action.message : 'There was an error retrieving dates', hasError: true, dates: []}),

  DEAL_AS_OF_REQUEST: (state, action) => ({...state, isFetching: true, hasError: false}),
  DEAL_AS_OF_RESPONSE: (state, action) => ({...state, deal: processReceivedDeal(action, true),
                                  asOfDate: action.asOfDate, isIssuance: false, isFetching: false, hasError: false, pageIdx: action.pageIdx, pageSize: action.pageSize}),
  DEAL_AS_OF_ERROR: (state, action) => ({...state, message: action.message, hasError: true, isFetching: false}),

  DEAL_CLASS_CUSIPS_RESPONSE: (state, action) => ({...state, classCusips: action.classCusips || [],  hasError: false}),
  DEAL_CLASS_CUSIPS_ERROR: (state, action) => ({...state, message: action.message, hasError: true, classCusips: []}),
});
