import React, { Component } from 'react';

import ButtonDropdown from 'common/button/ButtonDropdown';
import ButtonGroup from 'common/button/ButtonGroup';
import MonthlyStatistics from 'www/components/details/MonthlyStatistics';

import { formatDateYyyMm } from 'common/util/common-formatters';
import { eventHandler } from 'common/util/events';

import {
  getMonthSelector,
  getOrgCode
} from 'www/util/www-helpers';

export default class StatisticsPanel extends Component {
  state = {
    activeTab: 0
  };

  onSelectItem = (idx, month) => {
    if (month) {
      this.setState({activeTab: idx, selectedMonth: month});
    } else {
      this.setState({activeTab: idx});
    }
  };
    // constructor(props, context) {
    //     super(props, context);
    //
    //     this.state = this.state || {};
    // }


  componentDidMount() {

    const { dataFiles, getDataFiles, details  } = this.props;

    if (!dataFiles.data) {
      getDataFiles(details.data.issuance);
    }
    let _activeTab = 1;
    if (dataFiles.data && dataFiles.data.length === 1) {
        _activeTab = 1;
    } else if (dataFiles.data && dataFiles.data.length > 1) {
      _activeTab = 2;
    }
    else {
      _activeTab = 0;
    }
    this.setState({
         activeTab: _activeTab
    });
  }


  render() {
    const {
      cusip,
      dataFiles,
      details,
      loggedIn,
      onLoginClick
    } = this.props;

    const { issuance, current } = details.data;
    const { activeTab, selectedMonth } = this.state;
    const options = [];

    if (issuance.quartiles && issuance.distributionsMap) {
      options.push({label: 'Issuance'});
    }

    if (dataFiles.data && dataFiles.data.length > 1) {
      options.push(getMonthSelector(selectedMonth, dataFiles, this.onSelectItem));
    }

    if (current && current.quartiles && current.distributionsMap) {
      options.push({label: 'Current'});
    }

    if (options.length === 0) {
      return <div className="empty-text">No statistics are available at this time</div>
    }

    let tab, currentOrIssuance, selectedMonthName;
    if (activeTab === 0) {
      currentOrIssuance = "issuance";
      tab = <MonthlyStatistics {...this.props} currentOrIssuance={currentOrIssuance} />;
    } else if (activeTab === options.length-1) {
      currentOrIssuance = "current";
      tab = <MonthlyStatistics {...this.props} currentOrIssuance={currentOrIssuance} />;
    } else {
      currentOrIssuance = "current";
      selectedMonthName = selectedMonth.name;
      tab = <MonthlyStatistics {...this.props} month={selectedMonthName} />;
    }

    var exportLink;
    if (selectedMonthName) {
      exportLink = `/api/security/${getOrgCode()}/${cusip}/${currentOrIssuance}/statistics?export=true&reportingPeriod=${selectedMonthName}`;
    } else {
      exportLink = `/api/security/${getOrgCode()}/${cusip}/${currentOrIssuance}/statistics?export=true`;
    }

    const exportName = 'statistics.csv';
    let exportOptions;
    if (loggedIn){
      exportOptions = [];
      //exportOptions = [
      //  { text: 'Export', url: exportLink, disabled: !exportLink, download: exportName || false }
      //];
    } else {
      exportOptions = [];
      //exportOptions = [
      //  { text: 'Export', action: () => onLoginClick('You must be logged in to export', () => {}), disabled: !exportLink}
      //];
    }

    return (
      <div className="statistics-container">
        <div className="statistics-header">
          <ButtonGroup className="stats-selector" options={options} onSelectItem={this.onSelectItem} />
          {/*<ButtonDropdown className="stats-export" key="settings" buttonClassName="icon-button" iconCls="fa fa-cog" options={exportOptions} />*/}
        </div>
        {tab}
      </div>
    );
  }
}
