import React, { Component } from 'react'
import Header from 'www/containers/Header'
import Footer from 'www/components/Footer'
import { isLoggedIn, showLoginMessage, idleLogout, logout, cancelIdleLogout, keepSessionActive } from 'common/login/loginActions'
import { getSettings } from 'www/actions/settingsActions'
import { connect } from 'react-redux'
import FannieArchiveAnnouncementsContainer from 'common/fannieArchiveAnnouncementsContainer'
import { getArchivedAnnouncementsForFannie } from 'www/actions/fannieResourcesActions'
import IdleDetector from 'common/IdleDetector';

class FannieArchivedAnnouncements extends Component{
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.props.getArchivedAnnouncementsForFannie();
    }

    render() {
        const { login, showLoginMessage, fannieArchiveResources, settings, idleLogout, logout, cancelIdleLogout, keepSessionActive } = this.props;
        const { params } = this.props.match;
        const   managedResourceDataList   = fannieArchiveResources;

        if(params.organization=='freddie') return;


        return(<div id="app" className={params.organization}>
              {login.loggedIn && <IdleDetector showIdleLogout={login.showIdleLogout}
                                               idleTimeout={settings.idleTimeout}
                                               idleAction={idleLogout}
                                               defaultAction={logout}
                                               cancelAction={cancelIdleLogout}
                                               keepSessionActive={keepSessionActive} />}
            <Header {...this.props} isSearchNeeded={false}/>
            <div className="content">
                <div className="fnm-announce-archive">
                <FannieArchiveAnnouncementsContainer announcements = {managedResourceDataList}/>
                </div></div>
            <Footer params={params} />
            </div>
                        
    );}
}

export default connect(
    ({ login, settings, fannieArchiveResources }) => ({ login, settings, fannieArchiveResources }),
    { showLoginMessage, getSettings,  isLoggedIn, getArchivedAnnouncementsForFannie, idleLogout, logout, cancelIdleLogout, keepSessionActive })(FannieArchivedAnnouncements);