import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import Modal from 'common/Modal';
import { slug } from 'common/util/common-helpers';
import { findPortfolioBySlug } from 'www/util/portfolio';
import { getOrganizationFromLocation } from 'common/util/common-helpers';

export default class AddToPortfolioPopup extends Component {
  
  state = {
    showInput: true,
    showLoading: false,
    showConfirmation: false,
    addedToTitle: '',
    nameInput: null
  };

  UNSAFE_componentWillMount() {

    this.setState({
      showInput: true,
      showLoading: false,
      showConfirmation: false
    });
  }

  onNameInputRef = (nameInput) => {
    
    // called during render, so don't use setState()
    this.state.nameInput = nameInput;
  };

  onPortfolioSelect = (selectedPortfolio) => {

    const { addResultsToPortfolio, securities, portfolio } = this.props;
    let self = this;

    const existingPortfolioGroup = findPortfolioBySlug(portfolio, selectedPortfolio.title);
    const title = existingPortfolioGroup ? existingPortfolioGroup.title : selectedPortfolio.title;

    this.setState({
      showInput: false,
      showLoading: true
    });
    addResultsToPortfolio(securities, title, addResultsToPortfolioHandler);

    function addResultsToPortfolioHandler() {

      self.setState({
        showInput: false,
        showLoading: false,
        showConfirmation: true,
        addedToTitle: title
      });

    };
  };

  onCreate = () => {
    
    const { nameInput } = this.state;

    if (nameInput && nameInput.value) {
      let newPortfolio = {
        title: nameInput.value,
        portfolio: []
      };
      this.onPortfolioSelect(newPortfolio);
    }

  };

  onNameKeyUp = (e) => {

    if (e.keyCode == 13) { // Enter key
      this.onCreate();
    }
  };

  render() {

    const { showInput, showLoading } = this.state;
    const { onClose } = this.props;

    return (
      <Modal className="add-to-portfolio-popup" onClose={onClose}>
        
        <div className="title">Add to Portfolio</div>
        
        {
          showInput 
            ? this.renderSelect() 
            : showLoading
              ? this.renderLoading()
              : this.renderConfirmation()
        }

      </Modal>
      );    
  }

  renderSelect() {

    const { portfolio = [] } = this.props.account;
    const { onClose } = this.props;

    let nameMessage = portfolio.length
                          ? 'or enter name for new portfolio:'
                          : 'Enter name for new portfolio:';

    return (
      <div className="select-form">        
        <PortfolioSelect portfolio={portfolio} onPortfolioSelect={this.onPortfolioSelect} />
        
        <div className="instruction">{nameMessage}</div>
        <input ref={this.onNameInputRef} type="text" autoFocus onKeyUp={this.onNameKeyUp} />
        
        <div className="add-portfolio-buttons">
          <button onClick={onClose}>Cancel</button>
          <button className="btn-primary" onClick={this.onCreate}>Create</button>
        </div>
      </div>
    );
  }

  renderLoading() {

    return (
      <div className="loading-container">
        <i key="loading" className="fa fa-spinner fa-pulse loading-icon"/>
      </div>
      );
  }

  renderConfirmation() {
    const { onClose } = this.props;
    const { addedToTitle } = this.state;
    const organization = getOrganizationFromLocation();

    return (
      <div className="confirmation">
        
        <div className="confirmation-text">
          Results have been added to &nbsp;
          <Link to={`/${organization}/account/portfolio/${slug(addedToTitle)}`}>
            {addedToTitle}
          </Link>
        </div>

        <div className="add-portfolio-buttons">
          <button onClick={onClose}>OK</button>
        </div>
      </div>
    );  
  }
}

const PortfolioSelect = ({portfolio, onPortfolioSelect}) => {

  if (portfolio.length === 0) {
    return null;
  }

  return (
    <div className="porfolio-list-container">
      <div className="instruction">Select an existing portfolio:</div>
        { portfolio.map(p => (
          <a key={p.title} className="portfolio-link" onClick={() => onPortfolioSelect(p)}>{p.title}</a>
        ))}
    </div>
    );
};

