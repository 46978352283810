export const DOCUMENTS_REQUEST = 'DOCUMENTS_REQUEST';
export const DOCUMENTS_RESPONSE = 'DOCUMENTS_RESPONSE';
export const DOCUMENTS_ERROR = 'DOCUMENTS_ERROR';

export const CHECK_EMAIL_LANDING_REQUEST = 'CHECK_EMAIL_LANDING_REQUEST';
export const CHECK_EMAIL_LANDING_RESPONSE = 'CHECK_EMAIL_LANDING_RESPONSE';
export const CHECK_EMAIL_LANDING_ERROR = 'CHECK_EMAIL_LANDING_ERROR';

export const UNSUBSCRIBE_REQUEST = 'UNSUBSCRIBE_REQUEST';
export const UNSUBSCRIBE_RESPONSE = 'UNSUBSCRIBE_RESPONSE';
export const UNSUBSCRIBE_ERROR = 'UNSUBSCRIBE_ERROR';

export function getDocuments() {
  return { type: DOCUMENTS_REQUEST };
}

export function getUnsubscribeFromReport(reportName) {
      
    return { 
      type: UNSUBSCRIBE_REQUEST, 
      reportName 
    };
}

export function checkEmailLanding(id, fileName, reportName) {
      
    return { 
      type: CHECK_EMAIL_LANDING_REQUEST, 
      id,
      fileName,
      reportName 
    };
}
