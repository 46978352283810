import React from 'react'

const FannieSplashBox = function (props) {
  const { fannieResources, fannieArchiveResources, titleUrl, titleName, content, externalURL } = props
  const { params } = props.match
  let url = /fannie/ + titleUrl
  if (externalURL === 'true'){
        url = titleUrl
   }
  return (
      <a href={`${url}`} className="fnm-outer-boxes">
      <div className="fnm-second-row-boxes">
        <u>{titleName}</u>
        {content && <p>&nbsp;{content}&nbsp;</p>}
      </div>
    </a>
  )
}
export default FannieSplashBox