import React, { Component } from 'react';

import { formatDateMmmm, monthNames } from 'common/util/common-formatters';
import ButtonDropdown from 'common/button/ButtonDropdown';

import { getOrganizationFromLocation } from 'common/util/common-helpers';
import { convertToRefName, documentDownload, subscribeToReportLink } from 'www/util/www-helpers';
import { getLabels } from 'www/util/labels';
import { getByKeys } from 'www/util/dataFileMappings';

const ALL_PLACEHOLDER = 'All';

export class Reports extends Component {

  constructor(props){
    super(props);
    this.state = {
      selectedType: ALL_PLACEHOLDER,
      selectedDay: ALL_PLACEHOLDER,
      selectedMonth: ALL_PLACEHOLDER,
      selectedYear: ALL_PLACEHOLDER,
      years: []
    };

  }
  
  emptyText() {
    return 'Reports';
  }

  belongsToSubsection(obj, report) {
    if (obj === undefined) {
      return false;
    }
    return report.headingKey in obj;
  }

  category() {
    return 'Normal';
  }

  currentRoute() {
    const currentUrl = this.props.location.pathname;
    const pathElements = currentUrl.split('/');
    const lastElem = pathElements.pop();
    const arrayToReturn = [];
    arrayToReturn.unshift(lastElem);
    arrayToReturn.unshift(pathElements.pop());
    if (lastElem === 'issuance' || lastElem === 'monthly' || lastElem === 'other' || lastElem === 'reset' || lastElem ==='socialDisclosures') {
      arrayToReturn.unshift(pathElements.pop());
    }

    return arrayToReturn;
  }
  
  currentRouteKey() {
    return this.currentRoute().join('-');
  }

  getReportGroup() {
    return getLabels("report-group-map")[this.currentRouteKey()];
  }

  componentWillMount() {
    // When you go away from reports component, for example, when click on logo to go home page and then click data files and reports
    // or click on Tax data files and come back
    // The reportstore is not fethced again, but the component state is removed, 
    // this is to trigger the reports store fetch again
  
    const { getGroupReports, reports: reportsStore } = this.props;
    if(reportsStore && reportsStore.hasFetched && !reportsStore.isFetching) {
        getGroupReports(this.getReportGroup(), this.currentRouteKey());
    }
  
  }
  componentDidMount() {
    const { getGroupReports, reports } = this.props;
    let currentUrlPath = this.currentRouteKey();
    if (!reports || !(reports.hasFetched || reports.isFetching)) {
       getGroupReports(this.getReportGroup(), currentUrlPath);
    } else if(reports.urlPath && reports.urlPath !== currentUrlPath) {
       getGroupReports(this.getReportGroup(), currentUrlPath);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    
    // list of years are not in reportsStore for when only year reports are fetched
    // Thats why no of years are stored in state.
    // if we are getting new list of years, set them in state
    if(nextProps.reports.years && nextProps.reports.years.length >0) {
      this.setState({years: nextProps.reports.years});
    }
    
    if(nextProps.reports.selectYear !==0  && nextProps.reports.selectYear != this.props.reports.selectYear) {
      this.setState({selectedYear: nextProps.reports.selectYear});
      this.setState({selectedType: ALL_PLACEHOLDER});
      this.setState({selectedDay: ALL_PLACEHOLDER});
      this.setState({selectedMonth: ALL_PLACEHOLDER});
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    const { reports, getGroupReports } = this.props;
  
  	let currentUrlPath = this.currentRouteKey();
    // when click on left menu, the url path is changed but the props are not fetched again
    // compare the reports urlpath and current path, and force props refresh and re-render
  	if(reports && reports.urlPath && reports.urlPath !== currentUrlPath) {
  	  getGroupReports(this.getReportGroup(), currentUrlPath);
  	}
  }
  
  render() {
    return (
      <div className="reports">
        { this.renderContents() }
      </div>
    );
  }

  renderContents() {
  
    const { account, reports: reportsStore, subscribeToReport, unsubscribeToReport, getGroupYearReports } = this.props;
    const { isFetching, hasError, message, selectYear, reports = [] } = reportsStore;
    const { reportSubscriptions = {}, subscribingToReports = {} } = account;

    if (isFetching) {
      return <div className="loading"><i className="fa fa-spinner fa-pulse"/></div>;
    } 

    if (hasError) {
      return <div className="error-text">{message || 'Unknown error'}</div>;
    }

    const routeKeyArray = this.currentRoute();
    const routeElements = getByKeys(routeKeyArray);
    const getLabel = labelsWrapper(routeElements, getOrganizationFromLocation() === 'freddie');

    const labeledReports = reports.filter(report => this.belongsToSubsection(routeElements, report))
      .map(report => (
      {
        ...report,
        label: getLabel(report.headingKey),
        isSubscribed: !!reportSubscriptions[convertToRefName(report.headingKey)],
        isSubscribing: !!subscribingToReports[convertToRefName(report.headingKey)]
      }))
      .filter(report => !!report.label)
      ;

    if (labeledReports.length === 0) {
      return <div className="empty-text">No {this.emptyText()} are currently available.</div>;
    }

    labeledReports.sort((a, b) => {
      const labelA = a.label.toUpperCase(); // ignore upper and lowercase
      const labelB = b.label.toUpperCase();
    
      if (labelA < labelB) {
        return -1;
      } else if (labelA > labelB) {
        return 1;
      } else {
        return 0;
      }
    });

    const days = [ALL_PLACEHOLDER];
    for (var i = 1; i <= 31; i++) {
      days.push(i);
    }

    const dayOptions = days.map(day => ({
      text: day,
      value: day,
      onClick: dayList => this.setState({selectedDay: dayList.value}),
      disabled: day === this.state.selectedDay
    }));

    const monthOptions = days.slice(0, 13).map(month => {
      const formatted = month !== ALL_PLACEHOLDER ? formatDateMmmm(new Date(2042, month - 1, 1)) : ALL_PLACEHOLDER;
      return ({
        text: formatted,
        value: formatted,
        onClick: monthOrAll => this.setState({selectedMonth: monthOrAll.value}),
        disabled: formatted === this.state.selectedMonth
      })
    });

    const types = [ALL_PLACEHOLDER];
    labeledReports.forEach(report => {
      types.push(report.label);
    });

    const yearOptions = this.state.years.map(label => ({
      text: label,
      value: label,
      onClick: typeListItem => getGroupYearReports(this.getReportGroup(), typeListItem.value, this.currentRouteKey()),
      disabled: label === this.state.selectedYear
    }));

    const typeOptions = types.map(label => ({
      text: label,
      value: label,
      onClick: typeListItem => this.setState({selectedType: typeListItem.value}),
      disabled: label === this.state.selectedType
    }));

    const daySelector = <ButtonDropdown className="day-selector" options={dayOptions}
                                        label={this.state.selectedDay === ALL_PLACEHOLDER ? 'Day' : this.state.selectedDay} />;
    const monthSelector = <ButtonDropdown className="month-selector" options={monthOptions}
                                          label={this.state.selectedMonth === ALL_PLACEHOLDER ? 'Month' : this.state.selectedMonth} />;
    const yearSelector = <ButtonDropdown className="year-selector" options={yearOptions}
                                          label={this.state.selectedYear === ALL_PLACEHOLDER ? 'Year' : this.state.selectedYear} />;
    const typeSelector =  <ButtonDropdown className="type-selector" options={typeOptions}
                                          label={this.state.selectedType === ALL_PLACEHOLDER ? 'Report' : this.state.selectedType} />;

    const {selectedDay, selectedMonth, selectedYear, selectedType} = this.state;

    const calFilter = (val, compare) => {
      return val === ALL_PLACEHOLDER ? () => true
        : dataFile => {
        if (!dataFile || !dataFile.file || !dataFile.file.effectiveDate) {
          console.error('Effective date for a datafile was was null!  This shouldnt happen');
          return false;
        }

        return compare(val, dataFile.file.effectiveDate);
      }
    };

    const dayFilter = calFilter(selectedDay, (day, effDate) => day === effDate.getDate());
    const monthFilter = calFilter(selectedMonth, (month, effDate) => getMonthFromName(month) === effDate.getMonth());
    const yearFilter = calFilter(selectedYear, (year, effDate) => year === effDate.getFullYear());

    const curSubState = routeKeyArray.pop();
    const rptKey = routeKeyArray[0];
    
    const singleOrMulti = routeKeyArray.pop();
    var dataFileFilter;
    var filterSelectors;

    if(rptKey==='miscellaneous'){
      dataFileFilter = dataFile => monthFilter(dataFile) && yearFilter(dataFile);
      filterSelectors = <span>{yearSelector} {monthSelector}</span>;
    }
    else if (curSubState === 'exchanges') {
      dataFileFilter = dataFile => yearFilter(dataFile);
      filterSelectors = <span>{typeSelector} {yearSelector}</span>;
    } else if (singleOrMulti === 'singleclass' && curSubState === 'issuance') {
      dataFileFilter = dataFile => dayFilter(dataFile) && monthFilter(dataFile) && yearFilter(dataFile);
      filterSelectors = <span>{typeSelector} {yearSelector} {monthSelector} {daySelector}</span>;
    }else {
      dataFileFilter = dataFile => monthFilter(dataFile) && yearFilter(dataFile);
      filterSelectors = <span>{typeSelector} {yearSelector} {monthSelector}</span>;
    }
    const reportTypeFilter = selectedType === ALL_PLACEHOLDER ? () => true : report => selectedType === report.label;

    var renderedReports = labeledReports
      .filter(report => reportTypeFilter(report)) // Need to access reports before rendering
      .map((report, idx) =>
          renderReport(report, idx, subscribeToReport, unsubscribeToReport, this.category(), dataFileFilter))
      .filter(rendered => !!rendered); // Leave logic of rendering null in renderReport for now to minimize disruptive changes.

    if (renderedReports.length === 0) {
      renderedReports = <div className="empty-text">No {this.emptyText()} match the selected criterion.</div>;
    }

    return (
      <div className="report-container">
        {filterSelectors}
        {renderedReports}
      </div>
    );
  }
}

const renderReport = (report, idx, subscribeToReport, unsubscribeToReport, reportCategory, dataFileFilter) => {
  const { label, dataFiles, category } = report;

  if (!dataFiles) {
    return null;
  }

  const dataFilesFiltered = dataFiles.filter(dataFileFilter);
  if (dataFilesFiltered.length === 0) {
    return null;
  }

  return (
    <div className="report" key={idx}>
      <div className="title"><span>{label}</span>
        {subscribeToReportLink(report, subscribeToReport, unsubscribeToReport)}
      </div>
      { dataFilesFiltered
        .map((fileObj, idx) => {
          const href = fetchDocumentEndpoint(fileObj.file, category);
          var entries = [];
          entries.push(documentDownload(href, fileObj.file.name, fileObj.file.name, reportCategory, {key: idx * 3 + 1}));
          return entries;
        })}
    </div>
  );
};

function fetchDocumentEndpoint(file) {
  return `/api/report/download/${file.id}/${file.name}`;
}

function labelsWrapper(labels, isFreddie) {
  return (headingKey) => {
    const label = labels[headingKey];
    return isFreddie ? label && label.replace(/^MBS\s/, '') : label;
  };
}

function getMonthFromName(monthString) {
  var monthInt = -1;
  monthNames.forEach((mnth, idx) => {
    if (mnth === monthString) {
      monthInt = idx;
    }
  });
  return monthInt;
}
