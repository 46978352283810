import React from 'react';

export default ({children, className, onClose}) => {
  return (
    <div className="modal-wrap">
      <div className={`modal ${className}`}>
        <div className="close-button" onClick={onClose}><i className="fa fa-close"/></div>
        {children}
      </div>
    </div>
  );
}
