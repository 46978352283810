import React from 'react';

import { COLUMN_TYPES } from 'common/table/SimpleTable';
import { eventHandler } from 'common/util/events';

export default function Editable() {
  let setState, props;

  let state = {};

  const onTitleChange = (e) => {
    if (props.onTitleChange) {
      props.onTitleChange(e.target.value, props.config.title);
    }
  };

  return {
    key: 'editable',
    attach: function(featureSetState) {
      setState = featureSetState;
      return state;
    },
    onRender: function(newProps, config) {
      let { buttons = [], classes, tableConfig, title } = config;
      const { isEditing, selected, onCheckRow, onCheckRows, onDeletePortfolio, customizing } = newProps;

      props = newProps;

      classes.push({
        editing: isEditing
      });

      if (isEditing && !customizing) {
        const titleValue = title;

        title = <input type="text" value={titleValue} onChange={onTitleChange} />;

        buttons = buttons.concat(
          <button key="delete-portfolio" className="btn-danger" onClick={eventHandler(onDeletePortfolio, titleValue)}>Delete Portfolio</button>
        );

        const checkboxColumn = {
          type: COLUMN_TYPES.CHECKBOX,
          key: 'checkbox',
          sortable: false,
          className: 'center',
          width: '40px',
          forceVisible: true,
          visible: true,
          onCheckRow: (...args) => {
            return onCheckRow(titleValue, ...args);
          }
        };

        if (onCheckRows) {
          checkboxColumn.onCheckRows = (...args) => {
            return onCheckRows(titleValue, ...args);
          };
        }

        tableConfig.columns = [checkboxColumn, ...tableConfig.columns];
        tableConfig.selected = selected;
      }

      return {buttons, classes, tableConfig, title};
    }
  };
}