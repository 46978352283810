import React, { Component } from 'react';

import { TermsForm } from 'www/components/login/AcceptTermsForm';
import { fetchInitialData } from 'www/util/www-helpers';

export default class TermConditions extends Component {
  
  componentDidMount() {
    fetchInitialData(this.props);
  }

  
  render() {  
    const { settings, acceptTerms, cancel, onLogin, termsModif } = this.props;
    let loginForm;
    
    loginForm = <TermsForm termsAndConditions={settings.termsAndConditions} tcDate={settings.tcDate} onCancel={cancel} onAccept={acceptTerms} initialTC={true} showLogin={true} onLogin={onLogin}/>
    return <div className="modal-wrap login"> <div className="modal register-form">{loginForm}</div></div>;
  }

}
