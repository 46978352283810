import React from 'react';

import GroupedTable from 'common/table/GroupedTable';
import { currencyWithCents, formatDateMmmYyyy, integer, decimal } from 'common/util/common-formatters';
import { getTargetArray } from 'common/util/common-helpers';

import { getConfigs } from 'www/components/details/DistributionTable';

import { rate } from 'www/util/www-formatters';
import { isEsfPseudopool } from 'www/util/www-helpers';

import { mapColumns } from 'www/util/labels';

export default ({
      asOfDate,
      currentOrIssuance,
      dataKey,
      details,
      doNotShowIfEmpty,
      formatter = formatDateMmmYyyy,
      keyFlex = 0.5,
      label,
      month,
      monthly,
      monthsToAmortizationVisible,
      cityStateVisible,
      labelCity,
      labelState,
      title,
      visible
    }) => {

  if (visible === false) {
    return null;
  }

  const { data, key } = getConfigs({ dataKey, details, currentOrIssuance, monthly, month });

  if ((!data || data.length === 0) && doNotShowIfEmpty) {
    return null;
  }
  const secu = monthly.data[month] || details.data[currentOrIssuance];
  const isEsfP = secu ? isEsfPseudopool(secu) : false;

  const groupRmm = 'Remaining Months to Maturity';
  const groupLoanAge = 'Loan Age';
  const groupInterestRate = 'Interest Rate';

    // False only if the displayed security is a FNM ESF pseudo pool.
    // As of DIS-6949, WA columns are no longer shown on seller/servicer strats for pseudo pools.

    const columns = [
      { key: 'key', label, formatter, flex: keyFlex },
      { key: 'city', label: labelCity,   visible: cityStateVisible , className: 'right'},
      { key: 'state', label: labelState, visible: cityStateVisible , className: 'right'},
      { key: 'monthsToAmortization', formatter: integer, flex: 1.25, visible: monthsToAmortizationVisible, className: 'right' },
      { key: 'loanCountAggregate', formatter: integer, className: 'right' },
        { key: 'loanCountPercent', formatter: decimal, precision: 2, className: 'right' },
        isEsfP && { key: 'investorLoanUPBAggregate', label: 'AGGREGATE INVESTOR LOAN UPB WITH EXCESS CONTRIBUTION PERCENT', className: 'right', formatter: currencyWithCents  },
        isEsfP && { key: 'investorLoanUPBPercent', label: 'PERCENTAGE INVESTOR LOAN UPB WITH EXCESS CONTRIBUTION PERCENT', className: 'right', formatter: decimal, precision: 2 },
        !isEsfP && { key: 'investorLoanUPBAggregate', className: 'right', formatter: currencyWithCents},
        !isEsfP && { key: 'investorLoanUPBPercent', className: 'right', formatter: decimal, precision: 2},
        { key: 'remainingMonthsToMaturityMax', label: 'High',group: groupRmm, formatter: integer, flex: 0.75, className: 'right header-center' },
        { key: 'remainingMonthsToMaturityMin', label: 'Low', group: groupRmm, formatter: integer, flex: 0.75, className: 'right header-center' },
        !isEsfP && { key: 'remainingMonthsToMaturityWA', label: 'W.A.', title: 'Weighted Average', group: groupRmm, formatter: integer, flex: 0.75, className: 'right header-center' },
        { key: 'loanAgeMax', label: 'High', group: groupLoanAge, formatter: integer, flex: 0.75, className: 'right header-center' },
        { key: 'loanAgeMin', label: 'Low', group: groupLoanAge, formatter: integer, flex: 0.75, className: 'right header-center' },
        !isEsfP && { key: 'loanAgeWA', label: 'W.A.', title: 'Weighted Average', group: groupLoanAge, formatter: integer, flex: 0.75, className: 'right header-center' },
        { key: 'interestRateMax', label: 'High', group: groupInterestRate, formatter: rate, flex: 0.75, className: 'right header-center' },
        { key: 'interestRateMin', label: 'Low', group: groupInterestRate, formatter: rate, flex: 0.75, className: 'right header-center' },
        !isEsfP && { key: 'interestRateWA', label: 'W.A.', title: 'Weighted Average', group: groupInterestRate, formatter: rate, flex: 0.75, className: 'right header-center' },
        isEsfP && { key: 'investorLoanUPBAggregateNoExcess', className: 'right', formatter: currencyWithCents, emptyText: '$0.00'},
        isEsfP && { key: 'investorLoanUPBPercentNoExcess', className: 'right', formatter: decimal, precision: 2, emptyText: '0.00'}
    ];

  const config = { 
    className: 'distribution-table',
    emptyText: 'No Data',
    columnEmptyText: '-',
    columns: columns.filter(column => column !== false)
  };

  mapColumns('interestOnlyFirstPaymentStrat', config.columns);

  return (
    <div className="strat-container">
      <div className="subheading">{title} as of {asOfDate}</div>
      <GroupedTable config={config} data={data}/>
    </div>
  );
};
