import { SETTINGS_REQUEST, SETTINGS_RESPONSE, DISMISS_BANNER } from 'www/actions/settingsActions';
import { reducerGenerator, reduceReducers } from 'common/util/redux';


const initialState = {
  hasFetched: false,
  isFetching: false,
  sectionHeadingText: 'Welcome',
  announcements: [],
  links: [],
  documents: [],
  termsAndConditions: '...',
  privacy: '',
  idleTimeout: 30,
  taxInvestorPortal:'tax-investor-portal',
  dataDynamics:'data-dynamics',
  contactUs:'contact-us'
};

export default reducerGenerator(initialState, {
  [SETTINGS_REQUEST]: (state, action) => {
    return {...initialState, isFetching: true};
  },
  [SETTINGS_RESPONSE]: (state, action) => {
    return {...initialState, ...action.response, hasFetched: true, isFetching: false};
  }
});
