import React, { Component } from 'react';

import SimpleTable from 'common/table/SimpleTable';

import { currencyWithCents, formatDateMmDdYyyy, formatDateMmYyyy, zeroToEmpty } from 'common/util/common-formatters';
import { rate, cusipLink, dealLink, securityType } from 'www/util/www-formatters';
import { mapColumns } from 'www/util/labels';
import ENUMS from 'www/util/enumFormatters';

const { issuer, securityStatus } = ENUMS;

export default class Allocation extends Component {

  componentDidMount() {
    const { getAllocation, cusip, isRemic } = this.props;
    getAllocation(cusip, isRemic);
  }

  render() {
    const { allocation } = this.props;

    if (allocation.message) {
      return <div className="allocation empty-text">{allocation.message}</div>;
    }

    if (allocation.isFetching || !allocation.data) {
      return <div className="loading more"><i className="fa fa-spinner fa-pulse"/></div>;
    }

    const { singleClassAllocations, multiClassAllocations } = allocation.data;

    const singleClassGridConfig = {
      columns: [
        { key: 'issuer', formatter: issuer },
        { key: 'prefix' },
        { key: 'cusip', formatter: cusipLink },
        { key: 'secId' },
        { key: 'issrSpclSecuType', formatter: securityType },
        { key: 'netRate', formatter: rate, className: 'right', title: 'UPB-weighted average net interest rate, current month' },
        { key: 'issueDt', formatter: formatDateMmDdYyyy },
        { key: 'maturity', formatter: formatDateMmYyyy },
        { key: 'upbIssuance', formatter: currencyWithCents, className: 'right', title: 'Investor security UPB of this resecuritization, at issuance' },
        { key: 'upbCurrent', formatter: currencyWithCents, className: 'right', title: 'Investor security UPB of this resecuritization, current month' }
      ],
      columnEmptyText: '-',
      emptyText: 'No Data',
      title: 'Single-Class Resecuritization Allocation'
    };

    const multiClassGridConfig = {
      columns: [
        { key: 'trustNumber', formatter: dealLink },
        { key: 'groupId', formatter: zeroToEmpty, className: 'center header-center' },
        { key: 'issuer', formatter: issuer },
        { key: 'dealType', formatter: securityType },
        { key: 'issueDate', formatter: formatDateMmDdYyyy },
        { key: 'dealStatus', formatter: securityStatus }
      ],
      columnEmptyText: '-',
      emptyText: 'No Data',
      title: 'Multiclass Resecuritization Allocation'
    };

    mapColumns('allocationCharacteristics', singleClassGridConfig.columns);
    mapColumns('multiClassAllocationCharacteristics', multiClassGridConfig.columns);

    return (
      <div className="allocation-container">
        {getSimpleTableWithTitle(singleClassAllocations, singleClassGridConfig)}
        {getSimpleTableWithTitle(multiClassAllocations, multiClassGridConfig)}
      </div>
    );
  };
}

function getSimpleTableWithTitle(allocation, gridConfig) {
  if (allocation.length > 0){
    return (
      <>
        <div className="subheading">{gridConfig.title}</div>
        <SimpleTable config={gridConfig} data={allocation} />
      </>
    )
  }
}
