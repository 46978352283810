import { reducerGenerator, reduceReducers } from 'common/util/redux';
import {FNM_ANNOUNCEMENT_ARCHIVE_REQUEST, FNM_ANNOUNCEMENT_ARCHIVE_RESPONSE} from 'www/actions/fannieResourcesActions';

const initialState = {
    managedResourceDataList:[],
    hasFetched: false,
    isFetching: false,
}

export default reducerGenerator(initialState, {
    [FNM_ANNOUNCEMENT_ARCHIVE_REQUEST]: (state, action) => {
        return {...initialState, isFetching: true};
      },
    [FNM_ANNOUNCEMENT_ARCHIVE_RESPONSE]: (state, action) => {
        return {...initialState, ...action.response, hasFetched: true, isFetching: false};
      },      

});