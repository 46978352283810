import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table, { TYPES } from 'common/table/Table';
import Customizable from 'common/table/features/Customizable';
import { getMajors, sortMajors } from 'www/actions/searchActions';
import { cusipLink, yesNoIndicator, rate } from 'www/util/www-formatters';
import { currencyWithCents, formatDateMmYyyy, formatDateMmmm, monthNames } from 'common/util/common-formatters';
import { showLoginMessage, isLoggedIn, idleLogout, logout, cancelIdleLogout, keepSessionActive } from 'common/login/loginActions';
import { updateDocumentTitle } from 'www/util/www-helpers';
import { mapColumns } from 'www/util/labels';
import { getSettings  } from 'www/actions/settingsActions';
import {loadBanners } from 'www/actions/bannerAction';
import {fetchInitialData} from "../util/www-helpers";
import Header from 'www/containers/Header';
import Footer from 'www/components/Footer';
import IdleDetector from 'common/IdleDetector'
const PROFILES = {
  'fannie': {
    defaultTitle: 'Fannie Majors\u00AE',
    entityName: 'Majors'
  },
  'freddie': {
    defaultTitle: 'Freddie Multilenders\u00AE',
    entityName: 'Multilenders'
  }
}

class Majors extends Component {
  
  constructor(props) {
    super(props);
    this.organization = props.match.params.organization;
    this.defaultTitle = PROFILES[this.organization].defaultTitle;
    this.entityName   = PROFILES[this.organization].entityName;
    this.majorsConfig = {
      title: <div>{this.defaultTitle}</div>,
      className: 'results majors',
      table: {
        key: 'majors',
        columnDefaults: {
          sortable: true
        },
        columns: [
          { key: 'issueDt', formatter: formatDateMmYyyy, className: 'center header-center' },
          { key: 'acceptingDeliveries', formatter: yesNoIndicator, className: 'center header-center' },
          { key: 'prefixDescription', flex: 2 },
          { key: 'additionalSecurityDescription', emptyText: '', flex: 2 },
          { key: 'prefix', flex: 0.5, className: 'center header-center' },
          { key: 'netRate', formatter: rate, className: 'center header-center' },
          { key: 'secId', className: 'center header-center' },
          { key: 'cusip', formatter: cusipLink, className: 'center header-center' },
          { key: 'upb', formatter: currencyWithCents, className: 'right', title: 'Investor Security UPB - Issuance' }
        ],
        columnEmptyText: '-',
        customizable: false, 
        onHeaderClick: props.sortMajors
      }
    };

    mapColumns('majors', this.majorsConfig.table.columns);
  }

  UNSAFE_componentWillMount() {
    document.body.scrollTop = 0;
  }

  componentDidMount() {
    updateDocumentTitle(this.entityName);
    this.props.getMajors(this.organization);
    fetchInitialData(this.props);

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    document.body.scrollTop = 0;
  }

  render() {
    const { login, results, showLoginMessage, settings, idleLogout, logout, cancelIdleLogout, keepSessionActive } = this.props;
    const { params } = this.props.match;
    const { sort, majors, isLoadingMajors, hasError, message, exportLink, lastUpdated } = results;

    const hasMajors = results && results.majors && results.majors.majors && results.majors.majors.length !== 0;

    const tableProps = {
      ...this.props,
      loggedIn: login.loggedIn,
      onLoginClick: showLoginMessage
    };

    if (lastUpdated) {
        this.majorsConfig.title = (<div>{this.defaultTitle}<div className="custom-table-title">Last Update: {lastUpdated}</div></div>);
    }

      const majorsTable = hasMajors
              ? <Table type={TYPES.simple}
                       features={[Customizable]}
                       {...tableProps}
                       data={results.majors.majors}
                       sort={sort}
                       config={this.majorsConfig}
                       exportLink={exportLink}
                       exportName="majors.csv" />
              : null;

    const hasNotes = hasMajors && (majors.notes != undefined) && majors.notes.length > 0;

    const notesDiv = hasNotes
        ? <div className="notes">
            <div>Notes:</div>
            { majors.notes.map(value => <div dangerouslySetInnerHTML={{__html: value}}/>) }
          </div>
        : null;

    let errorMessage;
    if (hasError) {
      errorMessage = message || 'Something is technically wrong.'
    } else if (!hasMajors && !isLoadingMajors) {
      errorMessage = 'No ' + this.entityName.toLowerCase() + ' found';
    }

    if (isLoadingMajors) {
      return (<div className="loading more"><i className="fa fa-spinner fa-pulse"/></div>);
    } else {
      return (
        <div id="app" className={params.organization}>
          {login.loggedIn && <IdleDetector showIdleLogout={login.showIdleLogout}
                                     idleTimeout={settings.idleTimeout}
                                     idleAction={idleLogout}
                                     defaultAction={logout}
                                     cancelAction={cancelIdleLogout}
                                     keepSessionActive={keepSessionActive} />}
          <Header {...this.props} />    
          <div className="content majors">
            {errorMessage && <div className="empty-text">{errorMessage}</div>}
            {majorsTable}
            {notesDiv}
          </div>
          <Footer params={params} />
        </div>
      );
    }
  }
}

{/* <div className={classNames('app-content', {'login-visible': login.loginVisible})}>
<Header {...this.props} />
  {children && React.cloneElement(children, this.props)}
</div>
<Footer params={params} />
 */}
export default connect(
  ({ login, results, history, settings, globalBanners }) => ({ login, results, history, settings, globalBanners }),
  { getMajors, showLoginMessage, sortMajors, getSettings,  isLoggedIn, loadBanners, idleLogout, logout, cancelIdleLogout, keepSessionActive})(Majors);
