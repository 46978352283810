import { reducerGenerator } from 'common/util/redux';
import {
    GET_BANNER_RESPONSE,
    GET_BANNER_REQUEST,
    DISMISS_BANNER
  } from 'www/actions/bannerAction';

  const initialState = {
    banners:[],
    isFetching : false
  }

  export default reducerGenerator(initialState, {
    [GET_BANNER_REQUEST]: (state, action) => {
        return {...initialState, isFetching: true};
      },
    [GET_BANNER_RESPONSE]: (state, action) => ({...state, ...action.response})
    ,
  [DISMISS_BANNER]: (state, action) => {
    let { banners } = state;
    for(const ban of action.banners) {
        const idx = banners.findIndex((banner) => ( banner.id === ban.id )
        );

    if (idx !== -1) {
     // return {
     //   ...state,
       // globalBanners: banners.slice(0, idx).concat(banners.slice(idx+1))
       banners = banners.slice(0, idx).concat(banners.slice(idx+1));
      }
    }
    return {
        ...state,
        banners
    }
    }
  });