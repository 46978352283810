import {
    CURRENT_USER_REQUEST,
    CHECK_REGISTERED_REQUEST, CHECK_REGISTERED_RESPONSE, CHECK_REGISTERED_ERROR,
    REGISTER_REQUEST, REGISTER_RESPONSE, REGISTER_ERROR,REDIRECT_REQUEST,
    LOGIN_REQUEST, LOGIN_RESPONSE, LOGIN_ERROR, LOGIN_INACTIVE_MESSAGE,
    FORGOT_REQUEST, FORGOT_RESPONSE, FORGOT_ERROR,
    LOGOUT_REQUEST, LOGOUT_RESPONSE,ADMIN_LOGOUT_REQUEST,
    UPDATE_CREDENTIALS, UPDATE_CREDENTIALS_SUCCESS, UPDATE_CREDENTIALS_ERROR,
    KEEP_SESSION_ACTIVE,
    TERMS_ACCEPT, TERMS_ACCEPTED,
    VALIDATE_CSRF_REQUEST, VALIDATE_CSRF_RESPONSE,VALIDATE_CSRF_ERROR
} from 'common/login/loginActions';
import API from 'common/util/api';

import { getOrganizationFromLocation, insertInCopy, removeInCopy, slugAllTitles } from 'common/util/common-helpers';
import { reduceMiddleware } from 'common/util/redux';

function parseUserSettings(response) {
    const respSettings= JSON.parse(response.settings);
    return {
        ...(response.settings ? respSettings : {}),
        profile: respSettings.profile || response.profile || {}
    };
}

function redirectRequest(getState, next, {query}) {
}

function isLoggedIn(getState, next, action) {
    API.get(`user/${getOrganizationFromLocation()}/current`).then(
        (response) => {
            if (response.success !== false) {
            const settings = parseUserSettings(response);
            if (settings) {
                slugAllTitles(settings.portfolio);
            }
            next({...action, type: LOGIN_RESPONSE, response: settings, reportSubscriptions: response.reportSubscriptions});
            }
        },
    ignore => null // don't need to do anything if not logged in
    );
}

function checkRegisteredRequest(getState, next, action) {
    API.get(`user/${getOrganizationFromLocation()}/checkRegistration`, {email: action.email}).then(
        (response) => {
        next({...action, type: CHECK_REGISTERED_RESPONSE, response});
},
    (message) => {
        next({...action, type: CHECK_REGISTERED_ERROR, message, available: false});
    }
);
}

function registerRequest(getState, next, action) {
    const params = {
        email: action.email,
        password: action.newPass,
        captchaResponse: action.captchaResponse,
        ...distillProfile(action)
    };

    API.post(`user/${getOrganizationFromLocation()}/register`, params).then(
        (response) => {
        if (response.success !== false) {
        const settings = parseUserSettings(response);
        next({...action, type: REGISTER_RESPONSE, response: settings});
    } else {
        next({...action, type: REGISTER_ERROR, message: response.message});
    }
},
    (message) => {
        next({...action, type: REGISTER_ERROR, message});
    }
);
}

function loginRequest(getState, next, action) {
    API.post(`user/${getOrganizationFromLocation()}/login`, {email: action.email, password: action.password, captchaResponse: action.captchaResponse}).then(
        (response) => {
        if (response.success !== false) {
        const settings = parseUserSettings(response);
        if (settings) {
            slugAllTitles(settings.portfolio);
        }
        next({...action, type: LOGIN_RESPONSE, response: settings, reportSubscriptions: response.reportSubscriptions});
    } else {
        if(response.emailValidated !== false) {
            next({...action, type: LOGIN_ERROR, message: response.message});
        } else {
            next({...action, type: LOGIN_INACTIVE_MESSAGE, response});
        }
    }
},
    (message) => {
        next({...action, type: LOGIN_ERROR, message});
    }
);
}

//forgot password functionality
function forgotPwdRequest(getState, next, action) {
    API.post(`user/${getOrganizationFromLocation()}/resetPassword`, {email: action.email, captchaResponse: action.captchaResponse}).then(
        (response) => {
        if (response.success !== false) {
        next({type: FORGOT_RESPONSE});
    } else {
        next({...action, type: FORGOT_ERROR, message: response.message});
    }
},
    (message) => {
        console.warn('Issue with Forgot password Link, Please try later');
    }
);
}


function logoutRequest(getState, next, action) {
    API.post(`user/${getOrganizationFromLocation()}/logout`).then(
        (response) => {
        next({type: LOGOUT_RESPONSE, idleMessage: action.idleMessage});
},
    (message) => {
        console.warn('Logout did not succeed');
    }
);
}

function validateCsrfRequest(getState, next, action) {
    API.post(`user/${getOrganizationFromLocation()}/validateCsrf`).then(
        (response) => {
        next({type: VALIDATE_CSRF_RESPONSE});
},
    (message) => {
        //validateCsrfToken and forward error if it is not valid
        if(message.startsWith('403:')){
            next({type: VALIDATE_CSRF_ERROR, message});
        }else{
            next({type: VALIDATE_CSRF_RESPONSE});
        }

    }
);
}

function logoutAdminRequest(getState, next, action) {
    API.post(`user/logout`).then(
        (response) => {
       	var logoutLocation = window.location.protocol + "//" + window.location.host + "/logout.html";
           window.location = logoutLocation;
   },
    (message) => {
        console.warn('Admin Logout did not succeed');
    }
);
}

function updateCredentials(getState, next, action) {
    const { account : {profile : {email}} } = getState();
    const url = `user/${getOrganizationFromLocation()}/changeCredentials`;
    API.post(url, {email, ...action.newCredentials}).then(
        (response) => {
        next({type: UPDATE_CREDENTIALS_SUCCESS});
},
    (response) => {
        response = JSON.parse(response);
        next({type: UPDATE_CREDENTIALS_ERROR, response});
    }
);
}

function acceptTerms(getState, next, action) {
    const url = `user/${getOrganizationFromLocation()}/acceptTermsAndConditions`;
    API.post(url).then(
        (response) => {
        next({type: TERMS_ACCEPTED});
}
);
}

function keepSessionActive(getState, next, action) {
    const msSinceSessionActive = Date.now() - API.lastValidResponse.getTime();
    const sessionIdleThresholdMs = action.idleTimeoutMinutes * 1000 * 60 / 10; // if we're 1/10 on way to session timeout, keep alive

    if (msSinceSessionActive < sessionIdleThresholdMs) {
        return;
    }

    console.log('Sending keep session active ping.');

    API.get('search/time', undefined, {raw: true}).then(
        response => null,
        message => {
        console.log('Error returned trying to keep session alive: ' + message);
    }
);
}


export function distillProfile(params) {
    return {
        email: params.email,
        firstName: params.firstName,
        lastName: params.lastName,
        primaryPhone: params.primaryPhone,
        city: params.city,
        state: params.state,
        zipCode: params.zipCode,
        organization: params.organization,
        organizationType: params.organizationType,
        countryCode: params.countryCode
    };
}

export default reduceMiddleware({
    [CURRENT_USER_REQUEST]: isLoggedIn,
    [CHECK_REGISTERED_REQUEST]: checkRegisteredRequest,
    [REGISTER_REQUEST]: registerRequest,
    [LOGIN_REQUEST]: loginRequest,
    [FORGOT_REQUEST]: forgotPwdRequest,
    [LOGOUT_REQUEST]: logoutRequest,
    [REDIRECT_REQUEST]: redirectRequest,
    [ADMIN_LOGOUT_REQUEST]:logoutAdminRequest,
    [UPDATE_CREDENTIALS]: updateCredentials,
    [KEEP_SESSION_ACTIVE]: keepSessionActive,
    [TERMS_ACCEPT]: acceptTerms,
    [VALIDATE_CSRF_REQUEST]: validateCsrfRequest
});
