import React, {Component, useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import App from 'www/containers/App';
import TermConditions from 'www/components/TermConditions';
import { getOrganizationFromLocation } from 'common/util/common-helpers';
import Header from 'www/containers/Header';
import Footer from 'www/components/Footer';
import { isLoggedIn, showLogin, logout, checkEmail, close, login, validateCsrf } from 'common/login/loginActions';
import { getSettings } from 'www/actions/settingsActions';
import {loadBanners} from 'www/actions/bannerAction';

import { redirectToHome, checkTC, createTCCookie,  } from 'www/util/tc-helpers';


const initialState = {
  tcStatus: false
};

class AppRoute extends Component {
    
  constructor(props){ 
    super(props);
    this.props = props;
    this.params = props.computedMatch? props.computedMatch.params : null;

  }
    
  acceptTCFunc = ()=>{
    createTCCookie();
    this.setState({tcStatus: true});
  }
    
    state = initialState;
    
    
    render() {
        const { path, loginState, validateCsrf, settings, needTC=true,  ...rest } = this.props ;
        const { tcStatus } = this.state;

        const showTC = needTC ? !checkTC() : false;

    <App/>
    
    let pageContent;
    if(showTC) {
      pageContent = <div id="app" className={getOrganizationFromLocation()}>
         <Header {...this.props} params={this.params} />    
         {!loginState.loginVisible && <TermConditions {...this.props} settings={settings} acceptTerms={this.acceptTCFunc} cancel={redirectToHome} onLogin={validateCsrf} />}
         <Footer params={this.params} />
      </div>
      
    } else {
      pageContent = <Route
      path={path}
      {...rest}
      render={props => <Component {...props} />}
    />
    }
    
    return (
    <div>
      {pageContent}
    </div>
  )};

}

export default connect(
  ({login, settings, globalBanners}) => ({ loginState: login, settings, globalBanners }),
  { getSettings, showLogin, login, isLoggedIn, validateCsrf, loadBanners }
)(AppRoute)

