import { push } from 'react-router-redux';

import API from 'common/util/api';


import {
  getOrganizationFromLocation,
  getParams,
  getQueryString
} from 'common/util/common-helpers';

import { reduceMiddleware } from 'common/util/redux';

import { DELETE_HISTORY } from 'www/actions/historyActions';

import { SEARCH_REQUEST,
  SEARCH_COUNT_REQUEST,
  SEARCH_COUNT_RESPONSE,
  SEARCH_COUNT_ERROR,
  SEARCH_NAVIGATION,
  SEARCH_RESPONSE,
  SEARCH_ERROR,
  LOAD_MORE_RESULTS_REQUEST,
  LOAD_MORE_RESULTS_RESPONSE,
  MAJORS_REQUEST,
  MAJORS_RESPONSE,
  MAJORS_ERROR,
  SEARCH_REDIRECT
} from 'www/actions/searchActions';

import { getDetailOrDealUrl, redirectToDeal, getDealUrlForClasses,getDealUrlForSingleDeal, checkTrustNumberIdentical,checkTrustNumberIdenticalForDealIdSearch,getArrayTrustIdsFromResponse } from 'www/util/www-helpers';
import {securityType} from 'www/util/www-formatters';
import browserHistory from 'www/components/history/browserHistory';



const PAGE_SIZE_KEY = 'max_results';
const PAGE_KEY = 'page';
const SORT_FIELD_KEY = 'sortField';
const SORT_DIR_KEY = 'sortAsc';



function navigate(getState, next, {query, history}) {
  const queryString = getQueryString(query);
  const searchQueryChanged = location.search !== queryString;
  if (searchQueryChanged) {
    const org = getOrganizationFromLocation();
    history.push(`/${org}/search${queryString}`);
  }
}

let getUrlParams  = query => Object.fromEntries(new URLSearchParams(query ? query.replace('?','') : ""));

function getPagedParams({query, pageIdx, pageSize, field, direction}) {
  return getParams({...getUrlParams(query), [PAGE_KEY]: pageIdx, [PAGE_SIZE_KEY]: pageSize, [SORT_FIELD_KEY]: field, [SORT_DIR_KEY]: direction === 'asc'});
}

function extractParams(getState, action) {
  const query = action && action.query ? action.query : getState().history.present[0] ? getState().history.present[0].query  : 'undefined'
  const sort = action.sort || getState().results.sort; // Consider default if needed...
  const org = getOrganizationFromLocation();
  return {
    query,
    org,
    paged: getPagedParams({query, ...action, ...sort})
  };
}

function searchCount(getState, next, action) {
  const params = extractParams(getState, action);
  API.get(`search/${params.org}/count`, params.paged).then(
    response => next({...action, type: SEARCH_COUNT_RESPONSE, count: response.count, redirect: response.redirect}),
    message => next({...action, type: SEARCH_COUNT_ERROR, message})
  );
}


function search(getState, next, action) {
  const params = extractParams(getState, action);
  const innerHistory = action.history;

  let modifiedSort = params.paged.sortField;
  if (modifiedSort && modifiedSort !== 'cusip') {
    modifiedSort += ', cusip';
  }
  params.paged.sortField = modifiedSort;
  API.get(`search/${params.org}`, params.paged).then(
      response => {
        next({...action, type: SEARCH_RESPONSE, results: response || [], query: params.query});
      },
      message => next({...action, type: SEARCH_ERROR, message})
  );
}

function searchRedirect(getState, next, action) {
  const params = extractParams(getState, action);
  const innerHistory = action.history;
  const response = action.results.data;

  if (response) {
    if (!redirectToDeal(params.query)  && response && response.length === 1 && action.pageIdx === 1) {
      next({type: DELETE_HISTORY, query: params.query});
      innerHistory.push((getDetailOrDealUrl(response[0])));
    } else if(response && redirectToDeal(params.query) && response.length >= 1) {

      innerHistory.push(getDealUrlForClasses(response[0], params.query));
    } else {
      let arrayTrustIds = getArrayTrustIdsFromResponse(response);

      if(checkTrustNumberIdenticalForDealIdSearch(arrayTrustIds) && action.results.pageIdx === 1 && action.results.redirect) {
        next({type: DELETE_HISTORY, query: params.query});
        innerHistory.push(getDealUrlForSingleDeal(response[0], params.query));
      }else {
        next({...action, type: SEARCH_RESPONSE, results: response || [], query: params.query});
      }
    }
  }



}

function majors(getState, next, action) {
  const org = getOrganizationFromLocation();
  API.get(`search/majors/${org}`).then(
    (response) => {
      return next({...action, type: MAJORS_RESPONSE, majors: response});
    },
    (message) => {      
      return next({...action, type: MAJORS_ERROR, message})
    }
  );
}

export default reduceMiddleware({
  [SEARCH_COUNT_REQUEST]: searchCount,
  [SEARCH_REQUEST]: search,
  [SEARCH_NAVIGATION]: navigate,
  [MAJORS_REQUEST]: majors,
  [SEARCH_REDIRECT]: searchRedirect
});
