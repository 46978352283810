import React, { Component } from 'react';
import {PersonalInfo} from 'www/components/account/PersonalInfo';

export default class Profile extends Component {

  constructor(props) {
    super(props);
    this.state = {...this.props.account.profile};
  }

  render() {
    const { updateProfile, account } = this.props;
    const { isFetching } = this.props.login;

    return (
      <div className="profile">
        <PersonalInfo profileFields={account.profile}
                      isFetching={isFetching}
                      message={account.message}
                      updateProfile={updateProfile} />
      </div>
    );
  }
}
