import React, { Component } from 'react';

import Table, { TYPES } from 'common/table/Table';
import Customizable from 'common/table/features/Customizable';
import { currencyWithCents, formatDateMmDdYyyy } from 'common/util/common-formatters';
import { getOrganizationCodeFromLocation } from 'www/util/www-helpers';
import { mapColumns } from 'www/util/labels';

export default class MajorsPanel extends Component {

  constructor(props, context) {
    super(props, context);

    this.gridConfig = {
      key: 'majors',
      columnDefaults: {
        sortable: false
      },
      customizable: false,
      columns: [
        { key: 'date', formatter: formatDateMmDdYyyy },
        { key: 'balance', formatter: currencyWithCents, className: 'right'}
      ],
      emptyText: '-'
    };

    mapColumns('detailMajors', this.gridConfig.columns);
  }

  componentDidMount() {
    this.props.getDetailsMajors(this.props.cusip);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    document.body.scrollTop = 0;
  }

  render() {

    const { cusip, majors } = this.props;
    const { params } = this.props.match;
    const { data, isFetching, message } = majors;

    if (message) {
      return <div className="empty-text">{message}</div>;
    } else if (isFetching || !data) {
      return <div className="loading more"><i className="fa fa-spinner fa-pulse"/></div>;
    } else if (data.length === 0) {
      return <div className="tax empty-text">Balances are not available for this security</div>;
    }

    const tableProps = {
      ...this.props,
      type: TYPES.simple,
      //features: [Customizable],
      config: {
        title: 'Major Balances',
        className: 'detail-majors-table',
        table: {
          ...this.gridConfig
        },
      },
      data,
      exportLink: `/api/security/${getOrganizationCodeFromLocation()}/${cusip}/majors/export`,
      exportName: `${cusip}-major-balances.csv`
    };

    return (
      <div className="detail-majors-container">
        <Table {...tableProps} />
      </div>
    );
  };
}
