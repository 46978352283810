const CryptoJS = require('crypto-js')

import {
  LOGOUT_RESPONSE, REGISTER_RESPONSE, LOGIN_RESPONSE,ADMIN_LOGOUT_RESPONSE
} from 'common/login/loginActions';
import {
  APPLY_SETTINGS_REQUEST, APPLY_SETTINGS_RESPONSE, APPLY_SETTINGS_RESPONSE_TEMP, APPLY_SETTINGS_ERROR,
  RESTORE_PREFERENCES, PORTFOLIO_SUMMARY_REQUEST, PORTFOLIO_SUMMARY_RESPONSE, SHOW_DELETE_CUSTOM_VIEW, HIDE_DELETE_CUSTOM_VIEW,
  TAX_DATA_FILES_LIST_REQUEST, TAX_DATA_FILES_LIST_RESPONSE, TAX_DATA_FILES_LIST_ERROR,
  SUBSCRIBE_TO_REPORT_REQUEST, SUBSCRIBE_TO_REPORT_RESPONSE, SUBSCRIBE_TO_REPORT_ERROR, 
  UNSUBSCRIBE_TO_REPORT_REQUEST, UNSUBSCRIBE_TO_REPORT_RESPONSE, UNSUBSCRIBE_TO_REPORT_ERROR, 
  SHOW_COPY_CUSTOM_VIEW, HIDE_COPY_CUSTOM_VIEW, SHOW_SAVEAS_CUSTOM_VIEW, HIDE_SAVEAS_CUSTOM_VIEW,
  RESET_PASSWORD_REQUEST, RESET_PASSWORD_RESPONSE, RESET_PASSWORD_ERROR, ALL_PORTFOLIO_SUMMARIES_REQUEST, PORTFOLIO_SUMMARY_ERROR
} from 'www/actions/accountActions';
import { setInCopy, toLookup } from 'common/util/common-helpers';
import { reducerGenerator } from 'common/util/redux';
import { trackUser } from 'common/util/analytics';
import { getEmailActivationMsg } from 'common/login/login-messages';
import { convertToRefName } from 'www/util/www-helpers';

const initialState = {
  isFetching: false,
  hasError: false,
  deleteVisible: false,
  message: '',
  preferences: {},
  profile: {}
};

function onLogin(state, action) {
  const preferences = { ...action.response.preferences } || {};
  const preferenceKeys = Object.keys(preferences);

  if (preferenceKeys.length !== 0) {
    preferenceKeys.forEach((key) => {
      if (Array.isArray(preferences[key]) && (typeof preferences[key][0] === 'string')) {
        preferences[key] = [{ ['Custom View']: preferences[key] }];
      }
    });
  }

  const { profile } = action.response;
  if (profile && profile.email) {
    // GA rules require we don't send PII as user id, so send hash of email instead    
    const hashed = CryptoJS.SHA1(profile.email).toString();
    trackUser(hashed);
  }
  
  const reportSubsLookup = toLookup(action.reportSubscriptions);
  const reportSubscriptionsTrans = {};
  for (const[key, value] of Object.entries(reportSubsLookup)) {
     const newKey = convertToRefName(key);
     reportSubscriptionsTrans[newKey] = convertToRefName(value);
  }
  
  return {
    ...action.response,
    isFetching: false,
    hasError: false,
    message: '',
    preferences, 
    savedPreferences: preferences,
    portfolioSummaries: {},
    reportSubscriptions: reportSubscriptionsTrans,
    subscribingToReports: {}
  };
}

function onSubscribedToReport(state, action) {

  let { reportSubscriptions, subscribingToReports } = state;
  const reportType = action.reportType;
  
  subscribingToReports = {...subscribingToReports};
  delete subscribingToReports[reportType];

  return {
    ...state,
    reportSubscriptions: {...reportSubscriptions, [reportType]: true },
    subscribingToReports
  };
}

function onUnsubscribedToReport(state, action) {

  let { reportSubscriptions, subscribingToReports } = state;
  const reportType = action.reportType;

  reportSubscriptions = {...reportSubscriptions};
  delete reportSubscriptions[reportType];

  subscribingToReports = {...subscribingToReports};
  delete subscribingToReports[reportType];

  return {
    ...state,
    reportSubscriptions,
    subscribingToReports
  };
}

function onResetPassword(state, action){
  return {
      ...state,
      hasError: false,      
      loggedIn: false,
      loginVisible: true,
      email: action.email       
    };
}

export default reducerGenerator(initialState, {
  LOGOUT_RESPONSE: (state, action) => initialState,
  REGISTER_RESPONSE: (state, action) => ({...state, hasError: false, message: getEmailActivationMsg()}),
  LOGIN_RESPONSE: onLogin,
  SHOW_DELETE_CUSTOM_VIEW: (state, action) => ({ ...state, message: action.message, okAction: action.okAction, cancelAction: action.cancelAction, deleteVisible: true }),
  HIDE_DELETE_CUSTOM_VIEW: (state, action) => ({ ...state, deleteVisible: false }),
  SHOW_COPY_CUSTOM_VIEW: (state, action) => ({ ...state, message: action.message, okAction: action.okAction, cancelAction: action.cancelAction, copyVisible: true }),
  HIDE_COPY_CUSTOM_VIEW: (state, action) => ({ ...state, copyVisible: false }),
  SHOW_SAVEAS_CUSTOM_VIEW: (state, action) => ({ ...state, message: action.message, okAction: action.okAction, cancelAction: action.cancelAction, saveAsVisible: true }),
  HIDE_SAVEAS_CUSTOM_VIEW: (state, action) => ({ ...state, saveAsVisible: false }),
  //APPLY_SETTINGS_REQUEST: (state, action) => ({...state, isFetching: true}),
  APPLY_SETTINGS_RESPONSE_TEMP: (state, action) => ({...state, ...action.response, isFetching: false, hasError: false, message: ''}),
  APPLY_SETTINGS_ERROR: (state, action) => ({...state, isFetching: false, hasError: true, message: action.message}),
  APPLY_SETTINGS_RESPONSE: (state, action) => ({...state, ...action.response, savedPreferences: { ...action.response.preferences }, isFetching: false, hasError: false, message: 'Information updated successfully.'}),
  RESTORE_PREFERENCES: (state, action) => ({...state, preferences: {...state.savedPreferences}}),
  ALL_PORTFOLIO_SUMMARIES_REQUEST: (state, action) => ({...state, isFetching: true, hasError: false}),
  PORTFOLIO_SUMMARY_REQUEST: (state, action) => ({...state, isFetching: true, hasError: false}),
  PORTFOLIO_SUMMARY_RESPONSE: (state, action) => {
    let { portfolio, portfolioSummaries } = state;
    let { currentPortfolio } = action;
    currentPortfolio = currentPortfolio || {}; 
    if(currentPortfolio.portfolio == undefined){
    	currentPortfolio.portfolio = [];
    }
    let currentIds = currentPortfolio.portfolio;

    (action.response || []).forEach((summary) => {
      if (summary.cusip){
        portfolioSummaries[summary.cusip] = summary;

        if(currentIds.indexOf(summary.cusip)<0){
          currentIds.push(summary.cusip);
        }
      }
    });

    return {...state, portfolioSummaries, portfolio, isFetching: false, hasError: false};
  },
  PORTFOLIO_SUMMARY_ERROR: (state, action) => ({...state, isFetching: false, hasError: true}),

  TAX_DATA_FILES_LIST_REQUEST: (state, action) => ({...state, isFetching: true, hasError: false}),
  TAX_DATA_FILES_LIST_RESPONSE: (state, action) => ({...state, ...action.response, isFetching: false, hasError: false}),
  TAX_DATA_FILES_LIST_ERROR: (state, action) => ({...state, isFetching: false, hasError: true, message: action.message}),

  SUBSCRIBE_TO_REPORT_REQUEST: (state, action) => ({... state, subscribingToReports: {...state.subscribingToReports, [action.reportType]: true } }),
  SUBSCRIBE_TO_REPORT_RESPONSE: onSubscribedToReport,
  SUBSCRIBE_TO_REPORT_ERROR: onUnsubscribedToReport,
  UNSUBSCRIBE_TO_REPORT_REQUEST: (state, action) => ({...state, subscribingToReports: {...state.subscribingToReports, [action.reportType]: true } }),
  UNSUBSCRIBE_TO_REPORT_RESPONSE: onUnsubscribedToReport,
  UNSUBSCRIBE_TO_REPORT_ERROR: onSubscribedToReport,
  [RESET_PASSWORD_REQUEST]: (state, action) => ({...state, email: action.email}),
  [RESET_PASSWORD_RESPONSE]: onResetPassword,
  [RESET_PASSWORD_ERROR]: (state, action) => ({...state})
});