import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget} from 'react-dnd';
import classNames from 'classnames';

import HeaderCell from 'common/table/HeaderCell';
import { columnValue } from 'common/table/Cell';
import throttle from 'lodash.throttle';

class DraggableHeaderCell extends Component {

  render() {
    const { connectDropTarget, connectDragSource, isDragging, column, rows, config } = this.props;

    const ref = (instance) => {
      if (!instance) {
        return;
      }

      const node = findDOMNode(instance);
      connectDragSource(node);
      connectDropTarget(node);
    };
    const value = rows && rows[0] && columnValue(rows[0], column, 0, config) || '-';

    const decoratedColumn = {
      ...column,
      className: classNames(column.className, column.ghost, {'is-dragging': isDragging})
    };

    return (
      <HeaderCell {...this.props} ref={ref} column={decoratedColumn}>
        <div className="first-row-customize" key="data">{value}</div>
      </HeaderCell>
    );
  }
}

export default 
  DragSource('visible-column', {
    beginDrag(props) {
      const { index, column } = props;
      return {
        id: column.key,
        index
      };
    }
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }))(
    DropTarget(
      props => ['visible-column', 'hidden-column'],
      {
        canDrop() {
          return false;
        },
        hover: throttle(
            (props, monitor, component) => {
              const { column, index: hoverIndex } = props;
              const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
              const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
              const clientOffset = monitor.getClientOffset();
        
              if (clientOffset) {
                const hoverClientX = clientOffset.x - hoverBoundingRect.left;
        
                column.moveGhost(hoverClientX < hoverMiddleX ? 'before' : 'after', hoverIndex);
              }
            },200
          )
      },
      (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver()
      }))(
      DraggableHeaderCell
    )
  );