import React from 'react';
import { Link } from 'react-router-dom';

import { eventHandlerWithPrevent } from 'common/util/events';
import { getDetailsUrl, getSearchLocationObject } from 'www/util/www-helpers';
import { MULTI_RESULT } from 'www/reducers/historyReducer';

export default ({item, type, title, id, org, onClose, query, description, clickable}) => {
  const url = type === MULTI_RESULT ? getSearchLocationObject(query) : getDetailsUrl(id, org);
  const icon = type === MULTI_RESULT ? 'fa fa-search' : 'fa fa-file-text-o';
  
  return (
    <li>
      <Link to={url} onClick={clickable ? null : eventHandlerWithPrevent()} tabIndex={clickable ? 0 : -1}>
        <i className={icon} /> {title}
        {description.map((desc, idx) => (<p key={idx} className="description">{desc}</p>))}
        <i className="fa fa-close" onClick={eventHandlerWithPrevent(onClose, item)} />
      </Link>
    </li>
  );
};
