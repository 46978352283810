import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import {
  getAllocation,
  getDetails, 
  sortDetails,
  getCollateral,
  getCollateralCount,
  loadMoreCollateral,
  getDataFiles,
  fetchMonthlyData,
  getTaxCount,
  fetchTaxData,  
  taxSort, 
  taxYearSelect,
  getSecurityDocuments,
  getDetailsMajors,
  getDetailTaxFactCount,
  changePage
} from 'www/actions/detailsActions';

import {
  addToPortfolio,
  addResultsToPortfolio,
  removeFromPortfolio,
  updatePreferences,
  restorePreferences,
  savePreferences,
  removePreferences,
  copyPreferences,
  filterPreferences,
  showCopyCustomView,
  hideCopyCustomView,
  showSaveAsCustomView,
  hideSaveAsCustomView,
  showDeleteCustomView,
  hideDeleteCustomView
} from 'www/actions/accountActions';
import { showLoginMessage } from 'common/login/loginActions';
import { clearHistory, deleteHistoryItem } from 'www/actions/historyActions';
import AddToPortfolioButton from 'www/components/account/AddToPortfolioButton';
import DetailTabs from 'www/components/details/DetailTabs';
import DetailHeader from 'www/components/details/DetailHeader';
import OffPlatformHeader from 'www/components/details/OffPlatformHeader';
import { History, updateHistoryNavHeight } from 'www/components/history/History';
import { cusipLink } from 'www/util/www-formatters';
import { eventHandler } from 'common/util/events';
import { updateDocumentTitle, isOffPlatformSecurity } from 'www/util/www-helpers';
import { isCusipInPortfolio } from 'www/util/portfolio';
import ConfirmationModal from 'www/components/account/ConfirmationModal';
import Header from 'www/containers/Header';
import Footer from 'www/components/Footer';
import { getSettings  } from 'www/actions/settingsActions';
import {loadBanners } from 'www/actions/bannerAction';
import { isLoggedIn, idleLogout, logout, cancelIdleLogout, keepSessionActive } from 'common/login/loginActions';
import {fetchInitialData} from "../util/www-helpers";
import IdleDetector from 'common/IdleDetector';

class Details extends Component {

  constructor(props){ 
    super(props);
    this.props = props;
    this.resultId = props.match.params.resultId;
    this.params = props.match.params

  }
  state = { activeTab: 1 };

  UNSAFE_componentWillMount() {
    document.body.scrollTop = 0;
  }

  componentDidMount() {
    const { details, getDetails, getDetailTaxFactCount } = this.props;
    updateDocumentTitle(this.resultId);
    fetchInitialData(this.props);
    if (!details.isFetching) {
      getDetails(this.resultId);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { getDetails } = this.props;
    const {resultId}  = nextProps.match.params;
    if ( resultId !== this.resultId) {
      this.resultId = resultId;
      updateDocumentTitle(resultId);
      getDetails(resultId);
      return false;
    }

    return true;
  }


  componentDidUpdate() {
    updateHistoryNavHeight('security-details');
  }

  render() {
    const { 
      login, account, showLoginMessage, sortDetails, details, restorePreferences,
      settings, idleLogout, logout, cancelIdleLogout, keepSessionActive
    } = this.props;

    let { data, isFetching, hasError, message } = details;

    const classes = classNames('security-details', {
      loading: isFetching,
      'empty-text': hasError
    });

    let children = [];

    if (data) {

      const { issuance, current, issuanceNotes, ongoingNotes } = data;
      
      if (isOffPlatformSecurity(issuance)) {
        children.push(
          <div className="details-wrapper" key="details">
            <OffPlatformHeader {...issuance} />
          </div>
        );
      } else {
        const cusip = issuance.cusip;
        const detailsProps = {
          ...this.props,
          loggedIn: login.loggedIn,
          preferences: account.preferences,
          onLoginClick: showLoginMessage,
          cancelBtnClick: restorePreferences,
          cusip,
          onHeaderClick: sortDetails
        };

        // button will not render for ESF pseudo pool
        const headerButtons = <AddToPortfolioButton key="portfolio-add" {...detailsProps} />;

        children.push(
          <div className="details-wrapper" key="details">
            <ConfirmationModal {...this.props} />
             <DetailHeader issuance={issuance} current={current || {}} issuanceNotes={issuanceNotes} ongoingNotes={ongoingNotes} buttons={headerButtons} />
            <DetailTabs 
              {...detailsProps} />
          </div>
        );
      }
    }

    if (isFetching) {
      children.push(<i key="loading" className="fa fa-spinner fa-pulse"/>);
    } else if (hasError) {
      children = message || 'Something is technically wrong.';
    }

    return (
      <div id="app" className={this.params.organization}>
        {login.loggedIn && <IdleDetector showIdleLogout={login.showIdleLogout}
                                         idleTimeout={settings.idleTimeout}
                                         idleAction={idleLogout}
                                         defaultAction={logout}
                                         cancelAction={cancelIdleLogout}
                                         keepSessionActive={keepSessionActive} />}
         <Header {...this.props} params={this.params} />    
         <div className="content">
              <History {...this.props} />
              <main className={classes}>{children}</main>
        </div>
        <Footer params={this.params} />
      </div>
    );
  }
}


export default connect(
  ({ security, login, account, history, settings, globalBanners }) => ({ ...security, history, login, account, settings, globalBanners }),
  {
    getAllocation,
    getDetails,
    sortDetails,
    getCollateralCount,
    getCollateral,
    getDataFiles,
    showLoginMessage,
    clearHistory,
    deleteHistoryItem,
    loadMoreCollateral,
    addToPortfolio,
    addResultsToPortfolio,
    removeFromPortfolio,
    updatePreferences,
    restorePreferences,
    savePreferences,
    removePreferences,
    fetchMonthlyData,
    getTaxCount,
    fetchTaxData,    
    taxSort,
    taxYearSelect,
    getSecurityDocuments,
    getDetailsMajors,    
    copyPreferences,
    filterPreferences,
    showCopyCustomView,
    hideCopyCustomView,
    showSaveAsCustomView,
    hideSaveAsCustomView,
    showDeleteCustomView,
    hideDeleteCustomView,
    getDetailTaxFactCount,
    getSettings,
    isLoggedIn,
    loadBanners,
    idleLogout,
    logout,
    cancelIdleLogout,
    keepSessionActive,
    changePage
  }
)(Details);