require('es6-shim');

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import { BrowserRouter as Router, Route , Switch, withRouter } from 'react-router-dom';
import { syncHistoryWithStore, routerReducer, routerMiddleware } from 'react-router-redux';
import browserHistory from 'www/components/history/browserHistory';

import Splash from 'www/containers/Splash';
import App from 'www/containers/App';
import Results from 'www/containers/Results';
import Details from 'www/containers/Details';
import FannieArchivedAnnouncements from 'www/containers/fannieArchivedAnnouncements'

import Deal from 'www/containers/Deal';
import Majors from 'www/containers/Majors';
import IconsDev from 'www/containers/IconsDev';

import Help from 'www/components/Help';
import Account from 'www/components/Account';
import Documents from 'www/components/Documents';
import PrivacyPolicy from 'www/components/PrivacyPolicy';
import FileDownload from 'www/components/FileDownload';
import FannieResources from "./containers/fannieResources";

import allocationReducer from 'www/reducers/security/allocationReducer';
import dealReducer from 'www/reducers/deal/dealReducer';
import dealDocumentsReducer from 'www/reducers/deal/dealDocumentsReducer';
import detailsReducer from 'www/reducers/security/detailsReducer';
import collateralReducer from 'www/reducers/security/collateralReducer';
import dataFilesReducer from 'www/reducers/security/dataFilesReducer';
import monthlyReducer from 'www/reducers/security/monthlyReducer';
import securityDocumentsReducer from 'www/reducers/security/securityDocumentsReducer';
import taxReducer from 'www/reducers/security/taxReducer';
import majorsReducer from 'www/reducers/security/majorsReducer';
import accountReducer from 'www/reducers/accountReducer';
import documentsReducer from 'www/reducers/documentsReducer';
import historyReducer from 'www/reducers/historyReducer';
import loginReducer from 'common/login/loginReducer';
import reportsReducer from 'www/reducers/reportsReducer';
import resultsReducer from 'www/reducers/resultsReducer';
import settingsReducer from 'www/reducers/settingsReducer';
import fannieResourcesReducer from 'www/reducers/fannieResourcesReducer';
import fannieArchiveResourcesReducer from 'www/reducers/fannieArchieveResourceReducer';
import fannieResLinksReducer from "./reducers/fannieResLinksReducer";
import bannerReducer from 'www/reducers/bannerReducer'
import accountMiddleware from 'www/middleware/accountMiddleware';
import dealMiddleware from 'www/middleware/dealMiddleware';
import detailsMiddleware from 'www/middleware/detailsMiddleware';
import documentsMiddleware from 'www/middleware/documentsMiddleware';
import loggerMiddleware from 'common/util/loggerMiddleware';
import loginMiddleware from 'common/login/loginMiddleware';
import reportsMiddleware from 'www/middleware/reportsMiddleware';
import searchMiddleware from 'www/middleware/searchMiddleware';
import settingsMiddleware from 'www/middleware/settingsMiddleware';
import fannieResourcesMiddleware from 'www/middleware/fannieResourcesMiddleware';
import bannerMiddleware from 'www/middleware/bannerMiddleware'
import undoMiddleware from 'www/middleware/undoMiddleware';
import AppRoute from 'www/containers/AppRoute';
import fannieResLinksMiddleware from "./middleware/fannieResLinksMiddleware";
const reducer = combineReducers({
    history: historyReducer,
    security: combineReducers({
    allocation: allocationReducer,
    details: detailsReducer,
    collateral: collateralReducer,
    dataFiles: dataFilesReducer,
    monthly: monthlyReducer,
    tax: taxReducer,
    documents: securityDocumentsReducer,
    majors: majorsReducer
  }),
  deal: combineReducers({
    details: dealReducer,
    documents: dealDocumentsReducer
  }),
  reports: reportsReducer,
  results: resultsReducer,
  account: accountReducer,
  login: loginReducer,
  routing: routerReducer,
  settings: settingsReducer,
  fannieResources: fannieResourcesReducer,
  fannieArchiveResources: fannieArchiveResourcesReducer,
  fannieResLinks: fannieResLinksReducer,
  globalBanners: bannerReducer,
  documents: documentsReducer
});

const middlewareArray = [
  dealMiddleware,
  detailsMiddleware,
  searchMiddleware,
  accountMiddleware,
  loginMiddleware,
  settingsMiddleware,
  fannieResourcesMiddleware,
  fannieResLinksMiddleware,
  reportsMiddleware,
  documentsMiddleware,
  undoMiddleware,
  bannerMiddleware,
  routerMiddleware(browserHistory)
];
if (__DEV__) {
  middlewareArray.push(loggerMiddleware);
}

const createStoreWithMiddleware = applyMiddleware(...middlewareArray)(createStore);
 const store = createStoreWithMiddleware(reducer);
 const history = syncHistoryWithStore(browserHistory, store);



const provider = (
  <Provider store={store}>
    <div>
      <Router history={history}>
          <Switch>
              <Route exact path="/:organization" component={withRouter(Splash)} />
              <Route exact path="/:organization/loginPage" component={withRouter(Splash)} />
              <AppRoute exact path="/:organization/majors" component={withRouter(Majors)} needTC={false}/>
              <AppRoute exact path="/:organization/multilenders" component={withRouter(Majors)} needTC={false} />
              <AppRoute path="/:organization/announcements" component={withRouter(FannieArchivedAnnouncements)} needTC={false}/>
              <AppRoute path="/:organization/products" component={withRouter(FannieResources)} needTC={false}/>
              <AppRoute path="/:organization/learning" component={withRouter(FannieResources)} needTC={false}/>
              <AppRoute exact path="/:organization/account/:currAccount/:currSubAccount/:currSubSubAccount" component={Account} needTC={false} />
              <AppRoute exact path="/:organization/account/:currAccount/:currSubAccount" component={Account} needTC={false}/>
              <AppRoute exact path="/:organization/account/:currAccount" component={Account} needTC={false}/>
              <AppRoute path="/:organization" component={withRouter(App)}>
                  <AppRoute path="/:organization/search" component={withRouter(Results)}/>
                  <AppRoute path="/:organization/deal/:trustNumber" component={Deal} />
                  <AppRoute exact path="/:organization/details/:resultId" component={Details} />

                  <AppRoute path="/:organization/documents(/:group)" component={Documents} />
                  <AppRoute path="/:organization/privacy-policy" component={PrivacyPolicy} />
                  <AppRoute path="/:organization/help(/:helpItem)(/:helpSubItem)" component={Help}/>
                  <AppRoute path="/:organization/icons" component={IconsDev} /> 
                  <AppRoute path="/:organization/filedownload" component={FileDownload} /> 
              </AppRoute>
          </Switch>
      </Router>
    </div>
  </Provider>
);

ReactDOM.render(provider, document.getElementById('react'));

