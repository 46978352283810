import React, { Component } from 'react';

import Table, { TYPES } from 'common/table/Table';
import { formatDateMmDdYyyy } from 'common/util/common-formatters';
import { cusipLink } from 'www/util/www-formatters';
import { getOrganizationFromLocation, maxKey } from 'common/util/common-helpers';
import { factor, taxFactor } from 'www/util/www-formatters';
import { currencyWithCents, percent, percentNoSymbol } from 'common/util/common-formatters';
import { mapColumns } from 'www/util/labels';
import { isRemicSecurity, getOrganizationCodeFromLocation, isFreddie, getIssuerLegalAddress } from 'www/util/www-helpers';
import { HeaderField } from 'www/components/details/DetailHeader';
import DealTaxPanelChild  from 'www/components/deal/DealTaxPanel';

export default class DealTaxPanel extends Component {

    constructor(props ) {
        super(props);

        this.gridConfig = {
            key: 'tax',
            columnDefaults: {
                sortable: true
            },
            columns: [
                { key: 'accrualPeriodDays', flex: 0.5, className: 'right' },
                { key: 'accrualPeriodStartDate', formatter: formatDateMmDdYyyy },
                { key: 'accrualPeriodEndDate', formatter: formatDateMmDdYyyy },
                { key: 'taxPaymentDate', formatter: formatDateMmDdYyyy },
                { key: 'endingUPBFactor', formatter: factor, className: 'right'  },
                { key: 'qsiFactor', formatter: taxFactor, className: 'right' },
                { key: 'oidFactor', formatter: taxFactor, className: 'right' },
                { key: 'otherIncomeExpenseFactor', formatter: taxFactor, className: 'right' },
                { key: 'sec212Factor', formatter: taxFactor, className: 'right' },
                { key: 'beginningAipFactor', formatter: taxFactor, className: 'right' },
                { key: 'mdar', formatter: taxFactor, className: 'right' },
                { key: 'realEstatePct', flex: 0.5, formatter: percentNoSymbol, precision: 2, className: 'right' }
            ],
            emptyText: 'No tax data available'
        };

        mapColumns('tax', this.gridConfig.columns);
    }


    render() {
        const { cusip, tax, cusipTaxData, footerText, isComboClass, settings, taxSort, taxYearSelect } = this.props;
        const { isFetching, message, url } = tax;
        let { data } = tax;
        const selectedYearData = cusipTaxData || []
        const headerData = (selectedYearData && selectedYearData.length > 0? (selectedYearData[selectedYearData.length-1] || {}) : {});
        
        if (message) {
            return <div className="empty-text">{message}</div>;
        } else if (isFetching || !cusipTaxData) {
            return <div className="loading more"><i className="fa fa-spinner fa-pulse"/></div>;
        } else if (url) {
            return <div className="tax empty-text">For Tax Factors for this security, please go to the following location:
                <div className="static-tax-url"><a href="{url}">{url}</a></div>
            </div>
        } else if (cusipTaxData.length === 0) {
            return <div className="tax empty-text">Security Data for Searched Year Not Yet Available </div>;
        }

        let currentClassificationType = '';
        let formattedFooterText = [];
        if(cusipTaxData.length) {
            currentClassificationType = headerData.classificationType? headerData.classificationType : cusipTaxData[0].classificationType ? cusipTaxData[0].classificationType : '';
            formattedFooterText = footerText && footerText[currentClassificationType] && footerText[currentClassificationType].length ? footerText[currentClassificationType] : [];
        }
        return (
            <div className="tax-full-container">

                { this.renderSecurityTax(cusipTaxData, 0, formattedFooterText, isComboClass, cusip, settings, tax, taxSort, taxYearSelect) }


            </div>
        );
    }

    // Function to calculate latest MortgageBackedSecurityTaxIdentifier based on latest month of latest year.
      calculateLatestMortgageBackedSecurityTaxIdentifier (years, recombinableTax) {
        let latestMortgageBackedSecurityTaxIdentifier = '';
        try {
          if (!!years && years.length > 0) {
            const intYears = years.map(Number);
            if (intYears && intYears.length > 0) {
              const latestYear = Math.max(...intYears);
              if (latestYear > 0) {
                const latestYearData = recombinableTax[latestYear];
                const taxMonths = latestYearData.map(lyd => lyd.taxMonth);
                if (taxMonths && taxMonths.length > 0) {
                  const latestYearMonth = Math.max(...taxMonths);
                  const latestMortgageBackedSecurityTaxIdentifierData = (latestYearMonth && latestYearMonth > 0) ? latestYearData.filter((lyd) => {
                    if (lyd.taxMonth === latestYearMonth) {
                      return lyd.mortgageBackedSecurityTaxIdentifier;
                    }
                  }) : {};
                  if(JSON.stringify(latestMortgageBackedSecurityTaxIdentifierData) !== JSON.stringify({})) {
                    latestMortgageBackedSecurityTaxIdentifier = latestMortgageBackedSecurityTaxIdentifierData[0].mortgageBackedSecurityTaxIdentifier;
                  }
                }
              }
            }
          } else {
            const latestYearData = recombinableTax;
                const taxMonths = latestYearData.map(lyd => lyd.taxMonth);
                if (taxMonths && taxMonths.length > 0) {
                  const latestYearMonth = Math.max(...taxMonths);
                  const latestMortgageBackedSecurityTaxIdentifierData = (latestYearMonth && latestYearMonth > 0) ? latestYearData.filter((lyd) => {
                    if (lyd.taxMonth === latestYearMonth) {
                      return lyd.mortgageBackedSecurityTaxIdentifier;
                    }
                  }) : {};
                if(JSON.stringify(latestMortgageBackedSecurityTaxIdentifierData) !== JSON.stringify({})) {
                    latestMortgageBackedSecurityTaxIdentifier = latestMortgageBackedSecurityTaxIdentifierData[0].mortgageBackedSecurityTaxIdentifier;
                }
            }
          }
        } 
        catch (e) {
          latestMortgageBackedSecurityTaxIdentifier = '';
        }
        return latestMortgageBackedSecurityTaxIdentifier;
      }

    calculateCusipTaxData(cusip) {
        const dealTax = this.props.details.dealTax.data;
        const dealTaxByCusip = this.props.details.dealTax.dataByCusip;
        const footerText = this.props.details.dealTax.footerText;
        const  timeStamp = new Date();
        const currentYear = timeStamp.getFullYear();
        const prevYear = currentYear-1;
        const currentMonth = timeStamp.getMonth() + 1;
        let cusipListTaxData = dealTaxByCusip[cusip];        
        //dealTax.forEach(item => Object.keys(item).map(itm => item[itm]).forEach(innerItem => cusipListTaxData = cusipListTaxData.concat(innerItem)));
        let isComboClass = false;
        cusipListTaxData.filter((data) => {
            if(data.cusip === cusip && data.classificationType === 'Combo Class') {
                isComboClass = true;
                return isComboClass;
            }
        });
        let currentYearCusipTaxData = cusipListTaxData;
        let cusipFooterText = footerText;

        return {"cusipTaxData": currentYearCusipTaxData, "footerText": cusipFooterText, "isComboClass": isComboClass};
    }

    renderSecurityTax(recombinableTax, securityIndex, formattedFooterText, isComboClass, cusip, settings, tax, taxSort, taxYearSelect) {
        // const {cusip, settings, tax, taxSort, taxYearSelect} = this.props;
        const { sorts, selectedYears} = tax;

        const sort = sorts[securityIndex];
        const selectedYear = selectedYears[securityIndex];
        const selectedYearData = recombinableTax || [];

        const taxYearSelectProxy = obj => {
            obj.securityIndex = securityIndex;
            return taxYearSelect(obj);
        };

        const taxSortProxy = column => taxSort(column, securityIndex);
        let years = [];
        const timeStamp = new Date();
        const currentYear = timeStamp.getFullYear();
        const currentMonth = timeStamp.getMonth() +1;
        if(currentMonth === 1) {
            years = Object.keys(selectedYearData);
        }
        let trustTIN = this.calculateLatestMortgageBackedSecurityTaxIdentifier(years, selectedYearData);


        
        const headerData = (selectedYearData && selectedYearData.length > 0? (selectedYearData[selectedYearData.length-1] || {}) : {});
        const isRecombinableCombo = selectedYearData.length === 1 && securityIndex === 0;
        let recombinableExplanation;
        let table;

        const trustId = headerData.trustId ? headerData.trustId : '';
        const classId = headerData.classId ? headerData.classId : '';
        const isRemic = headerData.securityType ? isRemicSecurity({secType: headerData.securityType}) : false;
        const idValue = isRemic ? `${trustId} ${classId}` : headerData.secId;
        let cusipFormatter = cusipLink;
        if (cusip === headerData.cusip || headerData.doNotDiscloseType === 'SUPPRESS_ALL_DISCLOSURES') {
            cusipFormatter = null;
        }

        const headers =
            [
                <HeaderField colKey="cusip" label="CUSIP" value={headerData.cusip} formatter={cusipFormatter}/>,
                <HeaderField colKey="headerId" label="Pool/Series and Class" value={idValue}/>,
                <HeaderField colKey="classificationType" label="Security Classification"
                             value={headerData.classificationType}/>
            ];


            const tableProps = {
                ...this.props,
                type: TYPES.simple,
                config: {
                    title: <span>  </span>,
                    table: {
                        ...this.gridConfig,
                        onHeaderClick: taxSortProxy
                    }
                },
                data: selectedYearData,
                sort
            };

            const dynamicHeaders = [];
            const histTaxUrl  = isRemic ? "http://www.freddiemac.com/mbs/html/sd_remic_lookup.html" : "http://www.freddiemac.com/mbs/html/sd_pc_lookup.html" ;

            if (isFreddie()){
                dynamicHeaders[dynamicHeaders.length] = <HeaderField colKey="issueAmount1"
                                                                     label="Historical Tax Disclosures (pre-2019)."
                                                                     value={<a href = {histTaxUrl}
                                                                               target ="_blank">{histTaxUrl} </a>}
                                                                     formatter={currencyWithCents} />
            }


        headers.splice(headers.length,
            0,
            [
                <HeaderField colKey="reportingType" label="Class Issued with" value={headerData.reportingType}/>,
                <HeaderField colKey="qualStatedIntType" label="Interest Class" value={headerData.qualStatedIntType}/>,
                <HeaderField colKey="interestAccrualMethod" label="Interest Accrual Method"
                             value={headerData.interestAccrualMethod}/>,
                <HeaderField colKey="issueAmount" label="Original Amount" value={headerData.issueAmount}
                             formatter={currencyWithCents}/>
            ],
            dynamicHeaders);

        recombinableExplanation = null;
        if (!isComboClass) {
            table = <Table {...tableProps}  />;
        } else {
            const childCusips = this.props.cusipMappingList ? this.props.cusipMappingList : [];
            table = childCusips.map((cusip, i) => {
                const cusipTaxData = this.calculateCusipTaxData(cusip);
                let currentClassificationType = '';
                let formattedFooterText = [];
                if(cusipTaxData.length) {
                    currentClassificationType = headerData.classificationType? headerData.classificationType : cusipTaxData[0].classificationType ? cusipTaxData[0].classificationType : '';
                    formattedFooterText = cusipTaxData.footerText && cusipTaxData.footerText[currentClassificationType] && cusipTaxData.footerText[currentClassificationType].length ? cusipTaxData.footerText[currentClassificationType] : [];
                }
                return <div key={i}>
                    <DealTaxPanelChild
                        key={i}
                        tax={this.props.tax}
                        settings={this.props.settings}
                        taxSort={this.props.taxSort}
                        taxYearSelect={this.props.taxYearSelect}
                        cusip={cusip}
                        cusipTaxData={cusipTaxData.cusipTaxData}
                        footerText={cusipTaxData.footerText}
                        isComboClass={cusipTaxData.isComboClass}
                        cusipMappingList={[]}
                    />
                    <div className="footer tax-footer">
                        <p>{formattedFooterText[0]}</p>
                        <hr />
                        <p>{formattedFooterText[1]}</p>
                        <p>{formattedFooterText[2]}</p>
                        <p><strong>{formattedFooterText[3]}</strong></p>
                    </div>
                </div>;
            });
        }


        if(trustTIN.length <= 0) {
          trustTIN = headerData.mortgageBackedSecurityTaxIdentifier;
        }

        return  <div className={`tax-container security-index-${securityIndex}`} key={'sec' + securityIndex}>
            <div className="tax-header">
                <ul className="tax-col-2">
                    <HeaderField colKey="mortgageBackedSecurityTaxIdentifier" label="Trust TIN" value={trustTIN} />
                    <HeaderField colKey="issuerLegalAddress" label="Issuer" value={getIssuerLegalAddress(settings, headerData.issuer)} />
                </ul>
                <ul className="tax-col-1">
                    { headers }
                </ul>
                { recombinableExplanation }
            </div>
            {isComboClass && formattedFooterText.length ?
            <div className="footer tax-footer">
                <div>Notes:</div>
                <div>{formattedFooterText[0]}</div>
                <div>{formattedFooterText[1]}</div>
                <div>{formattedFooterText[2]}</div>
                <div>{formattedFooterText[3]}</div>
            </div> : ' '
            }
            <div  className="tax-table">
                { table }
            </div>
            {!isComboClass && formattedFooterText.length ?
                <div className="footer tax-footer">
                    <div>Notes:</div>
                    <div>{formattedFooterText[0]}</div>
                    <div>{formattedFooterText[1]}</div>
                    <div>{formattedFooterText[2]}</div>
                    <div>{formattedFooterText[3]}</div>
                </div> : ' '
            }
        </div>;
    };
}

// for recombinables put the parent security at top
// and others below that sorted by cusip. This work
// needs to be done here instead of in the reducer
// because in the reducer we don't know parent cusip.
function createRecombinableTaxSorter(cusip) {
    return function recombinableTaxSorter(x, y) {

        if (x === y) {
            return 0;
        }

        // x and y are individual security tax data,
        // each is a hash with years as keys
        // and then array of months
        // so this awesome expression below evaluates to
        // x['2015'][0] <- get me first month of 2015
        const cusipX = x[Object.keys(x)[0]][0].cusip;
        const cusipY = y[Object.keys(y)[0]][0].cusip;

        if (cusipX === cusipY) {
            return 0;
        }

        if (cusipX === cusip) {
            return -1;
        }

        if (cusipY === cusip) {
            return 1;
        }

        return cusipX < cusipY ? -1 : 1;
    }
}
