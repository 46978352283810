import React from 'react';

import SimpleTable from 'common/table/SimpleTable';
import { decimal, currencyWithCents } from 'common/util/common-formatters';
import { getTargetArray } from 'common/util/common-helpers';
import { isEsfPseudopool } from 'www/util/www-helpers';

import { rate } from 'www/util/www-formatters';
import { mapColumns } from 'www/util/labels';

export default ({
      asOfDate,
      currentOrIssuance,
      formatter,
      dataKey,
      details,
      label,
      month,
      monthly,
      onHeaderClick,
      title,
      stratType,
      hideIfEmpty=false
    }) => {

  const { data, dataKeyExists, key, sort } = getConfigs({ dataKey, details, currentOrIssuance, monthly, month, hideIfEmpty });
  if (!dataKeyExists) {
    return null;
  }
  const secu = monthly.data[month] || details.data[currentOrIssuance];
  const isEsfP = secu ? isEsfPseudopool(secu) : false;

  const sortable = true;

  const columns = [
      { key: 'key', label, title: title || label, formatter, sortable, showNotAvailable: true },
      isEsfP && { key: 'investorLoanUPBAggregate', label: 'AGGREGATE INVESTOR LOAN UPB WITH EXCESS CONTRIBUTION PERCENT', title: 'Unpaid Principal Balance with Excess Contribution Percent', className: 'right', formatter: currencyWithCents, sortable, emptyText: '$0' },
      isEsfP && { key: 'investorLoanUPBPercent', label: 'PERCENTAGE INVESTOR LOAN UPB WITH EXCESS CONTRIBUTION PERCENT', title: 'Percent Unpaid Principal Balance with Excess Contribution Percent', className: 'right', precision: 2, formatter: decimal, sortable, emptyText: '0.00' },
      !isEsfP && { key: 'investorLoanUPBAggregate', title: 'Unpaid Principal Balance', className: 'right', formatter: currencyWithCents, sortable, emptyText: '$0' },
      !isEsfP && { key: 'investorLoanUPBPercent', title: 'Percent Unpaid Principal Balance', className: 'right', formatter: decimal, precision: 2, sortable, emptyText: '0.00' },
      { key: 'loanCountAggregate', className: 'right', sortable, emptyText: '0' },
      { key: 'loanCountPercent', title: 'Percent Loan Count', className: 'right', formatter: decimal, precision: 2, sortable, emptyText: '0.00' },
      (stratType === 'adr') &&  { key: 'totalDeferralAmountAggregate', label: 'AGGREGATE TOTAL DEFERRAL AMOUNT ', title: 'Aggregate Total Deferral Amount', className: 'right', formatter: currencyWithCents, sortable, emptyText: '$0' },
      isEsfP && { key: 'investorLoanUPBAggregateNoExcess', title: 'Unpaid Principal Balance', className: 'right', formatter: currencyWithCents, sortable, emptyText: '$0.00' },
      isEsfP && { key: 'investorLoanUPBPercentNoExcess', title: 'Percent Unpaid Principal Balance', className: 'right', formatter: decimal, precision: 2, sortable, emptyText: '0.00' }
  ];
  
  const gridConfig = {
    sortKey: key,
    className: 'distribution-table',
    columns: columns.filter(column => column !== false),
    columnEmptyText: '-',
    emptyText: 'No Data',
    onHeaderClick
  };

  mapColumns('distribution', gridConfig.columns);

  return <div className="strat-container">
    <div className="subheading">{title} as of {asOfDate}</div>
    <SimpleTable config={gridConfig} data={data} sort={sort[key]} />
  </div>;
};

export const getConfigs = ({ dataKey, details, currentOrIssuance, monthly, month, hideIfEmpty }) => {
  let key, data, sort, dataKeyExists = true;

  if (currentOrIssuance) {
    key = `${currentOrIssuance}.distributionsMap.${dataKey}`;
    data = getTargetArray(details.data, key);
    sort = details.sort;
  } else {
    key = `${month}.distributionsMap.${dataKey}`;
    data = getTargetArray(monthly.data, key);
    sort = monthly.sort;
  }

  if (!data || (hideIfEmpty && data.length==0) ) {
    data = [];
    dataKeyExists = false;
  }

  return { key, data, sort, dataKeyExists };
};
