import React, { Component } from 'react';
// import { push } from 'react-router-redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { showLoginMessage, updateCredentials } from 'common/login/loginActions';
import { getOrganizationFromLocation } from 'common/util/common-helpers';

import { getSettings  } from 'www/actions/settingsActions';
import {loadBanners} from 'www/actions/bannerAction';
import { isLoggedIn, idleLogout, logout, cancelIdleLogout, keepSessionActive } from 'common/login/loginActions';
import {fetchInitialData} from "www/util/www-helpers";
import IdleDetector from 'common/IdleDetector';

import { updateProfile, 
         updatePortfolio, 
         getPortfolioSummaries,
         getAllPortfolioSummaries,
         restorePreferences,
         copyPreferences,
         savePreferences,
         updatePreferences,
         filterPreferences,
         removePreferences,
         showDeleteCustomView,
         hideDeleteCustomView,
         showCopyCustomView,
         hideCopyCustomView,
         showSaveAsCustomView,
         hideSaveAsCustomView,
         getTaxDataFilesList,
         subscribeToReport,
         unsubscribeToReport
      } from 'www/actions/accountActions';

import { getGroupYearReports, getGroupReports } from 'www/actions/reportsActions';
import Alerts from 'www/components/account/Alerts';
import { Reports } from 'www/components/account/Reports';
import TaxDataFiles from 'www/components/account/TaxDataFiles';
import Portfolio from 'www/components/account/Portfolio';
import Profile from 'www/components/account/Profile';
import ResetPassword from 'www/components/account/ResetPassword';
import { updateDocumentTitle } from 'www/util/www-helpers';
import Header from 'www/containers/Header';
import Footer from 'www/components/Footer';

class Account extends Component {

  constructor(props){
    super(props);
    this.params = props.match.params;
    this.organization = getOrganizationFromLocation()
  }
  componentDidMount() {
    fetchInitialData(this.props);
  }
  
  UNSAFE_componentWillMount() {
    updateDocumentTitle('Account');
  }

  render() {

    const { account, settings, idleLogout, logout, cancelIdleLogout, keepSessionActive } = this.props;
    const { params } = this.props.match;
    const { currAccount, organization } = params;
    const { portfolio } = this.props.account;
    const { loggedIn, showIdleLogout } = this.props.login;
    
    let links = accountLinks.map((link) => {
      if(link.key === 'portfolio' && portfolio && portfolio.length > 0){
        const sublinks = portfolio.map((p) => {
            return { key: p.slug, label: p.title };
        });
        link.sublist = !!loggedIn ? sublinks : [];
      }
      return link;
    });

    const currLink = links.find((link) => (link.key === currAccount));

    let section;

    if (!loggedIn) {
      section = <div className="error-text">To use this feature you must first be logged in</div>;
      updateDocumentTitle('Account');
    } else if (!currLink) {
      section = <div className="error-text">Page not found</div>;
      updateDocumentTitle('Account');
    } else {
      section = React.createElement(currLink.cmp, this.props);
      updateDocumentTitle(currLink.label);
    }

    const generateLinkWithDefaultsIfNeeded = link => {
      if (link.condition && !link.condition()) {
        return null;
      }
      let route = `/${organization}/account/${link.key}`;
      if (link.key === 'datafiles') {
        route += `/singleclass/issuance`;
      } else if (link.key === 'taxdata') {
        route += `/single_class`; // Must be aligned with key-label mappings...
      } else if (link.key === 'portfolio') {
        const firstPortfolioLink = this.props.account && this.props.account.portfolio && this.props.account.portfolio.length > 0 ? `/${this.props.account.portfolio[0].slug}` : '';
          route += firstPortfolioLink;
      }else if(link.key=== 'miscellaneous'){
        route+=`/documents`;
      }
      return <Link to={route}>{link.label}</Link>;
    };

    return (
      <div id="app" className={this.organization}>
        {loggedIn && <IdleDetector showIdleLogout={showIdleLogout}
                                         idleTimeout={settings.idleTimeout}
                                         idleAction={idleLogout}
                                         defaultAction={logout}
                                         cancelAction={cancelIdleLogout}
                                         keepSessionActive={keepSessionActive} />}
         <Header {...this.props} params={this.params} />    
          <div className="content account">
            <header>
              <h1>Account</h1>
            </header>
            <aside>
              <ul>
                { links.map((link) => (
                  <li key={link.key} className={currAccount === link.key ? 'active': ''}>
                    {generateLinkWithDefaultsIfNeeded(link)}
                    <SubList link={link} params={params} account={account} />
                  </li>))
                }
              </ul>
            </aside>
            <main>{section}</main>
          </div>
          <Footer params={this.params} />
      </div>
    );
  }
}

const SubList = ({link, params, account}) => {
  if (!link || link.sublist === undefined || link.sublist.length === 0) {
    return null;
  }

  const sublist = typeof link.sublist === 'function'
    ? link.sublist({link, ...params, ...account})
    : link.sublist;

  // if func generated list, theoretically future implementation could return null
  if (sublist === undefined) {
    return null;
  }

  if (!Array.isArray(sublist)) {
    console.error(`Account sublist for ${link.key} is not array`, sublist);
    return null;
  }

  const { currAccount, currSubAccount, organization } = params;

  const generateSubLink = (link, subitem) => {
    if (link.key === 'datafiles' && subitem.key !== 'exchanges') {
      return <Link to={`/${organization}/account/${link.key}/${subitem.key}/issuance`}>{subitem.label}</Link>;
    } else {
      return <Link to={`/${organization}/account/${link.key}/${subitem.key}`}>{subitem.label}</Link>;
    }
  };

  return  <ul>
             { sublist.filter(subitem => (!subitem.condition || subitem.condition())).map(subitem => (
             <li key={subitem.key} className={currAccount === link.key && currSubAccount === subitem.key ? 'active' : ''}>
               {generateSubLink(link, subitem)}
               <LeafList parentLink={link.key} link={subitem} params={params} account={account} />
             </li>
           ))}  
          </ul>;    
};

const LeafList = ({link, params, account, parentLink}) => {
  if (!link || link.sublist === undefined || link.sublist.length === 0) {
    return null;
  }

  const sublist = typeof link.sublist === 'function'
    ? link.sublist({link, ...params, ...account})
    : link.sublist;

  // if func generated list, theoretically future implementation could return null
  if (sublist === undefined) {
    return null;
  }

  if (!Array.isArray(sublist)) {
    console.error(`Account sublist for ${link.key} is not array`, sublist);
    return null;
  }

  const { currAccount, currSubAccount, currSubSubAccount, organization } = params;
  return  <ul>
    { sublist.map(subitem => (
      <li key={subitem.key} className={currSubAccount === link.key && currSubSubAccount === subitem.key ? 'active' : ''}>
        <Link to={`/${organization}/account/${parentLink}/${link.key}/${subitem.key}`}>{subitem.label}</Link>
      </li>
    ))}
  </ul>;
};

/*
const PortfolioLinks = ({link, organization, currAccount, currSubAccount, portfolio}) => {

  if (!Array.isArray(portfolio) || portfolio.length < 2) {
    return null;
  }


  return (
    <ul>
      { portfolio.map(p => (
          <li key={p.title} className={currAccount === link.key && currSubAccount === p.slug ? 'active': ''}>
            <Link to={`/${organization}/account/${link.key}/${p.slug}`}>{p.title}</Link>
          </li>
        ))
      }
    </ul>
    );
};
*/

const accountLinks = [
  { key: 'alerts', label: 'Alerts', cmp: Alerts, sublist: [] },
  { key: 'datafiles', label: 'Data Files and Reports', cmp: Reports, sublist: [
    { key: 'singleclass', label: 'Single-Class/Single-Class Resecuritization', cmp: Reports,
      sublist: [
        { key: 'issuance', label: 'Issuance' },
        { key: 'monthly', label: 'Monthly' },
        { key: 'other', label: 'Other' },
        { key: 'socialDisclosures', label: 'Social Disclosures' }
      ] },
    { key: 'multiclass', label: 'Multi-Class', cmp: Reports,
      sublist: [
        { key: 'issuance', label: 'Issuance' },
        { key: 'monthly', label: 'Monthly' },
        { key: 'reset', label: 'Reset' }
      ] },
    { key: 'exchanges', label: 'Exchanges', cmp: Reports,
      condition: () => (getOrganizationFromLocation() === 'freddie'), sublist : []}   
  ] },
  { key: 'taxdata', label: 'Tax Data', cmp: TaxDataFiles,
    sublist: [
      { key: 'single_class', label: 'Single-Class' },
      { key: 'multi_class', label: 'Multi-Class' }
    ] },
  { key:'miscellaneous', label: 'Miscellaneous', cmp: Reports, sublist:[]},  
  { key: 'portfolio', label: 'Portfolio', cmp: Portfolio,
    sublist: []/*({portfolio}) => (
   { key: portfolio.slug, label: portfolio.title }
   )*/
  },
  { key: 'profile', label: 'Profile', cmp: Profile, sublist: [] },
  { key: 'resetPassword', label: 'Reset Password', cmp: ResetPassword, sublist: [] }
];

export default  connect(
  ({login, account, settings, reports, reportSubscriptions, globalBanners}) => ({ login, account, settings, reports, reportSubscriptions, globalBanners }),
  { updateProfile, updatePortfolio, getPortfolioSummaries, getAllPortfolioSummaries, getGroupYearReports, getGroupReports, showLoginMessage,
    restorePreferences, copyPreferences, updateCredentials, savePreferences, updatePreferences, filterPreferences, removePreferences, showDeleteCustomView,
    hideDeleteCustomView, showCopyCustomView, hideCopyCustomView, showSaveAsCustomView, hideSaveAsCustomView, getTaxDataFilesList, subscribeToReport, unsubscribeToReport,
    getSettings, isLoggedIn, loadBanners, idleLogout, logout, cancelIdleLogout, keepSessionActive }
)(Account);
