export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_RESPONSE = 'SEARCH_RESPONSE';
export const SEARCH_REDIRECT = 'SEARCH_REDIRECT';
export const SEARCH_ERROR = 'SEARCH_ERROR';
export const SEARCH_COUNT_REQUEST = 'SEARCH_COUNT_REQUEST';
export const SEARCH_COUNT_RESPONSE = 'SEARCH_COUNT_RESPONSE';
export const SEARCH_COUNT_ERROR = 'SEARCH_COUNT_ERROR';
export const SEARCH_NAVIGATION = 'SEARCH_NAVIGATION';
export const MAJORS_REQUEST = 'MAJORS_REQUEST';
export const MAJORS_RESPONSE = 'MAJORS_RESPONSE';
export const MAJORS_ERROR = 'MAJORS_ERROR';
export const MAJORS_SORT = 'MAJORS_SORT';

export function search(query, history) {
  return { type: SEARCH_NAVIGATION, query, history };
}

export function getResultsCount(query, pageSize) {
  return { type: SEARCH_COUNT_REQUEST, query, pageSize };
}

export function getResults(query, pageIdx, pageSize, sort, history) {
  return {
    type: SEARCH_REQUEST,
    query,
    pageIdx,
    pageSize,
    sort,
    history
  };
}

export function getRedirect(query, pageIdx, pageSize, sort, history, results) {
  return {
    type: SEARCH_REDIRECT,
    query,
    pageIdx,
    pageSize,
    sort,
    history,
    results
  };
}

export function getMajors(organization) {
  return { type: MAJORS_REQUEST, organization};
}

export function sortMajors(column) {
  return { type: MAJORS_SORT, field: column.key };
}
