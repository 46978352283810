import React, { Component } from 'react';
import { isInsideDomNode } from 'common/util/common-helpers';
import classNames from 'classnames';

export default class DefinitionOverlay extends Component {

  delayHideOverlayTimer = null;

  state = {
    top: 0,
    left: 0,
    overlayVisible: false
  };

  domNode = null;
  containerNode = null;

  static activeOverlay = null;

  saveDomNode = (ref) => {
    this.domNode = ref;
  
    let containers = ref === null ? [] : ref.getElementsByClassName('definition-overlay-container');    
    this.containerNode = containers.length ? containers[0] : {clientWidth: 265, clientHeight: 200};
  }

  showOverlay = (event) => {

    if (this.delayHideOverlayTimer) {
      clearTimeout(this.delayHideOverlayTimer);
      this.delayHideOverlayTimer = null;
    }

    let previousOverlay = DefinitionOverlay.activeOverlay;
    if (previousOverlay === this) {
      return;
    }

    this.setState({overlayVisible: true});
    DefinitionOverlay.activeOverlay = this;
    DefinitionOverlay.startBodyClickListener();

    let { pageX = 0, pageY = 0 } = event || {};

    this.setState({
      top: pageY + 5,
      left: pageX + 5
    });

    let self = this;
    if (this.containerNode.clientWidth && this.containerNode.clientHeight) {
      adjustPositionIfOffViewport();
    } else {
      setTimeout(adjustPositionIfOffViewport);
    }

    if (previousOverlay) {
      previousOverlay.hideOverlay();
    }

    function adjustPositionIfOffViewport() {
      let { clientWidth, clientHeight } = self.containerNode;

      if (isNaN(clientWidth) || isNaN(clientHeight)) {
        return;
      }

      let changed = false;
      let { top, left } = self.state;

      if (pageX + clientWidth > window.innerWidth) {
        changed = true;
        left = pageX - clientWidth - 5;
      }
      if (pageY + clientHeight > window.innerHeight) {
        changed = true;
        top = pageY - clientHeight - 5;
      }

      if (changed) {
        self.setState({top, left});
      }

    }
  };

  hideOverlay = () => {

    if (this.delayHideOverlayTimer) {
      clearTimeout(this.delayHideOverlayTimer);
      this.delayHideOverlayTimer = null;
    }

    this.setState({overlayVisible: false});

    if (DefinitionOverlay.activeOverlay === this) {
      DefinitionOverlay.activeOverlay = null;
      DefinitionOverlay.stopBodyClickListener();
    }
  };

  delayHideOverlay = () => {

      if (this.delayHideOverlayTimer) {
        return;
      }

      this.delayHideOverlayTimer = setTimeout(this.hideOverlay, 500);
  };

  toggleOverlay = (event) => {

    if (this.state.overlayVisible) {
      this.hideOverlay();
    } else {
      this.showOverlay(event);    
    }
  };

  static startBodyClickListener() {
    document.getElementsByTagName('body')[0].addEventListener('click', DefinitionOverlay.bodyClickListener, true);
  }

  static stopBodyClickListener() {
    document.getElementsByTagName('body')[0].removeEventListener('click', DefinitionOverlay.bodyClickListener, true);
  }

  static bodyClickListener(event) {

    let activeOverlay = DefinitionOverlay.activeOverlay;

    if (activeOverlay === null) {
      DefinitionOverlay.stopBodyClickListener();
      return;
    }

    activeOverlay.hideOverlay();

    if (isInsideDomNode(event, activeOverlay.domNode)) {
      event.stopImmediatePropagation();
    }
  }

  render() {

    let {
      name,
      definition,
      placeholderClass,
      containerClass,
      nameClass,
      definitionClass,
      children
    } = this.props;

    let {
      top,
      left
    } = this.state;

    placeholderClass = classNames(placeholderClass, 'definition-overlay-placeholder');
    containerClass = classNames(containerClass, 'definition-overlay-container', this.state.overlayVisible ? 'show' : '');
    nameClass = classNames(nameClass, 'definition-overlay-name');
    definitionClass = classNames(definitionClass, 'definition-overlay-definition');

    return (

      <span className={placeholderClass}
            ref={this.saveDomNode}
            onClick={this.toggleOverlay}
            onMouseOver={this.showOverlay}
            onMouseOut={this.delayHideOverlay}>
            
        {children}

        <span className={containerClass} style={{top: top + 'px', left: left + 'px'}}>
          <span className={nameClass}>{name}</span>
          <span className={definitionClass}>{definition}</span>
        </span>

      </span>
      );
  }

}