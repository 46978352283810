import React, { Component } from 'react';
import classNames from 'classnames';

import SplitButton from 'common/button/SplitButton';
import { eventHandler } from 'common/util/events';
import MaterialUIPickers from './MaterialUIPickers';

export default class ButtonGroup extends Component {
  state = {
    selectedItem: 0
  };

  selectItem = (idx, month) => {
    const { onSelectItem } = this.props;

    if (onSelectItem) {
      onSelectItem(idx, month);
    }

    this.setState({selectedItem: idx});
  };

  UNSAFE_componentWillMount() {
    const { options } = this.props;
    let defaultSelectedItem = 0
    if (options !== undefined){
      defaultSelectedItem = options.length - 1;
    }

    this.setState({selectedItem: this.props.selectedItem ||  defaultSelectedItem});
  }

  render() {
    const { className, buttonClassName, options } = this.props;
    const { selectedItem } = this.state;

    const classes = classNames('button-group', className);

    return (
        <div className={classes}>
          {options.map((option, idx) => {
            const buttonClasses = classNames({active: selectedItem === idx}, buttonClassName);
            const onClick = eventHandler(this.selectItem, idx);

            if (option.isDropdown) {
              return <MaterialUIPickers key={idx} {...option} buttonClassName={buttonClasses} onClick={onClick} onSelectItemHandler={this.selectItem} idx={idx}/>;
            } else {
              return <button key={idx} className={buttonClasses} onClick={onClick}>{option.label}</button>
            }
          })}
        </div>
    );
  }
};