import React, { Component } from 'react';

import FormElement from 'common/form/FormElement';
import { requiredValidator } from 'common/form/validations';


export default class BaseFormComponent extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = this.state || {};
  }

  updateInvalidFieldsDebounced = (name, message) => {
    this.clearInvalidFieldsUpdateTimer(name);

    this.state.invalidTimers[name] = setTimeout(() => {
      this.setState({
        invalidFields: {
          ...(this.state.invalidFields || {}),
          [name]: message
        }
      });
    }, 500);
  };

  clearInvalidFieldsUpdateTimer = (name) => {
    if (this.state.invalidTimers[name]) {
      clearTimeout(this.state.invalidTimers[name]);
      this.state.invalidTimers[name] = null;
    }
  };

  handleInputChange = (formCmp, e) => {
    const { name, value } = e.target;
    const { fields } = this.state;

    const values = {...(this.state.values || {}), [name]: value};
    const dirtyFields = {...(this.state.dirtyFields || {}), [name]: true};

    let isInvalid = false;

    const invalidFields = Object.keys(fields).reduce((result, name) => {
      const { required = false, validator } = fields[name];
      const fieldValidationResult = validate({validator, value: values[name], values, required});

      //Mark the form as invalid if this field is invalid, but only mark the field as invalid if it's dirty
      isInvalid = isInvalid || !!fieldValidationResult;
      result[name] = dirtyFields[name] && fieldValidationResult;

      return result;
    }, {});

    // If the field wasn't already marked invalid, don't mark it invalid while the user is still typing
    this.state.invalidFields = this.state.invalidFields || {};
    this.state.invalidTimers = this.state.invalidTimers || {};


    if (!this.state.invalidFields[name] && invalidFields[name]) {
      this.updateInvalidFieldsDebounced(name, invalidFields[name]);
      invalidFields[name] = null;
    } else {
      this.clearInvalidFieldsUpdateTimer(name);
    }

    this.setState({
      values,
      isInvalid,
      isDirty: true,
      invalidFields,
      dirtyFields
    });
  };

  getFormElement = (props) => {
    const { invalidFields = {}, dirtyFields = {}, values = {} } = this.state;

    let value;

    if (!props.name) {
      console.warn('Setting up a form field with no name.');
    } else {
      if (!this.state.fields) {
        this.state.fields = {};
        this.state.values = {};
      }

      value = values[props.name] !== undefined ? values[props.name] : props.value;

      this.state.fields[props.name] = props;
      this.state.values[props.name] = value;
    }

    return (
      <FormElement onInputChange={this.handleInputChange}
                   {...props}
                   value={value}
                   initialValue={props.value || props.defaultValue}
                   isInvalid={!!invalidFields[props.name]}
                   isDirty={!!dirtyFields[props.name]}
                   invalidMessage={invalidFields[props.name]} />
    );
  };

  onSubmit = (action, initialValues = {}) => {
    return () => {
      const { isInvalid, isDirty, values } = this.state;

      console.log('onSubmit', { state: this.state });

      if (!isInvalid && isDirty) {
        action({...initialValues, ...values});
      }
    };
  }

}

function validate({validator, value = '', values, required}) {
  let validationResult = !validator || validator(value, values);

  if (validationResult === true) {
    validationResult = !required || requiredValidator(value);
  }

  if (validationResult === true) {
    validationResult = null;
  }

  return validationResult;
}
