import React, { Component } from 'react';
import classNames from 'classnames';

import { COLUMN_TYPES } from 'common/table/SimpleTable';
import { getColumnStyles } from 'common/table/Cell';
import { eventHandler } from 'common/util/events';

export default class extends Component {
  render() {
    const { column, rows, config, sort, children, disabled } = this.props;
    const onHeaderClick = () => {
      if (config.onHeaderClick && column.sortable) {
        if (!disabled) {
          config.onHeaderClick(column, config.sortKey);
        } else {
          console.log('Attempted to click on header while disabled, should only happen when trying to load.');
        }
      }
    };
    const className = classNames(column.className, {sortable: column.sortable});
    const tabIndex = column.sortable ? '0' : '-1';

    if (sort && (column.key === sort.field || column.sortKey === sort.field)) {
      column.sort = sort.direction;
    } else {
      column.sort = null;
    }

    const style = getColumnStyles(column);

    let columnHeaderValue = <span title={headerTooltip(column)}>{column.label}</span>;

    if (column.type === COLUMN_TYPES.CHECKBOX && column.onCheckRows) {
      let allChecked = rows.length > 0 && config.selected && rows.every((row, rowIdx) => config.selected[rowIdx]);
      columnHeaderValue = <input type="checkbox" checked={allChecked} onChange={eventHandler(column.onCheckRows, rows)} />;
    }

    return (
      <div className={className} onClick={onHeaderClick} style={style} tabIndex={tabIndex}>
        {columnHeaderValue}{sortIndicator(column)}
        {children}
      </div>
    );
  }
}

export const headerTooltip = ({title, label, titleNoLabel}) => {
  return titleNoLabel? titleNoLabel : ((title && label && title !== label) ? `${label}: ${title}` : title || label);
};

const sortIndicator = (column) => {
  if (column.sort) {
    let iconClass = classNames('fa', {
      'fa-caret-up': column.sort === 'asc',
      'fa-caret-down': column.sort === 'desc'
    });
    return (<span>&nbsp;<i className={iconClass}/></span>);
  } else {
    return '';
  }
};
