import React, { Component } from 'react';

import ButtonDropdown from 'common/button/ButtonDropdown';
import Table, { TYPES } from 'common/table/Table';
import Customizable from 'common/table/features/Customizable';
import { formatDateMmDdYyyy } from 'common/util/common-formatters';
import { cusipLink } from 'www/util/www-formatters';
import { getOrganizationFromLocation, maxKey, IS_75_DAY_SECURITY } from 'common/util/common-helpers';
import { factor, taxFactor } from 'www/util/www-formatters';
import { currencyWithCents, percent, percentNoSymbol } from 'common/util/common-formatters';
import { mapColumns } from 'www/util/labels';
import { isRemicSecurity, getOrganizationCodeFromLocation, isFreddie, getIssuerLegalAddress } from 'www/util/www-helpers';
import { HeaderField } from 'www/components/details/DetailHeader';


export default class TaxPanel extends Component {

  constructor(props, context) {
    super(props, context);

    this.gridConfig = {
      key: 'tax',
      columnDefaults: {
        sortable: true
      },
      columns: [
        { key: 'accrualPeriodDays', flex: 0.5, className: 'right' },
        { key: 'accrualPeriodStartDate', formatter: formatDateMmDdYyyy },
        { key: 'accrualPeriodEndDate', formatter: formatDateMmDdYyyy },
        { key: 'taxPaymentDate', formatter: formatDateMmDdYyyy },
        { key: 'endingUPBFactor', formatter: taxFactor, className: 'right' },
        { key: 'qsiFactor', formatter: taxFactor, className: 'right' },
        { key: 'oidFactor', formatter: taxFactor, className: 'right' },
        { key: 'otherIncomeExpenseFactor', formatter: taxFactor, className: 'right' },
        { key: 'sec212Factor', formatter: taxFactor, className: 'right' },
        { key: 'beginningAipFactor', formatter: taxFactor, className: 'right' },
        { key: 'mdar', formatter: taxFactor, className: 'right' },
        { key: 'realEstatePct', flex: 0.5, formatter: percentNoSymbol, precision: 2, className: 'right' }
      ],
      emptyText: 'No tax data available'
    };

    mapColumns('tax', this.gridConfig.columns);
  }

  componentDidMount() {
    this.props.fetchTaxData(this.props.cusip);
  }

  render() {
    const { cusip, tax } = this.props;
    const {  isFetching,  message, footerText, url, selectedYears  } = tax;
       
    let { data } = tax;     
    
    if (message) {
      return <div className="empty-text">{message}</div>;
    } else if (isFetching || !data) {
      return <div className="loading more"><i className="fa fa-spinner fa-pulse"/></div>;
    } else if (url) {
      return <div className="tax empty-text">For Tax Factors for this security, please go to the following location:
                   <div className="static-tax-url"><a href="{url}">{url}</a></div>
             </div>
    } else if (data.length === 0) {
      return <div className="tax empty-text">Security Data for Searched Year Not Yet Available </div>;
    }

    if (data.length > 1) {

      data = [...data];
      data.sort(createRecombinableTaxSorter(cusip));
    }
   
    let currentClassificationType = '';
    let formattedFooterText = [];
    if(selectedYears.length&& data.length) {
        currentClassificationType = data[0][selectedYears[0]] && data[0][selectedYears[0]].length && data[0][selectedYears[0]][0].classificationType ? data[0][selectedYears[0]][0].classificationType : '';
        formattedFooterText = footerText && footerText[currentClassificationType] && footerText[currentClassificationType].length ? footerText[currentClassificationType] : [];
    }

    return (
      <div className="tax-full-container">

        { data.map(this.renderSecurityTax.bind(this)) }

        <div className="footer tax-footer">
          <p>{formattedFooterText[0]}</p>
            {formattedFooterText && formattedFooterText.length ? <hr /> : ''}
          <p>{formattedFooterText[1]}</p>
          <p>{formattedFooterText[2]}</p>
          <p><strong>{formattedFooterText[3]}</strong></p>
        </div>
      </div>
      );
  }

  // Function to calculate latest MortgageBackedSecurityTaxIdentifier based on latest month of latest year.
  calculateLatestMortgageBackedSecurityTaxIdentifier (years, recombinableTax) {
    let latestMortgageBackedSecurityTaxIdentifier = '';
    try {
      if (!!years && years.length > 0) {
        const intYears = years.map(Number);
        if (intYears && intYears.length > 0) {
          const latestYear = Math.max(...intYears);
          if (latestYear > 0) {
            const latestYearData = recombinableTax[latestYear];
            const taxMonths = latestYearData.map(lyd => lyd.taxMonth);
            if (taxMonths && taxMonths.length > 0) {
              const latestYearMonth = Math.max(...taxMonths);
              const latestMortgageBackedSecurityTaxIdentifierData = (latestYearMonth && latestYearMonth > 0) ? latestYearData.filter((lyd) => {
                if (lyd.taxMonth === latestYearMonth) {
                  return lyd.mortgageBackedSecurityTaxIdentifier;
                }
              }) : {};
              if(JSON.stringify(latestMortgageBackedSecurityTaxIdentifierData) !== JSON.stringify({})) {
                latestMortgageBackedSecurityTaxIdentifier = latestMortgageBackedSecurityTaxIdentifierData[0].mortgageBackedSecurityTaxIdentifier;
              }
            }
          }
        }
      }
    } 
    catch (e) {
      latestMortgageBackedSecurityTaxIdentifier = '';
    }
    return latestMortgageBackedSecurityTaxIdentifier;
  }

  renderSecurityTax(recombinableTax, securityIndex) {
   
    const { cusip, settings, tax, taxSort, taxYearSelect, details } = this.props;
    const { data, sorts, selectedYears } = tax;
    

    const sort = sorts[securityIndex];
    let selectedYear = Number(selectedYears[securityIndex]);

    let selectedYearData = recombinableTax[selectedYear] || [];
    const isSelectedYearFirstQuarterDisclosed = (selectedYearData && selectedYearData.length > 1) || (selectedYearData.length === 1 && selectedYearData[0].taxMonth !== 1) ? true : false;
    // in case previous year data is not avialable then we have to concatenate the next year data

    const previousYearData = recombinableTax[selectedYear-1] ? recombinableTax[selectedYear-1] : recombinableTax[selectedYear+1];
    const nextYearData = recombinableTax[selectedYear+1] ? recombinableTax[selectedYear+1] : [];

    const selectedYearDataFor75DaySecurity =  // if current year is having less than 13 records then we need to show current year record for 75 day security
                                              selectedYearData && selectedYearData.length && (selectedYearData.length < 13 )
                                              && isFreddie && selectedYearData[0].is75DaySecurity &&
                                              selectedYearData[0].is75DaySecurity === IS_75_DAY_SECURITY
                                              ?
                                              // only jan and feb records for 75 day security
                                              selectedYearData && selectedYearData.length && (selectedYearData.length <=2 && (selectedYearData[0].taxMonth === 1 || selectedYearData[0].taxMonth === 2))
                                              && isFreddie && selectedYearData[0].is75DaySecurity &&
                                              selectedYearData[0].is75DaySecurity === IS_75_DAY_SECURITY
                                              ?
                                              previousYearData && previousYearData.length ? [...previousYearData, ...selectedYearData] : []
                                              :
                                              [...selectedYearData]
                                              :
                                              // if current year is having 13 records then we need to add next year feb record for 75 day security (if exists)
                                              selectedYearData && selectedYearData.length && selectedYearData.length === 13
                                              && isFreddie && selectedYearData[0].is75DaySecurity &&
                                              selectedYearData[0].is75DaySecurity === IS_75_DAY_SECURITY
                                              ?
                                              [...selectedYearData, ... nextYearData.filter((data) => {if (data && data.taxMonth && data.taxMonth ===2) {return data;} })]
                                              :
                                              //in case 75 day is not true then current year data needs to be added with next year data if next year data is having only jan and feb .
                                              recombinableTax[selectedYear+1] &&  recombinableTax[selectedYear+1].length &&
                                              recombinableTax[selectedYear+1].length <13 &&
                                              (recombinableTax[selectedYear+1][0].taxMonth === 1 || recombinableTax[selectedYear+1][0].taxMonth === 2)
                                              ?
                                              recombinableTax[selectedYear+1] &&  recombinableTax[selectedYear+1].length &&
                                              recombinableTax[selectedYear+1].length <=2 &&
                                              (recombinableTax[selectedYear+1][0].taxMonth === 1 || recombinableTax[selectedYear+1][0].taxMonth === 2)
                                              ?
                                              [...selectedYearData, ... nextYearData.filter((data) => {if (data && data.taxMonth && data.taxMonth ===1) {return data;} })] : selectedYearData : selectedYearData;

    selectedYearData = isSelectedYearFirstQuarterDisclosed ? selectedYearDataFor75DaySecurity : previousYearData;
    if (selectedYearData && selectedYearData.length > 0) {
        // remove duplicate jan records if any
        let count = 0;
        const filteredSelectedYearData = [];
        const maxYear =  Object.keys(recombinableTax).indexOf((selectedYear + 1).toString())> -1 ? Math.max(selectedYear, selectedYear+1) : selectedYear;
        selectedYearData.map(( data ) => {
            if (data && data.taxMonth && data.taxQuarter && data.taxMonth === 1 && data.taxQuarter === 4 && data.taxYear && (data.taxYear === maxYear)) {
                if (count < 1) {
                    filteredSelectedYearData.push(data);
                    count = 1;
                }

            } else {
                filteredSelectedYearData.push(data);
            }
        });
        selectedYearData = filteredSelectedYearData;
    } else {
        return <div className="tax empty-text">Security Data for Searched Year Not Yet Available </div>;
    }
    
    const taxYearSelectProxy = obj => {
      obj.securityIndex = securityIndex;
      return taxYearSelect(obj);
    };
    const taxSortProxy = column => taxSort(column, securityIndex);
    
    let years = Object.keys(recombinableTax);
    years.sort();
    const yearOptions = years.map(year => ({
      text: year,
      year,
      onClick: taxYearSelectProxy,
      disabled: year === selectedYear.toString()
    }));
    let trustTIN = this.calculateLatestMortgageBackedSecurityTaxIdentifier(years, recombinableTax);
    const yearSelector = <ButtonDropdown label={selectedYear} options={yearOptions} />;
    const headerData = selectedYearData && selectedYearData[0] ? selectedYearData[0] : {};

    const isRecombinableCombo = data.length > 1 && securityIndex === 0;
    let recombinableExplanation;
    let table;

    const trustId = headerData.trustId ? headerData.trustId : '';
    const classId = headerData.classId ? headerData.classId : '';
    const isRemic = headerData.securityType ? isRemicSecurity({secType : headerData.securityType}) : false;
    const idValue = isRemic ? `${trustId} ${classId}` : headerData.secId;
    let cusipFormatter = cusipLink;
    if (cusip === headerData.cusip || headerData.doNotDiscloseType === 'SUPPRESS_ALL_DISCLOSURES') {
        cusipFormatter = null;
    }
    
    const headers =
      [
        <HeaderField colKey="cusip" label="CUSIP" value={headerData.cusip} formatter={cusipFormatter} />,
        <HeaderField colKey="headerId" label="Pool/Series and Class" value={idValue} />,
        <HeaderField colKey="classificationType" label="Security Classification" value={headerData.classificationType} />
      ];
                  

    if (isRecombinableCombo) {
      recombinableExplanation = <div className="tax-recombinable-expl">
          <p>The above security is a combination class which represents a beneficial interest in one or more underlying classes and are shown below in succession.</p>
          <p>Please refer to the Offering Circular Supplement for the Series and Classes shown below for additional information and consult your tax advisor regarding proper tax treatment.</p> 
        </div>;

      table = null;
    
    } else {

      const tableProps = {
        ...this.props,
        type: TYPES.simple,
        features: [Customizable],
        exportLink: `/api/tax/${getOrganizationCodeFromLocation()}/${headerData.cusip}/${selectedYear}/taxexport`,
        exportName: `tax.xls`,
        config: {
          title: <span>  </span>,
          table: {
            ...this.gridConfig,
            onHeaderClick: taxSortProxy
          }
        },
        data: selectedYearData,
        sort
      };
      const dynamicHeaders = [];     
      const histTaxUrl  = isRemic ? "http://www.freddiemac.com/mbs/html/sd_remic_lookup.html" : "http://www.freddiemac.com/mbs/html/sd_pc_lookup.html" ;
      
      if (isFreddie()){
        dynamicHeaders[dynamicHeaders.length] = <HeaderField colKey="issueAmount1" 
          label="Historical Tax Disclosures (pre-2019)." 
          value={<a href = {histTaxUrl} 
          target ="_blank">{histTaxUrl} </a>} 
          formatter={currencyWithCents} />
      }
      headers.splice(headers.length,
        0,
        [
          <HeaderField colKey="reportingType" label="Class Issued with" value={headerData.reportingType} />,
          <HeaderField colKey="qualStatedIntType" label="Interest Class" value={headerData.qualStatedIntType} />,
          <HeaderField colKey="interestAccrualMethod" label="Interest Accrual Method" value={headerData.interestAccrualMethod} />,
          <HeaderField colKey="issueAmount" label="Original Amount" value={headerData.issueAmount} formatter={currencyWithCents} />
        ],
        dynamicHeaders);

      recombinableExplanation = null;
        table = <Table {...tableProps}  />;

    }

      if(trustTIN.length <= 0) {
          trustTIN = headerData.mortgageBackedSecurityTaxIdentifier;
      }

    return  <div className={`tax-container security-index-${securityIndex}`} key={'sec' + securityIndex}>
              <div className="tax-header">
                <ul className="tax-col-2">
                  <HeaderField colKey="mortgageBackedSecurityTaxIdentifier" label="Trust TIN" value={trustTIN} />
                  <HeaderField colKey="issuerLegalAddress" label="Issuer" value={getIssuerLegalAddress(settings, headerData.issuer)} />
                </ul>
                <ul className="tax-col-1">
                  { headers }
                </ul>
                { recombinableExplanation }
              </div>
                 <div  className="tax-table">
                    <div className="table-header"> <span>Viewing tax factors for:{yearSelector} </span>  </div>
                     { table }
                 </div>
            </div>;
  };
}

// for recombinables put the parent security at top
// and others below that sorted by cusip. This work
// needs to be done here instead of in the reducer
// because in the reducer we don't know parent cusip.
function createRecombinableTaxSorter(cusip) {
  return function recombinableTaxSorter(x, y) {

    if (x === y) {
      return 0;
    }

    // x and y are individual security tax data,
    // each is a hash with years as keys
    // and then array of months
    // so this awesome expression below evaluates to
    // x['2015'][0] <- get me first month of 2015    
    const cusipX = x[Object.keys(x)[0]][0].cusip;
    const cusipY = y[Object.keys(y)[0]][0].cusip;

    if (cusipX === cusipY) {
      return 0;
    }

    if (cusipX === cusip) {
      return -1;
    }

    if (cusipY === cusip) {
      return 1;
    }

    return cusipX < cusipY ? -1 : 1;
  }
}
