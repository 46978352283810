import React from 'react'
import classNames from 'classnames'
import { commaThousands } from 'common/util/common-formatters'

export default function Expandable () {
  let setState, props;

  let state = {
    expanded: false
  }

  const toggleExpanded = () => {
    state = setState({ expanded: !state.expanded })
  }

  return {
    key: 'expandable',
    attach: function (featureSetState) {
      setState = featureSetState
      return state
    },
    onRender: function (newProps, config) {
      const maxTextLength = 50
      let { buttons = [], classes } = config

      const btnClasses = classNames('fa', state.expanded ? 'fa-compress' : 'fa-expand')
      props = newProps;

      classes.push({ expanded: state.expanded });

      let customTitle = '';
      customTitle = props && props.count && props.count >= 0 ? customTitle + commaThousands(props.count) : '';
      customTitle = props && props.totalCount && props.totalCount >= 0 ? customTitle + ' out of ' : customTitle + ' Search Results';
      const customTitle2 = props && props.totalCount && props.totalCount >= 0 ? ' Search Results' : '';

      const customTitleLink = props && props.totalCount && props.totalCount >= 0 
        ? <a href={'#'} onClick={(e) => props.onTotalCountClick(e)}>{commaThousands(props.totalCount)}</a>
        : '';

      const title = (
          <div className="default-table-title">
            <div>{customTitle}{customTitleLink}{customTitle2}</div>
          </div>
        );

      buttons = buttons.concat(
        <button key="toggle-expanded" className="icon-button" onClick={toggleExpanded}><i className={btnClasses}/></button>
      );

      return { buttons, classes, title };
    }
  }
}
