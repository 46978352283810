import { getTargetArray, setInCopy } from 'common/util/common-helpers';

export function onSortWrappedArray(sortWrapper, sort, action) {
  const { key, field, direction } = action;
  const targetArray = getTargetArray(sortWrapper, key);

  if (!targetArray) {
    return { sortedWrapper: sortWrapper, newSort: sort };
  }

  const { originalArray, newSort } = getNewSortAndOriginalArray(sort, field, direction, targetArray);

  let sortedWrapper;
  if (newSort) {
    sortedWrapper = sortWithin(sortWrapper, key, newSort);
  } else {
    sortedWrapper = setInCopy(sortWrapper, key, originalArray);
  }

  return { sortedWrapper, newSort };
}

export function applyInitialSortWrapped(sortWrapper, key, sort) {
  const originalArray = getTargetArray(sortWrapper, key);
  const newSort = {...sort, originalArray };

  let sortedWrapper = sortWithin(sortWrapper, key, newSort);

  return { sortedWrapper, newSort };
}

export function onSortArray(targetArray, sort, action) {
  if (!targetArray) {
    return { sortedArray: targetArray, newSort: sort };
  }
  const { field, direction, sorter } = action;
  const { originalArray, newSort } = getNewSortAndOriginalArray(sort, field, direction, targetArray, sorter);

  let sortedArray = newSort ? sortArray(targetArray, newSort, sorter) : originalArray;

  return { sortedArray, newSort };
}

export function getNewSort(currSort, field, direction = 'asc', sorter) {
  let newSort = {
    field,
    direction,
    sorter
  };

  if (currSort && currSort.field === field) {
    if (currSort.direction === 'asc') {
      newSort.direction = 'desc';
    } else if (currSort.direction === 'desc') {
      newSort = null;
    }
  }

  return newSort;
}

export function sortArray(array, sort, sortFn = sorter) {
  let newResults = array.slice(0);

  if (sort) {
    newResults.sort(sortFn(sort.field, sort.direction));
  }

  return newResults;
}

function sorter(field, direction) {
  const larger = direction === 'asc' ? 1 : -1;
  const smaller = direction === 'asc' ? -1 : 1;
  const key = field;

  return (a, b) => {
    const aValue = a[key] || '';
    const bValue = b[key] || '';

    if (aValue > bValue) {
      return larger;
    } else if (aValue < bValue) {
      return smaller;
    }

    return 0;
  };
}

// this function sorts the given array in-place.
export function onMultiSortArray(targetArray, multiSort) {
    if (!targetArray || !multiSort || multiSort.length == 0) {
        return targetArray;
    }

    targetArray.sort(new MultiFieldSorter(multiSort).compare)

    return targetArray;
}

function MultiFieldSorter(multiSort) {
  this.multiSort = multiSort
  this.compare = (a, b) => {
      if (!this.multiSort) {
          return 0
      }

      const dirFlags = this.multiSort.map(sort => sort.direction === 'asc' ? 1 : -1)
      let idx = 0
      let compareResult = 0
      while (idx < this.multiSort.length && compareResult == 0) {
          let {field, comparator} = this.multiSort[idx]

          comparator = comparator || defaultComparator
          compareResult = dirFlags[idx] * comparator(a[field], b[field]);

          idx++;
      }
      return compareResult;
  }
}

export function defaultComparator(valueA, valueB) {
    const aValue = valueA || '';
    const bValue = valueB || '';
    if (aValue > bValue) {
        return 1;
    } else if (aValue < bValue) {
        return -1;
    } else {
        return 0;
    }
}

function sortWithin(sortWrapper, key, sort) {
  let sortedWrapper = sortWrapper;

  if (sort) {
    const sortTarget = getTargetArray(sortWrapper, key);
    if (sortTarget) {
      sortedWrapper = setInCopy(sortWrapper, key, sortArray(sortTarget, sort));
    }
  }

  return sortedWrapper;
}

function getNewSortAndOriginalArray(currSort, field, direction, targetArray, sorter) {
  const newSort = getNewSort(currSort, field, direction, sorter);
  const originalArray = currSort && currSort.originalArray || targetArray;

  if (newSort) {
    newSort.originalArray = originalArray;
  }

  return { newSort, originalArray };
}
