import {
	REPORTS_GROUP_REQUEST,
	REPORTS_GROUP_RESPONSE,
	REPORTS_GROUP_ERROR,
	REPORTS_GROUP_YEAR_REQUEST,
	REPORTS_GROUP_YEAR_RESPONSE,
	REPORTS_GROUP_YEAR_ERROR
} from 'www/actions/reportsActions';

import { reducerGenerator } from 'common/util/redux';
import { sortBy, SORT_DESCENDING, toDate } from 'common/util/common-helpers';
import { rootReportType } from 'www/util/www-helpers';

const initialState = {
  hasFetched: false,
  isFetching: false,
  reports: [],
  urlPath: '',
  selectYear: 0,
  years: [],
  hasError: false,
  message: ''
};

function getFileNames(report) {
  if (!report) {
    return undefined;
  }

  const file = report.document;
  if (file && file.effectiveDate) {
    file.effectiveDate = toDate(file.effectiveDate);
  } else {
    console.error("Backend didn't populated effective date, this is unexpected!");
  }
  return [{
    file: file,
    correctionFile: report.correctionDocument
  }];
}

function mergeReports(reports) {
  // combine report types
  const reportsByType = {};
  const mergedReports = [];

  reports.forEach(report => {
    try {
      const commonReportType = rootReportType(report.headingKey);

      let byType = reportsByType[commonReportType];
      if (byType === undefined) {
        report.dataFiles = [];
        reportsByType[commonReportType] = report;
        mergedReports.push(report);
        byType = report;
      }

      let fileNames = getFileNames(report);
      if (fileNames !== undefined) {
        byType.dataFiles.push.apply(byType.dataFiles, fileNames);        
      }
    } catch (ex) {
      console.log('REPORTS REFORMAT ERROR', ex);
    }
  });
  
  mergedReports.forEach(report => {
	  sortBy(report.dataFiles, f => f.file.name, SORT_DESCENDING);
  });
  
  sortBy(mergedReports, r => r.headingKey);
  
  return mergedReports;
}

// API to get the available years and reports for latest year
function groupReportsResponseHandler(state, action) {

  let reports = action.combinedRes.get("reports");
  if (!Array.isArray(reports)) {
    return;
  }

  reports = mergeReports(reports);
  return { urlPath: action.urlPath, selectYear: action.combinedRes.get("selectYear"), years: action.combinedRes.get("years"), reports, hasFetched: true, isFetching: false};
}

// API to get the reports of a given group and year
// this does not return no of years in the response
function groupYearReportsResponseHandler(state, action) {

  let reports = action.response;
  if (!Array.isArray(reports)) {
    return;
  }

  reports = mergeReports(reports);
  return { urlPath: action.urlPath, selectYear: action.year, reports, hasFetched: true, isFetching: false};
}


export default reducerGenerator(initialState, {
  REPORTS_GROUP_REQUEST: (state, action) => {
    return {...initialState, isFetching: true};
  },
  REPORTS_GROUP_RESPONSE: groupReportsResponseHandler,
  REPORTS_GROUP_ERROR: (state, action) => {
    return {...initialState, hasError: true, message: action.message};
  },
  
  REPORTS_GROUP_YEAR_REQUEST: (state, action) => {
    return {...initialState, isFetching: true};
  },
  REPORTS_GROUP_YEAR_RESPONSE: groupYearReportsResponseHandler,
  REPORTS_GROUP_YEAR_ERROR: (state, action) => {
    return {...initialState, hasError: true, message: action.message};
  }
  

});
