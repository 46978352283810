import {
  ALLOCATION_REQUEST,
  ALLOCATION_RESPONSE,
  ALLOCATION_ERROR,
  DETAILS_REQUEST
} from 'www/actions/detailsActions';
import { reducerGenerator } from 'common/util/redux';

const initialState = {
  message: null,
  data: null,
  hasError: false,
  isFetching: false
};

export default reducerGenerator(initialState, {
  DETAILS_REQUEST: (state, action) => initialState,
  ALLOCATION_REQUEST: (state, action) => ({...initialState, isFetching: true}),
  ALLOCATION_RESPONSE: (state, action) => {
    const returned_value = action.payload;

    if (returned_value) {
      const { parent_summaries = {} } = returned_value;
      const { multiClassAllocations = [], singleClassAllocations = [] } = parent_summaries;

      if (multiClassAllocations.length > 0 || singleClassAllocations.length > 0) {
        return {...initialState, data: parent_summaries};
      }
    }

    return {
      ...initialState,
      message: 'No cash flows from this security are allocated to resecuritizations or other securities'
    };
  },
  ALLOCATION_ERROR: (state, action) => ({...initialState, message: action.message})
});
