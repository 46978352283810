import React, { Component } from 'react';
import { v4 as uuid } from 'uuid';
import ButtonDropdown from 'common/button/ButtonDropdown';
import Expandable from 'common/table/features/Expandable';
import { columnValue } from 'common/table/Cell';
import { COLUMN_TYPES } from 'common/table/SimpleTable';
import BaseTable, { TYPES } from 'common/table/Table';

import { getMonthSelector, paginate } from 'www/util/www-helpers';
import { yesNoIndicator } from 'www/util/www-formatters';

import {
  DEFAULT_INIT_PAGE,
  DEFAULT_CLASSES_PAGE_SIZE
} from 'common/util/common-helpers';

import { 
  currencyWithCents,
  formatDateMmDdYyyy,
  formatDateMmYyyy,
  zeroToEmpty 
} from 'common/util/common-formatters';
import {
  createGroupClassNameGenerator,
  getOrganizationFromLocation,
  sortDesc,
  sortAsc,
  toLookup
} from 'common/util/common-helpers';
import {
  cusipLink,
  factor,
  rate,
  remicRate
} from 'www/util/www-formatters';

import { 
  getLabels,
  mapColumns 
} from 'www/util/labels';
import Paginator, { loadPageWithCall } from 'common/table/features/Paginator';

import ENUMS from 'www/util/enumFormatters';
import MaterialUIPickers from "../../../common/button/MaterialUIPickers";
import {isSmbs, isXsio, sortByGroupAndClassId} from '../../util/www-helpers';
const  { issuer, remicClassType }  = ENUMS;

const ISSUANCE_DATE_PLACEHOLDER = 'ISSUANCE';
                                      
export default class DealTrancheTable extends Component {

  state= {};

  constructor(props){
    super(props)
    this.trustNumber = props.match.params.trustNumber;
  }
  
  onSelectMonth = (idx, month) => {

    const { getDealAsOf, getDealIssuance, deal } = this.props;
    let currentMonthSelection = deal.classes ? deal.classes[0].factorDt : '';

    if (month && month.name !== currentMonthSelection) {
      this.setState({selectedDate: month.name})
      if (month.name === deal.issueDate) {
        getDealIssuance(this.trustNumber, DEFAULT_INIT_PAGE, DEFAULT_CLASSES_PAGE_SIZE);
      } else {
        getDealAsOf({'trustNumber': this.trustNumber, 'asOfDate': month.name}, DEFAULT_INIT_PAGE, DEFAULT_CLASSES_PAGE_SIZE);
      }
    }
  };

  componentDidMount() {
    const { details, getDealClassCount, deal } = this.props;

    const factorsDate = (deal && deal.classes && deal.classes.length > 0) ? deal.classes[0].factorDt : null;
    const issuanceDate = deal ? deal.issueDate : null;
    this.setState({selectedDate: factorsDate, issuanceDate: issuanceDate});

  	if (!details || !details.isFetching && !details.dealTrancheCount) {
      if (this.props.location && this.props.location.query && this.props.location.query.q && this.props.location.query.q.length) {
        const classParam = this.getClassParamFromQuery(this.props.location.query.q); 
         classParam && classParam.length ? getDealClassCount(this.trustNumber, classParam) : getDealClassCount(this.trustNumber);
      } else {   
			getDealClassCount(this.trustNumber);           
      }
      
    }
    if(sessionStorage.getItem('tabIndex')){
          setTimeout(()=>{ sessionStorage.removeItem('tabIndex');}), 0
      }
  } 

  getClassParamFromQuery = (queryParam) => {
      const arrQueryParam = queryParam.split(',').length > 1 ? queryParam.split(',') : queryParam.split(' ').length > 1 ? queryParam.split(' ') : queryParam.split(',');
      const deal = [];
      let classes = [];
      try {
          if (arrQueryParam && arrQueryParam.length) {
              arrQueryParam.map((queryParam) => {
                  const arrDealClass = queryParam.split('/');
                  deal.push(arrDealClass[0])
                  classes.push(arrDealClass[1]);
              });
          }
      } catch (e) {
          console.log(e);
          classes = [];
      }
      return classes;
  }

    redirectToDeal = (e) => {
        sessionStorage.setItem('tabIndex',this.props.tabIndex);
        const location = this.props.location;
        window.location.assign(window.location.origin + location.pathname);
        e.preventDefault();
    }

  render() {
    const {dates, deal, details, trustNumber, getDealAsOf, getDealByTrustNumberAndClass, changePage} = this.props;
    let {asOfDate, isIssuance, pageIdx, pageSize, dealTrancheCount, totalCount} = details;
    const currentState = this.state;
    let methodToCall = getDealAsOf;
    const {onSelectMonth} = this;

    const trustFromUrl = this.props.location.pathname.split('/')[3];

    if (deal.trustNumber === trustFromUrl) {
      methodToCall = changePage;
    }

    let classParam = '';
    if (this.props.location && this.props.location.query && this.props.location.query.q && this.props.location.query.q.length) {
        classParam = this.getClassParamFromQuery(this.props.location.query.q);
        methodToCall = getDealByTrustNumberAndClass;
    }
    const tranches = isXsio(details) ? sortByGroupAndClassId(deal.classes) : deal.classes;

    if (isSmbs(details)) {
      tranches.forEach( (tranche) => delete tranche.groupId);
    }

    const showMonthSelector = dates && dates.length > 0;

    if (showMonthSelector) {
      sortAsc(dates);

      if (asOfDate == undefined) {
        asOfDate = isIssuance ? ISSUANCE_DATE_PLACEHOLDER : dates[0];
      }
    }

    const paginatedTranches = paginate(tranches, pageIdx, pageSize);

    const gridConfig = {
      table: {
        className: 'results deal-table;',
        rowDynamicClassName: createGroupClassNameGenerator('groupId', paginatedTranches, 'group-start', ''),
        columnDefaults: {
          sortable: false
        },
        key: uuid(),
        uniqueKey: 'cusip',
        columns: [
          { 
            key: 'groupId',
            formatter: zeroToEmpty,
            className: 'center header-center',
            emptyText: isSmbs(details) ? '-' : '',
            dynamicClassName: createGroupClassNameGenerator('groupId', paginatedTranches, 'group-cell-show', 'group-cell-hide')
          },
          {
            key: 'expandCollapse',
            type: COLUMN_TYPES.EXPANDER
          },
          { 
            key: 'classId', 
            className: 'center header-center'
          },
          { 
            key: 'cusip', 
            formatter: cusipLink,
            className: 'center header-center'
          },
          {
            key: 'notionalDealInd',
            formatter: yesNoIndicator,
            className: 'center header-center'
          },
          {
            key: 'recombinableIndicator',
            formatter: yesNoIndicator,
            className: 'center header-center'
          },
          { 
            key: 'upbIssuance',
            formatter: currencyWithCents,
            className: 'right pad header-center'
          },
          { 
            key: 'upbOngoing',
            formatter: currencyWithCents,
            className: 'right pad header-center'
          },
          {
            key: 'netInterestRate',
            formatter: remicRate,
            className: 'center header-center'
          },
          {
            key: 'classFactor',
            formatter: factor,
            className: 'center header-center'
          },
          {
            key: 'maturity',
            formatter: formatDateMmYyyy,
            className: 'right pad header-center'
          },
          { 
            key: 'principalDist',
            formatter: currencyWithCents,
            className: 'right pad header-center',
            visible: false
          },
          {
            key: 'principalDistFactor',
            formatter: factor,
            className: 'center header-center',
            visible: false
          },
          { 
            key: 'interestDist',
            formatter: currencyWithCents,
            className: 'right pad header-center',
            visible: false
          },
          {
            key: 'interestDistFactor',
            formatter: factor,
            className: 'center header-center',
            visible: false
          },
          { 
            key: 'totalDist',
            formatter: currencyWithCents,
            className: 'right pad header-center',
            visible: false
          },
          { 
            key: 'accretionAmt',
            formatter: currencyWithCents,
            className: 'right pad header-center',
            visible: false
          },
          {
            key: 'accretionFactor',
            formatter: factor,
            className: 'center header-center',
            visible: false
          },
          { 
            key: 'shortFallCurrent',
            formatter: currencyWithCents,
            className: 'right pad header-center',
            visible: false
          },
          { 
            key: 'shortFallCumulative',
            formatter: currencyWithCents,
            className: 'right pad header-center',
            visible: false
          },
          {
            key: 'scheduledDistributionDate',                  
            formatter: formatDateMmDdYyyy,
            className: 'right pad header-center',
            visible: false
          },
          {
            key: 'delay',
            //formatter:  ???
            className: 'center header-center',
            visible: false
          },
          {
            key: 'paidOffDate',
            formatter: formatDateMmDdYyyy,
            className: 'right pad header-center',
            visible: false
          },
          {
            key: 'index',
            className: 'center header-center',
            visible: false
          },
          {
            key: 'indexValue',
            className: 'center header-center',
            visible: false
          },
          {
            key: 'classType',
            formatter: remicClassType,
            visible: false
          },
          {
            key: 'principalType',
            visible: false
          },
          {
            key: 'interestType',
            visible: false
          },
          {
            key: 'factorDt',
            formatter: formatDateMmYyyy,
            visible: false
          },
          {
            key: 'notes',
            visible: false
          }
        ],
        expandedComponent,
        columnEmptyText: '-',
        emptyText: 'No Data'
      }
    };

    mapColumns('dealTranches', gridConfig.table.columns);

    if (showMonthSelector) {
      gridConfig.buttons = [ monthSelector(currentState.selectedDate, dates, onSelectMonth, deal.issueDate) ];
    }

    const exportAsOf = isIssuance ? '1901-01-01' : asOfDate;
    const exportLink = `/api/deal/${getOrganizationFromLocation()}/${trustNumber}/classes/${exportAsOf}?export=true`;
    return (
             <BaseTable 
                      type={TYPES.simple}
                      features={[Expandable, Paginator]}
                      {...this.props}
                      config={gridConfig}
                      pageIdx={pageIdx}
                      pageSize={pageSize}
                      count ={dealTrancheCount}
                      totalCount={totalCount}
                      loadPage={classParam && classParam.length 
                      				? loadPageWithCall(methodToCall, {'trustNumber': trustNumber, 'classParam': classParam}, pageSize) 
                      				: loadPageWithCall(methodToCall, {'trustNumber': trustNumber, 'asOfDate': asOfDate}, pageSize)}
                      data={paginatedTranches}
                      exportLink={exportLink}
                      onTotalCountClick={this.redirectToDeal}
                      exportName="classes.csv" />
    );  

    function expandedComponent(row, rowIdx, data, config) {

      return (
        <li className="row-expanded" key={'rowExpanded' + rowIdx}>

          {gridConfig
            .table
            .columns
            .filter(column => column.visible === false)
            .map((column) => 
                <div className="row-expanded-item" key={uuid()}>
                  <span className="row-expanded-value">
                    {columnValue(row, column, rowIdx, config)}
                  </span>
                  <span className="row-expanded-label">
                    {column.label}
                  </span>
                </div>
              )}
        </li>
      );
    }

    function monthSelector (selectedDate, dates, onSelectMonth, issueDate) {

      const options = getMonthSelector(selectedDate, [...dates], onSelectMonth);

      return (
        <div className="deal-tranch-month-selector right">
          <MaterialUIPickers {...options} onSelectItemHandler={onSelectMonth} selectedDate={selectedDate} buttonClassName={'button-dropdown deal-classes-button'} activeTab={'deal-classes'} issueDate={issueDate}/>
        </div>
      );
    }
  }
}

