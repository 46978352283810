import React, { Component  } from 'react';
import PropTypes from 'prop-types';

import IdleDialog from 'common/IdleDialog';


export default class IdleDetector extends Component {

  static propTypes = {
    idleTimeout: PropTypes.number.isRequired,
    idleAction: PropTypes.func.isRequired,
    defaultAction: PropTypes.func.isRequired,
    cancelAction: PropTypes.func.isRequired,
    showIdleLogout: PropTypes.bool.isRequired,
    events: PropTypes.arrayOf(PropTypes.string),
    element: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  };

  static defaultProps = {
    events: ['mousemove', 'keydown', 'wheel', 'DOMMouseScroll', 'mouseWheel', 'mousedown', 'touchstart', 'touchmove'],
    element: document
  };

  state = {
    lastActive: Date.now(),
    timerId: null,
    pageX: null,
    pageY: null
  };

  UNSAFE_componentWillMount() {
    this.props.events.forEach(e => this.props.element.addEventListener(e, this._handleEvent))
  }

  componentDidMount() {
    this.resetTimer();
  }

  componentWillUnmount() {
    clearTimeout(this.state.timerId);
    this.props.events.forEach(e => this.props.element.removeEventListener(e, this._handleEvent));
  }

  render() {
    const { showIdleLogout } = this.props;

    return showIdleLogout ? <IdleDialog {...this.props} /> : null;
  }

  resetTimer = (state = {}) => {
    const { idleAction, idleTimeout, keepSessionActive } = this.props;

    clearTimeout(this.state.timerId);

    this.setState({
      ...state,
      lastActive: Date.now(),
      timerId: setTimeout(idleAction, idleTimeout * 1000 * 60)
    });

    keepSessionActive(idleTimeout);
  };

  _handleEvent = (e) => {
    // Simple catches to ignore very frequent behavior crowding the event loop
    if (e.type === 'mousemove') {
      const { pageX, pageY, lastActive } = this.state;

      if ((e.pageX === pageX && e.pageY === pageY) || (typeof e.pageX === 'undefined' && typeof e.pageY === 'undefined')) {
        return;
      }
      if ((Date.now() - lastActive) < 5000) {
        return;
      }
    }

    this.resetTimer({
      pageX: e.pageX,
      pageY: e.pageY
    });
  };
}