
import { emailRegex, cityRegex, passwordRegex, phoneRegex, usStateRegex, zipCodeRegex } from 'common/util/regexes';

function required(value, validationText='Field should not be empty') {
  return !!value || value === 0 || validationText;
}

export function requiredSelectedValidator(value) {
  return required(value, 'Please select an option');
}

export function requiredValidator(value) {
  return required(value);
}

export function passwordValidator(value) {
  return value.match(passwordRegex) !== null || 'Password Minimum length: 12 Characters, Password Complexity: 1 lower case, 1 upper case, 1 number, 1 non-alphabetic character.';
}

export function cityValidator(value) {
  return value.match(cityRegex) !== null || 'Please enter a valid city name';
}

export function emailValidator(value) {
	return value.match(emailRegex) !== null || 'Please enter a valid email address';
}

export function phoneValidator(value) {
  return value.replace(/[()+ ]/g, '').match(phoneRegex) !== null || 'Please enter a valid phone number without dashes';
}

export function stateValidator(value) {
  return value.match(cityRegex) !== null || 'Please enter a valid state name';
}

export function zipValidator(value) {
  return value.match(zipCodeRegex) !== null || 'AlphaNumeric only, please';
}
