import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUnsubscribeFromReport, checkEmailLanding } from 'www/actions/documentsActions';
import { getOrganizationFromLocation } from 'common/util/common-helpers';
import { documentDownload } from 'www/util/www-helpers';

import Header from 'www/containers/Header';
import Footer from 'www/components/Footer';
import { getSettings  } from 'www/actions/settingsActions';
import {loadBanners} from 'www/actions/bannerAction';
import { showLoginMessage, isLoggedIn, idleLogout, logout, cancelIdleLogout, keepSessionActive } from 'common/login/loginActions';
import {fetchInitialData} from "www/util/www-helpers";
import { getByKeys } from 'www/util/dataFileMappings';
import IdleDetector from 'common/IdleDetector'

class FileDownload extends Component {
    constructor(props){
        super(props);
        this.params = props.match.params;
        this.organization = getOrganizationFromLocation(); 
    }

    currentParams() {
       const urlParams = new URLSearchParams(this.props.location.search);
       const currentParams = new Map();
       currentParams.set("reportName", urlParams.get('reportName'));
       currentParams.set("id", urlParams.get('id'));
       currentParams.set("fileName", urlParams.get('fileName'));
       return currentParams; 
    }

    componentDidMount() {
       fetchInitialData(this.props);
        this.forceUpdate();
    }

    componentDidUpdate() {
       const { checkEmailLanding, documents } = this.props;
       const { loggedIn } = this.props.login;
       
       if(loggedIn && documents && !documents.checkDocument && !documents.isFetching) {
         const currentParams = this.currentParams();
         checkEmailLanding(currentParams.get("id"), currentParams.get("fileName"), currentParams.get("reportName"));
       }
     
    }


    doCancel = () => {          
       let tempLocation=  window.location;     
       window.location = window.location.protocol + "//" + window.location.hostname+ ":"+ window.location.port+ "/"+getOrganizationFromLocation() ; 

    };

    render() {
        const { getUnsubscribeFromReport, documents, settings, idleLogout, logout, cancelIdleLogout, keepSessionActive, location } = this.props;
        const { loggedIn, showIdleLogout } = this.props.login;
        const {message, hasError, isValidDoc, isSubscribed} = documents;
        const messageClass = hasError ? 'error-message' : '';

        const issuer = this.organization;
        const currentParams1 = this.currentParams();
        const reportName   = currentParams1.get('reportName');
        const id = currentParams1.get('id') === 'TAX' ? -1 : currentParams1.get('id');
        const fileName = currentParams1.get('fileName');
        const reportDescFunc = labelsWrapper(getByKeys(["datafiles"]), getOrganizationFromLocation() === 'freddie');
        const href = fetchDocumentEndpoint(id, fileName, reportName, issuer);
        let unsubscribedNow = 'false';

        const downloadLink = documentDownload(href, fileName, fileName, 'FileDownload', {key: 1});

        if (!hasError && message && message === "Unsubscribed") {
           unsubscribedNow = 'true';
        }
        
        const noLoggedInHTML = !loggedIn
              ? <div className="error-text">To use this feature you must first be logged in</div>
              : null;
        let downloadHTML = null;
        let unsubscribeHTML = null;
        if(loggedIn) {
          downloadHTML = isValidDoc
               ? <div><p>Please click here to download the newly published file</p>
               <p>{downloadLink}</p></div>
               : <div><p>The file <b>{fileName}</b> is no longer available.</p></div>;
               
          unsubscribeHTML = !isSubscribed || unsubscribedNow === 'true'
               ? <div className={messageClass}> You are not subscribed to this report <b>{reportDescFunc(reportName)} </b>. </div>
               :   <div>
                   <p>If you no longer want to receive notification messages for <b>{reportDescFunc(reportName)} </b> please hit unsubscribe below: </p>
                      <div className="buttons">
                         <button className="btn btn-primary" onClick={() => getUnsubscribeFromReport(reportName)}>Unsubscribe</button>  &nbsp;&nbsp;
                      </div>
                   </div>;
        }
        
        
    return (
      <div id="app" className={this.organization}>
        {loggedIn && <IdleDetector showIdleLogout={showIdleLogout}
                                         idleTimeout={settings.idleTimeout}
                                         idleAction={idleLogout}
                                         defaultAction={logout}
                                         cancelAction={cancelIdleLogout}
                                         keepSessionActive={keepSessionActive} />}
         <Header {...this.props} params={this.params} />    
          <div className="content account">
            <header>
              <h1>File Download</h1>
            </header>
            &nbsp;&nbsp;&nbsp;
            <main>
            {noLoggedInHTML}
            {downloadHTML}
            {unsubscribeHTML}
   				<p>&nbsp;</p>
                <div className="buttons">
                    <button className="btn btn-primary" onClick={this.doCancel}>Cancel</button>
                </div>
	
			</main>
          </div>
          <Footer params={this.params} />
      </div>
    );
    };

}

function fetchDocumentEndpoint(id, name, reportName, issuer) {
  return reportName.lastIndexOf('_TAX_FILE') > 0 ? `/api/doc/tax/${issuer}/${name}` : `/api/report/download/${id}/${name}`;
}

function labelsWrapper(labels, isFreddie) {
  return (headingKey) => {
    const label = labels[headingKey];
    return isFreddie ? label && label.replace(/^MBS\s/, '') : label;
  };
}

export default connect(
  ({login, account, settings, documents, globalBanners}) => ({login, account, settings, documents, globalBanners }),
  { showLoginMessage, getUnsubscribeFromReport, checkEmailLanding, getSettings, isLoggedIn, loadBanners, idleLogout, logout, cancelIdleLogout, keepSessionActive}
)(FileDownload);
