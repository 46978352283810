import React, { Component } from 'react';

import Modal from 'common/Modal';

export default class NewPortfolio extends Component {
  state = {
    name: ''
  };

  onCreate = () => {
    if (this.state.name) {
      this.props.onCreate({...this.state});
    }
  };

  onNameKeyUp = (e) => {
    this.state.name = e.target.value;

    if (e.keyCode == 13) { // Enter key
      this.onCreate();
    }
  };

  render() {
    const { onClose, onCreate, invalidPortfolioName } = this.props;

    return (
      <Modal className="new-portfolio" onClose={onClose}>
        <div className="title">New Portfolio</div>
        <input type="text" autoFocus onKeyUp={this.onNameKeyUp} />
        {invalidPortfolioName && <div><span className="error-message">Error: the portfolio name provided is already in use.</span></div>}
        <button onClick={onClose}>Cancel</button>
        <button className="btn-primary" onClick={this.onCreate}>Create</button>
      </Modal>
    );
  }

}
