import React, { Component } from 'react';

import { passwordValidator } from 'common/form/validations'; 
import ButtonDropdown from 'common/button/ButtonDropdown';
import Select from 'common/form/Select';
import {maskingField} from 'common/util/common-helpers';

export function PasswordChangeForm({getFormElement}) {

  return (
    <div>
      {getFormElement({label: 'New Password', name: 'newPass', type: maskingField, required: true, validator: passwordValidator})}
      {getFormElement({label: 'Confirm Password', name: 'reNewPass', type: maskingField, required: true, validator: passwordMatchValidator})}      
    </div>
  );

}
																																							
function passwordMatchValidator(reNewPass, {newPass}) {
  return reNewPass === newPass || 'Passwords don\'t match';
}
