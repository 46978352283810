import React from 'react';
import Modal from './Modal';

export default ({className, title, text, onOk, onCancel}) => {
  
  let titleEl = title 
				  ? <div className="title">{title}</div>
				  : null;

  let textEl;

  if (typeof text === 'string' && text.length) {
  	let texts = text.split(/\r?\n/);
  	if (texts.length === 1) {
  		textEl = text;
  	} else {
  		textEl = texts.map((t, idx) => (<div key={idx} className="modal-confirm-line">{t}</div>));
  	}
  } else {
  	textEl = text;
  }

  return (
  	<Modal className="confirm-modal" onClose={onCancel}>
	    <div className="modal-wrap">
	      <div className={`modal ${className}`}>
	        <div className="close-button" onClick={onCancel}><i className="fa fa-close"/></div>
	        {titleEl}
	        <div className="modal-confirm-text">{text}</div>
	        <div className="confirm-buttons">
		        <button onClick={onCancel}>Cancel</button>
		        <button className="btn-primary" onClick={onOk}>OK</button>
	        </div>
	      </div>
	    </div>
    </Modal>
  );
}
