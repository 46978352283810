import { trim } from 'common/util/common-helpers';
import { reducerGenerator } from 'common/util/redux';

import { DETAILS_REQUEST, DETAILS_SORT, MONTHLY_DETAILS_REQUEST, MONTHLY_DETAILS_RESPONSE, MONTHLY_DETAILS_ERROR } from 'www/actions/detailsActions';
import { transformStrats } from 'www/util/www-helpers';
import { onSortArray, onSortWrappedArray, applyInitialSortWrapped } from 'common/util/sorter';
import { updatePvm } from 'common/util/common-helpers';

const initialState = {
  message: null,
  data: {},
  hasError: false,
  isFetching: false,
  sort: {}
};

const stratRegExp = /distributionsMap/;

function onSortDetails(state, action) {
  const { key } = action;

  let results = {sortedWrapper: state.data};
  let sort = state.sort;

  if (stratRegExp.test(key)) {
    const stratKey = key.substring(key.lastIndexOf('.')+1);

    Object.keys(state.data).forEach((month) => {
      const key = `${month}.distributionsMap.${stratKey}`;

      results = onSortWrappedArray(results.sortedWrapper, state.sort[key], {...action, key});
      sort = {...sort, [key]: results.newSort};
    });
  }

  return { ...state, sort, data: results.sortedWrapper };
}

function applyDetailsSortDefaults(currSort, month, monthly) {
  const newSort = {};

  if (monthly && monthly.distributionsMap) {
    Object.keys(monthly.distributionsMap).map((key) => {
      newSort[`${month}.distributionsMap.${key}`] = {
        field: 'percentageInvestorLoanUPB',
        direction: 'desc'
      }
    });
  }

  return {...newSort, ...currSort};
}

    function onMonthlyDetailsResponse(state, action) {
      const {month, results} = action;

      if (results) {
            let factorDt = results.factorDt;
            let newDate = pvmPnewDate;
            let pvmKey = 'distributionsMap.PropertyValuationMethodType';
            updatePvm(factorDt, newDate, pvmKey,results)
      } else {
            console.warn('Missing Monthly value for Security Details, is this expected?')
      }

      let data = {
        ...state.data,
        [month]: {
          ...results,
          prefix: trim(results.prefix)
        }
      };
      let sort = applyDetailsSortDefaults(state.sort, month, results);

      Object.keys(sort).forEach((key) => {
        let { sortedWrapper, newSort } = applyInitialSortWrapped(data, key, sort[key]);
        data = sortedWrapper;
        sort[key] = newSort;
      });

      return {
        ...initialState,
        data,
        sort
      };
    }


export default reducerGenerator(initialState, {
  DETAILS_REQUEST: (state, action) => initialState,
  MONTHLY_DETAILS_REQUEST: (state, action) => ({...initialState, isFetching: true}),
  MONTHLY_DETAILS_RESPONSE: onMonthlyDetailsResponse,
  MONTHLY_DETAILS_ERROR: (state, action) => ({...initialState, hasError: true, message: action.message}),
  DETAILS_SORT: (state, action) => (onSortDetails(state, action))
});
