import React from 'react';

import ButtonDropdown from 'common/button/ButtonDropdown';
import { COLUMN_TYPES } from 'common/table/SimpleTable';
import { eventHandler } from 'common/util/events';

const MAX_PAGES_TO_SHOW = 10;
const NUM_PAGES_AROUND_SELECTED = 4 / 2; // This is total, including before and after selected

import {
  DEFAULT_INIT_PAGE
} from 'common/util/common-helpers';

export function loadPageWithCall(funcToCall, funcArg, curPageSize, sort) {
  return (newPageIdx, newPageSize) => funcToCall(
    funcArg,
    newPageIdx,
    newPageSize ? newPageSize : curPageSize,
    sort
  );
}

export default function Paginator() {
  let setState, props;

  const onPageSelect = pageIdx => {
    if (!props.loadPage) {
      console.error('No loadMore found on props, cannot load new page.');
      return;
    }

    props.loadPage(pageIdx);
  };

  return {
    key: 'paginated',
    attach: function(featureSetState) {
      setState = featureSetState;
      return {};
    },
    onRender: function(newProps, config) {
      let { classes, tableConfig, footer, customizing } = config;

      if (customizing) {
        // Pagination doesn't make sense while we are customizing the table, do nothing
        return;
      }

      classes.push('pagination');
      props = newProps;

      const { count, pageViewName, pageIdx, pageSize, isFetchingMore } = props;
      if (isFetchingMore) {
        classes.push('is-fetching-more');
      }

      const pages = createPaginatedButtons({pageIdx, isFetchingMore, onPageSelect, count, pageSize});
      const maxResultsProps = prepareMaxResults({isFetchingMore, pageSize, props});

      const name = pageViewName || 'results';
      const pagination =
        <div className="pagination-container">
          <ul className="pagination-buttons">{pages}</ul>
          <span><ButtonDropdown {...maxResultsProps}/> {name} per page </span>
        </div>;
      footer = pagination;
      return {classes, tableConfig, footer};
    }
  };
}

function getClassName(pageIdx, isFetchingMore, selectedPageIdx) {
  const activeOrDefault = pageIdx === selectedPageIdx ? 'active disabled=true' : `default disabled=${isFetchingMore}}`;
  return `btn ${activeOrDefault} page-btn`;
}

function pageButton({i, pageIdx, isFetchingMore, onPageSelect}) {

  const buttonContent = isFetchingMore && pageIdx === i
    ? <i className="fa fa-spinner fa-pulse"/>
    : i;
  const shouldBeDisabled = isFetchingMore || pageIdx === i;

  return <button
    key={i}
    className={getClassName(i, isFetchingMore, pageIdx)}
    onClick={eventHandler(onPageSelect, i)}
    disabled={shouldBeDisabled}
  >
    {buttonContent}
  </button>;
}

function createPaginatedButtons({pageIdx, isFetchingMore, onPageSelect, count, pageSize}) {
  const pages = [pageButton({i: DEFAULT_INIT_PAGE, pageIdx, isFetchingMore, onPageSelect})];

  const numPages = Math.ceil(count / pageSize);

  let minEllipsisIdx = -1, maxEllipsisIdx = -1;
  if (numPages > MAX_PAGES_TO_SHOW) {
    if (pageIdx > NUM_PAGES_AROUND_SELECTED + 2) {
      minEllipsisIdx = DEFAULT_INIT_PAGE + 1;
    }
    maxEllipsisIdx = Math.min(numPages, pageIdx + NUM_PAGES_AROUND_SELECTED + 1);
  }

  for (let i = DEFAULT_INIT_PAGE + 1; i <= numPages; i++) {
    if (i === minEllipsisIdx) {
      pages.push(<span key={i}>&#8230;</span>);
      i = pageIdx - NUM_PAGES_AROUND_SELECTED - 1;
      continue;
    }

    if (i === maxEllipsisIdx && i !== numPages) {
      pages.push(<span key={i}>&#8230;</span>);
      i = numPages - 1;
      continue;
    }

    pages.push(pageButton({i, pageIdx, isFetchingMore, onPageSelect}));
  }

  return pages;
}

function prepareMaxResults({isFetchingMore, pageSize, props}) {
  const options = [50, 100, 250, 500]
    .map(maxResults => ({
      text: maxResults,
      action: pageSize === maxResults ? () => {} : eventHandler(props.loadPage, DEFAULT_INIT_PAGE, maxResults)
    }));

  return {
    buttonClassName: 'max-results-dropdown',
    disabled: isFetchingMore,
    label: pageSize,
    options: options
  };
}
