import React from 'react';
import { currencyWithCents, dashToNull, formatDateMmDdYyyy, formatDateMmYyyy, htmlEncode } from 'common/util/common-formatters';
import { assign } from 'common/util/common-helpers';
import ENUMS from 'www/util/enumFormatters';
const  { issuer, remicClassType }  = ENUMS;

import {
  corrected,
  cusipLink,
  dealLink,
  disclosureStateFormatter,
  factor,
  rate,
  remicRate,
  securityType,
  yesNoIndicator
} from 'www/util/www-formatters';

import {
  isArmSecurity,
  isEsfPseudopool,
  isMirrorOrMirroredSecurity,
  isRemicSecurity,
  isShelfSecurity
} from 'www/util/www-helpers';

export default ({issuance, current, issuanceNotes, ongoingNotes, buttons}) => {
  const isRemic = isRemicSecurity(issuance);
  const remicFields = issuance.remicFields || {};
  const currentRemicFields = current.remicFields || {};
  const ppFields = issuance.pseudopoolFields || {};
  const isShelf = isShelfSecurity(issuance);
  const isArm = isArmSecurity(issuance) && issuance.armFields;
  const isEsfP = isEsfPseudopool(issuance);
    
  const row = assign({}, issuance, current, remicFields, issuance && issuance.armFields);
  
  return (
    <div className="details-header">
      {!isEsfP && <div className="cusip">{issuance.cusip || '---'}<label>CUSIP</label></div>}
      {isEsfP && <CusipHeader colKey="clsCusip" label="CUSIP" value={ppFields.clsCusip} show={isEsfP} formatter={cusipLink} row={row}/>}
      {!isEsfP && <div className="buttons">{buttons}</div>}
      <SecurityBanners issuance={issuance} current={current} ongoingNotes={ongoingNotes} />
      <ul className="metadata">
        <HeaderField colKey="secId" label="Pool Number" value={issuance.secId} show={!isRemic && !isEsfP}  row={row} />
        <HeaderField colKey="secId" label="Security Identifier" value={issuance.secId} show={isEsfP}  row={row} />
        <HeaderField colKey="trustNumber" label="Trust Identifier" value={remicFields.trustNumber} formatter={dealLink} show={isRemic || isEsfP}  row={row} />
        <HeaderField colKey="tranche" label="Class Identifier" value={remicFields.classId} show={isRemic || isEsfP}  row={row} />
        <HeaderField colKey="classType" label="Class Type" value={remicFields.classType} formatter={remicClassType} show={isRemic}  row={row} />
        <HeaderField colKey="groupId" label="Group" value={remicFields.groupId} show={isRemic}  row={row} />
        <HeaderField colKey="issuer" label="Issuer" value={issuer(issuance.issuer)} row={row} />
        <HeaderField colKey="prefix" label="Prefix" value={issuance.prefix} show={!isRemic && !isEsfP}  row={row} />
        <HeaderField colKey="issrSpclSecuType" label="Security Type" value={issuance.issrSpclSecuType} formatter={securityType}  row={row} show={isRemic || isEsfP}/>
        <HeaderField colKey="issueDt" label="Issue Date" value={formatDateMmDdYyyy(issuance.issueDt)}  row={row} />
        <HeaderField colKey="maturity" label="Maturity Date" value={formatDateMmYyyy(issuance.maturity)}   row={row} />
        <HeaderField colKey="issrSpclSecuType" label="Security Type" value={issuance.issrSpclSecuType} formatter={securityType}  row={row} show={!isRemic && !isEsfP}/>
        <HeaderField colKey="netRate" label="WA Net Interest Rate" value={getCurrentOrIssuanceValue({current, issuance, dataKey: 'netRate'})} formatter={rate} show={!isRemic && !isEsfP}  row={row} />
        <HeaderField colKey="netRate" label="WA Net Interest Rate - Current" value={getCurrentOrIssuanceValue({current, issuance, dataKey: 'netRate'})} formatter={rate} show={isEsfP}  row={row} />
        <HeaderField colKey="netInterestRate" label= "Net Interest Rate - Current" value= {((current && current.cusip) ? (current.remicFields && current.remicFields.netInterestRate) : (issuance.remicFields && issuance.remicFields.netInterestRate))} formatter={remicRate} show={isRemic} row={row} />

        <HeaderField colKey="upbIssuance" label="Investor Security UPB - Issuance" value={issuance.upb} formatter={currencyWithCents} show={!isRemic && !isEsfP} row={row} />
        <HeaderField colKey="upbIssuance" label="Class UPB - Issuance" value={issuance.upb} formatter={currencyWithCents} show={isRemic || isEsfP} row={row} />
        <HeaderField colKey="upbCurrent" label="Investor Security UPB - Current" value={getCurrentOrIssuanceValue({current, issuance, dataKey: 'upb'})} formatter={currencyWithCents} show={!isRemic && !isShelf}  row={row} />
        <HeaderField colKey="status" label="Security Status" value={getCurrentOrIssuanceValue({current, issuance, dataKey: 'status'})} row={row} show={!isRemic && isEsfP}/>
        <HeaderField colKey="lnCountIssuance" label="Loan Count - Issuance" value={issuance.lnCount} show={!isRemic && !isShelf}  row={row} />
        <HeaderField colKey="lnCountCurrent" label="Loan Count - Current" value={getCurrentOrIssuanceValue({current, issuance, dataKey: 'lnCount'})} show={!isRemic && !isShelf}  row={row} />
        <HeaderField colKey="principalType" label="Principal Type" value={remicFields.principalType} show={isRemic}  row={row} />
        <HeaderField colKey="status" label="Security Status" value={getCurrentOrIssuanceValue({current, issuance, dataKey: 'status'})} row={row} show={!isEsfP}/>
        <HeaderField colKey="interestType" label="Interest Type" value={remicFields.interestType} show={isRemic}  row={row} />
        <HeaderField colKey="notionalIndicator" label="Notional Class" value={yesNoIndicator(remicFields.notionalDealInd)} show={isRemic}  row={row} />
        <HeaderField colKey="recombinableIndicator" label="Recombinable Class" value={yesNoIndicator(remicFields.recombinableIndicator)} show={isRemic}  row={row} />
        <HeaderField colKey="factor" label="Security Factor" value={getCurrentOrIssuanceValue({current, issuance, dataKey: 'factor', defaultValue: ''})} formatter={factor} show={!isEsfP}   row={row} />
        <HeaderField colKey="factorDt" label="Security Factor Date" value={formatDateMmYyyy(getCurrentOrIssuanceValue({current, issuance, dataKey: 'factorDt'}))} show={!isShelf}  row={row} />
        <HeaderField colKey="secDesc" label="Security Description" value={issuance.secDesc} show={!isRemic && !isEsfP}  row={row} />
        <HeaderField colKey="subtype" label="Subtype" value={isArm ? ( (current && current.armFields) ? current.armFields.subType : issuance.armFields.subType) : ''} show={isArm && !isRemic}  row={row} />
        <HeaderField colKey="delay" label="Delay" value={currentRemicFields.delay || '-'} show={isRemic} row={row} />
        <HeaderField colKey="paidOffDate" label="Paid-Off Date" value={formatDateMmDdYyyy(currentRemicFields.paidOffDate) || '-'} show={isRemic} row={row} />
        <HeaderField colKey="index" label="Index" value={currentRemicFields.index || '-'} show={isRemic} row={row} />
      </ul>
      <IssuanceNotes issuance={issuance} issuanceNotes={issuanceNotes} />
    </div>
  );
};

function SecurityBanners({issuance, current, ongoingNotes}) {
  const banners = [];

  if (issuance != undefined && isMirrorOrMirroredSecurity(issuance)) {
    const mirrorUrl = issuance.mirrorDetailsUrl || 'http://www.freddiemac.com';

    banners.push(wrapBannerContent('mirror',
                  <span>
                    Additional exchange information is available for this security. 
                    <a href={mirrorUrl} target='_new'>View details <i className='fa fa-external-link' /></a>
                  </span>                  
      ));
  }

  if (ongoingNotes) {
    banners.push(wrapBannerContent('notes', ongoingNotes));
  }
  
  return banners.length === 0
    ? null
    : (
        <div className='security-banners'>
          {banners}
        </div>
      );
}

function wrapBannerContent(key, content) {
  return (
    <div key={key} className='security-banner gray'>
      <i className='security-banner-icon fa fa-info-circle' />
      <span className='security-banner-text'>
        {content}
      </span>
    </div>
  );
}

// Exported functions also used in L3Details

export function HeaderField({colKey, label, value, formatter, show, row = {}}) {
  if (show === false) {
    return null;
  }

  const formattedValue = (formatter ? formatter(value, {}, row) : value) || '-';
  return <li key={colKey}>{formattedValue}<label>{label}</label></li>;
}

export function CusipHeader({colKey, label, value, formatter, row = {}}) {
  const formattedValue = (formatter ? formatter(value, {}, row) : value) || '-';
  return <div className="cusip" key={colKey}>{formattedValue}<label>{label}</label></div>;
}

export function Badge({current, issuance, deal, className, title, dataKey, defaultValue, formatter}) {
  const rawValue = getCurrentOrIssuanceValue({current, issuance, deal, dataKey, defaultValue});
  const formattedValue = formatter && rawValue ? formatter(rawValue) : rawValue;

  if (!formattedValue) {
    return null;
  }

  return <span className={`badge ${className}`} title={title}>{formattedValue}</span>;
}

function getCurrentOrIssuanceValue({current, issuance, deal, dataKey, defaultValue = '-'}) {
  let value;

  // for pseudo pools, issuance data doesn't contain factorDt. As of DIS-3548, requirement is to default factorDt to issuance date under these conditions.
  if (isEsfPseudopool(issuance) && dataKey === 'factorDt') {
    value = (current.cusip || current.dealType) ? current[dataKey] : issuance[dataKey] || issuance['issueDt'];
  } else {
    value = deal ? deal[dataKey] : (current.cusip || current.dealType) ? current[dataKey] : issuance[dataKey];
  }
  return (value || value === 0) ? value : defaultValue;
}


function IssuanceNotes({issuance, issuanceNotes}) {
  if (typeof issuanceNotes !== 'string' || issuanceNotes.length === 0) {
    if (typeof issuance.issuanceNotes!== 'string' || issuance.length === 0) {
      return null;
    }else{
      return (
              <div className="issuance-notes-container">
                <div className="issuance-notes-header">Notes</div>
                <div className="issuance-notes-text">{issuance.issuanceNotes}</div> 
              </div> 
            );
    }    
  }   
  return (
    <div className="issuance-narrative-container">
      <div className="issuance-narrative-header">Notes</div>
      <div className="issuance-narrative-text">{issuanceNotes}</div>      
    </div>
  );
}
