import {
  DETAILS_REQUEST,

  COLLATERAL_RESPONSE,
  COLLATERAL_ERROR,

  COLLATERAL_COUNT_RESPONSE,
  COLLATERAL_COUNT_ERROR,

  COLLATERAL_SORT,
  LOAD_MORE_COLLATERAL_REQUEST,
  LOAD_MORE_COLLATERAL_RESPONSE
} from 'www/actions/detailsActions';
import { reducerGenerator } from 'common/util/redux';
import { onSortArray } from 'common/util/sorter';
import {useOldP} from 'common/util/common-helpers';

const initialState = {
  data: null,
  count: null,
  sort: null,
  hasError: false,
  message: null,
  isFetching: false,
  isFetchingMore: false
};

function onCollateralError(state, action) {
  return {
    ...state,
    data: null,
    count: null,
    hasError: true,
    isFetching: false,
    isFetchingMore: false,
    message: action.message || 'Unable to load collateral. Please try again later.'
    
  };
}

function onCollateralResponse(state, action) {
  const {pageIdx, pageSize, payload, sort, recordObject, factorDt} = action;

    let newDate = pvmPnewDate;

  for (const coll of payload ){
	  if ('P' === coll.propertyValuationMethodType && useOldP(factorDt, newDate) ){
		 coll.propertyValuationMethodType = 'oldP' ;
	  }
  }	
  return {
    ...state,
    selctedOption:recordObject.recordType,
    data: {
      collateral: payload,
      pageIdx,
      pageSize
    },
    isFetching: false,
    isFetchingMore: false,
    sort
  };

}

export default reducerGenerator(initialState, {
  DETAILS_REQUEST: (state, action) => ({...initialState, sort: state.sort}),
  COLLATERAL_REQUEST: (state, {pageIdx, pageSize}, factorDt) => ({
    ...state,
    isFetching: state.data == null,
    isFetchingMore: state.data != null,
    data: {
      collateral: (state.data && state.data.collateral) || initialState.data,
      pageIdx,
      pageSize
    },
    factorDt
  }),
  COLLATERAL_COUNT_RESPONSE: (state, action) => ({...state, count: action.count}),
  COLLATERAL_COUNT_ERROR: onCollateralError,
  COLLATERAL_RESPONSE: onCollateralResponse,
  COLLATERAL_ERROR: (state, {pageIdx, pageSize, payload, sort}) => ({
	    ...state,
	    data: {
	      collateral: [],
	      pageIdx,
	      pageSize
	    },
	    count: null,
	    hasError: true,
	    isFetching: false,
	    isFetchingMore: false
	  })
});
