import React from 'react';
import { useRouterHistory } from "react-router"
import { createHistory } from "history"

import { createBrowserHistory } from 'history';


let trackingUser;

export default function(trackingId) {
  window.onload = function() {
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

    if (!ga) {
      console.error('GA did not load correctly and will not function');
      return;
    }

    ga('create', trackingId, 'auto');
    trackUser(trackingUser);
    ga('send', 'pageview');
  };
}

export function trackDownload(category, fileName) {
  if (window.ga) {
    const downloadAttr = fileName.substring(fileName.lastIndexOf('/')+1);
    ga('send', 'event', category || 'Other Documents', 'download', downloadAttr);
  }
}

export function trackUser(userId) {
  if (window.ga) {
    ga('set', 'userId', userId);
    trackingUser = userId;
  }
}
