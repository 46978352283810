import React from 'react';

import Table, { TYPES } from 'common/table/Table';
import Customizable from 'common/table/features/Customizable';
import Paginator, { loadPageWithCall } from 'common/table/features/Paginator';
import ButtonDropdown from 'common/button/ButtonDropdown';
import {
  commaThousands,
  currencyWithCents,
  decimal,
  formatDateMmDdYyyy,
  formatDateMmYyyy,
  integer
} from 'common/util/common-formatters';

import {
  DEFAULT_INIT_PAGE,
  DEFAULT_COLLATERAL_PAGE_SIZE
} from 'common/util/common-helpers';

import { cusipLink, rate } from 'www/util/www-formatters';
import { getOrgCode,
  getCollateralOptionSelector, paginate
} from 'www/util/www-helpers';

import { mapColumns } from 'www/util/labels';
import { getNewSort } from 'common/util/sorter';

import ENUMS from 'www/util/enumFormatters';
const {issuer} = ENUMS;

const ISSAUANCE_PLACEHOLDER='ISSUANCE';
const ONGOING_PLACEHOLDER='CURRENT';
export default class StructurePanel extends React.Component {
  
  
  onOptionsSelect=(changedOption)=>{
    console.log("inside onchange method");
    const { cusip, collateral } = this.props;

    if (changedOption !== collateral.selctedOption) {
      this.props.getCollateralCount({'cusip':cusip,'recordType':changedOption});
      this.props.getCollateral({'cusip':cusip,'recordType':changedOption}, DEFAULT_INIT_PAGE, DEFAULT_COLLATERAL_PAGE_SIZE, collateral.sort );
    }
  }

  componentDidMount() {
    const { cusip, collateral, details } = this.props;
    let {selctedOption} = collateral;
    if(collateral.selctedOption=== undefined){
      if (details && details.data && details.data.current && details.data.current.discType && details.data.current.discType!='ISSUANCE'  )
        selctedOption=ONGOING_PLACEHOLDER;
      else
        selctedOption=ISSAUANCE_PLACEHOLDER;

    }
    if (!collateral.data) {
      this.props.getCollateralCount({'cusip':cusip,'recordType':selctedOption});
      this.props.getCollateral({'cusip':cusip,'recordType':selctedOption}, DEFAULT_INIT_PAGE, DEFAULT_COLLATERAL_PAGE_SIZE, collateral.sort);
    }
  } 

  render() {
    const { cusip, collateral, getCollateral, details, changePage } = this.props;
    const { data, sort, isFetching, message, count, isFetchingMore } = collateral;
    let {selctedOption} = collateral;
    const selectOptions = (details && details.data && details.data.current && details.data.current.discType && details.data.current.discType!='ISSUANCE') ? [ISSAUANCE_PLACEHOLDER, ONGOING_PLACEHOLDER] : [ISSAUANCE_PLACEHOLDER];
    const {onOptionsSelect}=this;
    let hasRemicCollateral=details.data.issuance.hasRemicCollateral;

    if(collateral.selctedOption=== undefined){
      if (details && details.data && details.data.current && details.data.current.discType && details.data.current.discType!='ISSUANCE')
        selctedOption=ONGOING_PLACEHOLDER;
      else
        selctedOption=ISSAUANCE_PLACEHOLDER;
    }

    if(details && details.data && details.data.issuance &&  data && data.collateral && data.collateral.length > 0 && details.data.issuance.issrSpclSecuType == 'ESF' && details.data.issuance.issuer == 'FNM'){
      data.collateral.forEach(esf => {esf.cusip = "-";});
    }

    if (message) {
      return <div className="empty-text">{message}</div>;
    } else if (isFetching || !data) {
      return <div className="loading more"><i className="fa fa-spinner fa-pulse"/></div>;
    }

    const pageSize = details.pageSize ? details.pageSize : data.pageSize;
    const pageIdx = details.pageIdx ? details.pageIdx : data.pageIdx;

    const gridConfig = {
      key: 'structure',
      className: 'structure-table',
      columnDefaults: {
        sortable: true
      },
      
      columns: [
        { key: 'prefix' },
        { key: 'secId' }
        ],
        
      columnEmptyText: '-',
      emptyText: {
        key: 'collateralText',
        text: 'No collateral available'
      }
    };

    if(details && details.data && details.data.issuance &&  data && data.collateral && data.collateral.length > 0 && details.data.issuance.issrSpclSecuType == 'ESF' && details.data.issuance.issuer == 'FNM'){
      gridConfig.columns.push({ key: 'cusip'});
    } else {
      gridConfig.columns.push({ key: 'cusip', formatter:  (v, c, r) => cusipLink(v,c,r)});
    }


    var titleString='';
        if(selctedOption=='CURRENT'){
        	titleString='UPB-weighted average net interest rate, as of Current';
        }else{
        	titleString='UPB-weighted average net interest rate, as of the issuance of the resecuritization'
        }
      gridConfig.columns.push({ key: 'netRate', formatter: rate, className: 'right', title: titleString });
	const restColumns=[
		{ key: 'issuer', formatter: issuer },
        { key: 'issueDt', formatter: formatDateMmDdYyyy },
        { key: 'maturity', formatter: formatDateMmYyyy },
        { key: 'upb', formatter: currencyWithCents, className: 'right', title: 'Total Investor Security UPB of the Security as of its issuance' },
        { key: 'upbContribIssuance', formatter: currencyWithCents, className: 'right', title: 'Portion of the Total Investor Security UPB (issuance) contributing to the resecuritization' },
        { key: 'upbContribCurrent', formatter: currencyWithCents, className: 'right', title: 'Contributing Investor Security UPB, as of the issuance of the resecuritization *' },
        { key: 'rate', formatter: rate, className: 'right', title: 'UPB-weighted average interest rate, as of the settlement of the resecuritization' },
        { key: 'rmm', formatter: integer, className: 'right', title: 'UPB-weighted average remaining months to maturity, as of the settlement of the resecuritization' },
        { key: 'lnAge', formatter: integer, className: 'right', title: 'UPB-weighted average loan age, as of the settlement of the resecuritization' }
      
	];
	restColumns.forEach((ob)=>gridConfig.columns.push(ob));
    const rbmColumns = [
  		{ key: 'trustNumber' },
  		{ key: 'classId' },
  		{ key: 'groupId' }
	];
	if(hasRemicCollateral != null && hasRemicCollateral=='YES'){
	rbmColumns.forEach((ob)=>gridConfig.columns.push(ob));
		
	}
    mapColumns('esf-collateral', gridConfig.columns);

    const config = {
      className: 'collateral-table',
      title: count !== null ? <span>This security contains <strong>{commaThousands(count)} SECURITIES</strong> as collateral.</span> : null,
      customizingTitle: 'Customizing All Security Level Collateral',
      table: {
        ...gridConfig,
        onHeaderClick: (column, sortKey) => {
          const newSort = getNewSort(sort, column.key, ((sort && sort.direction) || 'asc'));
          getCollateral({'cusip':cusip,'recordType':selctedOption}, DEFAULT_INIT_PAGE, pageSize, newSort);
        }
      }
    };
    config.buttons = [ optionSelector() ];
    const exportLink = `/api/security/${getOrgCode()}/${cusip}/collateral?export=true`;
    const paginatedCollateral = paginate(data.collateral, pageIdx, pageSize);
    return (
      <div>
        <Table type={TYPES.simple}
               features={[Customizable, Paginator]}
               {...this.props}
               config={config}
               data={paginatedCollateral.map(col => ({...col, ...col.armFields, ...col.rplFields}))}
               sort={sort}
               threshold={50}
               count={count}
               isFetchingMore={isFetchingMore}
               pageIdx={pageIdx}
               pageSize={pageSize}
               pageViewName={'securities'}
               loadPage={(collateral.data && collateral.count) ? loadPageWithCall(changePage, {'cusip':cusip,'recordType':selctedOption}, pageSize)
                   : loadPageWithCall(getCollateral, {'cusip':cusip,'recordType':selctedOption}, pageSize, sort)}
               exportLink={exportLink}
               exportName="collateral.csv" />
        <div className="caption">* as of the issuance of the Resecuritization</div>
      </div>
    );
    function optionSelector(){
      const options=getCollateralOptionSelector(selctedOption,selectOptions, onOptionsSelect);
      return (
              <div className="deal-tranch-month-selector right">
                <ButtonDropdown {...options} />
              </div>
              );
    }
  }
}
