import React from 'react';

import {eventHandler} from 'common/util/events';
import {formatDateYyyMm} from 'common/util/common-formatters';
import {getOrganization, getOrganizationFromLocation, toBoolean} from 'common/util/common-helpers';
import {getLabels} from 'www/util/labels';
import {trackDownload} from 'common/util/analytics';
import Banner from '../../admin/components/Banner';

export function documentDownload(href, linkText, downloadAttr, category, props, onClick, preventDownload = false) {
  const trackClick = eventHandler(trackDownload, category, downloadAttr);

  return (
    <div className="document-download-container">
      <a {...props} href={href} onClick={preventDownload ? onClick : trackClick } download={downloadAttr}>{linkText}</a>
    </div>
  );
}

export function fetchInitialData({ settings, getSettings, isLoggedIn, login, loadBanners }) {
  if (!login.checkedLoggedIn) {
    isLoggedIn();
  }

  if (!settings || (!settings.hasFetched && !settings.isFetching)) {
    getSettings();
    if(loadBanners){
      loadBanners();
    }
  }
}

export function isSmbs(details){
    return details && details.deal && details.deal.dealType=='SMBS';
}

export function isXsio(details) {
    return details && details.deal && details.deal.dealType=='ESF' && details.deal.issuer=='FRE';
}

export function sortByGroupAndClassId(classes) {
    if (!Array.isArray(classes) || classes.length === 0) {
        return classes;
    }
    try {
        classes.sort((a, b) => {
            //First sort on groupId
            if (a.groupId !== b.groupId) {
                return a.groupId - b.groupId;
            }
            //Second sort on classId
            let av = String(a.classId); //force to be string
            let bv = String(b.classId);
            return av.localeCompare(bv, undefined, { numeric: true, sensitivity: 'base' });
        })
    } catch (error) {
        console.log('Error sorting array by Group or Class Id.', {classes, error});
        if (isLocalhost()) {
            throw new Error('Error sorting array by Group or Class Id. See console for more info.');
        }
    }
    return classes;
}

export function subscribeToReportLink(report, subscribeToReport, unsubscribeToReport) {

    const isTaxReport = report.headingKey.toString().includes('TAX') ? true : false;
    if (report.isSubscribing) {
        return (
            <div className="report-subscribe-container">
                <i className="fa fa-spinner fa-pulse"/>
            </div>
        );
    }

    let regularClass = 'for-regular ';
    let regularIcon;
    let hoverClass = 'for-hover ';
    let hoverText;
    let hoverIcon;

    if (report.isSubscribed) {
        regularClass += "subscribed";
        regularIcon = <i className="fa fa-bell"/>;

        hoverClass += "subscribed";
        hoverText = "Turn Off Email Alerts";
        hoverIcon = <i className="fa fa-bell-slash-o"/>

    } else {
        regularClass += "not-subscribed";
        regularIcon = <i className="fa fa-bell-slash-o"/>;

        hoverClass += "not-subscribed";
        hoverText = "Turn On Email Alerts";
        hoverIcon = <i className="fa fa-bell"/>
    }

    return (
        <div className="report-subscribe-container">
            <a className={regularClass} onClick={onClickHandler}>Email Alerts {regularIcon}</a>
            <a className={hoverClass} onClick={onClickHandler}>{hoverText} {hoverIcon}</a>
        </div>
    );

    function onClickHandler() {
        isTaxReport ? (report.isSubscribed ? unsubscribeToReport : subscribeToReport)(report.headingKey) :
        (report.isSubscribed ? unsubscribeToReport : subscribeToReport)(convertToRefName(report.headingKey));
    }
}

export function paginate(response, pageIdx, pageSize) {
    const startIndex = (pageIdx - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const data = response.slice(startIndex, endIndex);
    return data;
}

export function getMonthSelector(selectedMonth, dataFiles, onSelectItem) {
  const months = Array.isArray(dataFiles) ? dataFiles : dataFiles.data.slice(0, dataFiles.data.length-1);

  return {
    label: (selectedMonth && formatDateYyyMm(selectedMonth.name || selectedMonth)) || 'Month...',
    isDropdown: true,
    buttonDisabled: !selectedMonth,
    onClick: eventHandler(onSelectItem, 1, selectedMonth),
    options: months.map((month) => ({
      text: month.name || month,
      onClick: eventHandler(onSelectItem, 1, month),
      formatter: formatDateYyyMm
    }))
  };
}

export function getCollateralOptionSelector(selectedOption,availableOptions, onSelectItem){
  return{
    label:selectedOption,
    isDropdown: true,
    buttonDisabled:selectedOption,
    onClick: eventHandler(onSelectItem, selectedOption),
    options:availableOptions.map((option)=>({
      text:option,
      onClick: eventHandler(onSelectItem,option)
    }))
  };
}

export function getDealUrl(dealId, issuer) {
  return `/${getOrganization(issuer) || getOrganizationFromLocation()}/deal/${dealId}`;
}

export function getDealDocumentUrl(filename, trustNumber, issuer) {
  return `/api/deal/${getOrganization(issuer) || getOrganizationFromLocation()}/document/download/${trustNumber}_${filename}`;
}

export function getDetailsUrl(cusip, issuer) {
  return `/${getOrganization(issuer) || getOrganizationFromLocation()}/details/${cusip}`;
}

export function getDetailOrDealUrl(securityDetails) {
  const issuer = securityDetails.issuer;

  if (securityDetails.cusip) {
    return getDetailsUrl(securityDetails.cusip, issuer);
  } else if (securityDetails.dealId) {
    return getDealUrl(securityDetails.dealId, issuer);
  } else {
    throw new Error("Received search result that does not have a cusip or deal id!", securityDetails);
  }
}

export function getSearchLocationObject(query) {
    return `/${getOrganizationFromLocation()}/search/${query}`;

}

export function getOrganizationCodeFromLocation() {
  const orgFullName = getOrganizationFromLocation();
  if ('fannie' === orgFullName) {
    return 'FNM';
  } else if ('freddie' === orgFullName) {
    return 'FRE';
  } else {
    throw new Error('Unexpected Organization: ' + orgFullName);
  }
}

export function updateDocumentTitle(title) {
  const org = getOrganizationFromLocation();
  const disclosuresTitle = title ? `${title} | ` : '';
  document.title = `${disclosuresTitle}Disclosure - ${org === 'fannie' ? 'Fannie Mae' : 'Freddie Mac'}`;
}

const ARM = 'ARM';
export function isArmSecurity({amortizationType}) {
  if (window.location.search === "?isArm") {
    return true;
  }
  
  return amortizationType === ARM;
}

/**
 * Determine if anything is an ESF or part of one.. deal, class, 
 * pseudo-pool, or any collateral thereof.
 */
export function isEsfAnything(dealOrSecurityOrCollateralOrSummaryOrType) {
  
  if (dealOrSecurityOrCollateralOrSummaryOrType == undefined) {
    return false;
  }

  if (typeof dealOrSecurityOrCollateralOrSummaryOrType === 'string') {
    return dealOrSecurityOrCollateralOrSummaryOrType.toLowerCase().indexOf('esf') !== -1;
  }

  if (Array.isArray(dealOrSecurityOrCollateralOrSummaryOrType)) {
    return dealOrSecurityOrCollateralOrSummaryOrType.some(isEsfAnything);
  }

  return isEsfAnything(dealOrSecurityOrCollateralOrSummaryOrType.dealType) ||
         isEsfAnything(dealOrSecurityOrCollateralOrSummaryOrType.secType) ||
         isEsfAnything(dealOrSecurityOrCollateralOrSummaryOrType.type) ||
         isEsfAnything(dealOrSecurityOrCollateralOrSummaryOrType.collateral) ||
         isEsfAnything(dealOrSecurityOrCollateralOrSummaryOrType.collateralSummary) ||
         isEsfAnything(dealOrSecurityOrCollateralOrSummaryOrType.remicFields);
}

/** 
 * given a security, return if it is an ESF class (belong to deal, parent of pseudopool)
 */
export function isEsfClass(secu) {
  return secu != undefined &&
         isRemicSecurity(secu) &&
         secu.remicFields &&
         secu.remicFields.dealType === 'ESF';
}

export function isEsfDeal(deal) {
  return deal != undefined &&
         deal.dealType === 'ESF';
}

export function isEsfPseudopool(secu) {
  return secu != undefined &&
         secu.secType === 'ESFPSP';
}

export function isFannieSecurity(sec) {
  return !isFreddieSecurity(sec);
}


export function isFreddieSecurity({issuer}) {
  return getOrganization(issuer) === 'freddie';
}

export function isGnmaBackedSecurity({gnmaColtIndicator}) {
  return gnmaColtIndicator === 'YES' || gnmaColtIndicator === 'Y';
}

export function isGnmaCollateral({issuer}){
  return issuer === 'GNMA';
}

export function isOffPlatformRedirect({platformInd, offPlatformRedirects, disclosureRedirectActiveIndicator = 'NO'}) {
  const redirectUrlPresent = offPlatformRedirects || toBoolean(disclosureRedirectActiveIndicator);
  return !redirectUrlPresent && (platformInd === '2' || platformInd === '3');
}

export function isLocalhost() {
  return window && window.location && window.location.hostname === 'localhost';
}

export function isMirrorOrMirroredSecurity({mirrorType}) {
  return mirrorType === 'MIRROR' || mirrorType === 'MIRRORED';
}

export function isOpenMajorSecurity({issuer, secType, deliveryOption, displayOpenMajor}) {

  if (window.location.search === '?major') {
    return true;
  }
  
  return issuer === 'FNM' &&
         secType === 'MBS' &&
         deliveryOption === 'MULTIPLE_DELIVERIES_ACCEPTED' &&
         toBoolean(displayOpenMajor);
}

export function isRecombinableTranche({trancheType}) {
  return trancheType === 'RECOMBINABLE';
}

export function isRemicSecurity({secType}) {
  return secType === 'REMIC';
}

export function isReperformingSecurity({isRPL}) {
  return toBoolean(isRPL);
}

export function isSingleSecurity({issrSpclSecuType}) {
  return issrSpclSecuType === 'UMBS' || issrSpclSecuType === 'SUPER';
}

export function isL1({secType}) {
  return 'MBS' === secType
}

export function isInterestOnly({interestOnlySecurityIndicator}) {
  return interestOnlySecurityIndicator === 'YES';
}

export function isInterestOnlyOrMixed({interestOnlySecurityIndicator}) {
  return interestOnlySecurityIndicator === 'YES' || interestOnlySecurityIndicator === 'MIXED';
}

export function isRPLModStepOrFixed(security) {
  return (isRPLModFixed(security) ||  isRPLModStep(security));
}
export function isRPLModFixed({rplType}) {
  return rplType === 'MODFX';
}

export function isRPLModStep({rplType}) {
  return rplType === 'MODSTP';
}

export function isOffPlatformSecurity({offPlatformRedirects}) {
  if(offPlatformRedirects){
    return true;
  }
  return false;
}

export function isShelfSecurity({requestType}) {
  return requestType === 'SHELF_ONLY' || requestType === 'SHELF_WITH_NO_COLLATERAL_AND_WIREINS';
}


/**
 * Some report types have an index after them like L1L2_INTRADAY_ISSUANCE_POOL_LEVEL_1
 * and L1L2_INTRADAY_ISSUANCE_POOL_LEVEL_2 and need to be treated as same, so strip
 * the index at end. But don't strip all numbers since some have valid numbers
 * as part of their type name, so only strip if it's a single numerical digit preceded by an underscore.
 */
export function rootReportType(reportType) {
  if (typeof reportType !== 'string') {
    return reportType;
  }

  return reportType.replace(/\_\d{1}$/, '')
  		           .replace(/\_\d{2}$/, '')
  		            .replace(/\_GNMA/, '');
}

/**
 * For some reports, we group them in same section on the UI, so the subscription should also be grouped
 * these are Intraday security, loan, supplement files as well as 5 more reports defined below.
 * these reports have refName defined in ReportDefinitionEnum.java, thatis used to drive the email subscription.
 */
export function convertToRefName(reportType) {
  if (typeof reportType !== 'string') {
    return reportType;
  }
  
  if(reportType.indexOf('INTRADAY_ISSUANCE') !== -1 
   || reportType === 'FNM_GNMA_REMIC_SHORTFILE' 
   || reportType === 'FNM_GNMA_REMIC_COMPONENT' 
   || reportType === 'FNM_GNMA_REMIC_FACTOR' 
   || reportType === 'FNM_GNMA_SMBS_FACTOR' 
   || reportType === 'FRE_GNMA_REMIC_COMPONENT') {
    return rootReportType(reportType);
  }
  
  return reportType;
}

const emptyOption = {key: 'emptypOption', value: '', display: 'All'};

export function getStatusOptions() {
  const labels = getLabels("securityStatus");
  return getOptions(labels, false);
}

export function getTypeOptions() {
  const labels = getLabels("securityTypes");
  return getOptions(labels, true);
}

export function getSecurityTypeFromLabel(label) {
    if (label == null) {
        return null;
    }
    const labels = getLabels("securityTypes");
    const types = getOptions(labels, true);
    let securityType = null;

    for (let i = 0; i < types.length; i++) {
        if (types[i].display === label) {
            securityType = types[i].value;
            break;
        }
    }
    return securityType;
}

function getOptions(labels, orgSpecific) {
  const orgLabels = labels && (orgSpecific ? labels[getOrganizationFromLocation()] : labels) || {};
  const types = Object.keys(orgLabels).map(key => {
    return {value: key, display: orgLabels[key]};
  });
  types.unshift(emptyOption);
  return types;
}

export function getOrgCode() {
  const orgFullName = getOrganizationFromLocation();
  if ('fannie' === orgFullName) {
    return 'FNM';
  } else if ('freddie' === orgFullName) {
    return 'FRE';
  } else {
    throw new Error('Unexpected Organization: ' + orgFullName);
  }
}

export function switchByOrganization(fannieValue, freddieValue) {
  return getOrgCode() === 'FNM' ? fannieValue : freddieValue;
}

export function isFreddie() {
  return getOrgCode() !== 'FNM' ;
}

// To check whether the query paramters are for deal or other fields
export function redirectToDeal(queryParams) {
    let redirect = false;
    try {
        if (queryParams && queryParams.q && queryParams.q.length > 0) {
            const arrParams = queryParams.q.split(',').length > 1 ? queryParams.q.split(',') : queryParams.q.split(' ').length > 1 ? queryParams.q.split(' ') : queryParams.q.split(',');
            if(arrParams && arrParams.length > 0) {
                arrParams.map((param) => {
                    param.indexOf('/') >= -1 ? redirect = true : redirect = false;
                });

                // check if the trust numbers are same then only redirect
                redirect = redirect ? checkTrustNumberIdentical(arrParams) : false;
            }
        } else {
            redirect = false;
        }
    } catch (e) {
        console.log(e);
        redirect = false;
    }

    return redirect;
}

// To check if the trust numbers are same then only redirect
// arrParams: array of trustnumber/class eg: ['004679/PA']
export function checkTrustNumberIdentical(arrParams) {
  
    let bool = false;
    try {
        const deal = [];
        const classes = [];
        arrParams.map((data) => {
            const arrDealClass = data.split('/');
            arrDealClass[0] && arrDealClass[0].length ? deal.push(arrDealClass[0].trim()) : deal;
            arrDealClass[1] && arrDealClass[1].length ? classes.push(arrDealClass[1].trim()) : classes;
        });
        bool = classes && classes.length && !!deal.reduce(function(a, b){ return (a === b) ? a : NaN; });
    } catch (e) {
        console.log(e);
        bool = false;
    }
    return bool;
}

// Get deal URL for single deal
export function getDealUrlForSingleDeal(securityDetails, queryParams) {  
    return `/${getOrganization(securityDetails.issuer) || getOrganizationFromLocation()}/deal/${securityDetails.dealId}`;
}

// method to retrieve TrustIds from Response
export function getArrayTrustIdsFromResponse(response) {  
  var arrayTrustIds = [];    
  response.forEach(item => arrayTrustIds.push(item.dealId));  
  
  return arrayTrustIds;
  
}

//Get deal URL for classes combination
export function getDealUrlForClasses(securityDetails, queryParams) {
   let baseUrl = getDealUrlForSingleDeal(securityDetails, queryParams); 
   return baseUrl.concat(`?q=${queryParams.q}`);
}


export function checkTrustNumberIdenticalForDealIdSearch(arrParams) {
    let bool = false;
    try {        
        bool = !!arrParams.reduce(function(a, b){ return (a === b) ? a : NaN; });        
    } catch (e) {
        console.log(e);
        bool = false;
    }    
    return bool;
}

// This function is to sort array of objects based on any key which is an integer.
export function sortIntByKey(key){
    return function(a, b) {
        const itemA = a[key];
        const itemB = b[key];
        if (itemA < itemB) //sort string ascending
            return -1;
        if (itemA > itemB)
            return 1;
        return 0; //default return value (no sorting)
    }
}

export const mergeBanners = (banners)=>{
  let tags = [];
  for (const ban of banners) {
    const bannerTag = parseStringForLinks(ban.text);
    tags = tags.concat(bannerTag);
    tags.push(<pre></pre>);
  }
  return tags;
}

export const parseStringForLinks = (inputString) => {
  let tags = [];
  let startIndex = 0;
  let middleIndex = 0;
  let endIndex = 0;
  let remainingString = inputString;
  let key = 0;//Dummy key to make react happy
  while(inputString.length > 0) {
    startIndex = remainingString.indexOf("<LS>");
    middleIndex = remainingString.indexOf("<TS>");
    endIndex = remainingString.indexOf("</LS>");
    if(startIndex === -1){
      tags.push(<span >{remainingString}&nbsp;</span>)
      break;
    } else { 
      tags.push(<span >{remainingString.substring(0, startIndex)}&nbsp;</span>);
      tags.push(<a style ={{margin: 0, color: 'white', fontWeight:'bold', textDecoration: 'underline'}} href={remainingString.substring(startIndex+4, middleIndex).trim()}>{remainingString.substring(middleIndex+4, endIndex).trim()}</a>);
      remainingString = remainingString.substring(endIndex+5);
    }
  }
  return tags;
}

export const reconcileBanners = function(banners) {
  let info = [];
  let success = [];
  let error = [];
  if(banners) {
    for(const banner of banners) {
      if(banner.type === 'Info') {
        info.push(banner);
      }
      if(banner.type === 'Success') {
        success.push(banner);
      }
      if(banner.type === 'Error') {
        error.push(banner);
      }
    }
  }
  return {info, success, error};
  
}

export function getIssuerLegalAddress(settings, issuer){
  if (settings === undefined) {
    return issuer;
  }

  let legalName = settings.issuerLegalNameFNM || '';
  let legalAddress = settings.issuerLegalAddressFNM || '';

  if(issuer === 'FRE') {
    legalName = settings.issuerLegalNameFRE || '';
    legalAddress = settings.issuerLegalAddressFRE || '';
  }

  return [legalName].concat(legalAddress.split('\n')).map(lin =><div className="tax-addr-line">{lin}</div>)
}
