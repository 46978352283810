import { blankNotAvailable, formatNotAvailable } from 'common/util/common-formatters';
import { getLabels } from 'www/util/labels';


export const enums = [
  'amortizationType',
  'borrowerCount',
  'channelType',
  'daysDelinquentMonthCount',
  'disclosureStateType',
  'governmentInsured',
  'initialFixedRatePeriodType',
  'issuer',
  'loanCorrectionType',
  'daysDelinquentMonthCount',
  'loanModificationProgramType',
  'loanModificationType',
  'loanPurposeType',
  'modificationInformation',
  'modifiedPoolSnapshot',
  'mortgageInsurancePercentage',
  'mortgageInsurancePercentageForCollateral',
  'numberOfUnits',
  'occupancyStatusType',
  'propertyType',
  'quartileLevel',
  'remicClassType',
  'securityStatus',
  'unavailableData',
  'yesNoNotAvailableNotApplicableType',
  'yesNoNotAvailableNotApplicableType2',  
  'propertyValuationMethodType',
  'borrowerAssistancePlan',
  'alternativeDelinquencyResolution',
  'numberOfAlternativeDelinquencyResolutions',
  'interestRateBuydownType',
  'greenProgramType',
  'greenBuildingCertificationType',
  'energyRatingIndexType',
  'renewableEnergyType'
];
 const ENUMS = enums.reduce((formatters, enumKey) => {
  const enumHash = getLabels(enumKey);
  let formatted;
  if (enumHash) {
    formatters[enumKey] = function(value, properties = {}) {
      const { showNotAvailable = false } = properties;
      if(undefined !=value ){        
        formatted = enumHash[value] || enumHash[value.toUpperCase()] || value;
      }else {        
        formatted = value;
      }
      
      return showNotAvailable ? formatNotAvailable(formatted) : blankNotAvailable(formatted);
    };
  } else {
    console.warn(`Enum '${enumKey}' does not have labels defined - using noop formatter.`);
    formatters[enumKey] = (value) => value;
  }

  return formatters;
}, {});


export default ENUMS;