import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { getOrganizationFromLocation } from 'common/util/common-helpers';

import {
  addToPortfolio, 
  addResultsToPortfolio, 
  removeFromPortfolio, 
  updatePreferences, 
  restorePreferences, 
  savePreferences,
  copyPreferences,
  filterPreferences,
  showCopyCustomView,
  hideCopyCustomView,
  showSaveAsCustomView,
  hideSaveAsCustomView,
  showDeleteCustomView,
  hideDeleteCustomView
} from 'www/actions/accountActions';

import ConfirmationModal from 'www/components/account/ConfirmationModal';

import { showLoginMessage } from 'common/login/loginActions';
import { clearHistory, deleteHistoryItem } from 'www/actions/historyActions';
import { 
  getDeal,
  getDealIssuance,
  getComponentDeal,
  getDealTax,
  getDealCollateralCount,
  getDealCollateral,
  getDealDates,
  getDealAsOf,
  getDealDocuments,
  getDealTaxFactorCount,
  getDealByTrustNumberAndClass,
  getDealClassCount,
  getDealClassCusips,
  changePage
} from 'www/actions/dealActions';
import {
    getAllocation,
    getDetails,
    sortDetails,
    getCollateral,
    getCollateralCount,
    loadMoreCollateral,
    getDataFiles,
    fetchMonthlyData,
    fetchTaxData,
    taxSort,
    taxYearSelect,
    getSecurityDocuments,
    getDetailsMajors
} from 'www/actions/detailsActions';

import {
  max,
  not,
  DEFAULT_INIT_PAGE,
  DEFAULT_TAX_FACTOR_PAGE_SIZE,
  DEFAULT_CLASSES_PAGE_SIZE
} from 'common/util/common-helpers';

import {
  cusipLink,
  decimal,
  fileIcons,
  millions,
  monthYearOnly,
  trancheNameToClass,
  trustNumberFormatter,
  zeroToEmpty
} from 'www/util/www-formatters';

import {
  dateRange,
  fixPosition,
  isOffPlatformSecurity,
  isRecombinableTranche,
  toDate,
  updateDocumentTitle  
} from 'www/util/www-helpers';



import AddToPortfolioButton from 'www/components/account/AddToPortfolioButton';
import DealTabs from 'www/components/deal/DealTabs';
import DealHeader from 'www/components/deal/DealHeader';
import OffPlatformHeader from 'www/components/details/OffPlatformHeader';
import { History, updateHistoryNavHeight } from 'www/components/history/History';
import { getNewSort, sortArray } from 'common/util/sorter';
import Header from 'www/containers/Header';
import Footer from 'www/components/Footer';
import {fetchInitialData} from "../util/www-helpers";

import { isLoggedIn, idleLogout, cancelIdleLogout, logout, keepSessionActive } from 'common/login/loginActions';
import { getSettings, dismissBanner } from 'www/actions/settingsActions';
import {loadBanners} from 'www/actions/bannerAction';
import IdleDetector from 'common/IdleDetector';

class Deal extends Component {

  constructor(props){
    super(props);
    this.trustNumber = props.match.params.trustNumber;
    this.params = props.match.params;
    this.organization = getOrganizationFromLocation()
    this.state={
      activeTab:1, taxRequestCalled: false
    }
  }

  componentDidMount() {
    const { details, getDeal, getDealDates, getDealByTrustNumberAndClass, getDealClassCount, getDealClassCusips, settings} = this.props;
    updateDocumentTitle(trustNumberFormatter(this.trustNumber));
    this.setState({taxRequestCalled: false});
    if (!details || !details.isFetching) {
      if (this.props.location && this.props.location.query && this.props.location.query.q && this.props.location.query.q.length) {
        const classParam = this.getClassParamFromQuery(this.props.location.query.q);
          classParam && classParam.length ? getDealByTrustNumberAndClass({trustNumber: this.trustNumber, classParam: classParam}, DEFAULT_INIT_PAGE, DEFAULT_CLASSES_PAGE_SIZE) : getDeal(params.trustNumber);
         classParam && classParam.length ? getDealClassCount(this.trustNumber, classParam) : getDealClassCount(this.trustNumber);
      } else {
          getDeal(this.trustNumber, DEFAULT_INIT_PAGE, DEFAULT_CLASSES_PAGE_SIZE);
          getDealClassCount(this.trustNumber);
      }
      getDealDates(this.trustNumber);
      getDealClassCusips(this.trustNumber);
    }
      if(sessionStorage.getItem('tabIndex') && Number(sessionStorage.getItem('tabIndex'))){
          this.setState({activeTab: Number(sessionStorage.getItem('tabIndex'))})
      }
    fetchInitialData(this.props);
  }  

  UNSAFE_componentWillReceiveProps(nextProps) {

    if (this.props.details.hasError !== nextProps.details.hasError) {
        window.location.assign(`${window.location.origin}/${getOrganizationFromLocation()}?q=redirected`);
    }

    document.body.scrollTop = 0;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { getDeal } = this.props;
    const {trustNumber} = nextProps.match.params;
    if (trustNumber !== this.trustNumber) {
      updateDocumentTitle(trustNumberFormatter(trustNumber));
      getDeal(trustNumber);      
      return false;
    }
    return true;
  }

  componentDidUpdate() {
    updateHistoryNavHeight('security-details');
  }

  getClassParamFromQuery = (queryParam) => {
    const arrQueryParam = queryParam.split(',').length > 1 ? queryParam.split(',') : queryParam.split(' ').length > 1 ? queryParam.split(' ') : queryParam.split(',');
    const deal = [];
    let classes = [];
    try {
        if (arrQueryParam && arrQueryParam.length) {
            arrQueryParam.map((queryParam) => {
                const arrDealClass = queryParam.split('/');
                deal.push(arrDealClass[0])
                classes.push(arrDealClass[1]);
            });
        }
    } catch(e) {
      console.log(e);
        classes = [];
    }
    return classes;
  }


  render() {
    const trustNumber   = this.trustNumber;
    const {
      login, account, showLoginMessage, details, restorePreferences,
      settings, idleLogout, logout, cancelIdleLogout, keepSessionActive
    } = this.props;

    const { deal, dates, isFetching, hasError, message, classCusips } = details;

    const classes = classNames('security-details', {
      loading: isFetching,
      'empty-text': hasError
    });

    let classParam = '';
    if (this.props.location && this.props.location.query && this.props.location.query.q && this.props.location.query.q.length) {
        classParam = this.getClassParamFromQuery(this.props.location.query.q);
    }

    const renderMain = (children) => (
      <div id="app" className={this.organization}>
        {login.loggedIn && <IdleDetector showIdleLogout={login.showIdleLogout}
                                         idleTimeout={settings.idleTimeout}
                                         idleAction={idleLogout}
                                         defaultAction={logout}
                                         cancelAction={cancelIdleLogout}
                                         keepSessionActive={keepSessionActive} />}
        <Header {...this.props} params={this.params} />
        <div className="content">
          <History {...this.props} />
          <main className={classes}>{children}</main>
        </div>
        <Footer params={this.params} />
      </div>

    );

    if (isFetching) {
      return renderMain(<i key="loading" className="fa fa-spinner fa-pulse"/>);
    }

    if (hasError) {
      return renderMain(message || 'An error occurred.');
    }

    if (deal == undefined) {
      return renderMain('Deal not found.');
    }

    if (isOffPlatformSecurity(deal)) {
      return renderMain(
        <div className="details-wrapper" key="details">
            <OffPlatformHeader {...deal} />
          </div>
        );
    }

    const detailsProps = {
      ...this.props,
      activeTab: this.state.activeTab,
      trustNumber,
      deal,
      dealId: deal.dealId,
      dates,
      loggedIn: login.loggedIn,
      cancelBtnClick: restorePreferences,
      preferences: account.preferences,
      onLoginClick: showLoginMessage,
      classParam,
      classCusips: classCusips
    };

    const headerButtons = <AddToPortfolioButton key="portfolio-add" {...detailsProps} />;
      let hideCollateralAndDocumentTab = false;
      if (this.props.location && this.props.location.query && this.props.location.query.q && this.props.location.query.q.length) {
        hideCollateralAndDocumentTab = true;
      }
    return renderMain(
   
        <div className="details-wrapper" key="details">
          <ConfirmationModal {...this.props} />
          <DealHeader deal={deal} ongoingNotes={deal.notes} buttons={headerButtons} />
          <DealTabs {...detailsProps} hideCollateralAndDocumentTab={hideCollateralAndDocumentTab} trustNumber={this.trustNumber}/>
        </div>    
    );
  }
}


export default connect(
  ({ deal, login, account, history, security, settings, globalBanners}) => {
      const tax = {tax: security.tax};
      return { ...deal, login, ...tax, account, history, settings, globalBanners};
  },
  {
    getDeal,
    getDealIssuance,
    getComponentDeal,
    getDealTax,
    getDealCollateralCount,
    getDealCollateral,
    getDealDates,
    getDealAsOf,
    getDealDocuments,
    getDealTaxFactorCount,
    getDealByTrustNumberAndClass,
    getDealClassCount,
    getDealClassCusips,
    showLoginMessage,
    clearHistory,
    deleteHistoryItem,
    addToPortfolio,
    addResultsToPortfolio,
    removeFromPortfolio,
    fetchMonthlyData,
    fetchTaxData,
    taxSort,
    taxYearSelect,
    updatePreferences,
    restorePreferences,
    savePreferences,
    copyPreferences,
    filterPreferences,
    showCopyCustomView,
    hideCopyCustomView,
    showSaveAsCustomView,
    hideSaveAsCustomView,
    showDeleteCustomView,
    hideDeleteCustomView,
    getSettings,  isLoggedIn, loadBanners,
    idleLogout,
    logout,
    cancelIdleLogout,
    keepSessionActive,
    changePage
  }
) (Deal);


