export function reducerGenerator(initialState, functionChain, defaultState) {
  return (state = initialState, action) => {
    if (functionChain[action.type]) {
      const mergedState = defaultState ? {...state, ...defaultState} : state;
      return functionChain[action.type](mergedState, action);
    }

    return state;
  };
}

export function reduceReducers(...reducers) {
  return (previous, current) => {
    return reducers.reduce((p, r) => r(p, current), previous);
  };
}

export function reduceMiddleware(actionMiddlewareMap) {
  return ({getState}) => {
    return (next) => {
      return (action) => {
        next(action); //Let requests flow to reducers before performing actions

        const middleware = actionMiddlewareMap[action.type];
        if (middleware) {
          middleware(getState, next, action);
        } 
      };
    };
  };
}