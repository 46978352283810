import React from 'react';

const { countries } = require('Config');

import BaseFormComponent from 'common/form/BaseFormComponent';
import FormElement from 'common/form/FormElement';
import { emailValidator, cityValidator, phoneValidator, requiredSelectedValidator, stateValidator, zipValidator } from 'common/form/validations';

import Select from 'common/form/Select';

export class PersonalInfo extends BaseFormComponent {

  render() {
    const { profileFields, isFetching, updateProfile, message } = this.props;
    const { isInvalid, isDirty } = this.state;

    return (
      <div>
        <h3>Personal Information</h3>
        <div className="profile-form">
          <label>Email Address</label>
          <input type="email" value={profileFields.email} readOnly="true"/>
          <CoreInfo getFormElement={this.getFormElement} profileFields={profileFields} />
          <button disabled={isInvalid || !isDirty} onClick={this.onSubmit(updateProfile, profileFields)}>
            {isFetching ? (<i className="fa fa-spinner fa-spin"/>) : 'Update Profile'}
          </button>
          <div className="error-message">{message}</div>
        </div>
      </div>
    );
  }

}

export function CoreInfo({getFormElement, profileFields = {}}) {
  const { firstName, lastName, organization, organizationType, city, state, zipCode, primaryPhone, countryCode } = profileFields;
  const required = true;

  const companyTypes = [
    'Academic',
    'Broker-Dealer',
    'Government',
    'Investor',
    'Originator/Seller/Servicer',
    'Rating Agency',
    'Research & Analyst',
    'Third-Party Data Vendor',
    'Trade Association',
    'Other'
  ]
    .map(companyType => ({
      value: companyType,
      display: companyType
    }));

  const countryTypes = countries
    .map(country => ({
      value: country.code,
      display: country.label
    }));

  companyTypes.unshift({value: '', display: 'Please Select'});
  countryTypes.unshift({value: '', display: 'Please Select'});

  return (
    <div>
      <div className="split-group">
        {getFormElement({label: 'First Name', name: 'firstName', value: firstName, required})}
        {getFormElement({label: 'Last Name', name: 'lastName', value: lastName, required})}
      </div>
      <div className="split-group">
        {getFormElement({label: 'Company', name: 'organization', value: organization, required})}
        {getFormElement({label: 'Company Type', name: 'organizationType', value: organizationType, required, validator: requiredSelectedValidator, type: Select, options: companyTypes})}
      </div>
      {getFormElement({label: 'City', name: 'city', value: city, required, validator: cityValidator, className: 'city'})}
      {getFormElement({label: 'State', name: 'state', value: state, required, validator: stateValidator, className: 'state'})}
      {getFormElement({label: 'Postal Code', name: 'zipCode', value: zipCode, required, validator: zipValidator, className: 'zip'})}
      {getFormElement({label: 'Phone Number', name: 'primaryPhone', value: primaryPhone, required, validator: phoneValidator, className: 'phone'})}
      {getFormElement({label: 'Country', name: 'countryCode', value: countryCode, required, validator: requiredSelectedValidator, type: Select, options: countryTypes, className: 'country'})}
    </div>
  );
}

export function EmailInfo({getFormElement, profileFields = {}}) {
	  const required = true;
	  const { email } = profileFields;
	  return (
	    <div>
	      {getFormElement({label: 'Email Address', name: 'email', value: email, required, validator: emailValidator})}
	    </div>
	  );
	}
