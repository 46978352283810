import { DETAILS_REQUEST, DETAILS_MAJORS_REQUEST, DETAILS_MAJORS_RESPONSE, DETAILS_MAJORS_ERROR } from 'www/actions/detailsActions';
import { reducerGenerator } from 'common/util/redux';

const initialState = {
    message: null,
    data: null,
    hasError: false,
    isFetching: false
};

export default reducerGenerator(initialState, {
    DETAILS_REQUEST: (state, action) => initialState,
    DETAILS_MAJORS_REQUEST: (state, action) => ({...state, isFetching: true}),
    DETAILS_MAJORS_RESPONSE: (state, action) => ({...initialState, data: action.results}),
    DETAILS_MAJORS_ERROR: (state, action) => ({
        ...state,
        data: null,
        hasError: true,
        isFetching: false,
        message: action.message || 'Unable to load majors data. Please try again later.'
    })
});