import { DETAILS_REQUEST, DATA_FILES_REQUEST, DATA_FILES_RESPONSE, DATA_FILES_ERROR } from 'www/actions/detailsActions';
import { reducerGenerator } from 'common/util/redux';


const initialState = {
  message: null,
  data: null,
  hasError: false,
  isFetching: false
};

function getDecoratedDataFile(cusip, secId, dataFile) {
  return {
    name: dataFile,
    links: {
      xml: `/api/security/download/${secId}_${cusip}_${dataFile}.xml`,
      csv: `/api/security/download/issuance/${secId}_${cusip}_${dataFile}.csv`
    },
    loginRequired: true
  };
}

function getDecoratedDataFiles(state, action) {
  const { cusip, secId, results } = action;

  results.sort();

  return {
    ...initialState,
    data: results.reduce((decoratedFiles, dataFile) => {
      return decoratedFiles.concat(getDecoratedDataFile(cusip, secId, dataFile));
    }, [])
  };
}

export default reducerGenerator(initialState, {
  DETAILS_REQUEST: (state, action) => initialState,
  DATA_FILES_REQUEST: (state, action) => ({...initialState, isFetching: true}),
  DATA_FILES_RESPONSE: getDecoratedDataFiles,
  DATA_FILES_ERROR:(state, action) => ({...initialState, hasError: true, message: action.message})
});
