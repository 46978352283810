import React from 'react';
import { v4 as uuid } from 'uuid';
import TabPanel from 'common/TabPanel';
import DealTrancheTable from 'www/components/deal/DealTrancheTable';
import DealComponentTable from 'www/components/deal/DealComponentTable';
import DealCollateralTable from 'www/components/deal/DealCollateralTable';
import DealDocumentList from 'www/components/deal/DealDocumentList';
import DealTaxFactorTable from 'www/components/deal/DealTaxFactorTable';

//import DealExchangeTable from 'www/components/deal/DealExchangeTable';
//import DealFactorTable from 'www/components/deal/DealFactorTable';
// import DealOverview from 'www/components/deal/DealOverview';

export default (props) => {
  const { activeTab } = props;

  let tabs = [
    <DealTrancheTable  tabIndex={1} key="mainTranches" tabTitle="Classes" {...props} />,
    <DealComponentTable  tabIndex={2} key="components" tabTitle="Components" {...props}/>,
    <DealCollateralTable  tabIndex={3} key="collateral" tabTitle="Collateral" {...props} />,
    <DealDocumentList   tabIndex={4} key="documents" tabTitle="Documents" {...props} />,
    <DealTaxFactorTable  tabIndex={5} key="taxFactor" tabTitle="Tax Factors" {...props} />,

    /*
    l3.exchanges && l3.exchanges.length 
      ? <DealExchangeTable key="exchanges" tabTitle="Recombinables" l3={l3} />
      : <DealTrancheTable key="recombinables" tabTitle="Recombinables" l3={l3} tranches={recombinableTranches} />,
    <DealFactorTable key="factors" tabTitle="Factors" l3={l3} />,
    <DealOverview key="overview" tabTitle="Overview" l3={l3} mainTranches={mainTranches} collateral={collateral} />,
    */
  ];

  return (
    <div className="detail-tabs">
      <TabPanel tabActive={activeTab} changePage={props.changePage}>{tabs}</TabPanel>
    </div>
  );
};
