import React from 'react';

export default ({classes, name, value, onChange, options, onMouseDown}) => {
  return (
    <select name={name} value={value} className={classes} onChange={onChange} onMouseDown = {onMouseDown}>
      {options.map(({value, display}) => {
         return <option key={value} value={value}>{display}</option>; 
        })
      }
    </select>
  );
};
