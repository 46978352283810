import { LOGIN_REQUEST } from 'common/login/loginActions';
import API from 'common/util/api';
import { reduceMiddleware } from 'common/util/redux';
import { getOrganizationFromLocation } from 'common/util/common-helpers';

import { SETTINGS_REQUEST, SETTINGS_RESPONSE, SETTINGS_ERROR } from 'www/actions/settingsActions';


function settingsRequest(getState, next, action) {
  API.get('settings/'+getOrganizationFromLocation()).then(
    (response) => {
      next({...action, type: SETTINGS_RESPONSE, response});
    },
    (message) => {
      next({...action, type: SETTINGS_ERROR, message});
    }
  );
}

export default reduceMiddleware({
  [SETTINGS_REQUEST]: settingsRequest,
  [LOGIN_REQUEST]: settingsRequest
});