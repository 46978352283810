export const phoneRegex = /^(\d{3,})*$/;
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*["_;:',?\/*~$^+=<>\[\]{}%\\.`|!@#&()-]).{12,}$/;
export const usStateRegex = /^(AL|AK|AZ|AR|CA|CO|CT|DE|D\.?C\.?|FL|GA|HI|ID|IL|IN|IA|KS|KY|LA|ME|MD|MA|MI|MN|MS|MO|MT|NE|NV|NH|NJ|NM|NY|NC|ND|OH|OK|OR|PA|RI|SC|SD|TN|TX|UT|VT|VA|WA|WV|WI|WY|AS|FM|GU|MH|MP|PW|PR|VI)$/i;
export const zipCodeRegex = /^[a-zA-Z0-9]*$/;
export const cityRegex = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*([^0-9]*)$/;
export const mmDdYyyyRegex = /^((0?[1-9])|(1[0-2]))[-\/](0?\d|[1-2]\d|3[01])[-\/](\d{4})$/;
export const mmYYYYRegex = /^((0[1-9])|(1[0-2]))[-\/](\d{4})$/;
export const numberOrBlank = /^(\d+|\d*\.\d+)?$/;
export const commaNumberOrBlank = /^(\d{1,3}(,\d{3})*)?$/;
export const moneyOrBlank = /^(\d+|\d*\.\d{1,2})?$/;
export const factorOrBlank = /^((\d{0,4})?(\.\d{1,8})?)?$/;
export const rateOrBlank = /^(\d{0,10}(\.\d{1,10})?)?$/;
export const emailRegex = /^[a-zA-Z0-9\.-_\+]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+$/;
// above format regular expression literal are valid in JS, but need pure string for non-js use (like input tags)
export const emailRegexString = "^[a-zA-Z0-9\.-_\+]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+$";