import React, { cloneElement } from 'react';

export const SORT_ASCENDING = {};
export const SORT_DESCENDING = {};
export const maskingField = 'password';

export const DEFAULT_COLLATERAL_PAGE_SIZE = 100;
export const DEFAULT_TAX_FACTOR_PAGE_SIZE = 50;
export const DEFAULT_CLASSES_PAGE_SIZE = 50;

export const DEFAULT_INIT_PAGE = 1;
export const DEFAULT_MAX_PAGE_SIZE=9999999;
export const IS_75_DAY_SECURITY = 'Y';

export function assign(target, ...sources) {
  if (!isObject(target)) {
    target = {};
  }

  if (sources.length) {
    sources.forEach(assignImpl);
  }

  return target;

  function assignImpl(source) {
    if (!isObject(source)) {
      return;
    }
    Object.keys(source).forEach(k => target[k] = source[k]);
  }
}

function createCharValidator(validCharsOrCharMap) {

  if (typeof validCharsOrCharMap === 'string') {
    return createIndependentCharValidator(validCharsOrCharMap);
  } if (validCharsOrCharMap === undefined) {
    console.log('Requested createCharValidator without valid arguments--no chars specified.');
    return () => true;
  }
  return createDependentCharValidator(validCharsOrCharMap);

  function createIndependentCharValidator(validChars) {
    const validCharLookup = toCharCodeLookup(validChars);
    return function independentCharValidator(char) {
      return !!validCharLookup[char];
    }
  }

  function createDependentCharValidator(validCharMap) {
    const validCharLookupByPrevious = {};

    Object.keys(validCharMap).forEach(prev => validCharLookupByPrevious[prev] = toCharCodeLookup(validCharMap[prev]));

    return function dependentCharValidator(char, prev) {

      const prevMap = validCharLookupByPrevious[prev];
      return prevMap !== undefined && !!prevMap[char];
    }
  }
}

function createDateCharCodeValidator(validatorsByPosition) {
  return function dateCharCode(event) {
    if (event == undefined || 
        event.target == undefined || 
        event.target.value == undefined || 
        event.target.value.length == undefined) {
      console.log('invalid dateCharCode event', event);
      return true;
    }

    const value = event.target.value;
    const len = value.length;

    const validator = validatorsByPosition[len];
    if (validator === undefined) {
      // len too long or got messed up
      return false;
    }

    return validator(event.charCode, value[len-1]);
  }
}

/**
 * Used by tables, rows, and cells to create a class name appender that returns the
 * static name if no dynamic function is provided, or calls the dynamic function
 * and returns the results along with the static class name.
*/
export function createDynamicClassNameAppender(baseClassName, dynamicClassNameFn) {
    
  if (typeof dynamicClassNameFn !== 'function') {
    return () => baseClassName;
  }

  return (row, column, rowIdx) => {
    let dynamicClassName = dynamicClassNameFn(row, column, rowIdx);
    return isEmpty(dynamicClassName) ? baseClassName : baseClassName + ' ' + dynamicClassName;
  };
}

/**
 * Used in table configs to generate a separate class name for each row, based on a group
 * field and when the group field value changes.
 */
export function createGroupClassNameGenerator(groupFieldName, data, groupFirstRowClassName, otherClassName) {
  return function groupDynamicClassName(row, column, rowIndex) {
    return rowIndex === 0 || row[groupFieldName] !== data[rowIndex - 1][groupFieldName]
      ? groupFirstRowClassName
      : otherClassName;
  }
}

const issuers = {
  FNM: 'fannie',
  FRE: 'freddie'
};

const orgLabels = {
  fannie: 'Fannie Mae',
  freddie: 'Freddie Mac'
};

export function getOrganization(issuer) {
  return issuers[issuer];
}

export function getOrganizationFromLocation() {
  return window.location.pathname.split('/')[1];
}

export function getOrganizationLabel(org) {
  return orgLabels[org];
}

function dateProcessor(value) {
  return value ? value.replace(/[\/\.]/g, '-') : value;
}

export function deepCopy(source) {

  var typ = typeof source;

  switch(typ) {

    case 'string':
    case 'number':
    case 'undefined':
    case 'boolean':
    case 'function':
      return source;
  }

  if (source === null) {
    return source;
  }

  if (source.toLocaleDateString && source.getTime && !isNaN(source.getTime())) {
    return new Date(source.getTime());
  }

  const target = Array.isArray(source) ? [] : {};
  const keys = Object.getOwnPropertyNames(source);

  keys.forEach(key => target[key] = deepCopy(source[key]));

  return target;
}

export function defaults(target, ...sources) {
  if (!isObject(target)) {
    target = {};
  }

  if (sources.length) {
    sources.forEach(defaultsImpl);
  }

  return target;

  function defaultsImpl(source) {
    if (!isObject(source)) {
      return;
    }
    Object.keys(source).forEach(k => 
    {
      if (target[k] === undefined) {
        target[k] = source[k];      
      }
    });
  }
}

export function format(pattern, values) {

  if (typeof pattern !== 'string') {
    return pattern;
  }

  return pattern.replace(/\{([\w][\w\d-]*)\}/g, replacePlaceholder);

  function replacePlaceholder(match, key) {
    const value = values[key];
    return value !== undefined ? value : key;
  }
}

export function getFirstInt(text) {
  let digits = /\d+/.exec(text);
  return digits === null
    ? null
    : parseInt(digits);  
}

//Note: processors need to be able to be performed multiple times w/o side effects (e.g. can't replace / with \/)
const processors = {
  issuanceFrom: dateProcessor,
  issuanceTo: dateProcessor,
  maturityFrom: dateProcessor,
  maturityTo: dateProcessor
};

function processQuery(query) {
  let processedQuery = {};

  if (query){
      Object.keys(query).map(key => {
          let value = query[key];
          processedQuery[key] = processors.hasOwnProperty(key) ? processors[key](value) : value;
    });
  } else {
    console.log('query string is undefined')
  }
  return processedQuery;
}

export function getQueryString(query) {
  let params = [];

  const processedQuery = processQuery(query);

  Object.keys(processedQuery).forEach((key) => {
    const value = processedQuery[key];

    if (value || value === 0 || value === false) {
      params.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  });
  return params.length > 0 ? `?${params.join('&')}` : '';
}

export function getParams(query) {
  let params = {};

  Object.keys(query).forEach((key) => {
    const value = query[key];

    if (value || value === 0 || value === false) {
      params[key] = value;
    }
  });

  return params;
}

/**
 * given a flat array of objects, return an array of grouped objects
 *
 * @remarks
 *
 * Example:
 *
 *     groupObjectArray( 
 *        [
 *          {group: 1, value: 'a'},
 *          {group: 1, value: 'b'},
 *          {group: 2, value: 'c'},
 *          {group: 2, value: 'd'}
 *        ],
 *        'group');
 *
 *  Returns:
 *
 *  [
 *    {
 *      key: 1,
 *      values: 
 *        [
 *          {group: 1, value: 'a'},
 *          {group: 1, value: 'b'},
 *        ]
 *    },
 *    {
 *      key: 2,
 *      values: 
 *        [
 *          {group: 2, value: 'c'},
 *          {group: 2, value: 'd'}
 *        ]
 *    },
 *  ]
 *
 */
export function groupObjectArray(array, field) {

  if (!Array.isArray(array) || array.length === 0) {
    return [];
  }

  const grouped = [];
  const groupsByKey = {};

  array.forEach(obj => {
    var key = obj[field];

    let group = groupsByKey[key];

    if (group === undefined) {
      group = {
        key,
        values: []
      };
      grouped.push(group);
      groupsByKey[key] = group;
    }

    group.values.push(obj);    
  });

  return grouped;
}

export function intersperseInReactArray(values, inBetweenFn) {
  if (!Array.isArray(values)) {
    return values === undefined ? null : values;
  }

  let len = values.length;

  if (len < 2) {
    return values;
  }

  let interspersed = new Array(len * 2 - 1);
  interspersed[0] = values[0];
  let j = 1;
  for(let i=1; i<len; i++) {
    interspersed[j++] = inBetweenFn('inBetweenValue' + j, j);
    interspersed[j++] = values[i];
  }

  return interspersed;
}

const alphaNumericCharCodesOrWildCard = toCharCodeLookup('*abcdefjhijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890');

export function isAlphaNumericCharCodeOrWildCard(charCodeOrEvent) {
  return !!alphaNumericCharCodesOrWildCard[(charCodeOrEvent || {}).charCode || charCodeOrEvent];
}

export function isBlank(value) {
  return value === null || value === undefined || value === '';
}

export function isBlankOrNaN(value) {
  return (!value && value !== 0) || isNaN(value);
}

const IS_DATE_STRING_VALUE = Object.prototype.toString.call(new Date());

export function isDate(value) {
  return typeof value === 'object' &&
         (value instanceof Date || Object.prototype.toString.call(value) === IS_DATE_STRING_VALUE);          
}

const decimalPointCharCode = '.'.charCodeAt(0);
export function isDecimalPointCharCode(charCodeOrEvent) {
  return decimalPointCharCode === ((charCodeOrEvent || {}).charCode || charCodeOrEvent);
}

const commaPointCharCode = ','.charCodeAt(0);
export function isCommaCharCode(charCodeOrEvent) {
  return commaPointCharCode === ((charCodeOrEvent || {}).charCode || charCodeOrEvent);
}


// useful in .filter(isDefined)
export function isDefined(value) {  
  return value !== undefined;
}

/**
 * Returns true if the value is empty for any type's representation of
 * empty: undefined, null, NaN, emtpy string, empty array, invalid date,
 * or object without any keys.
 */
export function isEmpty(value) {

  let valueIsDate;

  return value === null ||
         value === undefined ||
         (typeof value === 'number' && isNaN(value)) ||
         value.length === 0 ||
         ((valueIsDate = isDate(value)) && isNaN(value.getTime())) ||
         (!valueIsDate && isObjectStrict(value) && Object.keys(value).length === 0);
}

export function isEnterCharCode(charCodeOrEvent) {
  return 13 === ((charCodeOrEvent || {}).charCode || charCodeOrEvent);
}

export function isInsideDomNode(testEventOrElement, containerDomElement) {

  if (testEventOrElement == undefined || containerDomElement == undefined) {
    return false;
  }

  if (testEventOrElement.target) {
    testEventOrElement = testEventOrElement.target;
  }

  while(testEventOrElement) {
    if (testEventOrElement === containerDomElement) {
      return true;
    }
    testEventOrElement = testEventOrElement.parentNode;
  }

  return false;
}

export function isLocalhost() {
  return window && window.location && window.location.hostname === 'localhost';
}

export const isMmDdYyyyCharCode = createDateCharCodeValidator(
  {
    '0': createCharValidator('01'),
    '1': createCharValidator(
          {
            '0': '123456789',
            '1': '012'
          }),
    '2': createCharValidator('/'),
    '3': createCharValidator('0123'),
    '4': createCharValidator(
          {
            '0': '123456789',
            '1': '0123456789',
            '2': '0123456789',
            '3': '01'
          }),
    '5': createCharValidator('/'),
    '6': createCharValidator('12'),
    '7': createCharValidator(
          {
            '1': '9',
            '2': '01'
          }),
    '8': createCharValidator('0123456789'),
    '9': createCharValidator('0123456789')
  });

export const isMmYyyyCharCode = createDateCharCodeValidator(
  {
    '0': createCharValidator('01'),
    '1': createCharValidator(
          {
            '0': '123456789',
            '1': '012'
          }),
    '2': createCharValidator('/'),
    '3': createCharValidator('12'),
    '4': createCharValidator(
          {
            '1': '9',
            '2': '01'
          }),
    '5': createCharValidator('0123456789'),
    '6': createCharValidator('0123456789')
  });


const numericCharCodes = toCharCodeLookup('1234567890');

export function isNumericCharCode(charCodeOrEvent) {
  return !!numericCharCodes[(charCodeOrEvent || {}).charCode || charCodeOrEvent];
}

export function isObject(value) {
  var type = typeof value;
  return !!value && (type == 'object' || type == 'function');
}

export function isObjectStrict(value) {
  return value != undefined &&
         typeof value === 'object' &&
         !Array.isArray(value) &&
         !isDate(value);
}

/**
    Determines if an object is empty, the truth of which depends on type:

    undefined - always empty
    null - always empty
    string - empty string (no characters)
    array - no elements is empty
    object - empty if no keys
    number - never empty
    boolean - never empty
    date - empty if no valid date/time
 */
// export function isEmpty(value) {
//   if (value == undefined) {
//     return true;
//   }
//
//   switch (typeof value) {
//     case 'string':
//       return value.length === 0;
//
//     case 'number':
//     case 'boolean':
//       return false;
//   }
//
//   if (Array.isArray(value)) {
//     return value.length === 0;
//   }
//
//   if (typeof value.getTime === 'function') {
//     return isNaN(value.getTime());
//   }
//
//   return Object.keys(value).length === 0;
// }

const cusipCharValues = (function getCusipCharValues() {
  let charValues =  {
    '*': 36,
    '@': 37,
    '#': 38
  };

  for (let i = 0; i<10; i++) {
    charValues[i.toString()] = i;
  }

  for (let i=0; i<26; i++) {
    charValues[String.fromCharCode(i + 65)] = i + 10;
  }

  return charValues;
})();

export function isValidSecurityId(Id) {         
	var securityIdRegex =new RegExp("^(\\w{0,6})$");	
	return securityIdRegex.exec(Id);	  
} 

export function isValidTrustId(Id) {        
   	var trustIdReg =new RegExp("^((\\d{4}-[A-Z]{1}?\\d{2})|(?=[A-Z0-9]{7,10})|(\\d{4}-[A-Z]?\\d{3}))$");	     
    return trustIdReg.exec(Id);	  
}

export function isValidtrustAndClassID(Id) {         
	const trustAndClassIDReg =new RegExp("^((\\d{4}-[A-Z]{1}?\\d{2})|([A-Z0-9]{6,10})|(\\d{4}-[A-Z]?\\d{3}))(/\\w{1,4})?$");
	return trustAndClassIDReg.exec(Id);	  
}  


export function isValidIdentifier(id){
	let success = true;
	if(!isValidCusip(id)){
		if(!isValidSecurityId(id)) {
			if(!isValidTrustId(id)){
				if(!isValidtrustAndClassID(id)){
					success = false
				}
			} 
		}
	}
	return success;
}

export function isValidCusip(cusip) {
  if (typeof cusip !== 'string' || cusip.length !== 9) {
    return false;
  }

  let sum = 0;
  cusip = cusip.toUpperCase();

  for(let i = 0; i<8; i++) {
    let c = cusip.charAt(i);
    let v = cusipCharValues[c];
    if (v === undefined) {
      return false;
    }
    if (i % 2 === 1) {
      v *= 2;
    }
    sum += Math.floor(v / 10) + v % 10;
  }

  let checkDigit = ((10 - (sum % 10)) % 10).toString();
  return checkDigit === cusip.charAt(8);
}

export function throttle(fn, wait) {
  var timeoutId;
  var args;
  var scope;

  return function() {
    args = arguments;
    scope = this;

    if (!timeoutId) {
      timeoutId = setTimeout(() => {
        fn.apply(scope, args);
        timeoutId = undefined;
      }, wait);
    }
  };
}

export function getTargetArray(data, key) {
  if (!key) {
    return data;
  }

  return key.split('.').reduce((value, key) => {
    return value ? value[key] : null;
  }, data);
}


export function useOldP(factorDt, newDate) {
  let fDate = new Date(factorDt).getTime();
  let nDate = new Date(newDate).getTime();

  if (fDate <  nDate) {
    return true;
  } else {
    return false;
  }
}

export function compareDateLessThan(dt1, dt2) {
  if (!dt1 || !dt2 || dt1 === undefined || dt2 === undefined ) {
    console.error ("incorrect date format")
    return false;
  }
  let date1 = new Date(dt1).getTime();
  let date2 = new Date(dt2).getTime();

  if (date1 <  date2) {
    return true;
  } else {
    return false;
  }
}

export function getPlatformDate(month, year, day){
  if (!month || !year || !day || month===undefined || year === undefined || day === undefined )
    return ;
  return month + "-" + day + "-" + year;
}


export function updatePvm(compareDt, newDate, pvmKey,data) {
  if (data === undefined || compareDt === undefined || newDate === undefined || pvmKey === undefined)
    return;
  let pvmData = getTargetArray(data, pvmKey);
  if (pvmData == undefined)
    return;
  for (let pvm of pvmData) {
    if ('P' === pvm.key && useOldP(compareDt, newDate)) {
      pvm.label = 'Onsite Property Data Collection';
      pvm.key = 'oldP';
    }
  }
}


/**
 * Returns max of two values regardless of what type they are.
 * 
 * Precedence when types differ and are not convertable:
 *
 * Number
 * Boolean
 * Date
 * String
 * null
 * undefined
 *
 */
export function max(x, y) {

  if (x === y) {
    return x;
  }

  const xt = typeof x;
  const yt = typeof y;

  if (Array.isArray(x) && (y === undefined || yt === 'function')) {
    return maxArray(x, y);
  }

  if (x === undefined || x === null) {
    return y;
  }

  if (y === undefined || y === null) {
    return x;
  }

  let nMax = Math.max(x, y);
  if (!isNaN(nMax)) {
    return nMax;
  }

  if (xt === 'boolean') {
    return yt === 'boolean' ? (x || y) : x;
  }

  if (yt === 'boolean') {
    return y; // we know x is not boolean, so y is 'greater' according to above rules
  }

  if (xt === 'string' && yt === 'string') {
    return xt > yt ? x : y;
  }

  let xd = isDate(x) ? x : new Date(x);
  let yd = isDate(y) ? y : new Date(y);
  let xTime = xd.getTime();
  let yTime = xd.getTime();
  let xIsDate = !isNaN(xTime);
  let yIsDate = !isNaN(yTime);
  
  if (xIsDate || yIsDate) {
    if (xIsDate && yIsDate) {
      return xTime > yTime ? xd : yd;
    }  

    return  xIsDate ? xd : yd;
  }

  // last option, compare as strings

  let xs = x.toString();
  let ys = y.toString();

  return xs > ys ? x : y; 
}

export function maxArray(array, selector) {
  if (!Array.isArray(array) || array.length === 0) {
    return null;
  }

  selector = toSelectorFn(selector);

  return array.reduce(
    (pv, el, i) => {
      let cv = selector(el);
      return cv > pv ? cv : pv;
    },
    selector(array[0]));
}

export function maxBy(array, bySelector, isDate) {
  if (!Array.isArray(array) || array.length === 0) {
    return null;
  }

  bySelector = toSelectorFn(bySelector);
  let maxElem = array[0];
  let maxBy = bySelector(maxElem);
  if(isDate){
    maxBy = Date.parse(maxBy);
  }
  const len = array.length;


  for(let i=1; i<len; i++) {
    const curElem = array[i];
    let curBy = bySelector(curElem);
    if(isDate){
      curBy = Date.parse(curBy);
    }
    if (max(maxBy, curBy) === curBy) {
      maxBy = curBy;
      maxElem = curElem;
    }
  }

  return maxElem;
}

export function maxKey(obj) {
  if (obj == null) {
    return null;
  }

  let keys = Object.keys(obj);
  return maxArray(keys);
}

export function nativeInputValid(name) {
  const { validity = {} } = document.querySelector(`input[name="${name}"]`) || {};
  return validity.valid !== false;
}

/*
 * Returns function that negates the result of the passed in function, when called
 */
export function not(fn) {
  
  let oldValue;

  if (typeof fn !== 'function') {
    oldValue = fn;
    fn = () => oldValue;
  }
  return function notImpl() {
    return !fn.apply(this, arguments);
  }
}

export function orDefault(fn, defaultValue) {
  try {
    const result = fn();
    return result === undefined ? defaultValue : result;
  } catch (ignore) {
    return defaultValue;
  }
}

export function insertInCopy(array, element, idx) {
  if (!array) {
    return [element];
  }

  if (idx === 0) {
    return [element].concat(array.slice(1));
  } else if (idx > 0) {
    return [].concat(array.slice(0, idx), element, array.slice(idx+1));
  } else {
    return array.concat(element);
  }
}

export function removeInCopy(array, idx) {
  if (idx === -1) {
    return array;
  }

  return [].concat(array.slice(0, idx), array.slice(idx+1));
}

export function replaceOrAppendPathPart(oldPart, newPart) {
  const location = window.location;
  let path = location.pathname;

  if (oldPart == undefined) {
    path += '/' + newPart;
  } else {
    let paths = path.split('/');

    if (newPart) {
      paths[paths.length-1] = newPart;
    } else {
      paths.length = paths.length - 1;
    }

    path = paths.join('/');
  }

  return path + location.search + location.hash;
}

/**
 * If the source matches the test, return the result parameter, otherwise return source.
 *
 * replaceValueIfEquals(x, 1, 'One')
 *
 * is equivalen to
 *
 * x == 1 ? 'One' : x
 *
 * which is useful when 'x' is itself a complex expression or method result so it doesn't
 * have to be repeated or stashed away.
 */
export function replaceValueIfEquals(sourceValue, testAgainStValue, resultIfEquals) {
  return sourceValue == testAgainStValue ? resultIfEquals : sourceValue;
}

export function setInCopy(object, key, value) {
  const dotIdx = key.indexOf('.');

  if (dotIdx === -1) {
    return {
      ...object,
      [key]: value
    };
  }

  const currKey = key.substring(0, dotIdx);
  const remainingKey = key.substring(dotIdx+1);
  return {
    ...object,
    [currKey]: setInCopy(object[currKey], remainingKey, value)
  };
}

export function slug(text) {
  return typeof text !== 'string'
    ? ''
    : text.replace(/[^\w\d]+/g, '-').toLowerCase();
}

export function slugAllTitles(array) {
  if (!Array.isArray(array)) {
    return;
  }
  array.forEach(i => i.slug = slug(i.title));
}

export function sortBy(array, selector, order) {

  if (!Array.isArray(array) || array.length === 0) {
    return array;
  }

  if (typeof selector === 'string') {
    let fieldName = selector;
    selector = i => i[fieldName];
  }

  if (typeof selector !== 'function') {
    console.log('Cannot sort by non-string non-function selector.', {array, selector});
    if (isLocalhost()) {
      throw new Error('Cannot sort by non-string non-function selector. See console for more info.');
    } 
    return array;
  }


  try {
    array.sort(order === SORT_DESCENDING ? sortByDesc : sortByAsc);
  } catch (error) {
    console.log('Error sorting array by selector.', {array, selector, error});
    if (isLocalhost()) {
      throw new Error('Error sorting array by selector. See console for more info.');
    } 
  }

  return array;

  function sortByDesc(x, y) {
    return - sortByAsc(x, y);
  }

  function sortByAsc(x, y) {
    if (x == undefined || y == undefined) {
      if (x === y) {
        return 0;
      }
      return x === undefined ? -1 : 1;
    }

    let xv = String(select(x)); //force to be string
    let yv = String(select(y)); //force to be string

    //use default locale by passing in undefined
    //set numeric to true to work with alphanumeric sorting
    return xv.localeCompare(yv, undefined, { numeric: true, sensitivity: 'base' });
  }

  function select(i) {
    try {
      return selector(i);
    } catch(e) {
      console.log('Error applying selector to value.', { value: i });
      return null;
    }
  }
}

export function sortDesc(array) {
  return sortBy(array, x => x, SORT_DESCENDING);
}

export function sortAsc(array) {
  return sortBy(array, x=> x, SORT_ASCENDING);
}

export function stackTrace() {
  try {
    this.blah();
  } catch(e) {
    return e.stack;
  }
}

export function sortDates(array) {
  if (!Array.isArray(array)) {
    return array;
  }

  return array.sort(arrayDateSorter);

  function arrayDateSorter(x, y) {
    let dx = toDate(x);
    let dy = toDate(y);

    if (dx === null || dy === null) {
      return dx === null && dy === null 
        ? 0
        : dx === null
          ? 1 
          : -1;
    }

    let tx = dx.getTime();
    let ty = dy.getTime();

    return tx < ty ? -1 : tx === ty ? 0 : 1;
  }
}


const booleanKnownValues = {
  'Yes': true,
  'YES': true,
  'yes': true,
  'True': true,
  'TRUE': true,
  'true': true,
  'No': false,
  'NO': false,
  'no': false,
  'False': false,
  'FALSE': false,
  'false': false,
  '0': false,
  'NOT_AVAILABLE': false,
  'NOT_APPLICABLE': false
};

export function toBoolean(value) {

  switch(typeof value) {

    case 'boolean':
      return value;

    case 'string':
      const known = booleanKnownValues[value];
      return known === undefined ? !!value : known;

  }

  return !!value;
}

export function toCharCodeLookup(characters) {
  if (typeof characters !== 'string') {
    console.log('Invalid value for char code lookup table: ' + characters);
    return {};
  }

  return toLookup(characters.split('').map(c => c.charCodeAt(0)));
}

const isoPattern = /^(\d\d\d\d)-(\d\d)(?:-(\d\d))?$/;
const mmyyyyPattern = /^(\d\d)-?((?:19|20|21)\d\d)$/;
const mmddyyyyPattern = /^(\d\d)-(\d\d)-((?:19|20|21)\d\d)$/;

export function toDate(date) {
  if (date == undefined || date == '' || date == '-') {
    return null;
  }

  if (typeof date.getTime === 'function') {
    return date;
  }

  if (typeof date === 'string') {
    // if this is ISO date only without time, parse it ourselves because
    // JS will assume it's a UTC time and may be off by one depending
    // on time of day and current timezone.

    let isoParts = isoPattern.exec(date);
    if (isoParts != null) {
      return new Date(isoParts[1], parseInt(isoParts[2])-1, isoParts[3] || 1);
    }

    let mmyyyyParts = mmyyyyPattern.exec(date);
    if (mmyyyyParts) {
      return new Date(mmyyyyParts[2], parseInt(mmyyyyParts[1])-1);
    }

    let mmddyyyyParts = mmddyyyyPattern.exec(date);
    if (mmddyyyyParts) {
      return new Date(mmddyyyyParts[3],  parseInt(mmddyyyyParts[1]) - 1, mmddyyyyParts[2]);
    }

    let test = new Date(date);
    if (!isNaN(test.getTime())) {
      return test;
    }
  }

  console.log('Unsupported date format found:' + date);    
  return null;
}

export function toLookup(array, selector) {
  
  if (array == null) {
    return {};
  }

  selector = toSelectorFn(selector);

  if (typeof selector !== 'function') {
    selector = a => a;
  }

  let lookup = {};
  array.forEach(val => lookup[selector(val)] = val);

  return lookup;
}

export function toSelectorFn(selector) {
  const selectorTyp = typeof selector;

  switch(selectorTyp) {

    case 'string':
    case 'number':
      let fieldName = selector;
      return i => i[fieldName];

    case 'function':
      return selector;

    default: 
      return i => i;

  }
}

export function trim(value) {
  if (typeof value === 'string') {
    return value.trim();
  }

  return value;
}

export function uniqueSimple(array) {
  if (!Array.isArray(array) || array.length === 0) {
    return array;
  }

  let u = [];
  let found = {};

  array.forEach(value => {
    if (found[value] === undefined) {
      u.push(value);
      found[value] = true;
    }
  });

  return u;
}

export function multilineText(value) {
  const splitValues = value.split(/(\r\n|\n|\r)/);
  return <div>{splitValues.map((value, idx) => <p key={idx}>{value}</p>)}</div>;
}

export function sortSecondary(array, selector, order) {

  if (!Array.isArray(array) || array.length === 0) {
    return array;
  }

  if (typeof selector === 'string') {
    let fieldName = selector;
    selector = i => i[fieldName];
  }

  if (typeof selector !== 'function') {
    console.log('Cannot sort by non-string non-function selector.', {array, selector});
    if (isLocalhost()) {
      throw new Error('Cannot sort by non-string non-function selector. See console for more info.');
    } 
    return array;
  }


  try {
    array.sort(selector);
  } catch (error) {
    console.log('Error sorting array by selector.', {array, selector, error});
    if (isLocalhost()) {
      throw new Error('Error sorting array by selector. See console for more info.');
    } 
  }

  return array;
}

export function getFirstIndexOf(ipString, searchTerm){
  return ipString.indexOf(searchTerm);
}

export function getNextIndexOf(ipString, searchTerm, beginingIndex){
  return ipString.indexOf(searchTerm, beginingIndex);
}

export function getSubStringFromBegining(ipString, endIndex){
  return ipString.substring(0, endIndex);
}

export function getSubString(ipString, beginingIndex, endIndex){
  return ipString.substring(beginingIndex, endIndex);
}
