import React, { Component } from 'react';
import classNames from 'classnames';

import DropdownList from 'common/DropdownList';

export default class SplitButton extends Component {
  state = {
    optionsVisible: false
  };

  toggleOptions = (e) => {
    e.stopPropagation();

    this.setState({
      optionsVisible: !this.state.optionsVisible
    });
  };

  onDocumentClick = (e) => {
    if (this.state.optionsVisible) {
      this.setState({
        optionsVisible: false
      });
    }
  };

  componentDidMount() {
    if (document.getElementById('app')) {
        document.getElementById('app').addEventListener('click', this.onDocumentClick);
    }
  }

  UNSAFE_componentWillUnmount() {
    if (document.getElementById('app')) {
      document.getElementById('app').removeEventListener('click', this.onDocumentClick);
    }
  }

  render() {
    const { className, buttonClassName, label, options, onClick, iconCls, hidden, buttonDisabled, disabled, onItemSelect } = this.props;
    const { optionsVisible } = this.state;

    const classes = classNames('split-button', className);
    const buttonClasses = classNames({toggled: optionsVisible}, buttonClassName);

    const toggleIcon = <i className={iconCls || 'fa fa-angle-down'}/>;

    return (
      <div className={classes} hidden={hidden || false}>
        <button className={buttonClasses} disabled={buttonDisabled || disabled || false} onClickCapture={onClick}>{label}</button>
        <button className={buttonClasses} disabled={disabled || false} onClickCapture={this.toggleOptions}>{toggleIcon}</button>
        <DropdownList visible={optionsVisible} options={options} onItemSelect={onItemSelect} />
      </div>
    );
  }
};