import React, { Component } from 'react';

import { emailValidator } from 'common/form/validations';

import BaseFormComponent from 'common/form/BaseFormComponent';
import { eventHandler } from 'common/util/events';
import { multilineText } from 'common/util/common-helpers';

import { PersonalInfo, CoreInfo, EmailInfo } from 'www/components/account/PersonalInfo';
import { PasswordChangeForm } from 'www/components/account/PasswordChangeForm';
import Recaptcha  from 'www/util/Recaptcha';

import { TermsForm } from 'www/components/login/AcceptTermsForm';

const initialState = {
  termsAccepted: false,
  termsVisible: false,
  termsRead: false,
  captchaVerified: false,
};

export default class RegisterForm extends BaseFormComponent {

  constructor(props, context) {
    super(props, context);

    this.state = initialState;
  }

  onTermAcceptanceToggle = () => {
    this.setState({termsAccepted: !this.state.termsAccepted});
  };

  onTermsClick = (tcURL) => {
    this.setState({termsVisible: true, termsRead: true});
    //window.open(tcURL,'_blank');
  };

   onTermsCancel = () => {
    this.setState({termsAccepted: false,termsVisible: false});
  };

   onTermsClose = () => {
    this.setState({termsVisible: false});
  };

  onTermsAgree = () => {
    this.setState({termsAccepted: true, termsVisible: false});
  };

   expiredDiscCaptchaCallback= (response) => {
      this.setState({captchaVerified:false, captchaResponse:''});
   };
   verifyDiscCaptchaCallback =  (response) => {
       this.setState({captchaVerified:true, captchaResponse:response});
   };


  render() {
    const { onClose, onRegister, email, isFetching, settings } = this.props;
    const { termsAccepted, termsVisible, termsRead, isInvalid, captchaVerified, captchaResponse } = this.state;

    const message = this.props.message || this.state.message;
    return (
      <div className="modal register-form">
        <div className="close-button" onClick={onClose}><i className="fa fa-close"/></div>
        <div className="title">Creating a new account.</div>
        <div className="description">Please fill the information below to create a new account.
        All fields are required. Enter NA for state and postal code if not applicable.
        </div>
        <div className="form">
            <EmailInfo getFormElement={this.getFormElement} />
            <CoreInfo getFormElement={this.getFormElement} />
          <div className="register-password-fields">
            <PasswordChangeForm getFormElement={this.getFormElement} />
          </div>
          <div className="terms-conditions-confirm">
            <input type="checkbox" id="login-accept-terms" name="terms" checked={termsAccepted} onChange={this.onTermAcceptanceToggle} />
            <label className="terms-checkbox-label">I agree to the <a onClick={() => this.onTermsClick(settings.termsAndConditions)}>terms and conditions</a></label>
            <button onClick={this.onSubmit(onRegister, {email, captchaResponse})} disabled={isInvalid || !termsAccepted || !captchaVerified}>
              {isFetching ? (<i className="fa fa-spinner fa-spin"/>) : 'Register'}
            </button>
          </div>

          <div className="error-message">{message}</div>
            <Recaptcha  verifyCallback={this.verifyDiscCaptchaCallback} expiredCallback = {this.expiredDiscCaptchaCallback} sitekey={recaptchaTrackId} elementID={"disclosuresCaptcha"} render={"explicit"} hl={"en"}   />
        </div>

        {termsVisible && <TermsForm termsAndConditions={settings.termsAndConditions} tcDate={settings.tcDate} onCancel={this.onTermsCancel} onAccept={this.onTermsAgree} onClose={this.onTermsClose} termsModif={false} isRegister={true}/>}
      </div>
    );
  }
}

