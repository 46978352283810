import React from 'react'
import { getOrganizationFromLocation } from './util/common-helpers'

export default function Announcement ({ title, url, description, link, creationDate }) {

  if (getOrganizationFromLocation() === 'fannie') {
    const heading = <a href={link}>{title}</a>
    return (
      <li>
        <li>
          {heading}
        </li>
        <ul style={{listStyle: 'none'}}>
          <li>{creationDate}
          </li>
        </ul>
      </li>
    )
  } else {
    if (title) {
      const heading = url ? <a href={url}>{title}</a> : <h3>{title}</h3>
      return <li>{heading}
        <div>{description}</div>
      </li>
    } else {
      return <li>{description}</li>
    }
  }
}