export function eventHandler(handler, ...args) {
  if (!handler) {
    console.warn('No handler provided to eventHandler wrapper. This may be benign but could generate console errors.', arguments);
  }

  return (e) => {
    handler(...args);
  };
}

export function eventHandlerWithPrevent(handler, ...args) {
  if (!handler) {
    console.warn('No handler provided to eventHandlerWithPrevent wrapper. This may be benign but could generate console errors.', arguments);
  }

  return (e) => {
    e.preventDefault();
    handler && handler(...args);
  };
}

export function eventHandlerWithStopPropagation(handler, ...args) {
  if (!handler) {
    console.warn('No handler provided to eventHandlerWithStopPropagation wrapper. This may be benign but could generate console errors.', arguments);
  }

  return (e) => {
    e.stopPropagation();
    handler && handler(...args);
  };
}