import React, { Component } from 'react';

import ButtonDropdown from 'common/button/ButtonDropdown';
import SplitButton from 'common/button/SplitButton';
import { DEFAULT_PORTFOLIO } from 'www/actions/accountActions';
import AddToPortfolioPopup from 'www/components/account/AddToPortfolioPopup';
import { eventHandler } from 'common/util/events';
import { isDealInPortfolioGroup } from 'www/util/portfolio';


export default class AddToPortfolioButtonPopup extends Component {

  state = {
    showAddToPortfolioDialog: false
  };

  showAddToNewPortfolio = () => {
    this.setState({
      showAddToPortfolioDialog: true
    });
  };

  hideAddToNewPortfolio = () => {
    this.setState({
      showAddToPortfolioDialog: false
    });
  };

  render() {

    if (!this.state.showAddToPortfolioDialog) {
      return this.renderButton();
    }

    return (
      <div>
        {this.renderPopup()}
        {this.renderButton()}
      </div>
      );
    
  }

  renderButton() {

    const {login, account, cusip, classCusips = [], addToPortfolio, removeFromPortfolio} = this.props;
    const portfolio = account.portfolio || [];
    const adds = [], removes = [];
    let trackingSecurity = false;

    adds.push({
      text: 'Add to New Portfolio',
      action: () => this.showAddToNewPortfolio()
    });

    portfolio.forEach(({title, portfolio}) => {
      const trackingDeal = classCusips && isDealInPortfolioGroup(portfolio, classCusips);

      if (trackingDeal || (portfolio && portfolio.indexOf(cusip) !== -1)) {
        removes.push({
          text: `Remove from ${title}`,
          action: eventHandler(removeFromPortfolio, cusip, classCusips, title)
        });

        trackingSecurity = true;

      } else if (title !== DEFAULT_PORTFOLIO) {
        adds.push({
          text: `Add to ${title}`,
          action: eventHandler(addToPortfolio, cusip, classCusips, title)
        });
      }
    });

    const props = {
      buttonClassName: 'btn-secondary',
      disabled: !login.loggedIn,
      onClick: this.showAddToNewPortfolio,
      options: combineOptions(adds, removes)
    };

    if (login.loggedIn && trackingSecurity) {
      props.label = <span><i className="fa fa-star "/> Modify Portfolio</span>;
      return <ButtonDropdown {...props} />;
    } else {
      props.label = 'Add To Portfolio';
      if (props.options.length > 0) {
        return <SplitButton {...props} />;
      } else {
        return <button disabled={!login.loggedIn} className={props.buttonClassName} onClick={this.showAddToNewPortfolio}>{props.label}</button>
      }
    }
  }

  renderPopup() {
    
    const { addResultsToPortfolio, cusip, classCusips = [] } = this.props;
    const portfolio = this.props.account.portfolio;
    const {  params } = this.props.match;
    const securities = cusip ? [{cusip}] : classCusips;
    const account = {}; // don't actually pass account along, always do add new

    return (
        <AddToPortfolioPopup securities={securities}
                               account={account}
                               params={params}
                               addResultsToPortfolio={addResultsToPortfolio}
                               onClose={this.hideAddToNewPortfolio}
                               portfolio={portfolio} />
      );
  }
}

const combineOptions = (adds, removes) => {
  if (adds.length > 0 && removes.length > 0) {
    return adds.concat('-', removes);
  } else {
    return adds.concat(removes);
  }
};
