import React, { Component } from 'react';
import classNames from 'classnames';

import ButtonDropdown from 'common/button/ButtonDropdown';
import HiddenColumns from 'common/table/draggable/HiddenColumns';
import DraggableTable from 'common/table/draggable/Table';

import {
  commaThousands
} from 'common/util/common-formatters';

export default function Customizable() {
  let setState, props;

  let state = {
    expanded: false,
    customizing: false,
    customizingTitle: false,
    defaultColumns: false,
    customizingViewChanged: false,
    isCopyingView: false,
    currentView: 'defaultView',
    customizingTitleName: 'Custom View'
  };

  const getDefaultPrefs = () => {
    const tableConfig = props.config.table;
    return (tableConfig.origColumns || tableConfig.columns).filter((c) => c.visible !== false).map((c) => c.key);
  };

  const hasPrefs = (key) => !!(props.preferences && props.preferences[key]);  
  const getPrefs = (key) => {
    let results;

    if (props.preferences && props.preferences[key]) {
      const index =  props.preferences[key].findIndex( o => o[state.currentView] );

      if (index > -1) {
        results = props.preferences[key][index][state.currentView];
      }
    }

    return [...(results || getDefaultPrefs())];
  };

  const getExportLink = () => {
    const { key, columns } = props.config.table;
    const { pageIdx, pageSize, sort } = props;
    const prefs = state.defaultColumns ? getDefaultPrefs() : getPrefs(key);
    let base = `${props.exportLink}&page=${pageIdx}&max_results=${pageSize}`;
    
    if (sort) {
      let { field, direction } = sort;
      
      if (field && direction) {
        let sortAsc = direction === 'asc' ? true : false;
        base = `${base}&sortField=${field}&sortAsc=${sortAsc}`;
      }
      
    }

    if (props.exportLink && columns.length !== prefs.length && !props.overridingColumns) {
      return `${base}&columns=${prefs.join(',')}`;
    }

    return base;
  };

  const addColumn = (colKey, index) => {
    const { key } = props.config.table;
    const { currentView } = state;
    const prefs = getPrefs(key);
    const existingIndex = prefs.indexOf(colKey);
    state = setState({ customizingViewChanged: true, isCopyingView: false });

    if (existingIndex > -1) {
      prefs.splice(existingIndex, 1);
      prefs.splice(index < existingIndex ? index : index - 1, 0, colKey);
    } else {
      prefs.splice(index, 0, colKey);
    }

    props.updatePreferences(key, prefs, currentView);
  };

  const removeColumn = (colKey) => {
    const { key } = props.config.table;
    const { currentView } = state;
    const prefs = getPrefs(key);
    const existingIndex = prefs.indexOf(colKey);
    state = setState({ customizingViewChanged: true, isCopyingView: false });

    if (existingIndex !== -1) {
      prefs.splice(existingIndex, 1);
      props.updatePreferences(key, prefs, currentView);
    }
  };

  const toggleExpanded = () => {
    state = setState({expanded: !state.expanded});
  };

  const toggleDefaultColumns = () => {
    state = setState({ currentView: 'defaultView', defaultColumns: true, customizingTitleName: 'Custom View'});
  };

  const toggleCustomView = (viewName) => {
    state = setState({ currentView: viewName, defaultColumns: false });
  };
  
  const customize = () => {
    let { loggedIn, onLoginClick } = props;
    //Note: I think it's helpful to forcibly switch users back to custom view when customizing.
    //To change this, remove the defaultColumns switch here and add a smarter check when applying prefs.

    let customizingDefaultView = { customizing: true, defaultColumns: false, currentView: 'customDefaultTempView', customizingViewChanged: false, isCopyingView: false };
    let customizingCurrentView = { customizing: true, defaultColumns: false, customizingTitleName: state.currentView, customizingViewChanged: false, isCopyingView: false };

    if (!loggedIn) {
      onLoginClick('You must be logged in to customize', () => {
        state = state.currentView === 'defaultView' ? setState(customizingDefaultView) : setState(customizingCurrentView);
      });
    } else {
      state = state.currentView === 'defaultView' ? setState(customizingDefaultView) : setState(customizingCurrentView);
    }
  };

  const startCustomizingTitle = () => {
    state = setState({ customizingTitle: !state.customizingTitle, customizingViewChanged: true, isCopyingView: false });
  };
  
  const promptForLogin = () => {
    let { loggedIn, onLoginClick } = props;
    if (!loggedIn) {
      onLoginClick('You must be logged in to export', () => {});
    } 
  };

  const cancel = () => {
    let viewName, titleName;
    const { config } = props;
    const { currentView, customizingTitleName } = state;

    const key = config.table.key;
    const tempViewName = 'customDefaultTempView';

    if (currentView === tempViewName) {
      viewName = 'defaultView';
      titleName = 'Custom View';
    } else {
      viewName = currentView;
      titleName = customizingTitleName;
    }

    if (props.cancelBtnClick) {
      props.cancelBtnClick();
    }

    props.filterPreferences(key, tempViewName);
    const resetView = { currentView: viewName, customizing: false, customizingTitle: false, customizingTitleName: titleName, customizingCurrentView: false };

    state = setState(resetView);
  };

  const updateCustomizingTitle = (e) =>  {
    state = setState({
      customizingTitleName: e.target.value
    });
  };

  const deleteCustomization = () => {
    const { currentView } = state;
    const { config, showDeleteCustomView, hideDeleteCustomView } = props;
    const key = config.table.key;

    showDeleteCustomView('This will delete your saved view. Do you wish to continue?', () => {
      props.removePreferences(key, currentView);
      state = setState({customizing: false, customizingTitle: false, customizingTitleName: 'Custom View', currentView: 'defaultView', customizingCurrentView: false });
      hideDeleteCustomView();
    }, () => {
      hideDeleteCustomView();
    });

  };

  const copy = () => {
    const maxStrLength = 50;
    let viewName, copyCount = 1, viewIndex = -1, fields = getDefaultPrefs();

    const { customizingTitleName, currentView } = state;
    const { config, preferences, showCopyCustomView, hideCopyCustomView } = props;
    const key = config.table.key;
    const defaultTempViewName = 'customDefaultTempView';
    viewName = currentView === defaultTempViewName ? 'Copy of Default View': `Copy of ${customizingTitleName}`;
    
    if ( viewName.length > maxStrLength ) {
      viewName = 'Choose a Shorter Title';
    }
    
    // find duplicate keys
    if (preferences[key]) {
      viewIndex = preferences[key].findIndex(o => o[viewName] );
      while ( viewIndex > -1 ) {
        viewName = `${viewName}(${copyCount})`; 
        copyCount += 1;
        viewIndex = preferences[key].findIndex(o => o[viewName] );
      }
    }

    showCopyCustomView(`A copy of your current view will be created with the name "${viewName}". Note, this name was specifically chosen to minimize naming conflicts.`, () => {
      props.copyPreferences(key, viewName, currentView, fields);
      state = setState({ currentView: viewName, customizing: true, customizingTitle: false, customizingTitleName: viewName, isCopyingView: true });
      hideCopyCustomView();
    }, () => {
      hideCopyCustomView();
    });

  };

  const save = () => {
    const fields = getDefaultPrefs();

    const { customizingTitleName, currentView } = state;
    const { config, preferences } = props;
    const key = config.table.key;
    const updatedView = customizingTitleName;
   
    props.savePreferences(key, currentView, updatedView, fields);
    state = setState({currentView: updatedView, customizing: false, customizingTitle: false, customizingTitleName: 'Custom View', customizingCurrentView: false });
  };

  const saveAs = () => {
    let viewName, copyCount = 1, viewIndex = -1;

    const { customizingTitleName } = state;
    const { config, preferences, showSaveAsCustomView, hideSaveAsCustomView } = props;
    const key = config.table.key;
    viewName = customizingTitleName;

    const prefs = getPrefs(key);

    // Check for duplicates
    if (preferences[key]) {
      viewIndex = preferences[key].findIndex(o => o[viewName] );
      while ( viewIndex > -1 ) {
        viewName = `${viewName}(${copyCount})`; 
        copyCount += 1;
        viewIndex = preferences[key].findIndex(o => o[viewName] );
      }
    }

    showSaveAsCustomView(`You have chosen to modify your previous view and wish to save a new version as "${viewName}". This name may reflect any naming conflicts.`, () => {
      props.savePreferences(key, viewName, viewName, prefs);
      state = setState({currentView: viewName, customizing: false, customizingTitle: false, customizingTitleName: 'Custom View', customizingCurrentView: false });
      hideSaveAsCustomView();
    }, () => {
      hideSaveAsCustomView();
    });
  };

  const getPreferredColumns = (columns, prefs) => {
    // Note: this is complicated. We're making theoretical sacrifices here as there's only one use case for forcing
    // columns to be visible: checkbox columns for "editable" grids. These need to go at the beginning so we need to
    // put visibleColumns before preferredColumns before hiddenColumns in our output. Obviously if forceVisible needs
    // to imply some other order, we need a more robust solution.
    const visibleColumns = [], preferredColumns = [], hiddenColumns = [];

    columns.forEach((column, idx) => {
      // Columns are visible because they are preferred or forced by other features.
      // But other features can't force visible columns during customization.
      const prefsIndex = prefs.indexOf(column.key);
      const visible = (column.forceVisible && !state.customizing) || prefsIndex !== -1;
      const columnWithVisibility = {...column, visible};

      if (prefsIndex === -1) {
        if (visible) {
          visibleColumns.push(columnWithVisibility);
        } else {
          hiddenColumns.push(columnWithVisibility);
        }
      } else {
        preferredColumns[prefsIndex] = columnWithVisibility;
      }
    });

    return visibleColumns.concat(preferredColumns, hiddenColumns);
  };

  const applyColumnPrefs = (tableConfig) => {
    const { key } = tableConfig;

    // If no prefs are provided or the user has requested to use default columns
    if (!hasPrefs(key) || state.defaultColumns) {
      tableConfig.origColumns = tableConfig.origColumns || tableConfig.columns;
      tableConfig.columns = tableConfig.origColumns || tableConfig.columns;
      return;
    }

    if (tableConfig.origColumns) {
      tableConfig.columns = tableConfig.origColumns;
    }

    const { columns } = tableConfig;
    const prefs = getPrefs(key);
    const preferredColumns = getPreferredColumns(columns, prefs);

    tableConfig.origColumns = tableConfig.origColumns || tableConfig.columns;
    tableConfig.columns = preferredColumns;
  };

  const customViewArray = (key, preferences) => {
    let views = [];

    if (key && preferences && preferences[key]) {
      views = preferences[key].filter( o => !o['default'] ).map( o => {
        let viewName = Object.keys(o)[0];

        return {
          text: viewName, action: toggleCustomView.bind(null, viewName)
        };
      });
    }

    return views;
  };

  const getExportAllOption = () => {
    const {loggedIn, anonymousExport, exportLink, exportName} = props;

    if (loggedIn || anonymousExport) {
      return  { text: 'Export All', url: exportLink, disabled: !exportLink, download: exportName || false };
    }
    return { text: 'Export All', action : promptForLogin, disabled: !exportLink };
  };


  return {
    key: 'customizable',
    attach: function(featureSetState) {
      setState = featureSetState;
      return state;
    },
    onRender: function(newProps, config) {
      const maxTextLength = 50;
      let { buttons = [], classes, tableConfig, header = '', footer = '', customizingTitle = '', tableType } = config;
      const { preferences, data } = newProps;
      const { customizingTitleName, currentView, customizingViewChanged, isCopyingView } = state;
      const defaultTempViewName = 'customDefaultTempView';

      let title = config.title;
      props = newProps;

      applyColumnPrefs(tableConfig);
      classes.push({
        expanded: state.expanded,
        customizing: state.customizing
      });

      if (state.customizing) {
        let customizingTitleWHyphen = customizingTitle.length > 0 ? `${customizingTitle} -` : customizingTitle;
        title = state.customizingTitle
        ? <div key="customize-title">{customizingTitleWHyphen} <input  type="text" maxLength={maxTextLength} placeholder={customizingTitleName} value={customizingTitleName} onChange={updateCustomizingTitle}/></div>
        : <div key="customize-title">{customizingTitleWHyphen} {customizingTitleName} <a onClick={startCustomizingTitle}><i className="fa fa-pencil" /></a></div>;

        const isSaveButtonHidden = getPrefs(tableConfig.key).length === 0;
        const isDeleteButtonHidden = currentView === defaultTempViewName;
        const isCopyButtonHidden = customizingViewChanged;
        // const copyDisabled = true;
        // waiting for ideas on copying custom view.

        buttons = [
          <button key="cancel" onClick={cancel} className="btn-secondary">Cancel</button>,
          <button key="delete" onClick={deleteCustomization} className={isDeleteButtonHidden ? 'hidden' : 'btn-danger'}>Delete</button>,
          <button key="copy" onClick={copy} className={isCopyButtonHidden ? 'hidden' : 'btn-secondary'}>Copy</button>,
          <button key="save" onClick={save} className={isSaveButtonHidden || isCopyingView ? 'hidden': 'btn-primary'}>Save</button>,
          <button key="saveas" onClick={saveAs} className={(customizingViewChanged && currentView !== defaultTempViewName) ? 'btn-primary' : 'hidden'}>Save As</button>
        ];

        tableType = DraggableTable;
        tableConfig.dropFunction = addColumn;

        header = <div className="instruction"><strong>Configured Columns</strong> Drag columns to reorder or remove them from the table.</div>;
        footer = (
          <div className="field-area">
            <div className="instruction"><strong>Available Columns</strong> Drag columns up to add them to the table.</div>

            <HiddenColumns dropFunction={removeColumn} columns={tableConfig.columns} tableData={data} />
          </div>
        );

      } else {
        if (tableConfig.customizable !== false) {
          let customizableTitle = '';
          customizableTitle = props && props.count && props.count >= 0 ? customizableTitle + commaThousands(props.count) : '';
          customizableTitle = props && props.totalCount && props.totalCount >= 0 ? customizableTitle + ' out of ' : customizableTitle + ' Search Results';
          const customizableTitleLink = props && props.totalCount && props.totalCount >= 0 ?
              <a href={'#'} onClick={(e) => props.onTotalCountClick(e)}>{commaThousands(props.totalCount)}</a> : '';
          const customizableTitle2 = props && props.totalCount && props.totalCount >= 0 ? ' Search Results' : '';

          if (currentView === 'defaultView') {
            title = <div className="default-table-title">
              <div>{customizableTitle}{customizableTitleLink}{customizableTitle2} </div>
            </div>;
          } else {
            title = <div className="custom-table-title">
              <div>{currentView}</div>
              <div>{customizableTitle}{customizableTitleLink}{customizableTitle2}</div>
            </div>;
          }
        }

        const btnClasses = classNames('fa', state.expanded ? 'fa-compress': 'fa-expand');
        let options = [], exportAllOption, customViews, effectiveExportLink = '';
        let { loggedIn } = props;
        
        // so far only Majors and MajorsPanel have customizable to false
        if (tableConfig.customizable === false) {
          if (loggedIn) {
            options = [
              { text: 'Export View', url: props.exportLink, disabled: !props.exportLink, download: props.exportName || false }
            ]
          } else {
            options = [
              { text: 'Export View', action: promptForLogin, disabled: !props.exportLink }
            ]
          }
        } else if (tableConfig.isDealOrL2CollateralTable) {
          exportAllOption = getExportAllOption();
        } else {
          effectiveExportLink = getExportLink()
          exportAllOption = getExportAllOption();

          customViews = customViewArray(tableConfig.key, preferences).sort((a, b) => {
            if (a.text < b.text) {
              return -1;
            }

            if (a.text > b.text) {
              return 1;
            }

            return 0;
          });

          options = [
            { text: 'Default View', action: toggleDefaultColumns },
              ...(customViews.length > 0 ? [...customViews, '-'] : customViews),
              { text: 'Customize...', action: customize }
          ];
        }

        if (exportAllOption && (props.exportAll || effectiveExportLink.includes('tax'))) {
          options.push(exportAllOption);
        }

        buttons = buttons.concat(
          <ButtonDropdown key="settings" buttonClassName="icon-button" iconCls="fa fa-cog" options={options} />,
          <button key="toggle-expanded" className="icon-button" onClick={toggleExpanded}><i className={btnClasses} /></button>
        );
      }

      return {buttons, classes, tableConfig, header, tableType, footer, title, customizing: state.customizing};
    }
  };
}

