import React, { Component } from 'react';

import ButtonDropdown from 'common/button/ButtonDropdown';
import { isEmpty, sortDates, toDate } from 'common/util/common-helpers';

import { documentDownload, subscribeToReportLink } from 'www/util/www-helpers';
import { getLabels } from 'www/util/labels';
import { getOrganizationFromLocation } from 'common/util/common-helpers';
const ALL_PLACEHOLDER = 'All';

export default class extends Component {

  state = {
    selectedYear: null,
    selectedQuarter: ALL_PLACEHOLDER
  };

  constructor(props){
    super(props);
    this.params = props.match.params;
    this.organization = getOrganizationFromLocation()
  }

  UNSAFE_componentWillMount() {
    const { getTaxDataFilesList } = this.props;

    getTaxDataFilesList();
  }

  onYearChange = ({year}) => {
    this.setState({selectedYear: year});
  };

  onQuarterChange = ({quarter}) => {
    this.setState({selectedQuarter: quarter});
  };

  render() {
    const { account, subscribeToReport, unsubscribeToReport } = this.props;
    const { taxDataFiles, isFetching } = this.props.account;
    let { organization, currSubAccount } = this.props.match.params;
    const { reportSubscriptions = {}, subscribingToReports = {} } = account;

    if (isFetching || taxDataFiles == null) {
      return <i key="loading" className="fa fa-spinner fa-pulse"/>;
    }

    if (currSubAccount == undefined) {
      currSubAccount = 'single_class';
    }

    let dataKey = getLabels("tax-sub-account-to-date")[currSubAccount];
    if (dataKey === undefined) {
      return <div className="warning">No tax key found for <span className="warning">{getLabels("tax-sub-account-label")[currSubAccount.toUpperCase()]}</span>.</div>      
    }

    let currTaxDataFiles = taxDataFiles[dataKey];
    const report = !currTaxDataFiles ? {} : {
      dataFiles: currTaxDataFiles,
      headingKey: currTaxDataFiles[0].documentCategory + '_QUARTERLY_TAX_FILE',
      isSubscribed: !!reportSubscriptions[currTaxDataFiles[0].documentCategory + '_QUARTERLY_TAX_FILE'],
      isSubscribing: !!subscribingToReports[currTaxDataFiles[0].documentCategory + '_QUARTERLY_TAX_FILE'],
      label: currTaxDataFiles[0].fileName,
    }
    if (isEmpty(currTaxDataFiles)) {
      return <div className="warning">No tax data found for <span className="warning">{getLabels("tax-sub-account-label")[currSubAccount.toUpperCase()]}</span>.</div>
    }

    let { selectedQuarter, selectedYear } = this.state;

    // need to know if we have multiple years of data to show year selector
    let uniqueYears = [];
    if (currTaxDataFiles) {
      let years = currTaxDataFiles.map(fil => fil.taxYear).filter(year => year !== null);

      if (years.length > 1) {
        years.sort();
        let prevYear = null;
        uniqueYears = years.filter(year => {
          const isNew = year !== prevYear;
          prevYear = year;
          return isNew;
        });

        if (uniqueYears.length >= 1) {
          if (selectedYear == null) {
            selectedYear = years[years.length - 1];
          }

          currTaxDataFiles = currTaxDataFiles
            .filter(fil => fil.taxYear === selectedYear)
            .filter(fil => selectedQuarter === ALL_PLACEHOLDER ? true : `Q${fil.taxQuarter}` === selectedQuarter);
        }
      }
    }

    const onYearChange = this.onYearChange;
    const onQuarterChange = this.onQuarterChange;
    const byYear = yearSelector({uniqueYears, selectedYear, onYearChange});
    const byQuarter = quarterSelector({selectedQuarter, onQuarterChange});
    const filterSelectors = <div className="year-selector-container"> { byYear } { byQuarter } </div>;

    return (
      <div className="tax-data-files">
        <div className="data-files-container">
          <div className="title"><span>{selectedYear} {getLabels("tax-sub-account-label")[currSubAccount.toUpperCase()]} Tax Data</span>
            {subscribeToReportLink(report, subscribeToReport, unsubscribeToReport)}
          </div>
          { filterSelectors }
          <div className="section-title">Quarterly Tax Data Files</div>
          <div className="section">
          { currTaxDataFiles.map(fil => {
            const documentLabel = getDocumentLabel(fil.fileName);
            const fileName = `${documentLabel}`;
            const props = {
              className: 'file data-file',
              key: fil.fileName
            };
            return documentDownload(`/api/doc/tax/${organization}/${fil.fileName}`, fileName, fil.fileName, 'Tax Documents', props);
          })}
          </div>
        </div>
      </div>
    );
  }
}

const yearSelector = ({uniqueYears, selectedYear, onYearChange}) => {
  if (uniqueYears.length < 2) {
    return null;
  }

  const yearOptions = uniqueYears.map(year => ({
                                   text: year,
                                   year,
                                   onClick: onYearChange,
                                   disabled: year === selectedYear
                                 }));
  return (
    <ButtonDropdown label={selectedYear} options={yearOptions} />
  );
};

const quarterSelector = ({selectedQuarter, onQuarterChange}) => {
  const quarterOptions = [ALL_PLACEHOLDER, 'Q1', 'Q2', 'Q3', 'Q4'].map(quarter => ({
    text: quarter,
    quarter,
    onClick: onQuarterChange,
    disabled: quarter === selectedQuarter
  }));

  return (
    <ButtonDropdown className="quarter-selector-container" options={quarterOptions}
                    label={selectedQuarter === ALL_PLACEHOLDER ? 'Quarter' : selectedQuarter} />
  );
};

const singleClassFactorFileRegex = new RegExp("SC.+\.fac");
const singleClassTypeFileRegex = new RegExp("SC.+\.typ");
const multiClassFactorFileRegex = new RegExp("MC.+\.fac");
const multiClassTypeFileRegex = new RegExp("MC.+\.typ");

function getDocumentLabel(fileName) {
  const taxLabels = getLabels("tax-document-label") || [];
  let labeled = undefined;
  if (singleClassFactorFileRegex.test(fileName)) {
    labeled = taxLabels["singleClassFactorFile"];
  } else if (singleClassTypeFileRegex.test(fileName)) {
    labeled = taxLabels["singleClassTypeFile"];
  } else if (multiClassFactorFileRegex.test(fileName)) {
    labeled = taxLabels["multiClassFactorFile"];
  } else if (multiClassTypeFileRegex.test(fileName)) {
    labeled = taxLabels["multiClassTypeFile"];
  }

  return labeled || fileName;
}
