import React from 'react';

import DefinitionOverlay from 'common/DefinitionOverlay';
import { eventHandlerWithPrevent } from 'common/util/events';
import { getLink, decimal, formatDateMmYyyy } from 'common/util/common-formatters';

import {
  getOrganization,
  getOrganizationFromLocation,
  intersperseInReactArray,
  isBlankOrNaN,
  stackTrace,
  toDate
} from 'common/util/common-helpers';


import {
  isOffPlatformRedirect,
  isOffPlatformSecurity,
  isGnmaCollateral,
  getDealUrl,
  getDetailsUrl,
  getOrgCode,
  documentDownload as documentDownloadHelper
} from 'www/util/www-helpers';

import { getLabels } from 'www/util/labels';

import typeDefinitions from 'resources/type-definitions';

import ENUMS from 'www/util/enumFormatters';
const { disclosureStateType } = ENUMS;

const yesNoMap = {
  "TRUE": "Yes",
  "YES": "Yes",
  "FALSE": "No",
  "NO": "No",
  "MIXED": "Mixed",
  "NOT_AVAILABLE": "Not Available",
  "NOT_APPLICABLE": "Not Applicable"
};

const fileIconMap = {
  'pdf': 'fa-file-pdf-o',
  'xls': 'fa-file-excel-o',
  'xlsx': 'fa-file-excel-o',
  'csv': 'fa-file-excel-o',
  'doc': 'fa-file-word-o',
  'docx': 'fa-file-word-o',
  'default': 'fa-file-o',
  'xml': 'fa-file-code-o',
  'txt': 'fa-file-text-o',
  'zip': 'fa-file-archive-o',

  '1': 'fa-file-text-o',
  '2': 'fa-file-pdf-o',
  '3': 'fa-file-excel-o',
  '4': 'fa-file-code-o',
  '5': 'fa-file-text-o',
  '6': 'fa-file-excel-o',
  '7': 'fa-file-excel-o',
  '8': 'fa-file-archive-o'
};

export function fileIcon(str) {

  if (typeof str !== 'string') {
    str = str === undefined ? '' : str.toString();
  }

  const dot = str.lastIndexOf('.');
  if (dot !== -1) {
    str = str.substring(dot + 1);
  }

  return <i className={`fa ${fileIconMap[str] || 'fa-file-o'}`}/>;
}


export function dealLink(dealId, column, {issuer, enableDealLink}) {
  if (!dealId) {
    return '';
  } else if (false === enableDealLink) {
    return dealId;
  }

  const url = getDealUrl(dealId, issuer);
  const org = getOrganization(issuer) || getOrganizationFromLocation();
  let handler;

  if (org !== getOrganizationFromLocation()) {
    handler = () => {window.open(url, org);};
  }

  return getLink({url, value: trustNumberFormatter(dealId), handler});
}

export function dealTypeOrSecurityType(secType, col, row) {
  return row.dealType || securityType(secType);
}

/**
 * Given a string of delimited terms, or an array of terms,
 * returns React elements to create spans for those terms with
 * definition overlays on mouseover/click to show the full name
 * and definition.
 */
export function definitionOverlay(values, typeKey) {

  if (typeKey === undefined || values === undefined) {
    return null;
  }

  let definitions = typeDefinitions[typeKey];

  if (definitions === undefined) {
    console.log('Unrecognized definition type: ' + typeKey);
    return null;
  }

  if (typeof values === 'string') {
    values = values.split(/[\/_,]+/);
  }

  if (!Array.isArray(values)) {
    console.log('Unsupported values for definitions: \n' + stackTrace(), values);
    return null;
  }

  return values.length === 0
      ? null
      : values.length === 1
          ? definitionOverlayOne(values[0])
          : intersperseInReactArray(values.map(definitionOverlayOne), key => <span key={key}>, </span>);

  function definitionOverlayOne(value) {
    let {category, description } = definitions[value] || {};

    return category === undefined
        ? null
        : <DefinitionOverlay key={value} name={category} definition={description}>{value}</DefinitionOverlay>;
  }
}

const disclosureStatesToNotDisplay = {
  'M': true,          // Matured
  'C': true,          // Collapsed
  'D': true,          // Dissolved
  '-': true,          // Default
  '': true,           // Being defensive
  'undefined': true,  // Being defensive
  'null': true        // Being defensive
};


export function disclosureStateFormatter(value) {
  return disclosureStatesToNotDisplay[value] ? null : disclosureStateType(value);
}

export function documentDownload(fileObj, column, row) {

  if (fileObj == undefined) {
    return null;
  }

  const id = fileObj.id;
  const filename = fileObj.name;
  const downloadFilename = fileObj.dName ? fileObj.dName : filename;
  const href = `/api/download/${getOrgCode()}/${id}/${downloadFilename}`;
  const preventDownload = row.loginRequired && !column.loggedIn;
  const denyDownloadClick = eventHandlerWithPrevent(column.onLoginClick, 'You must be a registered user to view this document.');
  const linkContent = <span>
                        {fileIcon(filename)}
    <span className="download-document-filename">{filename}</span>
                      </span>;

  return documentDownloadHelper(href, linkContent, filename, row.headingKey, {}, denyDownloadClick, preventDownload);
}

export function documentDownloads(links, column, row) {
  if (!links) {
    return;
  }

  const onClick = eventHandlerWithPrevent(column.onLoginClick, 'You must be a registered user to view this document.');
  const preventDownload = !column.loggedIn && row.loginRequired;

  return (
      <div className="document-links">{Object.keys(links).map((fileType) => {
        const link = links[fileType];
        const downloadAttr = link && link.substring(link.lastIndexOf('/')+1);
        return <a className="text-uppercase" href={link} onClick={preventDownload ? onClick : undefined} download={downloadAttr}>{fileType}</a>;
      })}</div>
  );
}

export function cusipLink(cusip, column, {issuer, platformInd, offPlatformRedirects, disclosureRedirectActiveIndicator, status}) {
  if (!cusip) {
    return '';
  }

  if (isGnmaCollateral({issuer}) || (isOffPlatformRedirect({platformInd, offPlatformRedirects, disclosureRedirectActiveIndicator})) || (isOffPlatformSecurity({offPlatformRedirects}) && issuer !== 'FNM' )) {
    return cusip;
  }


  const url = getDetailsUrl(cusip, issuer);
  const org = getOrganization(issuer);
  let handler;

  if (org != getOrganizationFromLocation()) {
    handler = () => {window.open(url, org);};
  }

  return getLink({url, value: cusip, handler});
}

export function factor(value) {
  return decimal(value, {precision: 8});
}

export function taxFactor(value) {
  return decimal(value, {precision: 8});
}

export function interestTypeOverlay(types) {
  return definitionOverlay(types, "interestTypes");
}

export function yesNoIndicator(yesNo) {
  return yesNoMap[yesNo] || yesNo;
}

export function mortgageTypeOverlay(types) {
  return definitionOverlay(types, "mortgageTypes");
}

export function notAvailableGenerator(naValue, formatter) {
  return function(value) {
    if (value === naValue) {
      return 'Not Available';
    }

    return formatter ? formatter(value) : value;
  };
}

export function formatNegativeAmortizationFactor(negativeAmortizationFactorValue, column, row) {
  const rowNegativeAmortizationIndicator = row.armFields.negativeAmortizationIndicator;

  if (rowNegativeAmortizationIndicator === 'NO') {
    return '-';
  }

  return decimal(negativeAmortizationFactorValue, {precision: 8});
};

export function rate(value) {
  return decimal(value, {precision: 3});
}

export function monthsToNextRate(value){
  return decimal(value, {precision: 0});
}

export function remicRate(value) {
  return decimal(value, {precision: 6});
}

export function ltv(value) {
  return decimal(value, {precision: 2});
}

export function capPercent(value) {
  const decimalValue = decimal(value, {precision: 3});
  return isBlankOrNaN(decimalValue) ? value : `${decimalValue}%`;
}

export function prepaymentTypeOverlay(types) {
  return definitionOverlay(types, "prepaymentTypes");
}

export function principalTypeOverlay(types) {
  return definitionOverlay(types, "principalTypes");
}

export function securityType(value) {
  const labels = getLabels("securityTypes");
  const types = labels && labels[getOrganizationFromLocation()] || {};
  return types[value] || value || 'NA';
}

export function corrected(value) {
  return value === 'YES' ? 'Corrected' : undefined;
}

export function strattribute(value) {
  return value ? value.replace(/\_/g, ' ') : '';
}

export function trancheNameToClass(trancheName) {
  let parsed = /^\d+-(.+)$/.exec(trancheName);
  return parsed === null ? trancheName : parsed[1];
}

/**
 * FRE Trust numbers can be duplicate, so internally CSP appends an S to some,
 * which we need to remove when displaying.
 */
export function trustNumberFormatter(trustNumberOrContainer) {

  if (trustNumberOrContainer == undefined) {
    return undefined;
  }

  if (typeof trustNumberOrContainer === 'string') {
    return /[sS]$/.test(trustNumberOrContainer) && 'freddie' == getOrganizationFromLocation() ? trustNumberOrContainer.substr(0, trustNumberOrContainer.length - 1) : trustNumberOrContainer;
  }

  return trustNumberOrContainer.trustNumber ? trustNumberFormatter(trustNumberOrContainer.trustNumber) : undefined;
}

export function issuanceIndicator(issuanceDate) {

  let issuanceFormatted = formatDateMmYyyy(issuanceDate);

  return (currentDate) => {
    const currentFormatted = formatDateMmYyyy(currentDate);

    if (currentFormatted !== issuanceFormatted) {
      return currentFormatted;
    } else {

      return <span>{currentFormatted}{/*{<span className="issuance-badge"/>}*/}</span>
    }
  };
}
