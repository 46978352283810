import React from 'react';
import classNames from 'classnames';

import ResourcesButtonDropdown from 'common/button/ResourcesButtonDropdown';

import { getOrganizationFromLocation } from 'common/util/common-helpers';


export default ({loggedIn, settings}) => {

    const className = classNames('header-resources', {'logged-in': loggedIn});
    const org = getOrganizationFromLocation()
    const accountOptions = [
        { text: 'Product Information', url: `/${org}/products`},
        { text: 'Learning Center', url: `/${org}/learning`},
        { text: 'Data Collections', url: `/${org}/account/datafiles/singleclass/issuance`},
        { text: 'REMIC Residual Tax Investor Portal', url: `${settings.taxInvestorPortal}`, external: 'true'},
        { text: 'Fannie Majors', url: `/${org}/majors`}
    ];

    return (
        <div className={className}>
            <ResourcesButtonDropdown buttonClassName='resources-button' options={accountOptions} />
        </div>
    );

};