import React from 'react';

export default () => {
  return (
    <div className="alerts">
          <h3>Alerts</h3>
          <p>Registered users are able to be notified by email that selected files have been published.</p>
          <h4>To Enroll:</h4>
          <ul>
            <li>For each requested Email Alert, select the bell adjacent to the header label of the specific file name.</li>
            <li>Hover over the Email Alerts bell and select the hyperlink “Turn On Email Alerts.”</li>
            <li>Repeat for each file enrollment request.</li>
          </ul>
          <h4>To Unenroll:</h4>
          <ul>
            <li>Select the bell adjacent to the header label of the specific file name.</li>
            <li>Hover over the Email Alerts bell and select the hyperlink “Turn Off Email Alerts.”</li>
            <li>Repeat for each file unenrollment request.</li>
          </ul>
    </div>
  )
};