import React, { Component } from 'react';

import { FORM_OPTIONS } from 'common/login/loginReducer';

import AcceptTermsForm from 'www/components/login/AcceptTermsForm';
import LoginForm from 'www/components/login/LoginForm';
import MessageForm from 'www/components/login/MessageForm';
import RegisterForm from 'www/components/login/RegisterForm';
import StepOneForm from 'www/components/login/StepOneForm';


export default class Login extends Component {
  
  render() {  
    const { loginState, checkEmail, close, forgotPassword, showRegister, register, login, showLogin, settings, acceptTerms, logout, onForgotPwd  } = this.props;
    const { form, isFetching, message, email } = loginState;
    let loginForm;
    if (form === FORM_OPTIONS.LOGIN) {
        loginForm = <LoginForm isFetching={isFetching} email={email} message={message} onLogin={login} onForgotPassword={onForgotPwd} onRegister={showRegister} onClose={close} />;
    } else if (form === FORM_OPTIONS.REGISTER) {    
      loginForm = <RegisterForm isFetching={isFetching} email={email} message={message} onRegister={register} onClose={close} settings={settings} />;
    } else if (form === FORM_OPTIONS.MESSAGE) {    
      loginForm = <MessageForm message={message} onLogin={showLogin} onClose={close}/>;
    } else if (form === FORM_OPTIONS.ACCEPT_TERMS) {    
      loginForm = <AcceptTermsForm settings={settings} acceptTerms={acceptTerms} cancel={logout} />;
    } else {        
        loginForm = <StepOneForm isFetching={isFetching} message={message} onCheckEmail={checkEmail} onClose={close} />;
    }

    return <div className="modal-wrap login">{loginForm}</div>;
  }

}
