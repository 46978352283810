let mappings = require('resources/dataFiles.json');

if (!mappings) {
  console.warn('No key -> label mappings found...');
  mappings = {};
}

function flatten(toFlatten, flattened = {}) {
  if (toFlatten !== undefined) {
    Object.keys(toFlatten).forEach(key => {
      const value = toFlatten[key];
      if (typeof value === 'object') {
        flatten(value, flattened);
      } else {
        flattened[key] = value;
      }
    });
  }
  return flattened;
}

function isEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

function getBySingleKey(key, subSection) {
  if (isEmpty(subSection)) {
    return flatten(mappings[key]);
  } else {
    return flatten(subSection[key]);
  }
}

function getFromMappingByKeys(keys, subSection = {}) {
  if (keys.length === 1) {
    return getBySingleKey(keys.pop(), subSection);
  } else {
    const parent = keys.shift();
    if (isEmpty(subSection)) {
      return getFromMappingByKeys(keys, mappings[parent]);
    } else {
      return getFromMappingByKeys(keys, subSection[parent]);
    }
  }
}

export function getByKeys(keys) {
  const keysCopy = Array.from(keys);
  return getFromMappingByKeys(keysCopy);
}