import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { isLoggedIn, idleLogout, cancelIdleLogout, logout, keepSessionActive } from 'common/login/loginActions';
import { getDocuments,getUnsubscribeFromReport } from 'www/actions/documentsActions';
import {loadBanners} from 'www/actions/bannerAction';
import { getSettings } from 'www/actions/settingsActions';
import Header from 'www/containers/Header';
import Footer from 'www/components/Footer';
import { fetchInitialData } from 'www/util/www-helpers';
import IdleDetector from 'common/IdleDetector';
class App extends Component {
  componentDidMount() {
    fetchInitialData(this.props);
  }

  render() {
     const { children, login, settings, idleLogout, logout, cancelIdleLogout, keepSessionActive } = this.props;
     const { params } = this.props.match;

    return (
      <div id="app" className={params.organization}>
        {login.loggedIn && <IdleDetector showIdleLogout={login.showIdleLogout}
                                   idleTimeout={settings.idleTimeout}
                                   idleAction={idleLogout}
                                   defaultAction={logout}
                                   cancelAction={cancelIdleLogout}
                                   keepSessionActive={keepSessionActive} />}
        <div className={classNames('app-content', {'login-visible': login.loginVisible})}>
          <Header {...this.props} />
            {children && React.cloneElement(children, this.props)}
        </div>
        <Footer params={params} />
      </div>
    );
  }

}


export default connect(
  ({login, account, settings, documents, globalBanners}) => ({ login, account, settings, documents, globalBanners }),
  { getSettings, getDocuments,getUnsubscribeFromReport, isLoggedIn, logout, idleLogout, cancelIdleLogout, keepSessionActive, loadBanners }
)(App);
