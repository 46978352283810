import request from 'superagent';

const backend = `/api/`;
const getUrl = (url, options) => (
  options.api !== false ? `${backend}${url}` : `/${url}`
);

const metaMap = {};
(function() {
  const metaTags = document.getElementsByTagName('meta');

  for (let i = 0; i < metaTags.length; i++) {
    const metaTag = metaTags[i];
    const name = metaTag.getAttribute('name');
    const content = metaTag.getAttribute('content');

    if (name && content) {
      metaMap[name] = content;
    }
  }

  if (metaMap[CSRF_NAME] === undefined) {
    metaMap[CSRF_NAME] = "X-Csrf-Missing";
    console.log('*** Could not find CSRF token from meta tags. Server cals may faill.')
  }
})();

const CSRF_NAME = '_csrf_header';
const CSRF_VALUE = '_csrf';
const CSRF_TOKEN_NAME = 'X-CSRFToken'
const CSRF_TOKEN_VALUE = 'csrfToken'

const responseHandler = ({options, resolve, reject}) => {
  return (err, res) => {
    if (res && !res.ok) {
      reject(res.status+':'+res.text);
    } else if (err) {
      reject(err.message);
    } else {
      API.lastValidResponse = new Date();
      resolve(options.raw ? res.text : res.text && JSON.parse(res.text));
    }
  };
};

export default class API {
  static get = (url, params, options = {}) => {
    return new Promise((resolve, reject) => {
      request.get(getUrl(url, options)).query(params)
        .set(metaMap[CSRF_NAME], metaMap[CSRF_VALUE])
        // .set(metaMap[CSRF_TOKEN_NAME], metaMap[CSRF_TOKEN_VALUE])
        .end(responseHandler({options, resolve, reject}));
    });
  };

  static postJson = (url, params, options = {}) => {
    return new Promise((resolve, reject) => {
      request.post(getUrl(url, options))
        .type('json')
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set(metaMap[CSRF_NAME], metaMap[CSRF_VALUE])
        .send(params)
        .end(responseHandler({options, resolve, reject}));
    });
  };

  static post = (url, params, options = {}) => {
    return new Promise((resolve, reject) => {
      request.post(getUrl(url, options))
        .type('form')
        .set(metaMap[CSRF_NAME], metaMap[CSRF_VALUE])
        .send(params)
        .end(responseHandler({options, resolve, reject}));
    });
  };

  static put = (url, params, options = {}) => {
    return new Promise((resolve, reject) => {
      request.put(getUrl(url, options))
        .type('form')
        .set(metaMap[CSRF_NAME], metaMap[CSRF_VALUE])
        .send(params)
        .end(responseHandler({options, resolve, reject}));
    });
  };

  static del = (url, params, options = {}) => {
    return new Promise((resolve, reject) => {
      request.del(getUrl(url, options))
        .set(metaMap[CSRF_NAME], metaMap[CSRF_VALUE])
        .query(params)
        .end(responseHandler({options, resolve, reject}));
    });
  };

  static lastValidResponse = new Date(); // we loaded our JS, that's a valid response
}

