import React, {Component, useState } from 'react';

import Select from 'common/form/Select';

import {
  isEmpty,
  getOrganizationFromLocation,
  isAlphaNumericCharCodeOrWildCard,
  isCommaCharCode,
  isDecimalPointCharCode,
  isEnterCharCode,
  isMmDdYyyyCharCode,
  isMmYyyyCharCode,
  isNumericCharCode
} from 'common/util/common-helpers';

import { mmDdYyyyRegex, mmYYYYRegex } from 'common/util/regexes';
import { getTypeOptions, getStatusOptions } from 'www/util/www-helpers';
import { MONTH_NAMES } from 'common/util/common-formatters.jsx';

const AdvancedSearch = props => {
  const {
    hasSearchCriteria,
    onFocusChange,
    onInputChange,
    onSearch,
    onCancel,
    onClear,
    yearRange,
    invalidFields,
    prefix,
    issrSpclSecuType,
    status,
    q,
    searchPlaceHolder,
    factorFrom, factorTo,
    issuanceFromMonth, issuanceFromYear,
    issuanceToMonth, issuanceToYear,
    maturityFromMonth, maturityFromYear,
    maturityToMonth, maturityToYear,
    netRateFrom, netRateTo,
    upbFrom, 
    upbTo,
    currentPlatformMonth,
    currentPlatformYear,
    handleInputChangeSelect
  } = props;

  console.log(issuanceFromYear);

  const secTypeOptions = getTypeOptions();
  const statusOptions = getStatusOptions();
  const textProps = {
    type: 'text',
    onChange: onInputChange,
    onInput: onInputChange,
    onBlur: onFocusChange,
    onKeyUp: onFocusChange
  };

  const monthOptions = getMonthOptions();
  const yearOptions = getYearOptions(yearRange);
  const issueStartDateYearOptions = getYearOptionsInDecendingOrder({max:currentPlatformYear+1, min:yearRange.min});
  const MaturityDateYearOptions = getYearOptions({min:yearRange.min, max:currentPlatformYear+40});
  return (
    <div className="advanced-panel">
      <div className="input range">
        <label>Search</label>
        <input 
          value={q === 'redirected' ? '' : q}
          name="advancedQuery"
          onKeyUp={onInputChange} 
          onChange={onInputChange}
          placeholder={searchPlaceHolder}/>
      </div>
      <div className="input">
        <label>Security Type</label>
        <Select {...nameAndClass("issrSpclSecuType")} value={issrSpclSecuType} onChange={blurAndInputChange} options={secTypeOptions}/>
      </div>
      <div className="input">
        <label>Security Status</label>
        <Select {...nameAndClass("status")} value={status} onChange={blurAndInputChange} options={statusOptions}/>
      </div>
      <div className="input">
        <label>Pool Prefix <a href="#!" onClick={clickAction} title="An asterisk (*) can be used for partial search matches on this field."><span className="fa fa-question-circle"></span></a></label>
        <input {...textProps} {...nameAndClass("prefix")} placeholder="e.g., CL" value={prefix} onKeyPress={poolPrefixKeyUp} />
      </div>
      <div className="input date">
        <label>Issue Date</label>
        <Select {...nameAndClass("issuanceFromMonth")} value={issuanceFromMonth} onChange={blurAndInputChange} options={monthOptions}/>
        <Select {...nameAndClass("issuanceFromYear")} classes="year" value={issuanceFromYear} onChange={blurAndInputChangeIssuanceFrom} options={issueStartDateYearOptions} onMouseDown={defaultYearToCurrentIssuanceFrom}/>
        <span> to </span>
        <Select {...nameAndClass("issuanceToMonth")} value={issuanceToMonth} onChange={blurAndInputChange} options={monthOptions}/>
        <Select {...nameAndClass("issuanceToYear")} classes="year" value={issuanceToYear} onChange={blurAndInputChange} options={issueStartDateYearOptions} onMouseDown={defaultYearToCurrentIssuanceFrom}/>
      </div>
      <div className="input date">
        <label>Maturity Date</label>
        <Select {...nameAndClass("maturityFromMonth")} value={maturityFromMonth} onChange={blurAndInputChange} options={monthOptions}/>
        <Select {...nameAndClass("maturityFromYear")} classes="year" value={maturityFromYear} onChange={blurAndInputChangeMatureFrom} options={MaturityDateYearOptions} onMouseDown={defaultYearToCurrentMatureFrom}/>
        <span> to </span>
        <Select {...nameAndClass("maturityToMonth")} value={maturityToMonth} onChange={blurAndInputChange} options={monthOptions} />
        <Select {...nameAndClass("maturityToYear")} classes="year" value={maturityToYear} onChange={blurAndInputChange} options={MaturityDateYearOptions} onMouseDown={defaultYearToCurrentMatureFrom}/>
      </div>
      <div className="input range">
        <label>Current Factor</label>
        <input {...textProps} {...nameAndClass("factorFrom")} placeholder="e.g., 0.80" value={factorFrom} onKeyPress={decimalKeyPress} />
        <span> to </span>
        <input {...textProps} {...nameAndClass("factorTo")} placeholder="e.g., 0.95" value={factorTo} onKeyPress={decimalKeyPress} />
        <span {...showHideErrorHint("factorError")} name="factorError"> Invalid factor. A valid factor has up to four digits followed by up to 8 decimal places after the period.</span>
      </div>
      <div className="input range">
        <label>Current WA Net Interest Rate</label>
        <input {...textProps} {...nameAndClass("netRateFrom")} placeholder="e.g., 1.5" value={netRateFrom} onKeyPress={decimalKeyPress} />
        <span> to </span>
        <input {...textProps} {...nameAndClass("netRateTo")} placeholder="e.g., 6.0" value={netRateTo}  onKeyPress={decimalKeyPress} />
        <span {...showHideErrorHint("netRateError")} name="netRateError"> Invalid interest rate. A valid rate has up to 10 digits followed by up to 10 decimal places after the period.</span>
      </div>
      <div className="input range">
        <label>UPB (Issuance)</label>
        <input {...textProps} {...nameAndClass("upbFrom")} placeholder="e.g., 500000" value={upbFrom} onKeyPress={decimalKeyPress} />
        <span> to </span>
        <input {...textProps} {...nameAndClass("upbTo")} placeholder="e.g., 10000000" value={upbTo} onKeyPress={decimalKeyPress} />
        <span {...showHideErrorHint("upbError")} name="upbError"> Invalid UPB. A valid UPB has up to 2 decimal places after the period.</span>
      </div>

      <div className="buttons">
        <button className="btn btn-primary clear" onClick={onClear} disabled={!hasSearchCriteria}>Clear</button>
        <button className="btn btn-primary search" onClick={onSearch} disabled={!isEmpty(invalidFields)}>Search</button>
        <button className="btn btn-primary cancel" onClick={onCancel}>Cancel</button>
      </div>


      {  getOrganizationFromLocation() === 'freddie'
        ? <div> <label className="freddie-search-notice"> Securities information specific to Pseudopools, ARMs, Reinstated, Modified, Multifamily
             and 75-day fixed-rate mortgages will only be found in the Basic Search.
          </label></div>
        : null }
    </div>
  );


  const  clickAction = () =>{
    console.log("advance search  ");
  } 

  function defaultYearToCurrentIssuanceFrom(e){
    if(e.target.selectedIndex===0){
      e.target.options[2].selected = true;
      e.target.selectedIndex=2;
      blurAndInputChangeIssuanceFrom(e);
    }
  }

  function defaultYearToCurrentMatureFrom(e){
    if(e.target.selectedIndex===0){
      e.target.value = currentPlatformYear;
      blurAndInputChangeMatureFrom(e);
    }
  }

  function blurAndInputChange(e) {
    onInputChange(e);
    onFocusChange(e);
  }

  function blurAndInputChangeIssuanceFrom(e){
      const issuanceToSelectOption = document.getElementsByName('issuanceToYear')[0];
      issuanceToSelectOption.options[e.target.selectedIndex].selected = true;
      issuanceToSelectOption.selectedIndex = e.target.selectedIndex;
      handleInputChangeSelect(e.target, issuanceToSelectOption);
  }

  function blurAndInputChangeMatureFrom(e){
    const issuanceToSelectOption = document.getElementsByName('maturityToYear')[0];
    issuanceToSelectOption.options[e.target.selectedIndex].selected = true;
    issuanceToSelectOption.selectedIndex = e.target.selectedIndex;
    handleInputChangeSelect(e.target, issuanceToSelectOption);
}

  function nameAndClass(name) {
    const retProps = {name};

    if (invalidFields[name]) {
      retProps['className'] = 'invalid-field';
    }

    return retProps;
  }

  function showHideErrorHint(name) {
    const retProps = {name}

    if (invalidFields[name]) {
      retProps['className'] = 'fa fa-exclamation-circle error-hint';
    } else {
      retProps['className'] = 'hidden';
    }

    return retProps;
  }

  function poolPrefixKeyUp(e) {
    if (isEnterCharCode(e) ||
       (isAlphaNumericCharCodeOrWildCard(e) && e.target.value.length < 2)) {
      return;
    }
    e.preventDefault();
  }

  function issueDateKeyPress(e) {
    if (isEnterCharCode(e)) {
      return;
    }

    if (!isMmDdYyyyCharCode(e)) {
      e.preventDefault();
      return;
    }

    const target = e.target;
    const len = target.value.length;
    if (len == 1 || len ==4) {
      setTimeout(() => target.value = target.value + '/');
    }
  }

  function maturityDateKeyPress(e) {
    if (isEnterCharCode(e)) {
      return;
    }

    if (!isMmYyyyCharCode(e)) {
      e.preventDefault();
      return;
    }

    const target = e.target;
    const len = target.value.length;
    if (len == 1) {
      setTimeout(() => target.value = target.value + '/');
    }
  }

  /**
	 * This prevents the user from typing in an invalid key into an input box.
	 * 
	 * Be aware that Chrome allows copy-paste to go through without this being
	 * called.
	 * 
	 * @param e
	 *            keypress event
	 */
  function preventKeyPress(e) {
    e.preventDefault();
  }

  function isAllowedChar(e, allowCommas) {
    return isEnterCharCode(e) ||
      isNumericCharCode(e) ||
      (allowCommas && isCommaCharCode(e)) ||
        // backend and expectations appears to only accepts integer,
        // so for the comma UPB values, accept commas without decimal.
      (!allowCommas && (isDecimalPointCharCode(e) && e.target.value.indexOf('.') === -1))
  }

  function integerWithCommaPress(e) {
    if (!isAllowedChar(e, true)) {
      preventKeyPress(e);
    }
   }

  function decimalKeyPress(e) {
    if (!isAllowedChar(e, false)) {
      preventKeyPress(e);
    }
  }
}

function getMonthOptions() {
  return MONTH_NAMES.map((month, idx) => ({value: idx + 1, display: month}));
}

function getYearOptions({min, max}) {
  const years = [];
  for (let i = min; i <= max; i++) {
    years.push(i);
  }
  years.unshift("Select");
  return years.map(year => ({value: year, display: year}));
}

function getYearOptionsInDecendingOrder({min, max}) {
  const years = [];
  for (let i = max; i >= min; i--) {
    years.push(i);
  }
  years.unshift("Select");
  return years.map(year => ({value: year, display: year}));
}


export default AdvancedSearch;