import React, {Component} from "react"
import {LocalizationProvider, DesktopDatePicker} from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import Button from "@mui/material/Button";
import createTheme from "@mui/material/styles/createTheme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import {darken} from "@mui/system";
import {getOrganizationFromLocation} from "../util/common-helpers";

export default class MaterialUIPickers extends Component {

    state = {};

    constructor(props) {
        super(props);
        const { options, selectedDate, issueDate } = this.props;
        this.organization = getOrganizationFromLocation();
        this.state = {timestamp: selectedDate ? new Date(selectedDate + 'T00:00-0800') : null, minDate: (options && options.length > 0) ? options[0] : null,
            maxDate: (options && options.length > 0) ? options[options.length - 1] : null, issueDate: issueDate ? issueDate : null};
    }

    onAcceptItem = (timestamp) => {
        const { onSelectItemHandler } = this.props;
        const { idx } = this.props || 0;
        if (timestamp) {
            const month = {
                name : new Intl.DateTimeFormat('fr-ca', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(timestamp)
            }
            this.setState({timestamp: timestamp});
            if (onSelectItemHandler) {
                onSelectItemHandler(idx, month);
            }
        }
    };

    onIssuanceButtonClick = () => {
        const { issueDate, onSelectItemHandler } = this.props;
        const month = { name: issueDate };
        onSelectItemHandler(0, month);
    }

    componentDidMount() {}

    render() {
        const {label, onClick, buttonClassName, activeTab, onSelectItemHandler} = this.props;
        const timestamp = this.state.timestamp ? this.state.timestamp : null;
        const minDate = new Date(this.state.minDate.text + 'T00:00-0800');
        const maxDate = new Date(this.state.maxDate.text + 'T00:00-0800');
        //issueDate is used to determine if we render 'Issuance' button on DatePicker - value is only not null for Deal Classes tab
        const issueDate = this.state.issueDate;

        const yearDropdownColor = this.organization === 'fannie' ? '#90b815' : '#e06f19';
        const selectedDateColor = this.organization === 'fannie' ? '#87b5d8' : '#87bd45';
        const defaultMaterialTheme = createTheme();
        const newTheme = (theme) => createTheme({
            ...theme,
            components: {
                MuiPickersCalendarHeader: {
                    styleOverrides: {
                        labelContainer: {
                            '&:hover': {
                                backgroundColor: darken(yearDropdownColor, .3)
                            },
                            color: '#fff',
                            borderRadius: 20,
                            backgroundColor: yearDropdownColor,
                            paddingLeft: '15px'
                        },
                        switchViewIcon: {
                            color: '#fff'
                        }
                    }
                }, MuiPickersMonth: {
                    styleOverrides: {
                        monthButton: {
                            "&.Mui-selected": {
                                backgroundColor: selectedDateColor,
                                "&:focus, &:hover": {
                                    backgroundColor: selectedDateColor
                                }
                            }
                        }
                    }
                }, MuiPickersYear: {
                    styleOverrides: {
                        yearButton: {
                            "&.Mui-selected": {
                                backgroundColor: selectedDateColor,
                                "&:focus, &:hover": {
                                    backgroundColor: selectedDateColor
                                }
                            }
                        }
                    }
                }
            }
        })

        return (
            <ThemeProvider theme={defaultMaterialTheme} >
            <LocalizationProvider dateAdapter={AdapterDateFns} >
                <ThemeProvider theme={newTheme}>
                    <ButtonDatePicker
                        minDate={minDate}
                        maxDate={maxDate}
                        openTo="month"
                        views={["year", "month"]}
                        id="date-picker-inline"
                        label={timestamp == null ? label : ''}
                        format='yyyy-MM'
                        size='small'
                        inputFormat='yyyy-MM'
                        onAccept={this.onAcceptItem}
                        buttonClassName={buttonClassName}
                        onClick={onClick}
                        activeTab={activeTab}
                        timestamp={timestamp}
                        issueDate={issueDate}
                        onIssuanceButtonClick={this.onIssuanceButtonClick}
                        onSelectItemHandler={onSelectItemHandler}
                    />
                </ThemeProvider>
            </LocalizationProvider>
            </ThemeProvider>
        );
    }

}

const IssuanceButton = (props) => {
    const {hidden, onIssuanceButtonClick, onSelectItemHandler} = props;
    if (hidden) {
        return ('');
    }
    return (
        <div className={'issuance-button-mui'}>
            <button onClick={() => onIssuanceButtonClick(onSelectItemHandler)}>Issuance</button>
        </div>
    )
}

const ButtonDatePicker = (props)  => {
    const [open, setOpen] = React.useState(false);
    const { buttonClassName, activeTab, timestamp, issueDate, onIssuanceButtonClick, onSelectItemHandler } = props;

    //When hideToolbar = true the DatePicker will not have ISSUANCE button
    const hideToolbar = issueDate === null ? true : false;

    return (
        <DesktopDatePicker
            slots={{ field: ButtonField, ...props.slots, toolbar: IssuanceButton }}
            slotProps={{ field: { setOpen }, textField: {size: 'small'}, customization: {activeTab: activeTab, className: buttonClassName, timestamp: timestamp, issueDate: issueDate},
            toolbar: {hidden: hideToolbar, onIssuanceButtonClick: onIssuanceButtonClick, onSelectItemHandler: onSelectItemHandler}}}
            {...props}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        />
    );
}

const ButtonField = (props) => {
    const {
        setOpen,
        id,
        InputProps: { ref } = {},
        inputProps: { 'aria-label': ariaLabel } = {},
        value,
        slotProps
    } = props;

    const buttonClassName = slotProps.customization.className ? slotProps.customization.className : '';
    const timestamp = slotProps.customization.timestamp ? slotProps.customization.timestamp : null;
    const issueDate = slotProps.customization.issueDate ? slotProps.customization.issueDate.slice(0,7) : null;
    const formattedValue = new Intl.DateTimeFormat('fr-ca', {year: 'numeric', month: '2-digit'}).format(timestamp);

    const displayDate = issueDate === formattedValue ? 'ISSUANCE' : formattedValue;
    const icon = <i className={'fa fa-angle-down'} />;

    let styles;
    if (slotProps.customization.activeTab && slotProps.customization.activeTab === 'details') {
        styles = {borderRadius: 0, height: '28px', display: 'right', fontSize: '12px', color: 'white', padding: '2px', margin: '-2px'}
    } else if (slotProps.customization.activeTab && slotProps.customization.activeTab === 'deal-classes') {
        styles = {display: 'flex', justifyContent: 'center', alignItems: 'center'}
    } else {
        //This else block is currently only entered if the activeTab is statistics
        styles = {borderRadius: 0, height: '32px', fontSize: '12px'}
    }

    return (
        <Button
            style={styles}
            variant="text"
            id={id}
            ref={ref}
            aria-label={ariaLabel}
            onClick={() => setOpen?.((prev) => !prev)}
            className={buttonClassName}
        >
            {(timestamp) ? `${displayDate}` : 'MONTH...'} {icon}
        </Button>
    );
}