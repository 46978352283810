import { DETAILS_REQUEST, TAX_REQUEST, TAX_RESPONSE, TAX_ERROR, TAX_SORT, TAX_YEAR_SELECT,
  TAX_COUNT_RESPONSE,TAX_COUNT_ERROR } from 'www/actions/detailsActions';
import { maxKey } from 'common/util/common-helpers';
import { reducerGenerator } from 'common/util/redux';
import { getNewSort, sortArray } from 'common/util/sorter';


const initialState = {
  message: null,
  data: [],
  hasError: false,
  isFetching: false,
  sorts: [],
  txCount: null,
  selectedYears: [],
  footerText: null
};

function onDetailsTaxFactorCountError({state, action}) {
  return {...state, message: (action && action.message) ? action.message : 'There was an error retrieving TaxFactor count.', hasError: true, isFetchingTaxCount: false, data: []};
}


export default reducerGenerator(initialState, {
  DETAILS_REQUEST: (state, action) => initialState,  
  TAX_COUNT_RESPONSE: (state, action) => ({...state, txCount: action && action.count >= 0 ? action.count : state.count}),
  TAX_COUNT_ERROR: onDetailsTaxFactorCountError,
  TAX_REQUEST: (state, action) => ({...state, isFetching: true, pageIdx: action.pageIdx, pageSize: action.pageSize}),
  TAX_RESPONSE: (state, action) => {
    const taxResponse = action.results || {};
    if (taxResponse.factorsResponse) {
      let data = taxResponse.data || {};

      if (!Array.isArray(data)) {
        data = [ data || {} ]
      }

      const footerText = taxResponse.footerText || [];
      const hasSelectedYears = Array.isArray(state.selectedYears) && state.selectedYears.length > 0
      const selectedYears = data.map((d, i) => hasSelectedYears ? state.selectedYears[i]: maxKey(d));
      return {...initialState, sorts: state.sorts, data, selectedYears, footerText, pageIdx: action.pageIdx, pageSize: action.pageSize};
    } else {      
      const data = [];
      const footerText = [];
      const selectedYears = maxKey(data);
      const url = taxResponse.url;
      return {...initialState, sorts: state.sorts, data, selectedYears, footerText, url, pageIdx: action.pageIdx, pageSize: action.pageSize};
    }
  },
  TAX_ERROR: (state, action) => ({
    ...state, 
    data: null, 
    hasError: true,
    isFetching: false,
    message: action.message || 'Unable to load tax data. Please try again later.'
  }),
    
  TAX_SORT: (state, action) => {
    
    const { data, selectedYears, sorts } = state;
    const { field, securityIndex } = action;

    // remember ... is shallow copy, so some repetition of copies below to make sure
    // we shallow copy what we are not going to change and deep copy what we are
    const newData = [...data];

    const newSorts = [...sorts];
    const newSort = getNewSort(newSorts[securityIndex] || {}, field);
    newSorts[securityIndex] = newSort;

    const selectedYear = selectedYears[securityIndex] || maxKey(data[securityIndex]);

    newData[securityIndex] = {...newData[securityIndex]};

    newData[securityIndex][selectedYear] = sortArray(newData[securityIndex][selectedYear], newSort);
    return {
      ...state,
      sorts: newSorts,
      data: newData
    };
  },
  TAX_YEAR_SELECT: (state, action) => {
    const selectedYears = [...state.selectedYears];
    selectedYears[action.securityIndex] = action.year;
    return {...state, selectedYears};
  }

});

