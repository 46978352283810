export const REPORTS_GROUP_REQUEST = 'REPORTS_GROUP_REQUEST';
export const REPORTS_GROUP_RESPONSE = 'REPORTS_GROUP_RESPONSE';
export const REPORTS_GROUP_ERROR = 'REPORTS_GROUP_ERROR';

export const REPORTS_GROUP_YEAR_REQUEST = 'REPORTS_GROUP_YEAR_REQUEST';
export const REPORTS_GROUP_YEAR_RESPONSE = 'REPORTS_GROUP_YEAR_RESPONSE';
export const REPORTS_GROUP_YEAR_ERROR = 'REPORTS_GROUP_YEAR_ERROR';

export function getGroupReports(group, urlPath) {
  return {
    type: REPORTS_GROUP_REQUEST,
    group,
    urlPath,
    id: group
  };
}

export function getGroupYearReports(group, year, urlPath) {
  return {
    type: REPORTS_GROUP_YEAR_REQUEST,
    group,
    year,
    urlPath,
    id: group
  };
}