import React, { Component } from 'react';
import classNames from 'classnames';

import Cell from 'common/table/Cell';
import HeaderCell from 'common/table/HeaderCell';
import { assign, createDynamicClassNameAppender, defaults, isBlank, isEmpty } from 'common/util/common-helpers';
import { eventHandler } from 'common/util/events';

export const COLUMN_TYPES = {
  CHECKBOX: 'CHECKBOX',
  ACTION: 'ACTION',
  DATA: 'DATA',
  EXPANDER: 'EXPANDER'
};
const pStyle = {
        fontSize: '15px'
      };
export default class extends Component {

  state = {
    expandedRows: {}
  };
  

  setExpanded = (row, expanded) => {
    const key = row[this.props.config.uniqueKey];

    let newExpandedRows = assign({}, this.state.expandedRows, {[key]: expanded});
    this.setState({expandedRows: newExpandedRows});
  };

  render() {
    const { setExpanded } = this;
    const { config, headerType } = this.props;
    const { expandedRows } = this.state;
    const columns = getVisibleColumns(this.props);
    const className = classNames('simple-table', config.className);
    const buttonClassName = classNames('buttons', 'buttonBackground');

    const rows = getRows({...this.props, columns, expandedRows, setExpanded}, this.props);
    return (
      <ul className={className}>
        {React.createElement(headerType || Header, {...this.props, columns, key: 0})}
          {config.buttons && config.buttons.length && <div className={buttonClassName}>{config.buttons}</div>}
        {rows}
      </ul>
    );
  }
}

export const getVisibleColumns = ({config}) => {
  const { columns, columnDefaults } = config;
  const visibleColumns = columns.filter((c) => c.visible || c.visible === undefined);

  if (columnDefaults) {
    return visibleColumns.map(column => Object.assign({}, columnDefaults, column));
  } else {
    return visibleColumns;
  }
};

const Header = ({config, data, columns, sort, headerCellType, isFetchingMore}) => {
  return (
    <li className="list-group-item" key="header-row">
      {columns.map((column, index) => (
        React.createElement(headerCellType || HeaderCell, {key: index, index, column, config, sort, rows: data, disabled: isFetchingMore})
      ))}
    </li>
  );
};

const getRows = ({config, data, dataKey, columns, showRows, showSummaryRows, expandedRows, setExpanded}, props) => {
  const expandedComponent = config.expandedComponent;
  const { uniqueKey = '', onRowClick } = config;

  const rowData = dataKey ? data[dataKey] : data;

  const rowClassName = classNames('list-group-item', config.rowClassName);
  const rowDynamicClassNameAppender = createDynamicClassNameAppender(rowClassName, config.rowDynamicClassName);

  if (rowData && rowData.length > 0 ) {

    const colDynamicClassNameAppenders = columns.map(c => createDynamicClassNameAppender(c.className, c.dynamicClassName));
    const rows = [];


    (showRows !== false ? rowData : []).forEach((row, rowIdx) => {
      const rowClickHandler = onRowClick && eventHandler(onRowClick, row, rowIdx);

      rows.push(
        <li className={rowDynamicClassNameAppender(row, null, rowIdx)} key={'row'+(rowIdx+1)} onClick={rowClickHandler}>
          {columns.map((column, colIdx) => 
            <Cell key={colIdx} 
                  className={colDynamicClassNameAppenders[colIdx](row, column, rowIdx)} 
                  column={column} 
                  row={row} 
                  rowIdx={rowIdx} 
                  config={config} 
                  expandedRows={expandedRows} 
                  setExpanded={setExpanded} /> )}
        </li>
      );

      if (expandedComponent && expandedRows[row[uniqueKey]]) {
        rows.push(expandedComponent(row, rowIdx, data, config, props));
      }

    });

    if (showSummaryRows !== false && config.summaryRows) {
      config.summaryRows.map((summaryRow, summaryIdx) => {
        rows.push(
          <li className="list-group-item summary" key={'summary'+summaryIdx}>
            {summaryRow.columns.map((column, colIdx) => (
              <Cell key={colIdx} 
                    column={column} 
                    row={data} 
                    config={config} />
            ))}
          </li>
        );
      });
    }
    return rows;
  } else if (config.emptyText) {
    if(config.emptyText.issuer==='freddie'){
      return <li className="list-group-item no-data" key="no-data-container"><div key="empty-table-text">{config.emptyText.message1}<p style={pStyle}>{config.emptyText.message2}</p></div></li>;
      
      }
    
    else if(config.emptyText.issuer==='fannie'){
      
      return <li className="list-group-item no-data" key="no-data-container"><div key="empty-table-text">{config.emptyText.message1}</div></li>;
    }
    else if(config.emptyText.key ==='collateralText'){
      return <li className="list-group-item no-data" key="no-data-container"><div key="empty-table-text">{config.emptyText.text}</div></li>;

    }
  //   else  {
  //     // This used to be logic before DE24460 attempted to set issuer specific message for one page, but broke all others.
  //     // Behavior was then fixed in DIS-3266, but business decided they only want the text on collateral page for now, because
  //     // other emptyText have not been verified/reviewed against any recent requirement, and they won't to go through testing them all now
  //     // If this changes in future, just uncomment out this else block and all empty text will start working again
  //     return <li className="list-group-item no-data" key="no-data-container"><div key="empty-table-text">{config.emptyText}</div></li>;
  // }

    
  }

  return <span/>;
  
};