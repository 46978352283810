export const FNM_ANNOUNCEMENTS_REQUEST = 'FNM_ANNOUNCEMENTS_REQUEST';
export const FNM_ANNOUNCEMENTS_RESPONSE = 'FNM_ANNOUNCEMENTS_RESPONSE';
export const FNM_ANNOUNCEMENTS_ERROR = 'FNM_ANNOUNCEMENTS_ERROR';
export const FNM_ANNOUNCEMENT_ARCHIVE_REQUEST = 'FNM_ANNOUNCEMENT_ARCHIVE_REQUEST';
export const FNM_ANNOUNCEMENT_ARCHIVE_RESPONSE = 'FNM_ANNOUNCEMENT_ARCHIVE_RESPONSE';
export const FNM_ANNOUNCEMENT_ARCHIVE_ERROR = 'FNM_ANNOUNCEMENT_ARCHIVE_ERROR';

export const FNM_PRODUCT_REQUEST = 'FNM_PRODUCT_REQUEST';
export const FNM_PRODUCT_RESPONSE = 'FNM_PRODUCT_RESPONSE';
export const FNM_PRODUCT_ERROR = 'FNM_PRODUCT_ERROR';

export const FNM_LEARNING_REQUEST = 'FNM_LEARNING_REQUEST';
export const FNM_LEARNING_RESPONSE = 'FNM_LEARNING_RESPONSE';
export const FNM_LEARNING_ERROR = 'FNM_LEARNING_ERROR';


export function getAnnouncementsForFannie() {
    return { type:FNM_ANNOUNCEMENTS_REQUEST};
  }

export function getArchivedAnnouncementsForFannie(){
  return {type:FNM_ANNOUNCEMENT_ARCHIVE_REQUEST};
}

export function getProductResourceForFannie(){
    return {type:FNM_PRODUCT_REQUEST};
}

export function getLearningResourceForFannie(){
    return {type:FNM_LEARNING_REQUEST};
}

