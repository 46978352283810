import React, { Component } from 'react'
import { connect } from 'react-redux'


import Announcement from 'common/Announcement'
import FannieAnnouncements from '../../common/fannieAnnouncements'
import IdleDetector from 'common/IdleDetector'
import { eventHandler } from 'common/util/events'

import { cancelIdleLogout, idleLogout, isLoggedIn, keepSessionActive, logout } from 'common/login/loginActions'
import { getSettings } from 'www/actions/settingsActions'
import {loadBanners, dismissBanner} from 'www/actions/bannerAction';
import { getAnnouncementsForFannie, getArchivedAnnouncementsForFannie } from 'www/actions/fannieResourcesActions'
import Header from 'www/containers/Header'
import Footer from 'www/components/Footer'
import FannieSplashBox from '../../common/fannieSplashBox'
import { fetchInitialData, updateDocumentTitle, mergeBanners, reconcileBanners } from 'www/util/www-helpers';


class Splash extends Component {


    componentDidMount() {
        updateDocumentTitle();
        if (this.props && this.props.location && this.props.location.query && this.props.location.query.q && this.props.location.query.q === 'redirected') {
            alert('You will be redirected to home page as session token has been expired or set invalid');
        }
        fetchInitialData(this.props);
        //TODO: Future update - only call if issuer is FNM
        this.props.getAnnouncementsForFannie();
        this.props.getArchivedAnnouncementsForFannie();
    }

    render() {
        const { dismissBanner, login, settings, idleLogout, logout, cancelIdleLogout, globalBanners } = this.props;
        const {params}  = this.props.match;
        const org = params.organization;

        let reconciledBanners;
        let info, success, error;
        if(globalBanners && globalBanners.banners && globalBanners.banners.length > 0){
            reconciledBanners = reconcileBanners(globalBanners.banners);
        }

        if(reconciledBanners) {
            info = reconciledBanners.info;
            success = reconciledBanners.success;
            error = reconciledBanners.error;
        }

        if (org  === "freddie") {
            return (
                <div id="app">
                    {info && info.length > 0 && <Banner type = "info" banner={info} dismissBanner={dismissBanner}/>}
                    {success && success.length > 0 && <Banner type = "success" banner={success} dismissBanner={dismissBanner}/>}
                    {error && error.length > 0 && <Banner type = "error" banner={error} dismissBanner={dismissBanner}/>}

                    {login.loggedIn && <IdleDetector showIdleLogout={login.showIdleLogout}
                                                     idleTimeout={settings.idleTimeout}
                                                     idleAction={idleLogout}
                                                     defaultAction={logout}
                                                     cancelAction={cancelIdleLogout}
                                                     keepSessionActive={keepSessionActive} />}
                    <div className="app-content splash">
                        <Header {...this.props} isSplash='true'/>
                        {<Announcements {...settings} />}
                    </div>
                    <Footer params={params} />
                </div>
            );
            } else {
            return (
              <div id="app">
                  {info && info.length > 0 && <Banner type = "info" banner={info} dismissBanner={dismissBanner}/>}
                  {success && success.length > 0 && <Banner type = "success" banner={success} dismissBanner={dismissBanner}/>}
                  {error && error.length > 0 && <Banner type = "error" banner={error} dismissBanner={dismissBanner}/>}
                  {login.loggedIn && <IdleDetector showIdleLogout={login.showIdleLogout}
                                                   idleTimeout={settings.idleTimeout}
                                                   idleAction={idleLogout}
                                                   defaultAction={logout}
                                                   cancelAction={cancelIdleLogout}
                                                   keepSessionActive={keepSessionActive}/>}
                  <div className="app-content splash">
                      <Header {...this.props} isSplash='true'/>
                      <div className="homepage-body">
                          <div className="fnm-box-session">
                              {<FannieAnnouncements {...this.props} />}
                          </div>
                          <div className="fnm-box-session">
                              {<FannieSplashBox {...this.props} titleName='Product Information' titleUrl='products' content='Information about the single-family products issued by Fannie Mae'/>}
                              {<FannieSplashBox {...this.props} titleName='Learning Center' titleUrl='learning' content='Guides and documentation relating to using PoolTalk and disclosures'/>}
                              {<FannieSplashBox {...this.props} titleName='Data Collections' titleUrl= 'account/datafiles/singleclass/issuance' content='A repository of documentation and sample files related to PoolTalk' />}
                          </div>
                          <div className="fnm-box-session">
                              {<FannieSplashBox {...this.props} titleName='Fannie Majors&reg;' titleUrl='majors'/>}
                              {<FannieSplashBox {...this.props} titleName='REMIC Residual Tax Investor Portal' titleUrl= {settings.taxInvestorPortal} externalURL = 'true' /> }
                          </div>
                      </div>
                  </div>
                  <Footer params={params}/>
              </div>
            )
        }
    }

}

const Banner = (props) => {
    const {banner, dismissBanner, type} = props;
    return (
        <div className={`banner ${type || 'info'}`}>
            <div >
                {mergeBanners(banner)}
            </div>
            <i className="close fa fa-close" onClick={eventHandler(dismissBanner, banner)} />
        </div>
    );
};

const Announcements = ({sectionHeadingText, announcements, links}) => {
    return (
        <div className="announce-boxes">
            <h2 className="welcome-msg">{sectionHeadingText}</h2>
            <div className="two-panel-flex">
                <section className="news">
                    <h1 className="section-heading">News &amp; Announcements</h1>
                    <ul>{ announcements.map((obj, idx) => (<Announcement {...obj} key={idx}/>)) }</ul>
                </section>
                <section className="links">
                    <h1 className="section-heading">Helpful Links</h1>
                    <ul>{ links.map((obj, idx) => (<Announcement {...obj} key={idx}/>)) }</ul>
                </section>
            </div>
        </div>
    );
};


export default connect(
    ({login, account, settings, fannieResources, fannieArchiveResources, globalBanners}) => ({ login, account, settings, fannieResources, fannieArchiveResources, globalBanners }),
    { getSettings, dismissBanner, getAnnouncementsForFannie, getArchivedAnnouncementsForFannie, isLoggedIn, logout, idleLogout, cancelIdleLogout, keepSessionActive, loadBanners }
)(Splash);

