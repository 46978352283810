import {
  DETAILS_REQUEST, 
  SECURITY_DOCUMENTS_REQUEST,
  SECURITY_DOCUMENTS_RESPONSE,
  SECURITY_DOCUMENTS_ERROR,
} from 'www/actions/detailsActions';

import {
  sortBy,
  SORT_DESCENDING
} from 'common/util/common-helpers';

import { reducerGenerator } from 'common/util/redux';



const initialState = {
  message: null,
  documents: [],
  hasError: false,
  isFetching: false
};

function onSecurityDocumentsResponse(state, action) {

  const { documents = [] } = action;

  sortBy(documents, doc => doc.document.effectiveDate, SORT_DESCENDING);

  return {
    ...initialState, 
    documents
  };
}

export default reducerGenerator(initialState, {
  DETAILS_REQUEST: (state, action) => ({...initialState}),
  SECURITY_DOCUMENTS_REQUEST: (state, action) => ({...initialState, isFetching: true}),
  SECURITY_DOCUMENTS_RESPONSE: onSecurityDocumentsResponse,
  SECURITY_DOCUMENTS_ERROR: (state, action) => ({
    ...state,
    hasError: true,
    isFetching: false,
    message: action.message || 'Unable to load documents. Please try again later.'
  })
});
