let mappings = require('resources/dealDocuments.json');

export function getByKeys (keys){
    let labels = new Array();
    for(let key of keys){
        labels.push(mappings[key]);
    }
    return labels;
}

export function getByKey(key){
    return mappings[key];    
}