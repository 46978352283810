import { sortArray } from 'common/util/sorter';
import {  slug } from 'common/util/common-helpers';


export function findPortfolioBySlug(portfolio, title) {
  return (portfolio || []).find((element) => {
    return element.slug === slug(title);
  });
}

export function findPortfolioIndexBySlug(portfolio, title) {
  return (portfolio || []).findIndex((element) => {
    return element.slug === slug(title);
  });
}

export function isCusipInPortfolio(portfolio, cusip) {
  return portfolio.some((portfolioGroup) => {
    return portfolioGroup.portfolio.indexOf(cusip) !== -1;
  });
}

export function isDealInPortfolioGroup(portfolio, dealClassCusips){
  for (const cusip of portfolio) {
    if (dealClassCusips.indexOf(cusip) !== -1){
      return true;
    }
  };
  return false;
}

export function getCusipsInPortfolio(portfolio) {
  let cusips = [];
  portfolio.forEach((portfolioGroup) => {
    cusips = cusips.concat(portfolioGroup.portfolio);
  });
  return cusips;
}

export function getCusipsInCurrentPortfolio(currentPortfolio) {  
  return currentPortfolio && currentPortfolio.portfolio && currentPortfolio.portfolio.filter(c => c !== null);
}

export function deduplicatePortfolio(portfolio) {
  const portfolioHash = {};
  let newPortfolio = [];

  portfolio.forEach((cusip) => {
    if (!portfolioHash[cusip]) {
      newPortfolio.push(cusip);
      portfolioHash[cusip] = true;
    }
  });

  return newPortfolio;
}

export function sortPortfolio(portfolio, portfolioSummaries, sort) {
  let sortedPortfolio = [];

  if (portfolio && portfolio.length > 0) {
    //Note: we perform the map regardless of whether there's a sort so that we get a copy
    sortedPortfolio = portfolio.map((portfolioGroup) => {
      let sortedArray = getPortfolioData(portfolioGroup.portfolio, portfolioSummaries);

      if (sort) {
        sortedArray = sortArray(sortedArray, sort);
      }

      return {
        ...portfolioGroup,
        portfolio: sortedArray.map(({cusip}) => cusip)
      };
    });
  }

  return sortedPortfolio;
}

export function getPortfolioData(portfolio, summaries) {
  return portfolio.map((cusip) => {
    return (summaries && summaries[cusip]) ? summaries[cusip] : {cusip};
  });
}

export function extractIdentifiers(dataList) {
  let ids = [];

  dataList.forEach(obj => {
    ids.push(obj.secId);

    if (obj.dealId !== undefined) {
      ids.push(obj.dealId);
      ids.push(obj.dealId + '/' + obj.classId);

      if (obj.dealId.endsWith('S') || obj.dealId.endsWith('s')) {
        let dealIdWithoutS = obj.dealId.slice(0, obj.dealId.length - 1)
        ids.push(dealIdWithoutS);
        ids.push(dealIdWithoutS + '/' + obj.classId);
      }
    }
  });

  return ids;
}
