import React, { Component } from 'react';
import PropTypes from 'prop-types';



export default class IdleDialog extends Component {

  static propTypes = {
    idleTimeout: PropTypes.number,
    dialogTimeout: PropTypes.number,
    defaultAction: PropTypes.func,
    cancelAction: PropTypes.func
  };

  static defaultProps = {
    dialogTimeout: 60,
    defaultAction: () => {},
    cancelAction: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      remaining: props.dialogTimeout
    };
  }

  startTimer = () => {
    this.timerId = setInterval(() => {
      const { remaining } = this.state;
      if (remaining === 0) {
        this.props.defaultAction(true);
        clearInterval(this.timerId);
      } else {
        this.setState({remaining: remaining-1});
      }
    }, 1000);
  };

  componentDidMount() {
    this.startTimer();
  }

  UNSAFE_componentWillUnmount() {
    clearInterval(this.timerId);
  }

  render() {
    const { dialogTimeout, defaultAction, cancelAction, idleTimeout } = this.props;
    const { remaining} = this.state;

    return (
      <div className="timeout-dialog">
        <div className="dialog-contents">
          <div className="circle">
            <div className="left">
              <div style={{animationDuration: `${dialogTimeout}s`}}/>
            </div>
            <div className="right">
              <div style={{animationDuration: `${dialogTimeout}s`}}/>
            </div>
          </div>
          <div className="remaining">{remaining}</div>
          <p className="idle-message">
            You have been inactive for {idleTimeout} minutes.
            For your security, you will be automatically logged out in {remaining} seconds.
          </p>
          <div>
            <button onClick={cancelAction}>Cancel</button>
            <button className="btn-primary" onClick={defaultAction}>Logout now</button>
          </div>
        </div>
      </div>
    );
  }

}

