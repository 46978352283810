import React, { Component } from 'react';

import HistoryItem from 'www/components/history/HistoryItem';


export function updateHistoryNavHeight(className) {
  const windowHeight = window.innerHeight;
  const tableHeight = document.getElementsByClassName(className)[0].clientHeight;
  const nav = document.getElementsByClassName('sidebar')[0];

  nav.style.maxHeight = Math.max(windowHeight, tableHeight);
}

export function History ({history, clearHistory, deleteHistoryItem}) {
  return (
    <nav className="sidebar">
      <ul>{
        history.present.map((item, idx) => (
            <HistoryItem key={item.id} item={item} {...item} clickable={idx > 0} onClose={deleteHistoryItem} />
        ))
      }</ul>
      <div className="clear-history" onClick={clearHistory} tabIndex="0">Clear History</div>
    </nav>
  );
}
