import React, { Component } from 'react';
import { DropTarget, DragSource } from 'react-dnd';
import classNames from 'classnames';

import { COLUMN_TYPES } from 'common/table/SimpleTable';

class HiddenColumns extends Component {



  render() {
    const { connectDropTarget, isOver, columns, tableData } = this.props;
    const hiddenColumns = columns.filter((c) => c.visible === false && c.type !== COLUMN_TYPES.CHECKBOX);
    const className = classNames('draggable-fields', {'over-target': isOver});
    let contents;
    if (hiddenColumns.length === 0) {
      contents = <div className="no-fields-message">Drag columns here to exclude them</div>;
    } else {
      contents = hiddenColumns.map((c, i) => <HiddenColumn column={c} tableData={tableData} key={i} index={i} />);
    }

    if ( typeof connectDropTarget !== 'undefined' ){
      return connectDropTarget(
        <div className={className}>
          {contents}
        </div>
      );
    }else {
      return null;
    }
  }
}

class HiddenColumn extends Component {
  render () {
    const { connectDragSource, index, column, tableData } = this.props;
    const formatter = column.formatter || (i => i);

    return connectDragSource(
      <div className="field drag-element" key={column.key} index={index}>
        <div>{column.label}</div>
        <div>{tableData && tableData[0] && tableData[0][column.key] != undefined ? formatter(tableData[0][column.key], column, tableData[0]) : column.example || '-'}</div>
      </div>,
      { dropEffect: 'copy' }
    );
  }
}

HiddenColumns = DropTarget('visible-column', {
    drop(props, monitor) {
      props.dropFunction(monitor.getItem().id);
    }
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  })
)(HiddenColumns)

HiddenColumn = DragSource('hidden-column', {
    beginDrag(props) {
      const { index, column } = props;
      return {
        id: column.key,
        index
      };
    }
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource()
  })
)(HiddenColumn)

export default HiddenColumns;