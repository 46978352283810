let mappings = require('resources/keyLabelMappings.json');

if (!mappings) {
  console.warn('No key -> label mappings found...');
  mappings = {};
}

//case insensitive lookup
export function getLabels(type) {
  return mappings[type];
}

export function mapColumns(type, columns) {
  let labels = getLabels(type);
  if (labels == undefined) {
    console.log('KEY_LABEL_MAPPINGS: No key group found for: ' + type);
    labels = {};
  }
  columns.forEach(column => column.label = column.label || labels[column.key] || column.key);
}