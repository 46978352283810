import React, { useEffect, useReducer } from 'react'
import FannieArchivedAnnouncement from './fannieArchivedAnnouncement'
import ButtonDropdown from 'common/button/ButtonDropdown'

const monthMap = new Map()
monthMap.set(1, 'January')
monthMap.set(2, 'February')
monthMap.set(3, 'March')
monthMap.set(4, 'April')
monthMap.set(5, 'May')
monthMap.set(6, 'June')
monthMap.set(7, 'July')
monthMap.set(8, 'August')
monthMap.set(9, 'September')
monthMap.set(10, 'October')
monthMap.set(11, 'November')
monthMap.set(12, 'December')

const initialState = {
  filteredRows: [],
  selectedYear: '',
  selectedMonth: 'MONTH',
}

const filterRows = function (announcements, year, month) {
  let filteredRows = []
  if (announcements.length > 0) {
    const withYearFilter = announcements.filter(r => {
      const recordYear = new Date(r.creationDate).getUTCFullYear()
      return year === recordYear
    })

    if (month === 'MONTH') {
      filteredRows = withYearFilter
    } else {
      const withYearMonthFilter = withYearFilter.filter(r => {
        const date = new Date(r.creationDate)
        const recordMonth = date.toLocaleString('en-US', { month: 'long', timeZone: 'UTC' })
        return month === recordMonth
      })
      filteredRows = withYearMonthFilter
    }
  }

  return filteredRows
}

const getDistinctYears = function (announcements) {
  let years = new Set()
  if (announcements && announcements.length > 0) {
    for (let announcement of announcements) {
      years.add(new Date(announcement.creationDate).getUTCFullYear())
    }
  }
  return Array.from(years)
}

const filterByYear= function(announcement, year){
  return year && (new Date(announcement.creationDate).getUTCFullYear() === year)
}

const getDistinctMonths = function (announcements, year) {
  if (announcements.length > 0) {
    const filteredByYear = announcements.filter(a => filterByYear(a, year))
    const allMonths = filteredByYear.map(announce => new Date(announce.creationDate).getUTCMonth()+1)
    const distinctMonths = new Set(allMonths)
    const sortedDistinctMonths = Array.from(distinctMonths).sort((a, b) => a - b)
    const sortedMonthNames = sortedDistinctMonths.map(month => monthMap.get(month))
    sortedMonthNames.unshift('MONTH')
    return sortedMonthNames
  }
  return []
}

const getDefaultYear =  function(announcementList){
  const currentYear = new Date().getUTCFullYear()
  // return current year if there are announcements for it; if not, return greatest year
  for (let idx = 0; idx < announcementList.length; idx++) {
    let year = new Date(announcementList[idx].creationDate).getUTCFullYear();
    if (year === currentYear){
      return year;
    }
  }
  return new Date(announcementList[0].creationDate).getUTCFullYear();
}

const FannieArchiveAnnouncementsContainer = function (props) {
  const { announcements } = props
  //grabbing announcements
  const announcementList = announcements.managedResourceDataList

  useEffect(() => {

    const selectedYear = announcementList.length > 0 && getDefaultYear(announcementList)
    fannieArchivalResourceDispatcher({ type: 'CHANGE_YEAR', payload: { selectedYear } })
  }, [])

  useEffect(() => {

    const selectedYear = announcementList.length > 0 && getDefaultYear(announcementList)
    fannieArchivalResourceDispatcher({ type: 'CHANGE_YEAR', payload: { selectedYear } })
  }, [announcementList])

  const [filteredState, fannieArchivalResourceDispatcher] = useReducer(fannieArchivalResourceReducer, initialState)

  const monthOptions = getDistinctMonths(announcementList, filteredState.selectedYear).map(month => {
    const formatted = month !== 'MONTH' ? month : 'MONTH'
    return ({
      text: formatted,
      value: formatted,
      onClick: monthOrAll => fannieArchivalResourceDispatcher({ type: 'CHANGE_MONTH', payload: { selectedMonth: monthOrAll.value } }),
      disabled: formatted === filteredState.selectedMonth
    })
  })

  const yearsOptions = getDistinctYears(announcementList).map(yr => ({
      text: yr,
      value: yr,
      onClick: yearObj => fannieArchivalResourceDispatcher({ type: 'CHANGE_YEAR', payload: { selectedYear: yearObj.value } }),
      disabled: yr === filteredState.selectedYear
    }))

  function fannieArchivalResourceReducer (state, action) {

    if (action.type === 'CHANGE_MONTH') {
      const filteredMonthResources = filterRows(announcementList, state.selectedYear, action.payload.selectedMonth)
      return { filteredRows: filteredMonthResources, selectedYear: state.selectedYear, selectedMonth: action.payload.selectedMonth }
    } else if (action.type === 'CHANGE_YEAR') {
      const filteredYearResources = filterRows(announcementList, action.payload.selectedYear, 'MONTH')
      return { filteredRows: filteredYearResources, selectedYear: action.payload.selectedYear, selectedMonth: 'MONTH' }
    } else {
      return { ...state }
    }
  }

  const monthSelector = <ButtonDropdown className="month-selector" options={monthOptions}
                                        label={filteredState.selectedMonth === 'MONTH' ? 'MONTH' : filteredState.selectedMonth}/>

  const yearSelector = <ButtonDropdown className="year-selector" options={yearsOptions}
                                       label={filteredState.selectedYear}/>

  return (<div className="report-container" style={{ marginBottom: '10px', marginLeft:'35px' }}>
    <div >
      <span>{yearSelector} {monthSelector}</span>
    </div>
    <h2>Announcements Archive (Active and Past)</h2>

    <section className="ann">
      {filteredState.filteredRows.length > 0 ? <ul>{filteredState.filteredRows.map(row => <FannieArchivedAnnouncement record={{ ...row }} key={row.id}/>)}</ul> : <p>No announcements to display.</p>}
    </section>

  </div>)

}
export default FannieArchiveAnnouncementsContainer