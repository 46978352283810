import React from 'react';

import GroupedTable from 'common/table/GroupedTable';
import { formatDateMmYyyy } from 'common/util/common-formatters';
import { getTargetArray } from 'common/util/common-helpers';

import { rate } from 'www/util/www-formatters';

export default ({details, currentOrIssuance, monthly, month}) => {
  const key = `${currentOrIssuance || month}.distributionsMap.NextInterestRateAdjDate`;
  const data = currentOrIssuance ? details.data : monthly.data;
  const tableData = getTargetArray(data, key);

  const config = {
    className: 'distribution-table',
    columnEmptyText: '-',
    columns: [
      { key: 'label', formatter: formatDateMmYyyy, flex: 0.5 },
      { key: 'waNetLifeInterestRateCeiling', group: 'Net Life Interest Rate Ceiling', label: 'Weighted Average', formatter: rate, className: 'right' },
      { key: 'netLifeInterestRateCeilingHigh', group: 'Net Life Interest Rate Ceiling', label: 'High', formatter: rate, className: 'right' },
      { key: 'netLifeInterestRateCeilingLow', group: 'Net Life Interest Rate Ceiling', label: 'Low', formatter: rate, className: 'right' },
      { key: 'waLifeInterestRateCeiling', group: 'Life Interest Rate Ceiling', label: 'Weighted Average', formatter: rate, className: 'right' },
      { key: 'lifeInterestRateCeilingHigh', group: 'Life Interest Rate Ceiling', label: 'High', formatter: rate, className: 'right' },
      { key: 'lifeInterestRateCeilingLow', group: 'Life Interest Rate Ceiling', label: 'Low', formatter: rate, className: 'right' },
      { key: 'waNetLifeInterestRateFloor', group: 'Net Life Interest Rate Floor', label: 'Weighted Average', formatter: rate, className: 'right' },
      { key: 'netLifeInterestRateFloorHigh', group: 'Net Life Interest Rate Floor', label: 'High', formatter: rate, className: 'right' },
      { key: 'netLifeInterestRateFloorLow', group: 'Net Life Interest Rate Floor', label: 'Low', formatter: rate, className: 'right' },
      { key: 'waLifeInterestRateFloor', group: 'Life Interest Rate Floor', label: 'Weighted Average', formatter: rate, className: 'right' },
      { key: 'lifeInterestRateFloorHigh', group: 'Life Interest Rate Floor', label: 'High', formatter: rate, className: 'right' },
      { key: 'lifeInterestRateFloorLow', group: 'Life Interest Rate Floor', label: 'Low', formatter: rate, className: 'right' }
    ],
    emptyText: 'No data'
  };

  return <GroupedTable config={config} data={tableData} />;
}
