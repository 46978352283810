export const CURRENT_USER_REQUEST = 'CURRENT_USER_REQUEST';

export const CHECK_REGISTERED_REQUEST = 'CHECK_REGISTERED_REQUEST';
export const CHECK_REGISTERED_RESPONSE = 'CHECK_REGISTERED_RESPONSE';
export const CHECK_REGISTERED_ERROR = 'CHECK_REGISTERED_ERROR';

export const REGISTER_SHOW = 'REGISTER_SHOW';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_RESPONSE = 'REGISTER_RESPONSE';
export const REGISTER_ERROR = 'REGISTER_ERROR';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_RESPONSE = 'LOGIN_RESPONSE';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_INACTIVE_MESSAGE = 'LOGIN_INACTIVE_MESSAGE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_RESPONSE = 'LOGOUT_RESPONSE';
export const ADMIN_LOGOUT_REQUEST = 'ADMIN_LOGOUT_REQUEST';
export const ADMIN_LOGOUT_RESPONSE = 'ADMIN_LOGOUT_RESPONSE';

export const FORGOT_REQUEST = 'FORGOT_REQUEST';
export const FORGOT_RESPONSE = 'FORGOT_RESPONSE';
export const FORGOT_ERROR = 'FORGOT_ERROR';

export const SHOW_LOGIN = 'SHOW_LOGIN';
export const SHOW_LOGIN_MESSAGE = 'SHOW_LOGIN_MESSAGE';
export const HIDE_LOGIN = 'HIDE_LOGIN';

export const UPDATE_CREDENTIALS = 'UPDATE_CREDENTIALS';
export const UPDATE_CREDENTIALS_ERROR = 'UPDATE_CREDENTIALS_ERROR';
export const UPDATE_CREDENTIALS_SUCCESS = 'UPDATE_CREDENTIALS_SUCCESS';

export const IDLE_LOGOUT = 'IDLE_LOGOUT';
export const CANCEL_IDLE_LOGOUT = 'CANCEL_IDLE_LOGOUT';
export const KEEP_SESSION_ACTIVE = 'KEEP_SESSION_ACTIVE';

export const TERMS_ACCEPT = 'TERMS_ACCEPT';
export const TERMS_ACCEPTED = 'TERMS_ACCEPTED';

export const VALIDATE_CSRF_REQUEST = 'VALIDATE_CSRF_REQUEST';
export const VALIDATE_CSRF_RESPONSE = 'VALIDATE_CSRF_RESPONSE';
export const VALIDATE_CSRF_ERROR = 'VALIDATE_CSRF_ERROR';

export const REDIRECT_REQUEST = 'REDIRECT_REQUEST';

export function reidect() {
    return { type: SEARCH_NAVIGATION, query };
}

export function isLoggedIn () {
  return { type: CURRENT_USER_REQUEST };
}

export const login = (credentials) => ({ type: LOGIN_REQUEST, ...credentials });

export const logout = (idleStatus = false) => {
	if (idleStatus === true) {
		return { type: LOGOUT_REQUEST, idleMessage: 'Account has been logged out due to inactivity'};
	}
	return { type: LOGOUT_REQUEST };
};

export const validateCsrf = () => ({ type: VALIDATE_CSRF_REQUEST });

export const adminLogout = (idleStatus = false)=> {
    return { type: ADMIN_LOGOUT_REQUEST };
};

export const checkEmail = ({email}) => ({ type: CHECK_REGISTERED_REQUEST, email });

export const showRegister = () => ({ type: REGISTER_SHOW});
export const register = (userDetails) => ({ type: REGISTER_REQUEST, ...userDetails });

export const onForgotPwd = ({email, captchaResponse}) => ({ type: FORGOT_REQUEST, email, captchaResponse }); 
export const showLogin = () => ({ type: SHOW_LOGIN });

export const showLoginMessage = (message, pendingAction) => ({ type: SHOW_LOGIN_MESSAGE, message, pendingAction });

export const close = () => ({ type: HIDE_LOGIN });

export const idleLogout = () => ({ type: IDLE_LOGOUT });

export const cancelIdleLogout = () => ({ type: CANCEL_IDLE_LOGOUT });

export function updateCredentials(newCredentials) {
  return {type: UPDATE_CREDENTIALS, newCredentials};
}

export function keepSessionActive(idleTimeoutMinutes) {
  return {type: KEEP_SESSION_ACTIVE, idleTimeoutMinutes: idleTimeoutMinutes || 30};
}

export function acceptTerms() {
  return {type: TERMS_ACCEPT};
}