import React, {Component} from 'react';

import {currencyWithCents, currencyRoundDownCents, decimal, formatDateMmDdYyyy, formatDateMmYyyy, integer} from 'common/util/common-formatters';
import {getTargetArray} from 'common/util/common-helpers';

import {remicRate, factor, monthsToNextRate, rate, yesNoIndicator, formatNegativeAmortizationFactor} from 'www/util/www-formatters';
import {isRemicSecurity, getMonthSelector, isArmSecurity, isEsfPseudopool, isInterestOnly, isReperformingSecurity, isRPLModStep, isRPLModStepOrFixed, isShelfSecurity, isSingleSecurity, isL1} from 'www/util/www-helpers';
import {mapColumns} from 'www/util/labels';

import ENUMS from 'www/util/enumFormatters';
import {compareDateLessThan, getPlatformDate} from "../../../common/util/common-helpers";
import MaterialUIPickers from '../../../common/button/MaterialUIPickers';
const {initialFixedRatePeriodType} = ENUMS;

const fields = [
  { key: 'upb', formatter: currencyWithCents },
  { key: 'netRate' , formatter: rate, displayForShelf: true },
  { key: 'rate' , formatter: rate },
  { key: 'rmm' },
  { key: 'lnAge' },
  { key: 'term' , displayForShelf: true},
  { key: 'waLnAmt', formatter: currencyWithCents },
  { key: 'avgLnAmt', formatter: currencyWithCents },
  { key: 'ltv', formatter: integer, hideRplModStepFix: true },
  { key: 'cltv', formatter: integer, hideRplModStepFix: true  },
  { key: 'credScore', hideRplModStepFix: true  },
  { key: 'pctTpo', formatter: decimal, precision: 2,hideRplModStepFix: true },
  { key: 'dti', formatter: integer },
  { key: 'greenInd', formatter: yesNoIndicator, greenFlag: true },
  { key: 'socialInd', formatter: yesNoIndicator, socialFlag: true },
  /*Update name from Social to Mission with CR 3849 - name is set in secDetailsTable depending on missionEffectiveDate */
  { key: 'socialDensityScore', formatter: decimal, precision: 2 },
  { key: 'socialCriteriaShare', formatter: decimal, precision: 2 },
  { key: 'lnCount', formatter: integer },
  { key: 'seller', displayForShelf: true },
  { key: 'sllrCity' },
  { key: 'sllrSt' },
  { key: 'servicer' },
  { key: 'svcrCity' },
  { key: 'svcrSt' },
  { key: 'dlqPurchCount' },
  { key: 'dlqPurchUpb', formatter: currencyWithCents },
  { key: 'maxMat', formatter: formatDateMmYyyy },
  { key: 'eligResec', formatter: yesNoIndicator },
  { key: 'paymentDelayDays' },
  { key: 'interestOnlySecurityIndicator', formatter: yesNoIndicator },
  { key: 'prepaymentPenaltyIndicator', formatter: yesNoIndicator},
  { key: 'reducedMinimumServicingIndicator', formatter: yesNoIndicator },
  { key: 'corrInd', formatter: yesNoIndicator, displayForShelf: true },
  { key: 'notif', formatter: yesNoIndicator, displayForShelf: true },
  { key: 'status', displayForShelf: true}
];

const shelfFields = [
  { key: 'netRate' , formatter: rate },
  { key: 'term' },
  { key: 'seller' },
  { key: 'corrInd', formatter: yesNoIndicator },
  { key: 'notif', formatter: yesNoIndicator },
  { key: 'status'},
  { key: 'subType', displayForArm: true },
  { key: 'greenInd', formatter: yesNoIndicator, greenFlag: true }
];

const armFields = [
  { key: 'netRateAccrual', formatter: rate },
  { key: 'indexDesc' },
  { key: 'initialFixedRatePeriod', formatter: initialFixedRatePeriodType },
  { key: 'interestRateAdjusmentFrequency', formatter: integer }, //TODO: fix spelling when backend makes corresponding fix
  { key: 'initialInterestRateCapUpPercentage', formatter: rate },
  { key: 'initialInterestRateCapDownPercentage', formatter: rate },
  { key: 'periodicInterestRateCapUpPercentage', formatter: rate },
  { key: 'periodicInterestRateCapDownPercentage', formatter: rate },
  { key: 'waLifeInterestRateCeiling', formatter: rate },
  { key: 'waNetLifeInterestRateCeiling', formatter: rate },
  { key: 'waLifeInterestRateFloor', formatter: rate },
  { key: 'waNetLifeInterestRateFloor', formatter: rate },
  { key: 'firstRateAdjustmentDate', formatter: formatDateMmYyyy },
  { key: 'waMonthsToNextRateAdjustmentDate', formatter: monthsToNextRate },
  { key: 'interestRateLookback', formatter: integer },
  { key: 'waMortgageMargin', formatter: rate },
  { key: 'waMBSPCMargin', formatter: rate },
  { key: 'paymentRateAdjusmentFrequency', formatter: integer }, //TODO: fix spelling when backend makes corresponding fix
  { key: 'firstPaymentAdjustmentDate', formatter: formatDateMmYyyy},
  { key: 'paymentRateLookback', formatter: integer },
  { key: 'negativeAmortizationIndicator', formatter: yesNoIndicator },
  { key: 'negativeAmortizationFactor', formatter: formatNegativeAmortizationFactor },
  { key: 'waNegativeAmortizationLimit', formatter: rate },
  { key: 'subType', displayForShelf: true },
  { key: 'convertibilityIndicator', formatter: yesNoIndicator }

];

const rplFieldsStepFixed = [
  { key: 'waOriginationInterestRate', formatter: rate },
  { key: 'waOriginationLoanTerm' },
  { key: 'waOriginationMortgageLoanAmount', formatter: currencyWithCents },
  { key: 'avgOriginationMortgageLoanAmount', formatter: currencyWithCents },
  { key: 'waOriginationLTV', formatter: integer },
  { key: 'waOriginationCLTV', formatter: integer },
  { key: 'waOriginationDTI', formatter: integer },
  { key: 'waOriginationCreditScore' },
  { key: 'thirdPartyOriginationUPBPercent', formatter: decimal, precision: 2 }
];

const rplFieldsStep = [
  { key: 'initialStepFixedRatePeriod', formatter: initialFixedRatePeriodType },
  { key: 'stepRateAdjustmentFrequency' },
  { key: 'nextStepRateAdjustmentDate', formatter: formatDateMmYyyy },
  { key: 'waMonthstoNextStepRateAdjustment', formatter: integer },
  { key: 'periodicStepRateCapUpPercent',formatter: rate }
];

const armOrNonSingleSecurityFields = [
 { key: 'waMonthsToAmortization', formatter: integer , hideRpl: true}
];

const rplFieldsCommon = [
  { key: 'waESTDLTV', formatter: integer },
  { key: 'waUpdatedCreditScore' }
];

const remicFields = [
  { key: 'netInterestRate', formatter: remicRate, label: 'Net Interest Rate' },
  { key: 'principalDist', formatter: currencyWithCents, label: 'Principal Distribution' },
  { key: 'principalDistFactor', formatter: factor, label: 'Principal Distribution Factor' },
  { key: 'interestDist', formatter: currencyWithCents, label: 'Interest Distribution' },
  { key: 'interestDistFactor', formatter: factor, label: 'Interest Distribution Factor' },
  { key: 'totalDist', formatter: currencyWithCents, label: 'Total Distribution' },
  { key: 'scheduledDistributionDate', formatter: formatDateMmDdYyyy, label: 'Scheduled Distribution Date'},
  { key: 'accretionAmt', formatter: currencyWithCents, label: 'Accretion Amount' },
  { key: 'accretionFactor', formatter: factor, label: 'Accretion Factor' },
  { key: 'shortFallCurrent', formatter: currencyWithCents, label: 'Shortfall Current' },
  { key: 'shortFallCumulative', formatter: currencyWithCents, label: 'Shortfall Cumulative' },
  { key: 'indexValue', label: 'Index Value' },
  { key: 'notes', label: 'Notes' }
]

const CLASS_ID_KEY = 'waClassIdentifier';
const FIXED_VALUE = 'N';

const formatWaExcessStripPercent = (waExcessStripValue, column, row) => {
  let precision = 9;
  if (row[CLASS_ID_KEY] === FIXED_VALUE) {
    precision = 4;
  }

  return decimal(waExcessStripValue, {precision});
};

const esfFields = [

  // types and precisions based on sample Freddie report found online for ESF,
  // not specified in story or mapping file.
  //
  // http://www.freddiemac.com/mbs/docs/excess_servicing_fee_security_factor_file.txt
  //
  // ^^  Some precisions clarified in US19934

  { key: CLASS_ID_KEY},
  { key: 'waExcessStripPercent', formatter: formatWaExcessStripPercent },
  { key: 'maturity', formatter: formatDateMmYyyy }, // standard field
  { key: 'maxMat', formatter: formatDateMmYyyy },
  { key: 'upb', formatter: currencyWithCents, formatterIssuance: currencyRoundDownCents },
  { key: 'upbExs', formatter: currencyWithCents, formatterIssuance: currencyRoundDownCents },
  { key: 'netRate', formatter: rate },
  { key: 'waNetInterestRateExcess', formatter: rate },
  { key: 'rate', formatter: rate },
  { key: 'waInterestRateWithExcessContributionPercent', formatter: rate },
  { key: 'rmm', formatter: integer },
  { key: 'waRmmExcess', formatter: decimal, precision: 0 },
  { key: 'lnAge', formatter: integer },
  { key: 'waLoanAgeExcess', formatter: decimal, precision: 0 },
  { key: 'term', formatter: integer },
  { key: 'waLoanTermExcess', formatter: decimal, precision: 0 },
  { key: 'waLnAmt', formatter: currencyWithCents },
  { key: 'waLoanAmountExcess', formatter: currencyWithCents },
  { key: 'avgLnAmt', formatter: currencyWithCents},
  { key: 'ltv', formatter: integer },
  { key: 'waLtvExcess', formatter: decimal, precision: 0 },
  { key: 'cltv', formatter: integer },
  { key: 'waCltvExcess', formatter: decimal, precision: 0 },
  { key: 'dti', formatter: integer },
  { key: 'waDtiExcess', formatter: integer },
  { key: 'credScore', formatter: integer },
  { key: 'waCreditScoreExcess', formatter: decimal, precision: 0 },
  { key: 'lnCount', formatter: integer },
  { key: 'pctTpo', formatter: decimal, precision: 2 },
  { key: 'seller' },
  { key: 'servicer' },
  { key: 'dlqPurchCount', formatter: integer },
  { key: 'dlqPurchUpb', formatter: currencyWithCents }
];


mapColumns('attributeComparison', fields.filter(f => !f.hideRplModStepFix));
mapColumns('attributeComparisonNonRplStepFixed', fields.filter(f => f.hideRplModStepFix));
mapColumns('attributeComparisonArm', armFields);
mapColumns('attributeComparisonRpl', rplFieldsStepFixed);
mapColumns('attributeComparisonRplStep', rplFieldsStep);
mapColumns('attributeComparisonArmOrNotTbaCommon', armOrNonSingleSecurityFields);
mapColumns('attributeComparisonEsf', esfFields);
mapColumns('attributeComparisonRplCommon', rplFieldsCommon);
mapColumns('remicDetailsTable', remicFields);
mapColumns('attributeComparisonShelf', shelfFields);

export default class AttributeComparisonTable extends Component {
  state = {};

  onSelectItem = (idx, month) => {
    if (month) {
      this.setState({selectedMonth: month});
    }
  };

  componentDidMount() {
    const { dataFiles, getDataFiles, details } = this.props;

    if (!dataFiles.data) {
      getDataFiles(details.data.issuance);
    }
  }



  shouldComponentUpdate(nextProps, nextState) {
    const { cusip, fetchMonthlyData } = this.props;
    const { selectedMonth } = this.state;

    if (selectedMonth !== nextState.selectedMonth) {
      fetchMonthlyData(cusip, nextState.selectedMonth.name);
    }

    return true;
  }


  render() {
    const { details, dataFiles, monthly, settings } = this.props;
    const { issuance, current } = details.data;
    const { selectedMonth } = this.state;
    const isShelf = isShelfSecurity(issuance);
    const isRemic = isRemicSecurity(issuance);
    const isEsfP = isEsfPseudopool(issuance);
    const isArm = isArmSecurity(issuance) && issuance.armFields;
    
    const showMonthSelector = dataFiles.data && dataFiles.data.length > 1;
    const month = showMonthSelector && selectedMonth ? monthly.data[selectedMonth.name] : {};
    const {currentPlatformMonth, currentPlatformYear, currentPlatformDay} = settings;
    const platformDt = getPlatformDate(currentPlatformMonth, currentPlatformYear, currentPlatformDay);
    const hideGreenProgram = compareDateLessThan(platformDt, greenprogramEffectiveDate) && compareDateLessThan(issuance.issueDt, greenprogramEffectiveDate);
    const hideSocialIndicator = compareDateLessThan(platformDt, socialIndicatorEffectiveDate) && compareDateLessThan(issuance.issueDt, socialIndicatorEffectiveDate);

    let correctedFields = hideGreenProgram ? fields.filter(f => !f.greenFlag) : fields;
    correctedFields = hideSocialIndicator ? correctedFields.filter(f => !f.socialFlag) : fields;
    const correctedShelfFields = hideGreenProgram ? shelfFields.filter(f => !f.greenFlag) : shelfFields;
    let baseFields ;
    let filteredFields;
    updateSocialIndex(issuance, settings);

    if(isShelf){

      baseFields = isArm ? correctedShelfFields : correctedShelfFields.filter(f => !f.displayForArm);
      filteredFields = isSingleSecurity(issuance) ? baseFields.filter(f => !f.hideForSingleSecurity) : baseFields;
    } else if (!isRemic){
       baseFields = isRPLModStepOrFixed(issuance) ? correctedFields.filter(f => !f.hideRplModStepFix) :  correctedFields;
       filteredFields = isSingleSecurity(issuance) ? baseFields.filter(f => !f.hideForSingleSecurity) : baseFields;
    }
      let rows;

      if (!isShelf && (isEsfP || isRemic)) {
        
        // since we need props from multiple sub objects, flatten them rather than use the 'path' functionality
        const flatIssuance = 
          {
            ...(issuance || {}), 
            ...((issuance || {}).remicFields || {}),
            ...((issuance || {}).pseudopoolFields || {}),          
          };

          const flatMonth = month == undefined ? undefined :
          {
              ...(month || {}),
              ...((month || {}).remicFields || {}),
              ...((month || {}).pseudopoolFields || {}),
          };

        const flatCurrent = current == undefined ? undefined :
          {
            ...(current || {}), 
            ...((current || {}).remicFields || {}),
            ...((current || {}).pseudopoolFields || {}),          
          };

        rows = isRemic ? remicFields.map((field, idx) => secDetailsTable({field, idx, issuance: flatIssuance, month: flatMonth , current: flatCurrent, showMonthSelector, platformDt}))
            : esfFields.map((field, idx) => secDetailsTable({field, idx, issuance: flatIssuance, month: flatMonth , current: flatCurrent, showMonthSelector, platformDt}));
      } else {
        let idx = 0;
        rows = filteredFields.map((field) => secDetailsTable({field, idx:idx++, issuance, month, current, showMonthSelector, platformDt}));
        if(!isShelf )
         { 
          if (isArmSecurity(issuance)) {
            const armRows = armFields.map(field => secDetailsTable({field, idx:idx++, issuance, month, current, showMonthSelector, path: 'armFields', platformDt}));
            rows = rows.concat(armRows)
            if(isInterestOnly(issuance)) {
            	const armCommonRows = armOrNonSingleSecurityFields.map(field => secDetailsTable({field, idx:idx++, issuance, month, current, showMonthSelector, path: 'armFields', platformDt}));
            	rows = rows.concat(armCommonRows);
            }
          }
          if (isRPLModStepOrFixed(issuance)) {
            const rplRows = rplFieldsStepFixed.map(field => secDetailsTable({field, idx:idx++, issuance, month, current, showMonthSelector, path: 'rplFields', platformDt}));
            rows = rows.concat(rplRows);
            if (isRPLModStep(issuance)) {
    		    const rplStepRows = rplFieldsStep.map(field => secDetailsTable({field, idx:idx++, issuance, month, current, showMonthSelector, path: 'rplFields', platformDt}));
    		    rows = rows.concat(rplStepRows);
    		}
          }
          if (isReperformingSecurity(issuance)) {
            const rplRows = rplFieldsCommon.map(field => secDetailsTable({field, idx:idx++, issuance, month, current, showMonthSelector, path: 'rplFields', platformDt}));
            rows = rows.concat(rplRows);
          }else if (!isSingleSecurity(issuance) && isInterestOnly(issuance)) {
          	 const singleSecurityRows = armOrNonSingleSecurityFields.map(field => secDetailsTable({field, idx:idx++, issuance, month, current, showMonthSelector, path: 'armFields', platformDt}));
             rows = rows.concat(singleSecurityRows);
        }
      }
    }

    return (
      <ul className="simple-table attribute-table">
        <li className="header list-group-item">
          <div>Attribute</div>
          <div>Issuance</div>
          {showMonthSelector && MonthSelector(selectedMonth, dataFiles, this.onSelectItem)}
          {current && <div>Current</div>}
        </li>
        {rows}
      </ul>
    );

    function updateSocialIndex(issuance,  settings) {
      if (!issuance || issuance.issueDt !== socialSupressIssueDate)
        return;
      if (compareDateLessThan(platformDt, socialUnsupressPlatformDate)) {
        issuance.socialDensityScore  = '';
        issuance.socialCriteriaShare = '';
      }
    }
  }
}

const MonthSelector = (selectedMonth, dataFiles, onSelectItem) => {
  const option = getMonthSelector(selectedMonth, dataFiles, onSelectItem);
  return <div className="right" ><MaterialUIPickers {...option} onSelectItemHandler={onSelectItem} buttonClassName={'button-dropdown'} activeTab={'details'}/></div>;
};

const secDetailsColumn = (result, key, formatter, formatterParams, path) => {
  if (!result) {
    return '';
  }

  let value = path ? getTargetArray(result, `${path}.${key}`) : result[key];
  //Work around to show Convertibility Indicator from outside object as opposed to the path.
  //only updating paymentRateLookback as per story DIS-6930.
  // If you want to chagne for all attributes you could do
  // change if(!value && key !== 'paymentRateLookback'){
  // to  if(value === undefined){
  // Added check if not negativeAmortizationFactor for DIS-10562 to prevent UI from showing '-'
  // when the factor value is 0
  // This logic may need to be revisited as any value = 0 that is not paymentRateLookback or negativeAmortizationFactor
  // will enter this block - value may be set to undefined for nested array inside result such as ARM fields
    if(!value && key !== 'paymentRateLookback' && key !== 'negativeAmortizationFactor'){
      value = result[key];
    }
  if (!value && value !== 0) {
    return '-';
  } else {
    return formatter ? formatter(value, formatterParams || {}, result) : value;
  }
};

const secDetailsTable = ({field, idx, issuance, month, current, showMonthSelector = false, path, platformDt}) => {
  let label;
  if (field.key === 'socialDensityScore' && compareDateLessThan(platformDt, missionEffectiveDate)) {
    label = 'Social Density Score';
  } else if (field.key === 'socialCriteriaShare' && compareDateLessThan(platformDt, missionEffectiveDate)) {
    label = 'Social Criteria Share';
  } else {
    label = field.label;
  }

  if (field.displayForArm) {
    path = 'armFields'
  }

  return (
    <li key={idx} className={'list-group-item'}>
      <div>{label}</div>
      <div>{secDetailsColumn(issuance, field.key, field.formatterIssuance || field.formatter, field, path)}</div>
      {showMonthSelector && <div>{secDetailsColumn(month, field.key, field.formatter, field, path)}</div>}
      {current && <div>{secDetailsColumn(current, field.key, field.formatter, field, path)}</div>}
    </li>
  );
};
