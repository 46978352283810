import { reducerGenerator, reduceReducers } from 'common/util/redux';
import {FNM_ANNOUNCEMENTS_REQUEST, FNM_ANNOUNCEMENTS_RESPONSE} from 'www/actions/fannieResourcesActions';

const initialState = {
    managedResourceDataList:[],
    hasFetched: false,
    isFetching: false,
}

export default reducerGenerator(initialState, {
    [FNM_ANNOUNCEMENTS_REQUEST]: (state, action) => {
        return {...initialState, isFetching: true};
      },
    [FNM_ANNOUNCEMENTS_RESPONSE]: (state, action) => {
        return {...initialState, ...action.response, hasFetched: true, isFetching: false};
      },
        

});