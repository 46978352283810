import React, { Component } from 'react';

import { isDefined, switchByOrganization, updateDocumentTitle, documentDownload, getOrgCode } from 'www/util/www-helpers';

const { legalDocumentsProspectus, legalDocumentsTrustAgreements } = require('Config');

export default class Documents extends Component {

    UNSAFE_componentWillMount() {
        updateDocumentTitle(getTitle());
    }

    componentDidMount() {
        this.props.getDocuments();
    }

    render() {

        ///////////////
        //
        // Works by taking documents structure from settings
        // and merging with actual documents available based
        // on key and dates and displaying most recent of each
        // at top, then rest, if any, under a Historical documents
        // section
        //
        ///////////////

        const { documents } = this.props.settings;

        if (documents === undefined || documents.length === 0) {
            return null;
        }

        const legalDocumentsProspectusValues = legalDocumentsProspectus
            .map(legalDocumentsProspectusValues => ({
                value: legalDocumentsProspectusValues.code,
                display: legalDocumentsProspectusValues.label
            }));

        const legalDocumentsTrustAgreementsValues = legalDocumentsTrustAgreements
            .map(legalDocumentsTrustAgreementsValues => ({
                value: legalDocumentsTrustAgreementsValues.code,
                display: legalDocumentsTrustAgreementsValues.label
            }));

        return (
            <div className="content documents">
                <header>
                    <h1>{getTitle()}</h1>
                </header>
                <main>

                    <section>
                        <h2>Base Prospectuses</h2>
                        { getkeyValues(legalDocumentsProspectusValues) }
                    </section>
                    <section>
                        <h2>Trust Agreements</h2>
                        { getkeyValues(legalDocumentsTrustAgreementsValues) }
                    </section>
                </main>
            </div>
        );
    }
}

function getkeyValues(input) {
    const rows = [];
    if (input && input.length > 0) {
        input.forEach((row, rowIdx) => {
            rows.push(<li> <a href={row.display}> {row.value}</a> </li> );
        });
    }
    return rows ;
}

function getTitle() {
    return switchByOrganization('Base Prospectus & Trust Agreement', 'Offering Circular & Trust Agreement');
}
