import React from 'react';

export default () => window.location.hostname !== 'localhost' ? null : (

  <div className="dev-icon-list">
    <div className="dev-icon"><i className="fa fa-500px" /><span className="dev-icon-text">500px</span></div>
    <div className="dev-icon"><i className="fa fa-adjust" /><span className="dev-icon-text">adjust</span></div>
    <div className="dev-icon"><i className="fa fa-adn" /><span className="dev-icon-text">adn</span></div>
    <div className="dev-icon"><i className="fa fa-align-center" /><span className="dev-icon-text">align-center</span></div>
    <div className="dev-icon"><i className="fa fa-align-justify" /><span className="dev-icon-text">align-justify</span></div>
    <div className="dev-icon"><i className="fa fa-align-left" /><span className="dev-icon-text">align-left</span></div>
    <div className="dev-icon"><i className="fa fa-align-right" /><span className="dev-icon-text">align-right</span></div>
    <div className="dev-icon"><i className="fa fa-amazon" /><span className="dev-icon-text">amazon</span></div>
    <div className="dev-icon"><i className="fa fa-ambulance" /><span className="dev-icon-text">ambulance</span></div>
    <div className="dev-icon"><i className="fa fa-anchor" /><span className="dev-icon-text">anchor</span></div>
    <div className="dev-icon"><i className="fa fa-android" /><span className="dev-icon-text">android</span></div>
    <div className="dev-icon"><i className="fa fa-angellist" /><span className="dev-icon-text">angellist</span></div>
    <div className="dev-icon"><i className="fa fa-angle-double-down" /><span className="dev-icon-text">angle-double-down</span></div>
    <div className="dev-icon"><i className="fa fa-angle-double-left" /><span className="dev-icon-text">angle-double-left</span></div>
    <div className="dev-icon"><i className="fa fa-angle-double-right" /><span className="dev-icon-text">angle-double-right</span></div>
    <div className="dev-icon"><i className="fa fa-angle-double-up" /><span className="dev-icon-text">angle-double-up</span></div>
    <div className="dev-icon"><i className="fa fa-angle-down" /><span className="dev-icon-text">angle-down</span></div>
    <div className="dev-icon"><i className="fa fa-angle-left" /><span className="dev-icon-text">angle-left</span></div>
    <div className="dev-icon"><i className="fa fa-angle-right" /><span className="dev-icon-text">angle-right</span></div>
    <div className="dev-icon"><i className="fa fa-angle-up" /><span className="dev-icon-text">angle-up</span></div>
    <div className="dev-icon"><i className="fa fa-apple" /><span className="dev-icon-text">apple</span></div>
    <div className="dev-icon"><i className="fa fa-archive" /><span className="dev-icon-text">archive</span></div>
    <div className="dev-icon"><i className="fa fa-area-chart" /><span className="dev-icon-text">area-chart</span></div>
    <div className="dev-icon"><i className="fa fa-arrow-circle-down" /><span className="dev-icon-text">arrow-circle-down</span></div>
    <div className="dev-icon"><i className="fa fa-arrow-circle-left" /><span className="dev-icon-text">arrow-circle-left</span></div>
    <div className="dev-icon"><i className="fa fa-arrow-circle-o-down" /><span className="dev-icon-text">arrow-circle-o-down</span></div>
    <div className="dev-icon"><i className="fa fa-arrow-circle-o-left" /><span className="dev-icon-text">arrow-circle-o-left</span></div>
    <div className="dev-icon"><i className="fa fa-arrow-circle-o-right" /><span className="dev-icon-text">arrow-circle-o-right</span></div>
    <div className="dev-icon"><i className="fa fa-arrow-circle-o-up" /><span className="dev-icon-text">arrow-circle-o-up</span></div>
    <div className="dev-icon"><i className="fa fa-arrow-circle-right" /><span className="dev-icon-text">arrow-circle-right</span></div>
    <div className="dev-icon"><i className="fa fa-arrow-circle-up" /><span className="dev-icon-text">arrow-circle-up</span></div>
    <div className="dev-icon"><i className="fa fa-arrow-down" /><span className="dev-icon-text">arrow-down</span></div>
    <div className="dev-icon"><i className="fa fa-arrow-left" /><span className="dev-icon-text">arrow-left</span></div>
    <div className="dev-icon"><i className="fa fa-arrow-right" /><span className="dev-icon-text">arrow-right</span></div>
    <div className="dev-icon"><i className="fa fa-arrows" /><span className="dev-icon-text">arrows</span></div>
    <div className="dev-icon"><i className="fa fa-arrows-alt" /><span className="dev-icon-text">arrows-alt</span></div>
    <div className="dev-icon"><i className="fa fa-arrows-h" /><span className="dev-icon-text">arrows-h</span></div>
    <div className="dev-icon"><i className="fa fa-arrows-v" /><span className="dev-icon-text">arrows-v</span></div>
    <div className="dev-icon"><i className="fa fa-arrow-up" /><span className="dev-icon-text">arrow-up</span></div>
    <div className="dev-icon"><i className="fa fa-asterisk" /><span className="dev-icon-text">asterisk</span></div>
    <div className="dev-icon"><i className="fa fa-at" /><span className="dev-icon-text">at</span></div>
    <div className="dev-icon"><i className="fa fa-automobile" /><span className="dev-icon-text">automobile</span></div>
    <div className="dev-icon"><i className="fa fa-backward" /><span className="dev-icon-text">backward</span></div>
    <div className="dev-icon"><i className="fa fa-balance-scale" /><span className="dev-icon-text">balance-scale</span></div>
    <div className="dev-icon"><i className="fa fa-ban" /><span className="dev-icon-text">ban</span></div>
    <div className="dev-icon"><i className="fa fa-bank" /><span className="dev-icon-text">bank</span></div>
    <div className="dev-icon"><i className="fa fa-bar-chart" /><span className="dev-icon-text">bar-chart</span></div>
    <div className="dev-icon"><i className="fa fa-bar-chart-o" /><span className="dev-icon-text">bar-chart-o</span></div>
    <div className="dev-icon"><i className="fa fa-barcode" /><span className="dev-icon-text">barcode</span></div>
    <div className="dev-icon"><i className="fa fa-bars" /><span className="dev-icon-text">bars</span></div>
    <div className="dev-icon"><i className="fa fa-battery-0" /><span className="dev-icon-text">battery-0</span></div>
    <div className="dev-icon"><i className="fa fa-battery-1" /><span className="dev-icon-text">battery-1</span></div>
    <div className="dev-icon"><i className="fa fa-battery-2" /><span className="dev-icon-text">battery-2</span></div>
    <div className="dev-icon"><i className="fa fa-battery-3" /><span className="dev-icon-text">battery-3</span></div>
    <div className="dev-icon"><i className="fa fa-battery-4" /><span className="dev-icon-text">battery-4</span></div>
    <div className="dev-icon"><i className="fa fa-battery-empty" /><span className="dev-icon-text">battery-empty</span></div>
    <div className="dev-icon"><i className="fa fa-battery-full" /><span className="dev-icon-text">battery-full</span></div>
    <div className="dev-icon"><i className="fa fa-battery-half" /><span className="dev-icon-text">battery-half</span></div>
    <div className="dev-icon"><i className="fa fa-battery-quarter" /><span className="dev-icon-text">battery-quarter</span></div>
    <div className="dev-icon"><i className="fa fa-battery-three-quarters" /><span className="dev-icon-text">battery-three-quarters</span></div>
    <div className="dev-icon"><i className="fa fa-bed" /><span className="dev-icon-text">bed</span></div>
    <div className="dev-icon"><i className="fa fa-beer" /><span className="dev-icon-text">beer</span></div>
    <div className="dev-icon"><i className="fa fa-behance" /><span className="dev-icon-text">behance</span></div>
    <div className="dev-icon"><i className="fa fa-behance-square" /><span className="dev-icon-text">behance-square</span></div>
    <div className="dev-icon"><i className="fa fa-bell" /><span className="dev-icon-text">bell</span></div>
    <div className="dev-icon"><i className="fa fa-bell-o" /><span className="dev-icon-text">bell-o</span></div>
    <div className="dev-icon"><i className="fa fa-bell-slash" /><span className="dev-icon-text">bell-slash</span></div>
    <div className="dev-icon"><i className="fa fa-bell-slash-o" /><span className="dev-icon-text">bell-slash-o</span></div>
    <div className="dev-icon"><i className="fa fa-bicycle" /><span className="dev-icon-text">bicycle</span></div>
    <div className="dev-icon"><i className="fa fa-binoculars" /><span className="dev-icon-text">binoculars</span></div>
    <div className="dev-icon"><i className="fa fa-birthday-cake" /><span className="dev-icon-text">birthday-cake</span></div>
    <div className="dev-icon"><i className="fa fa-bitbucket" /><span className="dev-icon-text">bitbucket</span></div>
    <div className="dev-icon"><i className="fa fa-bitbucket-square" /><span className="dev-icon-text">bitbucket-square</span></div>
    <div className="dev-icon"><i className="fa fa-bitcoin" /><span className="dev-icon-text">bitcoin</span></div>
    <div className="dev-icon"><i className="fa fa-black-tie" /><span className="dev-icon-text">black-tie</span></div>
    <div className="dev-icon"><i className="fa fa-bluetooth" /><span className="dev-icon-text">bluetooth</span></div>
    <div className="dev-icon"><i className="fa fa-bluetooth-b" /><span className="dev-icon-text">bluetooth-b</span></div>
    <div className="dev-icon"><i className="fa fa-bold" /><span className="dev-icon-text">bold</span></div>
    <div className="dev-icon"><i className="fa fa-bolt" /><span className="dev-icon-text">bolt</span></div>
    <div className="dev-icon"><i className="fa fa-bomb" /><span className="dev-icon-text">bomb</span></div>
    <div className="dev-icon"><i className="fa fa-book" /><span className="dev-icon-text">book</span></div>
    <div className="dev-icon"><i className="fa fa-bookmark" /><span className="dev-icon-text">bookmark</span></div>
    <div className="dev-icon"><i className="fa fa-bookmark-o" /><span className="dev-icon-text">bookmark-o</span></div>
    <div className="dev-icon"><i className="fa fa-briefcase" /><span className="dev-icon-text">briefcase</span></div>
    <div className="dev-icon"><i className="fa fa-btc" /><span className="dev-icon-text">btc</span></div>
    <div className="dev-icon"><i className="fa fa-bug" /><span className="dev-icon-text">bug</span></div>
    <div className="dev-icon"><i className="fa fa-building" /><span className="dev-icon-text">building</span></div>
    <div className="dev-icon"><i className="fa fa-building-o" /><span className="dev-icon-text">building-o</span></div>
    <div className="dev-icon"><i className="fa fa-bullhorn" /><span className="dev-icon-text">bullhorn</span></div>
    <div className="dev-icon"><i className="fa fa-bullseye" /><span className="dev-icon-text">bullseye</span></div>
    <div className="dev-icon"><i className="fa fa-bus" /><span className="dev-icon-text">bus</span></div>
    <div className="dev-icon"><i className="fa fa-buysellads" /><span className="dev-icon-text">buysellads</span></div>
    <div className="dev-icon"><i className="fa fa-cab" /><span className="dev-icon-text">cab</span></div>
    <div className="dev-icon"><i className="fa fa-calculator" /><span className="dev-icon-text">calculator</span></div>
    <div className="dev-icon"><i className="fa fa-calendar" /><span className="dev-icon-text">calendar</span></div>
    <div className="dev-icon"><i className="fa fa-calendar-check-o" /><span className="dev-icon-text">calendar-check-o</span></div>
    <div className="dev-icon"><i className="fa fa-calendar-minus-o" /><span className="dev-icon-text">calendar-minus-o</span></div>
    <div className="dev-icon"><i className="fa fa-calendar-o" /><span className="dev-icon-text">calendar-o</span></div>
    <div className="dev-icon"><i className="fa fa-calendar-plus-o" /><span className="dev-icon-text">calendar-plus-o</span></div>
    <div className="dev-icon"><i className="fa fa-calendar-times-o" /><span className="dev-icon-text">calendar-times-o</span></div>
    <div className="dev-icon"><i className="fa fa-camera" /><span className="dev-icon-text">camera</span></div>
    <div className="dev-icon"><i className="fa fa-camera-retro" /><span className="dev-icon-text">camera-retro</span></div>
    <div className="dev-icon"><i className="fa fa-car" /><span className="dev-icon-text">car</span></div>
    <div className="dev-icon"><i className="fa fa-caret-down" /><span className="dev-icon-text">caret-down</span></div>
    <div className="dev-icon"><i className="fa fa-caret-left" /><span className="dev-icon-text">caret-left</span></div>
    <div className="dev-icon"><i className="fa fa-caret-right" /><span className="dev-icon-text">caret-right</span></div>
    <div className="dev-icon"><i className="fa fa-caret-square-o-down" /><span className="dev-icon-text">caret-square-o-down</span></div>
    <div className="dev-icon"><i className="fa fa-caret-square-o-left" /><span className="dev-icon-text">caret-square-o-left</span></div>
    <div className="dev-icon"><i className="fa fa-caret-square-o-right" /><span className="dev-icon-text">caret-square-o-right</span></div>
    <div className="dev-icon"><i className="fa fa-caret-square-o-up" /><span className="dev-icon-text">caret-square-o-up</span></div>
    <div className="dev-icon"><i className="fa fa-caret-up" /><span className="dev-icon-text">caret-up</span></div>
    <div className="dev-icon"><i className="fa fa-cart-arrow-down" /><span className="dev-icon-text">cart-arrow-down</span></div>
    <div className="dev-icon"><i className="fa fa-cart-plus" /><span className="dev-icon-text">cart-plus</span></div>
    <div className="dev-icon"><i className="fa fa-cc" /><span className="dev-icon-text">cc</span></div>
    <div className="dev-icon"><i className="fa fa-cc-amex" /><span className="dev-icon-text">cc-amex</span></div>
    <div className="dev-icon"><i className="fa fa-cc-diners-club" /><span className="dev-icon-text">cc-diners-club</span></div>
    <div className="dev-icon"><i className="fa fa-cc-discover" /><span className="dev-icon-text">cc-discover</span></div>
    <div className="dev-icon"><i className="fa fa-cc-jcb" /><span className="dev-icon-text">cc-jcb</span></div>
    <div className="dev-icon"><i className="fa fa-cc-mastercard" /><span className="dev-icon-text">cc-mastercard</span></div>
    <div className="dev-icon"><i className="fa fa-cc-paypal" /><span className="dev-icon-text">cc-paypal</span></div>
    <div className="dev-icon"><i className="fa fa-cc-stripe" /><span className="dev-icon-text">cc-stripe</span></div>
    <div className="dev-icon"><i className="fa fa-cc-visa" /><span className="dev-icon-text">cc-visa</span></div>
    <div className="dev-icon"><i className="fa fa-certificate" /><span className="dev-icon-text">certificate</span></div>
    <div className="dev-icon"><i className="fa fa-chain" /><span className="dev-icon-text">chain</span></div>
    <div className="dev-icon"><i className="fa fa-chain-broken" /><span className="dev-icon-text">chain-broken</span></div>
    <div className="dev-icon"><i className="fa fa-check" /><span className="dev-icon-text">check</span></div>
    <div className="dev-icon"><i className="fa fa-check-circle" /><span className="dev-icon-text">check-circle</span></div>
    <div className="dev-icon"><i className="fa fa-check-circle-o" /><span className="dev-icon-text">check-circle-o</span></div>
    <div className="dev-icon"><i className="fa fa-check-square" /><span className="dev-icon-text">check-square</span></div>
    <div className="dev-icon"><i className="fa fa-check-square-o" /><span className="dev-icon-text">check-square-o</span></div>
    <div className="dev-icon"><i className="fa fa-chevron-circle-down" /><span className="dev-icon-text">chevron-circle-down</span></div>
    <div className="dev-icon"><i className="fa fa-chevron-circle-left" /><span className="dev-icon-text">chevron-circle-left</span></div>
    <div className="dev-icon"><i className="fa fa-chevron-circle-right" /><span className="dev-icon-text">chevron-circle-right</span></div>
    <div className="dev-icon"><i className="fa fa-chevron-circle-up" /><span className="dev-icon-text">chevron-circle-up</span></div>
    <div className="dev-icon"><i className="fa fa-chevron-down" /><span className="dev-icon-text">chevron-down</span></div>
    <div className="dev-icon"><i className="fa fa-chevron-left" /><span className="dev-icon-text">chevron-left</span></div>
    <div className="dev-icon"><i className="fa fa-chevron-right" /><span className="dev-icon-text">chevron-right</span></div>
    <div className="dev-icon"><i className="fa fa-chevron-up" /><span className="dev-icon-text">chevron-up</span></div>
    <div className="dev-icon"><i className="fa fa-child" /><span className="dev-icon-text">child</span></div>
    <div className="dev-icon"><i className="fa fa-chrome" /><span className="dev-icon-text">chrome</span></div>
    <div className="dev-icon"><i className="fa fa-circle" /><span className="dev-icon-text">circle</span></div>
    <div className="dev-icon"><i className="fa fa-circle-o" /><span className="dev-icon-text">circle-o</span></div>
    <div className="dev-icon"><i className="fa fa-circle-o-notch" /><span className="dev-icon-text">circle-o-notch</span></div>
    <div className="dev-icon"><i className="fa fa-circle-thin" /><span className="dev-icon-text">circle-thin</span></div>
    <div className="dev-icon"><i className="fa fa-clipboard" /><span className="dev-icon-text">clipboard</span></div>
    <div className="dev-icon"><i className="fa fa-clock-o" /><span className="dev-icon-text">clock-o</span></div>
    <div className="dev-icon"><i className="fa fa-clone" /><span className="dev-icon-text">clone</span></div>
    <div className="dev-icon"><i className="fa fa-close" /><span className="dev-icon-text">close</span></div>
    <div className="dev-icon"><i className="fa fa-cloud" /><span className="dev-icon-text">cloud</span></div>
    <div className="dev-icon"><i className="fa fa-cloud-download" /><span className="dev-icon-text">cloud-download</span></div>
    <div className="dev-icon"><i className="fa fa-cloud-upload" /><span className="dev-icon-text">cloud-upload</span></div>
    <div className="dev-icon"><i className="fa fa-cny" /><span className="dev-icon-text">cny</span></div>
    <div className="dev-icon"><i className="fa fa-code" /><span className="dev-icon-text">code</span></div>
    <div className="dev-icon"><i className="fa fa-code-fork" /><span className="dev-icon-text">code-fork</span></div>
    <div className="dev-icon"><i className="fa fa-codepen" /><span className="dev-icon-text">codepen</span></div>
    <div className="dev-icon"><i className="fa fa-codiepie" /><span className="dev-icon-text">codiepie</span></div>
    <div className="dev-icon"><i className="fa fa-coffee" /><span className="dev-icon-text">coffee</span></div>
    <div className="dev-icon"><i className="fa fa-cog" /><span className="dev-icon-text">cog</span></div>
    <div className="dev-icon"><i className="fa fa-cogs" /><span className="dev-icon-text">cogs</span></div>
    <div className="dev-icon"><i className="fa fa-columns" /><span className="dev-icon-text">columns</span></div>
    <div className="dev-icon"><i className="fa fa-comment" /><span className="dev-icon-text">comment</span></div>
    <div className="dev-icon"><i className="fa fa-commenting" /><span className="dev-icon-text">commenting</span></div>
    <div className="dev-icon"><i className="fa fa-commenting-o" /><span className="dev-icon-text">commenting-o</span></div>
    <div className="dev-icon"><i className="fa fa-comment-o" /><span className="dev-icon-text">comment-o</span></div>
    <div className="dev-icon"><i className="fa fa-comments" /><span className="dev-icon-text">comments</span></div>
    <div className="dev-icon"><i className="fa fa-comments-o" /><span className="dev-icon-text">comments-o</span></div>
    <div className="dev-icon"><i className="fa fa-compass" /><span className="dev-icon-text">compass</span></div>
    <div className="dev-icon"><i className="fa fa-compress" /><span className="dev-icon-text">compress</span></div>
    <div className="dev-icon"><i className="fa fa-connectdevelop" /><span className="dev-icon-text">connectdevelop</span></div>
    <div className="dev-icon"><i className="fa fa-contao" /><span className="dev-icon-text">contao</span></div>
    <div className="dev-icon"><i className="fa fa-copy" /><span className="dev-icon-text">copy</span></div>
    <div className="dev-icon"><i className="fa fa-copyright" /><span className="dev-icon-text">copyright</span></div>
    <div className="dev-icon"><i className="fa fa-creative-commons" /><span className="dev-icon-text">creative-commons</span></div>
    <div className="dev-icon"><i className="fa fa-credit-card" /><span className="dev-icon-text">credit-card</span></div>
    <div className="dev-icon"><i className="fa fa-credit-card-alt" /><span className="dev-icon-text">credit-card-alt</span></div>
    <div className="dev-icon"><i className="fa fa-crop" /><span className="dev-icon-text">crop</span></div>
    <div className="dev-icon"><i className="fa fa-crosshairs" /><span className="dev-icon-text">crosshairs</span></div>
    <div className="dev-icon"><i className="fa fa-css3" /><span className="dev-icon-text">css3</span></div>
    <div className="dev-icon"><i className="fa fa-cube" /><span className="dev-icon-text">cube</span></div>
    <div className="dev-icon"><i className="fa fa-cubes" /><span className="dev-icon-text">cubes</span></div>
    <div className="dev-icon"><i className="fa fa-cut" /><span className="dev-icon-text">cut</span></div>
    <div className="dev-icon"><i className="fa fa-cutlery" /><span className="dev-icon-text">cutlery</span></div>
    <div className="dev-icon"><i className="fa fa-dashboard" /><span className="dev-icon-text">dashboard</span></div>
    <div className="dev-icon"><i className="fa fa-dashcube" /><span className="dev-icon-text">dashcube</span></div>
    <div className="dev-icon"><i className="fa fa-database" /><span className="dev-icon-text">database</span></div>
    <div className="dev-icon"><i className="fa fa-dedent" /><span className="dev-icon-text">dedent</span></div>
    <div className="dev-icon"><i className="fa fa-delicious" /><span className="dev-icon-text">delicious</span></div>
    <div className="dev-icon"><i className="fa fa-desktop" /><span className="dev-icon-text">desktop</span></div>
    <div className="dev-icon"><i className="fa fa-deviantart" /><span className="dev-icon-text">deviantart</span></div>
    <div className="dev-icon"><i className="fa fa-diamond" /><span className="dev-icon-text">diamond</span></div>
    <div className="dev-icon"><i className="fa fa-digg" /><span className="dev-icon-text">digg</span></div>
    <div className="dev-icon"><i className="fa fa-dollar" /><span className="dev-icon-text">dollar</span></div>
    <div className="dev-icon"><i className="fa fa-dot-circle-o" /><span className="dev-icon-text">dot-circle-o</span></div>
    <div className="dev-icon"><i className="fa fa-download" /><span className="dev-icon-text">download</span></div>
    <div className="dev-icon"><i className="fa fa-dribbble" /><span className="dev-icon-text">dribbble</span></div>
    <div className="dev-icon"><i className="fa fa-dropbox" /><span className="dev-icon-text">dropbox</span></div>
    <div className="dev-icon"><i className="fa fa-drupal" /><span className="dev-icon-text">drupal</span></div>
    <div className="dev-icon"><i className="fa fa-edge" /><span className="dev-icon-text">edge</span></div>
    <div className="dev-icon"><i className="fa fa-edit" /><span className="dev-icon-text">edit</span></div>
    <div className="dev-icon"><i className="fa fa-eject" /><span className="dev-icon-text">eject</span></div>
    <div className="dev-icon"><i className="fa fa-ellipsis-h" /><span className="dev-icon-text">ellipsis-h</span></div>
    <div className="dev-icon"><i className="fa fa-ellipsis-v" /><span className="dev-icon-text">ellipsis-v</span></div>
    <div className="dev-icon"><i className="fa fa-empire" /><span className="dev-icon-text">empire</span></div>
    <div className="dev-icon"><i className="fa fa-envelope" /><span className="dev-icon-text">envelope</span></div>
    <div className="dev-icon"><i className="fa fa-envelope-o" /><span className="dev-icon-text">envelope-o</span></div>
    <div className="dev-icon"><i className="fa fa-envelope-square" /><span className="dev-icon-text">envelope-square</span></div>
    <div className="dev-icon"><i className="fa fa-eraser" /><span className="dev-icon-text">eraser</span></div>
    <div className="dev-icon"><i className="fa fa-eur" /><span className="dev-icon-text">eur</span></div>
    <div className="dev-icon"><i className="fa fa-euro" /><span className="dev-icon-text">euro</span></div>
    <div className="dev-icon"><i className="fa fa-exchange" /><span className="dev-icon-text">exchange</span></div>
    <div className="dev-icon"><i className="fa fa-exclamation" /><span className="dev-icon-text">exclamation</span></div>
    <div className="dev-icon"><i className="fa fa-exclamation-circle" /><span className="dev-icon-text">exclamation-circle</span></div>
    <div className="dev-icon"><i className="fa fa-exclamation-triangle" /><span className="dev-icon-text">exclamation-triangle</span></div>
    <div className="dev-icon"><i className="fa fa-expand" /><span className="dev-icon-text">expand</span></div>
    <div className="dev-icon"><i className="fa fa-expeditedssl" /><span className="dev-icon-text">expeditedssl</span></div>
    <div className="dev-icon"><i className="fa fa-external-link" /><span className="dev-icon-text">external-link</span></div>
    <div className="dev-icon"><i className="fa fa-external-link-square" /><span className="dev-icon-text">external-link-square</span></div>
    <div className="dev-icon"><i className="fa fa-eye" /><span className="dev-icon-text">eye</span></div>
    <div className="dev-icon"><i className="fa fa-eyedropper" /><span className="dev-icon-text">eyedropper</span></div>
    <div className="dev-icon"><i className="fa fa-eye-slash" /><span className="dev-icon-text">eye-slash</span></div>
    <div className="dev-icon"><i className="fa fa-facebook" /><span className="dev-icon-text">facebook</span></div>
    <div className="dev-icon"><i className="fa fa-facebook-f" /><span className="dev-icon-text">facebook-f</span></div>
    <div className="dev-icon"><i className="fa fa-facebook-official" /><span className="dev-icon-text">facebook-official</span></div>
    <div className="dev-icon"><i className="fa fa-facebook-square" /><span className="dev-icon-text">facebook-square</span></div>
    <div className="dev-icon"><i className="fa fa-fast-backward" /><span className="dev-icon-text">fast-backward</span></div>
    <div className="dev-icon"><i className="fa fa-fast-forward" /><span className="dev-icon-text">fast-forward</span></div>
    <div className="dev-icon"><i className="fa fa-fax" /><span className="dev-icon-text">fax</span></div>
    <div className="dev-icon"><i className="fa fa-feed" /><span className="dev-icon-text">feed</span></div>
    <div className="dev-icon"><i className="fa fa-female" /><span className="dev-icon-text">female</span></div>
    <div className="dev-icon"><i className="fa fa-fighter-jet" /><span className="dev-icon-text">fighter-jet</span></div>
    <div className="dev-icon"><i className="fa fa-file" /><span className="dev-icon-text">file</span></div>
    <div className="dev-icon"><i className="fa fa-file-archive-o" /><span className="dev-icon-text">file-archive-o</span></div>
    <div className="dev-icon"><i className="fa fa-file-audio-o" /><span className="dev-icon-text">file-audio-o</span></div>
    <div className="dev-icon"><i className="fa fa-file-code-o" /><span className="dev-icon-text">file-code-o</span></div>
    <div className="dev-icon"><i className="fa fa-file-excel-o" /><span className="dev-icon-text">file-excel-o</span></div>
    <div className="dev-icon"><i className="fa fa-file-image-o" /><span className="dev-icon-text">file-image-o</span></div>
    <div className="dev-icon"><i className="fa fa-file-movie-o" /><span className="dev-icon-text">file-movie-o</span></div>
    <div className="dev-icon"><i className="fa fa-file-o" /><span className="dev-icon-text">file-o</span></div>
    <div className="dev-icon"><i className="fa fa-file-pdf-o" /><span className="dev-icon-text">file-pdf-o</span></div>
    <div className="dev-icon"><i className="fa fa-file-photo-o" /><span className="dev-icon-text">file-photo-o</span></div>
    <div className="dev-icon"><i className="fa fa-file-picture-o" /><span className="dev-icon-text">file-picture-o</span></div>
    <div className="dev-icon"><i className="fa fa-file-powerpoint-o" /><span className="dev-icon-text">file-powerpoint-o</span></div>
    <div className="dev-icon"><i className="fa fa-files-o" /><span className="dev-icon-text">files-o</span></div>
    <div className="dev-icon"><i className="fa fa-file-sound-o" /><span className="dev-icon-text">file-sound-o</span></div>
    <div className="dev-icon"><i className="fa fa-file-text" /><span className="dev-icon-text">file-text</span></div>
    <div className="dev-icon"><i className="fa fa-file-text-o" /><span className="dev-icon-text">file-text-o</span></div>
    <div className="dev-icon"><i className="fa fa-file-video-o" /><span className="dev-icon-text">file-video-o</span></div>
    <div className="dev-icon"><i className="fa fa-file-word-o" /><span className="dev-icon-text">file-word-o</span></div>
    <div className="dev-icon"><i className="fa fa-file-zip-o" /><span className="dev-icon-text">file-zip-o</span></div>
    <div className="dev-icon"><i className="fa fa-film" /><span className="dev-icon-text">film</span></div>
    <div className="dev-icon"><i className="fa fa-filter" /><span className="dev-icon-text">filter</span></div>
    <div className="dev-icon"><i className="fa fa-fire" /><span className="dev-icon-text">fire</span></div>
    <div className="dev-icon"><i className="fa fa-fire-extinguisher" /><span className="dev-icon-text">fire-extinguisher</span></div>
    <div className="dev-icon"><i className="fa fa-firefox" /><span className="dev-icon-text">firefox</span></div>
    <div className="dev-icon"><i className="fa fa-flag" /><span className="dev-icon-text">flag</span></div>
    <div className="dev-icon"><i className="fa fa-flag-checkered" /><span className="dev-icon-text">flag-checkered</span></div>
    <div className="dev-icon"><i className="fa fa-flag-o" /><span className="dev-icon-text">flag-o</span></div>
    <div className="dev-icon"><i className="fa fa-flash" /><span className="dev-icon-text">flash</span></div>
    <div className="dev-icon"><i className="fa fa-flask" /><span className="dev-icon-text">flask</span></div>
    <div className="dev-icon"><i className="fa fa-flickr" /><span className="dev-icon-text">flickr</span></div>
    <div className="dev-icon"><i className="fa fa-floppy-o" /><span className="dev-icon-text">floppy-o</span></div>
    <div className="dev-icon"><i className="fa fa-folder" /><span className="dev-icon-text">folder</span></div>
    <div className="dev-icon"><i className="fa fa-folder-o" /><span className="dev-icon-text">folder-o</span></div>
    <div className="dev-icon"><i className="fa fa-folder-open" /><span className="dev-icon-text">folder-open</span></div>
    <div className="dev-icon"><i className="fa fa-folder-open-o" /><span className="dev-icon-text">folder-open-o</span></div>
    <div className="dev-icon"><i className="fa fa-font" /><span className="dev-icon-text">font</span></div>
    <div className="dev-icon"><i className="fa fa-fonticons" /><span className="dev-icon-text">fonticons</span></div>
    <div className="dev-icon"><i className="fa fa-fort-awesome" /><span className="dev-icon-text">fort-awesome</span></div>
    <div className="dev-icon"><i className="fa fa-forumbee" /><span className="dev-icon-text">forumbee</span></div>
    <div className="dev-icon"><i className="fa fa-forward" /><span className="dev-icon-text">forward</span></div>
    <div className="dev-icon"><i className="fa fa-foursquare" /><span className="dev-icon-text">foursquare</span></div>
    <div className="dev-icon"><i className="fa fa-frown-o" /><span className="dev-icon-text">frown-o</span></div>
    <div className="dev-icon"><i className="fa fa-futbol-o" /><span className="dev-icon-text">futbol-o</span></div>
    <div className="dev-icon"><i className="fa fa-gamepad" /><span className="dev-icon-text">gamepad</span></div>
    <div className="dev-icon"><i className="fa fa-gavel" /><span className="dev-icon-text">gavel</span></div>
    <div className="dev-icon"><i className="fa fa-gbp" /><span className="dev-icon-text">gbp</span></div>
    <div className="dev-icon"><i className="fa fa-ge" /><span className="dev-icon-text">ge</span></div>
    <div className="dev-icon"><i className="fa fa-gear" /><span className="dev-icon-text">gear</span></div>
    <div className="dev-icon"><i className="fa fa-gears" /><span className="dev-icon-text">gears</span></div>
    <div className="dev-icon"><i className="fa fa-genderless" /><span className="dev-icon-text">genderless</span></div>
    <div className="dev-icon"><i className="fa fa-get-pocket" /><span className="dev-icon-text">get-pocket</span></div>
    <div className="dev-icon"><i className="fa fa-gg" /><span className="dev-icon-text">gg</span></div>
    <div className="dev-icon"><i className="fa fa-gg-circle" /><span className="dev-icon-text">gg-circle</span></div>
    <div className="dev-icon"><i className="fa fa-gift" /><span className="dev-icon-text">gift</span></div>
    <div className="dev-icon"><i className="fa fa-git" /><span className="dev-icon-text">git</span></div>
    <div className="dev-icon"><i className="fa fa-github" /><span className="dev-icon-text">github</span></div>
    <div className="dev-icon"><i className="fa fa-github-alt" /><span className="dev-icon-text">github-alt</span></div>
    <div className="dev-icon"><i className="fa fa-github-square" /><span className="dev-icon-text">github-square</span></div>
    <div className="dev-icon"><i className="fa fa-git-square" /><span className="dev-icon-text">git-square</span></div>
    <div className="dev-icon"><i className="fa fa-gittip" /><span className="dev-icon-text">gittip</span></div>
    <div className="dev-icon"><i className="fa fa-glass" /><span className="dev-icon-text">glass</span></div>
    <div className="dev-icon"><i className="fa fa-globe" /><span className="dev-icon-text">globe</span></div>
    <div className="dev-icon"><i className="fa fa-google" /><span className="dev-icon-text">google</span></div>
    <div className="dev-icon"><i className="fa fa-google-plus" /><span className="dev-icon-text">google-plus</span></div>
    <div className="dev-icon"><i className="fa fa-google-plus-square" /><span className="dev-icon-text">google-plus-square</span></div>
    <div className="dev-icon"><i className="fa fa-google-wallet" /><span className="dev-icon-text">google-wallet</span></div>
    <div className="dev-icon"><i className="fa fa-graduation-cap" /><span className="dev-icon-text">graduation-cap</span></div>
    <div className="dev-icon"><i className="fa fa-gratipay" /><span className="dev-icon-text">gratipay</span></div>
    <div className="dev-icon"><i className="fa fa-group" /><span className="dev-icon-text">group</span></div>
    <div className="dev-icon"><i className="fa fa-hacker-news" /><span className="dev-icon-text">hacker-news</span></div>
    <div className="dev-icon"><i className="fa fa-hand-grab-o" /><span className="dev-icon-text">hand-grab-o</span></div>
    <div className="dev-icon"><i className="fa fa-hand-lizard-o" /><span className="dev-icon-text">hand-lizard-o</span></div>
    <div className="dev-icon"><i className="fa fa-hand-o-down" /><span className="dev-icon-text">hand-o-down</span></div>
    <div className="dev-icon"><i className="fa fa-hand-o-left" /><span className="dev-icon-text">hand-o-left</span></div>
    <div className="dev-icon"><i className="fa fa-hand-o-right" /><span className="dev-icon-text">hand-o-right</span></div>
    <div className="dev-icon"><i className="fa fa-hand-o-up" /><span className="dev-icon-text">hand-o-up</span></div>
    <div className="dev-icon"><i className="fa fa-hand-paper-o" /><span className="dev-icon-text">hand-paper-o</span></div>
    <div className="dev-icon"><i className="fa fa-hand-peace-o" /><span className="dev-icon-text">hand-peace-o</span></div>
    <div className="dev-icon"><i className="fa fa-hand-pointer-o" /><span className="dev-icon-text">hand-pointer-o</span></div>
    <div className="dev-icon"><i className="fa fa-hand-rock-o" /><span className="dev-icon-text">hand-rock-o</span></div>
    <div className="dev-icon"><i className="fa fa-hand-scissors-o" /><span className="dev-icon-text">hand-scissors-o</span></div>
    <div className="dev-icon"><i className="fa fa-hand-spock-o" /><span className="dev-icon-text">hand-spock-o</span></div>
    <div className="dev-icon"><i className="fa fa-hand-stop-o" /><span className="dev-icon-text">hand-stop-o</span></div>
    <div className="dev-icon"><i className="fa fa-hashtag" /><span className="dev-icon-text">hashtag</span></div>
    <div className="dev-icon"><i className="fa fa-hdd-o" /><span className="dev-icon-text">hdd-o</span></div>
    <div className="dev-icon"><i className="fa fa-header" /><span className="dev-icon-text">header</span></div>
    <div className="dev-icon"><i className="fa fa-headphones" /><span className="dev-icon-text">headphones</span></div>
    <div className="dev-icon"><i className="fa fa-heart" /><span className="dev-icon-text">heart</span></div>
    <div className="dev-icon"><i className="fa fa-heartbeat" /><span className="dev-icon-text">heartbeat</span></div>
    <div className="dev-icon"><i className="fa fa-heart-o" /><span className="dev-icon-text">heart-o</span></div>
    <div className="dev-icon"><i className="fa fa-history" /><span className="dev-icon-text">history</span></div>
    <div className="dev-icon"><i className="fa fa-home" /><span className="dev-icon-text">home</span></div>
    <div className="dev-icon"><i className="fa fa-hospital-o" /><span className="dev-icon-text">hospital-o</span></div>
    <div className="dev-icon"><i className="fa fa-hotel" /><span className="dev-icon-text">hotel</span></div>
    <div className="dev-icon"><i className="fa fa-hourglass" /><span className="dev-icon-text">hourglass</span></div>
    <div className="dev-icon"><i className="fa fa-hourglass-1" /><span className="dev-icon-text">hourglass-1</span></div>
    <div className="dev-icon"><i className="fa fa-hourglass-2" /><span className="dev-icon-text">hourglass-2</span></div>
    <div className="dev-icon"><i className="fa fa-hourglass-3" /><span className="dev-icon-text">hourglass-3</span></div>
    <div className="dev-icon"><i className="fa fa-hourglass-end" /><span className="dev-icon-text">hourglass-end</span></div>
    <div className="dev-icon"><i className="fa fa-hourglass-half" /><span className="dev-icon-text">hourglass-half</span></div>
    <div className="dev-icon"><i className="fa fa-hourglass-o" /><span className="dev-icon-text">hourglass-o</span></div>
    <div className="dev-icon"><i className="fa fa-hourglass-start" /><span className="dev-icon-text">hourglass-start</span></div>
    <div className="dev-icon"><i className="fa fa-houzz" /><span className="dev-icon-text">houzz</span></div>
    <div className="dev-icon"><i className="fa fa-h-square" /><span className="dev-icon-text">h-square</span></div>
    <div className="dev-icon"><i className="fa fa-html5" /><span className="dev-icon-text">html5</span></div>
    <div className="dev-icon"><i className="fa fa-i-cursor" /><span className="dev-icon-text">i-cursor</span></div>
    <div className="dev-icon"><i className="fa fa-ils" /><span className="dev-icon-text">ils</span></div>
    <div className="dev-icon"><i className="fa fa-image" /><span className="dev-icon-text">image</span></div>
    <div className="dev-icon"><i className="fa fa-inbox" /><span className="dev-icon-text">inbox</span></div>
    <div className="dev-icon"><i className="fa fa-indent" /><span className="dev-icon-text">indent</span></div>
    <div className="dev-icon"><i className="fa fa-industry" /><span className="dev-icon-text">industry</span></div>
    <div className="dev-icon"><i className="fa fa-info" /><span className="dev-icon-text">info</span></div>
    <div className="dev-icon"><i className="fa fa-info-circle" /><span className="dev-icon-text">info-circle</span></div>
    <div className="dev-icon"><i className="fa fa-inr" /><span className="dev-icon-text">inr</span></div>
    <div className="dev-icon"><i className="fa fa-instagram" /><span className="dev-icon-text">instagram</span></div>
    <div className="dev-icon"><i className="fa fa-institution" /><span className="dev-icon-text">institution</span></div>
    <div className="dev-icon"><i className="fa fa-internet-explorer" /><span className="dev-icon-text">internet-explorer</span></div>
    <div className="dev-icon"><i className="fa fa-intersex" /><span className="dev-icon-text">intersex</span></div>
    <div className="dev-icon"><i className="fa fa-ioxhost" /><span className="dev-icon-text">ioxhost</span></div>
    <div className="dev-icon"><i className="fa fa-italic" /><span className="dev-icon-text">italic</span></div>
    <div className="dev-icon"><i className="fa fa-joomla" /><span className="dev-icon-text">joomla</span></div>
    <div className="dev-icon"><i className="fa fa-jpy" /><span className="dev-icon-text">jpy</span></div>
    <div className="dev-icon"><i className="fa fa-jsfiddle" /><span className="dev-icon-text">jsfiddle</span></div>
    <div className="dev-icon"><i className="fa fa-key" /><span className="dev-icon-text">key</span></div>
    <div className="dev-icon"><i className="fa fa-keyboard-o" /><span className="dev-icon-text">keyboard-o</span></div>
    <div className="dev-icon"><i className="fa fa-krw" /><span className="dev-icon-text">krw</span></div>
    <div className="dev-icon"><i className="fa fa-language" /><span className="dev-icon-text">language</span></div>
    <div className="dev-icon"><i className="fa fa-laptop" /><span className="dev-icon-text">laptop</span></div>
    <div className="dev-icon"><i className="fa fa-lastfm" /><span className="dev-icon-text">lastfm</span></div>
    <div className="dev-icon"><i className="fa fa-lastfm-square" /><span className="dev-icon-text">lastfm-square</span></div>
    <div className="dev-icon"><i className="fa fa-leaf" /><span className="dev-icon-text">leaf</span></div>
    <div className="dev-icon"><i className="fa fa-leanpub" /><span className="dev-icon-text">leanpub</span></div>
    <div className="dev-icon"><i className="fa fa-legal" /><span className="dev-icon-text">legal</span></div>
    <div className="dev-icon"><i className="fa fa-lemon-o" /><span className="dev-icon-text">lemon-o</span></div>
    <div className="dev-icon"><i className="fa fa-level-down" /><span className="dev-icon-text">level-down</span></div>
    <div className="dev-icon"><i className="fa fa-level-up" /><span className="dev-icon-text">level-up</span></div>
    <div className="dev-icon"><i className="fa fa-life-bouy" /><span className="dev-icon-text">life-bouy</span></div>
    <div className="dev-icon"><i className="fa fa-life-buoy" /><span className="dev-icon-text">life-buoy</span></div>
    <div className="dev-icon"><i className="fa fa-life-ring" /><span className="dev-icon-text">life-ring</span></div>
    <div className="dev-icon"><i className="fa fa-life-saver" /><span className="dev-icon-text">life-saver</span></div>
    <div className="dev-icon"><i className="fa fa-lightbulb-o" /><span className="dev-icon-text">lightbulb-o</span></div>
    <div className="dev-icon"><i className="fa fa-line-chart" /><span className="dev-icon-text">line-chart</span></div>
    <div className="dev-icon"><i className="fa fa-link" /><span className="dev-icon-text">link</span></div>
    <div className="dev-icon"><i className="fa fa-linkedin" /><span className="dev-icon-text">linkedin</span></div>
    <div className="dev-icon"><i className="fa fa-linkedin-square" /><span className="dev-icon-text">linkedin-square</span></div>
    <div className="dev-icon"><i className="fa fa-linux" /><span className="dev-icon-text">linux</span></div>
    <div className="dev-icon"><i className="fa fa-list" /><span className="dev-icon-text">list</span></div>
    <div className="dev-icon"><i className="fa fa-list-alt" /><span className="dev-icon-text">list-alt</span></div>
    <div className="dev-icon"><i className="fa fa-list-ol" /><span className="dev-icon-text">list-ol</span></div>
    <div className="dev-icon"><i className="fa fa-list-ul" /><span className="dev-icon-text">list-ul</span></div>
    <div className="dev-icon"><i className="fa fa-location-arrow" /><span className="dev-icon-text">location-arrow</span></div>
    <div className="dev-icon"><i className="fa fa-lock" /><span className="dev-icon-text">lock</span></div>
    <div className="dev-icon"><i className="fa fa-long-arrow-down" /><span className="dev-icon-text">long-arrow-down</span></div>
    <div className="dev-icon"><i className="fa fa-long-arrow-left" /><span className="dev-icon-text">long-arrow-left</span></div>
    <div className="dev-icon"><i className="fa fa-long-arrow-right" /><span className="dev-icon-text">long-arrow-right</span></div>
    <div className="dev-icon"><i className="fa fa-long-arrow-up" /><span className="dev-icon-text">long-arrow-up</span></div>
    <div className="dev-icon"><i className="fa fa-magic" /><span className="dev-icon-text">magic</span></div>
    <div className="dev-icon"><i className="fa fa-magnet" /><span className="dev-icon-text">magnet</span></div>
    <div className="dev-icon"><i className="fa fa-mail-forward" /><span className="dev-icon-text">mail-forward</span></div>
    <div className="dev-icon"><i className="fa fa-mail-reply" /><span className="dev-icon-text">mail-reply</span></div>
    <div className="dev-icon"><i className="fa fa-mail-reply-all" /><span className="dev-icon-text">mail-reply-all</span></div>
    <div className="dev-icon"><i className="fa fa-male" /><span className="dev-icon-text">male</span></div>
    <div className="dev-icon"><i className="fa fa-map" /><span className="dev-icon-text">map</span></div>
    <div className="dev-icon"><i className="fa fa-map-marker" /><span className="dev-icon-text">map-marker</span></div>
    <div className="dev-icon"><i className="fa fa-map-o" /><span className="dev-icon-text">map-o</span></div>
    <div className="dev-icon"><i className="fa fa-map-pin" /><span className="dev-icon-text">map-pin</span></div>
    <div className="dev-icon"><i className="fa fa-map-signs" /><span className="dev-icon-text">map-signs</span></div>
    <div className="dev-icon"><i className="fa fa-mars" /><span className="dev-icon-text">mars</span></div>
    <div className="dev-icon"><i className="fa fa-mars-double" /><span className="dev-icon-text">mars-double</span></div>
    <div className="dev-icon"><i className="fa fa-mars-stroke" /><span className="dev-icon-text">mars-stroke</span></div>
    <div className="dev-icon"><i className="fa fa-mars-stroke-h" /><span className="dev-icon-text">mars-stroke-h</span></div>
    <div className="dev-icon"><i className="fa fa-mars-stroke-v" /><span className="dev-icon-text">mars-stroke-v</span></div>
    <div className="dev-icon"><i className="fa fa-maxcdn" /><span className="dev-icon-text">maxcdn</span></div>
    <div className="dev-icon"><i className="fa fa-meanpath" /><span className="dev-icon-text">meanpath</span></div>
    <div className="dev-icon"><i className="fa fa-medium" /><span className="dev-icon-text">medium</span></div>
    <div className="dev-icon"><i className="fa fa-medkit" /><span className="dev-icon-text">medkit</span></div>
    <div className="dev-icon"><i className="fa fa-meh-o" /><span className="dev-icon-text">meh-o</span></div>
    <div className="dev-icon"><i className="fa fa-mercury" /><span className="dev-icon-text">mercury</span></div>
    <div className="dev-icon"><i className="fa fa-microphone" /><span className="dev-icon-text">microphone</span></div>
    <div className="dev-icon"><i className="fa fa-microphone-slash" /><span className="dev-icon-text">microphone-slash</span></div>
    <div className="dev-icon"><i className="fa fa-minus" /><span className="dev-icon-text">minus</span></div>
    <div className="dev-icon"><i className="fa fa-minus-circle" /><span className="dev-icon-text">minus-circle</span></div>
    <div className="dev-icon"><i className="fa fa-minus-square" /><span className="dev-icon-text">minus-square</span></div>
    <div className="dev-icon"><i className="fa fa-minus-square-o" /><span className="dev-icon-text">minus-square-o</span></div>
    <div className="dev-icon"><i className="fa fa-mixcloud" /><span className="dev-icon-text">mixcloud</span></div>
    <div className="dev-icon"><i className="fa fa-mobile" /><span className="dev-icon-text">mobile</span></div>
    <div className="dev-icon"><i className="fa fa-mobile-phone" /><span className="dev-icon-text">mobile-phone</span></div>
    <div className="dev-icon"><i className="fa fa-modx" /><span className="dev-icon-text">modx</span></div>
    <div className="dev-icon"><i className="fa fa-money" /><span className="dev-icon-text">money</span></div>
    <div className="dev-icon"><i className="fa fa-moon-o" /><span className="dev-icon-text">moon-o</span></div>
    <div className="dev-icon"><i className="fa fa-mortar-board" /><span className="dev-icon-text">mortar-board</span></div>
    <div className="dev-icon"><i className="fa fa-motorcycle" /><span className="dev-icon-text">motorcycle</span></div>
    <div className="dev-icon"><i className="fa fa-mouse-pointer" /><span className="dev-icon-text">mouse-pointer</span></div>
    <div className="dev-icon"><i className="fa fa-music" /><span className="dev-icon-text">music</span></div>
    <div className="dev-icon"><i className="fa fa-navicon" /><span className="dev-icon-text">navicon</span></div>
    <div className="dev-icon"><i className="fa fa-neuter" /><span className="dev-icon-text">neuter</span></div>
    <div className="dev-icon"><i className="fa fa-newspaper-o" /><span className="dev-icon-text">newspaper-o</span></div>
    <div className="dev-icon"><i className="fa fa-object-group" /><span className="dev-icon-text">object-group</span></div>
    <div className="dev-icon"><i className="fa fa-object-ungroup" /><span className="dev-icon-text">object-ungroup</span></div>
    <div className="dev-icon"><i className="fa fa-odnoklassniki" /><span className="dev-icon-text">odnoklassniki</span></div>
    <div className="dev-icon"><i className="fa fa-odnoklassniki-square" /><span className="dev-icon-text">odnoklassniki-square</span></div>
    <div className="dev-icon"><i className="fa fa-opencart" /><span className="dev-icon-text">opencart</span></div>
    <div className="dev-icon"><i className="fa fa-openid" /><span className="dev-icon-text">openid</span></div>
    <div className="dev-icon"><i className="fa fa-opera" /><span className="dev-icon-text">opera</span></div>
    <div className="dev-icon"><i className="fa fa-optin-monster" /><span className="dev-icon-text">optin-monster</span></div>
    <div className="dev-icon"><i className="fa fa-outdent" /><span className="dev-icon-text">outdent</span></div>
    <div className="dev-icon"><i className="fa fa-pagelines" /><span className="dev-icon-text">pagelines</span></div>
    <div className="dev-icon"><i className="fa fa-paint-brush" /><span className="dev-icon-text">paint-brush</span></div>
    <div className="dev-icon"><i className="fa fa-paperclip" /><span className="dev-icon-text">paperclip</span></div>
    <div className="dev-icon"><i className="fa fa-paper-plane" /><span className="dev-icon-text">paper-plane</span></div>
    <div className="dev-icon"><i className="fa fa-paper-plane-o" /><span className="dev-icon-text">paper-plane-o</span></div>
    <div className="dev-icon"><i className="fa fa-paragraph" /><span className="dev-icon-text">paragraph</span></div>
    <div className="dev-icon"><i className="fa fa-paste" /><span className="dev-icon-text">paste</span></div>
    <div className="dev-icon"><i className="fa fa-pause" /><span className="dev-icon-text">pause</span></div>
    <div className="dev-icon"><i className="fa fa-pause-circle" /><span className="dev-icon-text">pause-circle</span></div>
    <div className="dev-icon"><i className="fa fa-pause-circle-o" /><span className="dev-icon-text">pause-circle-o</span></div>
    <div className="dev-icon"><i className="fa fa-paw" /><span className="dev-icon-text">paw</span></div>
    <div className="dev-icon"><i className="fa fa-paypal" /><span className="dev-icon-text">paypal</span></div>
    <div className="dev-icon"><i className="fa fa-pencil" /><span className="dev-icon-text">pencil</span></div>
    <div className="dev-icon"><i className="fa fa-pencil-square" /><span className="dev-icon-text">pencil-square</span></div>
    <div className="dev-icon"><i className="fa fa-pencil-square-o" /><span className="dev-icon-text">pencil-square-o</span></div>
    <div className="dev-icon"><i className="fa fa-percent" /><span className="dev-icon-text">percent</span></div>
    <div className="dev-icon"><i className="fa fa-phone" /><span className="dev-icon-text">phone</span></div>
    <div className="dev-icon"><i className="fa fa-phone-square" /><span className="dev-icon-text">phone-square</span></div>
    <div className="dev-icon"><i className="fa fa-photo" /><span className="dev-icon-text">photo</span></div>
    <div className="dev-icon"><i className="fa fa-picture-o" /><span className="dev-icon-text">picture-o</span></div>
    <div className="dev-icon"><i className="fa fa-pie-chart" /><span className="dev-icon-text">pie-chart</span></div>
    <div className="dev-icon"><i className="fa fa-pied-piper" /><span className="dev-icon-text">pied-piper</span></div>
    <div className="dev-icon"><i className="fa fa-pied-piper-alt" /><span className="dev-icon-text">pied-piper-alt</span></div>
    <div className="dev-icon"><i className="fa fa-pinterest" /><span className="dev-icon-text">pinterest</span></div>
    <div className="dev-icon"><i className="fa fa-pinterest-p" /><span className="dev-icon-text">pinterest-p</span></div>
    <div className="dev-icon"><i className="fa fa-pinterest-square" /><span className="dev-icon-text">pinterest-square</span></div>
    <div className="dev-icon"><i className="fa fa-plane" /><span className="dev-icon-text">plane</span></div>
    <div className="dev-icon"><i className="fa fa-play" /><span className="dev-icon-text">play</span></div>
    <div className="dev-icon"><i className="fa fa-play-circle" /><span className="dev-icon-text">play-circle</span></div>
    <div className="dev-icon"><i className="fa fa-play-circle-o" /><span className="dev-icon-text">play-circle-o</span></div>
    <div className="dev-icon"><i className="fa fa-plug" /><span className="dev-icon-text">plug</span></div>
    <div className="dev-icon"><i className="fa fa-plus" /><span className="dev-icon-text">plus</span></div>
    <div className="dev-icon"><i className="fa fa-plus-circle" /><span className="dev-icon-text">plus-circle</span></div>
    <div className="dev-icon"><i className="fa fa-plus-square" /><span className="dev-icon-text">plus-square</span></div>
    <div className="dev-icon"><i className="fa fa-plus-square-o" /><span className="dev-icon-text">plus-square-o</span></div>
    <div className="dev-icon"><i className="fa fa-power-off" /><span className="dev-icon-text">power-off</span></div>
    <div className="dev-icon"><i className="fa fa-print" /><span className="dev-icon-text">print</span></div>
    <div className="dev-icon"><i className="fa fa-product-hunt" /><span className="dev-icon-text">product-hunt</span></div>
    <div className="dev-icon"><i className="fa fa-puzzle-piece" /><span className="dev-icon-text">puzzle-piece</span></div>
    <div className="dev-icon"><i className="fa fa-qq" /><span className="dev-icon-text">qq</span></div>
    <div className="dev-icon"><i className="fa fa-qrcode" /><span className="dev-icon-text">qrcode</span></div>
    <div className="dev-icon"><i className="fa fa-question" /><span className="dev-icon-text">question</span></div>
    <div className="dev-icon"><i className="fa fa-question-circle" /><span className="dev-icon-text">question-circle</span></div>
    <div className="dev-icon"><i className="fa fa-quote-left" /><span className="dev-icon-text">quote-left</span></div>
    <div className="dev-icon"><i className="fa fa-quote-right" /><span className="dev-icon-text">quote-right</span></div>
    <div className="dev-icon"><i className="fa fa-ra" /><span className="dev-icon-text">ra</span></div>
    <div className="dev-icon"><i className="fa fa-random" /><span className="dev-icon-text">random</span></div>
    <div className="dev-icon"><i className="fa fa-rebel" /><span className="dev-icon-text">rebel</span></div>
    <div className="dev-icon"><i className="fa fa-recycle" /><span className="dev-icon-text">recycle</span></div>
    <div className="dev-icon"><i className="fa fa-reddit" /><span className="dev-icon-text">reddit</span></div>
    <div className="dev-icon"><i className="fa fa-reddit-alien" /><span className="dev-icon-text">reddit-alien</span></div>
    <div className="dev-icon"><i className="fa fa-reddit-square" /><span className="dev-icon-text">reddit-square</span></div>
    <div className="dev-icon"><i className="fa fa-refresh" /><span className="dev-icon-text">refresh</span></div>
    <div className="dev-icon"><i className="fa fa-registered" /><span className="dev-icon-text">registered</span></div>
    <div className="dev-icon"><i className="fa fa-remove" /><span className="dev-icon-text">remove</span></div>
    <div className="dev-icon"><i className="fa fa-renren" /><span className="dev-icon-text">renren</span></div>
    <div className="dev-icon"><i className="fa fa-reorder" /><span className="dev-icon-text">reorder</span></div>
    <div className="dev-icon"><i className="fa fa-repeat" /><span className="dev-icon-text">repeat</span></div>
    <div className="dev-icon"><i className="fa fa-reply" /><span className="dev-icon-text">reply</span></div>
    <div className="dev-icon"><i className="fa fa-reply-all" /><span className="dev-icon-text">reply-all</span></div>
    <div className="dev-icon"><i className="fa fa-retweet" /><span className="dev-icon-text">retweet</span></div>
    <div className="dev-icon"><i className="fa fa-rmb" /><span className="dev-icon-text">rmb</span></div>
    <div className="dev-icon"><i className="fa fa-road" /><span className="dev-icon-text">road</span></div>
    <div className="dev-icon"><i className="fa fa-rocket" /><span className="dev-icon-text">rocket</span></div>
    <div className="dev-icon"><i className="fa fa-rotate-left" /><span className="dev-icon-text">rotate-left</span></div>
    <div className="dev-icon"><i className="fa fa-rotate-right" /><span className="dev-icon-text">rotate-right</span></div>
    <div className="dev-icon"><i className="fa fa-rouble" /><span className="dev-icon-text">rouble</span></div>
    <div className="dev-icon"><i className="fa fa-rss" /><span className="dev-icon-text">rss</span></div>
    <div className="dev-icon"><i className="fa fa-rss-square" /><span className="dev-icon-text">rss-square</span></div>
    <div className="dev-icon"><i className="fa fa-rub" /><span className="dev-icon-text">rub</span></div>
    <div className="dev-icon"><i className="fa fa-ruble" /><span className="dev-icon-text">ruble</span></div>
    <div className="dev-icon"><i className="fa fa-rupee" /><span className="dev-icon-text">rupee</span></div>
    <div className="dev-icon"><i className="fa fa-safari" /><span className="dev-icon-text">safari</span></div>
    <div className="dev-icon"><i className="fa fa-save" /><span className="dev-icon-text">save</span></div>
    <div className="dev-icon"><i className="fa fa-scissors" /><span className="dev-icon-text">scissors</span></div>
    <div className="dev-icon"><i className="fa fa-scribd" /><span className="dev-icon-text">scribd</span></div>
    <div className="dev-icon"><i className="fa fa-search" /><span className="dev-icon-text">search</span></div>
    <div className="dev-icon"><i className="fa fa-search-minus" /><span className="dev-icon-text">search-minus</span></div>
    <div className="dev-icon"><i className="fa fa-search-plus" /><span className="dev-icon-text">search-plus</span></div>
    <div className="dev-icon"><i className="fa fa-sellsy" /><span className="dev-icon-text">sellsy</span></div>
    <div className="dev-icon"><i className="fa fa-send" /><span className="dev-icon-text">send</span></div>
    <div className="dev-icon"><i className="fa fa-send-o" /><span className="dev-icon-text">send-o</span></div>
    <div className="dev-icon"><i className="fa fa-server" /><span className="dev-icon-text">server</span></div>
    <div className="dev-icon"><i className="fa fa-share" /><span className="dev-icon-text">share</span></div>
    <div className="dev-icon"><i className="fa fa-share-alt" /><span className="dev-icon-text">share-alt</span></div>
    <div className="dev-icon"><i className="fa fa-share-alt-square" /><span className="dev-icon-text">share-alt-square</span></div>
    <div className="dev-icon"><i className="fa fa-share-square" /><span className="dev-icon-text">share-square</span></div>
    <div className="dev-icon"><i className="fa fa-share-square-o" /><span className="dev-icon-text">share-square-o</span></div>
    <div className="dev-icon"><i className="fa fa-shekel" /><span className="dev-icon-text">shekel</span></div>
    <div className="dev-icon"><i className="fa fa-sheqel" /><span className="dev-icon-text">sheqel</span></div>
    <div className="dev-icon"><i className="fa fa-shield" /><span className="dev-icon-text">shield</span></div>
    <div className="dev-icon"><i className="fa fa-ship" /><span className="dev-icon-text">ship</span></div>
    <div className="dev-icon"><i className="fa fa-shirtsinbulk" /><span className="dev-icon-text">shirtsinbulk</span></div>
    <div className="dev-icon"><i className="fa fa-shopping-bag" /><span className="dev-icon-text">shopping-bag</span></div>
    <div className="dev-icon"><i className="fa fa-shopping-basket" /><span className="dev-icon-text">shopping-basket</span></div>
    <div className="dev-icon"><i className="fa fa-shopping-cart" /><span className="dev-icon-text">shopping-cart</span></div>
    <div className="dev-icon"><i className="fa fa-signal" /><span className="dev-icon-text">signal</span></div>
    <div className="dev-icon"><i className="fa fa-sign-in" /><span className="dev-icon-text">sign-in</span></div>
    <div className="dev-icon"><i className="fa fa-sign-out" /><span className="dev-icon-text">sign-out</span></div>
    <div className="dev-icon"><i className="fa fa-simplybuilt" /><span className="dev-icon-text">simplybuilt</span></div>
    <div className="dev-icon"><i className="fa fa-sitemap" /><span className="dev-icon-text">sitemap</span></div>
    <div className="dev-icon"><i className="fa fa-skyatlas" /><span className="dev-icon-text">skyatlas</span></div>
    <div className="dev-icon"><i className="fa fa-skype" /><span className="dev-icon-text">skype</span></div>
    <div className="dev-icon"><i className="fa fa-slack" /><span className="dev-icon-text">slack</span></div>
    <div className="dev-icon"><i className="fa fa-sliders" /><span className="dev-icon-text">sliders</span></div>
    <div className="dev-icon"><i className="fa fa-slideshare" /><span className="dev-icon-text">slideshare</span></div>
    <div className="dev-icon"><i className="fa fa-smile-o" /><span className="dev-icon-text">smile-o</span></div>
    <div className="dev-icon"><i className="fa fa-soccer-ball-o" /><span className="dev-icon-text">soccer-ball-o</span></div>
    <div className="dev-icon"><i className="fa fa-sort" /><span className="dev-icon-text">sort</span></div>
    <div className="dev-icon"><i className="fa fa-sort-alpha-asc" /><span className="dev-icon-text">sort-alpha-asc</span></div>
    <div className="dev-icon"><i className="fa fa-sort-alpha-desc" /><span className="dev-icon-text">sort-alpha-desc</span></div>
    <div className="dev-icon"><i className="fa fa-sort-amount-asc" /><span className="dev-icon-text">sort-amount-asc</span></div>
    <div className="dev-icon"><i className="fa fa-sort-amount-desc" /><span className="dev-icon-text">sort-amount-desc</span></div>
    <div className="dev-icon"><i className="fa fa-sort-asc" /><span className="dev-icon-text">sort-asc</span></div>
    <div className="dev-icon"><i className="fa fa-sort-desc" /><span className="dev-icon-text">sort-desc</span></div>
    <div className="dev-icon"><i className="fa fa-sort-down" /><span className="dev-icon-text">sort-down</span></div>
    <div className="dev-icon"><i className="fa fa-sort-numeric-asc" /><span className="dev-icon-text">sort-numeric-asc</span></div>
    <div className="dev-icon"><i className="fa fa-sort-numeric-desc" /><span className="dev-icon-text">sort-numeric-desc</span></div>
    <div className="dev-icon"><i className="fa fa-sort-up" /><span className="dev-icon-text">sort-up</span></div>
    <div className="dev-icon"><i className="fa fa-soundcloud" /><span className="dev-icon-text">soundcloud</span></div>
    <div className="dev-icon"><i className="fa fa-space-shuttle" /><span className="dev-icon-text">space-shuttle</span></div>
    <div className="dev-icon"><i className="fa fa-spinner" /><span className="dev-icon-text">spinner</span></div>
    <div className="dev-icon"><i className="fa fa-spoon" /><span className="dev-icon-text">spoon</span></div>
    <div className="dev-icon"><i className="fa fa-spotify" /><span className="dev-icon-text">spotify</span></div>
    <div className="dev-icon"><i className="fa fa-square" /><span className="dev-icon-text">square</span></div>
    <div className="dev-icon"><i className="fa fa-square-o" /><span className="dev-icon-text">square-o</span></div>
    <div className="dev-icon"><i className="fa fa-stack-exchange" /><span className="dev-icon-text">stack-exchange</span></div>
    <div className="dev-icon"><i className="fa fa-stack-overflow" /><span className="dev-icon-text">stack-overflow</span></div>
    <div className="dev-icon"><i className="fa fa-star" /><span className="dev-icon-text">star</span></div>
    <div className="dev-icon"><i className="fa fa-star-half" /><span className="dev-icon-text">star-half</span></div>
    <div className="dev-icon"><i className="fa fa-star-half-empty" /><span className="dev-icon-text">star-half-empty</span></div>
    <div className="dev-icon"><i className="fa fa-star-half-full" /><span className="dev-icon-text">star-half-full</span></div>
    <div className="dev-icon"><i className="fa fa-star-half-o" /><span className="dev-icon-text">star-half-o</span></div>
    <div className="dev-icon"><i className="fa fa-star-o" /><span className="dev-icon-text">star-o</span></div>
    <div className="dev-icon"><i className="fa fa-steam" /><span className="dev-icon-text">steam</span></div>
    <div className="dev-icon"><i className="fa fa-steam-square" /><span className="dev-icon-text">steam-square</span></div>
    <div className="dev-icon"><i className="fa fa-step-backward" /><span className="dev-icon-text">step-backward</span></div>
    <div className="dev-icon"><i className="fa fa-step-forward" /><span className="dev-icon-text">step-forward</span></div>
    <div className="dev-icon"><i className="fa fa-stethoscope" /><span className="dev-icon-text">stethoscope</span></div>
    <div className="dev-icon"><i className="fa fa-sticky-note" /><span className="dev-icon-text">sticky-note</span></div>
    <div className="dev-icon"><i className="fa fa-sticky-note-o" /><span className="dev-icon-text">sticky-note-o</span></div>
    <div className="dev-icon"><i className="fa fa-stop" /><span className="dev-icon-text">stop</span></div>
    <div className="dev-icon"><i className="fa fa-stop-circle" /><span className="dev-icon-text">stop-circle</span></div>
    <div className="dev-icon"><i className="fa fa-stop-circle-o" /><span className="dev-icon-text">stop-circle-o</span></div>
    <div className="dev-icon"><i className="fa fa-street-view" /><span className="dev-icon-text">street-view</span></div>
    <div className="dev-icon"><i className="fa fa-strikethrough" /><span className="dev-icon-text">strikethrough</span></div>
    <div className="dev-icon"><i className="fa fa-stumbleupon" /><span className="dev-icon-text">stumbleupon</span></div>
    <div className="dev-icon"><i className="fa fa-stumbleupon-circle" /><span className="dev-icon-text">stumbleupon-circle</span></div>
    <div className="dev-icon"><i className="fa fa-subscript" /><span className="dev-icon-text">subscript</span></div>
    <div className="dev-icon"><i className="fa fa-subway" /><span className="dev-icon-text">subway</span></div>
    <div className="dev-icon"><i className="fa fa-suitcase" /><span className="dev-icon-text">suitcase</span></div>
    <div className="dev-icon"><i className="fa fa-sun-o" /><span className="dev-icon-text">sun-o</span></div>
    <div className="dev-icon"><i className="fa fa-superscript" /><span className="dev-icon-text">superscript</span></div>
    <div className="dev-icon"><i className="fa fa-support" /><span className="dev-icon-text">support</span></div>
    <div className="dev-icon"><i className="fa fa-table" /><span className="dev-icon-text">table</span></div>
    <div className="dev-icon"><i className="fa fa-tablet" /><span className="dev-icon-text">tablet</span></div>
    <div className="dev-icon"><i className="fa fa-tachometer" /><span className="dev-icon-text">tachometer</span></div>
    <div className="dev-icon"><i className="fa fa-tag" /><span className="dev-icon-text">tag</span></div>
    <div className="dev-icon"><i className="fa fa-tags" /><span className="dev-icon-text">tags</span></div>
    <div className="dev-icon"><i className="fa fa-tasks" /><span className="dev-icon-text">tasks</span></div>
    <div className="dev-icon"><i className="fa fa-taxi" /><span className="dev-icon-text">taxi</span></div>
    <div className="dev-icon"><i className="fa fa-television" /><span className="dev-icon-text">television</span></div>
    <div className="dev-icon"><i className="fa fa-tencent-weibo" /><span className="dev-icon-text">tencent-weibo</span></div>
    <div className="dev-icon"><i className="fa fa-terminal" /><span className="dev-icon-text">terminal</span></div>
    <div className="dev-icon"><i className="fa fa-text-height" /><span className="dev-icon-text">text-height</span></div>
    <div className="dev-icon"><i className="fa fa-text-width" /><span className="dev-icon-text">text-width</span></div>
    <div className="dev-icon"><i className="fa fa-th" /><span className="dev-icon-text">th</span></div>
    <div className="dev-icon"><i className="fa fa-th-large" /><span className="dev-icon-text">th-large</span></div>
    <div className="dev-icon"><i className="fa fa-th-list" /><span className="dev-icon-text">th-list</span></div>
    <div className="dev-icon"><i className="fa fa-thumbs-down" /><span className="dev-icon-text">thumbs-down</span></div>
    <div className="dev-icon"><i className="fa fa-thumbs-o-down" /><span className="dev-icon-text">thumbs-o-down</span></div>
    <div className="dev-icon"><i className="fa fa-thumbs-o-up" /><span className="dev-icon-text">thumbs-o-up</span></div>
    <div className="dev-icon"><i className="fa fa-thumbs-up" /><span className="dev-icon-text">thumbs-up</span></div>
    <div className="dev-icon"><i className="fa fa-thumb-tack" /><span className="dev-icon-text">thumb-tack</span></div>
    <div className="dev-icon"><i className="fa fa-ticket" /><span className="dev-icon-text">ticket</span></div>
    <div className="dev-icon"><i className="fa fa-times" /><span className="dev-icon-text">times</span></div>
    <div className="dev-icon"><i className="fa fa-times-circle" /><span className="dev-icon-text">times-circle</span></div>
    <div className="dev-icon"><i className="fa fa-times-circle-o" /><span className="dev-icon-text">times-circle-o</span></div>
    <div className="dev-icon"><i className="fa fa-tint" /><span className="dev-icon-text">tint</span></div>
    <div className="dev-icon"><i className="fa fa-toggle-down" /><span className="dev-icon-text">toggle-down</span></div>
    <div className="dev-icon"><i className="fa fa-toggle-left" /><span className="dev-icon-text">toggle-left</span></div>
    <div className="dev-icon"><i className="fa fa-toggle-off" /><span className="dev-icon-text">toggle-off</span></div>
    <div className="dev-icon"><i className="fa fa-toggle-on" /><span className="dev-icon-text">toggle-on</span></div>
    <div className="dev-icon"><i className="fa fa-toggle-right" /><span className="dev-icon-text">toggle-right</span></div>
    <div className="dev-icon"><i className="fa fa-toggle-up" /><span className="dev-icon-text">toggle-up</span></div>
    <div className="dev-icon"><i className="fa fa-trademark" /><span className="dev-icon-text">trademark</span></div>
    <div className="dev-icon"><i className="fa fa-train" /><span className="dev-icon-text">train</span></div>
    <div className="dev-icon"><i className="fa fa-transgender" /><span className="dev-icon-text">transgender</span></div>
    <div className="dev-icon"><i className="fa fa-transgender-alt" /><span className="dev-icon-text">transgender-alt</span></div>
    <div className="dev-icon"><i className="fa fa-trash" /><span className="dev-icon-text">trash</span></div>
    <div className="dev-icon"><i className="fa fa-trash-o" /><span className="dev-icon-text">trash-o</span></div>
    <div className="dev-icon"><i className="fa fa-tree" /><span className="dev-icon-text">tree</span></div>
    <div className="dev-icon"><i className="fa fa-trello" /><span className="dev-icon-text">trello</span></div>
    <div className="dev-icon"><i className="fa fa-tripadvisor" /><span className="dev-icon-text">tripadvisor</span></div>
    <div className="dev-icon"><i className="fa fa-trophy" /><span className="dev-icon-text">trophy</span></div>
    <div className="dev-icon"><i className="fa fa-truck" /><span className="dev-icon-text">truck</span></div>
    <div className="dev-icon"><i className="fa fa-try" /><span className="dev-icon-text">try</span></div>
    <div className="dev-icon"><i className="fa fa-tty" /><span className="dev-icon-text">tty</span></div>
    <div className="dev-icon"><i className="fa fa-tumblr" /><span className="dev-icon-text">tumblr</span></div>
    <div className="dev-icon"><i className="fa fa-tumblr-square" /><span className="dev-icon-text">tumblr-square</span></div>
    <div className="dev-icon"><i className="fa fa-turkish-lira" /><span className="dev-icon-text">turkish-lira</span></div>
    <div className="dev-icon"><i className="fa fa-tv" /><span className="dev-icon-text">tv</span></div>
    <div className="dev-icon"><i className="fa fa-twitch" /><span className="dev-icon-text">twitch</span></div>
    <div className="dev-icon"><i className="fa fa-twitter" /><span className="dev-icon-text">twitter</span></div>
    <div className="dev-icon"><i className="fa fa-twitter-square" /><span className="dev-icon-text">twitter-square</span></div>
    <div className="dev-icon"><i className="fa fa-umbrella" /><span className="dev-icon-text">umbrella</span></div>
    <div className="dev-icon"><i className="fa fa-underline" /><span className="dev-icon-text">underline</span></div>
    <div className="dev-icon"><i className="fa fa-undo" /><span className="dev-icon-text">undo</span></div>
    <div className="dev-icon"><i className="fa fa-university" /><span className="dev-icon-text">university</span></div>
    <div className="dev-icon"><i className="fa fa-unlink" /><span className="dev-icon-text">unlink</span></div>
    <div className="dev-icon"><i className="fa fa-unlock" /><span className="dev-icon-text">unlock</span></div>
    <div className="dev-icon"><i className="fa fa-unlock-alt" /><span className="dev-icon-text">unlock-alt</span></div>
    <div className="dev-icon"><i className="fa fa-unsorted" /><span className="dev-icon-text">unsorted</span></div>
    <div className="dev-icon"><i className="fa fa-upload" /><span className="dev-icon-text">upload</span></div>
    <div className="dev-icon"><i className="fa fa-usb" /><span className="dev-icon-text">usb</span></div>
    <div className="dev-icon"><i className="fa fa-usd" /><span className="dev-icon-text">usd</span></div>
    <div className="dev-icon"><i className="fa fa-user" /><span className="dev-icon-text">user</span></div>
    <div className="dev-icon"><i className="fa fa-user-md" /><span className="dev-icon-text">user-md</span></div>
    <div className="dev-icon"><i className="fa fa-user-plus" /><span className="dev-icon-text">user-plus</span></div>
    <div className="dev-icon"><i className="fa fa-users" /><span className="dev-icon-text">users</span></div>
    <div className="dev-icon"><i className="fa fa-user-secret" /><span className="dev-icon-text">user-secret</span></div>
    <div className="dev-icon"><i className="fa fa-user-times" /><span className="dev-icon-text">user-times</span></div>
    <div className="dev-icon"><i className="fa fa-venus" /><span className="dev-icon-text">venus</span></div>
    <div className="dev-icon"><i className="fa fa-venus-double" /><span className="dev-icon-text">venus-double</span></div>
    <div className="dev-icon"><i className="fa fa-venus-mars" /><span className="dev-icon-text">venus-mars</span></div>
    <div className="dev-icon"><i className="fa fa-viacoin" /><span className="dev-icon-text">viacoin</span></div>
    <div className="dev-icon"><i className="fa fa-video-camera" /><span className="dev-icon-text">video-camera</span></div>
    <div className="dev-icon"><i className="fa fa-vimeo" /><span className="dev-icon-text">vimeo</span></div>
    <div className="dev-icon"><i className="fa fa-vimeo-square" /><span className="dev-icon-text">vimeo-square</span></div>
    <div className="dev-icon"><i className="fa fa-vine" /><span className="dev-icon-text">vine</span></div>
    <div className="dev-icon"><i className="fa fa-vk" /><span className="dev-icon-text">vk</span></div>
    <div className="dev-icon"><i className="fa fa-volume-down" /><span className="dev-icon-text">volume-down</span></div>
    <div className="dev-icon"><i className="fa fa-volume-off" /><span className="dev-icon-text">volume-off</span></div>
    <div className="dev-icon"><i className="fa fa-volume-up" /><span className="dev-icon-text">volume-up</span></div>
    <div className="dev-icon"><i className="fa fa-warning" /><span className="dev-icon-text">warning</span></div>
    <div className="dev-icon"><i className="fa fa-wechat" /><span className="dev-icon-text">wechat</span></div>
    <div className="dev-icon"><i className="fa fa-weibo" /><span className="dev-icon-text">weibo</span></div>
    <div className="dev-icon"><i className="fa fa-weixin" /><span className="dev-icon-text">weixin</span></div>
    <div className="dev-icon"><i className="fa fa-whatsapp" /><span className="dev-icon-text">whatsapp</span></div>
    <div className="dev-icon"><i className="fa fa-wheelchair" /><span className="dev-icon-text">wheelchair</span></div>
    <div className="dev-icon"><i className="fa fa-wifi" /><span className="dev-icon-text">wifi</span></div>
    <div className="dev-icon"><i className="fa fa-wikipedia-w" /><span className="dev-icon-text">wikipedia-w</span></div>
    <div className="dev-icon"><i className="fa fa-windows" /><span className="dev-icon-text">windows</span></div>
    <div className="dev-icon"><i className="fa fa-won" /><span className="dev-icon-text">won</span></div>
    <div className="dev-icon"><i className="fa fa-wordpress" /><span className="dev-icon-text">wordpress</span></div>
    <div className="dev-icon"><i className="fa fa-wrench" /><span className="dev-icon-text">wrench</span></div>
    <div className="dev-icon"><i className="fa fa-xing" /><span className="dev-icon-text">xing</span></div>
    <div className="dev-icon"><i className="fa fa-xing-square" /><span className="dev-icon-text">xing-square</span></div>
    <div className="dev-icon"><i className="fa fa-yahoo" /><span className="dev-icon-text">yahoo</span></div>
    <div className="dev-icon"><i className="fa fa-yc" /><span className="dev-icon-text">yc</span></div>
    <div className="dev-icon"><i className="fa fa-y-combinator" /><span className="dev-icon-text">y-combinator</span></div>
    <div className="dev-icon"><i className="fa fa-y-combinator-square" /><span className="dev-icon-text">y-combinator-square</span></div>
    <div className="dev-icon"><i className="fa fa-yc-square" /><span className="dev-icon-text">yc-square</span></div>
    <div className="dev-icon"><i className="fa fa-yelp" /><span className="dev-icon-text">yelp</span></div>
    <div className="dev-icon"><i className="fa fa-yen" /><span className="dev-icon-text">yen</span></div>
    <div className="dev-icon"><i className="fa fa-youtube" /><span className="dev-icon-text">youtube</span></div>
    <div className="dev-icon"><i className="fa fa-youtube-play" /><span className="dev-icon-text">youtube-play</span></div>
    <div className="dev-icon"><i className="fa fa-youtube-square" /><span className="dev-icon-text">youtube-square</span></div>
  </div>
);

