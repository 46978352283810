import React from 'react';
import classNames from 'classnames';

export default (props) => {
  const {
    type = 'input',
    label,
    name,
    id,
    placeholder,
    value,
    defaultValue,
    disabled,
    checked,
    className,
    required = false,
    isInvalid = false,
    isDirty = false,
    onInputChange,
    options,
    invalidMessage = ''
  } = props;

  const elementType = type == 'password' ? 'input' : type;

  const classes = classNames(className, {
    invalid: isInvalid,
    dirty: isDirty,
    required,
    disabled
  });

  const onChange = onInputChange.bind(this, props);

  return (
    <div className={classes}>
      {label && <label htmlFor={id}>{label}</label>}
      {React.createElement(elementType, {id, type, name, placeholder, value, defaultValue, onChange, options, disabled, checked})}
      {isInvalid && isDirty && invalidMessage && <div className="error-message">{invalidMessage}</div>}
    </div>
  );
};
