import React, { Component } from 'react';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import GroupedTable from 'common/table/GroupedTable';
import InfiniteTable from 'common/table/InfiniteTable';
import SimpleTable from 'common/table/SimpleTable';
import { removeInCopy } from 'common/util/common-helpers';

export const TYPES = {
  'infinite': InfiniteTable,
  'grouped': GroupedTable,
  'simple': SimpleTable
};

const INITIAL_HEADER_FOOTER = '';

class BaseTable extends Component {

  state = {};
  features = [];
  table = null;
  idx = 0

  featureSetState = (key) => {
    return (state) => {
      const newState = {...this.state[key], ...state};
      this.setState({[key]: newState});
      return newState;
    };
  };

  UNSAFE_componentWillMount() {
    const {features} = this.props;

    if (features) {
      console.debug(`Attaching ${features.length} features to table.`);
      this.features = features.map((feature, idx) => {
        const featureObj = feature();
        this.state[featureObj.key] = featureObj.attach(this.featureSetState(featureObj.key));
        console.debug(`Added ${featureObj.key} feature.`);
        return featureObj;
      });
    }
  }

  checkForDuplicates(curElem, curKey) {
    if (this.elem === curElem) {
      return;
    }
    if (this.elem !== INITIAL_HEADER_FOOTER) {
      console.error(`The feature ${curKey} just replaced the ${this.type} generated by ${this.key}`);
    }

    this.elem = curElem;
    this.key = curKey;
  };

  render() {
    const { config, data, type, loader, buttonDropdown } = this.props;
    let processedConfigs = {
      key:uuid(),
      header: INITIAL_HEADER_FOOTER,
      footer: INITIAL_HEADER_FOOTER,
      ...config,
      tableType: type || TYPES.simple,
      tableConfig: config.table,
      tableData: data,
      classes: ['table', config.className],
      loader: loader
    };
    const footer = {type: 'footer', elem: INITIAL_HEADER_FOOTER, updateProcessedChanges: this.checkForDuplicates};
    const header = {type: 'header', elem: INITIAL_HEADER_FOOTER, updateProcessedChanges: this.checkForDuplicates};

    this.features.forEach((feature, idx)  => {
      processedConfigs = {...processedConfigs, ...feature.onRender(this.props, processedConfigs, uuid()) };
      header.updateProcessedChanges(processedConfigs.header, feature.key);
      footer.updateProcessedChanges(processedConfigs.footer, feature.key);
    });

    const table = React.createElement(processedConfigs.tableType, {
      ...this.props,
      ...processedConfigs,
      config: processedConfigs.tableConfig,
      data: processedConfigs.tableData
    });
                                                                                               
    return (
      <div className={classNames(processedConfigs.classes)}>
          <DndProvider backend={HTML5Backend}>
            {processedConfigs.header}
            <div className="table-title">{buttonDropdown ? buttonDropdown: processedConfigs.title}</div>
            <div className="buttons" key={uuid()}>{processedConfigs.buttons}</div>
                {table}
            {processedConfigs.footer}
          </DndProvider>
      </div>
    );
  }
}

BaseTable.defaultProps = {
  loader: <div className="loading more"><i className="fa fa-spinner fa-pulse"/></div>
};

export default BaseTable
