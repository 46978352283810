import React from 'react';
import { Link } from 'react-router-dom';

import { eventHandlerWithPrevent } from 'common/util/events';
import { isBlankOrNaN, toDate } from 'common/util/common-helpers';


export function blankNotAvailable(value) {
  if (value === 'NOT_AVAILABLE') {
    return '-';
  }

  return value;
}

export function dashToNull(value) {
  
  if (typeof value === 'function') {
    return value => dashToNull(value);
  }

  return value === '-' ? null : value;
}

export function formatNotAvailable(value) {
  if (value === 'NOT_AVAILABLE') {
    return 'Not Available';
  }

  return value;
}

export function formatDateYyyMm(value) {
  
  let date = toDate(value);
  if (date === null) {
    return value;
  }

  return date.getFullYear() + '-' + pad2(date.getMonth() + 1);
}

export function formatDateYyyyMmDd(value) {
  let date = toDate(value);
  if (date === null) {
    return value;
  }

  return date.getFullYear() + pad2(date.getMonth() + 1) + pad2(date.getDate());
}

export var monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export var monthAbbr = monthNames.map(n => n.substr(0, 3));

export function formatDateMmmmDYyyy(value) {
  
  let dat = toDate(value);
  if (dat === null) {
    return value;
  }  

  return monthNames[dat.getMonth()] + ' ' + dat.getDate() + ', ' + dat.getFullYear();
}

export function formatDateMmDdYyyy(value) {
  
  let dat = toDate(value);
  if (dat === null) {
    return value;
  }  

  return  pad2(dat.getMonth() + 1) + '/' + pad2(dat.getDate()) + '/' + dat.getFullYear();
}

export function formatDateMmDd(value) {
  
  let dat = toDate(value);
  if (dat === null) {
    return value;
  }  

  return  pad2(dat.getMonth() + 1) + '/' + pad2(dat.getDate());
}

export function formatDateMmmmYyyy(value) {
  let dat = toDate(value);
  if (dat === null) {
    return value;
  }  

  return monthNames[dat.getMonth()] + ' ' + dat.getFullYear();
}

export function formatDateMmmm(value) {
  let dat = toDate(value);
  if (dat === null) {
    return value;
  }  

  return monthNames[dat.getMonth()];
}

export function formatDateMmmYyyy(value) {
  let dat = toDate(value);
  if (dat === null) {
    return value;
  }  

  return monthAbbr[dat.getMonth()] + ' ' + dat.getFullYear();
}

export function formatDateMmYyyy(value) {
  let dat = toDate(value);
  if (dat === null) {
    return value;
  }  

  return pad2(dat.getMonth()+1) + '/' + dat.getFullYear();
}

export function formatDateRange(minValue, maxValue) {
  const minDate = toDate(minValue);
  const maxDate = toDate(maxValue);

  if (minDate === null && maxDate === null) {
    return '';
  }

  if (minDate === null || maxDate === null || minDate.getTime() === maxDate.valueOf()) {
    return formatDateMmDdYyyy(minDate || maxDate);
  }

  const firstFormat = minDate.getFullYear() === maxDate.getFullYear() 
    ? formatDateMmDd 
    : formatDateMmDdYyyy;

  return firstFormat(minDate) + ' - ' + formatDateMmDdYyyy(maxDate);
}

export function formatDateTimeMmDdYyyyHourMinute(value) {
  
  let dat = toDate(value);
  if (dat === null) {
    return value;
  }  

  return formatDateMmDdYyyy(dat) + ' ' + formatTimeHourMinute(dat);
}

export function formatTimeHourMinute(value) {
  
  let dat = toDate(value);
  if (dat === null) {
    return value;
  }  

  let hh = value.getHours();
  let ap = hh < 12 ? 'am' : 'pm';

  if (hh === 0) {
    hh = 12;
  } else if (hh > 12) {
    hh -= 12;
  }

  return  hh + ':' + pad2(dat.getMinutes()) + ' ' + ap;
}


const htmlEncodeCharacters = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '\'': '&apos;',
  '"': '&quot;'
};

const htmlEncodeRegex = new RegExp('[\\' + Object.keys(htmlEncodeCharacters).join('\\') + ']', 'g');

export function htmlEncode(text) {
  if (text == undefined) {
    return '';  
  }

  if (typeof text !== 'string') {
    text = text.toString();
  }

  return text.replace(htmlEncodeRegex, c => htmlEncodeCharacters[c]);
}

export function integer(value) {
  if (isBlankOrNaN(value)) {
    return value;
  }

  return Math.round(value);
}

export function getLink({ url, value, handler }) {
  if (handler) {
    return <Link to={url} onClick={eventHandlerWithPrevent(handler, value)}>{value || url}</Link>;
  } else {
    return <Link to={url}>{value || url}</Link>;
  }
}

export function decimal(value, {precision = 0}) {
  if (isBlankOrNaN(value)) {
    return value;
  }

  return parseFloat(value).toFixed(precision);
}

const MILLION = 1000000;
const MILLION_M = 'M';

export function millions(value) {
  if (isBlankOrNaN(value)) {
    return value;
  }

  value = parseFloat(value);

  if (value > MILLION) {
    let output = Math.round(value/MILLION) + '';
    return `$${output.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')}${MILLION_M}`;
  } else {
    return '<$1M';
  }
}

export const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
  ];

export function monthName(dateOrIndex) {

  let index;

  if (typeof dateOrIndex === "number") {
    index = dateOrIndex;
  } else if (dateOrIndex && dateOrIndex.getMonth) {
    index = dateOrIndex.getMonth();
  }

  return MONTH_NAMES[index];
}

export function monthYearOnly(date) {

  if (typeof date === "string") {

    // if it's iso whatever date without tz and we parse directly it'll be assumed 
    // to be UTC time and often end up being one day off, so parse what we want
    // directly

    let parsed = /^(\d{4})-(\d\d)-(\d\d)/.exec(date);
    if (parsed !== null) {
      date = new Date(parseInt(parsed[1]), parseInt(parsed[2]), parseInt(parsed[3]));
    }
  }

  if (date == undefined || typeof date.getMonth !== "function") {
    return "";
  }

  return monthName(date) + " " + date.getFullYear();
}

export function currencyWithCents(value, col = {}) {
	if (isBlankOrNaN(value)) {
    	return value;
  	}
 	return currency(value, {showCents : true});
}

export function currencyRoundDownCents(value, col = {}) {
	if (isBlankOrNaN(value)) {
    	return value;
  	}
 	return currency(Math.floor(value), {showCents : true});
}

export function currency(value, col = {}) {
  if (isBlankOrNaN(value)) {
    return value;
  }

  return '$' + commaThousands(value, col);
}

export function commaThousands(value, col = {}) {
  if (isBlankOrNaN(value)) {
    return value;
  }

  const output = parseFloat(value).toFixed(col.showCents ? 2 : 0);
  return output.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
}

export function pad2(value) {
  return padNumber(value, 2);
}

export function padNumber(value, places) {

  if (value == undefined) {
    return value
  }

  let num = typeof value === 'number' ? value : parseInt(value);
  if (isNaN(num)) {
    return value;
  }

  let text = num.toString();

  if (text.length >= places) {
    return text;
  }

  return '0'.repeat(places - text.length) + text;
}

export function percent(value, col = {}) {
  value = parseFloat(value);
  if (isNaN(value)){
    return 'N/A';
  } else {
    const { precision } = col;
    const output = precision && precision > 1 ? value.toFixed(precision): value.toFixed(0);
    return output + '%';
  }
}

export function percentNoSymbol(value, col = {}) {
  value = parseFloat(value);
  if (isNaN(value)){
    return 'N/A';
  } else {
    const { precision } = col;
    const output = precision && precision > 1 ? value.toFixed(precision): value.toFixed(0);
    return output;
  }
}

export function percentageBar(value, { }) {
  const width = `${value}%`;
  return (
    <div className="percentage-cell">
      <div>{value}</div>
      <div className="tiny-bar">
        <div className="color-span" style={{width}}></div>
      </div>
    </div>
  );
}

export function percentagePie(value, {radius = 3}) {
  return (
    <div>
      {value}
      {Pie({percent: value, radius})}
    </div>
  );
}

export function zeroToEmpty(value) {
  return value === 0 ? '' : value;
}

export function checkIfZero(value) {
	return value === 0 ? '-' : value;
}