import API from 'common/util/api';
import * as _ from 'lodash';
import { reduceMiddleware } from 'common/util/redux';
import { getOrganizationFromLocation, orDefault } from 'common/util/common-helpers';
import { getOrganizationCodeFromLocation, paginate } from 'www/util/www-helpers';

import {
  DEAL_REQUEST,
  DEAL_RESPONSE,
  DEAL_ERROR,
  DEAL_ISSUANCE_REQUEST,
  DEAL_ISSUANCE_RESPONSE,
  DEAL_ISSUANCE_ERROR,
  DEAL_COMPONENT_REQUEST,
  DEAL_COMPONENT_RESPONSE,
  DEAL_COMPONENT_ERROR,
  DEAL_TAX_REQUEST,
  DEAL_TAX_RESPONSE,
  DEAL_TAX_ERROR,
  DEAL_DATES_REQUEST,
  DEAL_DATES_RESPONSE, 
  DEAL_DATES_ERROR,
  DEAL_AS_OF_REQUEST, 
  DEAL_AS_OF_RESPONSE, 
  DEAL_AS_OF_ERROR,
  DEAL_COLLATERAL_COUNT_REQUEST,
  DEAL_COLLATERAL_COUNT_RESPONSE,
  DEAL_COLLATERAL_COUNT_ERROR,
  DEAL_COLLATERAL_REQUEST,
  DEAL_COLLATERAL_RESPONSE,
  DEAL_COLLATERAL_ERROR,
  DEAL_DOCUMENTS_REQUEST,
  DEAL_DOCUMENTS_RESPONSE,
  DEAL_DOCUMENTS_ERROR,
  DEAL_TAX_FACTOR_COUNT_REQUEST,
  DEAL_TAX_FACTOR_COUNT_RESPONSE,
  DEAL_TAX_FACTOR_COUNT_ERROR,
  DEAL_BY_CLASS_REQUEST,
  DEAL_BY_CLASS_RESPONSE,
  DEAL_BY_CLASS_ERROR,
  DEAL_CLASS_COUNT_REQUEST,
  DEAL_CLASS_COUNT_RESPONSE,
  DEAL_CLASS_COUNT_ERROR,
  DEAL_CLASS_CUSIPS_REQUEST,
  DEAL_CLASS_CUSIPS_RESPONSE,
  DEAL_CLASS_CUSIPS_ERROR,
  DEAL_PAGE_CHANGE_REQUEST
} from 'www/actions/dealActions';

import {
  getParams,
  getQueryString,
  extractParams
} from 'common/util/common-helpers';

function dealRequest(getState, next, action) {

  let query =deriveQuery(action);
  let trustNo =getTrustNumber(action);
  API.get(`deal/${getOrganizationFromLocation()}/${trustNo}`).then(
    response => next({...action, ...action.trustNumber, type: DEAL_RESPONSE, deal: response}),
    message => next({...action, ...action.trustNumber, type: DEAL_ERROR, message})
  );
}
function dealIssuanceRequest(getState, next, action) {

  let trustNo =getTrustNumber(action);
  API.get(`deal/${getOrganizationFromLocation()}/${trustNo}`).then(
    response => next({...action, ...action.trustNumber, type: DEAL_ISSUANCE_RESPONSE, deal: response}),
    message => next({...action, ...action.trustNumber, type: DEAL_ISSUANCE_ERROR, message})
  );
}

function dealComponentRequest(getState, next, action) {
  
  let query =deriveQuery(action);
  let trustNo =getTrustNumber(action);
  API.get(`deal/${getOrganizationFromLocation()}/${trustNo}?${query}`).then(
    response => next({...action, ...action.trustNumber, type: DEAL_COMPONENT_RESPONSE, componentDeal: response}),
    message => next({...action, ...action.trustNumber, type: DEAL_COMPONENT_ERROR, message})
  );
}
function deriveQuery(action){
  const { pageIdx, pageSize, sort } = action;
  let query = `page=${pageIdx}&max_results=${pageSize}`;
  if (sort) { // sort.field && sort.direction)
      const {field, direction} = sort;
      query += `&sortField=${field}&sortAsc=${direction === 'asc'}`;
    }
  return query;
}

function getTrustNumber(action){
  let trustNo = '' ;
  if(action.trustNumber.trustNumber === undefined ){
    trustNo = action.trustNumber 
  }else{
    trustNo = action.trustNumber.trustNumber
  }
  return trustNo
}

function pageChangeRequest(getState, next, action) {
    next({...action});
}

function dealTaxRequest(getState, next, action) {
  const { pageIdx, pageSize, sort, currentYear } = action;
  let additionalYears = 1;

    if (window.location.search == '?mock') {
        setTimeout(() => next({
            ...action,
            type: DEAL_TAX_RESPONSE,
            dealTax: _.cloneDeep(Tax_Data)
        }));
        return;
    }
  let endpoint = `tax/${getOrganizationCodeFromLocation()}/deal/${action.trustNumber}/tax/${currentYear}/${additionalYears}`;
  let query = ``;
  if (sort) { // sort.field && sort.direction)
    const {field, direction} = sort;
    query += `?sortField=${field}&sortAsc=${direction === 'asc'}`;
  }
    if(action.cusipParam && action.cusipParam.length) {
        endpoint = `tax/${getOrganizationCodeFromLocation()}/multiplecusips/tax/${currentYear}/${additionalYears}`;
        const params = {
            cusips: action.cusipParam
        };
        return API.postJson(`${endpoint}?${query}`, JSON.stringify(params)).then(
            response => next({...action, type: DEAL_TAX_RESPONSE, dealTax: response }),
            message => next({...action, type: DEAL_TAX_ERROR, message})
        );
    }
    return API.get(`${endpoint}${query}`).then(
    response => next({...action, type: DEAL_TAX_RESPONSE, dealTax: response}),
    message => next({...action, type: DEAL_TAX_ERROR, message})
  );
}

function dealTaxFactorCount(getState, next, action) {  
  let additionalYears = 1;
  const { currentYear } = action;

    if(action.cusipParam && action.cusipParam.length) {
        const endpoint = `tax/${getOrganizationCodeFromLocation()}/multiplecusips/count/${currentYear}/${additionalYears}`;
        const params = {
            cusips: action.cusipParam
        };
         API.postJson(`${endpoint}`, JSON.stringify(params)).then(
            response => next({...action, type: DEAL_TAX_FACTOR_COUNT_RESPONSE, count: response.count}),
            message => next({...action, type: DEAL_TAX_FACTOR_COUNT_ERROR, message})
        );
        return API.get(`tax/${getOrganizationCodeFromLocation()}/deal/${action.trustNumber}/count/${currentYear}/${additionalYears}`).then(
            response => next({...action, type: DEAL_TAX_FACTOR_COUNT_RESPONSE, totalCount: response.count}),
            message => next({...action, type: DEAL_TAX_FACTOR_COUNT_ERROR, message})
        );
    }

    API.get(`tax/${getOrganizationCodeFromLocation()}/deal/${action.trustNumber}/count/${currentYear}/${additionalYears}`).then(
    response => next({...action, type: DEAL_TAX_FACTOR_COUNT_RESPONSE, count: response.count, totalCount: 0}),
    message => next({...action, type: DEAL_TAX_FACTOR_COUNT_ERROR, message})
  );
}

function dealClassCount (getState, next, action) {
    if (action.classParam && action.classParam.length) {
        const params = {
            classIds: action.classParam
        }
        API.postJson(`deal/${getOrganizationFromLocation()}/classes/${action.trustNumber}/count`,JSON.stringify(params)).then(
            response => next({...action, type: DEAL_CLASS_COUNT_RESPONSE, count: response.count}),
            message => next({...action, type: DEAL_CLASS_COUNT_ERROR, message})
        );
        API.get(`deal/${getOrganizationFromLocation()}/${action.trustNumber}/count`).then(
            response => next({...action, type: DEAL_CLASS_COUNT_RESPONSE, totalCount: response.count}),
            message => next({...action, type: DEAL_CLASS_COUNT_ERROR, message})
        );

    } else {
        API.get(`deal/${getOrganizationFromLocation()}/${action.trustNumber}/count`).then(
            response => next({...action, type: DEAL_CLASS_COUNT_RESPONSE, count: response.count, totalCount: 0}),
            message => next({...action, type: DEAL_CLASS_COUNT_ERROR, message})
        );
    }
}

function dealClassCusips (getState, next, action) {
  API.get(`deal/${getOrganizationFromLocation()}/${action.trustNumber}/cusipsList`).then(
    response => next({...action, type: DEAL_CLASS_CUSIPS_RESPONSE, classCusips: response}),
    message => next({...action, type: DEAL_CLASS_CUSIPS_ERROR, message})
  );
}

function datesRequest(getNextState, next, action) {
  API.get(`deal/${getOrganizationFromLocation()}/dates/${action.trustNumber}`).then(
    response => next({...action, type: DEAL_DATES_RESPONSE, dates: response}),
    message => next({...action, type: DEAL_DATES_ERROR, message})
  );
}

function asOfRequest(getNextState, next, action) {
	const { pageIdx, pageSize, sort } = action;	
  API.get(`deal/${getOrganizationFromLocation()}/${action.trust.trustNumber}/${action.trust.asOfDate}`).then(
    response => next({...action, ...action.trust,  type: DEAL_AS_OF_RESPONSE, deal: response}),
    message => next({...action, ...action.trust, type: DEAL_ERROR, message})
  );
}

function collateralCountRequest(getState, next, action) {
    let query = `recordType=${action.recordType}`;
    API.get(`deal/${getOrganizationFromLocation()}/${action.dealId}/collateral/count?${query}`).then(
    response => next({...action, type: DEAL_COLLATERAL_COUNT_RESPONSE, count: response.count}),
    message => next({...action, type: DEAL_COLLATERAL_COUNT_ERROR, message})
  );
}

function collateralRequest(getState, next, action) {
  const { pageIdx, pageSize, sort } = action;
  let query = `recordType=${action.recordType}`;
  API.get(`deal/${getOrganizationFromLocation()}/${action.dealId}/collateral?${query}`).then(
    response => {
        next({...action, type: DEAL_COLLATERAL_RESPONSE, collateral: orDefault(() => response, [])})
    },
    message => next({...action, type: DEAL_COLLATERAL_ERROR, message})
  );
}

function dealDocumentsRequest(getState, next, action) {

  if (window.location.search == '?mock') {
    setTimeout(() => next({
      ...action,
      type: DEAL_DOCUMENTS_RESPONSE,
      documents: _.cloneDeep(SAMPLE_DEAL_DOCUMENTS)
    }));
     return;
  }

  API.get(`deal/${getOrganizationFromLocation()}/${action.trustNumber}/documents`).then(
    response => next({...action, type: DEAL_DOCUMENTS_RESPONSE, documents: response}),
    message => next({...action, type: DEAL_DOCUMENTS_ERROR, message})
  );
}

function dealByClassRequest(getState, next, action) {
    const params = {
            classIds: action.classParam
    };
    const { pageIdx, pageSize, sort } = action;
    let query = `page=${pageIdx}&max_results=${pageSize}`;
    if (sort) { // sort.field && sort.direction)
        const {field, direction} = sort;
        query += `&sortField=${field}&sortAsc=${direction === 'asc'}`;
    }
    API.postJson(`deal/${getOrganizationFromLocation()}/classes/${action.trustNumber}?${query}`,JSON.stringify(params)).then(
        response => next({...action, type: DEAL_BY_CLASS_RESPONSE, deal: response}),
        message => next({...action, type: DEAL_BY_CLASS_ERROR, message})
    );
}

export default reduceMiddleware({
  [DEAL_REQUEST]: dealRequest,
  [DEAL_ISSUANCE_REQUEST]: dealIssuanceRequest,
  [DEAL_COMPONENT_REQUEST]:dealComponentRequest,
  [DEAL_DATES_REQUEST]: datesRequest,
  [DEAL_AS_OF_REQUEST]: asOfRequest,
  [DEAL_COLLATERAL_COUNT_REQUEST]: collateralCountRequest,
  [DEAL_COLLATERAL_REQUEST]: collateralRequest,
  [DEAL_DOCUMENTS_REQUEST]: dealDocumentsRequest,
  [DEAL_TAX_REQUEST]: dealTaxRequest,
  [DEAL_TAX_FACTOR_COUNT_REQUEST]: dealTaxFactorCount,
  [DEAL_BY_CLASS_REQUEST]: dealByClassRequest,
  [DEAL_CLASS_COUNT_REQUEST]: dealClassCount,
  [DEAL_CLASS_CUSIPS_REQUEST]: dealClassCusips,
  [DEAL_PAGE_CHANGE_REQUEST]: pageChangeRequest,
});

const SAMPLE_DEAL_DOCUMENTS = [
 {
 'headingKey' : 'GNMA_REMIC_DELAY_FLOATERS',
 'document' : {
     'id': '9875',
     'name': 'deal-22.pdf',
     'effectiveDate' : '2017-04-22'
     },
 'correctionDocument' : {
     'id': '1234',
     'name': 'deal-22--corrected-25.pdf',
     'effectiveDate' : '2017-04-25'
     }
 },
 {
   'headingKey' : 'GNMA_REMIC_DELAY_FLOATERS',
   'document' : {
       'id': '9875',
       'name': 'deal-24.xls',
       'effectiveDate' : '2017-04-24'
     }
 },
 {
   'headingKey' : 'GNMA_REMIC_DELAY_FLOATERS',
   'document' : {
       'id': '9875',
       'name': 'deal-21.doc',
       'effectiveDate' : '2017-04-21'
     }
 },
 {
   'headingKey' : 'GNMA_REMIC_DELAY_FLOATERS',
   'document' : {
       'id': '9875',
       'name': 'deal-22.docx',
       'effectiveDate' : '2017-04-22'
    }
 }
];

const Tax_Data = {
    "data": []
}