import React from 'react';

import Table, { TYPES } from 'common/table/Table';
import Customizable from 'common/table/features/Customizable';
import Paginator, { loadPageWithCall } from 'common/table/features/Paginator';
import ButtonDropdown from 'common/button/ButtonDropdown';
import {
  commaThousands,
  currencyWithCents,
  decimal,
  formatDateMmDdYyyy,
  formatDateMmYyyy,
  integer
} from 'common/util/common-formatters';

import {
  DEFAULT_INIT_PAGE,
  DEFAULT_COLLATERAL_PAGE_SIZE
} from 'common/util/common-helpers';

import { cusipLink, rate } from 'www/util/www-formatters';
import { getOrgCode,
  getCollateralOptionSelector, paginate
} from 'www/util/www-helpers';

import { mapColumns } from 'www/util/labels';
import { getNewSort } from 'common/util/sorter';

import ENUMS from 'www/util/enumFormatters';
const {issuer} = ENUMS;

const ISSAUANCE_PLACEHOLDER='ISSUANCE';
const ONGOING_PLACEHOLDER='CURRENT';
export default class L2StructurePanel extends React.Component {
  
  
  onOptionsSelect=(changedOption)=>{
    console.log("inside onchange method");
    const { cusip, collateral } = this.props;

    if (changedOption !== collateral.selctedOption) {
      this.props.getCollateralCount({'cusip':cusip,'recordType':changedOption});
      this.props.getCollateral({'cusip':cusip,'recordType':changedOption}, DEFAULT_INIT_PAGE, DEFAULT_COLLATERAL_PAGE_SIZE, collateral.sort );
    }
  }

  componentDidMount() {
    const { cusip, collateral, details } = this.props;
    let {selctedOption} = collateral;
    if(collateral.selctedOption=== undefined){
      if (details && details.data && details.data.current && details.data.current.discType && details.data.current.discType!='ISSUANCE')
        selctedOption=ONGOING_PLACEHOLDER;
      else
        selctedOption=ISSAUANCE_PLACEHOLDER;
    }
    if (!collateral.data) {
      this.props.getCollateralCount({'cusip':cusip,'recordType':selctedOption});
      this.props.getCollateral({'cusip':cusip,'recordType':selctedOption}, DEFAULT_INIT_PAGE, DEFAULT_COLLATERAL_PAGE_SIZE, collateral.sort);
    }
  } 

  render() {

    const { cusip, collateral, getCollateral, details, changePage } = this.props;
    const { data, sort, isFetching, message, count, isFetchingMore } = collateral;
    let {selctedOption} = collateral;
    const selectOptions = (details && details.data && details.data.current && details.data.current.discType && details.data.current.discType!='ISSUANCE') ? [ISSAUANCE_PLACEHOLDER, ONGOING_PLACEHOLDER] : [ISSAUANCE_PLACEHOLDER];
    const {onOptionsSelect}=this;
    let hasRemicCollateral=details.data.issuance.hasRemicCollateral;

    if(collateral.selctedOption=== undefined){
      if (details && details.data && details.data.current && details.data.current.discType && details.data.current.discType!='ISSUANCE' )
        selctedOption=ONGOING_PLACEHOLDER;
      else
        selctedOption=ISSAUANCE_PLACEHOLDER;

    }
    if (message) {
      return <div className="empty-text">{message}</div>;
    } else if (isFetching || !data) {
      return <div className="loading more"><i className="fa fa-spinner fa-pulse"/></div>;
    }

    const pageSize = details.pageSize ? details.pageSize : data.pageSize;
    const pageIdx = details.pageIdx ? details.pageIdx : data.pageIdx;

    const gridConfig = {
      key: 'structure',
      className: 'structure-table',
      columnDefaults: {
        sortable: true
      },
      
      columns: [
        { key: 'prefix' },
        { key: 'secId' },
        { key: 'cusip', formatter:  (v, c, r) => cusipLink(v,c,r)}
        ],
        
      columnEmptyText: '-',
      emptyText: {
        key: 'collateralText',
        text: 'No collateral available'
      }
    };
    var titleStringNetRate='';
    var titleStringIssUpb='';
    var titleStringIssContrib='';
    var titleStringOngngContrib='';
    var titleStringIntRate='';
    var titleStringRmm='';
    var titleStringLnAge='';
    
    
        if(selctedOption=='CURRENT'){
           titleStringNetRate='Collateral Current WA Net Interest Rate - the weighted average net interest rate of the loans as of the current reporting period';
           titleStringIssUpb='Collateral Issuance Investor Security UPB - the unpaid principal balance of the loans as they contribute to the balance of the security at the time of securitization';
           titleStringIssContrib='Collateral Issuance Contributing Investor Security UPB - the pro-rata share of the Investor Security UPB - Issuance amount as of the current reporting period';
           titleStringOngngContrib='Collateral Current Contributing Investor Security UPB - the pro-rata share of the Investor Security UPB - Current amount as of the current reporting period';
           titleStringIntRate='Collateral WA Interest Rate - the weighted average interest rate at the time as of the current reporting period';
           titleStringRmm='Collateral WA Remaining Months to Maturity - the weighted average number of scheduled monthly payments that will reduce the Current Investor Loan UPB to zero as of the current reporting period';
           titleStringLnAge='Collateral WA Loan Age - the weighted average number of scheduled payments from the time the loan was originated or modified up to and including the current reporting period as of the current reporting period';
        }else{
           titleStringNetRate='Collateral Current WA Net Interest Rate - the weighted average net interest rate of the loans at the time of resecuritization';
           titleStringIssUpb='Collateral Issuance Investor Security UPB - the unpaid principal balance of the loans as they contribute to the balance of the security at the time of securitization';
           titleStringIssContrib='Collateral Issuance Contributing Investor Security UPB - the pro-rata share of the Investor Security UPB - Issuance amount at the time of resecuritization';
           titleStringOngngContrib='Collateral Current Contributing Investor Security UPB - the pro-rata share of the Investor Security UPB - Current amount at the time of resecuritization';
           titleStringIntRate='Collateral WA Interest Rate - the weighted average interest rate at the time of resecuritization';
           titleStringRmm='Collateral WA Remaining Months to Maturity - the weighted average number of scheduled monthly payments that will reduce the Current Investor Loan UPB to zero at the time of resecuritization';
           titleStringLnAge='Collateral WA Loan Age - the weighted average number of scheduled payments from the time the loan was originated or modified up to and including the current reporting period at the time of resecuritization';
        }
      gridConfig.columns.push({ key: 'netRate', formatter: rate, className: 'right', titleNoLabel: titleStringNetRate });
	const restColumns=[
		{ key: 'issuer', formatter: issuer },
        { key: 'issueDt', formatter: formatDateMmDdYyyy },
        { key: 'maturity', formatter: formatDateMmYyyy },
        { key: 'upb', formatter: currencyWithCents, className: 'right', titleNoLabel: titleStringIssUpb },
        { key: 'upbContribIssuance', formatter: currencyWithCents, className: 'right', titleNoLabel: titleStringIssContrib  },
        { key: 'upbContribCurrent', formatter: currencyWithCents, className: 'right', titleNoLabel: titleStringOngngContrib },
        { key: 'rate', formatter: rate, className: 'right', titleNoLabel:  titleStringIntRate},
        { key: 'rmm', formatter: integer, className: 'right', titleNoLabel: titleStringRmm },
        { key: 'lnAge', formatter: integer, className: 'right', titleNoLabel: titleStringLnAge }
      
	];
	restColumns.forEach((ob)=>gridConfig.columns.push(ob));
    const rbmColumns = [
  		{ key: 'trustNumber' },
  		{ key: 'classId' },
  		{ key: 'groupId' }
	];
	if(hasRemicCollateral != null && hasRemicCollateral=='YES'){
	rbmColumns.forEach((ob)=>gridConfig.columns.push(ob));
		
	}
    mapColumns('l2-collateral', gridConfig.columns);

    const config = {
      className: 'collateral-table',
      title: count !== null ? <span>This security contains <strong>{commaThousands(count)} SECURITIES</strong> as collateral.</span> : null,
      customizingTitle: 'Customizing All Security Level Collateral',
      table: {
        ...gridConfig,
        onHeaderClick: (column, sortKey) => {
          const newSort = getNewSort(sort, column.key, ((sort && sort.direction) || 'asc'));
          getCollateral({'cusip':cusip,'recordType':selctedOption}, DEFAULT_INIT_PAGE, pageSize, newSort);
        },
        isDealOrL2CollateralTable: true
      }
    };
    config.buttons = [ optionSelector() ];
    const recordType = ISSAUANCE_PLACEHOLDER === selctedOption ? ISSAUANCE_PLACEHOLDER : ONGOING_PLACEHOLDER;
    let exportLink = `/api/security/${getOrgCode()}/${cusip}/collateral?export=true&recordType=${recordType}`;
    let exportName = '${details.data.issuane.secId}_${cusip}_COLLAT_${recordType}.txt';
    let exportAll = true;
    let anonymousExport = false;
    const paginatedCollateral = paginate(data.collateral, pageIdx, pageSize);

    return (
      <div>
        <Table type={TYPES.simple}
               features={[Customizable, Paginator]}
               {...this.props}
               config={config}
               data={paginatedCollateral.map(col => ({...col, ...col.armFields, ...col.rplFields}))}
               sort={sort}
               threshold={50}
               count={count}
               isFetchingMore={isFetchingMore}
               pageIdx={pageIdx}
               pageSize={pageSize}
               pageViewName={'securities'}
               exportAll={exportAll}
               anonymousExport={anonymousExport}
               loadPage={(collateral.data && collateral.count) ? loadPageWithCall(changePage, {'cusip':cusip,'recordType':selctedOption}, pageSize)
                   : loadPageWithCall(getCollateral, {'cusip':cusip,'recordType':selctedOption}, pageSize, sort)}
               exportLink={exportLink}
               exportName={exportName} />
      </div>
    );
    function optionSelector(){
      const options=getCollateralOptionSelector(selctedOption,selectOptions, onOptionsSelect);
      return (
              <div className="deal-tranch-month-selector right">
                <ButtonDropdown {...options} />
              </div>
              );
    }
  }
}
