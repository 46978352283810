import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';
import classNames from 'classnames';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SimpleTable, { getVisibleColumns } from 'common/table/SimpleTable';
import DraggableHeaderCell from 'common/table/draggable/HeaderCell';

class DraggableTable extends Component {
  state = {
    ghost: {}
  };

  moveGhost = (key, beforeAfter, index) => {
    this.setState({
      ghost: {
        key,
        beforeAfter,
        index
      }
    });
  };

  getColumnsWithGhost = () => {
    const { config, isOver } = this.props;
    const { ghost: { key, beforeAfter } } = this.state;

    return config.columns.map((column) => {
      return {
        ...column,
        ghost: column.key === key && isOver ? beforeAfter : null,
        moveGhost: this.moveGhost.bind(this, column.key)
      };
    });
  };

  render() {

    const { config, isOver, connectDropTarget } = this.props;
    const props = {
      showRows: false,
      showSummaryRows: false,
      headerCellType: DraggableHeaderCell,
      config: {
        ...config,
        className: classNames(config.className, {'over-target': isOver}),
        columns: this.getColumnsWithGhost()
      }
    };


    if (getVisibleColumns(this.props).length > 0) {
      const ref = (instance) => {
        if (!instance) {
          return;
        }

        connectDropTarget(findDOMNode(instance));
      };

      return (
              <SimpleTable {...this.props} {...props} ref={ref} />
      );
    } else {
      const className = classNames('empty-customizing-table', {'over-target': isOver});
      return connectDropTarget(
        <div className={className}>
          <div className="no-fields-message">Drag columns here to include them</div>
        </div>
      );
    }
  }
}

export default  DropTarget(
  props => ['visible-column', 'hidden-column'],
  {
    drop(props, monitor, component) {
      const { beforeAfter = 'before', index = 0} = component.state.ghost;
      const key = monitor.getItem().id;

      props.config.dropFunction(key, beforeAfter === 'before' ? index : index + 1);
    }
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  })
)(DraggableTable)