import React, { Component } from 'react';
import ButtonDropdown from 'common/button/ButtonDropdown';
import Table, { TYPES } from 'common/table/Table';
import { COLUMN_TYPES } from 'common/table/SimpleTable';
import Customizable from 'common/table/features/Customizable';
import { formatDateMmDdYyyy,currencyWithCents, percent, percentNoSymbol, zeroToEmpty } from 'common/util/common-formatters';
import { getOrganizationFromLocation, createGroupClassNameGenerator, sortDesc, toLookup, maxKey, sortBy,
  SORT_DESCENDING,SORT_ASCENDING } from 'common/util/common-helpers';
import { factor, taxFactor,cusipLink } from 'www/util/www-formatters';
import { mapColumns } from 'www/util/labels';
import { isRemicSecurity, getOrganizationCodeFromLocation, isFreddie, getIssuerLegalAddress } from 'www/util/www-helpers';
import { HeaderField } from 'www/components/details/DetailHeader';
import Paginator, { loadPageWithCall } from 'common/table/features/Paginator';
import {  
  DEFAULT_INIT_PAGE,
  DEFAULT_TAX_FACTOR_PAGE_SIZE,
  IS_75_DAY_SECURITY
} from 'common/util/common-helpers';

export default class DetailTaxFactorTable extends Component {
  
    constructor(props) {
        super(props);
        this.gridSecClassConfig = {
                key: 'tax',
                columnDefaults: {
                    sortable: true
                },
                columns: [
                    { key: 'accrualPeriodDays', flex: 0.5, className: 'right' },
                    { key: 'accrualPeriodStartDate', formatter: formatDateMmDdYyyy },
                    { key: 'accrualPeriodEndDate', formatter: formatDateMmDdYyyy },
                    { key: 'taxPaymentDate', formatter: formatDateMmDdYyyy },
                    { key: 'endingUPBFactor', formatter: factor, className: 'right' },
                    { key: 'qsiFactor', formatter: taxFactor, className: 'right' },
                    { key: 'oidFactor', formatter: taxFactor, className: 'right' },
                    { key: 'otherIncomeExpenseFactor', formatter: taxFactor, className: 'right' },
                    { key: 'sec212Factor', formatter: taxFactor, className: 'right' },
                    { key: 'beginningAipFactor', formatter: taxFactor, className: 'right' },
                    { key: 'mdar', formatter: taxFactor, className: 'right' },
                    { key: 'realEstatePct', flex: 0.5, formatter: percentNoSymbol, precision: 2, className: 'right' }
                ],
                emptyText: 'No tax data available'
            };

            mapColumns('tax', this.gridSecClassConfig.columns);
    }
    
    componentDidMount() { 
      this.props.getTaxCount(this.props.cusip);
      this.props.fetchTaxData(this.props.cusip,DEFAULT_INIT_PAGE, DEFAULT_TAX_FACTOR_PAGE_SIZE);
      
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        document.body.scrollTop = 0;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }
    
    // new method to extract all Cusip tax data
    getCusipTaxData(yearsData, footerText, parentCusip){
      if(!yearsData || yearsData.length == 0 ){
        return undefined;
      }
      
      let cusipListTaxData = [];
      let comboCusip=parentCusip;
      yearsData
        .forEach(years => Object.keys(years)
        .forEach(yr => {
          years[yr].forEach((dta,idx)=>{
            let cusip=dta.cusip;
              let currCusipData = cusipListTaxData.filter(x=>x.cusip==cusip);
              if(currCusipData.length == 0) {
                currCusipData = {'cusip':cusip,'data':[], 'issuer':dta.issuer, 'trustTin': dta.mortgageBackedSecurityTaxIdentifier, 'issueAmount': dta.issueAmount,
                        'footerText': footerText[dta.classificationType]};
                cusipListTaxData.push(currCusipData);
              }else{
                currCusipData = currCusipData[0];
                currCusipData.trustTin = dta.mortgageBackedSecurityTaxIdentifier;
              }
              let currCusipYrData = currCusipData.data[yr] || [];
              currCusipYrData.push(dta);
              currCusipData.data[yr] = currCusipYrData;
           })}));
           
        let combo=cusipListTaxData.filter(x=>x.cusip==comboCusip);
        //holds the information for security matching parent cusip
        let listWithoutCombo= cusipListTaxData.filter(x=>!(x.cusip==comboCusip));
        let latestCombo = (combo && combo.length>0) ? sortBy([...Object.values(combo[0].data)[[...Object.values(combo[0].data)].length-1]], item => item.reportingPeriod, SORT_DESCENDING)[0] : {};
        let isCombo = (listWithoutCombo && listWithoutCombo.length>0);

       return {'COMBO': latestCombo , "COLLATERALS": cusipListTaxData, "IS_COMBO":isCombo, 'ALL_COMBO':combo}
    };
       
    render() {
      const { cusip, tax, fetchTaxData, settings, taxSort, taxYearSelect, details } = this.props;
      const { footerText, url, selectedYears, sorts, isFetching } = tax;
      let { data, pageIdx, pageSize} = tax;
      const singleClassURL ="http://www.freddiemac.com/mbs/html/sd_pc_lookup.html";
      const multiClassURL ="http://www.freddiemac.com/mbs/html/sd_remic_lookup.html";
      const historyTaxLabel="Historical Tax Disclosures (pre-2019).";
      const hasRemicFields = details && details.data && details.data.issuance && details.data.issuance.remicFields;
      let recombinableExplanation;
      let table;

      if (isFetching) {
        return <div className="loading"><i className="fa fa-spinner fa-pulse"/></div>;
      }

      const taxSortProxy = column => taxSort(column, securityIndex);
      let additionalYears = 0;
      let cusipsFromTax = [];
     
        // top table grid config
        const gridConfig = {             
            table: {
                className: 'results deal-table;',
                parentProps: this.props,
                gridSecClassConfig: this.gridSecClassConfig,
                onLoginClick: this.props.onLoginClick,
                taxSortProxy: taxSortProxy,               
                columnDefaults: {
                    sortable: false
                },
                key: 'tax',
                uniqueKey: 'cusip',
                columns: [
                    {
                        key: 'cusip',
                        formatter: cusipLink,
                        className: 'center header-center'
                    },
                    {
                        key: 'expandCollapse',
                        type: COLUMN_TYPES.EXPANDER
                    },
                    {
                        key: 'trustTin',
                        className: 'center header-center'
                    },
                    {
                        key: 'issueAmount',
                        formatter: currencyWithCents,
                        className: 'center header-center'
                    }

                ],
                expandedComponent,
                columnEmptyText: '-',
                emptyText: 'No tax data available'
            }
        };
        mapColumns('tax', gridConfig.table.columns);

        const  timeStamp = new Date();
        const currentYear = timeStamp.getFullYear();
        const currentMonth = timeStamp.getMonth() + 1;
        if(currentMonth === 1) {
            additionalYears = 1;
        }
        
        let comboContent = '<div>Loading...</div>';
        let securityIndex = 0;
        let cusipTaxData = this.getCusipTaxData(data, footerText,cusip);
        let comboTaxData = {};
        let coltTaxData = {};
        
        let comboType=false;

        if(cusipTaxData != undefined){
          // extracting Combo class Tax Info
          comboTaxData = cusipTaxData['COMBO'];
          // extracting remaining collaterals Tax Info
          coltTaxData = cusipTaxData['COLLATERALS']; 
          let regularTaxData;
          let sortedCusipTaxInfo;
          
          comboType = cusipTaxData['IS_COMBO'];
          let allTaxData=cusipTaxData['ALL_COMBO'];
          if(!comboType){
                /* if the Data is not Combo class, i.e regular Security */
                regularTaxData  = Object.values(allTaxData[0].data);
                //copy values only to use index to find last
                const cusipTaxData = Object.values((regularTaxData && regularTaxData.length > 0? (regularTaxData[regularTaxData.length-1] || {}) : {}));
                regularTaxData  = allTaxData[0].data;
                //capy fully to persist year as key
                sortedCusipTaxInfo = sortBy(cusipTaxData, item => item.reportingPeriod, SORT_DESCENDING);
                //comboTaxData = Object.values(sortedCusipTaxInfo)[0];
                //set last one as current data
                coltTaxData = [];
          }
         
          const trustId = comboTaxData.trustId ? comboTaxData.trustId : '';
          const classId = comboTaxData.classId ? comboTaxData.classId : '';
          const isRemic = comboTaxData.securityType ? isRemicSecurity({secType: comboTaxData.securityType}) : false;
          const idValue = isRemic ? `${trustId} ${classId}` : comboTaxData.secId;
          let cusipFormatter = cusipLink;
          if (cusip === comboTaxData.cusip || comboTaxData.doNotDiscloseType === 'SUPPRESS_ALL_DISCLOSURES') {
              cusipFormatter = null;
          }
       // header definition
          const headers =
              [
                  <HeaderField colKey="cusip" label="CUSIP" value={comboTaxData.cusip} formatter={cusipFormatter}/>,
                  <HeaderField colKey="headerId" label="Pool/Series and Class" value={idValue}/>,
                  <HeaderField colKey="classificationType" label="Security Classification"
                               value={comboTaxData.classificationType}/>
              ];
          const dynamicHeaders = [];
          const histTaxUrl  = isRemic ? multiClassURL : singleClassURL;
          let yearSelector;
          if (isFreddie()){
            dynamicHeaders[dynamicHeaders.length] = <HeaderField colKey="issueAmount1" 
              label= {historyTaxLabel} 
              value={<a href = {histTaxUrl} 
              target ="_blank">{histTaxUrl} </a>} 
              formatter={currencyWithCents} />
          }
          headers.splice(headers.length,
            0,
            [
              <HeaderField colKey="reportingType" label="Class Issued with" value={comboTaxData.reportingType} />,
              <HeaderField colKey="qualStatedIntType" label="Interest Class" value={comboTaxData.qualStatedIntType} />,
              <HeaderField colKey="interestAccrualMethod" label="Interest Accrual Method" value={comboTaxData.interestAccrualMethod} />,
              <HeaderField colKey="issueAmount" label="Original Amount" value={comboTaxData.issueAmount} formatter={currencyWithCents} />
            ],
            dynamicHeaders);

          if (comboType) {
            recombinableExplanation = <div className="tax-recombinable-expl">
                {getRecombinableExplanationText(hasRemicFields)}
              </div>;

            table = null;

          } else {
          
            // dropdown functionality
            const cusipMultiYearTaxData = regularTaxData; // currentYearCusipTaxData[0].data;
            const taxYearSelectProxy = obj => {                
              obj.securityIndex = securityIndex;                
              return taxYearSelect(obj);
            };
            
            let selectedYear = Number(selectedYears[securityIndex]);
            let years = Object.keys(cusipMultiYearTaxData);
            if(!selectedYear){
              selectedYear = Math.max(...years);
            }
            years.sort((a,b) => b - a);
            
            
            const yearOptions = years.map(year => ({
              text: year,
              year,
              onClick: taxYearSelectProxy,                
              disabled: year === selectedYear.toString()
            }));              
            
            yearSelector = <ButtonDropdown label={selectedYear} options={yearOptions} />;

            let selectedYearData = regularTaxData[selectedYear] ; 
            
            const previousYearData = regularTaxData[selectedYear-1] || regularTaxData[selectedYear+1] || [];
            const nextYearData = regularTaxData[selectedYear+1] || [];
            
            const is75DaySecurity = IS_75_DAY_SECURITY === selectedYearData[0].is75DaySecurity;
            
            const isSelYearFirstQuarterDisclosed = ( 1 < selectedYearData.length || (selectedYearData.length === 1 && selectedYearData[0].taxMonth !== 1)) ? true : false;
                                
            if(1 == years.length && 2 >= selectedYearData.length && (selectedYearData[0].taxMonth === 1 || selectedYearData[0].taxMonth === 2)) {
               if(!is75DaySecurity ) {
                  if (2<= selectedYearData.length){
                   //non 75 day and has 2 or more records... then show records
                  }else {
                    if(1== selectedYearData.length && selectedYearData[0].taxMonth === 1 && selectedYearData[0].taxQuarter === 4){
                      return <div> Security Data for Searched Year Not Yet Available </div>;
                    }
                  }
                        
                 
               }else {                 
                 return <div> Security Data for Searched Year Not Yet Available </div>;
               }
              
            }
            
            // Remove duplicates
            let result = [];
            let found = [];
            selectedYearData.forEach(x=>{
              
              if(!found[x.reportingPeriod]===true){
                found[x.reportingPeriod] = true;
                result.push(x);
              }
            });
              
            selectedYearData = result;

          let hasSort = sorts && sorts.length > 0 && sorts[securityIndex]

          let sortField = hasSort ? sorts[securityIndex].field: '';
          let sortAsc = hasSort ? sorts[securityIndex].direction.toUpperCase(): '';
            // inner Tax Table for non combo showing info for currently searched cusip
          const tableProps = {                         
                  type: TYPES.simple,
                  config: {
                      title: <span>  </span>,
                      table: {
                          ...this.gridSecClassConfig ,
                          onHeaderClick: taxSortProxy
                      }
                  },
                  data: selectedYearData ,
                  sort: sorts && sorts.length > 0 ? sorts[0]: undefined,
                  features: [Customizable],
                  exportLink: `/api/tax/${getOrganizationCodeFromLocation()}/${comboTaxData.cusip}/${selectedYear}/taxexport?sortField=${sortField}&sortAsc=${sortAsc}`,
                  exportName: `tax.xls`
              };
            table = <Table {...tableProps} {...this.props}  />;
          }
          let footer = footerText[comboTaxData.classificationType];
          //this ends up being used only if one cusip returned
            comboContent = <div className={`tax-container security-index-${securityIndex}`} key={'sec' + securityIndex}>
                    <div className="tax-header">
                    <ul className="tax-col-2">
                        <HeaderField colKey="mortgageBackedSecurityTaxIdentifier" label="Trust TIN" value={comboTaxData.mortgageBackedSecurityTaxIdentifier} />
                        <HeaderField colKey="issuerLegalAddress" label="Issuer" value={getIssuerLegalAddress(settings, getOrganizationCodeFromLocation())} />
                    </ul>
                    <ul className="tax-col-1">
                        { headers }
                    </ul>
                    { comboType ? recombinableExplanation: '' }
                </div>
                 <div className="tax-table">
                 {yearSelector ? <div className="table-header"> <span>Viewing tax factors for:{yearSelector} </span>  </div> : <div> </div> }
                { table }
               </div>
              <div>
                { footer && footer.length > 0 ?
                  <div>Notes:</div> : ''
                }
                { footer && footer.length > 0 ?
                  footer.map(value=><div>{value}</div>) : ''
                }
              </div>
            </div>;
        }
        //if data undefined, display not yet available
        //if comboTaxData is empty(meaning all data is in child cusips) or if it's marked as combo cusip,
        //  display only combo message or null followed by table for child cusips
        //if comboTaxData is not empty and not a real combo, then display comboContent(which is actually non combo data)
        //then if coltTaxData has data display drill down table for cusips which will happen for combo or
        //  can also happen for cases where more than searched for cusip is returned unexpectedly(like a strip with relationships saved)
        return cusipTaxData == undefined ?
                <div>
                  <div> Security Data for Searched Year Not Yet Available </div>
                  { isFreddie() ? <div>{historyTaxLabel}     <a href={singleClassURL} target="_blank">{singleClassURL}</a></div> : '' }
                </div>
                :
                    (                        
                  <div className="tax-full-container">
                  {((undefined != comboTaxData && _.isEmpty(comboTaxData)) || comboType)? <div>{ comboType ? recombinableExplanation: '' }</div> : comboContent }
                   { coltTaxData.length <= 0 ? <div> </div> : 
         ( <div  className="tax-table">
            <Table  key = {pageIdx}
              type={TYPES.simple}
            /* Outer Table with pagination */
              features={[Paginator]}
              {...this.props}              
              config={gridConfig}
              pageIdx={pageIdx}
              pageSize={pageSize}
              count = {this.state ? this.state.txCount : 0}
              loadPage={loadPageWithCall(fetchTaxData, cusip, pageSize )}
              data={coltTaxData}
            />
          </div> )}
        </div>             
   
        );

        function expandedComponent(row, rowIdx, data, config, props) {
            return (
                <li className="row-expanded" key={'rowExpanded' + rowIdx}> {
                     renderSecurityTax(data.filter(nxt=>nxt.cusip==row.cusip), config, rowIdx, props)
                   }
                </li>
            );
            
            function renderSecurityTax(currentYearCusipTaxData,config, securityIndex, props){
              let {sorts, footerText} = props.tax
              const gridSecClassConfig = config.gridSecClassConfig;
              const taxSortProxy = column => taxSort(column, securityIndex);
              const onLoginClick = config.onLoginClick;
              const cusipMultiYearTaxData = currentYearCusipTaxData[0].data;
              const taxYearSelectProxy = obj => {                
                obj.securityIndex = securityIndex;                
                return taxYearSelect(obj);
              };
              
              let selectedYear = Number(selectedYears[securityIndex]);
              let years = Object.keys(cusipMultiYearTaxData);
              if(!selectedYear){
                selectedYear = Math.max(...years);
              }
              years.sort((a,b) => b - a);

              const yearOptions = years.map(year => ({
                text: year,
                year,
                onClick: taxYearSelectProxy,                
                disabled: year === selectedYear.toString()
              }));              
              
              const yearSelector = <ButtonDropdown label={selectedYear} options={yearOptions} />;
              let recentYear=0;
              Object.keys(cusipMultiYearTaxData).forEach(x=>{
              if(x>recentYear){
              	recentYear=x;
              }
              });
             
              let selectedYearData = cusipMultiYearTaxData[selectedYear] ; 
              const previousYearData = cusipMultiYearTaxData[selectedYear-1] || cusipMultiYearTaxData[selectedYear+1] || [];
              const nextYearData = cusipMultiYearTaxData[selectedYear+1] || [];
              
             
              const is75DaySecurity = IS_75_DAY_SECURITY === selectedYearData[0].is75DaySecurity;
              
              const isSelYearFirstQuarterDisclosed = ( 1 < selectedYearData.length || (selectedYearData.length === 1 && selectedYearData[0].taxMonth !== 1)) ? true : false;

              // Remove duplicates
              let result = [];
              let found = [];
              selectedYearData.forEach(x=>{
                if(!found[x.reportingPeriod]===true){
                  found[x.reportingPeriod] = true;
                  result.push(x);
                }
              });
                  
              selectedYearData = result;              

              // header definition coltTaxData
                           
                // latest cusipTax data
                //let latestCusipTaxData = sortBy([...selectedYearData], item => item.reportingPeriod, SORT_DESCENDING)[0];
                let latestCusipTaxData = sortBy([...cusipMultiYearTaxData[recentYear]], item => item.reportingPeriod, SORT_DESCENDING)[0];
                 let table;
                 
                 const trustId = latestCusipTaxData.trustId ? latestCusipTaxData.trustId : '';
                 
                 const classId = latestCusipTaxData.classId ? latestCusipTaxData.classId : '';
                 const isRemic = latestCusipTaxData.securityType ? isRemicSecurity({secType: latestCusipTaxData.securityType}) : false;
                 const idValue = isRemic ? `${trustId} ${classId}` : latestCusipTaxData.secId;
                 let cusipFormatter = cusipLink;
                 
                 if (cusip === latestCusipTaxData.cusip || latestCusipTaxData.doNotDiscloseType === 'SUPPRESS_ALL_DISCLOSURES') {
                     cusipFormatter = null;
                 }

                 const headers =
                     [
                         <HeaderField colKey="cusip" label="CUSIP" value={latestCusipTaxData.cusip} row={latestCusipTaxData} formatter={cusipFormatter}/>,
                         <HeaderField colKey="headerId" label="Pool/Series and Class" value={idValue}/>,
                         <HeaderField colKey="classificationType" label="Security Classification"
                                      value={latestCusipTaxData.classificationType}/>
                     ];
                 const dynamicHeaders = [];
                 const histTaxUrl  = isRemic ? "http://www.freddiemac.com/mbs/html/sd_remic_lookup.html" : "http://www.freddiemac.com/mbs/html/sd_pc_lookup.html" ;
                 
                 if (isFreddie()){
                     dynamicHeaders[dynamicHeaders.length] = <HeaderField colKey="issueAmount1"
                     label="Historical Tax Disclosures (pre-2019)." 
                     value={<a href = {histTaxUrl} 
                     target ="_blank">{histTaxUrl} </a>} 
                     formatter={currencyWithCents} />
                 }
                 headers.splice(headers.length,
                   0,
                   [
                     <HeaderField colKey="reportingType" label="Class Issued with" value={latestCusipTaxData.reportingType} />,
                     <HeaderField colKey="qualStatedIntType" label="Interest Class" value={latestCusipTaxData.qualStatedIntType} />,
                     <HeaderField colKey="interestAccrualMethod" label="Interest Accrual Method" value={latestCusipTaxData.interestAccrualMethod} />,
                     <HeaderField colKey="issueAmount" label="Original Amount" value={latestCusipTaxData.issueAmount} formatter={currencyWithCents} />
                   ],
                   dynamicHeaders);
                 let hasSort = sorts && sorts.length > 0 && sorts[securityIndex]
                 let sortField = hasSort? sorts[securityIndex].field: '';
                 let sortAsc = hasSort? sorts[securityIndex].direction.toUpperCase(): '';
                 // inner Tax Table expanded view
                 const tableProps = {                         
                         type: TYPES.simple,                         
                         config: {
                             title: <span>  </span>,
                             table: {
                                 ...gridSecClassConfig ,
                                 onHeaderClick: taxSortProxy
                             }
                         },
                         features: [Customizable],
                         exportLink: `/api/tax/${getOrganizationCodeFromLocation()}/${latestCusipTaxData.cusip}/${selectedYear}/taxexport?sortField=${sortField}&sortAsc=${sortAsc}`,
                         exportName: `tax.xls`,
                         data: selectedYearData,
                         sort: sorts && sorts.length > 0 ? sorts[securityIndex]: undefined
                     };
                 
                 table = <Table key={pageIdx}  {...tableProps} {...config.parentProps} onLoginClick={onLoginClick}  />;
                 let footer = footerText[latestCusipTaxData.classificationType];

                   const securityContent = <div className={`tax-container security-index-${securityIndex}`} key={'sec' + securityIndex}>
                   <div className="tax-header">
                   <ul className="tax-col-2">
                       <HeaderField colKey="mortgageBackedSecurityTaxIdentifier" label="Trust TIN" value={latestCusipTaxData.mortgageBackedSecurityTaxIdentifier} />
                       <HeaderField colKey="issuerLegalAddress" label="Issuer" value={getIssuerLegalAddress(settings, latestCusipTaxData.issuer)} />
                   </ul>
                   <ul className="tax-col-1">
                       { headers }
                   </ul>
                   
               </div>
               <div  className="tax-table">
               <div className="table-header year-dropdown"> <span>Viewing tax factors for:{yearSelector} </span>  </div>
                   { table }
                   <br></br>
               </div>              

               <div>
                 { footer && footer.length > 0 ?
                   <div>Notes:</div> : ''
                 }
                 { footer && footer.length > 0 ?
                   footer.map(value=><div>{value}</div>) : ''
                 }
               </div>
               <br></br>
           </div>;
             return securityContent;    
               };
        }     
    } 
 
}

const getRecombinableExplanationText = (hasRemicFields) => {

  if (isFreddie()) {
    if (hasRemicFields){ // Security is FRE L3
      return <>
        <p>The above security is a combination class which represents a beneficial interest in one or more underlying classes and are shown below in succession.</p>
        <p>Please refer to the Offering Circular Supplement for the Series and Classes shown below for additional information and consult your tax advisor regarding proper tax treatment.</p>
      </>
    }

    // Security is FRE Reverse REMIC
    return <>
      <p>The above security represents a beneficial interest in one or more underlying classes and are shown below in succession.</p>
      <p>Please refer to the Pool Supplement for additional information and consult your tax advisor regarding proper tax treatment.</p>
    </>;
  }

  // Security is FNM RCR
  if (hasRemicFields) {
    return <>
      <p>The above security is a combination class which represents a beneficial interest in one or more underlying classes and are shown below in succession.</p>
      <p>Please refer to the Prospectus Supplement for the CUSIPs shown below for additional information and consult your tax advisor regarding proper tax treatment.</p>
    </>;
  }

  // Security is FNM RBM
  return <>
    <p>The above security represents a beneficial interest in one or more underlying classes that are shown below in succession.</p>
    <p>Please refer to the Prospectus Supplement for the CUSIPs shown below for additional information and consult your tax advisor regarding proper tax treatment.</p>
  </>;
}
