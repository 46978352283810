import {
  CURRENT_USER_REQUEST, LOGOUT_RESPONSE,
  CHECK_REGISTERED_REQUEST, CHECK_REGISTERED_RESPONSE, CHECK_REGISTERED_ERROR,
  REGISTER_SHOW, REGISTER_REQUEST, REGISTER_RESPONSE, REGISTER_ERROR,
  LOGIN_REQUEST, LOGIN_RESPONSE, LOGIN_ERROR, LOGIN_INACTIVE_MESSAGE,
  FORGOT_REQUEST, FORGOT_RESPONSE, FORGOT_ERROR,
  LOGOUT_REQUEST,ADMIN_LOGOUT_REQUEST, SHOW_LOGIN, SHOW_LOGIN_MESSAGE, HIDE_LOGIN,
  UPDATE_CREDENTIALS_ERROR, UPDATE_CREDENTIALS_SUCCESS, IDLE_LOGOUT, CANCEL_IDLE_LOGOUT,
  TERMS_ACCEPTED,VALIDATE_CSRF_REQUEST, VALIDATE_CSRF_RESPONSE,VALIDATE_CSRF_ERROR
} from 'common/login/loginActions';
import { reducerGenerator, reduceReducers } from 'common/util/redux';
import { SETTINGS_RESPONSE } from 'www/actions/settingsActions';
import { getEmailActivationMsg } from 'common/login/login-messages';
import { createTCCookie  } from 'www/util/tc-helpers';


export const FORM_OPTIONS = {
  STEP_ONE: 'step-one',
  REGISTER: 'register',
  LOGIN: 'login',
  MESSAGE: 'message',
  DONE: 'done',
  ACCEPT_TERMS: 'terms'
};

function onCheckRegistered(state, action){
	const targetForm =  action.response.available ? FORM_OPTIONS.REGISTER : 
		action.response.emailValidated ? FORM_OPTIONS.LOGIN : FORM_OPTIONS.MESSAGE;
	
	return {
	    ...state,
	    form: targetForm,
	    message: targetForm == FORM_OPTIONS.MESSAGE ? getEmailActivationMsg() : '' ,
	    email: action.email,
	    loggedIn: false,
	    loginVisible: true
	  };
}

function onLoginInactive(state, action){
	
	return {
	    ...state,
	    form: FORM_OPTIONS.MESSAGE,
	    message: getEmailActivationMsg(),
	    email: '',
	    loggedIn: false,
	    loginVisible: true
	  };
}

function onShowRegister(state, action){
	const targetForm =  FORM_OPTIONS.REGISTER;
	
	return {
	    ...state,
	    form: targetForm,
	    message: targetForm == FORM_OPTIONS.MESSAGE ? getEmailActivationMsg() : '' ,
        isFetching: false,
	    loggedIn: false,
	    loginVisible: true
	  };
}

function onForgotPwd (state, action){ 
  
  return {
      ...state,
      message: 'If a valid email address was provided, an email has been sent including instructions on how to reset your password.',
      hasError: false,      
      loggedIn: false,
      loginVisible: true
    };
}

function onRegisterResponse(state, action){
	return {
  	  ...state,
  	  hasError: false,
  	  form: FORM_OPTIONS.MESSAGE,
      message: getEmailActivationMsg(),
      loggedIn: false,
      loginVisible: true
	  };
}

function termsAccepted(state, action) {
    createTCCookie();

  return {
    ...state,
    form: FORM_OPTIONS.DONE,
    termsLastAccepted: new Date().toString()
  };
}
function onLogin(state, action) {
  if (state.pendingAction) {
    state.pendingAction();
  }

  let form = FORM_OPTIONS.DONE;
  let loginVisible = false;

  try {
    const updatedDate = Date.parse(state.termsAndConditionsLastUpdated);
    const acceptedDate = Date.parse(action.response.termsAndConditionAcceptDate);

    if (isNaN(acceptedDate) || updatedDate >= acceptedDate) {
      form = FORM_OPTIONS.ACCEPT_TERMS;
      loginVisible = true;
    } else {
      createTCCookie();
    }
    
    
  } catch (e) {
    console.warn('System not properly reporting terms and conditions acceptance/updated dates.');
  }

  return {
    ...state,
    loggedIn: true,
    loginVisible,
    form,
    updateCredentialsMessage: '',
    pendingAction: null,
    checkedLoggedIn: true,
    isFetching: false,
    termsLastAccepted: action.response && action.response.termsAndConditionAcceptDate
  };
}

function onSettings(state, action) {
  const newState = {...state, termsAndConditionsLastUpdated: action.response.termsAndContitionsUpdatedDate};

  if (state.loggedIn) {
    return onLogin(newState, {response: {termsAndConditionAcceptDate: state.termsLastAccepted}});
  }

  return newState;
}

const initialState = {
  isFetching: false,
  form: FORM_OPTIONS.LOGIN,
  message: '',
  idleMessage: '',
  loggedIn: false,
  loginVisible: false,
  checkedLoggedIn: false,
  updateCredentialsMessage: '',
  pendingAction: null,
  showIdleLogout: false
};

const defaultRequestState = {
  message: '',
  isFetching: true,
  loggedIn: false,
  loginVisible: true,
  updateCredentialsMessage: ''
};

const defaultErrorState = {
  isFetching: false,
  loggedIn: false,
  updateCredentialsMessage: '',
  loginVisible: true
};

const defaultResponseState = {
  message: '',
  isFetching: false,
  loggedIn: true,
  updateCredentialsMessage: '',
  loginVisible: false
};

function showLoginPage(state, action) {
	
  return {...state, form: FORM_OPTIONS.LOGIN, isFetching: false, loggedIn: false, loginVisible: true };
};

const requestHandlers = reducerGenerator(initialState, {
  [CURRENT_USER_REQUEST]: (state, action) => ({...state, checkedLoggedIn: true, loginVisible: false, isFetching: false}),
  [CHECK_REGISTERED_REQUEST]: (state, action) => ({...state, email: action.email}),
  [REGISTER_SHOW]: (state, action) => ({...state, form: FORM_OPTIONS.REGISTER, email: action.email, isFetching: false}),
  [REGISTER_REQUEST]: (state, action) => ({...state, form: FORM_OPTIONS.REGISTER, name: `${action.firstName} ${action.lastName}`}),
  [FORGOT_REQUEST]: (state, action) => ({...state, email: action.email}),
  [LOGIN_REQUEST]: (state, action) => ({...state, form: FORM_OPTIONS.LOGIN}),
  [LOGOUT_REQUEST]: (state, action) => ({...state, ...initialState, idleMessage: action.idleMessage}),
  [VALIDATE_CSRF_REQUEST]: (state, action) => ({...state, ...initialState}),
  [SHOW_LOGIN]: showLoginPage,
  [SHOW_LOGIN_MESSAGE]: (state, action) => ({...state, form: FORM_OPTIONS.MESSAGE, message: action.message, pendingAction: action.pendingAction, isFetching: false}),
  [HIDE_LOGIN]: (state, action) => ({...state, loginVisible: false, loginClosed:true})
}, defaultRequestState);

const errorHandlers = reducerGenerator(initialState, {
  [CHECK_REGISTERED_ERROR]: (state, action) => ({...state, message: action.message, form: FORM_OPTIONS.LOGIN}),
  [REGISTER_ERROR]: (state, action) => ({...state, message: action.message, form: FORM_OPTIONS.REGISTER}),
  [FORGOT_ERROR]: (state, action) => ({...state, message: 'Unable to reset password, please try again later.'}),
  [LOGIN_ERROR]: (state, action) => ({...state, message: action.message, form: FORM_OPTIONS.LOGIN}),
  [LOGIN_INACTIVE_MESSAGE]: onLoginInactive,
  [UPDATE_CREDENTIALS_ERROR]: (state, action) => {
    return ({...state, loggedIn: true, loginVisible: false, updateCredentialsMessage: action.response.message})
  },
  [VALIDATE_CSRF_ERROR]: (state, action) => ({...state, redirect: true,loggedIn: false})
}, defaultErrorState);

const responseHandlers = reducerGenerator(initialState, { 
  [CHECK_REGISTERED_RESPONSE]: onCheckRegistered,
  [REGISTER_RESPONSE]: onRegisterResponse,
  [LOGIN_RESPONSE]: onLogin,
  [UPDATE_CREDENTIALS_SUCCESS]: (state, action) => ({
    ...state,
    message: 'Successfully changed credentials. Please log in.',
    loggedIn: false,
    loginVisible: true
  }),
  [LOGOUT_RESPONSE]: (state, action) => ({...state, ...initialState, idleMessage: action.idleMessage}), 
  [FORGOT_RESPONSE]: onForgotPwd,
  [IDLE_LOGOUT]: (state, action) => ({...state, showIdleLogout: true}),
  [CANCEL_IDLE_LOGOUT]: (state, action) => ({...state, showIdleLogout: false}),
  [TERMS_ACCEPTED]: termsAccepted,
  [VALIDATE_CSRF_RESPONSE]: showLoginPage
  
}, defaultResponseState);

const settingsHandlers = reducerGenerator(initialState, {
  [SETTINGS_RESPONSE]: onSettings
});

export default reduceReducers(requestHandlers, errorHandlers, responseHandlers, settingsHandlers);