import React from 'react';

import { currencyWithCents, formatDateMmDdYyyy } from 'common/util/common-formatters';
// import { issuer } from 'www/util/enumFormatters';
import { HeaderField, Badge } from 'www/components/details/DetailHeader';
import { trustNumberFormatter } from 'www/util/www-formatters';

import ENUMS from 'www/util/enumFormatters';
import {
    securityType,
} from 'www/util/www-formatters';

const {issuer} = ENUMS;

export default ({deal, ongoingNotes, buttons}) => {
  return (
    <div className="details-header">
      <div className="cusip">{trustNumberFormatter(deal.trustNumber) || '---'}<label className="trust-id">Trust Number</label></div>
      <div className="buttons">{buttons}</div>
      <DealBanners ongoingNotes={ongoingNotes} />
      <ul className="metadata">
        <HeaderField colKey="dealType" label="Security Type" value={deal.dealType} formatter={securityType}/>
        {/*
        	<HeaderField colKey="dealSize" label="Total Deal Size" value={deal.dealSize} formatter={currencyWithCents} />
        */}
        <HeaderField colKey="issueDate" label="Issue Date" value={formatDateMmDdYyyy(deal.issueDate)} />
        <HeaderField colKey="issuer" label="Issuer" value={issuer(deal.issuer)} />
        <HeaderField colKey="dealStatus" label="Security Status" value={deal.dealStatus} />
      </ul>
    </div>
  );
};

function DealBanners({issuance, current, ongoingNotes}) {
  const banners = [];

  if (ongoingNotes) {
    banners.push(wrapBannerContent('notes', ongoingNotes));
  }
  
  return banners.length === 0
    ? null
    : (
        <div className='security-banners'>
          {banners}
        </div>
      );
}

function wrapBannerContent(key, content) {
  return (
    <div key={key} className='security-banner gray'>
      <i className='security-banner-icon fa fa-info-circle' />
      <span className='security-banner-text'>
        {content}
      </span>
    </div>
  );
}
