import React, { Component } from 'react';
import classNames from 'classnames';

import DropdownList from 'common/DropdownList';

export default class ButtonDropdown extends Component {
  state = {
    optionsVisible: false
  };

  toggleOptions = (e) => {
    e.stopPropagation();
    this.setState({
      optionsVisible: !this.state.optionsVisible
    });
  };

  onDocumentClick = (e) => {
    if (this.state.optionsVisible) {
      this.setState({
        optionsVisible: false
      });
    }
  };

  componentDidMount() {
    if (document.getElementById('app')) {
      document.getElementById('app').addEventListener('click', this.onDocumentClick);
    }
  }

  UNSAFE_componentWillUnmount() {
    if (document.getElementById('app')) {
      document.getElementById('app').removeEventListener('click', this.onDocumentClick);
    }
  }

  render() {
    const { className, buttonClassName, label, options, iconCls, hidden, disabled, onItemSelect = () => {} } = this.props;
    const { optionsVisible } = this.state;

    const classes = classNames('button-dropdown', className);
    const buttonClasses = classNames({toggled: optionsVisible}, buttonClassName);
    const icon = <i className={iconCls || 'fa fa-angle-down'} />;

    return (
        <div className={classes}>
          <button className={buttonClasses} hidden={hidden || false} disabled={disabled || false} onClickCapture={this.toggleOptions}>{label} {icon}</button>
          <DropdownList visible={optionsVisible} options={options} onItemSelect={onItemSelect} />
        </div>
    );
  }
};