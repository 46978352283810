import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import {
  addResultsToPortfolio,
  restorePreferences,
  copyPreferences,
  savePreferences,
  updatePreferences,
  filterPreferences,
  removePreferences,
  showDeleteCustomView,
  hideDeleteCustomView,
  showCopyCustomView,
  hideCopyCustomView,
  showSaveAsCustomView,
  hideSaveAsCustomView
} from 'www/actions/accountActions';
import { showLoginMessage } from 'common/login/loginActions';
import { getOrganizationFromLocation } from 'common/util/common-helpers';
import Table, { TYPES } from 'common/table/Table';
import Paginator, { loadPageWithCall } from 'common/table/features/Paginator';
import Customizable from 'common/table/features/Customizable';
import { getNewSort, sortArray } from 'common/util/sorter';
import {
  commaThousands,
  currencyWithCents,
  formatDateMmDdYyyy,
  formatDateMmYyyy,
  integer
} from 'common/util/common-formatters';
import {
  DEFAULT_COLLATERAL_PAGE_SIZE,
  DEFAULT_INIT_PAGE,
  getQueryString
} from 'common/util/common-helpers';
import ConfirmationModal from 'www/components/account/ConfirmationModal';
import { DEFAULT_PORTFOLIO } from 'www/actions/accountActions';
import {
  getResultsCount,
  getResults,
  getRedirect
} from 'www/actions/searchActions';
import { clearHistory, deleteHistoryItem } from 'www/actions/historyActions';
import AddToPortfolioPopup from 'www/components/account/AddToPortfolioPopup';
import { History, updateHistoryNavHeight } from 'www/components/history/History';
import {
  cusipLink,
  dealLink,
  securityType,
  rate,
  factor
} from 'www/util/www-formatters';
import { updateDocumentTitle } from 'www/util/www-helpers';
import { mapColumns } from 'www/util/labels';
import ENUMS from 'www/util/enumFormatters';
import Header from 'www/containers/Header';
import Footer from 'www/components/Footer';
import { getSettings  } from 'www/actions/settingsActions';
import {loadBanners } from 'www/actions/bannerAction';
import { isLoggedIn, idleLogout, logout, cancelIdleLogout, keepSessionActive } from 'common/login/loginActions';
import {fetchInitialData} from "../util/www-helpers";
import IdleDetector from 'common/IdleDetector';


const { securityStatus, disclosureStateType }  = ENUMS;

class Results extends Component {

  constructor(props) {
    super(props);
    // Our history object is renamed to avoid name conflicts/confusion
    // we have history (renamed leftmenuhistory for this file) that holds the data for the history tab on teh left side of screen
   // we also have history (renamed navhistory for this file) that controls the react routing navigatyion and allows forwarding to a new page
    this.navhistory=props.history;
    this.params = props.match.params
    this.organization = props.match.params.organization
    this.state = {
      addToPortfolioDialogVisible: false
    };
    this.gridConfig = {
      title: 'Search Results',
      className: 'results',
      customizingTitle: 'Customizing All Search Results',
      table: {
        key: 'results',
        columnDefaults: {

          sortable: true
        },
        columns: [
          { key: 'cusip', formatter: cusipLink },
          { key: 'secId' },
          { key: 'issrSpclSecuType', sortKey: 'issrSpclSecuType', formatter: securityType },
          { key: 'prefix', flex: 0.5 },
          { key: 'dealId', formatter: dealLink },
          { key: 'classId', flex: 0.5 },
          { key: 'netRate', formatter: rate, className: 'right' },
          { key: 'factor', formatter: factor, className: 'right' },
          { key: 'issueDt', formatter: formatDateMmDdYyyy },
          { key: 'maturity', formatter: formatDateMmYyyy },
          { key: 'upbIssuance', formatter: currencyWithCents, showCents: true, className: 'right', title: 'Investor Security UPB - Issuance' },
          { key: 'upbCurrent', formatter: upbIssuanceWhenPrelimOrFinalOtherwiseUpbCurrent, className: 'right', title: 'Investor Security UPB - Current' },
          { key: 'status', formatter: securityStatus },

          { key: 'discState', visible: false, flex: 0.75, formatter: disclosureStateType },
          { key: 'lnAge', visible: false, formatter: integer, className: 'right', title: 'UPB-Weighted Average Loan Age' },
          { key: 'rmm', visible: false, formatter: integer, className: 'right', title: 'UPB-Weighted Average Remaining Months to Maturity, Current' },
          { key: 'credScore', visible: false, formatter: integer, className: 'right', title: 'Borrower Credit Score' },
          { key: 'ltv', visible: false, formatter: integer, className: 'right', title: 'Loan-To-Value' },
          { key: 'cltv', visible: false, formatter: integer, className: 'right', title: 'Combined Loan-To-Value' }
        ],
        columnEmptyText: '-'
      }
    };

    mapColumns('results', this.gridConfig.table.columns);
  }

  onAddToPortfolioDialogOpen = () => {
    this.setState({addToPortfolioDialogVisible: true});
  };

  onAddToPortfolioDialogClose = () => {
    this.setState({addToPortfolioDialogVisible: false});
  };

  UNSAFE_componentWillMount() {
    document.body.scrollTop = 0;
  }

  componentDidMount() {
    const { getResults, getResultsCount, location, results } = this.props;
    updateDocumentTitle('Search Results');
    fetchInitialData(this.props);
    const { pageIdx, pageSize, sort } = results;
    getResultsCount(location.search, pageSize);
    getResults(location.search, pageIdx, pageSize, sort, this.navhistory);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.results.appendingResult) {
      document.body.scrollTop = 0;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    updateHistoryNavHeight('results-table');
    const { results, location, getRedirect } = this.props;
    const { pageIdx, pageSize, sort } = results;
    if (!results.countQuery && !results.searchQuery && !results.redirectAttempted) {
      getRedirect(location.search, pageIdx, pageSize, sort, this.navhistory, results)
    }
  }

  shouldComponentUpdate(nextProps) {
    if (searchQueryChanged(this.props, nextProps)) {
      const { getResults, getResultsCount, results } = this.props;
      const { pageSize, sort } = results;
      getResultsCount(nextProps.location.search, pageSize);
      getResults(nextProps.location.search, DEFAULT_INIT_PAGE, pageSize, sort, this.navhistory);
      return false;
    }

    return true;
  }
  
  render() {
    const {
      account, addResultsToPortfolio, getResults, login, location, params, showLoginMessage,
      restorePreferences, results, settings, idleLogout, logout, cancelIdleLogout, keepSessionActive
    } = this.props;

    const {
      count,
      data,
      isFetching,
      isFetchingMore,
      hasError,
      message,
      pageIdx,
      pageSize,
      sort
    } = results;

    const { addToPortfolioDialogVisible } = this.state;
    this.gridConfig.table.className = 'results-table';
    this.gridConfig.table.onHeaderClick = (column, sortKey) => {
      const newSort = getNewSort(sort, column.key, ((sort && sort.direction) || 'asc'));
      getResults(location.search, DEFAULT_INIT_PAGE, pageSize, newSort, this.navhistory);
    };

    this.gridConfig.buttons = [
      <button key={uuid()} 
              disabled={!login.loggedIn}
              title={login.loggedIn ? '' : 'Log in to create a portfolio'}
              className="btn-secondary"
              onClick={this.onAddToPortfolioDialogOpen}>Add to Portfolio</button>

    ];


    let errorMessage;
    if (hasError) {
      errorMessage = message || 'Something is technically wrong.';
    } else if (isFetching || !data) {
      errorMessage = <div className="loading more"><i className="fa fa-spinner fa-pulse"/></div>;
      this.gridConfig.table.className += ' loading-table';
    } else if (data.length === 0 && !isFetchingMore) {
      errorMessage = (getOrganizationFromLocation() === 'freddie')?
          { issuer: 'freddie',
            message1:'We are unable to find the requested entry.',
            message2:'Please try entering a specific CUSIP as some non-standard security types require searching by CUSIP.  For assistance, please email us at investor_inquiry@freddiemac.com or call our Investor Inquiry number at 800-336-3672.'

          }
          :
          { issuer:'fannie',
            message1:'No results found'
          }
      ;
    }

    this.gridConfig.title = <span>{commaThousands(count)} Search Results</span>;

    if (errorMessage) {
      this.gridConfig.table.emptyText = errorMessage;
    }
    const query = location.search ? location.search : location.query
    const exportLink = `/api/search/${this.organization}${encodeURIComponent(query)}&export=true`;

    const contents = <Table key={pageIdx}
                            type={TYPES.simple}
                            features={[Customizable, Paginator]}
                            {...this.props}
                            {...this.props.results}
                            cancelBtnClick={restorePreferences}
                            config={this.gridConfig}
                            preferences={account.preferences}
                            isFetchingMore={isFetchingMore}
                            count={count}
                            pageIdx={pageIdx}
                            pageSize={pageSize}
                            pageViewName={'results'}
                            loadPage={loadPageWithCall(getResults, query, pageSize, sort)}
                            onLoginClick={showLoginMessage}
                            loggedIn={login.loggedIn}
                            exportLink={exportLink}
                            exportName="results.csv" />;

    let addToPortfolioDialog;
    if (addToPortfolioDialogVisible) {
      addToPortfolioDialog = (
          <AddToPortfolioPopup securities={data}
                               account={account}
                               params={params}
                               addResultsToPortfolio={addResultsToPortfolio}
                               onClose={this.onAddToPortfolioDialogClose}
                               portfolio={account.portfolio} />
      );
    }

    const newPropsForHistory = {...this.props,history:this.props.leftmenuhistory}

    return (
      <div id="app" className={this.params.organization}>
        {login.loggedIn && <IdleDetector showIdleLogout={login.showIdleLogout}
                                         idleTimeout={settings.idleTimeout}
                                         idleAction={idleLogout}
                                         defaultAction={logout}
                                         cancelAction={cancelIdleLogout}
                                         keepSessionActive={keepSessionActive} />}
        <Header {...this.props} />    
        <div className="content results">
          <History {...newPropsForHistory} />
          <ConfirmationModal {...this.props} />
          {contents}
          {addToPortfolioDialog}
        </div>
        <Footer params={this.params} />
      </div>
    );
  }
}

function searchQueryChanged({location}, nextProps) {
  return nextProps.location.search !== location.search && !nextProps.results.isFetching;
}

function upbIssuanceWhenPrelimOrFinalOtherwiseUpbCurrent(upbCurrent, column, { discState, upbIssuance }) {
  return currencyWithCents(discState === 'P' || discState === 'F' ? upbIssuance : upbCurrent, {});
}
// Our history object is renamed to avoid name conflicts/confusion
// we have history (renamed leftmenuhistory for this file) that holds the data for the history tab on teh left side of screen
// we also have history (renamed navhistory for this file) that controls the react routing navigatyion and allows forwarding to a new page

export default connect(
  ({ results, history, login, account, settings, globalBanners }) => ({ results, leftmenuhistory:history, login, account, settings, globalBanners }),
  {
    getResultsCount,
    getResults,
    getRedirect,
    clearHistory,
    deleteHistoryItem,
    showLoginMessage,
    showDeleteCustomView,
    hideDeleteCustomView,
    addResultsToPortfolio,
    updatePreferences,
    filterPreferences,
    restorePreferences,
    savePreferences,
    removePreferences,
    copyPreferences,
    showCopyCustomView,
    hideCopyCustomView,
    showSaveAsCustomView,
    hideSaveAsCustomView,
    getSettings,
    isLoggedIn,
    loadBanners,
    idleLogout,
    logout,
    cancelIdleLogout,
    keepSessionActive
  })(Results);
