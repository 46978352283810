import React, { Component } from 'react';

import ConfirmModal from 'common/ConfirmModal';
import Table from 'common/table/Table';
import Customizable from 'common/table/features/Customizable';
import Editable from 'common/table/features/Editable';
import { currencyWithCents, formatDateMmDdYyyy, formatDateMmYyyy, formatDateYyyyMmDd, integer } from 'common/util/common-formatters';
import { getOrganizationFromLocation, replaceOrAppendPathPart, slug, toLookup } from 'common/util/common-helpers';
import MoveButton from 'www/components/account/MoveButton';
import NewPortfolio from 'www/components/account/NewPortfolio';
import AddManyToPortfolio from 'www/components/account/AddManyToPortfolio';
import { cusipLink, dealLink, factor, rate, securityType } from 'www/util/www-formatters';
import { mapColumns } from 'www/util/labels';
import { findPortfolioBySlug, findPortfolioIndexBySlug, sortPortfolio, deduplicatePortfolio, extractIdentifiers } from 'www/util/portfolio';
import { getNewSort } from 'common/util/sorter';
import  ENUMS from 'www/util/enumFormatters';
const {securityStatus, disclosureStateType} = ENUMS;
import ConfirmationModal from 'www/components/account/ConfirmationModal';

/*
 * Note: because of how we're pulling in the user's portfolio here, we can make some modifications to the portfolio
 * in the state w/o side effects, but we cannot do all. You can change the array, but not its constituents!! This is
 * preferable to a deepClone or allowing no changes whatsoever.
 */
export default class extends Component {

  state = {
    selected: {},
    isEditing: false,
    showNewPortfolioDialog: false,
    showAddManyDialog: false,
    showDeletePortfolioConfirm: false,
    portfolio: null,
    addManyIds: new Array(),   
    sort: {},
    securitiesInput: [],
    invalidPortfolioName: false
  };

  toggleEditing = () => {
    const { isEditing, portfolio = [] } = this.state;

    this.setState({
      isEditing: !isEditing,
      unsortedPortfolio: portfolio.slice(0)
    });
  };

  onCheckRows = (title, rows) => {
    const { selected } = this.state;

    if (rows.length === 0) {
      selected[title] = {};
      this.setState(selected);
      return;      
    }

    let thisSelected = selected[title] || (selected[title] = {});
    let keys = Object.keys(thisSelected);
    let newSelectState = 
          keys.length < rows.length
            ? true
            : keys.some(idx => !thisSelected[idx]);

    rows.forEach((row, rowIdx) => thisSelected[rowIdx] = newSelectState);

    this.setState({selected});
  };

  onCheckRow = (title, row, column, rowIdx) => {
    const { selected } = this.state;

    if (!selected[title]) {
      selected[title] = {};
    }

    selected[title][rowIdx] = !selected[title][rowIdx];

    this.setState({selected});
  };

  onDeleteSelected = () => {
    const { selected, portfolio } = this.state;

    this.state.portfolio = portfolio.map((portfolioGroup) => {
      let newPortfolio = portfolioGroup.portfolio;
      const selections = selected[portfolioGroup.title];

      if (selections) {

        const cusipsArr = Object.keys(selections)
                                .filter(selIdx => selections[selIdx])
                                .map(selIdx => newPortfolio[selIdx]);

        if (cusipsArr.length) {                                
          const selectedCusips = toLookup(cusipsArr);
          newPortfolio = newPortfolio.filter(cusip => selectedCusips[cusip] === undefined);
        }
      }

      return {
        ...portfolioGroup,
        portfolio: newPortfolio
      };
    });

    this.setState({portfolio: this.state.portfolio, selected: {}, securitiesInput: []});
  };

  onMoveSelected = (title) => {
    const { selected, sort, portfolio } = this.state;
    let { unsortedPortfolio } = this.state;
    const removedItems = [];

    unsortedPortfolio = unsortedPortfolio || portfolio;

    unsortedPortfolio = unsortedPortfolio.map((portfolioGroup) => {
      const newPortfolio = portfolioGroup.portfolio.slice(0);
      const selections = selected[portfolioGroup.title];

      if (selections) {
        Object.keys(selections).sort().reverse().forEach((rowIdx) => {
          removedItems.push(...newPortfolio.splice(rowIdx, 1));
        });
      }

      return {
        ...portfolioGroup,
        portfolio: newPortfolio
      };
    });

    unsortedPortfolio = unsortedPortfolio.map((portfolioGroup) => {
      if (portfolioGroup.title === title) {
        return {
          ...portfolioGroup,
          portfolio: deduplicatePortfolio(portfolioGroup.portfolio.concat(removedItems))
        };
      } else {
        return portfolioGroup;
      }
    });

    this.setState({
      selected: {},
      portfolio: sortPortfolio(unsortedPortfolio, sort),
      unsortedPortfolio,
      securitiesInput: []
    });
  };

  onDeletePortfolio = () => {
    this.setState({showDeletePortfolioConfirm: true, securitiesInput: []});
  };

  onDeletePortfolioConfirmed = (portfolioToDelete) => {
    const { getAllPortfolioSummaries } = this.props;

    if (portfolioToDelete === undefined) {
      console.log('ERROR: Tried to delete undefined portfolio?!?');
      this.onDeletePortfolioCancel();
      return;
    }

    const { currSubAccount } = this.props.match.params;
    const { updatePortfolio } = this.props;
    const { portfolio = [] } = this.state;
    const idx = findPortfolioIndexBySlug(portfolio, portfolioToDelete.title);

    if (idx >= 0) {
      portfolio.splice(idx, 1);
      this.props.match.params.currSubAccount = undefined;
    }

    this.setState({portfolio, showDeletePortfolioConfirm: false, securitiesInput: []});
    updatePortfolio(portfolio);
    this.toggleEditing();

    if (currSubAccount !== undefined) {
      let url = replaceOrAppendPathPart(currSubAccount, undefined);
      this.props.push(url);
    }

    getAllPortfolioSummaries(portfolio, this.getCurrentPortfolio());
  };

  onDeletePortfolioCancel = () => {
    this.setState({showDeletePortfolioConfirm: false, securitiesInput: []});
  };

  onNewPortfolio = () => {
    this.setState({showNewPortfolioDialog: true, securitiesInput: []});
  };

  onCloseNewPortfolio = () => {
    this.setState({showNewPortfolioDialog: false, securitiesInput: [], invalidPortfolioName: false});
  };

  onCreateNewPortfolio = ({name}) => {
    const { currSubAccount } = this.props.match.params;
    const { updatePortfolio } = this.props;
    let { portfolioSummaries } = this.props.account;
    let portfolio = this.state.portfolio;

    if (findPortfolioBySlug(portfolio, name)) {
      this.setState({invalidPortfolioName: true});
    } else{

      portfolio.push({ title: name, slug: slug(name), portfolio: [] });
      this.setState({portfolio});
      this.onCloseNewPortfolio();
      portfolioSummaries = new Array();
      updatePortfolio(portfolio);

    let url = replaceOrAppendPathPart(currSubAccount, slug(name));

      this.props.push(url);
    }
  };

  onPortfolioTitleChange = (newTitle, oldTitle) => {
    const idx = findPortfolioIndexBySlug(this.state.portfolio, oldTitle);
    const portfolio = findPortfolioBySlug(this.state.portfolio, oldTitle);

    if (portfolio) {
      if (this.state.selected[oldTitle]) {
        this.state.selected[newTitle] = this.state.selected[oldTitle];
        delete this.state.selected[oldTitle];
      }

      this.state.portfolio.splice(idx, 1, {
        title: newTitle,
        slug: portfolio.slug, // don't change at this point, not until save
        portfolio: portfolio.portfolio
      });
      this.setState({portfolio: this.state.portfolio, securitiesInput: []});
    }
  };

  onSort = ({key}) => {
    let { sort, unsortedPortfolio, portfolio } = this.state;
    const { portfolioSummaries } = this.props.account;

    sort = getNewSort(sort, key);
    unsortedPortfolio = unsortedPortfolio || portfolio;
    portfolio = sortPortfolio(unsortedPortfolio, portfolioSummaries, sort);

    this.setState({
      selected: {}, //This is important because they'd all be wrong now
      portfolio,
      unsortedPortfolio,
      sort
    });
  };

  onCancel = () => {
    const { account } = this.props;
    this.state.portfolio = account.portfolio ? account.portfolio.slice(0) : [];
    this.state.selected = {};

    if (this.state.showDeletePortfolioConfirm) {
      this.setState({showDeletePortfolioConfirm: false, securitiesInput: []});
    } else {
      this.setState({securitiesInput: []});
    }
    this.toggleEditing();
  };

  onAddManyClick = (newCusips) => {
    this.setState({showAddManyDialog: true});
  };

  onAddManyAccept = (newCusips) => {
	const securitiesInput = newCusips;
    const { portfolio = [] } = this.state;
    const { getPortfolioSummaries } = this.props;
    let { currSubAccount } = this.props.match.params;

    if ((currSubAccount === undefined || currSubAccount === '') && portfolio.length !== 0) {
      currSubAccount = portfolio[0].slug;
    }

    let newPortfolios = portfolio.map((portfolioGroup, idx) => {

      if (portfolioGroup.slug !== currSubAccount) {
        return portfolioGroup;
      }

      const existingCusips = {};

      portfolioGroup.portfolio.forEach(c => existingCusips[c] = true);
      newCusips = newCusips.filter(c => existingCusips[c] === undefined);

      return {
        ...portfolioGroup
      };
    });

    this.setState({portfolio: newPortfolios, showAddManyDialog: false, addManyIds: newCusips, securitiesInput });
    getPortfolioSummaries(newPortfolios, newCusips, this.getCurrentPortfolio());
  };

  onAddManyCancel = () => {
    this.setState({showAddManyDialog: false});
  };

  onSave = () => {
    const { currSubAccount } = this.props.match.params;
    const { updatePortfolio } = this.props;
    const { portfolio } = this.state;

    let url;

    // if we renamed the current, we need to redirect
    portfolio.forEach(p => {
      let newSlug = slug(p.title);      
      if (newSlug === p.slug) {
        // didn't change title, so no redirect needed
        return;
      }
      if (p.slug === currSubAccount) {
        url = replaceOrAppendPathPart(currSubAccount, newSlug);
      }
      p.slug = newSlug;
    });

    updatePortfolio(portfolio);
    this.toggleEditing();

    if (url) {
      this.props.push(url);
    }

    this.setState({securitiesInput: []});
  };

  hasItemsSelected() {
    const { selected } = this.state;
    return Object.keys(selected).some((title) => {
      return Object.keys(selected[title]).some((rowIdx) => selected[title][rowIdx]);
    });
  }

  UNSAFE_componentWillMount() {
    const { account, getAllPortfolioSummaries } = this.props;
    this.state.portfolio = account.portfolio ? account.portfolio.slice(0) : [];

    if (account.portfolio && account.portfolio.length > 0) {
      getAllPortfolioSummaries(account.portfolio, this.getCurrentPortfolio());
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
      if (nextProps.match.params.currSubAccount !== this.props.match.params.currSubAccount) {
          const selectPortfolio = this.getSelectedPortfolio(nextProps.account, nextProps.match.params.currSubAccount);
          if (selectPortfolio && selectPortfolio.portfolio) {
              nextProps.getAllPortfolioSummaries(nextProps.account.portfolio, selectPortfolio );
          }
      }
    }

  getCurrentPortfolio = () => {

    const { currSubAccount } = this.props.match.params;
      return this.getSelectedPortfolio (this.state, currSubAccount);

  };

   getSelectedPortfolio = (state, currSubAccount) => {

       const { portfolio } = state;
       const currentPortfolio = !Array.isArray(portfolio) || portfolio.length === 0
            ? null
            : currSubAccount === undefined
                ? portfolio[0]
                : portfolio.filter(p => p.slug === currSubAccount)[0];
        return currentPortfolio;
    }

  render() {
    const {
      isEditing,
      portfolio,
      sort,
      showNewPortfolioDialog,
      showAddManyDialog,
      securitiesInput,
      showDeletePortfolioConfirm,
      invalidPortfolioName
    } = this.state;

    const { showLoginMessage, updatePreferences, restorePreferences } = this.props;
    const { portfolioSummaries, preferences, isFetching } = this.props.account;
    const currentPortfolio = this.getCurrentPortfolio();
    const tableHeader = <h3>The data displayed reflects the latest disclosure reporting period.</h3>;
    const dataList = Object.keys(portfolioSummaries).map(key => portfolioSummaries[key]);

    if (isFetching) {
      return <div className="loading"><i className="fa fa-spinner fa-pulse"/></div>;
    }

    let identifiersData = Object.keys(portfolioSummaries);
    identifiersData.push(...extractIdentifiers(dataList));
	
    const invalidSecurities = securitiesInput.filter(id => !(identifiersData.indexOf(id)>-1));
    let self = this;

    return (
      <div className="portfolio">
        <div className="header">
          <div className="buttons">
            <button onClick={this.onNewPortfolio} hidden={isEditing} className="btn-secondary">New Portfolio</button>
            <button onClick={this.toggleEditing} hidden={!currentPortfolio || isEditing} className="btn-secondary">Edit</button>
            <button onClick={this.onCancel} hidden={!isEditing} className="btn-link">Cancel</button>
            <button onClick={this.onDeleteSelected} hidden={!isEditing} disabled={!this.hasItemsSelected()} className="btn-danger">Delete Selected</button>
            <MoveButton movePortfolio={this.onMoveSelected} portfolio={portfolio} hidden={!isEditing} disabled={!this.hasItemsSelected()} />
            <button onClick={this.onAddManyClick} hidden={!isEditing} className="btn-secondary">Add Many</button>
            <button onClick={this.onSave} hidden={!isEditing} className="btn-primary">Save</button>
          </div>
        </div>
        <ConfirmationModal {...this.props} />
        { currentPortfolio && tableHeader }
        { invalidSecurities && invalidSecurities.length > 0 && <span> <span className="error-message">  Securities not loaded into portfolio </span>   <span className ="invalidsecurities"> {invalidSecurities.join(', ')} </span> </span>}
        { isEditing && <div className="portfolio-export-msg">
                          <div className="msg-float-right">Please save your Portfolio view prior to selecting Export All. Only data from a saved Portfolio will be exported.</div>
                        </div>
        }
        { currentPortfolio && portfolioTable() || null }
        { showNewPortfolioDialog && <NewPortfolio onClose={this.onCloseNewPortfolio} onCreate={this.onCreateNewPortfolio} invalidPortfolioName={invalidPortfolioName}/>}
        { showAddManyDialog && <AddManyToPortfolio onAddMany={this.onAddManyAccept} onCancel={this.onAddManyCancel} />}
        { showDeletePortfolioConfirm &&
          <ConfirmModal title="Delete Portfolio"
                        text={`Are you sure you want to delete "${currentPortfolio ? currentPortfolio.title : 'this portfolio'}"?`}
                        onOk={() => this.onDeletePortfolioConfirmed(currentPortfolio)}
                        onCancel={this.onDeletePortfolioCancel}
                        />
        }                        
      </div>
    );

    function portfolioTable() {

      const config = getPortfolioGridConfig({title: currentPortfolio.title, onSort: self.onSort});
      let data = [];
      currentPortfolio && currentPortfolio.portfolio && currentPortfolio.portfolio.length && currentPortfolio.portfolio.map((item) => {
        if(portfolioSummaries && portfolioSummaries[item])  {
          data.push(portfolioSummaries[item]);
        }
      });

      const exportName = `Portfolio_${formatDateYyyyMmDd(new Date())}.txt`;
      const exportLink = `/api/portfolio/${getOrganizationFromLocation()}?portfolioId=${currentPortfolio.slug}&export=true`;

      return (
        <Table {...self.state}
              features={[Editable, Customizable]}
              config={config}
              data={data}
              sort={sort}
              selected={self.state.selected[config.title] || {}}
              loggedIn={true}
              preferences={preferences}
              onLoginClick={showLoginMessage}
              updatePreferences={updatePreferences}
              {...self.props}
              cancelBtnClick={restorePreferences}
              onTitleChange={self.onPortfolioTitleChange}
              onCheckRows={self.onCheckRows}
              onCheckRow={self.onCheckRow}
              onDeletePortfolio={self.onDeletePortfolio}
              exportAll={!isEditing}
              exportName={exportName}
              exportLink={exportLink}
        />
      );
    }
  }
}


const getPortfolioGridConfig = ({title, onSort}) => {
  return {
    title,
    customizingTitle: 'Customizing All Portfolios',
    table: {
      key: 'portfolio',
      columnDefaults: {
        sortable: true
      },
      columns,
      columnEmptyText: '-',
      onHeaderClick: onSort
    }
  };
};

const columns = [
  
  // Note data is from SecuritySearchSummary, not CoreSecurity..

  { key: 'cusip', formatter: cusipLink },
  { key: 'secId' },
  { key: 'issrSpclSecuType', formatter: securityType },
  { key: 'prefix', flex: 0.5 },
  { key: 'dealId', formatter: dealLink },
  { key: 'classId', flex: 0.5 },
  { key: 'netRate', formatter: rate, className: 'right' },
  { key: 'factor', formatter: factor, className: 'right' },
  { key: 'issueDt', formatter: formatDateMmDdYyyy },
  { key: 'maturity', formatter: formatDateMmYyyy },
  { key: 'upbIssuance', formatter: currencyWithCents, className: 'right', title: 'Investor Security UPB - Issuance' },
  { key: 'upbCurrent', formatter: currencyWithCents,  className: 'right', title: 'Investor Security UPB - Current' },
  { key: 'status', formatter: securityStatus },

  { key: 'discState', visible: false, flex: 0.75, formatter: disclosureStateType },
  { key: 'lnAge', visible: false, formatter: integer, className: 'right', title: 'UPB-Weighted Average Loan Age' },
  { key: 'rmm', visible: false, formatter: integer, className: 'right', title: 'UPB-Weighted Average Remaining Months to Maturity, Current' },
  { key: 'credScore', visible: false, formatter: integer, className: 'right', title: 'Borrower Credit Score' },
  { key: 'ltv', visible: false, formatter: integer, className: 'right', title: 'Loan-To-Value' },
  { key: 'cltv', visible: false, formatter: integer, className: 'right', title: 'Combined Loan-To-Value' }
];

mapColumns('portfolio', columns);
