import { DOCUMENTS_REQUEST, DOCUMENTS_RESPONSE, DOCUMENTS_ERROR } from 'www/actions/documentsActions';
import { reduceMiddleware } from 'common/util/redux';
import { getOrganizationFromLocation } from 'common/util/common-helpers';
import { getOrganizationCodeFromLocation } from 'www/util/www-helpers';
import API from 'common/util/api';
import { UNSUBSCRIBE_REQUEST, UNSUBSCRIBE_RESPONSE, UNSUBSCRIBE_ERROR } from 'www/actions/documentsActions';
import { CHECK_EMAIL_LANDING_REQUEST, CHECK_EMAIL_LANDING_RESPONSE, CHECK_EMAIL_LANDING_ERROR } from 'www/actions/documentsActions';

//noinspection JSUnusedLocalSymbols
function documentsRequest(getState, next, action) {
  API.get(`legaldocs/${getOrganizationCodeFromLocation()}`)
    .then(
      response => next({...action, type: DOCUMENTS_RESPONSE, documents: response}),
      message => next({...action, type: DOCUMENTS_ERROR, message})
    );
}

//unsubscribe from report
function unsubscribeFromReport(getState, next, action) {    
  API.post(`user/${getOrganizationFromLocation()}/reports/unsubscribe`, {report: action.reportName}).then(
    response => next({...action, type: UNSUBSCRIBE_RESPONSE, count: response.count}),
    message => next({...action, type: UNSUBSCRIBE_ERROR, message})
  );
}

function checkEmailLanding(getState, next, action) {

    API.get(`report/checkdocument/${action.id}/${action.fileName}`).then(
    response => {
        const isValidDoc = response;

        API.get(`user/${getOrganizationFromLocation()}/reports/${action.reportName}/checksubscription`).then(
          response => {
            const combinedRes = new Map();
            combinedRes.set("isValidDoc", isValidDoc);
            combinedRes.set("isSubscribed", response);
            next({...action, type: CHECK_EMAIL_LANDING_RESPONSE, combinedRes});
          },
          message => {
            next({...action, type: CHECK_EMAIL_LANDING_ERROR, message});
          }
        );
    },
    message => {
      next({...action, type: CHECK_EMAIL_LANDING_ERROR, message});
    }
  );
}

export default reduceMiddleware({
  [DOCUMENTS_REQUEST]: documentsRequest,
  [UNSUBSCRIBE_REQUEST]: unsubscribeFromReport,
  [CHECK_EMAIL_LANDING_REQUEST]: checkEmailLanding,
});
