import React, { Component } from 'react'
import Header from 'www/containers/Header'
import Footer from 'www/components/Footer'
import { isLoggedIn, showLoginMessage } from 'common/login/loginActions'
import { getSettings } from 'www/actions/settingsActions'
import { connect } from 'react-redux'
import { getProductResourceForFannie, getLayoutResourceRequestForFannie, getLearningResourceForFannie} from '../actions/fannieResourcesActions'


export default class FannieResource extends Component{
    constructor(props) {
        super(props);
    }


    render() {
        const { prior, current } = this.props;

        const link = <a style = {{fontSize:'medium', textAlign: 'left' }} href={current.link}>{current.title}</a>;
        const description = current.description && <p style={{margin:0,padding:0,textAlign:'left',fontSize: 'medium'}}>{current.description}</p>;
        const updated = current.updateStatus &&  <span style={{textAlign:'left',fontSize: 'medium'}}>UPDATED!</span>;
        return(<div  key={current.id} className="fnm-res-link">
                {prior.header != current.header && <h2>{current.header}</h2>}
                {current.subHeader && current.subHeader!=prior.subHeader  && <h3 style={{paddingLeft:10}}>{current.subHeader}</h3>}
            <li style={{marginTop:'0.5em', paddingLeft:20, listStyleType:'none'}}>
                <p>{link}&emsp;&emsp;{updated}</p>
                {description}
            </li>
            </div>
        );
    }
}
