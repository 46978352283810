import React, { Component } from 'react';
import { connect } from 'react-redux';

import Logo from 'common/Logo';
import { showLogin, logout, checkEmail, close, onForgotPwd, showRegister, register, login, acceptTerms,validateCsrf } from 'common/login/loginActions';
import { search } from 'www/actions/searchActions';
import { getSettings } from 'www/actions/settingsActions';
import SearchBox from 'www/components/SearchBox';
import Login from 'www/components/Login';
import LoginDropdown from 'www/components/login/LoginDropdown';
import { getOrganizationFromLocation } from 'common/util/common-helpers';
import ResourcesDropdown from 'www/components/login/ResourcesDropdown';


const initialState = {
    redirect: false
	};

class Header extends Component {

   redirectLogin = false;
	constructor(props) {
      super(props);
      this.state = initialState;
	    if(location.pathname.indexOf('/loginPage')>0) {
	    	 this.redirectLogin = true;
      }
  }

	login = (loginArgs) => {
		this.props.getSettings();
		this.props.login(loginArgs);
  };


  redirectPage = () => {
	const org = getOrganizationFromLocation();
  	const redirectLoginPath = `/${org}/loginPage`;
  	window.location.href=redirectLoginPath ;
  	return <div>Home</div>;
  };

  render() {
    const { loginState, account, logout, showLogin, validateCsrf, settings} = this.props;
    const { loginVisible, loggedIn, loginClosed} = loginState;
    const params = this.props.match ? this.props.match.params : this.params;
    const organization = getOrganizationFromLocation()
    const homeLink = "/".concat(organization);
    const redirectLoginShow = loginClosed !== true && loggedIn!== true && this.redirectLogin;
    const loginShow = redirectLoginShow || loginVisible ;
    const{isSearchNeeded = true} = this.props;
      if (organization === "freddie") {
          return (
              <header>
                  {this.props.isSplash === 'true' ? <Logo/> : <Logo link={homeLink}/> }
                  {isSearchNeeded && <SearchBox {...this.props} />}
                  <LoginDropdown {...loginState} {...account} logout={logout} showLogin={this.showLogin} validateCsrf={validateCsrf} redirectPage={this.redirectPage} state={this.state} params={params}/>
                  {loginShow && <Login {...this.props} login={this.login} />}
              </header>          );
      } else {
          return (
              <header>
                  <Logo link={homeLink}/>
                  {this.props.isSplash === 'true' ? <Logo/> : '' }
                  {isSearchNeeded && <SearchBox {...this.props} loggedIn={loggedIn}/>}
                  <>
                    <ResourcesDropdown {...loginState} {...account} settings = {settings} logout={logout} showLogin={this.showLogin} validateCsrf={validateCsrf} redirectPage={this.redirectPage} state={this.state} params={params}/>
                    <LoginDropdown {...loginState} {...account} logout={logout} showLogin={this.showLogin} validateCsrf={validateCsrf} redirectPage={this.redirectPage} state={this.state} params={params}/>
                  </>
                  {loginShow && <Login {...this.props} login={this.login} />}
              </header>
          );
      };
  }
}

export default connect(
  ({login, account, routing, settings}) => ({ loginState: login, account, routing, settings }),
  { getSettings, search, showLogin, logout, checkEmail, close, onForgotPwd, showRegister, register, login, acceptTerms, validateCsrf }
)(Header)
