import React from 'react';

import ButtonDropdown from 'common/button/ButtonDropdown';
import { eventHandler } from 'common/util/events';

export default ({portfolio, hidden, disabled, movePortfolio}) => {
  const label = 'Move Selected';

  const options = portfolio.map(({title, portfolio}) => {
    return {
      text: title,
      action: eventHandler(movePortfolio, title)
    };
  });

  const props = {
    buttonClassName: 'btn-secondary',
    disabled,
    hidden,
    label,
    options
  };

  return <ButtonDropdown {...props} />;
};