import React from 'react';

import SimpleTable from 'common/table/SimpleTable';
import { getTargetArray } from 'common/util/common-helpers';
import { currencyWithCents} from 'common/util/common-formatters';

import ENUMS from 'www/util/enumFormatters';
import { rate } from 'www/util/www-formatters';
import { isEsfPseudopool, isReperformingSecurity, isRPLModStepOrFixed } from 'www/util/www-helpers';
import { mapColumns } from 'www/util/labels';

export default ({details, currentOrIssuance, monthly, month}) => {
  const key = `${currentOrIssuance || month}.quartiles`;
  const data = currentOrIssuance ? details.data : monthly.data;
  const isReperformer = isReperformingSecurity(details.data.issuance);
  const isRPLModStepFixed = isRPLModStepOrFixed(details.data.issuance);
  const isEsfP = isEsfPseudopool(details.data.issuance);

  const {quartileLevel} = ENUMS;
  const gridConfig = {
    key,
    className: 'quartile-table',
    columnDefaults: {
      className: 'right'
    },
    columns: [
      { key: 'qrtlLevel', label: isEsfP && 'Decile Level', formatter: quartileLevel, className: 'center'},
      { key: 'lnAmt', formatter: currencyWithCents, className: 'right' },
      { key: 'rate', formatter: rate, flex: 0.75, className: 'right' },
      { key: 'netInterestRate', formatter: rate, flex: 0.75, className: 'right' },
      { key: 'term', flex: 0.75, className: 'right' },
      { key: 'lnAge', flex: 0.75, className: 'right' },
      { key: 'ltv', visible: !isRPLModStepFixed, className: 'right' },
      { key: 'rmm', className: 'right' },
      { key: 'estimatedLTV', visible: isReperformer, className: 'right' },
      { key: 'cltv', visible: !isRPLModStepFixed, className: 'right' },
      { key: 'credScore', visible: !isRPLModStepFixed, className: 'right' },
      { key: 'updatedCredScore', visible: isReperformer, className: 'right' },
      { key: 'dti', className: 'right' }
    ],
    columnEmptyText: '-',
    emptyText: isEsfP ? 'No Decile data' : 'No Quartile Data'
  };
  mapColumns('quartile', gridConfig.columns);
  const tableData = getTargetArray(data, key) || [];

  return <SimpleTable config={gridConfig} data={tableData}/>;
}
