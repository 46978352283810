import { formatDateMmDdYyyy } from 'common/util/common-formatters';
import { getOrganizationFromLocation, setInCopy, insertInCopy, removeInCopy } from 'common/util/common-helpers';
import { reducerGenerator, reduceReducers } from 'common/util/redux';

import { DEAL_REQUEST, DEAL_RESPONSE } from 'www/actions/dealActions';
import { DETAILS_REQUEST, DETAILS_RESPONSE } from 'www/actions/detailsActions';
import { CLEAR_HISTORY, DELETE_HISTORY } from 'www/actions/historyActions';
import { SEARCH_RESPONSE } from 'www/actions/searchActions';
import { BACK_ACTION, FORWARD_ACTION } from 'www/middleware/undoMiddleware';
import { securityType, trustNumberFormatter } from 'www/util/www-formatters';
import { isOffPlatformSecurity } from 'www/util/www-helpers';

export const SINGLE_RESULT = 'SINGLE_RESULT';
export const MULTI_RESULT = 'MULTI_RESULT';


function isAdvancedSearch(query) {
  return !query.hasOwnProperty('q');
}

function getQueryId(query) {
  return query == undefined ? '' : Object.keys(query).reduce((queryId, key) => {
    return key !== 'page' && query[key] ? `${queryId}${query[key]}` : queryId;
  }, '');
}

function getQueryDescription(queryString) {
  const query = Object.fromEntries(new URLSearchParams(queryString.replace('?','')));
  let description = [];
  Object.keys(query).forEach((key) => {
    if (query[key]) {
      description.push(`${key}: ${query[key]}`);
    }
  });
  return description.join(', ');
}

function findHistoryItemById(state, action) {
  return state.findIndex((item) => { return item.id === action.id });
}

function createDetailsHistoryItem({issuance}) {
  let description;

  if (isOffPlatformSecurity(issuance)) {
    description = [];
  } else {
    description = [
      `Security Type: ${securityType(issuance.issrSpclSecuType)}`,
      `Issue Date: ${formatDateMmDdYyyy(issuance.issueDt)}`
    ];
  }

  return {
    type: SINGLE_RESULT,
    id: issuance.cusip,
    title: issuance.cusip,
    org: issuance.issuer,
    description
  };
}

function createDealHistoryItem({deal}, query) {
  let description;

  if(deal){
    description = [
      `Deal Type: ${securityType(deal.dealType)}`,
      `Issue Date: ${formatDateMmDdYyyy(deal.issueDate)}`
    ];

    if(!query){
      query =`?q=${trustNumberFormatter(deal.trustNumber)}`
    }
  } 

  return {
    type: MULTI_RESULT,
    id: trustNumberFormatter(deal.trustNumber),
    title: trustNumberFormatter(deal.trustNumber),
    query,
    org: deal.issuer,
    description
  };
}

//TODO: Do we just put the results in the state? Do we really want to make decisions about the view here?
function createHistoryItem({ id, title, query, details, deal, results, numResults = 0}) {
  if (details) {
    return createDetailsHistoryItem(details);
  }

  if (deal) {
    return createDealHistoryItem(deal, query);
  }
  
  const isAdvanced = query && isAdvancedSearch(query);
  let item = {
    type: MULTI_RESULT,
    id,
    title: isAdvanced ? 'Advanced' : title || id,
    query,
    createdDate: Date.now(),
    org: getOrganizationFromLocation(),
    numResults
  };

  let description = [];
  if (!results && !deal) {
    description.push('in progress...');
  } else {
    if (isAdvanced) {
      description.push(getQueryDescription(query));
    }

    description.push(`${numResults} results`);
  }

  return {...item, description};
}

function clearHistory(state, action) {
  if (state.length > 1) {
    return state.slice(0, 1);
  } else {
    return state;
  }
}

function bumpHistoryItem(state, action) {
	
  if(action.type == 'DEAL_REQUEST'){
	  action.id = trustNumberFormatter(action.id);
  }	
  if (!action.id) {
    action.id = getQueryId(action.query);
  }

  const itemIdx = findHistoryItemById(state, action);

  if (itemIdx === -1) {
    return [
      createHistoryItem(action),
      ...state
    ];
  } else {
    return [
      state[itemIdx],
      ...state.slice(0, itemIdx),
      ...state.slice(itemIdx+1)
    ];
  }
}

function updateCurrentHistoryItem(state, action, {appendingResults}) {
  if (!action.query && !action.details && !action.deal) {
    return state;
  }

  if (!action.id) {
    action.id = getQueryId(action.query);
  }

  const itemIdx = findHistoryItemById(state, action);

  if (itemIdx === -1) {
    return state;
  }

  let numResults = (action.results && action.results.length) || 0;
  if (appendingResults) {
    numResults += (state[itemIdx].numResults || 0);
  }

  return insertInCopy(state, createHistoryItem({...state[itemIdx], ...action, numResults}), itemIdx);
}

function deleteHistoryItem(state, action) {
  if (!action.id) {
    action.id = getQueryId(action.query);
  }

  const itemIdx = findHistoryItemById(state, action);

  if (itemIdx === -1) {
    return state;
  }

  return removeInCopy(state, itemIdx);
}

const initialState = [];

const HttpHandlers = reducerGenerator(initialState, {
  SEARCH_REQUEST: (state, action) => bumpHistoryItem(state, action),
  SEARCH_RESPONSE: (state, action) => updateCurrentHistoryItem(state, action, {appendingResults: false}),
  DETAILS_REQUEST: (state, action) => bumpHistoryItem(state, action),
  DETAILS_RESPONSE: (state, action) => updateCurrentHistoryItem(state, action, {appendingResults: false}),
  DEAL_REQUEST: (state, action) => bumpHistoryItem(state, action),
  DEAL_RESPONSE: (state, action) => updateCurrentHistoryItem(state, action, {appendingResults: false})
});
const sidebarControlHandlers = reducerGenerator(initialState, {
  CLEAR_HISTORY: (state, action) => clearHistory(state, action),
  DELETE_HISTORY: (state, action) => deleteHistoryItem(state, action)
});

export default timetravel(reduceReducers(HttpHandlers, sidebarControlHandlers));


function timetravel(reducer) {
  const initialState = {
    past: [],
    present: reducer(undefined, {}),
    future: []
  };

  return function (state = initialState, action) {
    const { past, present, future } = state;

    switch (action.type) {
      case BACK_ACTION:
        const previous = past[past.length - 1];
        const newPast = past.slice(0, past.length - 1);

        if (future.length > 10) {
          future.splice(10, future.length - 10)
        }

        return {
          past: newPast,
          present: previous,
          future: [present, ...future]
        };
        break;

      case FORWARD_ACTION:
        const next = future[0];
        const newFuture = future.slice(1);

        if (past.length > 10) {
          past.splice(0, past.length - 10);
        }

        return {
          past: [...past, present],
          present: next,
          future: newFuture
        };
        break;

      default:
        const newPresent = reducer(present, action);

        if (present === newPresent) {
          return state
        }

        return {
          past: [...past, present],
          present: newPresent,
          future: future
        }
    }
  }
}
