import {FNM_ANNOUNCEMENTS_REQUEST, FNM_ANNOUNCEMENTS_RESPONSE, FNM_ANNOUNCEMENTS_ERROR, FNM_ANNOUNCEMENT_ARCHIVE_REQUEST, FNM_ANNOUNCEMENT_ARCHIVE_RESPONSE, FNM_ANNOUNCEMENT_ARCHIVE_ERROR} from 'www/actions/fannieResourcesActions'
import API from 'common/util/api';
import { reduceMiddleware } from 'common/util/redux';
import {getOrganizationFromLocation} from "../../common/util/common-helpers";

function announcementsRequest(getState, next, action) {
    API.get('resource/'+getOrganizationFromLocation()+'/announcement/recent').then(
        (response) => {
            next({...action, type: FNM_ANNOUNCEMENTS_RESPONSE, response});
          },
          (message) => {
            next({...action, type: FNM_ANNOUNCEMENTS_ERROR, message});
          }
    );
}

function archivedAnnouncementsRequest(getState, next, action) {
    API.get('resource/'+getOrganizationFromLocation()+'/announcement/full').then(
        (response) => {
            next({...action, type: FNM_ANNOUNCEMENT_ARCHIVE_RESPONSE, response});
          },
          (message) => {
            next({...action, type: FNM_ANNOUNCEMENT_ARCHIVE_ERROR, message});
          }
    );
}
export default reduceMiddleware({
    [FNM_ANNOUNCEMENTS_REQUEST]: announcementsRequest,
    [FNM_ANNOUNCEMENT_ARCHIVE_REQUEST]:archivedAnnouncementsRequest,
  });