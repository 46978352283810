import React, { Component } from 'react';
import { multilineText } from 'common/util/common-helpers';
import { updateDocumentTitle } from 'www/util/www-helpers';

export default class PrivacyPolicy extends Component {

    UNSAFE_componentWillMount() {
        updateDocumentTitle('Privacy Policy');
    }

    render() {
        const { privacy } = this.props.settings;

        return (
            <div className="content privacy">
                <header>
                    <h1>Privacy Policy</h1>
                </header>
                <main>{multilineText(privacy)}</main>
            </div>
        );
    }
}
