import { distillProfile } from 'common/login/loginMiddleware';
import API from 'common/util/api';
import { reduceMiddleware } from 'common/util/redux';
import { getOrganizationFromLocation, insertInCopy, isEmpty, removeInCopy, slug, slugAllTitles } from 'common/util/common-helpers';

import {
  UPDATE_PROFILE, UPDATE_PREFERENCES, REMOVE_PREFERENCES, FILTER_PREFERENCES, ADD_TO_PORTFOLIO, REMOVE_FROM_PORTFOLIO, APPLY_SETTINGS_REQUEST,
  APPLY_SETTINGS_RESPONSE, APPLY_SETTINGS_RESPONSE_TEMP, APPLY_SETTINGS_ERROR, PORTFOLIO_SUMMARY_RESPONSE, SHOW_DELETE_CUSTOM_VIEW, HIDE_DELETE_CUSTOM_VIEW,
  SAVE_PREFERENCES, COPY_PREFERENCES, ADD_RESULTS_TO_PORTFOLIO, PORTFOLIO_SUMMARY_REQUEST, UPDATE_PORTFOLIO,
  DEFAULT_PORTFOLIO, TAX_DATA_FILES_LIST_REQUEST, TAX_DATA_FILES_LIST_RESPONSE, TAX_DATA_FILES_LIST_ERROR,
  SUBSCRIBE_TO_REPORT_REQUEST, SUBSCRIBE_TO_REPORT_RESPONSE, SUBSCRIBE_TO_REPORT_ERROR,
  UNSUBSCRIBE_TO_REPORT_REQUEST, UNSUBSCRIBE_TO_REPORT_RESPONSE, UNSUBSCRIBE_TO_REPORT_ERROR,
  SHOW_COPY_CUSTOM_VIEW, HIDE_COPY_CUSTOM_VIEW, SHOW_SAVEAS_CUSTOM_VIEW, HIDE_SAVEAS_CUSTOM_VIEW
  , RESET_PASSWORD_REQUEST, RESET_PASSWORD_RESPONSE, RESET_PASSWORD_ERROR, ALL_PORTFOLIO_SUMMARIES_REQUEST, PORTFOLIO_SUMMARY_ERROR
} from 'www/actions/accountActions';

import { getCusipsInCurrentPortfolio, deduplicatePortfolio } from 'www/util/portfolio';


function updateProfile(getState, next, action) {
  const { account } = getState();
  const { profile, successCallback } = action;

  applySettings(next, account.id, {...account, profile}, successCallback);
}

function filterPreferences(getState, next, action) {
  const { account } = getState();
  const { key } = action;
  const tempView = 'customDefaultTempView';

  let preferences = { ...account.preferences };

  if (key && preferences[key]) {
    preferences[key] = preferences[key].filter(o => !o[tempView]);
  }

  next({type: APPLY_SETTINGS_RESPONSE_TEMP, response: {
      ...account,
      preferences
    }});

}

function addCustomViewToPreferences(prefs, action, saveFields) {
  let customViewIndex, { key, viewName, updatedViewName } = action;
  let preferences = { ...prefs };
  // saveFields variable is an optional param. this param is added in savedPreferences.

  const fields = saveFields || action.fields

  if (!fields) {
    return undefined;
  }

  if (updatedViewName && viewName !== updatedViewName) {
    viewName = updatedViewName;
  }

  const preferenceValue = { [viewName]: fields };
  // Overwrite view or add a new view
  if (preferences[key]) {
    customViewIndex = preferences[key].findIndex( o => o[viewName] );
  }

  if (customViewIndex > -1) {
    preferences[key][customViewIndex] = preferenceValue;
  } else {
    preferences[key].push(preferenceValue);
  }

  return preferences;
}

function showDeleteCustomView(getState, next, action) {
  const { message, okAction, cancelAction } = action;

  next({ type: SHOW_DELETE_CUSTOM_VIEW, message, okAction, cancelAction });
}

function hideDeleteCustomView(getState, next) {

  next({ type: HIDE_DELETE_CUSTOM_VIEW });
}

function showCopyCustomView(getState, next, action) {
  const { message, okAction, cancelAction } = action;

  next({ type: SHOW_COPY_CUSTOM_VIEW, message, okAction, cancelAction });
}

function hideCopyCustomView(getState, next) {

  next({ type: HIDE_COPY_CUSTOM_VIEW });
}

function showSaveAsCustomView(getState, next, action) {
  const { message, okAction, cancelAction } = action;

  next({ type: SHOW_SAVEAS_CUSTOM_VIEW, message, okAction, cancelAction });
}

function hideSaveAsCustomView(getState, next) {

  next({ type: HIDE_SAVEAS_CUSTOM_VIEW });
}

function updatePreferences(getState, next, action) {
  const { account } = getState();
  const { key, viewName, fields } = action;
  let { preferences } = { ...account };

  //Note: this does not actually saves data, just pretends to.

  if (key) {
    preferences[key] = preferences[key] || [];
    preferences = addCustomViewToPreferences(preferences, action);
  }

  next({type: APPLY_SETTINGS_RESPONSE_TEMP, response: {
      ...account,
      preferences
    }});
}

function copyPreferences(getState, next, action) {
  const { account } = getState();
  const { key, viewName, currentView } = action;
  const tempView = 'customDefaultTempView';
  let { preferences, savedPreferences } = { ...account };

  if (key && !!preferences && !!preferences[key]) {
    let currentViewIndex, { fields } = action;

    currentViewIndex = preferences[key].findIndex( o => o[currentView] );

    if (currentViewIndex > -1) {
      fields = preferences[key][currentViewIndex][currentView];
    }

    savedPreferences[key] = preferences[key];

    savedPreferences = addCustomViewToPreferences(savedPreferences, action, fields);
    applySettings(next, account.id, { ...account, preferences: savedPreferences });
  }
}

function savePreferences(getState, next, action) {
  const { account } = getState();
  const { key, viewName, updatedViewName, successCallback } = action;
  const tempView = 'customDefaultTempView';

  if (key) {
    let tempIndex, currentViewIndex, { fields } = action, { preferences, savedPreferences } = { ...account };

    // if customDefaultTempView key exists find it's index so we can first capture the fields and then remove its reference from preferences
    if (preferences[key]) {
      tempIndex = preferences[key].findIndex( o => o[tempView] );
    }

    if (tempIndex > -1) {
      fields = preferences[key][tempIndex][tempView];
      preferences[key].splice(tempIndex, 1);
    } else {

      if (preferences[key]) {
        currentViewIndex = preferences[key].findIndex( o => o[viewName] );
      }

      // find viewName in preferences, if it exists, capture the fields and remove the view from preferences
      if (currentViewIndex > -1) {
        fields = preferences[key][currentViewIndex][viewName];
        preferences[key].splice(currentViewIndex, 1);
      }
    }

    // Prepare saved prefs so new prefs can be stored in it.
    savedPreferences[key] = preferences[key] || [];

    savedPreferences = addCustomViewToPreferences(savedPreferences, action, fields);
    applySettings(next, account.id, { ...account, preferences: savedPreferences }, successCallback);
  }

}

function removePreferences(getState, next, action) {
  const { account } = getState();
  const { key, viewName } = action;
  let { preferences, savedPreferences } = { ...account };

  if (key && !!preferences && !!preferences[key]) {
    let currentViewIndex;

    currentViewIndex = preferences[key].findIndex( o => o[viewName] );
    if (currentViewIndex > -1) {
      preferences[key].splice(currentViewIndex, 1);
      if (preferences[key].length === 0) {
        delete preferences[key];
      }
    }

    savedPreferences[key] = preferences[key];
    applySettings(next, account.id, { ...account, preferences: savedPreferences });
  }
}

function updatePortfolio(getState, next, action) {
  const { account } = getState();
  const { portfolio, successCallback } = action;

  applySettings(next, account.id, {...account, portfolio}, successCallback);
}

function addToPortfolio(getState, next, action) {
  const { account } = getState();
  const { cusip, classCusips, group, successCallback } = action;
  const portfolio = getNewPortfolio(account.portfolio, group, cusip || classCusips);

  applySettings(next, account.id, {...account, portfolio}, successCallback);
}

function addResultsToPortfolio(getState, next, action) {
  const { account } = getState();
  const { results, group, successCallback } = action;
  const cusip = results.map(({cusip}) => (cusip));
  const portfolio = getNewPortfolio(account.portfolio, group, cusip);

  applySettings(next, account.id, {...account, portfolio}, successCallback);
}

function getNewPortfolio(oldPortfolio, group, cusip) {
  const groupName = group || DEFAULT_PORTFOLIO;

  let portfolioIdx = -1;

  let newPortfolio = {
    title: groupName,
    slug: slug(groupName),
    portfolio: [].concat(cusip)
  };

  if (oldPortfolio) {
    portfolioIdx = oldPortfolio.findIndex((element) => {
      return element.title === groupName;
  });

    if (portfolioIdx >= 0) {
      const oldGroup = oldPortfolio[portfolioIdx];
      newPortfolio = {
        title: groupName,
        slug: slug(groupName),
        portfolio: deduplicatePortfolio(oldGroup.portfolio.concat(cusip))
      };
    }
  }

  return insertInCopy(oldPortfolio, newPortfolio, portfolioIdx);
}

function removeFromPortfolio(getState, next, action) {
  const { account } = getState();
  const { cusip, classCusips, group } = action;
  const toRemove = cusip ? [cusip] : classCusips;

  if (account.portfolio) {
    const portfolioIdx = account.portfolio.findIndex((element) => {
      return element.title === group;
  });

    if (portfolioIdx >= 0) {
      const oldGroupPortfolio = account.portfolio[portfolioIdx];
      const filteredGroupPortfolio = oldGroupPortfolio.portfolio.filter(c => toRemove.indexOf(c) === -1);
      const newPortfolio = {
        ...oldGroupPortfolio,
        portfolio: filteredGroupPortfolio
      };

      const portfolio = insertInCopy(account.portfolio, newPortfolio, portfolioIdx);

      applySettings(next, account.id, {...account, portfolio});
    }
  }
}

function getPortfolioSummaries(getState, next, action) {
  const { portfolio, addManyIds, currentPortfolio } = action;
  let newIds = addManyIds || [];
  let portfolioItemIds = [];

  let cusips = getCusipsInCurrentPortfolio(currentPortfolio) || [];
  portfolioItemIds.push(...newIds.filter(c => c && !cusips.includes(c)));

  portfolioItemIds = portfolioItemIds.join(',');

  if (portfolioItemIds.length > 0){
    API.get(`portfolio/${getOrganizationFromLocation()}`, {portfolioItemIds: portfolioItemIds}).then(
      (response) => {
        next({type: PORTFOLIO_SUMMARY_RESPONSE, response, portfolio, currentPortfolio});
      },
      (message) => {
      console.error('Could not get portfolio summaries', message);
      next({...action, type: PORTFOLIO_SUMMARY_ERROR})
    });
  } else {
    const emptyArray = [];
    next({type: PORTFOLIO_SUMMARY_RESPONSE, emptyArray, portfolio, currentPortfolio});
  }
}

function getAllPortfolioSummaries(getState, next, action) {
  const { portfolio, currentPortfolio } = action;
  const portfolioId = currentPortfolio && currentPortfolio.slug || '';

  if (portfolioId){
    API.get(`portfolio/${getOrganizationFromLocation()}`, {portfolioId: portfolioId}).then(
      (response) => {
        next({type: PORTFOLIO_SUMMARY_RESPONSE, response, portfolio, currentPortfolio});
      },
      (message) => {
        console.error(`Could not get portfolio summaries for portfolio ${portfolioId}`, message);
        next({...action, type: PORTFOLIO_SUMMARY_ERROR})
      });
  } else {
    const emptyArray = [];
    next({type: PORTFOLIO_SUMMARY_RESPONSE, emptyArray, portfolio, currentPortfolio});
  }
}

function applySettings(next, user, params, successCallback) {
  const settings = {
    profile: distillProfile(params.profile),
    id: params.id,
    preferences: params.preferences,
    portfolio: params.portfolio || []
  };

  next({type: APPLY_SETTINGS_REQUEST});

  //Notice we are passing the above settings object through as if it were a response from successfully applying settings

  API.post(`settings/user/${getOrganizationFromLocation()}`, {settings: JSON.stringify(settings)}).then(
      (response) => {
    next({type: APPLY_SETTINGS_RESPONSE, response: settings});
  successCallback && successCallback();
},
  (message) => {
    next({type: APPLY_SETTINGS_ERROR, message});
  }
);
}

function getTaxDataFiles(getState, next, action) {
  API.get(`doc/tax/${getOrganizationFromLocation()}`).then(
      (response) => {
    next({type: TAX_DATA_FILES_LIST_RESPONSE, response: {taxDataFiles: response}});
},
  (message) => {
    next({type: TAX_DATA_FILES_LIST_ERROR, message});
  }
);
}

function reportSubscriptionHandler(actionType, reponseActionType, errorActionType) {

  return (getState, next, action) => {

    const { reportType } = action;

    if (actionType == 'subscribe') {
      API.post(`user/${getOrganizationFromLocation()}/reports/${reportType}/${actionType}`).then(
          response => {
        next({type: reponseActionType, reportType, response: response});
    },
      message => {
        console.log('Error ' + actionType + ': ' + message);
        next({type: errorActionType, reportType, message: message});
      })
    } else {
      API.post(`user/${getOrganizationFromLocation()}/reports/${actionType}`, {report: action.reportType}).then(
          response => {
        next({type: reponseActionType, reportType, response: response});
    },
      message => {
        console.log('Error ' + actionType + ': ' + message);
        next({type: errorActionType, reportType, message: message});
      })
    }};
}

function resetPasswordRequest(getState, next, action) {
  API.post(`user/${getOrganizationFromLocation()}/resetPassword`, {email:''}).then(
      (response) => {
    next({...action, type: RESET_PASSWORD_RESPONSE, response});
},
  (message) => {
    next({...action, type: RESET_PASSWORD_ERROR, message});
  }
);
}


export default reduceMiddleware({
  [UPDATE_PROFILE]: updateProfile,
  [UPDATE_PREFERENCES]: updatePreferences,
  [FILTER_PREFERENCES]: filterPreferences,
  [REMOVE_PREFERENCES]: removePreferences,
  [SAVE_PREFERENCES]: savePreferences,
  [COPY_PREFERENCES]: copyPreferences,
  [ADD_TO_PORTFOLIO]: addToPortfolio,
  [SHOW_DELETE_CUSTOM_VIEW]: showDeleteCustomView,
  [HIDE_DELETE_CUSTOM_VIEW]: hideDeleteCustomView,
  [SHOW_COPY_CUSTOM_VIEW]: showCopyCustomView,
  [HIDE_COPY_CUSTOM_VIEW]: hideCopyCustomView,
  [SHOW_SAVEAS_CUSTOM_VIEW]: showSaveAsCustomView,
  [HIDE_SAVEAS_CUSTOM_VIEW]: hideSaveAsCustomView,
  [ADD_RESULTS_TO_PORTFOLIO]: addResultsToPortfolio,
  [REMOVE_FROM_PORTFOLIO]: removeFromPortfolio,
  [PORTFOLIO_SUMMARY_REQUEST]: getPortfolioSummaries,
  [ALL_PORTFOLIO_SUMMARIES_REQUEST]: getAllPortfolioSummaries,
  [UPDATE_PORTFOLIO]: updatePortfolio,
  [TAX_DATA_FILES_LIST_REQUEST]: getTaxDataFiles,
  [SUBSCRIBE_TO_REPORT_REQUEST]: reportSubscriptionHandler('subscribe', SUBSCRIBE_TO_REPORT_RESPONSE, SUBSCRIBE_TO_REPORT_ERROR),
  [UNSUBSCRIBE_TO_REPORT_REQUEST]: reportSubscriptionHandler('unsubscribe', UNSUBSCRIBE_TO_REPORT_RESPONSE, UNSUBSCRIBE_TO_REPORT_ERROR),
  [RESET_PASSWORD_REQUEST]: resetPasswordRequest
});
