import React from 'react';

import SimpleTable from 'common/table/SimpleTable';
import { getTargetArray } from 'common/util/common-helpers';
import { currencyWithCents, formatDateMmDdYyyy, formatDateMmYyyy, integer, decimal } from 'common/util/common-formatters';

import { rate } from 'www/util/www-formatters';
import { mapColumns } from 'www/util/labels';

export default ({details, currentOrIssuance, monthly, month}) => {  
  const key = `${currentOrIssuance || month}.distributionsMap.NextInterestRateAdjDate`;  
  const data = currentOrIssuance ? details.data : monthly.data;
  const tableData = getTargetArray(data, key);
  
  const gridConfig = {
    key,
    className: 'quartile-table',
    columns: [
      { key: 'label', formatter: formatDateMmYyyy, flex: 0.5, className: 'right' },
      { key: 'waMonthsToNextRateAdjustmentDate', formatter: integer, className: 'right' },
      { key: 'interestOnlyFirstPrincipalAndInterestPaymentDate', formatter: formatDateMmDdYyyy, className: 'right' },
      { key: 'investorLoanUPBAggregate', formatter: currencyWithCents, className: 'right' },
      { key: 'investorLoanUPBPercent', formatter: decimal, precision: 2, className: 'right' },
      { key: 'loanCountAggregate', formatter: integer, className: 'right' },
      { key: 'loanCountPercent', formatter: decimal, precision: 2, className: 'right' }
    ],
    columnEmptyText: '-',
    emptyText: 'No data'
  };
  mapColumns("nextInterstRateAdjustmentUpbAndCount", gridConfig.columns);

  return <SimpleTable config={gridConfig} data={tableData} />;
};
