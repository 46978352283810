import React, { Component } from 'react'
import Header from 'www/containers/Header'
import Footer from 'www/components/Footer'
import { isLoggedIn, showLoginMessage, idleLogout, logout, cancelIdleLogout, keepSessionActive } from 'common/login/loginActions'
import { getSettings } from 'www/actions/settingsActions'
import { connect } from 'react-redux'
import { getProductResourceForFannie, getLayoutResourceRequestForFannie, getLearningResourceForFannie} from '../actions/fannieResourcesActions'
import FannieResource from "../components/FannieResource";
import IdleDetector from 'common/IdleDetector';
import {fetchInitialData} from '../util/www-helpers';

class FannieResources extends Component{
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.props.getProductResourceForFannie();
        this.props.getLearningResourceForFannie();
        fetchInitialData(this.props);
    }

    render() {
        const { login, showLoginMessage, fannieResLinks, settings, idleLogout, logout, cancelIdleLogout, keepSessionActive } = this.props;
        const { params, url } = this.props.match;
        const isProducts = url.endsWith("products");
        const resources   = isProducts?fannieResLinks.products:fannieResLinks.learning;
        const header =isProducts?"Product Information":"Learning Center"

        if(params.organization=='freddie') return;

        let count = 0;
        function displayLinks(resources) {
            let result = [];
            let prior = {}
            for(let item of resources) {
                result.push(<FannieResource current={item} prior={{header: prior.header, subHeader: prior.subHeader}}/>);
                prior=item;
            }
            return result;


        }

        return(<div id="app" className={params.organization}>
              {login.loggedIn && <IdleDetector showIdleLogout={login.showIdleLogout}
                                               idleTimeout={settings.idleTimeout}
                                               idleAction={idleLogout}
                                               defaultAction={logout}
                                               cancelAction={cancelIdleLogout}
                                               keepSessionActive={keepSessionActive} />}
                <Header {...this.props} isSearchNeeded={false}/>
                <div style={{paddingLeft:50, paddingBottom:75}}>
                    <p><h1>{header}</h1></p>
                    <div className="fnm-res-links">
                        {resources && displayLinks(resources)}
                    </div>
                </div>
                <Footer params={params} />
            </div>

        );}
}

export default connect(
    ({ login, settings, fannieResLinks }) => ({ login, settings, fannieResLinks }),
    { showLoginMessage, getSettings,  isLoggedIn, getProductResourceForFannie, getLearningResourceForFannie, idleLogout, logout, cancelIdleLogout, keepSessionActive })(FannieResources);