import { reducerGenerator, reduceReducers } from '../../common/util/redux';
import * as actions from '../actions/fannieResourcesActions';

const initialState = {
    products:[],
    learning: [],
    layout: null,
    productsFetched: false,
    learningFetched: false,
    layoutFetched: false,
    isProductsFetching: false,
    isLearningFetching: false,
    isLayoutFetching: false,
    errMsg: null}

export default reducerGenerator(initialState, {
    [actions.FNM_PRODUCT_REQUEST]: (state, action) => {
        return {...state, isProductsFetching: true};
      },
    [actions.FNM_PRODUCT_RESPONSE]: (state, action) => {
        return {...state, products: action.response.managedResourceDataList, productsFetched: true, isProductsFetching: false};
      },
    [actions.FNM_PRODUCT_ERROR]: (state, action) => {
        return {...state, errMsg: action.response, isProductsFetching: false};
    },
    [actions.FNM_LEARNING_REQUEST]: (state, action) => {
        return {...state, isLearningFetching: true};
    },
    [actions.FNM_LEARNING_RESPONSE]: (state, action) => {
        return {...state, learning: action.response.managedResourceDataList, learningFetched: true, isLearningFetching: false};
    },
    [actions.FNM_LEARNING_ERROR]: (state, action) => {
        return {...state, errMsg: action.response, isLearningFetching: false};
    },
});