export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export const UPDATE_PREFERENCES = 'UPDATE_PREFERENCES';
export const UPDATE_PREFERENCES_SUCCESS = 'UPDATE_PREFERENCES_SUCCESS';
export const UPDATE_PREFERENCES_ERROR = 'UPDATE_PREFERENCES_ERROR';

export const FILTER_PREFERENCES = 'FILTER_PREFERENCES';

export const REMOVE_PREFERENCES = 'REMOVE_PREFERENCES';

export const TAX_DATA_FILES_LIST_REQUEST = 'TAX_DATA_FILES_LIST_REQUEST';
export const TAX_DATA_FILES_LIST_RESPONSE = 'TAX_DATA_FILES_LIST_RESPONSE';
export const TAX_DATA_FILES_LIST_ERROR = 'TAX_DATA_FILES_LIST_ERROR';

export const ADD_TO_PORTFOLIO = 'ADD_TO_PORTFOLIO';
export const ADD_RESULTS_TO_PORTFOLIO = 'ADD_RESULTS_TO_PORTFOLIO';
export const REMOVE_FROM_PORTFOLIO = 'REMOVE_FROM_PORTFOLIO';
export const UPDATE_PORTFOLIO = 'UPDATE_PORTFOLIO';

export const ALL_PORTFOLIO_SUMMARIES_REQUEST = 'ALL_PORTFOLIO_SUMMARIES_REQUEST';
export const PORTFOLIO_SUMMARY_REQUEST = 'PORTFOLIO_SUMMARY_REQUEST';
export const PORTFOLIO_SUMMARY_RESPONSE = 'PORTFOLIO_SUMMARY_RESPONSE';
export const PORTFOLIO_SUMMARY_ERROR = 'PORTFOLIO_SUMMARY_ERROR';
export const APPLY_SETTINGS_REQUEST = 'APPLY_SETTINGS_REQUEST';
export const APPLY_SETTINGS_RESPONSE = 'APPLY_SETTINGS_RESPONSE';
export const APPLY_SETTINGS_RESPONSE_TEMP = 'APPLY_SETTINGS_RESPONSE_TEMP';
export const APPLY_SETTINGS_ERROR = 'APPLY_SETTINGS_ERROR';

export const DEFAULT_PORTFOLIO = 'Uncategorized';
export const RESTORE_PREFERENCES = 'RESTORE_PREFERENCES';
export const SAVE_PREFERENCES = 'SAVE_PREFERENCES';
export const COPY_PREFERENCES = 'COPY_PREFERENCES';
export const SHOW_DELETE_CUSTOM_VIEW = 'SHOW_DELETE_CUSTOM_VIEW';
export const HIDE_DELETE_CUSTOM_VIEW = 'HIDE_DELETE_CUSTOM_VIEW';
export const SHOW_COPY_CUSTOM_VIEW = 'SHOW_COPY_CUSTOM_VIEW';
export const HIDE_COPY_CUSTOM_VIEW = 'HIDE_COPY_CUSTOM_VIEW';
export const SHOW_SAVEAS_CUSTOM_VIEW = 'SHOW_SAVEAS_CUSTOM_VIEW';
export const HIDE_SAVEAS_CUSTOM_VIEW = 'HIDE_SAVEAS_CUSTOM_VIEW';

export const SUBSCRIBE_TO_REPORT_REQUEST = 'SUBSCRIBE_TO_REPORT_REQUEST';
export const SUBSCRIBE_TO_REPORT_RESPONSE = 'SUBSCRIBE_TO_REPORT_RESPONSE';
export const SUBSCRIBE_TO_REPORT_ERROR = 'SUBSCRIBE_TO_REPORT_ERROR';
export const UNSUBSCRIBE_TO_REPORT_REQUEST = 'UNSUBSCRIBE_TO_REPORT_REQUEST';
export const UNSUBSCRIBE_TO_REPORT_RESPONSE = 'UNSUBSCRIBE_TO_REPORT_RESPONSE';
export const UNSUBSCRIBE_TO_REPORT_ERROR = 'UNSUBSCRIBE_TO_REPORT_ERROR';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_RESPONSE = 'RESET_PASSWORD_RESPONSE';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export function updateProfile(profile) {
  return { type: UPDATE_PROFILE, profile };
}

export function filterPreferences(key, viewName) {
  return { type: FILTER_PREFERENCES, key, viewName };
}

export function updatePreferences(key, fields, viewName) {
  return { type: UPDATE_PREFERENCES, key, fields, viewName };
}

export function removePreferences(key, viewName) {
  return { type: REMOVE_PREFERENCES, key, viewName };
}

export function restorePreferences() {
  return { type: RESTORE_PREFERENCES };
}

export function savePreferences(key, viewName, updatedViewName, fields) {
  return { type: SAVE_PREFERENCES, key, viewName, updatedViewName, fields };
}

export function copyPreferences(key, viewName, currentView, fields) {
  return { type: COPY_PREFERENCES, key, viewName, currentView, fields };
}

export function showDeleteCustomView(message, okAction, cancelAction) {
  return { type: SHOW_DELETE_CUSTOM_VIEW, message, okAction, cancelAction }
}

export function hideDeleteCustomView() {
  return { type: HIDE_DELETE_CUSTOM_VIEW }
}

export function showCopyCustomView(message, okAction, cancelAction) {
  return { type: SHOW_COPY_CUSTOM_VIEW, message, okAction, cancelAction }
}

export function hideCopyCustomView(message) {
  return { type: HIDE_COPY_CUSTOM_VIEW }
}

export function showSaveAsCustomView(message, okAction, cancelAction) {
  return { type: SHOW_SAVEAS_CUSTOM_VIEW, message, okAction, cancelAction }
}

export function hideSaveAsCustomView(message) {
  return { type: HIDE_SAVEAS_CUSTOM_VIEW }
}

export function addToPortfolio(cusip, classCusips, group) {
  return { type: ADD_TO_PORTFOLIO, cusip, classCusips, group };
}

export function addResultsToPortfolio(results, group, successCallback) {
  return { type: ADD_RESULTS_TO_PORTFOLIO, results, group, successCallback };
}

export function removeFromPortfolio(cusip, classCusips, group) {
  return { type: REMOVE_FROM_PORTFOLIO, cusip, classCusips, group };
}

export function updatePortfolio(portfolio) {
  return { type: UPDATE_PORTFOLIO, portfolio };
}

export function getPortfolioSummaries(portfolio, addManyIds, currentPortfolio) {
  return { type: PORTFOLIO_SUMMARY_REQUEST, portfolio ,addManyIds, currentPortfolio};
}

export function getAllPortfolioSummaries(portfolio, currentPortfolio) {
  return { type: ALL_PORTFOLIO_SUMMARIES_REQUEST, portfolio, currentPortfolio};
}

export function getTaxDataFilesList(params) { // Not sure what params yet... sam 6/8/16
	return { type: TAX_DATA_FILES_LIST_REQUEST, ...params };
}

export function subscribeToReport(reportType) {
  return { type: SUBSCRIBE_TO_REPORT_REQUEST, reportType}
}

export function unsubscribeToReport(reportType) {
  return { type: UNSUBSCRIBE_TO_REPORT_REQUEST, reportType}
}

export const onResetPassword = (email) => ({ type: RESET_PASSWORD_REQUEST, email });
