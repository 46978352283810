import API from 'common/util/api';
import { reduceMiddleware } from 'common/util/redux';
import { getOrganizationFromLocation } from 'common/util/common-helpers';

import {
REPORTS_GROUP_REQUEST,
REPORTS_GROUP_RESPONSE,
REPORTS_GROUP_ERROR,
REPORTS_GROUP_YEAR_REQUEST,
REPORTS_GROUP_YEAR_RESPONSE,
REPORTS_GROUP_YEAR_ERROR
} from 'www/actions/reportsActions';

// API to get the reports of a given group and year
// this does not return no of years in the response
function groupYearReportsRequest(getState, next, action) {
    API.get(`report/${getOrganizationFromLocation()}/list/${action.group}/${action.year}`).then(
    response => {
      next({...action, type: REPORTS_GROUP_YEAR_RESPONSE, response});
    },
    message => {
      next({...action, type: REPORTS_GROUP_YEAR_ERROR, message});
    }
  );
}

// This chains two API to get available years and then the reports for the most recent group
// if no years are found then does not call the second API
function groupReportsRequest(getState, next, action) {

    API.get(`report/${getOrganizationFromLocation()}/listyears/${action.group}`).then(
    response => {

      if(response && response.length > 0 ) {    
        const sortedYears = response.map(year => parseInt(year)).sort((a, b) => b-a);
      
        API.get(`report/${getOrganizationFromLocation()}/list/${action.group}/${sortedYears[0]}`).then(
          response => {
            const combinedRes = new Map();
            combinedRes.set("selectYear", sortedYears[0]);
            combinedRes.set("years", sortedYears);
            combinedRes.set("reports", response);
            next({...action, type: REPORTS_GROUP_RESPONSE, combinedRes});
          },
          message => {
            next({...action, type: REPORTS_GROUP_ERROR, message});
          }
        );
      } else {
        const combinedRes = new Map();
        combinedRes.set("selectYear", 0);
        combinedRes.set("years", response);
        combinedRes.set("reports", response);
        next({...action, type: REPORTS_GROUP_RESPONSE, combinedRes});
      }
      
    },
    message => {
      next({...action, type: REPORTS_YEARS_ERROR, message});
    }
  );
}


export default reduceMiddleware({
  [REPORTS_GROUP_REQUEST]: groupReportsRequest,
  [REPORTS_GROUP_YEAR_REQUEST]: groupYearReportsRequest,
});