import React from 'react';
import { Link } from 'react-router-dom';

export default ({link}) => {
  if (link) {
    if (link.endsWith("/freddie")){
        return <a href="http://www.freddiemac.com/mbs"><div className="logo"></div></a>;
    }else{
        return <Link to={link}><div className="logo"></div></Link>;
    }

  } else {
    return <div className="home-logo"></div>;
  }
};