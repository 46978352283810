import React, { Component } from 'react';

import Modal from 'common/Modal';
import { isValidIdentifier } from 'common/util/common-helpers';

export default class AddManyToPortfolio extends Component {
  state = {
    cusips: [], // object {cusip: '', className: invalid/valid}
    invalidSecMessage: ''
  };
  
  
  onKeyUp = (e) => {
    
    let key = String.fromCharCode(e.keyCode);

    switch(key) {
      case ' ':
      case ',':
      case ';':
      case '\t':
      case '\n':

        let newCusips = e.target.value.split(/[\s,;]+/).filter(v => v !== '');

        // sometimes when typing fast we can get an extra separator after next parts first letter, protect against it
        if (newCusips.some(c => c.length < 3)) {
          return;
        }

        this.processNewCusips(newCusips);
        e.target.value = '';

        if (e.ctrlKey && key === '\n') {
          this.onAddToPortfolio();
        }

        break;
    }
  };

  onTextBlur = (e) => {
    e.keyCode = '\t'.charCodeAt(0);
    this.onKeyUp(e);
  };

  processNewCusips = (newCusips) => {

    if (newCusips.length === 0) {
      // can happen if was all whitespace
      return;
    }

    newCusips = newCusips.map(c => c.toUpperCase());
    
    let existingCusips = {};
    this.state.cusips.forEach(c => existingCusips[c.cusip] = true);

    newCusips = newCusips.filter(c => existingCusips[c] === undefined);

    if (newCusips.length === 0) {
      // filtered all out, duplicates
      return;
    }

    let newStateCusips = this.state.cusips.concat(newCusips.map(c => { return {cusip: c, className: isValidIdentifier(c) ? 'valid' : 'invalid'};}));

    let newMsg = this.getPortfolioSrchErrorMessage(newStateCusips);
    this.setState({cusips: newStateCusips, invalidSecMessage:newMsg});
  };
  
 getPortfolioSrchErrorMessage = (newStateCusips) =>{ 
 	let hasError=false;
 	
 	Object.keys(newStateCusips).forEach(function(key) {
      if (newStateCusips[key].className== 'invalid'){
        hasError = true;
      }
    }); 
   
   return hasError ? 'The following were not added to portfolio as there were no valid matches:' : '';
 };
 
 removeCusip = (cusip) => {
    let newCusips = this.state.cusips.filter(c => c.cusip !== cusip);    
    let newMsg = this.getPortfolioSrchErrorMessage(newCusips);    
    this.setState({cusips: newCusips, invalidSecMessage:newMsg});
  };

  onAddClick = () => {

    const { onAddMany } = this.props;

    let newCusips = this.state.cusips.filter(c => c.className === 'valid').map(c => c.cusip);

    if (newCusips.length === 0) {
      return;
    }

    onAddMany(newCusips);
  };
  
  render() {
    const { onCancel } = this.props;
    const { cusips } = this.state;
    
    return (
      <Modal className="add-many-to-portfolio" onClose={onCancel}>
        <div className="title">Add Securities to Portfolio</div>
        <div className="instructions">Type multiple CUSIPs, Security Identifiers or Trust Numbers/Class into input, separated by space or comma.</div>
        <span className="secErrorMsg" > <b>{this.state.invalidSecMessage}</b> </span>
        <div className="cusip-list">
          { cusips.map(cusip => (
              <span className={`cusip-container ${cusip.className}`} key={cusip.cusip}>
                <span className="cusip-number">{cusip.cusip}</span>                
                <a className="cusip-remove-link" onClick={() => this.removeCusip(cusip.cusip)}>
                  <i className="fa fa-close cusip-remove-icon"/>
                </a>
              </span>
            ))
          }
          <input type="text" autoFocus onKeyUp={this.onKeyUp} onBlur={this.onTextBlur} />
        </div>        
        <div className="buttons">
          <button onClick={onCancel}>Cancel</button>
          <button className="btn-primary" onClick={this.onAddClick}>Add All</button>          
        </div>
      </Modal>
    );
  }

}
