
export const DEAL_REQUEST = 'DEAL_REQUEST';
export const DEAL_RESPONSE = 'DEAL_RESPONSE';
export const DEAL_ERROR = 'DEAL_ERROR';
export const DEAL_PAGE_CHANGE_REQUEST = 'DEAL_PAGE_CHANGE_REQUEST';

export const DEAL_ISSUANCE_REQUEST = 'DEAL_ISSUANCE_REQUEST';
export const DEAL_ISSUANCE_RESPONSE = 'DEAL_ISSUANCE_RESPONSE';
export const DEAL_ISSUANCE_ERROR = 'DEAL_ISSUANCE_ERROR';

export const DEAL_COMPONENT_REQUEST = 'DEAL_COMPONENT_REQUEST';
export const DEAL_COMPONENT_RESPONSE = 'DEAL_COMPONENT_RESPONSE';
export const DEAL_COMPONENT_ERROR = 'DEAL_COMPONENT_ERROR';

export const DEAL_DATES_REQUEST = 'DEAL_DATES_REQUEST';
export const DEAL_DATES_RESPONSE = 'DEAL_DATES_RESPONSE';
export const DEAL_DATES_ERROR = 'DEAL_DATES_ERROR';

export const DEAL_TAX_REQUEST = 'DEAL_TAX_REQUEST';
export const DEAL_TAX_RESPONSE = 'DEAL_TAX_RESPONSE';
export const DEAL_TAX_ERROR = 'DEAL_TAX_ERROR';

export const DEAL_AS_OF_REQUEST = 'DEAL_AS_OF_REQUEST';
export const DEAL_AS_OF_RESPONSE = 'DEAL_AS_OF_RESPONSE';
export const DEAL_AS_OF_ERROR = 'DEAL_AS_OF_ERROR';

export const DEAL_COLLATERAL_REQUEST = 'DEAL_COLLATERAL_REQUEST';
export const DEAL_COLLATERAL_RESPONSE = 'DEAL_COLLATERAL_RESPONSE';
export const DEAL_COLLATERAL_ERROR = 'DEAL_COLLATERAL_ERROR';

export const DEAL_COLLATERAL_COUNT_REQUEST = 'DEAL_COLLATERAL_COUNT_REQUEST';
export const DEAL_COLLATERAL_COUNT_RESPONSE = 'DEAL_COLLATERAL_COUNT_RESPONSE';
export const DEAL_COLLATERAL_COUNT_ERROR = 'DEAL_COLLATERAL_COUNT_ERROR';

export const DEAL_TAX_FACTOR_COUNT_REQUEST = 'DEAL_TAX_FACTOR_COUNT_REQUEST';
export const DEAL_TAX_FACTOR_COUNT_RESPONSE = 'DEAL_TAX_FACTOR_COUNT_RESPONSE';
export const DEAL_TAX_FACTOR_COUNT_ERROR = 'DEAL_TAX_FACTOR_COUNT_ERROR';

export const DEAL_BY_CLASS_REQUEST = 'DEAL_BY_CLASS_REQUEST';
export const DEAL_BY_CLASS_RESPONSE = 'DEAL_BY_CLASS_RESPONSE';
export const DEAL_BY_CLASS_ERROR = 'DEAL_BY_CLASS_ERROR';


export const DEAL_DOCUMENTS_REQUEST = 'DEAL_DOCUMENTS_REQUEST';
export const DEAL_DOCUMENTS_RESPONSE = 'DEAL_DOCUMENTS_RESPONSE';
export const DEAL_DOCUMENTS_ERROR = 'DEAL_DOCUMENTS_ERROR';

export const DEAL_CLASS_COUNT_REQUEST = 'DEAL_CLASS_COUNT_REQUEST';
export const DEAL_CLASS_COUNT_RESPONSE = 'DEAL_CLASS_COUNT_RESPONSE';
export const DEAL_CLASS_COUNT_ERROR = 'DEAL_CLASS_COUNT_ERROR';

export const DEAL_CLASS_CUSIPS_REQUEST = 'DEAL_CLASS_CUSIPS_REQUEST';
export const DEAL_CLASS_CUSIPS_RESPONSE = 'DEAL_CLASS_CUSIPS_RESPONSE';
export const DEAL_CLASS_CUSIPS_ERROR = 'DEAL_CLASS_CUSIPS_ERROR';


export function getComponentDeal(trustNumber, pageIdx, pageSize, sort) {
  return {
    type: DEAL_COMPONENT_REQUEST,
    trustNumber,
    id: trustNumber,
    pageIdx, 
    pageSize, 
    sort
  };
}

export function getDeal(trustNumber, pageIdx, pageSize, sort) {
  return {
    type: DEAL_REQUEST,
    trustNumber,
    id: trustNumber,
    pageIdx, 
    pageSize, 
    sort
  };
}

export function getDealIssuance(trustNumber, pageIdx, pageSize, sort) {
    return {
        type: DEAL_ISSUANCE_REQUEST,
        trustNumber,
        id: trustNumber,
        pageIdx,
        pageSize,
        sort
    };
}

export function changePage(trustNumber, pageIdx, pageSize, sort) {
    return {
        type: DEAL_PAGE_CHANGE_REQUEST,
        trustNumber,
        id: trustNumber,
        pageIdx,
        pageSize,
        sort
    };
}


export function getDealTax(params, pageIdx, pageSize, sort) {
  return {
    type: DEAL_TAX_REQUEST,
    trustNumber: params.trustNumber,
    id: params.trustNumber,
    cusipParam:  params.cusipParam,
    currentYear: params.currentYear,
    pageIdx, 
    pageSize, 
    sort
  };
}


export function getDealDates(trustNumber) {
 return {
    type: DEAL_DATES_REQUEST,
    trustNumber
  };
}

export function getDealAsOf(trust, pageIdx, pageSize) {
 return {
    type: DEAL_AS_OF_REQUEST,
    trust ,
    pageIdx,
    pageSize
  };
}

// DealID is exception to the trustNumber-for-deal-rule due to back-end SQL refactoring that required this field to be used instead..
export function getDealCollateral(data, pageIdx, pageSize) {
 return {
    type: DEAL_COLLATERAL_REQUEST,
    dealId: data.dealId,
    recordType: data.recordType,
    pageIdx,
    pageSize
  };
}

export function getDealCollateralCount(dealId, recordType) {
  return {
    type: DEAL_COLLATERAL_COUNT_REQUEST,
    dealId, recordType
  };
}

export function getDealClassCount(trustNumber, classParam='') {  
  return {
	type: DEAL_CLASS_COUNT_REQUEST,
	trustNumber,
    classParam
  };
}

export function getDealTaxFactorCount(trustNumber, cusipParam = [], currentYear) {
  return {
    type: DEAL_TAX_FACTOR_COUNT_REQUEST,
    trustNumber,
    cusipParam,
    currentYear
  };
}

export function getDealDocuments(trustNumber) {
  return {
    type: DEAL_DOCUMENTS_REQUEST,
    trustNumber
  };
}

export function getDealByTrustNumberAndClass(param, pageIdx, pageSize, sort) {
	
    return {
        type: DEAL_BY_CLASS_REQUEST,
        trustNumber: param.trustNumber,
        id: param.trustNumber,
        classParam: param.classParam,
        pageIdx,
        pageSize,
        sort
    };
}

export function getDealClassCusips(trustNumber){
  return {
    type: DEAL_CLASS_CUSIPS_REQUEST,
    trustNumber };
}
