import React from 'react';

import { COLUMN_TYPES } from 'common/table/SimpleTable';
import { isBlank } from 'common/util/common-helpers';
import { eventHandler } from 'common/util/events';

export default ({column, row, rowIdx, config, className, expandedRows, setExpanded}) => (
  <div key={column.key+rowIdx} 
       className={(className || column.className) + (column.type === COLUMN_TYPES.EXPANDER ? ' center header-no-bar' : '')} 
       style={getColumnStyles(column)}>

    {columnValue(row, column, rowIdx, config, expandedRows, setExpanded)}
  </div>
);

export const getColumnStyles = (column) => {
  const style = {};

  if (column.type === COLUMN_TYPES.EXPANDER && column.width == undefined) {
    style.width = '24px';

  } else if (column.flex || !column.width) {
    style.flex = column.flex || 1;

  } else {
    style.width = column.width;

  }
  return style;
};

export const columnValue = (row, column, rowIdx, config, expandedRows, setExpanded) => {
  if (column.value) {
    return column.value;
  }

  switch(column.type) {

    case COLUMN_TYPES.CHECKBOX:
      return <input type="checkbox" checked={config.selected[rowIdx]} onClick={eventHandler(column.onCheckRow, row, column, rowIdx)} />;

    case COLUMN_TYPES.ACTION:
      return <i className={`fa ${column.iconCls}`} onClick={eventHandler(column.action, row, column, rowIdx)} />;

    case COLUMN_TYPES.EXPANDER:
      return expandedRows && expandedRows[row[config.uniqueKey]]
          ? <i className="fa fa-caret-down expand-collapse-button" onClick={() => setExpanded(row, false)} />
          : <i className="fa fa-caret-right expand-collapse-button"  onClick={() => setExpanded(row, true)} />
  }

  let value = column.labelKey ? row[column.labelKey] : null;
  if(isBlank(value)) {
    value = column.formatter ? column.formatter(row[column.key], column, row) : row[column.key];
  }
  return isBlank(value) ? (column.emptyText == null ? config.columnEmptyText : column.emptyText) : value;
};
