import React, {useState, useEffect} from "react";
import { getByKey } from 'www/util/dealFileMappings';
import {getFirstIndexOf, getSubStringFromBegining} from 'common/util/common-helpers'
import ButtonDropdown from 'common/button/ButtonDropdown';
import { formatDateMmmm } from 'common/util/common-formatters';
import SimpleTable from 'common/table/SimpleTable';
import {filterDealDocuments, createFilter} from 'www/util/dealDocumentHelper';

const ALL_PLACEHOLDER = 'All';

//helper functions
const getLabels = (documentTypeCodes)=>{
    let typeLabelMap = new Map();
    if(documentTypeCodes && documentTypeCodes.length>0){
        
        for(let documentTypeCode of documentTypeCodes){
            const label = getByKey(documentTypeCode);
            if(label){

                typeLabelMap.set(getByKey(documentTypeCode), documentTypeCode);
            }else{
                typeLabelMap.set(documentTypeCode, documentTypeCode);
            }
        }
    }
    return typeLabelMap;
}

const extractDocumentTypeCodes = (documents)=>{
    let documentTypeCodes = [];
    if(documents && documents.length>0){
        for(let doc of documents){
            documentTypeCodes.push(getSubStringFromBegining(doc.headingKey, getFirstIndexOf(doc.headingKey, '_')));
        }
    }
    return documentTypeCodes;
}

const extractYearsFromDocuments = (documents)=>{
    let years = new Set();
    if(documents && documents.length>0){
        for(let doc of documents){
            years.add(getSubStringFromBegining(doc.document.effectiveDate,getFirstIndexOf(doc.document.effectiveDate, '-')));
        }
    }
    return years;
}
//Component starts from here
const DealMonthlyDocuments = (props)=>{
    const{documents, config} =props;
    const[documentTypeLabelMap, updateDocumentTypeLabels] = useState(new Map());
    const[selectedType, updateSelectedType] = useState({selectedType: ALL_PLACEHOLDER});
    const[selectedYear, updateSelectedYear] = useState({selectedYear:''});
    const[selectedMonth, updateSelectedMonth] = useState({selectedMonth:ALL_PLACEHOLDER});
    const[filteredDocuments, updateFilteredDocuments] = useState(documents);
    

    useEffect(()=>{
        const documentTypeCodes = extractDocumentTypeCodes(documents);
        const documentTypeCodeLabelMap = getLabels(documentTypeCodes);
        const yearsOptions = extractYearsFromDocuments(documents);
        const yearsOptionsArray = Array.from(yearsOptions);
        yearsOptionsArray.sort((a, b)=>{if(b>a) return 1; else if(a>b) return -1; else return 0;});
        updateDocumentTypeLabels(documentTypeCodeLabelMap);
        updateSelectedYear({selectedYear:yearsOptionsArray[0]});
        
    },[]);

    useEffect(()=>{
        const filter = createFilter(selectedType.selectedType === ALL_PLACEHOLDER ? selectedType.selectedType : documentTypeLabelMap.get(selectedType.selectedType), selectedYear.selectedYear, selectedMonth.selectedMonth)
        const filteredDocuments = filterDealDocuments(documents, filter);
        updateFilteredDocuments(filteredDocuments);
    },[selectedType, selectedYear, selectedMonth]);

    const onChnageYearFilter = (yearListItem)=>{
        updateSelectedYear({selectedYear:yearListItem.value});
    }

    const onchangeMonthFilter = (monthOrAll)=>{       
        updateSelectedMonth({selectedMonth: monthOrAll.value});
    }

    const onChangeDocumentTypeFilter = (docTypeListItem) =>{   
        updateSelectedType({selectedType: docTypeListItem.value});
    }


    //generating months options for month filter dropdown
    const monthNumber = [ALL_PLACEHOLDER];
    for (var i = 1; i <= 13; i++) {
        monthNumber.push(i);
    }
    const monthOptions = monthNumber.slice(0, 13).map(month => {
        const formatted = month !== ALL_PLACEHOLDER ? formatDateMmmm(new Date(2042, month - 1, 1)) : ALL_PLACEHOLDER;
        return ({
          text: formatted,
          value: formatted,
          onClick: monthOrAll => onchangeMonthFilter(monthOrAll),
          disabled: formatted === selectedMonth.selectedMonth
        })
      });

    //generating year options for Year filter dropdown
    const yearsOptions = extractYearsFromDocuments(documents);
    const yearsOptionsArray = Array.from(yearsOptions);
    yearsOptionsArray.sort((a, b)=>{if(b>a) return 1; else if(a>b) return -1; else return 0;});

    const yearOptions = yearsOptionsArray.map(label => ({
      text: label,
      value: label,
      onClick: yearListItem => onChnageYearFilter(yearListItem),
      disabled: label === selectedYear.selectedYear
    })); 

    //generating Document Type options for document type filter
    let labelTypes =[];
    const iterator = documentTypeLabelMap.keys();
    let nextLabel = iterator.next();
    labelTypes.push(nextLabel.value);
    while(!nextLabel.done){
        nextLabel = iterator.next();
        labelTypes.push(nextLabel.value);
    }
    labelTypes.unshift(ALL_PLACEHOLDER);
    const typeOptions = labelTypes.map(label => ({
        text: label,
        value: label,
        onClick: typeListItem => onChangeDocumentTypeFilter(typeListItem),
        disabled: label === selectedType.selectedType
      }));


    const monthSelector = <ButtonDropdown className="month-selector" options={monthOptions}
      label={selectedMonth.selectedMonth === ALL_PLACEHOLDER ? 'Month' : selectedMonth.selectedMonth} />;
    const yearSelector = <ButtonDropdown className="year-selector" options={yearOptions} label={selectedYear.selectedYear} />;
    const typeSelector =  <ButtonDropdown className="type-selector" options={typeOptions}
        label={selectedType.selectedType === ALL_PLACEHOLDER ? 'Document' : selectedType.selectedType} />;

    return (<div className="report-container">
        <div style={{marginBottom:"10px"}}>
            <span>{typeSelector} {yearSelector} {monthSelector}</span>
        </div>
        <SimpleTable config={config} data={filteredDocuments}/>
    </div>);
}

export default DealMonthlyDocuments;