import React from 'react';

import { getOrganizationFromLocation } from 'common/util/common-helpers';

const cookieName = 'cssTC' + getOrganizationFromLocation();
export function redirectToHome() {
	const org = getOrganizationFromLocation();
  	const redirectHomePath = `/${org}/`;
  	window.location.href=redirectHomePath ;  		
  };

export function checkTC() {
    const allCookies = document.cookie;
    const cookieArray = allCookies.split(';');
    let tAndCFound = false;
    for(let i =0; i<cookieArray.length; i++){
      //console.log("Cookie value is: "+cookieArray[i]);
      if(cookieArray[i].includes(cookieName)){
        tAndCFound = true;
        break;
      }
    }
    //console.log(`Is Terms And Condition Accepted in this session? -> ${tAndCFound}`);

	return tAndCFound;
}

export function createTCCookie() {
	 const termsAndConditions = document.cookie = cookieName+"=valid;path=/";
}

export function resetTCFunc() {
	document.cookie = cookieName+"=invalid; expires = Thu, 01 Jan 2022 00:00:00 GMT;path=/";
	
//   console.log("Cookie Resetting");
//   const allCookies = document.cookie;
//   const cookieArray = allCookies.split(';');
//   let tAndCFound = false;
//   for(let i =0; i<cookieArray.length; i++){
//    console.log("Cookie value is: "+cookieArray[i]);
//  }
	 
  }
