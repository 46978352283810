  import {
  SEARCH_COUNT_RESPONSE,
  SEARCH_COUNT_ERROR,

  SEARCH_REQUEST,
  SEARCH_RESPONSE,
  SEARCH_REDIRECT,
  SEARCH_ERROR,

  MAJORS_REQUEST,
  MAJORS_RESPONSE,
  MAJORS_ERROR,
  MAJORS_SORT
} from 'www/actions/searchActions';

import { reducerGenerator, reduceReducers } from 'common/util/redux';
import { getNewSort, defaultComparator } from 'common/util/sorter';

import {
  DEFAULT_COLLATERAL_PAGE_SIZE,
  DEFAULT_INIT_PAGE
} from 'common/util/common-helpers';
  import {onMultiSortArray} from "../../common/util/sorter";

const initialState = {
  isFetching: false,
  hasError: false,
  isFetchingMore: false,
  isLoadingMajors: false,
  appendingResult: true,
  data: null,
  majors: null,
  message: null,
  prevQuery: null,
  pageIdx: DEFAULT_INIT_PAGE,
  pageSize: DEFAULT_COLLATERAL_PAGE_SIZE,
  sort: {
    field: 'cusip',
    direction: 'asc'
  },
  multiSort: [{
      field: 'cusip',
      direction: 'asc'
  }],
  exportLink: null,
  lastUpdated: null
};

const resultsSort = reducerGenerator(initialState, {
  MAJORS_SORT: onSortMajors
});

const MAJORS_CUSTOM_COMPARATOR = {'issueDt' : issueDtComparator};

const MAJORS_TIE_BREAKER = {field: 'cusip', direction: 'asc'};
const MAJORS_DEFAULT_SORT = [{field: 'issueDt', direction: 'desc', comparator: issueDtComparator}, {field: 'prefix', direction: 'asc'}, MAJORS_TIE_BREAKER];

function issueDtComparator(dateStrA, dateStrB) {
  return defaultComparator(
    dateStrA ? dateStrA.substring(0, 7) : '',
    dateStrB ? dateStrB.substring(0, 7) : '');
}

function onSortMajors(state, action) {
  let newMultiSort = null;
  let {field} = action;
  let newSort = getNewSort(state.sort, field);
  if (newSort) {
    newMultiSort = [{...newSort, comparator: MAJORS_CUSTOM_COMPARATOR[newSort.field]}];
    if (newSort.field !== MAJORS_TIE_BREAKER.field) {
        newMultiSort.push(MAJORS_TIE_BREAKER);
    }
  } else {
    newMultiSort = [MAJORS_TIE_BREAKER];
  }

  state.majors.majors = onMultiSortArray(state.majors.majors, newMultiSort);

  return {
    ...state,
    sort: newSort,
    multiSort: newMultiSort,
    exportLink: createExportLink(state.exportLink, newMultiSort)
  };
}

function onMajorRequest(state, action) {
  return {
    ...state,
    sort: MAJORS_DEFAULT_SORT[0],
    multiSort: MAJORS_DEFAULT_SORT,
    isLoadingMajors: true,
    exportLink: createExportLink(state.exportLink, MAJORS_DEFAULT_SORT, `/api/search/majors/${action.organization}/export`)
  }
}

function onMajorsResponse(state, action) {
  let { multiSort } = state;
  let { majors } = action;
  let lastUpdated = majors.timeStamp;
  if (majors && majors.majors && majors.majors.length) {
    if (multiSort) {
      majors.majors = onMultiSortArray(majors.majors, multiSort);
    }

    if (lastUpdated !== undefined) {
      // Only display the date portion of the timestamp returned from the Web service
      lastUpdated = lastUpdated.substr(0, 10)
    } else {
      lastUpdated = majors.majors.reduce(
          (max, item) => max.updateDate < item.updateDate ? item : max, {updateDate: '0000-00-00'}).updateDate
    }
  }

  return {...state, isLoadingMajors: false, majors: majors, lastUpdated: lastUpdated}
}

function createExportLink(currentLink, multiSort, defaultBaseLink) {
  let baseLink = currentLink ? currentLink.split("?")[0] : defaultBaseLink;

  if (multiSort) {
    return baseLink
      + '?sortField=' + multiSort.map(item => item.field).join(',')
      + '&sortAsc=' + multiSort.map(item => item.direction ? item.direction.toUpperCase() : '').join(',');
  } else {
    return baseLink;
  }
}

function onSearchError(state, action) {
  console.log("OnSearchError");
  return {
    ...state,
    isFetching: false,
    hasError: true,
    message: action.message,
    data: null,
    count: null // If count search results succeeded, but not search count, need to cancel that out!
  };
}

const resultsHTTP = reducerGenerator(initialState, {
  SEARCH_COUNT_REQUEST: (state, {action, pageSize}) => ({
    ...initialState,
    isFetching:  true,
    isFetchingMore: true,
    count: null,
    countQuery: true,
    pageSize,
    redirectAttempted: false
  }),
  SEARCH_COUNT_RESPONSE: (state, action) => ({
    ...state,
    isFetching: false,
    isFetchingMore: false,
    count: action.count,
    redirect: action.redirect,
    countQuery: false
  }),
  SEARCH_COUNT_ERROR: onSearchError,
  SEARCH_REQUEST: (state, {pageIdx, pageSize, sort}) => (
    {
    ...state,
    isFetching:  state.data == null,
    isFetchingMore: state.data != null,
    hasError: false,
    data: state.data,
    message: '',
    searchQuery: true,
    redirect: state.redirect,
    pageIdx,
    pageSize,
    sort
  }),
  SEARCH_RESPONSE: (state, {pageIdx, pageSize, results, sort}) => {

    const returnObj = {
      ...state,
      isFetching: false,
      hasError: false,
      isFetchingMore: false,
      data: results,
      message: null,
      searchQuery: false,
      pageIdx,
      pageSize,
      sort
    };

    return returnObj;
  },
  SEARCH_REDIRECT: (state, {pageIdx, pageSize, sort}) => (
    {
      ...state,
      data: state.data,
      pageIdx,
      pageSize,
      sort,
      redirectAttempted: true

  }),
  SEARCH_ERROR: onSearchError,

  MAJORS_REQUEST: onMajorRequest,
  MAJORS_RESPONSE: onMajorsResponse,
  MAJORS_ERROR: (state, action) => ({...state, hasError: true, isLoadingMajors: false, majors: null})
});

export default reduceReducers(resultsHTTP, resultsSort);
