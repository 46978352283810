import * as actions from '../actions/fannieResourcesActions'
import API from '../../common/util/api';
import { reduceMiddleware } from '../../common/util/redux';
import {getOrganizationFromLocation} from "../../common/util/common-helpers";



function productResourceRequest(getState, next, action) {
    API.get('resource/'+getOrganizationFromLocation()+'/product').then(
        (response) => {
            next({...action, type: actions.FNM_PRODUCT_RESPONSE, response});
          },
          (message) => {
            next({...action, type: actions.FNM_PRODUCT_ERROR, message});
          }
    );
}

function learningResourceRequest(getState, next, action) {
    API.get('resource/'+getOrganizationFromLocation()+'/learning').then(
        (response) => {
            next({...action, type: actions.FNM_LEARNING_RESPONSE, response});
        },
        (message) => {
            next({...action, type: actions.FNM_LEARNING_ERROR, message});
        }
    );
}


export default reduceMiddleware({
    [actions.FNM_PRODUCT_REQUEST]: productResourceRequest,
    [actions.FNM_LEARNING_REQUEST]: learningResourceRequest,
  });