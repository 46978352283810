import React, { Component } from 'react';
import { connect } from 'react-redux';
import {DEFAULT_INIT_PAGE,
  DEFAULT_MAX_PAGE_SIZE,
  } from 'common/util/common-helpers';
import { 
  currencyWithCents,
  formatDateMmDdYyyy, 
  zeroToEmpty 
} from 'common/util/common-formatters';
import {
  assign,
  createGroupClassNameGenerator,
  toLookup
} from 'common/util/common-helpers';
import {
  factor,
  rate
} from 'www/util/www-formatters';

import { mapColumns } from 'www/util/labels';

import SimpleTable from 'common/table/SimpleTable';

export default class DealComponentTable extends Component {
  
  componentDidMount(){
    this.props.getComponentDeal(this.props.trustNumber,DEFAULT_INIT_PAGE,DEFAULT_MAX_PAGE_SIZE);
  }
  extractComponents=(deal)=> {


    /*

        Securities have components, we need to extract them from structure like this:

        deal:
          classes:
          - cusip: "31398PWL9"
            remicFields:
              trustNumber: "10044"
              groupId: "1"
              classId: "ST"
              components:
              - componentClass: null
                component: "ST1"
                classInvestorSecurityUpb: 402387
                componentFactor: null
                netInterestRate: null
              - componentClass: null
                component: "ST2"
                classInvestorSecurityUpb: 2214832
                componentFactor: null
                netInterestRate: null
              - componentClass: null
                component: "ST3"
                classInvestorSecurityUpb: 667544
                componentFactor: null
                netInterestRate: null
          - cusip: "31243PJD3"
            remicFields:
              trustNumber: "10044"
              groupId: "1"
              classId: "SH"
              components:
              - componentClass: null
                component: "SH1"
                classInvestorSecurityUpb: 402387
              - componentClass: null
                component: "SH2"
                classInvestorSecurityUpb: 2214832
              - componentClass: null
                component: "SH3"
                classInvestorSecurityUpb: 667544

          And we want to make an array of components like this:

              - componentClass: null
                component: "ST1"
                cusip: "31398PWL9"
                groupId: "1"
                classId: "ST"
                classInvestorSecurityUpb: 402387
                componentFactor: null
                netInterestRate: null
              - componentClass: null
                component: "ST2"
                cusip: "31398PWL9"
                groupId: "1"
                classId: "ST"
                classInvestorSecurityUpb: 2214832
                componentFactor: null
                netInterestRate: null
              - componentClass: null
                component: "ST3"
                cusip: "31398PWL9"
                groupId: "1"
                classId: "ST"
                classInvestorSecurityUpb: 667544
                componentFactor: null
                netInterestRate: null
              - componentClass: null
                component: "SH1"
                groupId: "1"
                classId: "SH"
                cusip: "31243PJD3"
                classInvestorSecurityUpb: 402387
              - componentClass: null
                component: "SH2"
                groupId: "1"
                classId: "SH"
                cusip: "31243PJD3"
                classInvestorSecurityUpb: 2214832
              - componentClass: null
                component: "SH3"
                groupId: "1"
                classId: "SH"
                cusip: "31243PJD3"
                classInvestorSecurityUpb: 667544

    */

    const flattenedComponents = [];
    let classes=[];
    if(deal!=undefined){
      if(deal.ongoingClasses!=undefined){
        classes=deal.ongoingClasses
      }
      else if(deal.issuanceClasses!=undefined){
        classes=deal.issuanceClasses;
      }
      else{
        return[];
      }
    }
    else{
      return[];
    }

    classes.forEach(cls => {

      if (cls.remicFields == undefined || cls.remicFields.components == undefined) {
        return;
      }
      
      cls.remicFields
         .components
         .forEach(comp => flattenedComponents.push(assign({}, {"groupId" : cls.remicFields.groupId, "classId" : cls.remicFields.classId}, comp)));
    });

    
    return flattenedComponents;
  }

  render(){
  const trustNumber=this.props.trustNumber;
  const { details } = this.props;
  const { componentDeal, isComponentFetching, hasComponentError } = details;
  let deal=componentDeal;
  const components = this.extractComponents(deal);
  if (hasComponentError) {
    return <div className="empty-text">{'Unable to load components. Please try again later.'}</div>;
  } else if (isComponentFetching) {
    return <div className="loading more"><i className="fa fa-spinner fa-pulse"/></div>;
  }


  const gridConfig = {
    className: 'results deal-table',
    rowDynamicClassName: createGroupClassNameGenerator('groupId', components, 'group-start', ''),
    columnDefaults: {
      sortable: false
    },
    key: 'dealComponentTable',
    columns: [
      { 
        key: 'groupId',    // added to combined component below
        formatter: zeroToEmpty,
        className: 'center header-center',
        dynamicClassName: createGroupClassNameGenerator('groupId', components, 'group-cell-show', 'group-cell-hide')
      },
      { 
        key: 'classId', 
        dynamicClassName: createGroupClassNameGenerator('classId', components, '', 'group-cell-hide'),
        className: 'center header-center'
      },
      { 
        key: 'component', 
        className: 'center header-center'
      },
      { 
        key: 'classInvestorSecurityUpb',
        formatter: currencyWithCents,
        className: 'right pad header-center'
      },
      { 
        key: 'componentFactor', 
        formatter: factor,
        className: 'center header-center'
      },
      { 
        key: 'netInterestRate', 
        formatter: rate,
        className: 'center header-center'
      }
    ],
    columnEmptyText: '-',
    emptyText: 'No Data'
  };

  mapColumns('dealComponents', gridConfig.columns);

  return <SimpleTable config={gridConfig} data={components} />;
  }
  
}


